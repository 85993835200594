import { Component,  OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, Validators,ValidationErrors,AsyncValidatorFn,AbstractControl} from '@angular/forms';
import { Observable, filter , of} from 'rxjs';
import { debounceTime, map, startWith, switchMap,catchError } from 'rxjs/operators';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as constants from 'src/app/config/app-constants';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Inject } from '@angular/core';
import { SalesforceService } from '../../services/salesforce.service';
import { OrgRole } from 'src/app/common/user/models/org-role';
import { SiteWithAdminUser } from '../../models/org-with-admin-user';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { AuthorizationService } from '../../services/authorization.service';
import { AddOrganizationDialogComponent } from '../add-organization/add-organization-dialog.component';
import { Role } from 'src/app/common/permission-groups/models/role';
import { PermissionGroupsService } from 'src/app/common/permission-groups/services/permission-groups.service';
import { AutoCompleteDropDownValidators } from 'src/app/shared/directives/validate-selectedvalue-in-collection';
import { AutoCompleteField } from 'src/app/shared/models/auto-complete-field';

@Component({
  selector: 'app-add-site-dialog',
  templateUrl: './add-site-dialog.component.html',
  styleUrls: ['./add-site-dialog.component.scss']
})

export class AddSiteDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddSiteDialogComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    private salesforceService: SalesforceService,
    private authorizationService: AuthorizationService,
    private permissionGroupService : PermissionGroupsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userdataSource = new MatTableDataSource();
  }
  userdisplayedColumns = ['name', 'email', 'role', 'delete'];
  userdataSource: MatTableDataSource<SiteWithAdminUser>;

  // recent search dummy data
  displayedColumns: string[] = ['name'];
  showbackbutton = false;
  isparentnotassociated = false;  
  options: any[];

  siteOptions: any[];
  siteOptionswithfilter: any[] = []; 
  existingSites: any[];
  filteredOptions: Observable<any[]>;
  permissionGroups: Role[] = [];
  parentOrgName: string;
  parentOrgId: string;
  filter: string;
  minLengthToSearch = 3;
  siteSalesforceId: string;
  siteSalesforceName: string;
  siteCustomerNumber: string;
  isCustomerreload = false;
  isEmailExist = false;
  isSiteExist = false;

  isNoOrgResult=false;

  // placeholder
  myplaceHolder: string;

  formGroup: FormGroup = new FormGroup({
    siteName: new FormControl('', [Validators.required]),
    salesforceName: new FormControl('', [Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.siteOptionswithfilter, AutoCompleteField.salesforce)]),
    userLastName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
    userFirstName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
    permissionGroup: new FormControl('', [Validators.required]),
    userEmail: new FormControl('', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'), Validators.required]),
  });

  parentOrgformGroup: FormGroup = new FormGroup({
    parentName: new FormControl('', [Validators.required]),
  });

  @ViewChild('autoCompleteInput', { static: false,read: MatAutocompleteTrigger })  trigger: MatAutocompleteTrigger;

  ngOnInit() {
    this.formGroup.controls.salesforceName.valueChanges.pipe(
      switchMap(id => {
        if (id.length > 2)
          return this.salesforceService.getSitesByNameOrCustomerNo(id);
        else
          return this.siteOptionswithfilter = [];
      })
    ).subscribe(res => { 
      res = this.disableExistingSites(res);
      this.siteOptionswithfilter = res;
      this.resetSalesforceValidators();
    },
      (error) => {
        //Error callback
        this.siteOptionswithfilter = [];
        this.resetSalesforceValidators();
        this.toastService.openToast("No sites found", constants.ToastPanelType.error);
      });
    this.isparentnotassociated = this.data.isparentnotassociated;
    this.parentOrgName = this.data.parentName;
    this.parentOrgId=this.data.parentID;
    this.ngxLoader.start('site-dialog-load');
    this.loadParentOrg();
    if(!this.isparentnotassociated){
      this.loadAllSites();
    }
    this.loadPermissionGroups();
  }

  resetSalesforceValidators() {
      this.formGroup.controls.salesforceName.clearValidators();      
      this.formGroup.controls.salesforceName.setValidators([Validators.required, AutoCompleteDropDownValidators.SelectedValueValidator(this.siteOptionswithfilter, AutoCompleteField.salesforce)]);      
  }

  loadAllSites(){
    this.organizationService.getSites()
      .subscribe((sites) => {
        this.existingSites = sites;
      }, () => {
      });

  }

  showsite(value) {
    if ((document.getElementById('parentName') as HTMLInputElement).value=== '') {
      this.toastService.openToast('Parent Organization cannot be empty', constants.ToastPanelType.error);
    }
    else {
      this.isparentnotassociated = false;
      this.showbackbutton = value;
      this.parentOrgName = (document.getElementById('parentName') as HTMLInputElement).value;

    }
  }

  hidesite() {
    this.isparentnotassociated = true;
  }

  validateForm() {
    if (this.formGroup.invalid) {
       this.formGroup.get('siteName').markAsTouched();
       this.formGroup.get('salesforceName').markAsTouched();
       this.formGroup.get('userLastName').markAsTouched();
       this.formGroup.get('userFirstName').markAsTouched();
       this.formGroup.get('permissionGroup').markAsTouched();
       this.formGroup.get('userEmail').markAsTouched();
       return;
    }
 }

  // Adds new site with admin user.
  addRow() {

    if (!this.formGroup.valid)
    {
      this.validateForm();
      return;
    }

    this.siteExist();
    if(this.isSiteExist){
      const loadingKey = 'Site already exists!';
      this.toastService.stopLoadingAndShowError(
        'Site already exists!', loadingKey);
        this.isSiteExist=false;
    }
    else if(this.isEmailExist){
      const loadingKey = 'Email already exists!';
      this.toastService.stopLoadingAndShowError(
        'Email already exists!', loadingKey);
    }
    else{
      const siteWithAdminUser: SiteWithAdminUser = this.formGroup.getRawValue();
      siteWithAdminUser.parentOrgID = this.parentOrgId;
      siteWithAdminUser.salesforceName = this.siteSalesforceName;
      siteWithAdminUser.siteSalesforceId = this.siteSalesforceId;
      siteWithAdminUser.siteCustomerNo = this.siteCustomerNumber;
      siteWithAdminUser.roleID = this.formGroup.get('permissionGroup').value;
      this.ngxLoader.start('add-site-submit');
      this.organizationService.saveSiteWithAdminUser(siteWithAdminUser).subscribe(
        () => {

          this.ngxLoader.stop('add-site-submit');
          this.dialogRef.close('success');
          setTimeout(() => {
            this.toastService.openToast('New Site Created', constants.ToastPanelType.done);
          }, 700);
        },
        ({ error }) => {
          this.isCustomerreload = false;
          this.ngxLoader.stop('add-site-submit');
          const message = error.errors ? Object.values(error.errors)
            .map((e: string[]) => e.join('\n'))
            .join('\n') : error.error;
          this.toastService.openToast(message, constants.ToastPanelType.error);
        },
      );
    }

  }

  deleterow(value) {
    const index = value.currentTarget.id;
    this.userdataSource.data.splice(index, 1);
    this.userdataSource.data = this.userdataSource.data;
  }

  loadPermissionGroups() {
    this.permissionGroupService.getDefaultPermissionGroups()
      .subscribe((response) => {
      // New site needs only Admin permission group
      // Filter only permission groups where the 'description' property contains 'Admin'
      this.permissionGroups = response.filter(x=> x.description.includes('Admin'));
      this.ngxLoader.stop('site-dialog-load');
    }, (response) => this.toastService.stopLoadingAndShowError(response,
      'Unable to load permission groups!', 'site-dialog-load'));
  }

  loadParentOrg() {
    this.organizationService.getParentOrganizations()
      .subscribe((organizations) => {
        this.options = organizations;
        this.filteredOptions = this.parentOrgformGroup.controls.parentName.valueChanges
          .pipe( 
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.options.slice())
          );
        this.ngxLoader.stop('site-dialog-load');;
      }, () => {
        this.ngxLoader.stop('site-dialog-load');;
        this.toastService.openToast('Error while loading organizations!', constants.ToastPanelType.error);
      });
  }

  private _filter(name: string) {
    this.isNoOrgResult=false;
    const filterValue = name.toLowerCase();
    const filterResult =this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    this.isNoOrgResult = !(filterResult && filterResult.length>0);
    return filterResult;
  }

  fetchSalesforceDetails(siteDetail) {
    this.siteSalesforceId = siteDetail.id;
    this.siteSalesforceName = siteDetail.name;
    this.siteCustomerNumber = siteDetail.customer_Num__c;
  }

  clear(ctrl){
    ctrl.setValue('');
  }
  onBlur(){
    setTimeout(() => {
      this.formGroup.get('salesforceName').setValue((this.siteSalesforceId != null && this.siteSalesforceId != undefined)? this.siteSalesforceId : '');
    }, 200);
  }

  onParentOrgBlur(){
    setTimeout(() => {
      this.parentOrgformGroup.get('parentName').setValue((this.parentOrgId != null && this.parentOrgId != '')? this.parentOrgformGroup.get('parentName').value : '');
    }, 200);
  }

  emailExist() {
    this.authorizationService.checkUserExists(this.formGroup.get('userEmail').value)
      .subscribe((response) => {
        if (response == true) {
          this.isEmailExist = true;
        }
        else {
          this.isEmailExist = false;
        }
      }, (error) => {
        this.toastService.openToast('Error while checking email exists!', constants.ToastPanelType.error);
        this.isEmailExist = false;
      });
  }

  siteExist()
  {
    if (this.formGroup.get('siteName').value!='' && !this.isSiteExist)
    {
      this.organizationService.getOrganizationByName(this.formGroup.get('siteName').value)
      .subscribe(() => {
          this.isSiteExist=true;
      }, () => {
        this.isSiteExist=false; });
    }
  }

  fetchParentOrgDetails(orgDetail) {
   
    this.parentOrgId = orgDetail.organizationId;
    this.loadAllSites();


  }

  getSiteName(value: any) {
    if (value !== '') {
      const site = this.siteOptionswithfilter.find(org => org.id === value);
      return site != null ? site.name : '';
    }
    else {
      return '';
    }
  }

  disableExistingSites(assets) {
    assets?.forEach((element) => {
      const system = this.existingSites?.find(site => site.salesforceId === element.id);
      if(system != null) {
        element.isOnboarded = true;
      }
      else {
        element.isOnboarded = false;
      }
    });
    return assets;    
  }

  openOrgWin()
  {
    this.dialogRef.close('openorg');
  }

  getSalesforceErrorMessage() {            
    if (this.siteOptionswithfilter == null || this.siteOptionswithfilter.length == 0) {
      return "No Site Name retrieved from salesforce";
    } else if (this.formGroup.get('salesforceName').hasError('required')) {
      return "Site Name required";      
    } else if (this.formGroup.get('salesforceName').hasError('ShowValidatonError')) {
      return "Site Name is invalid";
    } else {
      return null;
    }
  }
}