import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewStoreService extends DataService<any> {

  constructor(http: HttpClient) {
    super(environment.apiUrl.viewstoreService + 'scriptmgmtviews', http);
  }

  public getInProgressBatchesSite(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/scriptrouting_inprocessbatches_site');
  }

  public getInQueueBatchesSite(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/scriptrouting_inqueuebatches_site');
  }

  public getInCompleteBatchesSite(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/scriptrouting_inprocessbatches_site');
  }

  public getCompletedBatchesSite(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/scriptrouting_inqueuebatches_site');
  }

}
