import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from 'src/app/store/state';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OrganizationsActions } from '../actions';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { of } from 'rxjs';

@Injectable()
export class OrganizationsEffect {
  constructor(private actions$: Actions,
    private organizationService: OrganizationService,
    public store: Store<State>) {}

  Organizations$ = createEffect(() => this.actions$.pipe(
      ofType(OrganizationsActions.getOrganizations),
      mergeMap(() =>
      this.organizationService.getActiveOrganizations().pipe
        (
          map((response) => OrganizationsActions.getOrganizationsSuccess({ organizations: response })),
          catchError(error => of(OrganizationsActions.getOrganizationsFailure({ error })))
        )
      )
    )
  );
}
