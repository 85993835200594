<div class="flex justify-between content-stretch items-stretch flex-col">
  <div>
    <h1 mat-dialog-title tabindex="0" class="xs:flex-1 sm:basis-full">
      <span *ngIf="edgeDeviceFriendlyName">{{edgeDeviceFriendlyName}} - </span>
      <span>{{edgeDeviceId}}</span>
    </h1>
  </div>

  <mat-dialog-content>
    <div class="grid xs:grid-cols-1 grid-cols-[repeat(4,_auto)] gap-4 deviceDetails">
      <div>
        <label for="organization">
          Organization
        </label>
        <p id="organization">{{organizationId}}</p>
      </div>
      <div>
        <label for="status">
          Agent Status
        </label>
        <p id="status">{{status}}</p>
      </div>
      <div>
        <label for="ipAddress">
          IP Address
        </label>
        <p id="ipAddress">{{ipAddress}}</p>
      </div>
      <div *ngIf="status === 'Offline'">
        <label for="lastKnownOnlineTime">
          Last Online Time
        </label>
        <p id="lastKnownOnlineTime"> {{ lastKnownOnlineTime | date:'dd-MMM-yyyy hh:mm aaaaa\'m\'' }}</p>
      </div>
      <ng-container *ngFor="let additionalField of additionalFields">
        <div>
          <label>
            {{additionalField.name}}
          </label>
          <p>{{additionalField.value}}</p>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-center w-full !py-4">
    <button mat-button class="btn-default btn-md" id="Disable" [disabled]="status === 'Disabled'" (click)="onDisable()"
      tabindex="0">
      Disable
    </button>
    <button mat-button type="submit" class="btn-primary btn-md" id="Done" [mat-dialog-close]="true" tabindex="0">
      Done
    </button>
  </mat-dialog-actions>
</div>