/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import * as constants from '../../config/app-constants';
import { ReportViewerComponent } from './components/report-viewer/report-viewer.component';
import { ReportSubscriptionComponent } from './components/report-subscription/report-subscription.component';
/* eslint-enable max-len */

const routes: Routes = [
  {
    path: constants.routePaths.REPORTS,
    component: ReportViewerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: constants.routePaths.REPORT_SUBSCRIPTION,
    component: ReportSubscriptionComponent,
    canActivate: [ AuthGuard ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule { }
