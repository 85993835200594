
<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">

  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        <h3>DRUG IMAGES</h3>
      </div>
      <div class="flex flex-row justify-center content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="dialogRef.close()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.clear.svg" alt="">
        </mat-icon>
      </div>

    </div>
  </div>

  <div class="row flex flex-col justify-center content-center items-center w-full gap-[1rem]" style="margin-bottom: .8rem;">
    <div class="flex flex-col basis-1/5">
      <span class="label">Data Source</span>
      <mat-form-field>
        <mat-select [(ngModel)]="dataSource" ngDefaultControl>
          <mat-option *ngFor="let data of listOfDataSource" 
            [value]="data.key">{{data?.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex flex-col basis-1/5">
      <button mat-button aria-label="Save Drug" style="margin-top: 1.5rem;" (click)="getSliderImage()" 
      class="btn-sm btn-primary" 
      type="button">Search</button>
    </div>
 
  
  </div>

<div *ngIf="slides?.length>0">
 <div  class="images-slideshow">
  <div  class="imagesSlides fade" *ngFor="let slide of slides; let i = index">
    <img *ngIf="currentIndex===i" [src]="slide.serialImage" />
    <span *ngIf="currentIndex===i">{{slide.imageName}}</span>
  </div>
 </div>
 <a *ngIf="currentIndex!==0" class="slider-btn previous" (click)="slideShow(false)"> < </a>
 <a *ngIf="currentIndex!==slides.length-1" class="slider-btn next" (click)="slideShow(true)"> > </a>
</div>
<div class="images-slideshow" style="margin-top: 5rem;" *ngIf="slides?.length===0">
  <!-- <span class="img-not-found"> Image Not Available </span> -->
  <img src='/assets/img/no-image.png' alt="">
</div>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
  <button mat-button class="btn-blue-light btn-md" (click)="dialogRef.close()" id="CancelNewUser" aria-label="Cancel" type="button">Cancel</button>

</mat-dialog-actions>
  </div>



