import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PermissionGroupsService } from '../../services/permission-groups.service';

@Component({
  selector: 'app-delete-permission-group-dialog.component',
  templateUrl: './delete-permission-group-dialog.component.html',
  styleUrls: ['./delete-permission-group-dialog.component.scss']
})
export class DeletePermissionGroupDialogComponent implements OnInit {
  parentOrgId: any;
  constructor(
    public dialogRef: MatDialogRef<DeletePermissionGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permissiongroupService: PermissionGroupsService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
  ) {

  }
  selectedGroup: any;
  impactedMembers: number;

  ngOnInit(): void {
    this.selectedGroup = this.data.role;
    this.parentOrgId = this.data.parentOrgId;
    console.log(this.data);
    this.getImpactedMembers();
  }

  getImpactedMembers() {

    const loadingKey = 'impacted-user-count';
    this.ngxLoader.start(loadingKey);
    this.permissiongroupService.getImpactedMembers(this.parentOrgId, this.selectedGroup.roleId)
      .subscribe((val) => {
        this.impactedMembers = val;
        this.ngxLoader.stop(loadingKey);
      },
        catchError((error: HttpErrorResponse) => {
          let errorMessage: string;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
          }
          this.ngxLoader.stop(loadingKey);
          this.toastService.stopLoadingAndShowError(error, 'Error while getting impacted members!', loadingKey);
          return throwError(error);
        }));
  }

  deletePermissionGroup() {

    const loadingKey = 'delete-Permission-Group';

    this.ngxLoader.start(loadingKey);
    this.permissiongroupService.deactivatePermissionGroup(this.parentOrgId, this.selectedGroup.roleId).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.dialogRef.close(true);
      this.toastService.openToast('Permission group deleted!');
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.ngxLoader.stop(loadingKey);
        this.toastService.stopLoadingAndShowError(error, 'Error while deleting permission group!', loadingKey);
        return throwError(error);
      })
    );
  }

  closePopup() {
    this.dialogRef.close(false);
  }
}
