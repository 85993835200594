import { Injectable } from '@angular/core';
import * as XLSX from "xlsx";
import { ExportFileInfo } from '../models/export-file-info';
@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor() { }
  public exportAsExcelFile(fileInfo: ExportFileInfo): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    fileInfo.templates.forEach((x) => {
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(x.data), x.sheetName);

    });

    XLSX.writeFile(
      wb,
      `${fileInfo.fileName}`
    );
  }

  exportToCsvFromString(data: string[], fileName: string): void {
    const csvContent = data.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();

    URL.revokeObjectURL(url);
  }
  public exportAsCSVFile(fileInfo: ExportFileInfo): void {
    fileInfo.templates.forEach((template) => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(template.data);
      const csvData: string = XLSX.utils.sheet_to_csv(ws);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
      const link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileInfo.fileName}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }
}
