import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { CustomerManagementModule } from './customer-management/customer-management.module';
import { OrderModule } from './order/order.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternalUserRoutingModule } from './internal-user-routing.module';
import { CommonModules } from '../common/common.module';
import { FleetManagementModule } from './fleet-management/fleet-management.module';
import { DrugManagementModule } from './drug-management/drug-management.module';
import { IMToolModule } from './im-tool/im-tool.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    DashboardModule,
    CustomerManagementModule,
    FleetManagementModule,
    OrderModule,
    SharedModule,
    CommonModules,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    InternalUserRoutingModule,
    DrugManagementModule,
    IMToolModule
  ]
})
export class InternalUserModule {}
