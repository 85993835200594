<div class="packagerdiscard_dialog">
    <form class="flex justify-between content-stretch items-stretch flex-col">
        <div>
            <h1 mat-dialog-title class="!flex justify-between content-stretch items-stretch flex-row">
                <div tabindex="0">
                    Discard Changes?
                </div>
                <div>
                    <button class="btn-transparent btn_icon" mat-dialog-close type="button" aria-label="close">
                        <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="">
                    </button>
                </div>
            </h1>
            <mat-dialog-content class="pack-down-container">
                <h6 class="m-0" tabindex="0">Changes you made so far will not be saved</h6>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
            <button mat-button class="btn-default btn-md" (click)="closeDialog()" 
                    type="button" id="cancel">Cancel</button>
            <button mat-button class="btn-primary btn-md m-l-16"  (click)="closeDialogUndo()" 
                    type="button" id="discard">Discard Changes</button>
        </mat-dialog-actions>
    </form>
</div>