<div class="pharmacy-peek-list">
  <div class="pharmacy_reorderlist">
    <div class="sticky_header">
      <div class="p-t-8 p-b-8 p-l-15 p-r-15 flex justify-between content-stretch items-stretch flex-row">
        <div class="sm:basis-[62%] md:basis-[67%] lg:basis-[70%]">
          <small tabindex="0">Drag and drop to reorder list</small>
        </div>
        <div class="text-right sm:basis-[38%] md:basis-[33%] lg:basis-[30%]">
          <button mat-button class="btn-default btn-sm !hidden" type="button">Preview</button>
        </div>
      </div>
      <h6 tabindex="0" class="uppercase">Pharmacy Peek List</h6>
    </div>
    <div cdkDropList class="pharpeeklist" *ngIf="selectedList.length > 0" (cdkDropListDropped)="drop($event)">
      <div class="pharpeeklist-box" *ngFor="let widget of selectedList;index as i" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <p tabindex="0" *ngIf="widget.name  !== 'SystemStatus'" matTooltip="{{widget?.displayName}} - {{ widget?.systemConfig?.deviceName}}" matTooltipPosition="below">
          {{widget?.displayName}} - {{ widget?.systemConfig?.deviceName}}
        </p>
        <p tabindex="0" *ngIf="widget.name  === 'SystemStatus'" matTooltip="{{widget?.displayName}} - {{ widget?.systemConfig?.deviceName.split(',').join(', ')}}" matTooltipPosition="below">
          {{widget?.displayName}} - {{ widget?.systemConfig?.deviceName.split(',').join(', ')}}
        </p>

        <figure>
          <button *ngIf="widget?.name !== 'SystemStatus' " class="btn-transparent btn_icon" type="button" (click)="removeWidget(i)" 
            (keydown.enter)="removeWidget(widget)" aria-label="Clear" tabindex="0">
            <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="" id="{{widget?.displayName|trim}}-{{ widget?.systemConfig?.deviceName|trim}}-{{i}}">
          </button>
          <button *ngIf="widget?.name === 'SystemStatus' " class="btn-transparent btn_icon" type="button" (click)="removeWidget(i)" 
            (keydown.enter)="removeWidget(widget)" aria-label="Clear" tabindex="0">
            <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="" id="{{widget?.displayName|trim}}-{{ widget?.systemConfig?.deviceName}}-{{i}}">
          </button>
        </figure>
      </div>
    </div>
    <div class="pharpeeklist text-center italic flex justify-center content-stretch items-stretch flex-col" *ngIf="selectedList.length == 0">
      <p class="listempty" tabindex="0">
        Pharmacy Peek List is Empty
      </p>
    </div>
  </div>

  <div class="btn_controls flex justify-end content-stretch items-stretch xs:hidden sm:block">
    <button mat-button class="btn-default btn-md" (click)="openPharmacyPeekDiscardChangesDialog()" 
      type="button">Cancel</button>
    <button mat-button class="btn-primary btn-md m-l-16" mat-dialog-close type="button" (click)="savePharmacyPeekList()" 
      (keydown.enter)="savePharmacyPeekList()">Done</button>
  </div>
</div>
