import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Organization } from '../../../../internal-user/customer-management/models/organization';
import { OrganizationService } from '../../../../internal-user/customer-management/services/organization.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import * as constants from '../../../../config/app-constants';
import { ToastPanelType } from '../../../../config/app-constants';
import { AssetService } from '../../../../external-user/inventory/services/asset.service';
import { OrgAssetService } from '../../../../external-user/inventory/services/org-asset.service';
import { Asset } from '../../../../external-user/inventory/models/asset';
import { AuthService } from '../../../../auth/auth.service';
import { ParataStandardInterfaceService } from 'src/app/external-user/batch-manager/services/parataStandardInterface.service';
import { MachineData, SiteData } from 'src/app/external-user/batch-manager/models/script-manager-data';
import { convertAuthEnumtoPsisEnum } from 'src/app/external-user/batch-manager/services/psis-enum-mapping';

@Component({
  selector: 'app-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.scss'],
})
export class CustomerSurveyComponent implements AfterViewInit {

  @ViewChild('formDirective', { static: true }) private formDirective: NgForm;

  formGroup: FormGroup;
  organization: Organization;
  machinedata: MachineData;
  sitedata: SiteData;
  pgAssets: Asset[] = [];
  ltMachineTypes: any[] = [];
  sfAssets: any[] = [];
  machinelist: any[] = [];
  orgId: string;
  orgType: string;
  orgSalesforceId: string;
  selectedMachineTypeText: string;

  constructor(
    private organizationService: OrganizationService,
    private assetService: AssetService,
    private orgAssetService: OrgAssetService,
    private toastService: ToastService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
  ) {

    this.formGroup = new FormGroup({
      FriendlyName: new FormControl('', [Validators.required]),
      SerialNumber: new FormControl('', [Validators.required]),
      MachineType: new FormControl('', [Validators.required]),
      ScriptRouting: new FormControl('')
    });
  }

  ngAfterViewInit(): void {
    this.getMachineTypes();
    if (this.route.parent && typeof this.route.parent !== 'undefined') {
      this.route.parent.params.subscribe(({ id }) => {
        this.loadOrganization(id);
      });
    } else {
      this.authService.profile$.subscribe((profile) => {
        this.orgId = profile.organizationId;
        this.orgSalesforceId = profile.organizationSfId;
        this.orgType = profile.orgType;

        this.loadOrganization(this.orgId);
      });
    }

  }

  getMachineTypes() {
    this.assetService.getActiveMachinesType()
      .subscribe((list) => {
        this.ltMachineTypes = list;
        this.reset();
        this.ngxLoader.stop();
      }, () => {
        this.reset();
        this.ngxLoader.stop();
      });
  }

  loadOrganization(id) {
    const loadingKey = 'loadOrganizationKey';
    this.ngxLoader.start(loadingKey);
    this.organizationService.getByID(id).subscribe((org) => {
      this.ngxLoader.stop(loadingKey);
      this.organization = org;
      this.orgId = this.organization.organizationId;
      this.orgSalesforceId = this.organization.salesforceId;
      this.parataStandardInterfaceService.getAllMachines(this.orgId).subscribe((response) => {
        this.machinelist = response;
        this.loadAssetsFromPostgreSQL(loadingKey);
      }, (error) => {
          this.machinelist = [];
          this.loadAssetsFromPostgreSQL(loadingKey);
      }
      );
    }, (response) => this.toastService
      .stopLoadingAndShowError(response, 'Error while loading organization!', loadingKey),
    );
  }

  loadAssetsFromPostgreSQL(loadingKey) {
    this.assetService.getAssetByOrgId(this.orgId)
      .subscribe((assets) => {
        if (assets) {          
          this.pgAssets = assets;
          this.pgAssets.map(asset => {
            if (asset.configurations) {
              asset.configurations.machineType = this.ltMachineTypes.find(machType => machType.machineTypeId == asset.configurations.machineTypeID).name;
            }
            const isexist = this.machinelist.find(machlist => machlist.assetId == asset.assetId);
            if (isexist)
              asset.isregistered = true;
            else
              asset.isregistered = false;
          });
        }
        else
        {
          this.pgAssets = [];
        }
        this.reset();
        this.ngxLoader.stop(loadingKey);
      }, () => {
        this.reset();
        this.pgAssets = [];
        this.ngxLoader.stop(loadingKey);
      });
  }

  deleteOrgAsset(asset) {
    const loadingKey = 'deleteAssetById';
    this.ngxLoader.start(loadingKey);
    this.orgAssetService.deleteAssetById(this.orgId, asset?.assetId)
      .subscribe((assets) => {
        //make a call to delete machine only if registered for script routing
        if(asset?.isregistered) {
          this.deleteMachine(asset?.name, asset?.machineNo, true, loadingKey);
        }
        this.reset();
        this.toastService.openToast('System deleted!', constants.ToastPanelType.done);
        this.loadAssetsFromPostgreSQL(loadingKey);
      }, () => {
        this.reset();

        this.toastService.stopLoadingAndShowError('Error while deleting asset!', loadingKey);
        this.loadAssetsFromPostgreSQL(loadingKey);
      });
    this.ngxLoader.stop(loadingKey);
  }

  registerDevicetoPSIS(event, asset) {
    this.machinedata = {
      assetId: asset.assetId,
      name: asset.name,
      deviceId: asset.machineNo,
      machineType: convertAuthEnumtoPsisEnum(asset.configurations.machineTypeID),
    };

    if (event.checked) {
      const loadingKey = 'registerDevicetoPSISKey';
      this.ngxLoader.start(loadingKey);
      this.parataStandardInterfaceService.getSite(this.organization.organizationId).subscribe((response) => {
          this.createMachine(asset.name, loadingKey);
      }, (error) => {
        if (error.status == 404) {
          this.createSitewithMachine(asset.name, loadingKey);
        }
        else {
          this.toastService.stopLoadingAndShowError(error, 'Error while enabling script routing for ' + asset.name, loadingKey)
        }
      }
      );
    }
    else {
      const loadingKey = 'deregisterDevicetoPSISKey';
      this.ngxLoader.start(loadingKey);
      this.deleteMachine(asset.name, asset.machineNo, false, loadingKey);
    }
  }

  createSitewithMachine(assetname, loadingKey) {

    this.sitedata = {
      id: this.organization.organizationId,
      name: this.organization.name,
      isParentAccount: this.organization.is_Parent_Account,
      parentAccountId: this.organization.parent_Account_Id,
      salesforceId: this.organization.salesforceId,
      salesforceName: this.organization.salesforceName,
      customerNo: this.organization.customerNo,
    };

    this.parataStandardInterfaceService.createSite(this.sitedata).subscribe((response) => {
      this.createMachine(assetname, loadingKey);
    }, (error) => this.toastService
      .stopLoadingAndShowError(error, 'Error while enabling script routing for ' + assetname, loadingKey),
    );
  }

  createMachine(assetname, loadingKey) {
    this.parataStandardInterfaceService.createMachine(this.organization.organizationId, this.machinedata).subscribe((response) => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('Script routing enabled for ' + assetname, constants.ToastPanelType.done);
    }, (error) => this.toastService
      .stopLoadingAndShowError(error, 'Error while enabling script routing for ' + assetname, loadingKey),
    );
  }

  deleteMachine(assetname, assetmachineNo, isAssetdelete, loadingKey) {

    this.parataStandardInterfaceService.deleteMachine(this.organization.organizationId, assetmachineNo).subscribe((response) => {
      this.ngxLoader.stop(loadingKey);
      if (!isAssetdelete)
        this.toastService.openToast('Script routing disabled for ' + assetname, constants.ToastPanelType.done);
    }, (error) => this.toastService
      .stopLoadingAndShowError(error, 'Error while disabling script routing for ' + assetname, loadingKey),
    );
  }

  getInnerText(innerText) {
    this.selectedMachineTypeText = innerText;
  }

  SerialNoUniqueValidation() {
    let serialno: any;
    serialno = this.formGroup.get('SerialNumber').value;

    if (this.pgAssets != null && this.pgAssets.length > 0) {
      if (this.pgAssets.filter((x) => x.machineNo.toLocaleLowerCase() == serialno.toLowerCase()).length>0) {
                this.toastService.openToast('Serial number should be Unique!', ToastPanelType.error);
        return false;
      }
    }
    return true;
  }
  FriendlyNameUniqueValidation() {
    let frdlyName: any;
    frdlyName = this.formGroup.get('FriendlyName').value;

    if (this.pgAssets != null && this.pgAssets.length > 0) {
      if (this.pgAssets.filter((x) => x.name.toLocaleLowerCase() == frdlyName.toLowerCase()).length>0) {
                this.toastService.openToast('Friendly Name should be Unique!', ToastPanelType.error);
        return false;
      }
    }
    return true;
  }

  submit() {

    if (this.formGroup.invalid) {
      this.formGroup.get('FriendlyName').markAsTouched();
      this.formGroup.get('MachineType').markAsTouched();
      this.formGroup.get('SerialNumber').markAsTouched();
      this.toastService.openToast('Please complete the form before submit!', ToastPanelType.error);
      return;
    }
    if (!this.FriendlyNameUniqueValidation() )  {
      return;
    }
    if (!this.SerialNoUniqueValidation() )  {
      return;
    }

    const loadingKey = 'save';
    this.orgAssetService.createOrgAsset(this.orgId, {
      assetTypeId: 5,
      name: this.formGroup.get('FriendlyName').value,
      description: this.formGroup.get('FriendlyName').value,
      machineNo: this.formGroup.get('SerialNumber').value,
      configurations: {
        machineTypeID: this.formGroup.get('MachineType').value,
        operatingSystem: this.selectedMachineTypeText
      }
    }).subscribe(() => {
     this.toastService.openToast('System created!', constants.ToastPanelType.done);
      this.loadOrganization(this.orgId);
    },
      (response) => { this.toastService
        .stopLoadingAndShowError(response, 'Error while saving assets!', loadingKey);
        this.loadOrganization(this.orgId); },
    );
    this.ngxLoader.stop(loadingKey);
  }

  reset() {
    this.formDirective.resetForm();
    this.formGroup.reset({
      FriendlyName: '',
      SerialNumber: '',
      MachineType: []
    });
  }
}
