import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-drug-management',
  templateUrl: './drug-management.component.html',
  styleUrls: ['./drug-management.component.scss']
})
export class DrugManagementComponent implements AfterViewInit {

  constructor(private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private ngxLoader: NgxUiLoaderService){}


  selected: string = '1';
  ngAfterViewInit() {
  }


}
