<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
         <h5>QUARANTINED</h5>
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
            <mat-icon (click)="this.dialogRef.close()" class="cross-wizard">
                <img src="/assets/img/icons/ico.16.clear.svg" alt="">
            </mat-icon>
        </div>
    </div>
</div>
<div class="sticky_search">
  <div class="p-16 bg-white">
      <div class="flex justify-between content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row">
          <div class="search-wrapper" style="padding: 9px">
              <div class="search-container">
                  <app-icon icon="search" class="icon_search"></app-icon>
                  <input class="search-input" inputId="searchcustomers-input" (change)="search()"   minLengthToSearch="1" placeholder="Scan or search by Drug Number, Drug Name or UPC" [(ngModel)]="searchText"  id="search_customers">

              </div>
          </div>
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
          <span class="label">Hide Permanent Quarantine</span>
            </div>
            <mat-form-field>
                <mat-select [(ngModel)]="permanentQuarantine"  (selectionChange)="search()" ngDefaultControl>
                    <mat-option *ngFor="let data of permanentQuarantineList" [value]="data.value">{{data?.label}}</mat-option>
                  </mat-select>
          </mat-form-field>
         
          </div>
         
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
          <span class="label">Data Source</span>
            </div>
            <mat-form-field>
              <mat-select  [(ngModel)]="dataSourceId"  (selectionChange)="search()" ngDefaultControl>
                <mat-option *ngFor="let data of listOfDataSourceFilter" [value]="data.key">{{data?.value}}</mat-option>
              </mat-select>
          </mat-form-field>
         
          </div>

          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
          <span class="label">Country</span>
            </div>
            <mat-form-field>
              <mat-select  [(ngModel)]="countryId"  (selectionChange)="search()" ngDefaultControl>
                <mat-option *ngFor="let data of listOfCountry" [value]="data.key">{{data?.value}}</mat-option>
              </mat-select>
          </mat-form-field>
         
          </div>

      </div>
  </div>
</div>
<div class="mat-elevation-z8" style="overflow: scroll;">
  <table  mat-table [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
      </ng-container>

      <!-- NDC Column -->
      <ng-container matColumnDef="ndc">
          <th mat-header-cell *matHeaderCellDef> Drug Number </th>
          <td mat-cell *matCellDef="let element"> {{element?.ndc}} </td>
      </ng-container>
    <!-- Country Column -->
    <ng-container matColumnDef="countryCode">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Country </th>
        <td mat-cell *matCellDef="let element" width ="10%">     <p style="display: inline-block; vertical-align: middle;">
            <img *ngIf="element?.countryCode?.length>0"  [src]="element?.countryCode === 'USA' ? '/assets/img/country/usa.png': element?.countryCode === 'CAN'? '/assets/img/country/canadian.webp':'/assets/img/no-image.png'" style="height: 25px; width: 25px; vertical-align: middle;" />
              {{element?.countryCode}}
        </p>
          </td>
    </ng-container>

     <!-- Data Source Column -->
     <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef> Source </th>
      <td mat-cell *matCellDef="let element"> {{element?.source}} </td>
  </ng-container>

      <!-- System Column -->
      <ng-container matColumnDef="system">
          <th mat-header-cell *matHeaderCellDef> System </th>
          <td mat-cell *matCellDef="let element"> {{element?.system}} </td>
      </ng-container>

      <!-- Strength Column -->
      <ng-container matColumnDef="strength">
          <th mat-header-cell *matHeaderCellDef> Strength </th>
          <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
      </ng-container>


      <!-- Manufacturer Column -->
      <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef> Manufacturer</th>
          <td mat-cell *matCellDef="let element"> {{element?.manufacturer}} </td>
      </ng-container>
       <!-- Priority Column -->
       <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef> Priority</th>
          <td mat-cell *matCellDef="let element"> {{element?.priority}} </td>
      </ng-container>

      <!-- Requestor Column -->
      <ng-container matColumnDef="requestor">
          <th mat-header-cell *matHeaderCellDef>Requestor </th>
          <td mat-cell *matCellDef="let element"> {{element?.requestor}} </td>
      </ng-container>

      <!-- Age Column -->
      <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef> Age </th>
          <td mat-cell *matCellDef="let element"> {{element?.age}} </td>
      </ng-container>
      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef  width ="5%">  </th>
        <td mat-cell *matCellDef="let element" width ="5%">
           <mat-icon (click)="redirectToDetailsPage(element.ndc,element.dataSourceId)"  style="margin-bottom: 2px;">
          <img src="/assets/img/icons/ico.12.arwrgt.svg" alt="">
      </mat-icon>
    </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>


  </table>
   <table>
      <tr *ngIf="dataSource?.data.length==0">
          <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
              No Records Found!
          </td>
      </tr>
  </table>

</div>
<mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">

</mat-paginator>


<mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
<button mat-button class="btn-blue-default btn-md" (click)="dialogRef.close()" id="Cancel" aria-label="Cancel" type="button">Cancel</button>
</mat-dialog-actions>

</div>


