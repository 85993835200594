import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as constants from 'src/app/config/app-constants';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../../../shared/services/toast.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArrayName, FormArray } from '@angular/forms';
import { OrderDetail } from '../../models/order-detail';
import { OrderService } from '../../services/order.service';
import { InventoryService } from '../../services/inventory.service';
import { InventoryStock } from '../../models/inventory-stock';
import { SubProduct } from '../../models/sub-product';
import { PackageSize } from '../../models/package-size';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';


@Component({
  selector: 'app-consumable-order-modify',
  templateUrl: './consumable-order-modify.component.html',
  styleUrls: ['./consumable-order-modify.component.scss']
})
export class ConsumableOrderModifyComponent implements OnInit {
  //#region Fields
  productDataSource: MatTableDataSource<Product>;
  orderTableColumns: string[] = ['productName', 'partNumber', 'packageSize', 'quantity'];
  formGroup: FormGroup;
  notificaitonId: string;
  products: Product[];
  subProducts: SubProduct[];
  packageSizes: PackageSize[];
  orderDetail: OrderDetail[] = [];
  inventoryStockThreshold: InventoryStock;
  minDate = new Date();
  maxDate = new Date();
  defaultMachineTypeATP = 1;
  //#endregion

  //#region Constructor & ngOnInit
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private productService: ProductService,
    private ordertService: OrderService,
    private inventoryService: InventoryService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.notificaitonId = this.route.snapshot.queryParamMap.get('rqid');
    this.formGroup = this.formBuilder.group({
      // order adjustment form controls
      orderFormControls: this.formBuilder.array([]),
      pouchesCount: new FormControl('', Validators.required),
      delayOrderDate: new FormControl('')
    });
    const today = new Date();
    this.minDate.setDate(today.getDate() + 1); // delay the order by min 1 day
    this.maxDate.setDate(today.getDate() + 30);// delay the order by max 30 days

    // get products like Paper & Ribbon
    this.ngxLoader.start();
    this.productService.getActiveProducts().subscribe(res => {
      const controlArry = this.formGroup.controls.orderFormControls as FormArray;
      controlArry.clear();
      res.forEach(() => {
        controlArry.push(this.createOrderFormGroup());
      });
      // sort the products in ascending order like Paper, Ribbon
      const sortedResult = res.sort((cur, next) => cur.name < next.name ? -1 : 1);
      this.products = sortedResult;
      this.productDataSource = new MatTableDataSource(sortedResult);
      this.getSubProduct();
    }, () => { this.ngxLoader.stop(); });
    this.getInventoryStockThreshold();
  }

  //#endregion

  //#region Functions
  // get sub products like Partnumber & Description
  getSubProduct() {
    this.productService.getSubProducts().subscribe(res => {
      this.subProducts = res;
      if (this.productDataSource) {
        this.productDataSource.data.forEach(product => {
          product.subProducts = res.filter(sp => sp.productId === product.productId);
        });
        this.getPackageSize();
      }
    }, () => { this.ngxLoader.stop(); });
  }
  // get package size of each pouch
  getPackageSize() {
    this.productService.getPackageSize().subscribe(res => {
      this.packageSizes = res;
      this.getActiveOrderDetailsByOrganization();
    }, () => { this.ngxLoader.stop(); });
  }
  getActiveOrderDetailsByOrganization() {
    this.ordertService.getActiveOrderDetailsByOrg().subscribe(resOrderDetails => {
      const orderAdjustmentDetails = [];
      if (resOrderDetails && resOrderDetails.length > 0) {
        if (this.products && this.subProducts && this.packageSizes) {
          for (let count = 0; count < resOrderDetails.length; count++) {
            const pkg = this.packageSizes.filter(pkg => pkg.packageSizeId.trim() === resOrderDetails[count].packageSizeId.trim());
            if (pkg && pkg.length > 0) {
              const sp = this.subProducts.filter(sp => sp.subProductId.trim() === pkg[0].subProductId.trim());
              if (sp && sp.length > 0) {
                const product = this.products.filter(p => p.productId.trim() === sp[0].productId.trim());
                if (product && product.length > 0) {
                  const array = {
                    orderPackageSize: pkg[0].packageSizeId,
                    orderSubProduct: sp[0].subProductId,
                    orderQuantity: resOrderDetails[count].quantity,
                    orderId: resOrderDetails[count].orderId,
                    productName: product[0].name
                  };
                  orderAdjustmentDetails.push(array);
                }
              }
            }
          }
        }
        // disable the form if the order adjustments expired - 24hours.
        if (!resOrderDetails[0].enabled) {
          this.formGroup.disable();
        }
        const sortedDetails = orderAdjustmentDetails.sort((cur, next) => cur.productName < next.productName ? -1 : 1);
        this.formGroup.patchValue({
          orderFormControls: sortedDetails
        });
        for (let i = 0; i < sortedDetails.length; i++) {
          this.subProductSelectionChanged(i, sortedDetails[i].orderSubProduct);
        }
      }
      else {
        this.ngxLoader.stop();
        this.navigateToHomePage(false, true);
      }
      this.ngxLoader.stop();
    }, () => {
      this.ngxLoader.stop();
      this.navigateToHomePage(false, true);
    });
  }
  getInventoryStockThreshold() {
    this.inventoryService.getInventoryStockByOrg(this.defaultMachineTypeATP).subscribe(res => {
      this.inventoryStockThreshold = res;
      this.formGroup.patchValue({
        pouchesCount: res.thresholdQty
      });
    });
  }
  createOrderFormGroup(): FormGroup {
    return this.formBuilder.group({
      orderSubProduct: new FormControl('', Validators.required),
      orderPackageSize: new FormControl('', Validators.required),
      orderQuantity: new FormControl('', Validators.required),
      orderId: new FormControl()
    });
  }
  subProductSelectionChanged(index: number, subid: string = '') {
    if (this.productDataSource != null && this.productDataSource && this.productDataSource.data.length > 0) {
      if (subid === '')
        {subid = (this.formGroup.get('orderFormControls') as FormArray).controls[index].get('orderSubProduct').value;}
      if (this.packageSizes) {
        this.productDataSource.data[index].packageSizes = this.packageSizes.filter(pkg => pkg.subProductId === subid);
      }
    }
  }
  saveChanges() {
    if (this.formGroup && !this.formGroup.pristine) {
      const threshold = this.formGroup.controls.pouchesCount;
      if (threshold.dirty && threshold.valid) {
        if (this.inventoryStockThreshold.thresholdQty != threshold.value) {
          this.ngxLoader.start();
          this.inventoryStockThreshold.thresholdQty = threshold.value;
          // update inventory threshold
          this.inventoryService.updateInventoryStockThreshold(this.inventoryStockThreshold.organizationId, this.defaultMachineTypeATP, this.inventoryStockThreshold).subscribe(res => {
            this.ngxLoader.stop();
          }, () => this.ngxLoader.stop());
        }
      }
      const formArray = this.formGroup.controls.orderFormControls as FormArray;
      this.ngxLoader.start();
      this.orderDetail = [];
      for (let count = 0; count < formArray.controls.length; count++) {
        const detail = new OrderDetail();
        detail.orderId = formArray.controls[count].get('orderId').value;
        detail.packageSizeId = formArray.controls[count].get('orderPackageSize').value;
        detail.quantity = formArray.controls[count].get('orderQuantity').value;
        this.orderDetail.push(detail);
      }
      if (this.orderDetail.length > 0) {
        const orderId = this.orderDetail[0].orderId;
        // update order details-quantity,package size
        this.ordertService.updateOrderDetails(orderId, this.orderDetail).subscribe(res => {
          if (!this.formGroup.controls.delayOrderDate.pristine) {
            const dateSelected = new Date(this.formGroup.controls.delayOrderDate.value);
            const delayDate = `${dateSelected.getFullYear()}-` +
              `${('0' + (dateSelected.getMonth() + 1)).slice(-2)}-` +
              `${('0' + dateSelected.getDate()).slice(-2)} 00:00:00`;
            // update delay order
            this.ordertService.updateDelayOrder(orderId, encodeURI(delayDate)).subscribe(res => {
              this.navigateToHomePage();
            }, () => {
              this.toastService.openToast('Update order delay failed!', constants.ToastPanelType.error);
            });
          }
          else {
            this.navigateToHomePage();
          }
          this.ngxLoader.stop();
        }, () => {
          this.toastService.openToast('Order detail update quantity failed!', constants.ToastPanelType.error);
          this.ngxLoader.stop();
        });
      }

    }
    else {
      this.navigateToHomePage(false, false);
    }
  }
  navigateToHomePage(success: boolean = true, error: boolean = false) {
    if (success)
      {this.toastService.openToast('Order adjustments done successfully.', constants.ToastPanelType.done);}
    if (error)
      {this.toastService.openToast('Currently there are No Active Orders.', constants.ToastPanelType.error);}
    this.router.navigateByUrl(`/${constants.routePaths.PHARMACY}`);
  }
  disablePackageSizeDropwDown(rowIndex: number): boolean {
    return this.formGroup.disabled ? true :
      (this.formGroup.get('orderFormControls') as FormArray).controls[rowIndex].get('orderSubProduct').invalid;
  }
  disableSaveChanges(): boolean {
    if (this.formGroup)
      {return this.formGroup.disabled ? true : this.formGroup.invalid;}
  }
  //#endregion
}
