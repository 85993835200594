import { ExportFileInfo } from './../../models/export-file-info';
import { ExcelExportService } from './../../services/excel-export-service.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AnalysisDrugList, AtpDrugList, CellLocatedSheet, CommonEntity, CustomerDrugCellLocationAtp, CustomerDrugInfo, DrugCellLog, DrugSystem, ListOfDrugLocationAtp, NotOralDrugList } from '../../models/customer-drug-info';
import { IMToolService } from '../../services/im-tool.service';
import { ImToolAtpService } from '../../services/im-tool-atp.service';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from "src/app/config/app-constants";
import { ImportWizardCddbComponent } from '../import-wizard-cddb/import-wizard-cddb.component';
import { ImportWizardComponent } from '../import-wizard/import-wizard.component';
import { ImportWizardDrugMovingComponent } from '../import-wizard-drug-moving/import-wizard-drug-moving.component';
import { MoveDrug } from '../../models/move-drugs';
import { Observable, forkJoin } from 'rxjs';
import { Medication, MedicationXMLBrand, MedicationXml } from '../../models/medication-export';


@Component({
  selector: 'app-atp-drug-list',
  templateUrl: './atp-drug-list.component.html',
  styleUrls: ['./atp-drug-list.component.scss']
})

export class AtpDrugListComponent implements OnInit {
  fileInfo: ExportFileInfo;
  searchText: string = '';
  drugList: CustomerDrugInfo;
  drugSystemList: DrugSystem[] = [];
  celLogList: DrugCellLog[] = [];
  drugCellSize: CommonEntity[] = [];
  drugCellLocationUpdateList: ListOfDrugLocationAtp;
  drugCellLocationList: CustomerDrugCellLocationAtp[] = [];
  analysisDrugList: AnalysisDrugList[] = [];
  analysisDrugListCsv: AnalysisDrugList[] = [];
  notAvailableDrugList: AtpDrugList[] = [];
  excludedDrugList: AtpDrugList[] = [];
  notOralDrugList: NotOralDrugList[] = [];
  cellLocatorSheetList: CellLocatedSheet[] = [];
  //#region  Table datasourse
  dataSource = new MatTableDataSource<AtpDrugList>();
  dataSourceOral = new MatTableDataSource<AtpDrugList>();
  dataSourceExcluded = new MatTableDataSource<AtpDrugList>();
  dataSourceNotAbailable = new MatTableDataSource<AtpDrugList>();
  dataSourcecellLocatorSheet = new MatTableDataSource<CellLocatedSheet>();
  //#endregion
  @ViewChild(MatTable, { static: true }) drugTabletable: MatTable<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("table", { read: MatSort, static: true }) sort: MatSort;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;
  @ViewChild("table4", { read: MatSort, static: true }) sort4: MatSort;
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild("TABLE1") table1: ElementRef;
  @ViewChild("TABLE2") table2: ElementRef;
  @ViewChild("TABLE3") table3: ElementRef;
  @ViewChild("TABLE4") table4: ElementRef;
  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();

  selectedDrugSystem: number = 1;
  xmlAnalysisFormatData: MedicationXml = new MedicationXml();
  orderOrUsage: string = '# of Orders';
  phllDispLable: string = 'Pills Disp';
  analysisData: number;
  ndcOfMultippleLocation = new Array<string>();
  // Selected Row Color Change key
  selectedRow;

  isShowCellLocOnMouseHover: boolean = false;
  deviceId: number;
  customerName: string = "";
  targetInstallDate: string = "";
  subDeviceTypeName: string = "";
  locationAnalysis: string = "";
  primaryLabelName: string = "";
  secondaryLabelName: string = "";
  deviceName: string = "";

  //#region drawer count variables
  smartCount: number;
  regularCount: number;
  highCapCount: number;
  noDrawerCount: number;
  remainingSmartCount: number;
  remainingHighCapCount: number;
  remainingRegularCount: number;
  isMultipleCellLocation: boolean = false;

  // DynamicColumn Show

  isApwColumnShow: boolean = false;
  isMarkingColumnShow: boolean = false;
  isQtyInCanisterShow: boolean = false;
  isPackQtyShow: boolean = false;
  isDiscriptionShow: boolean = false;
  isLotShow: boolean = false;
  isStrengthShow: boolean = false;
  isShapeShow: boolean = false;
  isColorShow: boolean = false;
  isBarCodeShow: boolean = false;
  isVolumeShow: boolean = false;
  isPillVolumeShow: boolean = false;
  isActiveShow: boolean = false;
  isUnRegulatedColumnShow: boolean = false;

  // Hide column

  isHideMfgExpiration: boolean = true;
  isHideCostPerPill: boolean = true;
  isHidecontrolSched: boolean = true;
  isHideComment: boolean = true;
  isHideDontMix: boolean = true;
  isHideMin: boolean = true;
  isHideMax: boolean = true;
  isHideInvoice: boolean = true;
  isHideSmartId: boolean = true;
  isHideAssignedLot: boolean = true;
  isHideAssignedExp: boolean = true;
  isHideDistributor: boolean = true;
  //#endregion

  //#region Table colums Array
  displayedColumns: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    "customerDrugNdc",
    "barcode",
    "primaryDrugName",
    "secondaryDrugName",
    "mfgExpiration",
    "strength",
    "location",
    "pillForm",
    "volume",
    "lot",
    "qtyInCanister",
    "atpCanisterNumber",
    "color",
    "shape",
    "marking",
    "description",
    "packQuantity",
    "costPerPill",
    "apw",
    "controlSched",
    "comment",
    "doNotMix",
    "active",
    "min",
    "max",
    "invoiceNumber",
    "smartId",
    "genericSequenceNumber",
    "assignedLot",
    "assignedExpiration",
    "distributor",
    "unitUsage",
    "drawer",
    "drawerType",
    "thirtyDramCapacity",
    "pillVolume",
    // "drugStatus",
    "isUnRegulated",
    "action"
  ];

  displayedColumnsNotOral: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    "customerDrugNdc",
    "barcode",
    "primaryDrugName",
    "secondaryDrugName",
    "mfgExpiration",
    "dosageForm",
    "strength",
    "location",
    "pillForm",
    "volume",
    "lot",
    "qtyInCanister",
    "atpCanisterNumber",
    "color",
    "shape",
    "marking",
    "description",
    "packQuantity",
    "costPerPill",
    "apw",
    "controlSched",
    "comment",
    "doNotMix",
    "active",
    "min",
    "max",
    "invoiceNumber",
    "smartId",
    "genericSequenceNumber",
    "assignedLot",
    "assignedExpiration",
    "distributor",
    "unitUsage",
    "drawer",
    "drawerType",
    "thirtyDramCapacity",
    "pillVolume",
    // "drugStatus",
    "isUnRegulated",
    "action"
  ];
  displayedColumnsExcluded: string[] = [
    "checkbox",
    "topOrder",
    "excludedReason",
    "ndc",
    "customerDrugNdc",
    "barcode",
    "primaryDrugName",
    "secondaryDrugName",
    "mfgExpiration",
    "strength",
    "location",
    "pillForm",
    "volume",
    "lot",
    "qtyInCanister",
    "atpCanisterNumber",
    "color",
    "shape",
    "marking",
    "description",
    "packQuantity",
    "costPerPill",
    "apw",
    "controlSched",
    "comment",
    "doNotMix",
    "active",
    "min",
    "max",
    "invoiceNumber",
    "smartId",
    "genericSequenceNumber",
    "assignedLot",
    "assignedExpiration",
    "distributor",
    "unitUsage",
    "drawer",
    "drawerType",
    "thirtyDramCapacity",
    "pillVolume",
    // "drugStatus",
    "action"
  ];

  //#endregion

  customerId: string;

  // current which tab selected
  tabSelected: number = 0;
  currentFilterSize: number = 0;


  // All selected checkbox propertities
  isAnyDrugSelected: boolean = false;

  //isScrollButtonShow:boolean=false;

  moveDrug: MoveDrug = {} as MoveDrug;


  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(
    private dialog: MatDialog,
    private imtoolService: IMToolService,
    private imtoolAtpService: ImToolAtpService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private activateRoute: ActivatedRoute,
    private toastService: ToastService,
    private exportService: ExcelExportService
  ) {

  }

  ngOnInit(): void {
    
    this.drugSystemList.push(
      {
        id: 1,
        value: 2,
      },
      {
        id: 2,
        value: 5,
      }
    );



    this.activateRoute.paramMap.subscribe((params) => {
      this.customerId = params.get("customerid");
    });

    this.getRecommendedDrugData(this.customerId, 0);
    this.analysisData = this.dataSource.data.length;
    this.getAvailableCellLocation(this.customerId);


  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSourceNotAbailable.sort = this.sort1;
    this.dataSourceExcluded.sort = this.sort2;
    this.dataSourceOral.sort = this.sort3;
  }


  exportTheCanisterOrderFile() {
    let data: any[] = [];
    this.analysisDrugList.forEach(x => {
      if (x.canisterLocation !== '') {
        data.push({
          'NDC-9': x.ndc.substring(0, 9),
          'Canister': x.canisterLocation.padStart(3,"0"),
          'Drug Name': x.name && x.name?.length > 0 ? x.name : x.customerDrugName,
          'Strength': x.strength,
          'Manufacturer': x.manufacturer,
          'NDC-11': x.ndc,
          'CustomerNDC': x.customerDrugNdc,
          'CItem#': x.atpCanisterNumber,
          'OTC/VIT': x.otcOrVitamin,
        })
      }

    })

    this.fileInfo = {
      fileName: `Canister Order XLSX-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates: [{
        data: data,
        sheetName: `Canister Order`
      }]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }
  
  excelExportFromAtpTable() {
    let analysisDrugData: any[] = [];

    this.analysisDrugList.forEach(x => {
      analysisDrugData.push({
        'NDC': '^' + x.ndc,
        'CustomerNDC': '^' + x.customerDrugNdc,
        'Barcode': x.barcode && x.barcode.length > 0 ? '^' + x.barcode : '^' + x.ndc,
        'PrimaryMedName': x.primaryDrugName,
        'SecondaryMedName': x.secondaryDrugName,
        'MFG': x.manufacturer,
        'Strength': x.strength,
        'Location': this.formatCanisterLocation(x.canisterLocation),
        'Pill Form': x.pillForm?.includes(',')?x.pillForm.split(',').join(''):x.pillForm,
        'Volume': 0,
        'Lot': x.lot,
        'MFG Expiration': x.mfgExpiration,
        'QtyInCanister': x.qtyInCanister > 0 ? x.qtyInCanister : '',
        'Color': x.color?.includes(',')?x.color.split(',').join(''):x.color,
        'Shape': x.shape,
        'Marking': x.marking,
        'Description': x.description?.includes(',')?x.description.split(',').join(''):x.description,
        'PackQuantity': x.packQuantity,
        'CostPerPill': 0,
        'APW': x.apw,
        'Control Sched': 0,
        'Comment':  x.customerComment,
        'Do Not Mix': 0,
        'Active': x.active && x.active?.toLocaleLowerCase() === 'active' ? 1 : 0,
        'Min': 0,
        'Max': parseInt(x.max.toString()),
        'INVNumber' : x.customerInventoryNumber,
        'Smart Id': '',
        'GCN SEQ': x.gcnSeqInExport.toString().replace('^','').toString(),
        'Assigned Lot': '',
        'Assigned Expiration': '',
        'Distributor': '',
        'PackageVolume': 0,
        'Priority': 0,
        'Image': '',
        'Score': '',
        'ColdPackDictId': '',
        'DrugDoseFormDictid': '',
      })
    })

    // let newDrugData: any[] = [];
     this.notAvailableDrugList.forEach(x => {
 
    analysisDrugData.push({
      'NDC': '^' + x.ndc,
      'CustomerNDC': '^' + x.customerDrugNdc,
      'Barcode': x.barcode && x.barcode.length > 0 ? '^' + x.barcode : '^' + x.ndc,
      'PrimaryMedName': x.primaryDrugName,
      'SecondaryMedName': x.secondaryDrugName,
      'MFG': x.manufacturer,
      'Strength': x.strength,
      'Location': this.formatCanisterLocation(x.canisterLocation),
      'Pill Form': x.pillForm?.includes(',')?x.pillForm.split(',').join(''):x.pillForm,
      'Volume': 0,
      'Lot': x.lot,
      'MFG Expiration': x.mfgExpiration,
      'QtyInCanister': x.qtyInCanister > 0 ? x.qtyInCanister : '',
      'Color': x.color?.includes(',')?x.color.split(',').join(''):x.color,
      'Shape': x.shape,
      'Marking': x.marking,
      'Description': x.description?.includes(',')?x.description.split(',').join(''):x.description,
      'PackQuantity': x.packQuantity,
      'CostPerPill': 0,
      'APW': x.apw,
      'Control Sched': 0,
      'Comment':  x.customerComment,
      'Do Not Mix': 0,
      'Active': x.active && x.active?.toLocaleLowerCase() === 'active' ? 1 : 0,
      'Min': 0,
      'Max': parseInt(x.max.toString()),
      'INVNumber' : x.customerInventoryNumber,
      'Smart Id': '',
      'GCN SEQ': x.gcnSeqInExport.toString().replace('^','').toString(),
      'Assigned Lot': '',
      'Assigned Expiration': '',
      'Distributor': '',
      'PackageVolume': 0,
      'Priority': 0,
      'Image': '',
      'Score': '',
      'ColdPackDictId': '',
      'DrugDoseFormDictid': '',
    })
    })


    // let excludedDrugData: any[] = [];
     this.excludedDrugList.forEach(x => {
      analysisDrugData.push({
        'NDC': '^' + x.ndc,
        'CustomerNDC': '^' + x.customerDrugNdc,
        'Barcode': x.barcode && x.barcode.length > 0 ? '^' + x.barcode : '^' + x.ndc,
        'PrimaryMedName': x.primaryDrugName,
        'SecondaryMedName': x.secondaryDrugName,
        'MFG': x.manufacturer,
        'Strength': x.strength,
        'Location': this.formatCanisterLocation(x.canisterLocation),
        'Pill Form': x.pillForm?.includes(',')?x.pillForm.split(',').join(''):x.pillForm,
        'Volume': 0,
        'Lot': x.lot,
        'MFG Expiration': x.mfgExpiration,
        'QtyInCanister': x.qtyInCanister > 0 ? x.qtyInCanister : '',
        'Color': x.color?.includes(',')?x.color.split(',').join(''):x.color,
        'Shape': x.shape,
        'Marking': x.marking,
        'Description': x.description?.includes(',')?x.description.split(',').join(''):x.description,
        'PackQuantity': x.packQuantity,
        'CostPerPill': 0,
        'APW': x.apw,
        'Control Sched': 0,
        'Comment':  x.customerComment,
        'Do Not Mix': 0,
        'Active': x.active && x.active?.toLocaleLowerCase() === 'active' ? 1 : 0,
        'Min': 0,
        'Max': parseInt(x.max.toString()),
        'INVNumber' : x.customerInventoryNumber,
        'Smart Id': '',
        'GCN SEQ': x.gcnSeqInExport.toString().replace('^','').toString(),
        'Assigned Lot': '',
        'Assigned Expiration': '',
        'Distributor': '',
        'PackageVolume': 0,
        'Priority': 0,
        'Image': '',
        'Score': '',
        'ColdPackDictId': '',
        'DrugDoseFormDictid': '',
      })
    })

    // let notOralDrugData: any[] = [];
     this.notOralDrugList.forEach(x => {
      analysisDrugData.push({
        'NDC': '^' + x.ndc,
        'CustomerNDC': '^' + x.customerDrugNdc,
        'Barcode': x.barcode && x.barcode.length > 0 ? '^' + x.barcode : '^' + x.ndc,
        'PrimaryMedName': x.primaryDrugName,
        'SecondaryMedName': x.secondaryDrugName,
        'MFG': x.manufacturer,
        'Strength': x.strength,
        'Location': this.formatCanisterLocation(x.canisterLocation),
        'Pill Form': x.pillForm?.includes(',')?x.pillForm.split(',').join(''):x.pillForm,
        'Volume': 0,
        'Lot': x.lot,
        'MFG Expiration': x.mfgExpiration,
        'QtyInCanister': x.qtyInCanister > 0 ? x.qtyInCanister : '',
        'Color': x.color?.includes(',')?x.color.split(',').join(''):x.color,
        'Shape': x.shape,
        'Marking': x.marking,
        'Description': x.description?.includes(',')?x.description.split(',').join(''):x.description,
        'PackQuantity': x.packQuantity,
        'CostPerPill': 0,
        'APW': x.apw,
        'Control Sched': 0,
        'Comment':  x.customerComment,
        'Do Not Mix': 0,
        'Active': x.active && x.active?.toLocaleLowerCase() === 'active' ? 1 : 0,
        'Min': 0,
        'Max': parseInt(x.max.toString()),
        'INVNumber' : x.customerInventoryNumber,
        'Smart Id': '',
        'GCN SEQ': x.gcnSeqInExport.toString().replace('^','').toString(),
        'Assigned Lot': '',
        'Assigned Expiration': '',
        'Distributor': '',
        'PackageVolume': 0,
        'Priority': 0,
        'Image': '',
        'Score': '',
        'ColdPackDictId': '',
        'DrugDoseFormDictid': '',
      })
    })

    this.analysisDrugListCsv = [];
    this.analysisDrugListCsv = analysisDrugData.filter(
      (drug, index, self) => index === self.findIndex((d) => d.NDC === drug.NDC)
    );

    this.fileInfo = {
      fileName: `QSQR Druglist CSV-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }`,
      templates:
        [
          {
            data: this.analysisDrugListCsv,
            sheetName: 'Analysis'
          },
        ]
    }
    this.exportService.exportAsCSVFile(this.fileInfo);
  }

   formatCanisterLocation(canisterLocation) {
    let locationString = canisterLocation.toString();
    let formattedCanisterLocation = locationString.padStart(3, '0');
    return formattedCanisterLocation;
}


  customerDrugExport() {
    let customerDrugData: any[] = [];
    this.analysisDrugList.forEach(x => {
      customerDrugData.push({
        'NDC': x.ndc,
        'Customer Drug Id': x.customerDrugNdc,
        'Barcode': x.barcode,
        'CDDB Drug Name': x.primaryDrugName,//
        'Customer Drug Name': x.secondaryDrugName,
        'Strength': x.strength,
        'Location': x.canisterLocation.length > 0 ? x.canisterLocation.padStart(3,'0') : '',
        'Pill Form': x.pillForm,
        'Volume': x.volume,
        'Lot': x.lot,
        'QtyInCanister': x.qtyInCanister,
        '#CItem': x.atpCanisterNumber,
        'Color': x.color,
        'Shape': x.shape,
        'Marking': x.marking,
        'PackQuantity': x.packQuantity,
        'APW': x.apw,
        'Active': x.active,
        'GCN SEQ': x.genericSequenceNumber,
        'Unit Usage': x.usage,
        'Drawer': x.drawer,
        'Drawer Type': x.drawerType,
        '30 Dram': x.thirtyDramCapacity,
        'Pill Volume': x.pillVolume,
        'EXP Date': '',
        'IsUnRegulated': x.isUnregulated,
      })
    })

    this.fileInfo = {
      fileName: `Customer Drug XLSX-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
        this.getCurrentDate()
       }.xlsx`,
      templates:
        [
          {
            data: customerDrugData,
            sheetName: 'Customer Drugs'
          }
        ]
    }
    this.exportService.exportAsExcelFile(this.fileInfo);
  }

  onAllUserPaginateChange() {
    const matTable = document.getElementById('matTable');
    matTable.scrollIntoView();
  }

  allSelectedDrugUnchecked() {
    if (this.drugCellLocationList.length > 0) {
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.fileUploadDrugId == this.drugCellLocationList[i].fileUploadDrugId
        );
        this.dataSource.data[index].checked = false;
      }
      this.drugCellLocationList = [];
    }
  }

  drugCellLocationChecked(row: any, isChecked: boolean) {
    if (this.ndcOfMultippleLocation?.indexOf(row.customerDrugNdc) !== -1) {
      this.isMultipleCellLocation = true;
    }

    if (isChecked) {
      this.drugCellLocationList.push({
        fileUploadDrugId: row.fileUploadDrugId,
        canisterLocation: row.canisterLocation,
        drawerType: row.drawerType,
        drugName: row.customerDrugName,
        processDrugAtpId: row.processDrugAtpId
      });
      this.isAnyDrugSelected = true;
    } else {
      let index = this.drugCellLocationList.findIndex(
        (x) => x.fileUploadDrugId == row.fileUploadDrugId
      );
      this.drugCellLocationList.splice(index, 1);
      if (this.drugCellLocationList.length == 0) {
        this.isAnyDrugSelected = false;
      }
    }
  }

  getAvailableCellLocation(customerId: string) {

    const ATPDRUGDATAAVAILABEL_KEY = 'Atpdrugavailablecelllocation'
    this.ngxLoader.start(ATPDRUGDATAAVAILABEL_KEY)
    this.imtoolAtpService.getAvailableCellLocationAtp(customerId).subscribe(
      (response) => {
        let result = response.data;
        this.ngxLoader.stop(ATPDRUGDATAAVAILABEL_KEY);
        result?.forEach((x) => {
          this.celLogList.push({
            id: x.cellLoc,
            value: x.cellLoc,
          });
        });
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.ngxLoader.stop(ATPDRUGDATAAVAILABEL_KEY);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );

  }

  drugCellLocationChanged(row: any) {

    if (!this.isAlreadyCellLocationUsed(row)) {
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        if (
          this.drugCellLocationList[i].fileUploadDrugId == row.fileUploadDrugId
        ) {
          this.drugCellLocationList[i].canisterLocation = row.canisterLocation;
          break;
        }
      }
    }
  }

  isAlreadyCellLocationUsed(row: any): boolean {
    for (let i = 0; i < this.drugCellLocationList.length; i++) {
      if (this.drugCellLocationList[i].canisterLocation == row.canisterLocation) {
        this.toastService.openToast(
          `drug (${this.drugCellLocationList[i].drugName}) already used this cell location`,
          constants.ToastPanelType.warning,
          2
        );
        this.replaceTheOldCellLocation(row);
        return true;
      }
    }
    return false;
  }



  openAleftWizardForValidateDrug(row: any) {

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: 'Unoccupied Space Warning',
        bodyMessage: 'Are you sure you want to add a regular cell drug to a Super cell location',
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Ok",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result.data) {
        this.replaceTheOldCellLocation(row);
      }
    });
  }


  replaceTheOldCellLocation(row: any) {
    let index = this.dataSource.data.findIndex(
      (x) => x.ndc == row.ndc
    );
    this.dataSource.data[index].cellLoc = row.cellLocOld;
    index = this.drugCellLocationList.findIndex((x) => x.fileUploadDrugId == row.fileUploadDrugId)
    this.drugCellLocationList[index].canisterLocation = row.cellLocOld;
  }



  getCurrentTabSelect(tab: any) {
    this.updateMainDataSourceArray(this.tabSelected);
    this.tabSelected = tab.index;
    this.drugCellLocationList = [];
  }
  updateMainDataSourceArray(tab: number) {
    if (tab === 0) {
      this.getTheSelectedDrugList().forEach(x => {
        let index = this.dataSource.data.findIndex(y => y.processDrugAtpId == x)
        this.dataSource.data[index].checked = false;
        this.isAnyDrugSelected = false;
      });
    }
    else if (tab === 1) {
      this.getTheSelectedDrugList().forEach(x => {
        let index = this.dataSourceNotAbailable.data.findIndex(y => y.processDrugAtpId == x)
        this.dataSourceNotAbailable.data[index].checked = false;
      });
    }
    else if (tab === 2) {
      this.getTheSelectedDrugList().forEach(x => {
        let index = this.dataSourceExcluded.data.findIndex(y => y.processDrugAtpId == x)
        this.dataSourceExcluded.data[index].checked = false;
      });
    }

    else if (tab === 3) {
      this.getTheSelectedDrugList().forEach(x => {
        let index = this.dataSourceOral.data.findIndex(y => y.processDrugAtpId == x)
        this.dataSourceOral.data[index].checked = false;
      });
    }

  }

  getTheSelectedDrugList(): number[] {
    return this.drugCellLocationList.map((x) => x.processDrugAtpId)
  }

  downloadFile(data: number) {
    if (data == 1 || data == 2 || data == 3) {
      this.openAlertWindow(data);
    }
    else if (data == 4) {
      this.downloadXML();
    }
  }

  ExportTOExcel() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.changeStatusToExport();
      this.ngxLoader.stop();
    }, 3000)
  }

  OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "\n<" + prop + ">";

      if (obj[prop] instanceof Array) {
        var arr =obj[prop];
        for (var i = 0; i < arr.length; i++) {
        xml += "\n<" + prop + ">";
        xml += this.OBJtoXML(new Object(arr[i]));
        xml += "\n</" + prop + ">";
        if (i === arr.length - 1) {
          xml += "\n";
        }
      }

      } else if (typeof obj[prop] == "object") {
        xml +=  this.OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');

    return xml
  }
  downloadXML() {
    // analysis drug list
    var fileContents, fileName;

    fileContents = this.OBJtoXML(this.xmlAnalysisFormatData);
    fileName = `SQRMEDExport.xml`;
    var pp = document.createElement('a');
    pp.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(fileContents.trimStart().replace('<ArrayOfMedication>', MedicationXMLBrand)));
    pp.setAttribute('download', fileName);
    pp.click();

  }

  ExportCustomerDrug() {
    this.ngxLoader.start();
    setTimeout(() => {
      this.customerDrugExport();
      this.changeStatusToExport();
      this.ngxLoader.stop();
    }, 1000)
  }


 
  changeStatusToExport() {
    this.ngxLoader.start();
    this.imtoolService.changeStatusToExport(this.customerId).subscribe(
      (result) => {
        this.ngxLoader.stop();
        if (result.statusCode == 200) {
          this.toastService.openToast(
            `Downloaded Successfully. ${result.message}`,
            constants.ToastPanelType.done,
            2
          );
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }
  getRecommendedDrugData(customerId, cellSize) {
    this.currentFilterSize = cellSize;

    const ATPDRUGDATA_KEY = 'Atpdrugdatakey'
    this.ngxLoader.start(ATPDRUGDATA_KEY)
    this.xmlAnalysisFormatData = new MedicationXml();
    this.imtoolService.getRecommendedDrugData(customerId, cellSize).subscribe(
      (response) => {
        let result = response.data
        if (result == null) {
          this.ngxLoader.stop(ATPDRUGDATA_KEY);
          this.toastService.openToast(
            response.message,
            constants.ToastPanelType.error,
            2
          );

        };
        this.orderOrUsage = result.orderOrUsage == true ? '# of Orders' : 'Pills Disp';
        this.phllDispLable = result.orderOrUsage == true ? 'Pills Disp' : '# of Orders';
        console.log(result.arrayOfMedication)
       if(result.arrayOfMedication != null){
        result.arrayOfMedication.medications.forEach((element) => {
          this.xmlAnalysisFormatData.ArrayOfMedication.Medication.push({
              ndc: element.ndc,
              custNdc: element.custNdc,
              barcode: element.barcode,
              genericName: element.genericName,
              brandName: element.brandName,
              manufacturer: element.manufacturer,
              strength: element.strength,
              pillForm: element.pillForm,
              volume: element.volume,
              color: element.color,
              shape: element.shape,
              marking: element.marking,
              description: element.description,
              packQuantity: element.packQuantity,
              costPerPill: element.costPerPill,
              weight: element.weight,
              drugControlLevelDictId: element.drugControlLevelDictId,
              comment: element.comment,
              doNotMix: element.doNotMix,
              active: element.active,
              parlevel: element.parlevel,
              maxParLevel: element.maxParLevel,
              inventoryNumber: element.inventoryNumber,
              gcn_seqNo: element.gcn_seqNo,
              distributor: element.distributor,
              ndcid: element.ndcid,
              drugid: element.drugid,
              manufacturerid: element.manufacturerid,
              dispLocTypeDictId: element.dispLocTypeDictId,
              packageVolume: element.packageVolume,
              priority: element.priority,
              image: element.image,
              score: element.score,
              coldPackDictId: element.coldPackDictId,
              drugDoseFormDictId: element.drugDoseFormDictId,
              dispLoc: {
                DispLocation: element.dispLoc.map(dispLocElement => ({
                    smartId: dispLocElement.smartId,
                    canNumber: dispLocElement.canNumber,
                    dispLocDictId: dispLocElement.dispLocDictId,
                    lotNumber: dispLocElement.lotNumber,
                    expDate: dispLocElement.expDate,
                    canQty: dispLocElement.canQty,
                    aLotNumber: dispLocElement.aLotNumber,
                    aExpDate: dispLocElement.aExpDate,
                    active: dispLocElement.active,
                    dispenselocationid: '',
                    clusterCanStatusDictID: dispLocElement.clusterCanStatusDictID
                }))
            }
          });
      });
       
       }
        this.customerName = result.customerName;
        this.targetInstallDate = result.targetInstallDate;
        this.subDeviceTypeName = result.deviceTypeName;
        this.deviceId = result.deviceId;
        this.deviceName = result.deviceName;
        this.primaryLabelName = result.primaryDrugKey==1?"Customer Drug Name":result.primaryDrugKey==2?"FDB Brand":result.primaryDrugKey==3?"FDB Generic":result.primaryDrugKey==4?"FDB Tall Man Name":"CDDB Drug Name";
        this.secondaryLabelName = result.secondaryDrugKey==1?"Customer Drug Name":result.secondaryDrugKey==2?"FDB Brand":result.secondaryDrugKey==3?"FDB Generic":result.secondaryDrugKey==4?"FDB Tall Man Name":"Customer Drug Name";
        this.smartCount = result.smartCount;
        this.highCapCount = result.highCapCount;
        this.regularCount = result.regularCount;
        this.noDrawerCount = result.remainingCount;
        this.remainingHighCapCount = result.remainingHighCapCount;
        this.remainingSmartCount = result.remainingSmartCount;
        this.remainingRegularCount = result.remainingRegularCount;

        this.analysisDrugList = [];
        this.notAvailableDrugList = [];
        this.excludedDrugList = [];
        this.notOralDrugList = [];
        this.cellLocatorSheetList = [];

        let rowSerial = 1;
        result.drugs?.forEach((x) => {
          if (x.canisterLocation !== undefined || x.canisterLocation !== null) {
            if (x.canisterLocation?.indexOf(',') > -1) {
              var location_array = x.canisterLocation.split(',');
              this.ndcOfMultippleLocation.push(x.customerDrugNdc);

              for (var i = 0; i < location_array.length; i++) {
                let lcoation = Number(location_array[i]);
                this.analysisDrugList.push({
                  checked: false,
                  topOrder: x.topOrder,
                  processDrugAtpId: x.processDrugAtpId,
                  processDrugType: x.processDrugType,
                  fileUploadDrugId: x.processDrugAtpId, // rplace this old code x.fileUploadDrugId,
                  ndc: x.ndc,
                  customerDrugNdc: x.customerDrugNdc,
                  barcode: x.barcode,
                  name: x.name,
                  customerDrugName: x.customerDrugName,
                  mfgExpiration: x.mfgExpiration,
                  strength: x.strength,
                  canisterLocation: location_array[i],
                  pillForm: x.pillForm,
                  volume: x.volume,
                  pillVolume: x.pillVolume,
                  lot: x.lot,
                  qtyInCanister: x.qtyInCanister,
                  color: x.color,
                  shape: x.shape,
                  marking: x.marking,
                  description: x.description,
                  packQuantity: x.packQuantity,
                  costPerPill: x.costPerPill,
                  apw: x.apw,
                  controlSched: x.controlSched,
                  comment: x.comment,
                  doNotMix: x.doNotMix,
                  active: x.active,
                  min: x.min,
                  max: x.max,
                  invoiceNumber: x.invoiceNumber,
                  smartId: x.smartId,
                  genericSequenceNumber: x.genericSequenceNumber,
                  assignedLot: x.assignedLot,
                  assignedExpiration: x.assignedExpiration,
                  distributor: x.distributor,
                  drawerType: x.drawerType,
                  drawer: x.drawer,
                  usage: x.usage,
                  noOfOrder: x.noOfOrder,
                  otc: x.otc,
                  schedule: x.schedule,
                  thirtyDramCapacity: x.thirtyDramCapacity,
                  manufacturer: x.manufacturer,
                  statusForNotOral: x.statusForNotOral,
                  oralSolidStatus: x.oralSolidStatus,
                  nonCountReasonForNotAvailable: x.nonCountReasonForNotAvailable,
                  tab: x.tab,
                  automate: x.automate,
                  excludedReason: x.excludedReason,
                  dnuMaxForExclusion: x.dnuMaxForExclusion,
                  gcValue: x.gcValue,
                  hcGcValue: x.hcGcValue,
                  cellLocOld: x.canisterLocation,
                  atpCanisterNumber: x.atpCanisterNo,
                  primaryDrugName: x.primaryDrugName,
                  secondaryDrugName: x.secondaryDrugName,
                  drugStatus: x.drugStatus,
                  gcnSeqInExport: x.gcnSeqInExport,
                  locationInExport: `^${String(lcoation).padStart(3, '0')}`,
                  locationAnalysis: x.drawerType === 'Smart' ? 'SMART' : location_array[i],
                  customerComment: x.customerComment,
                  customerInventoryNumber: x.customerInventoryNumber,
                  otcOrVitamin: x.otcOrVitamin,
                  isUnregulated: x.isUnregulated
                });
              }
            }

            else {
              this.analysisDrugList.push({
                checked: false,
                topOrder: x.topOrder,
                processDrugAtpId: x.processDrugAtpId,
                processDrugType: x.processDrugType,
                fileUploadDrugId: x.processDrugAtpId, // rplace this old code x.fileUploadDrugId,
                ndc: x.ndc,
                customerDrugNdc: x.customerDrugNdc,
                barcode: x.barcode,
                name: x.name,
                customerDrugName: x.customerDrugName,
                mfgExpiration: x.mfgExpiration,
                strength: x.strength,
                canisterLocation: x.canisterLocation,
                pillForm: x.pillForm,
                volume: x.volume,
                pillVolume: x.pillVolume,
                lot: x.lot,
                qtyInCanister: x.qtyInCanister,
                color: x.color,
                shape: x.shape,
                marking: x.marking,
                description: x.description,
                packQuantity: x.packQuantity,
                costPerPill: x.costPerPill,
                apw: x.apw,
                controlSched: x.controlSched,
                comment: x.comment,
                doNotMix: x.doNotMix,
                active: x.active,
                min: x.min,
                max: x.max,
                invoiceNumber: x.invoiceNumber,
                smartId: x.smartId,
                genericSequenceNumber: x.genericSequenceNumber,
                assignedLot: x.assignedLot,
                assignedExpiration: x.assignedExpiration,
                distributor: x.distributor,
                drawerType: x.drawerType,
                drawer: x.drawer,
                usage: x.usage,
                noOfOrder: x.noOfOrder,
                otc: x.otc,
                schedule: x.schedule,
                thirtyDramCapacity: x.thirtyDramCapacity,
                manufacturer: x.manufacturer,
                statusForNotOral: x.statusForNotOral,
                oralSolidStatus: x.oralSolidStatus,
                nonCountReasonForNotAvailable: x.nonCountReasonForNotAvailable,
                tab: x.tab,
                automate: x.automate,
                excludedReason: x.excludedReason,
                dnuMaxForExclusion: x.dnuMaxForExclusion,
                gcValue: x.gcValue,
                hcGcValue: x.hcGcValue,
                cellLocOld: x.canisterLocation,
                atpCanisterNumber: x.atpCanisterNo,
                primaryDrugName: x.primaryDrugName,
                secondaryDrugName: x.secondaryDrugName,
                drugStatus: x.drugStatus,
                gcnSeqInExport: x.gcnSeqInExport,
                locationInExport: x.canisterLocation === null || x.canisterLocation === 0 || x.canisterLocation === undefined || x.canisterLocation === "" || x.canisterLocation === 'DCR' || x.canisterLocation ==='Tray' ? '2' : `^${String(x.canisterLocation).padStart(3, '0')}`,
                locationAnalysis: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
                customerComment: x.customerComment,
                customerInventoryNumber: x.customerInventoryNumber,
                otcOrVitamin: x.otcOrVitamin,
                isUnregulated: x.isUnregulated
              });
            }
          }

        });


        rowSerial = 1;
        result.notAvailableDrugs?.forEach((x) => {
          this.notAvailableDrugList.push({
            checked: false,
            topOrder: x.topOrder,
            processDrugAtpId: x.processDrugAtpId,
            processDrugType: x.processDrugType,
            fileUploadDrugId: x.processDrugAtpId, // rplace this old code x.fileUploadDrugId,
            ndc: x.ndc,
            customerDrugNdc: x.customerDrugNdc,
            barcode: x.barcode,
            name: x.name,
            customerDrugName: x.customerDrugName,
            mfgExpiration: x.mfgExpiration,
            strength: x.strength,
            canisterLocation: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            pillForm: x.pillForm,
            volume: x.volume,
            pillVolume: x.pillVolume,
            lot: x.lot,
            qtyInCanister: x.qtyInCanister,
            color: x.color,
            shape: x.shape,
            marking: x.marking,
            description: x.description,
            packQuantity: x.packQuantity,
            costPerPill: x.costPerPill,
            apw: x.apw,
            controlSched: x.controlSched,
            comment: x.comment,
            doNotMix: x.doNotMix,
            active: x.active,
            min: x.min,
            max: x.max,
            invoiceNumber: x.invoiceNumber,
            smartId: x.smartId,
            genericSequenceNumber: x.genericSequenceNumber,
            assignedLot: x.assignedLot,
            assignedExpiration: x.assignedExpiration,
            distributor: x.distributor,
            drawerType: x.drawerType,
            drawer: x.drawer,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            otc: x.otc,
            schedule: x.schedule,
            thirtyDramCapacity: x.thirtyDramCapacity,
            manufacturer: x.manufacturer,
            statusForNotOral: x.statusForNotOral,
            oralSolidStatus: x.oralSolidStatus,
            nonCountReasonForNotAvailable: x.nonCountReasonForNotAvailable,
            tab: x.tab,
            automate: x.automate,
            excludedReason: x.excludedReason,
            dnuMaxForExclusion: x.dnuMaxForExclusion,
            gcValue: x.gcValue,
            hcGcValue: x.hcGcValue,
            cellLocOld: x.canisterLocation,
            atpCanisterNumber: x.atpCanisterNo,
            primaryDrugName: x.primaryDrugName,
            secondaryDrugName: x.secondaryDrugName,
            drugStatus: x.drugStatus,
            gcnSeqInExport: x.gcnSeqInExport,
            locationInExport: x.locationInExport,
            locationAnalysis: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            customerComment: x.customerComment,
            customerInventoryNumber: x.customerInventoryNumber,
            otcOrVitamin: x.otcOrVitamin,
            isUnregulated: x.isUnregulated
          });
        });
        rowSerial = 1;
        result.excludedDrugs?.forEach((x) => {
          this.excludedDrugList.push({
            checked: false,
            topOrder: x.topOrder,
            processDrugAtpId: x.processDrugAtpId,
            processDrugType: x.processDrugType,
            fileUploadDrugId:x.processDrugAtpId, // rplace this old code x.fileUploadDrugId,
            ndc: x.ndc,
            customerDrugNdc: x.customerDrugNdc,
            barcode: x.barcode,
            name: x.name,
            customerDrugName: x.customerDrugName,
            mfgExpiration: x.mfgExpiration,
            strength: x.strength,
            canisterLocation: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            pillForm: x.pillForm,
            volume: x.volume,
            pillVolume: x.pillVolume,
            lot: x.lot,
            qtyInCanister: x.qtyInCanister,
            color: x.color,
            shape: x.shape,
            marking: x.marking,
            description: x.description,
            packQuantity: x.packQuantity,
            costPerPill: x.costPerPill,
            apw: x.apw,
            controlSched: x.controlSched,
            comment: x.comment,
            doNotMix: x.doNotMix,
            active: x.active,
            min: x.min,
            max: x.max,
            invoiceNumber: x.invoiceNumber,
            smartId: x.smartId,
            genericSequenceNumber: x.genericSequenceNumber,
            assignedLot: x.assignedLot,
            assignedExpiration: x.assignedExpiration,
            distributor: x.distributor,
            drawerType: x.drawerType,
            drawer: x.drawer,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            otc: x.otc,
            schedule: x.schedule,
            thirtyDramCapacity: x.thirtyDramCapacity,
            manufacturer: x.manufacturer,
            statusForNotOral: x.statusForNotOral,
            oralSolidStatus: x.oralSolidStatus,
            nonCountReasonForNotAvailable: x.nonCountReasonForNotAvailable,
            tab: x.tab,
            automate: x.automate,
            excludedReason: x.excludedReason,
            dnuMaxForExclusion: x.dnuMaxForExclusion,
            gcValue: x.gcValue,
            hcGcValue: x.hcGcValue,
            cellLocOld: x.canisterLocation,
            atpCanisterNumber: x.atpCanisterNo,
            primaryDrugName: x.primaryDrugName,
            secondaryDrugName: x.secondaryDrugName,
            drugStatus: x.drugStatus,
            gcnSeqInExport: x.gcnSeqInExport,
            locationInExport: x.locationInExport,
            locationAnalysis: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            customerComment: x.customerComment,
            customerInventoryNumber: x.customerInventoryNumber,
            otcOrVitamin: x.otcOrVitamin
          })
        })
        rowSerial = 1;
        result.notOralDrugs?.forEach((x) => {
          this.notOralDrugList.push({
            checked: false,
            topOrder: x.topOrder,
            processDrugAtpId: x.processDrugAtpId,
            processDrugType: x.processDrugType,
            fileUploadDrugId: x.processDrugAtpId, // rplace this old code x.fileUploadDrugId,
            ndc: x.ndc,
            customerDrugNdc: x.customerDrugNdc,
            barcode: x.barcode,
            name: x.name,
            customerDrugName: x.customerDrugName,
            mfgExpiration: x.mfgExpiration,
            strength: x.strength,
            canisterLocation: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            pillForm: x.pillForm,
            volume: x.volume,
            pillVolume: x.pillVolume,
            lot: x.lot,
            qtyInCanister: x.qtyInCanister,
            color: x.color,
            shape: x.shape,
            marking: x.marking,
            description: x.description,
            packQuantity: x.packQuantity,
            costPerPill: x.costPerPill,
            apw: x.apw,
            controlSched: x.controlSched,
            comment: x.comment,
            doNotMix: x.doNotMix,
            active: x.active,
            min: x.min,
            max: x.max,
            invoiceNumber: x.invoiceNumber,
            smartId: x.smartId,
            genericSequenceNumber: x.genericSequenceNumber,
            assignedLot: x.assignedLot,
            assignedExpiration: x.assignedExpiration,
            distributor: x.distributor,
            drawerType: x.drawerType,
            drawer: x.drawer,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            otc: x.otc,
            schedule: x.schedule,
            thirtyDramCapacity: x.thirtyDramCapacity,
            manufacturer: x.manufacturer,
            statusForNotOral: x.statusForNotOral,
            oralSolidStatus: x.oralSolidStatus,
            nonCountReasonForNotAvailable: x.nonCountReasonForNotAvailable,
            tab: x.tab,
            automate: x.automate,
            excludedReason: x.excludedReason,
            dnuMaxForExclusion: x.dnuMaxForExclusion,
            gcValue: x.gcValue,
            hcGcValue: x.hcGcValue,
            cellLocOld: x.canisterLocation,
            atpCanisterNumber: x.atpCanisterNo,
            primaryDrugName: x.primaryDrugName,
            secondaryDrugName: x.secondaryDrugName,
            drugStatus: x.drugStatus,
            dosageForm: x.dosageForm,
            gcnSeqInExport: x.gcnSeqInExport,
            locationInExport: x.locationInExport,
            locationAnalysis: x.drawerType === 'Smart' ? 'SMART' : x.canisterLocation,
            customerComment: x.customerComment,
            customerInventoryNumber: x.customerInventoryNumber,
            otcOrVitamin: x.otcOrVitamin,
            isUnregulated: x.isUnregulated
          })
        })

        this.dataSource.data = this.analysisDrugList;

        this.dataSourceNotAbailable.data = this.notAvailableDrugList;
        this.dataSourceExcluded.data = this.excludedDrugList;
        this.dataSourceOral.data = this.notOralDrugList;
        this.dataSourcecellLocatorSheet.data = this.cellLocatorSheetList;
        this.ngxLoader.stop(ATPDRUGDATA_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(ATPDRUGDATA_KEY);
        console.log(error)
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );

  }

  removeDrugCellLocation() {
    this.ngxLoader.start();
    this.drugCellLocationUpdateList = {
      customerId: this.customerId,
      addRemoveDrugs: this.drugCellLocationList,
    };
    this.imtoolAtpService.drugRemoveAtp(this.drugCellLocationUpdateList).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
          this.ngxLoader.stop();
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop();
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  isCellLocationNotEmpty(): boolean {
    return this.drugCellLocationList.some(function (el) {
      return el.canisterLocation === "" || el.canisterLocation === null;
    });
  }

  addDrugCellLocation() {

    if (this.isCellLocationNotEmpty()) {
      this.toastService.openToast(
        "Please select a cell location to Add!",
        constants.ToastPanelType.error,
        2
      );
    }
    else {
      this.ngxLoader.start();
      this.drugCellLocationUpdateList = {
        customerId: this.customerId,
        addRemoveDrugs: this.drugCellLocationList,
      };
      this.imtoolAtpService.drugAddAtp(this.drugCellLocationUpdateList).subscribe(
        (result) => {
          if (result.statusCode == 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            window.setTimeout(() => {
              window.location.reload();
            }, 2000);
            this.ngxLoader.stop();
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
            this.ngxLoader.stop();
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
    }

  }


  openAlertWindowForDrugRemove() {
    var bodyMessage = this.isMultipleCellLocation == false ? "Are you sure, you want to remove this drug?" : "Multiple location detected for specific drug. Do you want to remove them all?";
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Remove Drug ?",
        bodyMessage: bodyMessage,
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Remove",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        console.log(result.data);
        this.removeDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openAlertWindowForDrugAdd() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Add Drug?",
        bodyMessage: "Are you sure you want to add this drug?",
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Add",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.addDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }




  openImportWizardCDDB() {
    const dialogRef = this.dialog.open(ImportWizardCddbComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId: this.deviceId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugData(this.customerId, 0);
      } else {
        console.log("No change implement", result);
      }
    });
  }


  public doFilter = (value: string) => {
    if (this.tabSelected === 0)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 1)
      this.dataSourceNotAbailable.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 2)
      this.dataSourceExcluded.filter = value.trim().toLocaleLowerCase();
    else this.dataSourceOral.filter = value.trim().toLocaleLowerCase();
  };


  openAlertWindow(data: number) {
    let title = "Confirm export"
    let message1 = "Are you sure you wish to export?"

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: title,
        bodyMessage: message1,
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        if (data == 3) {
          this.excelExportFromAtpTable()
        }
        else if(data==2){
          this.exportTheCanisterOrderFile()
        }
        else if (data == 1) {
          this.ExportCustomerDrug();
        }
      } else {
        console.log("not export", result);
      }
    });
  }

  redirectCustomerList() {
    this.router.navigate(["/im-tool"]);
  }
  openImportWizard() {
    const dialogSiteRef = this.dialog.open(ImportWizardComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customer: {
          sl: 1,
          customerId: this.customerId,
          customerName: this.customerName,
          caseNumber: '',
          customerNumber: '',
          deviceType: '',
          subDeviceTypeName: '',
          lastEdited: '',
          status: '',
          statusDescription: '',
          targetInstallDate: this.targetInstallDate,
        }, isparentnotassociated: 2
      },
    });
    dialogSiteRef.afterClosed().subscribe((result) => {

      this.getRecommendedDrugData(this.customerId, 0);

    });
  }


  openImportWizardDrugMovingComponent() {

    const dialogRef = this.dialog.open(ImportWizardDrugMovingComponent, {

      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        title: '',
        listOfData: [{ key: 1, value: 'Analysis', isSelected: this.tabSelected === 0 },
        { key: 2, value: 'New Drugs', isSelected: this.tabSelected === 1 },
        { key: 3, value: 'Excluded', isSelected: this.tabSelected === 2 },
        { key: 4, value: 'Not Oral Solid', isSelected: this.tabSelected === 3 },]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {


      if (result.data.isClose === true) {
        this.onUpload(result.data.selectedTab, this.drugCellLocationList.map((x) => x.processDrugAtpId))
      }

    });
  }

  onUpload(selectedTab, drugs) {
    if (selectedTab === 0) {
      return
    }
    this.ngxLoader.start();
    this.moveDrug = {
      customerId: this.customerId,
      deviceId: this.deviceId,
      processDrugType: selectedTab,
      drugId: drugs,
    }
    this.imtoolService.moveDrugs(this.moveDrug).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );

          this.ngxLoader.stop();
          setTimeout(() => {
            location.reload();
          }, 2000)
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop();
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {  
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    return currentDate.toLocaleDateString('en-US',options);
  }

}
