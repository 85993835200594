import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { State } from '../reducers/assets.reducer';

export const getAssetsState = createFeatureSelector<State>('assets');

export const getAssetsByOrgID = createSelector(
    getAssetsState,
    (state: State) => state.assets
);

export const getMachinePermissions = createSelector(
    getAssetsState,
    (state: State) => state.machinePermissions
);

export const getAuthProfile = createSelector(
    getAssetsState,
    (state: State) => state.authProfile
);

export const getLaunchDarklyFlags = createSelector(
    getAssetsState,
    (state: State) => state.launchDarklyFlags
);
