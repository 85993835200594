<mat-form-field class="flex flex-row xs:flex-1 gt-xs:basis-1/5" floatLabel="never" [formGroup]="form">
  <label>
    <app-icon icon="asterisk" aria-hidden="true" size="10"></app-icon>
    Account Name
    <custom-tooltip text="Customer's Account Name in Salesforce."></custom-tooltip>
  </label>
  <div class="autocomplete-input flex flex-row">
    <input type="text" matInput appNoHtmlTags trim [formControlName]="orgControlName"
      [matAutocomplete]="auto">
    <button matSuffix mat-button mat-icon-button aria-label=""
      [ngStyle]="{'visibility': (orgSelectControl.value) ? 'visible' : 'hidden'}" 
      (click)="orgSelectControl.reset()" [disabled]="orgSelectControl.disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let org of filteredOrg" [value]="org.customerNo"
      matTooltip="{{org.name}}" matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
      {{org.name}} 
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    <span *ngIf="orgSelectControl.errors?.['NoOptionsError']">No account to provision. </span>
    <span *ngIf="orgSelectControl.errors?.['required']">Account Name required. </span>
    <span *ngIf="orgSelectControl.errors?.['InvalidOptionError']">Account Name is invalid. </span>
  </mat-error>
</mat-form-field>