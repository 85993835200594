import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getSaveWidgetsList, getSelectedWidgetsList } from 'src/app/store/selectors/pharmacy-peek-widgets.selector';
import { reorderSelectedPharmacyWidgetList } from 'src/app/store/actions/pharmacy-peek-widgets.action';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PharmacyPeekDiscardChangesDialogComponent } from '../pharmacy-peek-discard-changes-dialog/pharmacy-peek-discard-changes-dialog.component';
import { PharmacyPeekMgmtDialogComponent } from '../pharmacy-peek-mgmt-dialog/pharmacy-peek-mgmt-dialog.component';
import { PharmacyPeekService } from '../../../services/pharmacy-peek.service';
import { PharmacyPeekWidgetActions } from 'src/app/store/actions';

@Component({
  selector: 'app-pharmacy-peek-list',
  templateUrl: './pharmacy-peek-list.component.html',
  styleUrls: ['./pharmacy-peek-list.component.scss']
})

export class PharmacyPeekListComponent implements OnInit {
  selectedList: any = [];
  compareList: any = [];

  constructor(public store: Store<State>, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PharmacyPeekMgmtDialogComponent>,
    private pharmacyPeekService: PharmacyPeekService
  ) { }

  ngOnInit(): void {

    this.store.select(getSelectedWidgetsList).subscribe(res => {
      if (res) {
        this.compareList = res;
      }
    }
    );

    this.store.select(getSaveWidgetsList).subscribe(res => {
      if (res) {
        this.selectedList = res;
      }
    }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedList, event.previousIndex, event.currentIndex);
    this.store.dispatch(reorderSelectedPharmacyWidgetList({ data: this.selectedList }));
  }

  removeWidget(selectedindex) {
    const arr: any = [...this.selectedList];
    arr.splice(selectedindex, 1);
    this.store.dispatch(reorderSelectedPharmacyWidgetList({ data: arr }));
  }

  openPharmacyPeekDiscardChangesDialog() {  

    if (JSON.stringify(this.selectedList) !== JSON.stringify(this.compareList)) {
      const dialogRefDisgard = this.dialog.open(PharmacyPeekDiscardChangesDialogComponent, { backdropClass: 'smDialog' });

      dialogRefDisgard.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if (result.undoChanges === true) {
          this.store.dispatch(PharmacyPeekWidgetActions.updateSelectedPharmacyWidgetList({ data: this.compareList }));
          this.closeDialog();
        }
      });
    }
    else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  savePharmacyPeekList() {

    this.pharmacyPeekService.saveUserConfiguration(this.selectedList).subscribe(
      (response) => {
        this.store.dispatch(PharmacyPeekWidgetActions.updateSelectedPharmacyWidgetList({ data: this.selectedList }));
        console.log('pharmacypeekwidget added');
        this.closeDialog();
      },
      (error) => {
        console.log('pharmacypeekwidget error:', error);
      },
    );

  }

}
