import { GcnDrugAdd, MaxGcnDrug, MaxGcnDrugAdd, PassGcnDrug, SynmedGcn } from '../../models/synmed-drug';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IMToolService } from '../../services/im-tool.service';
import * as constants from 'src/app/config/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
@Component({
  selector: 'app-import-wizard-gcn-pass',
  templateUrl: './import-wizard-gcn-pass.component.html',
  styleUrls: ['./import-wizard-gcn-pass.component.scss']
})
export class ImportWizardPassComponent implements OnInit {

  @Output() hideSide = new EventEmitter<number>();
  @Output() closeWizard = new EventEmitter<boolean>();
  
  @ViewChild("TABLE") table: ElementRef;
  dataSource = new MatTableDataSource<MaxGcnDrug>([]);
  gcnDrugList: PassGcnDrug []=[];
  @Input() customerId:string;
  SaveGcnDrugListModel:MaxGcnDrugAdd= {} as MaxGcnDrugAdd;
  gcnDrugListModel:GcnDrugAdd[]=[];
  searchText:string=''


   //#region Table colums Array


   displayedColumns:string[] = [
    "sl",
    "name",
    "ndc",
    "strength",
    "usage",
    "bottleSize",
    "dailyUsage",
    "cellCapacity",
    "largeCanisterCapacity",
    "extenderCapacity",
    "groupCell",
    "cellType",
  ]
//#endregion

  selectedRow:any;


  constructor(
    private imtoolService: IMToolService,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    this.customerId = data.customerId;

  }

  ngOnInit(): void {
    this.getGcnDrugList();
  }





  redirectToPassDrugPage(){
    this.route.navigate([ '/im-tool/pass-export', this.customerId]);
  }

  alertMessageForLidHoleSize(lidHole:number, noOfCanister:number):boolean{
    if(lidHole >=120 && noOfCanister <= 1){
    this.toastService.openToast('Lidhole > 120 cannot have single location', constants.ToastPanelType.warning, 2);
    return true;
    }
    return false;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  saveGcnDrugs(){
    this.ngxLoader.start();
    this.SaveGcnDrugListModel = {} as MaxGcnDrugAdd;
    this.gcnDrugListModel = [];
    this.dataSource.data.forEach(row =>{

        this.gcnDrugListModel.push({
          ndc: row.ndc,
          groupCell : row.groupCell===null ? 0:row.groupCell,
          cellType:row.cellType,
          cellCapacity:row.cellCapacity === null? 1:row.cellCapacity
        })

       });

       this.SaveGcnDrugListModel.customerId = this.customerId;
       this.SaveGcnDrugListModel.drugs = this.gcnDrugListModel;

    this.imtoolService.passGcnDrugAdd(this.SaveGcnDrugListModel).subscribe((result)=>{
      this.ngxLoader.stop();
      if(result.statusCode===200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.dataSource.data = [];
        this.SaveGcnDrugListModel={} as MaxGcnDrugAdd;
        this.gcnDrugList = [];
        this.closeWizard.emit(true)
        this.redirectToPassDrugPage();
      }
      else{
        this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);
      }

    },(error: HttpErrorResponse) => {

      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  openAlertWindow() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Export Duplicate NDC List",
        bodyMessage: "Are you sure you want to export this drug list?",
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {

      } else {
        console.log("not export", result);
      }
    });
  }

  getGcnDrugList(){
    this.ngxLoader.start();
    this.gcnDrugList = [];
    this.imtoolService.getPassGcnDrugs(this.customerId).subscribe((response)=>{
      let result = response.data;
      let sl = 1;
      result.data.forEach(element => {
        this.gcnDrugList.push({
          sl: sl++, 
          name: element.customerDrugName, //?? element.cddbDrugName ?? 'Unknown', // Provide default if null
          strength: element.strength,
          bottleSize: element.packageSize,
          dailyUsage: element.minimumDaysSupply,
          cellCapacity: element.canisterCapacity,// ?? 'Unknown', // Provide default if null
          groupCell: 1, // Assuming 'groupCell' corresponds to 'topOrder'
          cellType: element.canisterType,//?? 'Unknown', // Provide default if null
          ndc: element.ndc,
          usage: element.usage,
          largeCanisterCapacity: element.largeCanisterCapacity,
          extenderCapacity: element.extenderCapacity
        });
      });

      this.dataSource.data = this.gcnDrugList;
      this.ngxLoader.stop();
      if(result.message?.length>0){
        this.toastService.openToast(
          result.message,
          constants.ToastPanelType.error,
          2
        );
      }
    },(error: HttpErrorResponse)=>{
      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }


}
