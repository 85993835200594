import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import icons from '../../../../assets/img/icons.json';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {

  @Input() icon: string;
  @Input() color = '';
  @Input() size = 16;

  @Input() rotatable = null;
  @Input() rotate = 0;
  src: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!icons[this.icon]) {
      this.src = null;
      throw new Error(`Icon ${this.icon} not found!`);
    }

    this.src = '/assets/img/icons/' + icons[this.icon];
    if (this.color) {
      this.src = this.src.replace('.svg', `.${this.color.toLowerCase()}.svg`);
    }
  }
}
