import { DataService } from '../../../shared/services/data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomerAndFileInfo, ListOfDrugLocation, ListOfDrugLocationAtp } from '../models/customer-drug-info';
import { AddCddbDrugs } from '../models/cddb-drugs';
import { AdjustGcnDrug, UpdateGroupCanister } from '../models/gcn-drugs';
import { UpdateQualifierDrug } from '../models/drug-qualifer';
import { MaxGcnDrugAdd, SaveSynmedDrug, SynmedGcn } from '../models/synmed-drug';
import { ListOfAddDrugLocationSynmed, ListOfDrugLocationSynmed } from '../models/drugInfo-synmed';
import { MoveDrug } from '../models/move-drugs';


@Injectable({
  providedIn: "root",
})
export class IMToolService extends DataService<any> {
  httpClient: HttpClient;


  constructor(http: HttpClient) {
    super(`${environment.apiUrl.imToolService}`, http);
    this.httpClient = http;
  }

  create(
    resource,
    url: string = this.url + "GetHeaderListFromFile"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      reportProgress: true,
      observe: "events",
    });
  }

  customerAndFileInfoSave(
    resource: CustomerAndFileInfo,
    url: string = this.url + "UploadCustomerAndDrugInfo"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  customerRemove(
    customerId,
    url: string = this.url + "RemoveCustomerById"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(customerId), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  drugRemove(
    resource: ListOfDrugLocation,
    url: string = this.url + "RemoveDrug"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  drugAdd(
    resource: ListOfDrugLocation,
    url: string = this.url + "AddDrug"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  gcnDrugAdd(
    resource: AdjustGcnDrug,
    url: string = this.url + "AdjustGcn"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  groupCanisterAdd(
    resource: UpdateGroupCanister,
    url: string = this.url + "UpdateGroupCanisterByNDC"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  changeStatusToExport(
    customerId,
    url: string = this.url + "ChangeStatusToExport"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(customerId), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  // drugInfoWithCanisterSave(
  //   customerId,
  //   url: string = this.url + "drugInfoWithCanisterBaseUrl"
  // ): Observable<any> {
  //   return this.httpClient.post(url, JSON.stringify(customerId), {
  //     headers: new HttpHeaders({ "Content-Type": "application/json" }),
  //   });
  // }
  drugInfoWithCanisterSaveNew(
    resource,
    url: string = this.url + "CreateCustomerConfiguration"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  addCddbDrugs(
    cddbDrug: AddCddbDrugs,
    url: string = this.url + "AddCDDBDrug"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(cddbDrug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


    getSalesForceStatus(
    url: string = this.url + "GetSalesForceStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCustomerData(
    searchText: string,
    currentPageNo: any,
    currentPageSize: any,
    sortDir: string,
    sortColumnName: string,
    statusId: number,
    salesForceStatus:string,
    url: string = this.url + "GetAllCustomer"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        search: searchText,
        pageNo: currentPageNo,
        pageSize: currentPageSize,
        vieworder: sortDir,
        viewOrderBy: sortColumnName,
        statusId: statusId.toString(),
        salesForceStatus:salesForceStatus
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getCddbDrugsData(
    searchText,
    customerId,
    pageNo,
    pageSize,
    sortDir,
    url: string = this.url + "SearchCDDDrug"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { ndcOrName: searchText, customerId:customerId, pageNo: pageNo, pageSize: pageSize, viewOrder: sortDir },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  getRecommendedDrugData(
    customerId,
    cellSize,
    url: string = this.url + "LoadDrugRecommendationScreen​"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId, cellSize: cellSize },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getfileStatus(
    url: string = this.url + "GetAllFileStatus"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getAvailableCellLocation(
    customerId,
    url: string = this.url + "GetAvailableCellLoc"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getSystemDeviceType(
    id,
    url: string = this.url + "GetAllDeviceType"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { deviceId: id },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getDeviceType(
    url: string = this.url + "GetAllDevice"
  ): Observable<any> {
    return this.httpClient.get(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getAnalysisDataById(customerId, dataSource:number): Observable<any> {
    return this.httpClient.get(this.url + "AnalyzeDevice", {
      params: { customerId: customerId, dataSource:dataSource },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getPassAnalysisData(
    subDeviceTypeId: number,
    customerId: string,
    minimumDaysSupply: number,
    autoCanister: number,
    timespan: number,
    daysPerWeek: number
  ): Observable<any> {
    return this.httpClient.get(this.url + "AnalyzeDeviceForPass", {
      params: {
        subDeviceTypeId: subDeviceTypeId,
        customerId: customerId,
        minimumDaysSupply: minimumDaysSupply,
        autoCanister: autoCanister, // Assuming autoCanister is convertible to string
        timespan: timespan,
        daysPerWeek: daysPerWeek
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  }
  
  getImportWizardData(customerId): Observable<any> {
    return this.httpClient.get(this.url + "GetCustomerConfigByCustomerId", {
      params: { customerId: customerId },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getGcnDrugs(
    customerId: string,
    url: string = this.url + "GcnListByCustomerId"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        customerId: customerId,
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getCountries() {
    return this.getAll(this.url + 'GetCountryCodeListForSynMed');
  }
  getQualifierDrugDropdownData() {
    return this.getAll(this.url + 'GetStandardValuesDropdownOfDrugQualifierWizard');
  }


  getQualiferDrug(
    customerId,
    pageNo,
    pageSize,
    searchValue,
    subDevice,
    url: string = this.url + "GetDrugQualifierWizardForSynMed"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId, search: searchValue, page: pageNo, pageSize: pageSize, subDevice:subDevice },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  updateQualifierDrug(
    customerId: string,
    resource: UpdateQualifierDrug[],
    url: string = this.url + "UpdateQualifierWizardDrug?customerId=" + customerId
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  synmedDrugInfoWithCanisterSave(
    resource: SaveSynmedDrug,
    url: string = this.url + "ImportWizard2ForSynmed"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getRecommendedDrugDataSynmed(
    customerId,
    url: string = this.url + "GetSynMedRecommendationDrugByCustomerId"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getSynmedGcnDrugs(
    customerId: string,
    url: string = this.url + "GetGroupCanisterDataForSynMedByCustomerId"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        customerId: customerId,
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  getMaxGcnDrugs(
    customerId: string,
    url: string = this.url + "GetGroupCanisterMax"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        customerId: customerId,
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getPassGcnDrugs(
    customerId: string,
    url: string = this.url + "GetGroupCanisterForPass"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: {
        customerId: customerId,
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  synmedGcnDrugAdd(
    customerId: string,
    resource: SynmedGcn[],
    url: string = `${this.url}UpdateGroupCanisterDataForSynMed?customerId=${customerId}`
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  maxGcnDrugAdd(
    resource: MaxGcnDrugAdd,
    url: string = `${this.url}UpdateGroupCanister`
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
  passGcnDrugAdd(
    resource: MaxGcnDrugAdd,
    url: string = `${this.url}UpdateGroupCanisterForPass`
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  drugAddSynmed(

    resource: ListOfAddDrugLocationSynmed,
    url: string = this.url + "AddDrugSynmed"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  drugRemoveSynmed(
    resource: ListOfDrugLocationSynmed,
    url: string = this.url + "RemoveDrugSynmed"
  ): Observable<any> {
    return this.httpClient.put(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  addCddbDrugsSynmed(
    cddbDrug: AddCddbDrugs,
    url: string = this.url + "AddCddbDrugForSynmed"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(cddbDrug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getTdrugDataSynmed(
    customerId,
    pageLimit,
    offset,
    url: string = this.url + "SynmedTdrugData​"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId, pageLimit:pageLimit, offset:offset },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }


  moveDrugs(
    cddbDrug: MoveDrug,
    url: string = this.url + "MoveDrugs"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(cddbDrug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }
}
