

  <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
      <div class="flex justify-start content-center items-center flex-row">
          <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
             DRUG QUALIFIER WIZARD
          </div>
          <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
              <mat-icon (click)="dialogRef.close()" class="cross-wizard">
                  <img src="/assets/img/icons/ico.16.clear.svg" alt="">
              </mat-icon>
          </div>

      </div>

  </h1>



  <div class="flex justify-start content-center items-center flex-row">
    <div class="search-wrapper" style="padding: 9px">
        <div class="search-container">
            <app-icon icon="search" class="icon_search"></app-icon>
            <input type="text" class="search-input" inputId="searchdrugs-input" (change)="getQualiferDrug($event.target.value); currentPage = 0" minLengthToSearch="1" placeholder="Search" [(ngModel)]="searchText" id="search_drugs">

            <app-icon icon="clear" *ngIf="searchText != null && searchText.length>0" (click)="getQualiferDrug(''); searchText=''; currentPage = 0" id="data-clear" class="pointer"></app-icon>
        </div>
    </div>
</div>
<!-- <mat-dialog-content > -->

<div class="mat-elevation-z8"  (scroll)="onScroll($event)" #TABLE>
        <table  #table mat-table [dataSource]="dataSource">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>

                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row">
                      <mat-checkbox (change)="drugChecked(element, $event.checked)" [(ngModel)]="element.isChecked" ></mat-checkbox>
                      <img  class="img" (click)="openImageWindow(element.image)" [src]="element.image" alt="drug image">
                    </div>

                    </td>
              </ng-container>
            <!-- Customer Drug Name -->
            <ng-container matColumnDef="customerDrugName">
                <th mat-header-cell *matHeaderCellDef> Customer Drug Name </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                  <span> {{element?.customerDrugName}} </span>
                  <div class="flex flex-row">
                    <label for="Pipette"> Pipette </label>
                    <mat-form-field floatLabel="never" class="basis-1/2">
                      <mat-select [(ngModel)]="element.pipetteSize" (selectionChange)="drugInfoChange(element)">
                          <mat-option *ngFor="let type of pipetteList" [value]="type.value">{{type.label}}</mat-option>
                      </mat-select>
                  </mat-form-field>
                  </div>
                  </div>
                 </td>
            </ng-container>

            <!-- CDDB Drug Name -->
            <ng-container matColumnDef="cddbDrugName">
                <th mat-header-cell *matHeaderCellDef>CDDB Drug Name</th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                    <span> {{element?.cddbDrugName}}</span>
                   
                    <div class="flex flex-row">
                      <label for="Container Size"> Container Size </label>
                      <mat-form-field floatLabel="never" class="basis-3/5">
                        <mat-select  [(ngModel)]="element.containerSize" (selectionChange)="drugInfoChange(element)">
                            <mat-option *ngFor="let type of containerSizeList" [value]="type.value">{{type.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                  </div>

                </td>
            </ng-container>

            <!-- ndc Column -->
            <ng-container matColumnDef="ndc">
                <th mat-header-cell *matHeaderCellDef> NDC </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                    <span>  {{element?.ndc}}</span>
                   
                    <div class="flex flex-row">
                      <label for="Fragile"> Fragile </label>
                      <mat-form-field floatLabel="never" class="basis-4/5">
                        <mat-select  [(ngModel)]="element.fragileFlag" (selectionChange)="drugInfoChange(element)">
                            <mat-option *ngFor="let type of fragileList" [value]="type.value">{{type.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                  </div>

                </td>
            </ng-container>

            <!-- GCN Column -->
            <ng-container matColumnDef="gcn">
                <th mat-header-cell *matHeaderCellDef> GCN </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                    <span>  {{element?.gcn}}</span>

                    <div class="flex flex-row">
                      <label for="Fragile"> Drop Height </label>
                      <input class="custom-input" [(ngModel)]="element.dispensingHeight" (change)="drugInfoChange(element)"  type="number">
                    </div>
                    
                  </div>
                </td>
            </ng-container>


            <!-- Manufacturer Column -->
            <ng-container matColumnDef="manufacturer">
                <th mat-header-cell *matHeaderCellDef> Manufacturer</th>
                <td mat-cell *matCellDef="let element">

                  <div class="flex flex-col">
                    <span> {{element?.manufacturer}}</span>
                    <div class="flex flex-row">
                      <div class="flex flex-row basis-full">
                        <label for="Mass Index" class="basis-1/2"> Mass Index </label>
                        <mat-form-field floatLabel="never" class="basis-1/2">
                          <mat-select [(ngModel)]="element.massIndex" (selectionChange)="drugInfoChange(element)">
                              <mat-option *ngFor="let type of masIndexList" [value]="type.value">{{type.label}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                      </div>
                      <div class="flex flex-row">
                      <label for="Volcanic"> Volcanic </label>
                      <mat-form-field floatLabel="never" class="basis-full">
                        <mat-select [(ngModel)]="element.lidVolcanic" (selectionChange)="drugInfoChange(element)">
                            <mat-option *ngFor="let type of volcanicList" [value]="type.value">{{type.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                      <div class="flex flex-row">
                        <label for="DNU"> DNU </label>
                        <mat-form-field floatLabel="never" class="basis-full">
                          <mat-select  [(ngModel)]="element.dnuMax" (selectionChange)="drugInfoChange(element)">
                              <mat-option *ngFor="let type of dnuList" [value]="type.value">{{type.label}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                      </div>
                    </div>
                  </div>
                </td>
            </ng-container>
             <!-- strength Column -->
             <ng-container matColumnDef="strengthUnitOfMeasure">
              <th mat-header-cell *matHeaderCellDef> Unit</th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-col">
                  <span>{{element?.strengthUnitOfMeasure}}</span>
                  <div class="flex flex-row">
                   
                  </div>
                </div>
              </td>
          </ng-container>
             <!-- strength Column -->
             <ng-container matColumnDef="strength">
                <th mat-header-cell *matHeaderCellDef> Strength</th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                    <span>{{element?.strength}}</span>
                    <div class="flex flex-row">
                     
                    </div>
                  </div>
                </td>
            </ng-container>

            <!-- Unit Usage Column -->
            <ng-container matColumnDef="unitUsage">
                <th mat-header-cell *matHeaderCellDef> Unit Usage </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                    <span>{{element?.unitUsage}}</span>


                     <div class="flex flex-row">
                      <label for="Rank"> Rank  {{element?.rank}}</label>
                    
                    </div>
                    
                  </div>
                </td>
            </ng-container>

            <!-- Rx Qty Column -->
            <ng-container matColumnDef="rxQty">
                <th mat-header-cell *matHeaderCellDef> Rx Qty </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex flex-col">
                  <span>{{element?.rxQty}}</span>

                  <div class="flex flex-row">
                    <label for="lidHoleType"> Lid Type </label>
                    <mat-form-field floatLabel="never">
                      <mat-select [(ngModel)]="element.lidHoleType" (selectionChange)="drugInfoChange(element)">
                          <mat-option *ngFor="let type of lidTypeList" [value]="type.value">{{type.label}}</mat-option>
                      </mat-select>
                  </mat-form-field>
                  </div>
                   
                  </div>
                </td>
            </ng-container>




            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
            <tr mat-row   [style.background]="row.backgroundColor"  *matRowDef="let row; columns: displayedColumns;"></tr>


        </table>
         <table>
            <tr *ngIf="dataSource?.data.length==0">
                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                    No Records Found!
                </td>
            </tr>
        </table>

    </div>


<!-- </mat-dialog-content> -->

<mat-dialog-actions class="flex justify-end content-end items-end flex-row">
  <button mat-button class="btn-blue btn-md"  id="Save" (click)="saveButtonClick()"   type="button" aria-label="Confirm">Next</button>
</mat-dialog-actions>

<!--END: Import Wizard GCN-->

