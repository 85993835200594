<div class="card" *ngIf="!collapsable || !collapsed">
  <div class="card-title" [class.collapsable]="collapsable">
    <div *ngIf="title || options" class="flex justify-start content-center items-center flex-row">
      <div *ngIf="backLink" class="basis-[29px]">
        <button class="btn-transparent btn_icon" [routerLink]="[backLink]" tabindex="0" aria-label="Go Back" type="button">
          <app-icon icon="arwlft"></app-icon>
        </button>
      </div>

      <h5 *ngIf="title" class="ucase m-0 text-primaryblue flex flex-row justify-between content-stretch items-stretch h-full" tabindex="0">{{ title }}</h5>

      <ng-container *ngIf="!title && options">
        <div class="flex justify-between">
          <div>
            <button class="ucase m-0 text-primaryblue pointer btn-transparent detail_report_btn" [matMenuTriggerFor]="menu" #t="matMenuTrigger" tabindex="0" type="button">
              <span class="valign-middle">{{ options[selectedIndex] && options[selectedIndex].title }}</span>
              <app-icon icon="arwdown" class="m-l-5"></app-icon>
            </button>

            <mat-menu #menu="matMenu" xPosition="after" [backdropClass]="['custom_drop', 'no-arrow', 'no-max-width']">
              <ng-container *ngFor="let option of options; let index = index">
                <button mat-menu-item *ngIf="option.routerLink" 
                  [class.activated]="index === selectedIndex" [routerLink]="[option.routerLink]" type="button" 
                  class="flex justify-between content-center items-center flex-row">
                  {{ option.title }}
                  <app-icon *ngIf="index === selectedIndex" icon="check" size="13" color="053ab2" class="m-l-10">
                  </app-icon>
                </button>

                <button mat-menu-item *ngIf="!option.routerLink" [class.activated]="index === selectedIndex" 
                  (click)="selectIndex.emit(index)" type="button">
                  {{ option.title }}
                  <app-icon *ngIf="index === selectedIndex" icon="check" size="13" color="053ab2" class="m-l-10">
                  </app-icon>
                </button>
              </ng-container>
            </mat-menu>
          </div>


        </div>
      </ng-container>
    </div>

    <div *ngIf="!(title || options)">
      <ng-content select="[title]"></ng-content>
    </div>

    <div *ngIf="collapsable" class="pp_ec_arrow">
      <button class="btn-transparent btn_icon" (click)="collapsedChange.emit(!collapsed)" (keypress.down)="collapsedChange.emit(!collapsed)" aria-label="Pharmacy Peek Collapse" id="pharmacypeekpanelarrow" type="button">
        <app-icon icon="arwrgt"></app-icon>
      </button>
    </div>

  </div>
  <!-- end card title-->

  <div #content class="card-content">
    <ng-content></ng-content>
  </div>
</div>

<div class="card card-collapsed" *ngIf="collapsable && collapsed">
  <div class="card-title">
    <div class="basis-5">
      <button class="btn-transparent btn_icon" (click)="collapsedChange.emit(!collapsed)" aria-label="Pharmacy Peek Expand" type="button">
        <app-icon icon="arwlft"></app-icon>
      </button>
    </div>
  </div>
  <div class="card-content" [style.min-height]="contentHeight + 'px'">
    <h5 class="m-0 text-primaryblue">{{ collapsedTitle || title }}</h5>
  </div>
</div>
