/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { HomeComponent } from './shared/components/home/home.component';
/* eslint-enable max-len */

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-user',
    loadChildren: () => import('./external-user/external-user.module').then(mod => mod.ExternalUserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'internal-user',
    loadChildren: () => import('./internal-user/internal-user.module').then(mod => mod.InternalUserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'common-module',
    loadChildren: () => import('./common/common.module').then(mod => mod.CommonModules),
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})

export class AppRoutingModule { }
