import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BlobService } from '../../services/blob.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-eula-dialog',
  templateUrl: './eula-dialog.component.html',
  styleUrls: ['./eula-dialog.component.scss']
})
export class EulaDialogComponent {

  public acceptAgreement = false;

  constructor(
    public dialogRef: MatDialogRef<EulaDialogComponent>,
    private blobService: BlobService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastService: ToastService,
  ) {
    dialogRef.disableClose = true;
  }

  downloadPDF() {
    this.blobService.downloadFile(
      'absportal', 'Eula.pdf',
      this.ngxUiLoaderService, this.toastService
    );
  }
}
