import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FocusOrigin } from '@angular/cdk/a11y';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { UserManagementDialogComponent } from '../user-management-dialog/user-management-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { Profile } from '../../models/auth/profile';
import { Role } from '../../models/auth/role';
import { Menu, orgType } from '../../../config/app-constants';
import { AppComponent } from 'src/app/app.component';
import { NotificationMgmtDialogComponent } from 'src/app/external-user/notification-mgmt-dialog/notification-mgmt-dialog.component';
import { getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { PermissionGroupsDialogComponent } from '../permission-groups-dialog/permission-groups-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() menuClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() cdkFocusChange: EventEmitter<FocusOrigin> = new EventEmitter<FocusOrigin>();
  @Input() showPharmacyPeek: boolean;
  @Input() enablePharmacyPeek: boolean;

  profile: Profile;
  message: string;
  enableGearIcon = false;
  enableConsumable = false;
  enableNotifications = false;
  enableMessages = false;
  menu_NotificationManagementFlag = false;
  enableGearIconUserManagement = false;
  enableGearIconPermissionGroup = false;

  Role = Role;

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private messageService: SharedService,
    public myapp: AppComponent,
    public store: Store<State>,
  ) { }

  ngOnInit() {
    this.messageService.sharedMessage
      .subscribe(message => this.message = message);
    this.auth.profile$.subscribe(profile => {
      this.profile = profile;
      this.enableGearIcon = profile.menuPermissions.includes(Menu.GearIconUserManagement) ||  profile.menuPermissions.includes(Menu.GearIconPermissionGroup);
      this.enableGearIconUserManagement = profile.menuPermissions.includes(Menu.GearIconUserManagement);
      this.enableGearIconPermissionGroup = profile.menuPermissions.includes(Menu.GearIconPermissionGroup);
    });

    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.enableConsumable = flags.CustomerManagement_ConsumablesSubscription;
        this.enableNotifications = flags.TopNavigation_Notifications;
        this.enableMessages = flags.TopNavigation_Messages; 
        this.menu_NotificationManagementFlag = flags.Menu_NotificationManagement; 
      }
    }
    );

  }

  openUserManagement() {
    if (this.auth.loggedIn && this.enableGearIconUserManagement) {
      this.dialog.open(
        UserManagementDialogComponent,
        { disableClose: true, backdropClass: ['usermgmtDialog','lgDialog', 'SnackDialog'] },
      );
    }
  }

  openPermissionGroups() {
    if (this.auth.loggedIn && this.enableGearIconPermissionGroup) {
      this.dialog.open(
        PermissionGroupsDialogComponent,
        { disableClose: true, backdropClass: ['PermissionGrpsDialog','lgDialog', 'SnackDialog'] },
      );
    }
  }

  openNotificationMgmtDialog() {
    if (this.auth.loggedIn) {
      const dialogRef = this.dialog.open(NotificationMgmtDialogComponent, { backdropClass: ['NotificationMgmtDialog', 'lgDialog', 'SnackDialog'] });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  openPharmacyPeekDialog() {
    this.myapp.openPharmacyPeekDialog();
    return;
  }
}
