<div *ngIf="loadApp === true && auth.loggedIn === true" class="parata_webshell">  
  <mat-sidenav-container class="sidebarnav_container">
    <!-- A sidebar -->
    <mat-sidenav class="sidebarnav-fixed" 
      *ngIf="auth.loggedIn" 
      fixedInViewport="true" 
      [(opened)]="opened" 
      mode="over" 
      position="start" 
      disableClose="true" 
      fixedTopGap="64" 
      aria-label="Sidebar panel" 
      cdkMonitorSubtreeFocus 
      (cdkFocusChange)="formatOrigin($event); markForCheck()" id="sidebarnav">
      <figure class="sidenav_icon">
        <img role="img" aria-label="Parata Systems" alt="parata" class="p_icon" src="../../assets/p_logo.png">
      </figure>
      <h3 class="mobile_nav">
        <button class="sidebar_close" (click)='selectedMenuItem()' type="button">
          <mat-icon class="icon-grey">close</mat-icon>
        </button>
        MENU
      </h3>

      <ng-material-multilevel-menu class="multilevel-menu" 
        role="navigation" 
        [configuration]='navConfig'
        [items]='navItems' 
        cdkMonitorSubtreeFocus 
        (cdkFocusChange)=" formatOrigin($event); markForCheck()" 
        (selectedItem)='selectedMenuItem()'>
        <ng-template #listTemplate let-node="item" let-configuration="configuration">
          <app-list-item-content 
            class="filled" 
            [node]="node" [isRtlLayout]="false"
          ></app-list-item-content>
        </ng-template>
      </ng-material-multilevel-menu>

      <ul class="sidenav_notification" aria-label="Mobile Navigation" role="list">
        <li *ngFor="let navitem of navItems">
          <a attr.aria-valuenow="{{navitem.label}}" href="{{navitem.link}}" id="{{navitem.menuId}}" role="link">
            <img src="{{ navitem.imageIcon }}" alt="" attr.aria-valuenow="{{ navitem.label }}"> {{ navitem.label }}
          </a>
        </li>
        <li *ngIf="enableMessages">
          <a href="#" aria-labelledby="Messages" role="link">
            <img src="../assets/img/icons/ico.24.mail.svg" alt="Messages"> Messages
          </a>
        </li>
        <li *ngIf="enableNotifications">
          <a href="#" aria-labelledby="Notifications" role="link">
            <img src="../assets/img/icons/ico.24.notify.svg" alt="Notifications"> Notifications
          </a>
        </li>
        <li>
          <hr/>
        </li>
        <li *ngIf="profile.hasRole(Role.pharmacy) && menu_NotificationManagementFlag === true">
          <button mat-icon-button aria-label="Notifications" type="button" class="smdevice_dropdown_button" [matMenuTriggerFor]="notify">
            <img src="../assets/img/icons/ico.24.notify.svg" class="icon_settings" alt="Notifications"> Notifications
          </button>
          <mat-menu #notify="matMenu" xPosition="before" backdropClass="smdevice_dropdown">
            <button mat-menu-item type="button" (click)="openNotificationMgmtDialog()" id="notification_sm" aria-label="Notification Management" >Notification Management</button>
          </mat-menu>
        </li>
        <li>
          <button mat-icon-button aria-label="Site Settings" type="button" class="notifications_none notify smdevice_dropdown_button" [matMenuTriggerFor]="gear">
            <app-icon class="icon_settings" icon="settings" color="ffffff" size="24"></app-icon> Site Settings
          </button>
          <mat-menu #gear="matMenu" xPosition="before" backdropClass="smdevice_dropdown">
            <button mat-menu-item type="button" (click)="openUserManagement()" id="user_mgmt" aria-label="User Management" >User Management</button>
          </mat-menu>
        </li>
        <li>
          <a href="#" class="caps" role="link">
            <img alt="" attr.aria-valuenow="{{profile.name}}" class="profile_pic" id="mprofile" [src]="profile.picture" />
            {{profile.name}}
          </a>
        </li>
        <li (click)="auth.logout()">
          <a href="#" role="link">
            <img src="../assets/img/icons/ico.24.logout.svg" alt="" attr.aria-valuenow="logout"> Log Out
          </a>
        </li>
      </ul>

      <figure class="sidenav_logo">
        <img role="img" aria-label="Parata Systems" alt="" src="../../assets/logo.png">
      </figure>      
    </mat-sidenav>       
    <!-- Page content -->
    <div mat-sidenav-content>      
      <div class="pw_container" [class.addPadding]="!auth.loggedIn">        
        <header role="banner">
          <app-header 
            (menuClick)="toggleOpened()" 
            (cdkFocusChange)="formatOrigin($event); markForCheck()" 
            [showPharmacyPeek]="showPharmacyPeek" 
            [enablePharmacyPeek]="enablePharmacyPeek"
          ></app-header>
        </header>
        <mat-sidenav-container role="main" aria-label="main content" class="ipm_content container-fluid" 
          cdkMonitorSubtreeFocus (cdkFocusChange)="formatOrigin($event); markForCheck()">
          <div class="flex flex-row gap-4 justify-start items-stretch content-stretch xs:flex xs:flex-col xs:gap-0 self-start sm:flex sm:flex-col sm:gap-0">
            <div class="sm:basis-full basis-full">
              <router-outlet></router-outlet>
            </div>

            <div *ngIf="showPharmacyPeek && enablePharmacyPeek" class="pharmacy_peek sm:basis-full xs:hidden flex-1 sm:hidden">
              <app-pharmacy-peek class="app_pharmacypeek pp_panel"></app-pharmacy-peek>
            </div>
            <div *ngIf="showPharmacyPeek && enablePharmacyPeek" class="pharmacy_peek m-t-16 sm:basis-full sm:block flex-1">
            </div>
          </div>
        </mat-sidenav-container>
      </div>
    </div>
  </mat-sidenav-container>
</div>


<ngx-ui-loader></ngx-ui-loader>
