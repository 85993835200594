import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormularyData } from '../../models/file';
import { ToastService } from '../../services/toast.service';
import * as constants from 'src/app/config/app-constants';
@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;

  // File array holds file objects, which can be accessed in other components.
  public SelectedRawFiles = [];
  public SelectedFiles: FormularyData[] = [];
  constructor(private toastService: ToastService) { }

  ngOnInit() {
  }

  onFileDropped(event: Array<any>) {
    this.prepareFile(event);
  }
  fileBrowseHandler(files) {
    this.prepareFile(files);
  }
  private prepareFile(files: Array<any>) {
    for (const item of files) {
      if (item.type === 'application/vnd.ms-excel'
        || item.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        item.progress = 0;
        this.SelectedRawFiles = [];
        this.SelectedFiles = [];
        // create file object
        const formularyData = new FormularyData();
        formularyData.fileName = item.name;
        const reader = new FileReader();
        reader.onload = (readerEvent: any) => {
          // get file content as Base64 encoded
          const splitVal = reader.result.toString().split(',');
          if (splitVal.length > 0) {
            formularyData.fileContent = splitVal[1];
            // console.log("Base64", formularyData.fileContent);
          }
        };
        reader.readAsDataURL(item);
        this.SelectedRawFiles.push(item);
        this.SelectedFiles.push(formularyData);
        this.toastService.openToast('File Added', constants.ToastPanelType.done);
      } else {
        this.toastService.openToast('Format not recognized. Please upload a recognized file format', constants.ToastPanelType.warning);
      }
    }
    this.fileDropEl.nativeElement.value = '';
  }
  deleteFile(index: number) {
    if (this.SelectedFiles.length > 0 && index < this.SelectedFiles.length) {
      this.SelectedRawFiles.splice(index, 1);
      this.SelectedRawFiles = [];
      this.SelectedFiles.splice(index, 1);
      this.SelectedFiles = [];
      this.toastService.openToast('File Removed', constants.ToastPanelType.warning);
    }
  }
}
