import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddCddbDrugs } from '../models/cddb-drugs';
import { ListOfDrugLocationAtp } from '../models/customer-drug-info';
import { UpdateGroupCanister } from '../models/gcn-drugs';

@Injectable({
  providedIn: 'root'
})
export class ImToolAtpService {
  httpClient: HttpClient;
  url :string;
  constructor(http: HttpClient) {
    this.httpClient = http;
    this.url = environment.apiUrl.imToolService;
   }

   getAtpAnalizeData(
    deviceTypeId:number,
    customerId:string,
    daysOfUse:number,
    hcDrawers:number,
    smartDrawer:number,
    url: string = this.url + "AnalyzeDeviceForAtp"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params:{
        subDeviceTypeId:deviceTypeId,
        customerId:customerId,
        daysOfUse:daysOfUse,
        hcDrawer: hcDrawers,
        smartDrawer: smartDrawer
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getGroupCanister(
    customerId:string,
     url: string = this.url + "GetGroupCanisterLocationByCustomerId"
     ): Observable<any> {
    return this.httpClient.get(url, {
      params:{
        customerId:customerId,
      },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }



  finalImportForAtp(
    resource: UpdateGroupCanister,
    url: string = this.url + "FinalImportForAtp"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  addCddbDrugsAtp(
    cddbDrug:AddCddbDrugs,
    url: string = this.url + "AddCDDBDrugAtp"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(cddbDrug), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  drugAddAtp(
    resource: ListOfDrugLocationAtp,
    url: string = this.url + "AddDrugAtp"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  drugRemoveAtp(
    resource: ListOfDrugLocationAtp,
    url: string = this.url + "RemoveDrugAtp"
  ): Observable<any> {
    return this.httpClient.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  getAvailableCellLocationAtp(
    customerId,
    url: string = this.url + "GetAvailableCellLocAtp"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { customerId: customerId },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

}
