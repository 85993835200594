import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-pharmacy-peek-discard-changes-dialog',
  templateUrl: './pharmacy-peek-discard-changes-dialog.component.html',
  styleUrls: ['./pharmacy-peek-discard-changes-dialog.component.scss']
})
export class PharmacyPeekDiscardChangesDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PharmacyPeekDiscardChangesDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({undoChanges : false});
  }

  closeDialogUndo() {
    this.dialogRef.close({undoChanges : true});
  }


}
