import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { getUptimeDevice } from 'src/app/store/selectors/pharmacy-peek.selector';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';

@Component({
  selector: 'app-packager-uptime-device',
  templateUrl: './packager-uptime-device.component.html',
  styleUrls: ['./packager-uptime-device.component.scss']
})
export class PackagerUptimeDeviceComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() filterDeviceId: string;
  childOrg = '';
  step = -1;
  @ViewChild('todayOverview', { static: false }) todayOverviewRef: ElementRef;

  views = {
    overview: true,
  };

  uptimeData: any = null;
  overallIdleTime: string;
  overallIdleTimePercent: string;
  overallUptime: string;
  overallUptimePercent: string;
  overallUtilizationTime: string;
  overallUtilizationTimePercent: string;
  noDataExistsForUP = true;
  deviceId;

  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.UPTIME_DEVICE,
    deviceId: ''
  }

  constructor(public store: Store<State>,
     private signal: SignalRService,
     public toastService: ToastService,
     public viewStoreService: ViewStoreService,
     private loggerService: LoggerService,
     ) { }

  ngOnInit() {

    this.store.select(getUptimeDevice).subscribe(res => {
      if (res) {
      let resData : any = [...res];
        this.deviceId = this.filterDeviceId;
        if (this.filterDeviceId) {
          resData = resData.filter(t => t.deviceId == this.filterDeviceId);
        }

        if (resData == null) {
          return;
        }
        this.uptimeData = resData[0];
        this.noDataExistsForUP = false;
      }
    });

    this.subscription = this.signal.getDataStream<string>(SignalEventType.UPTIME_DEVICE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateUptimePackagerDevice({ data: dataObj.data }));
      console.log(dataObj);
    });

    this.signalrSubList = {
      type: SignalEventType.UPTIME_DEVICE,
      deviceId: this.filterDeviceId ? this.filterDeviceId : ''
    }

    this.callUptimeDevice();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
    this.deviceId = this.filterDeviceId ? this.filterDeviceId : ''; 
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  private callUptimeDevice() {
    const deviceId = this.filterDeviceId ? this.filterDeviceId : '';
    this.viewStoreService.getUpTimeDevice(deviceId)
      .subscribe(
        (response) => {
            this.store.dispatch(PharmacyPeekActions.getUptimePackagerDevice({ data: response }));
        },
        (error) => {
          console.log('callUptimeDevice error:', error);
          if (error.status === 404) {
            this.loggerService.logWarning('UptimeDevice Service: No Data: ', error);
          } else {
            this.toastService.openToast('UptimeDevice service load error!', ToastPanelType.error);
          }
        }
      );
  }

  setStep(index: number) {
    if (this.step === index)
      {this.step = -1;}
    else
      {this.step = index;}
  }

}
