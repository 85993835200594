import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as constants from 'src/app/config/app-constants';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { Organization } from '../../../customer-management/models/organization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../../../shared/services/toast.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutoOrder } from '../../models/auto-order';
import { CurrentStock } from '../../models/current-stock';
import { InventoryStock } from '../../models/inventory-stock';
import { InventoryService } from '../../services/inventory.service';
import { SubProduct } from '../../models/sub-product';
import { PackageSize } from '../../models/package-size';
import { OrderDetail } from '../../models/order-detail';
import { FileuploadComponent } from '../../../../shared/components/fileupload/fileupload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationService } from '../../../customer-management/services/organization.service';
import { AuthorizationService } from '../../../customer-management/services/authorization.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';


@Component({
  selector: 'app-consumable-order',
  templateUrl: './consumable-order.component.html',
  styleUrls: ['./consumable-order.component.scss']
})
export class ConsumableOrderComponent implements OnInit {


  //#region Fields
  @Input() isClicked = false;
  orderTableColumns: string[] = ['productName', 'partNumber', 'packageSize', 'quantity'];
  currentStockDataSource: MatTableDataSource<Product>;
  requiredStockDataSource: MatTableDataSource<Product>;
  formGroup: FormGroup;
  currentStockPouchesCount: number;
  notificaitonId: string;
  orgnizations: Organization[] = [];
  machineTypes: any[] = [];
  // save auto consume order
  autoOrderDetail: AutoOrder[] = [];
  currentStocks: CurrentStock[] = [];
  inventoryStock: InventoryStock;
  currentStockControls = 'currentStockControls';
  curStockSubProduct = 'curStockSubProduct';
  curStockPackageSize = 'curStockPackageSize';
  curStockQuantity = 'curStockQuantity';
  requiredStockControls = 'requiredStockControls';
  requiredStockSubProduct = 'requiredStockSubProduct';
  requiredStockPackageSize = 'requiredStockPackageSize';
  requiredStockQuantity = 'requiredStockQuantity';
  products: Product[];
  subProducts: SubProduct[];
  packageSizes: PackageSize[];
  orderDetail: OrderDetail[] = [];
  resp: CurrentStock[] = [];
  toUpdate: boolean;
  private processStarted = false;

  @ViewChild(FileuploadComponent, {static: false}) fileUpload: FileuploadComponent;

  //#endregion

  //#region Constructor & ngOnInit
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private inventoryService: InventoryService,
    private authorizationService: AuthorizationService,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.notificaitonId = this.route?.snapshot?.queryParamMap.get('rqid');
    this.formGroup = this.formBuilder.group({
      currentStockControls: this.formBuilder.array([]), // current stock
      requiredStockControls: this.formBuilder.array([]), // required stock
      pouchesCount: new FormControl(10000, Validators.required),
      organizationId: new FormControl('', Validators.required),
      machineType: new FormControl('', Validators.required),
    });
    this.ngxLoader.start();
    this.organizationService.getActiveOrganizations().subscribe(res => {
      this.orgnizations = res.filter(or => or?.is_Parent_Account === false);
    });

    // get products like Paper & Ribbon
    this.productService.getActiveProducts().subscribe(res => {
      this.products = res;
      const currentArry = (this.formGroup.get(this.currentStockControls) as FormArray);
      currentArry.clear();
      res.forEach(() => {
        currentArry.push(this.createCurStockFormGroup());
      });
      this.currentStockDataSource = new MatTableDataSource(res.sort((cur, next) => cur?.name < next?.name ? -1 : 1));
      // separate call to avoid same reference, otherwise packagesize dropdown selection will not retain.
      this.productService.getActiveProducts().subscribe(res => {
        const reqArry = (this.formGroup.get(this.requiredStockControls) as FormArray);
        reqArry.clear();
        res.forEach(() => {
          reqArry.push(this.createReqStockFormGroup());
        });
        this.requiredStockDataSource = new MatTableDataSource(res.sort((cur, next) => cur?.name < next?.name ? -1 : 1));
        this.getSubProduct();
        this.getPackage();
      }, () => { this.ngxLoader.stop(); });
      this.ngxLoader.stop();
    }, () => { this.ngxLoader.stop(); });

    if(this.isClicked)
    {
    this.route?.parent?.params.subscribe(({id}) => {
      this.organizationService.getByID(id)
      .subscribe((org) => {
        this.formGroup?.controls?.organizationId.setValue(org.organizationId);
        this.getOrganizationBasedMachine();
      });
    });
  }

  }

  //#endregion

  //#region Functions
  // to get machine type based on the organization selected
  getOrganizationBasedMachine() {
    this.resetFormValues();
    this.formGroup?.controls?.machineType.reset();
    this.machineTypes = this.authorizationService.getMachineTypes(this.formGroup?.controls?.organizationId?.value);

  }

  // get sub products like Partnumber & Description
  getSubProduct() {
    this.productService.getSubProducts().subscribe(res => {
      if (this.currentStockDataSource && this.requiredStockDataSource
        && this.currentStockDataSource?.data && this.requiredStockDataSource?.data) {
        this.subProducts = res;
        this.currentStockDataSource?.data.forEach(product => {
          product.subProducts = res.filter(sp => sp?.productId === product?.productId);
        });
        this.requiredStockDataSource?.data.forEach(product => {
          product.subProducts = res.filter(sp => sp?.productId === product?.productId);
        });
      }
    }, () => { this.ngxLoader.stop(); });
  }

  // get package size of each pouch
  getPackage() {
    this.productService.getPackageSize().subscribe(res => {
      if (this.currentStockDataSource && this.requiredStockDataSource
        && this.currentStockDataSource?.data && this.requiredStockDataSource?.data) {
        this.packageSizes = res;
        this.currentStockDataSource?.data.forEach(product => {
          product.packageSizes = product.holdPackageSizes = res;
        });
        this.requiredStockDataSource?.data.forEach(product => {
          product.packageSizes = product.holdPackageSizes = res;
        });
      }
    });
  }
  createCurStockFormGroup(): FormGroup {
    return this.formBuilder.group({
      curStockSubProduct: new FormControl('', Validators.required),
      curStockPackageSize: new FormControl('', Validators.required),
      curStockQuantity: new FormControl(5, Validators.required),
    });
  }
  createReqStockFormGroup(): FormGroup {
    return this.formBuilder.group({
      requiredStockSubProduct: new FormControl('', Validators.required),
      requiredStockPackageSize: new FormControl('', Validators.required),
      requiredStockQuantity: new FormControl(5, Validators.required),
    });
  }
  filterPackages(index: number, subId: string, dataSource: MatTableDataSource<Product>) {
    if (dataSource != null && dataSource.data.length > 0) {
      const product = dataSource.data[index];
      dataSource.data[index].packageSizes = product.holdPackageSizes.filter(hpk => hpk.subProductId === subId);
    }
  }
  getValue(rowIndex: number, isCurStock: boolean = true, getSubProduct: boolean = false, getPackageSize: boolean = false, getQuantity: boolean = false): string {
    const array = isCurStock ? this.currentStockControls : this.requiredStockControls;
    if (getSubProduct) {
      const control = isCurStock ? this.curStockSubProduct : this.requiredStockSubProduct;
      return (this.formGroup.get(array) as FormArray).controls[rowIndex].get(control).value;
    } else if (getPackageSize) {
      const control = isCurStock ? this.curStockPackageSize : this.requiredStockPackageSize;
      return (this.formGroup.get(array) as FormArray).controls[rowIndex].get(control).value;
    } else if (getQuantity) {
      const control = isCurStock ? this.curStockQuantity : this.requiredStockQuantity;
      return (this.formGroup.get(array) as FormArray).controls[rowIndex].get(control).value;
    }
  }

  subProductSelectionChanged(index: number, isCurStock: boolean = true) {
    isCurStock ? this.filterPackages(index, this.getValue(index, true, true), this.currentStockDataSource)
      : this.filterPackages(index, this.getValue(index, false, true), this.requiredStockDataSource);
  }

  // puches count based on current stock
  currentStockPackageSizeOrQuantityChanged(rowIndex: number, row: Product) {
    if (row && row.name && row.name.trim().toLowerCase() === constants.appConst.PAPER) {
      const pkgSize = row.holdPackageSizes.filter(pkg => pkg.subProductId === this.getValue(rowIndex, true, true));
      if (pkgSize && pkgSize.length > 0) {
        const puchesPerSize = pkgSize.filter(pkg => pkg.packageSizeId === this.getValue(rowIndex, true, false, true));
        if (puchesPerSize && puchesPerSize.length > 0) {
          this.currentStockPouchesCount = puchesPerSize[0].bagsPerRoll * +this.getValue(rowIndex, true, false, false, true);
        }
      }
    }
  }

  // to load data based on the organization and machine type selected
  machineTypeSelectionChanged() {
    this.inventoryService.getInventoryStockByOrgAndMachineType(this.formGroup.controls.organizationId.value, this.formGroup.controls.machineType.value).subscribe((res: any) => {
      this.toUpdate = true;
      this.formGroup.patchValue({
        pouchesCount: res.thresholdQty,
      });
      this.inventoryService.getCurrentStockByOrgAndMachineType(this.formGroup.controls.organizationId.value, this.formGroup.controls.machineType.value).subscribe((resCurrentStock: any) => {
        this.populateProductAndSubProduct(true, resCurrentStock);
      });
      this.inventoryService.getAutoOrderByOrgAndMachineType(this.formGroup.controls.organizationId.value, this.formGroup.controls.machineType.value).subscribe((resOrderDetails: any) => {
        this.populateProductAndSubProduct(false, resOrderDetails);
      });
    }, (err) => {
      // checked status to stop loading of other endpoints if the error is not found
      this.toUpdate = false;
      this.resetFormValues();
    });
  }

  populateProductAndSubProduct(curStock: boolean = true, resource: any) {
    const consumableDetails = [];
    if (resource && resource.length > 0) {
      for (let count = 0; count < resource.length; count++) {
        const pkg = this.packageSizes.filter(pkg => pkg.packageSizeId.trim() === resource[count].packageSizeId.trim() && resource[count].isActive === true);
        if (pkg && pkg.length > 0) {
          const sp = this.subProducts.filter(sp => sp.subProductId.trim() === pkg[0].subProductId.trim());
          if (sp && sp.length > 0) {
            const product = this.products.filter(p => p.productId.trim() === sp[0].productId.trim());
            let array = {};
            if (product && product.length > 0 && curStock) {
              array = {
                curStockPackageSize: pkg[0].packageSizeId,
                curStockSubProduct: sp[0].subProductId,
                curStockQuantity: resource[count].stockQty,
                orderId: resource[count].orderId,
                productName: product[0].name,
              };
            } else {
              array = {
                requiredStockPackageSize: pkg[0].packageSizeId,
                requiredStockSubProduct: sp[0].subProductId,
                requiredStockQuantity: resource[count].orderQty,
                orderId: resource[count].orderId,
                productName: product[0].name,
              };
            }
            consumableDetails.push(array);
          }
        }
      }
      const sortedDetails = consumableDetails.sort((cur, next) => cur.productName < next.productName ? -1 : 1);
      if (curStock) {
        this.formGroup.patchValue({
          currentStockControls: sortedDetails,
        });
      } else {
        this.formGroup.patchValue({
          requiredStockControls: sortedDetails,
        });
      }
      for (let i = 0; i < sortedDetails.length; i++) {
        this.subProductSelectionChanged(i, sortedDetails[i].orderSubProduct);
      }
    } else {
      this.ngxLoader.stop();
    }
  }
  disablePackageSizeDropwDown(rowIndex: number, isCurStock: boolean = true): boolean {
    const array = isCurStock ? this.currentStockControls : this.requiredStockControls;
    const control = isCurStock ? this.curStockSubProduct : this.requiredStockSubProduct;
    return (this.formGroup.get(array) as FormArray).controls[rowIndex].get(control).invalid;
  }
  resetFormValues() {
    this.formGroup.controls.currentStockControls.reset();
    this.formGroup.controls.requiredStockControls.reset();
    this.formGroup.controls.pouchesCount.reset();
    const currentStockDefValue = [];
    const reqStockDefValue = [];

    for (let i = 0; i < this.products.length; i++) {
      currentStockDefValue.push({ curStockQuantity: 5 });
      reqStockDefValue.push({ requiredStockQuantity: 5 });
    }
    this.formGroup.patchValue({
      currentStockControls: currentStockDefValue,
      requiredStockControls: reqStockDefValue,
      pouchesCount: 10000,
    });
  }
  submitAutoConsumebleOrder() {
    this.currentStocks = [];
    this.autoOrderDetail = [];
    this.inventoryStock = new InventoryStock();
    const curStkArray = this.formGroup.controls.currentStockControls as FormArray;
    for (let i = 0; i < curStkArray.controls.length; i++) {
      const curstock = new CurrentStock();
      curstock.packageSizeId = curStkArray.controls[i].get(this.curStockPackageSize).value;
      curstock.quantity = curStkArray.controls[i].get(this.curStockQuantity).value;
      this.currentStocks.push(curstock);
    }
    const reqStkArray = this.formGroup.controls.requiredStockControls as FormArray;
    for (let counter = 0; counter < reqStkArray.controls.length; counter++) {
      const order = new AutoOrder();
      order.packageSizeId = reqStkArray.controls[counter].get(this.requiredStockPackageSize).value;
      order.quantity = reqStkArray.controls[counter].get(this.requiredStockQuantity).value;
      this.autoOrderDetail.push(order);
    }
    this.inventoryStock = new InventoryStock();
    this.inventoryStock.organizationId = this.formGroup.controls.organizationId.value;
    const orgName = this.orgnizations.filter(org => org.organizationId === this.inventoryStock.organizationId);
    if (orgName && orgName.length > 0) {
      this.inventoryStock.organizationName = orgName[0].name;
    }
    this.inventoryStock.machineTypeId = this.formGroup.controls.machineType.value;
    this.inventoryStock.currentStock = this.currentStockPouchesCount;
    this.inventoryStock.thresholdQty = this.formGroup.controls.pouchesCount.value;
    this.inventoryStock.stockConsumed = 0;

    const inventoryStockOrder = {
      inventoryStock: this.inventoryStock,
      currentStocks: this.currentStocks,
      orderDetails: this.autoOrderDetail,
    };
    this.ngxLoader.start();
    if (!this.toUpdate) {
      this.inventoryService.createStockAndOrderDetails(inventoryStockOrder).subscribe(res => {
        this.responseHandler('Auto Consumable Order Setup Done Successfully.');
      }, () => {
        this.ngxLoader.stop();
      });
    } else {
      // if toUpdate flag is true call the update method
      this.inventoryService.UpdateInventoryAndAutoAutoOrder(
        this.formGroup.controls.organizationId.value,
        this.formGroup.controls.machineType.value,
        inventoryStockOrder,
      ).subscribe(res => {
        this.toUpdate = false;
        this.responseHandler('Auto Consumable Order Updated Successfully.');
      }, (error: HttpErrorResponse) => {
        const response = JSON.parse(error.error) || {};
        this.ngxLoader.stop();
        this.processStarted = false;
        this.toastService.openToast(response.message || 'Error while updating Auto Consumable Order!', constants.ToastPanelType.error);
      });
    }
  }

  responseHandler(message: string) {
    this.toastService.openToast(message, constants.ToastPanelType.done);
    this.ngxLoader.stop();
    if(this.isClicked)
    {this.router.navigateByUrl(`/${constants.routePaths.CUSTOMER_MANAGEMENT}`);}
    else
    {this.router.navigateByUrl(`/${constants.routePaths.DASHBOARD}`);}
  }

  disableSubmitOrder(): boolean {
    return this.formGroup && this.formGroup.invalid;
  }

  //#endregion

}
