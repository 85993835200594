
    <div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
      <div mat-dialog-title id="drug-details-header">
        <div class="flex justify-start content-center items-center flex-row">

            <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
             <h3>{{wizardTitle}}</h3>
            </div>
            <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
                <mat-icon (click)="this.dialogRef.close()" class="cross-wizard">
                    <img src="/assets/img/icons/ico.16.clear.svg" alt="">
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="inventory-info flex flex-row">
        <div class="inventory-left-side">

          <div *ngIf="inventoryWizard===1">
            <form [formGroup]="saveInfoModelForm">
            <table>
              <tr class="group-label-row">
                <td colspan="2">
                  <span class="group-label">Characteristics</span>
                  </td>
              </tr>
              <tr>
                <td colspan="2">
                  <span class="label">Generic Name</span>
                </td>
                <td colspan="2">
                  <span class="label">Strength</span>
                </td>
                <td colspan="2">
                  <span class="label">Unit</span>
                </td>
                <td colspan="2">
                  <span class="label">Drug Number</span>
                </td>
                <td colspan="2">
                  <span class="label">Inventory Number</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
               <span class="label-value-bold">{{this.drugInfoModel.genericName}}</span>
                </td>
                <td colspan="2">
                  <span class="label-value-bold">{{this.drugInfoModel.strength}}</span>
                </td>
                <td colspan="2">
                  <span class="label-value-bold">{{this.drugInfoModel.unit}}</span>
                </td>
                <td colspan="2">
                  <span class="label-value-bold">{{this.drugInfoModel.drugNumber}}</span>
                </td>

                <td colspan="2">
                  <span class="label-value">{{this.drugInfoModel.inventoryNumber}}</span>
                </td>
              </tr>
            </table>
            <div style="margin-top: 1rem;" class="input-row flex justify-between content-stretch items-stretch flex-row">
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span style="color:red">*</span> <span>Inventory</span>
                </div>
               
                  <div>
                    <input formControlName="quantity" onkeypress="return !(event.charCode == 46)" min="0" type="number">
                  </div>

                  <mat-error *ngIf="saveInfoModelForm.controls.quantity.hasError('required') && saveInfoModelForm.controls.quantity.touched">Inventory required</mat-error>
              </div>
              <div class="flex flex-col basis-1/2">
                  <span>Inventory Comments</span>
                  <input formControlName="inventoryComment" type="text">
              </div>
              <div class="flex flex-col basis-1/5">
                  <span>Price</span>
                  <input formControlName="price" onkeypress="return !(event.charCode == 46)" min="0" type="number">
              </div>

            </div>
            <div style="margin-top: 1rem;" class="input-row flex justify-between content-stretch items-stretch flex-row">
              <div class="flex flex-col basis-1/5">
                  <span>UPC</span>
                  <input type="text">
              </div>
              <div class="flex flex-col basis-1/2">
                  <span>2D Barcode</span>
                  <input type="text">
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span style="color:red">*</span> <span> Source</span>
                </div>
              
                  <div>
               
                    <input formControlName="source" type="text">

                  </div>
                  <mat-error *ngIf="saveInfoModelForm.controls.source.hasError('required') && saveInfoModelForm.controls.source.touched">Source required</mat-error>
              </div>

            </div>
            <div class="input-row flex justify-between content-stretch items-stretch flex-row">
              <div class="flex flex-col basis-1/5">
                  <span>Source Address</span>
                  <input  formControlName="sourceAddress"  type="text">
              </div>
              <div class="flex flex-col basis-1/2">
                  <span>Source Phone</span>
                  <input formControlName="sourcePhone" type="text">
              </div>
              <div class="flex flex-col basis-1/5">
                <span>Source Mail</span>
                <input formControlName="sourceMail" type="text">
            </div>


            </div>
          </form>
          </div>

          <div class="destroy-drug" *ngIf="inventoryWizard===2">
            <form [formGroup]="destroyInventoryForm">
            <h5>Inventory Record</h5>
            <div class="flex flex-row">
              <div class="stock-div basis-[10%]"><span>In Stock</span></div>
              <div class="basis-[90%]">
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Generic Name</span>
                    <span class="label-value">{{drugInfoModel.genericName}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Strength</span>
                    <span class="label-value">{{drugInfoModel.strength}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Unit</span>
                    <span class="label-value"> {{drugInfoModel.unit}} </span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Drug Number</span>
                    <span class="label-value"> {{drugInfoModel.drugNumber}}</span>
                  </div>
                </div>
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Inventory Number</span>
                    <span class="label-value">{{this.getInventoryInfo?.inventoryNumber}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Inventory</span>
                    <span class="label-value">{{this.getInventoryInfo.inventoryQuantity}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Source</span>
                    <span class="label-value">{{this.getInventoryInfo.source}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Added</span>
                    <span class="label-value">{{this.getInventoryInfo.addedDate}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Owner</span>
                    <span class="label-value">{{this.getInventoryInfo.owner}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Last Changed</span>
                    <span class="label-value">{{this.getInventoryInfo.lastChangedDate}}</span>
                  </div>
                </div>
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-1/2">
                    <span class="label">Inventory Comments</span>
                    <span class="label-value-bold">{{this.getInventoryInfo.comments}}</span>
                  </div>
                </div>
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-1/2">
                    <span class="label">Destruction Comments</span>
                    <input type="text" formControlName="destroyInventoryComment">
                  </div>
                  <div style="text-align: center;" class="flex flex-col basis-[30%]">
                    <span class="label">Date</span>
                    <span class="label-value">{{this.getInventoryInfo.destroyDate}}</span>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </div>
          <div class="drug-history-div" *ngIf="inventoryWizard===3">
            <h5>Inventory Record</h5>
            <div *ngIf="inventoryList.length===0" class="flex flex-row">
              <h3>No data found!!</h3>
            </div>
            <div *ngFor="let inventory of inventoryList" class="flex flex-row">
              <div [ngClass]="{'stock-div':!inventory.isDestroyed, 'destroy-div':inventory.isDestroyed}" class="basis-[10%]">
                <span *ngIf="!inventory.isDestroyed">In Stock</span>
                <span *ngIf="inventory.isDestroyed">Destroyed</span>
              </div>
              <div class="basis-[90%]">
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Generic Name</span>
                    <span class="label-value">{{drugInfoModel.genericName}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Strength</span>
                    <span class="label-value">{{drugInfoModel.strength}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Unit</span>
                    <span class="label-value">{{drugInfoModel.unit}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Drug Number</span>
                    <span class="label-value">{{drugInfoModel.drugNumber}}</span>
                  </div>
                </div>
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Inventory Number</span>
                    <span class="label-value">{{inventory.inventoryNumber}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Inventory</span>
                    <span class="label-value">{{inventory.inventoryQuantity}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Source</span>
                    <span class="label-value">{{inventory.source}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Added</span>
                    <span class="label-value">{{inventory.addedDate}}</span>
                  </div>
                  <div class="flex flex-col basis-[15%]">
                    <span class="label">Owner</span>
                    <span class="label-value">{{inventory.owner}}</span>
                  </div>
                  <div class="flex flex-col basis-1/5">
                    <span class="label">Last Changed</span>
                    <span class="label-value">{{inventory.lastChangedDate}}</span>
                  </div>
                </div>
                <div class="drug-info-row flex flex-row">
                  <div class="flex flex-col basis-1/2">
                    <span class="label">Inventory Comments</span>
                    <span class="label-value-bold">{{inventory.comments}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <!-- <div class="inventory-right-side">
          <div fxLayout="row" fxLayoutAlign="end">
            <div class="choose_file_pet" *ngIf="inventoryWizard===1" fxLayout="column">
              <div><p>Add New Image</p></div>
              <label for="choose_file_pet">
                <input type="file" id="choose_file_pet" accept=".csv, .xls, .xlsx" (change)="onFileSelected($event)">
                <span>Browse My Pc</span>
              </label>
            </div>
            <div *ngIf="fieleSelect">
              <img style="width: 200px; height: 200px;" [src]="this.fileContent" alt="drug image">
            </div>
          </div>


        </div> -->
    </div>

    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
    <button mat-button class="btn-blue-light btn-md" (click)="dialogRef.close()" id="CancelNewUser" aria-label="Cancel" type="button">Cancel</button>

        <button *ngIf="inventoryWizard===1" mat-button aria-label="Done" [ngClass]="{'btn-blue':saveInfoModelForm?.valid, 'btn-disabled':!saveInfoModelForm?.valid}" [disabled]="!saveInfoModelForm?.valid"  id="Next" (click)="save()"  class="btn-primary btn-md" 
        type="button">Next </button>

        <button *ngIf="inventoryWizard===2" mat-button aria-label="Done"  id="Done" (click)="update()"  class="btn-primary btn-md" 
        type="button">Done </button>

  </mat-dialog-actions>
    </div>



