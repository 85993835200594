<div floatLabel="never" [formGroup]="form">
  <div class="autocomplete-input flex flex-row">
    <input type="text" matInput appNoHtmlTags trim [formControlName]="controlName" [matAutocomplete]="auto">
    <button mat-button [ngStyle]="{'visibility': (siteSelectControl.value) ? 'visible' : 'hidden'}" mat-icon-button
      aria-label="" (click)="siteSelectControl.setValue('')" [disabled]="siteSelectControl.disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySite.bind(this)">
    <mat-option *ngFor="let site of filteredSites$ | async" [value]="site.customerNo" matTooltip="{{site.name}}"
      matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
      {{site.name}} ({{site.customerNo}})
    </mat-option>
  </mat-autocomplete>
</div>