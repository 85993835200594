<app-card title="">
    <div title class="flex justify-between content-start items-start">

            <mat-icon (click)="redirectCustomerList()" style="margin-bottom: 6px;">
                <img src="/assets/img/icons/ico.16.arwlft.svg" alt="">
            </mat-icon>
            <h5 style="padding-top: 5px;" class="ucase m-0 text-primaryblue xs:basis-full basis-[81%]" tabindex="0">{{customerName}} <span *ngIf="subDeviceTypeName !== null">({{subDeviceTypeName}} {{lockingCellCount+regularCellCount}}/{{superLockingCellCount+superCellCount}}, {{targetInstallDate}})</span></h5>
            <div class="flex justify-between content-center items-center flex-row">
            <mat-icon (click)="onAllUserPaginateChange()" style="padding-right: 14px; ">
                <img style="padding: 4px;" src="/assets/img/icons/ico.24.scrolltotop.svg" alt="">
            </mat-icon>
        </div>
        <div class="basis-[7%]">
          <button id="btn_cddb_add" (click)="openImportWizardCDDB()" class="btn-light btn-sm add_site"><span> CDDB Add </span></button>
        </div>

  <div class="basis-[11%]">
    <button id="btn_change_device" class="bg-red btn-sm" (click)="openImportWizard()">
        Change Device
      </button>

  </div>

            <mat-form-field floatLabel="never" class="basis-[11%]">
                <mat-select style="margin-top: 0rem !important;" matInput [value]="0"  (selectionChange)="downloadFile($event.value)">
                    <mat-option [disabled]="true"  [value]="0" style="text-align: center; background-color: rgb(202 198 198);"><span style="font-weight: bolder;">Export</span></mat-option>
                    <mat-option  [value]="1">Export XLSX</mat-option>
                    <mat-option  [value]="2">  Export XML</mat-option>
                    <mat-option  [value]="3" (click)="openAlertWindowFoManufactureFile()">Mini Cell Bartender XSL</mat-option>
                    <mat-option  [value]="3" (click)="printWorksheet()">Cell Locator Sheet PDF</mat-option>
                </mat-select>
            </mat-form-field>
    </div>
    <section class="table-section">
        <div class="sticky_search">
            <div class="bg-white" style="padding-bottom: 6px; padding-top: 6px;">
                <div class="flex justify-start content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row">

                    <div class="search-wrapper basis-[17%]" style="padding: 9px">
                      <div class="search-container">
                          <app-icon icon="search" class="icon_search"></app-icon>
                          <input class="search-input" appNoHtmlTags inputId="searchcustomers-input" (keyup)="doFilter($event.target.value)"  minLengthToSearch="1" placeholder="Search" [ngModel]="searchText"  id="search_customers">
                          <app-icon icon="clear" *ngIf="searchText.length>0" (click)="doFilter('')" id="data-clear" class="pointer"></app-icon>
                      </div>
                  </div>

                    <div class="project-stuats-div flex justify-between content-start items-start basis-1/5">
                        <button id="btn_add" (click)="openAlertWindowForDrugAdd()" [ngClass]="{'btn-light':drugCellLocationList.length>0 && this.tabSelected === 0, 'mat-button-disabled':drugCellLocationList.length==0}" [disabled]="drugCellLocationList.length==0 || this.tabSelected
                        !== 0" class=" btn-sm add_site"> <span>Add</span>  </button>

                        <button id="remove_celllocation" [ngClass]="{'btn-primary':drugCellLocationList.length>0 && this.tabSelected === 0, 'mat-button-disabled':drugCellLocationList.length==0}" [disabled]="drugCellLocationList.length==0 || this.tabSelected !== 0" (click)="openAlertWindowForDrugRemove()" class=" btn-sm add_site">
                          <span>Remove</span>   </button>

                          <button id="move_celllocation" 
                          [ngClass]="{'btn-danger':drugCellLocationList.length>0, 'mat-button-disabled':drugCellLocationList.length==0}" 
                          [disabled]="drugCellLocationList.length==0" (click)="openImportWizardDrugMovingComponent()" 
                          class=" btn-sm add_site" >
                          Move
                      </button>


                    </div>

                    <div class="cell-count-div flex justify-between content-start items-start basis-[16%]">
                        <button class="mat-button-disabled  btn-sm"  mat-raised-button
                         
                        [matTooltip]="'  Remaining Regular  : ' + unconfiguredRegularCount + '\n' +
                                        'Remaining Regular Locking    : ' + unconfiguredRegularLockingCount "

                        matTooltipPosition="below"
                        matTooltipClass="custom-tooltip"
                        aria-label="Button that displays a multi-line tooltip when focused or hovered over"> 
                         <span>Remaining Cell : {{remainingCellCount}}</span></button>

                    </div>

                    <div class="super-cell-count-div flex justify-between content-start items-start basis-[17%]">
                        <button class="mat-button-disabled  btn-sm"  mat-raised-button
                         
                        [matTooltip]="'  Remaining Super  : ' + unconfiguredSuperCount + '\n' +
                                        'Remaining Super Locking    : ' + unconfiguredSuperLockingCount "

                        matTooltipPosition="below"
                        matTooltipClass="custom-tooltip"
                        aria-label="Button that displays a multi-line tooltip when focused or hovered over"> 
                         <span>Remaining Super Cell : {{remainingSuperCellCount}}</span></button>

                    </div>

                    <div class="flex justify-start content-center items-center basis-[29%]">
                        <span class="flex justify-center content-stretch items-stretch basis-[19.3%]">Cell Type</span>
                        <mat-form-field floatLabel="never" class="flex justify-start content-stretch items-stretch basis-[50.3%] sm:basis-1/2">
                            <mat-select matInput  [value]="0" (selectionChange)="getRecommendedDrugData(customerId, $event.value)">
                                <mat-option  [value]="0">All</mat-option>
                                <mat-option  [value]="1">Regular Cell : {{regularCellCount}}</mat-option>
                                <mat-option  [value]="2">Super Cell : {{superCellCount}}</mat-option>
                                <mat-option  [value]="3">Locking Cell : {{lockingCellCount}}</mat-option>
                                <mat-option  [value]="4">Super Locking Cell : {{superLockingCellCount}}</mat-option>
                                <mat-option  [value]="5">Alternate, No Cell: {{alternateCellCount}}</mat-option>
                                <mat-option  [value]="6">All Assigned Drugs: {{allAssignDrugCount}}</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                    <div style="text-align: center; margin-top: 1rem; color:red;" *ngIf="gcnDrugList.length>0">Similar GCN found for multiple drugs.<span style="text-decoration: underline;
                        color: blue;" (click)="openImportWizardGCN()">Click here</span> to adjust.</div>


            </div>
        </div>
        <div class="setup_scroll" >
            <mat-tab-group (selectedTabChange)="getCurrentTabSelect($event)">
                <mat-tab label="Analysis({{dataSource.data.length}})">
                    <div class="mat-elevation-z8" #TABLE>
                        <table  mat-table #table [dataSource]="dataSource" id ="matTable" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>
                              <!-- Top Order Column -->
                         <ng-container matColumnDef="topOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                            <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                            <!--NDC column-->

                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC </th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!--genericSequenceNumber Column-->
                            <ng-container matColumnDef="genericSequenceNumber">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> GCN </th>
                              <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}} </td>
                          </ng-container>

                            <!-- Drug Name Column -->
                            <ng-container matColumnDef="drugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>
                            <!-- Customer Drug Name Column -->
                            <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                            </ng-container>


                            <!-- Strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthShow" mat-header-cell *matHeaderCellDef> Strength </th>
                                <td [hidden]="!isStrengthShow" mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>


                            <!-- isUnRegulated Column -->
                            <ng-container matColumnDef="isUnRegulated">
                                <th [hidden]="!isUnRegulatedColumnShow" mat-header-cell *matHeaderCellDef> isUnRegulated </th>
                                <td [hidden]="!isUnRegulatedColumnShow" mat-cell *matCellDef="let element"> {{element.isUnregulated}} </td>
                            </ng-container>                            

                            <!-- Pills Disp Column -->
                            <ng-container matColumnDef="usage">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{orderOrUsage}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                            </ng-container>


                            <!-- No Of Orders Column -->
                            <ng-container matColumnDef="noOfOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{phllDispLable}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.noOfOrder}} </td>
                            </ng-container>


                            <!-- Cell Cap Column -->
                            <ng-container matColumnDef="cellCapacity">
                                <th mat-header-cell [hidden]="!isCellCapShow" *matHeaderCellDef mat-sort-header> Cell Cap </th>
                                <td mat-cell [hidden]="!isCellCapShow" *matCellDef="let element"> {{element.cellCapacity}}</td>
                            </ng-container>

                            <!-- Super Cell Cap Column -->
                            <ng-container matColumnDef="superCellCapacity">
                                <th mat-header-cell [hidden]="!isSuperCellCapShow" *matHeaderCellDef mat-sort-header> Super Cell Cap </th>
                                <td mat-cell [hidden]="!isSuperCellCapShow" *matCellDef="let element"> {{element.superCellCapacity}}</td>
                            </ng-container>

                            <!-- Ratio Column -->
                            <ng-container matColumnDef="ratio">
                                <th mat-header-cell [hidden]="!isRatioShow" *matHeaderCellDef mat-sort-header> Ratio </th>
                                <td mat-cell [hidden]="!isRatioShow" *matCellDef="let element"> {{element.ratio}}</td>
                            </ng-container>

                            <!-- Package Size Column -->
                            <ng-container matColumnDef="packageSize">
                                <th mat-header-cell [hidden]="!isPackageSizeShow" *matHeaderCellDef mat-sort-header> Package Size </th>
                                <td mat-cell [hidden]="!isPackageSizeShow" *matCellDef="let element"> {{element.packageSize}}</td>
                            </ng-container>

                            <!-- Manufacturer Column -->
                            <ng-container matColumnDef="manufacturer">
                                <th mat-header-cell [hidden]="!ismanufacturerShow" *matHeaderCellDef mat-sort-header> Manufacturer </th>
                                <td mat-cell [hidden]="!ismanufacturerShow" *matCellDef="let element"> {{element.manufacturer}}</td>
                            </ng-container>

                            <!-- Tab Column -->
                            <ng-container matColumnDef="tab">
                                <th mat-header-cell [hidden]="!isTabShow" *matHeaderCellDef mat-sort-header> Tab </th>
                                <td mat-cell [hidden]="!isTabShow" *matCellDef="let element"> {{element.tab}}</td>
                            </ng-container>

                            <!-- Tab Column -->
                            <ng-container matColumnDef="automate">
                                <th mat-header-cell [hidden]="!isautomateShow" *matHeaderCellDef mat-sort-header> Automate </th>
                                <td mat-cell [hidden]="!isautomateShow" *matCellDef="let element"> {{element.automate}}</td>
                            </ng-container>


                            <!-- Rec. Super Cell Column -->
                            <ng-container matColumnDef="recSuperCell">
                                <th mat-header-cell [hidden]="!isrecSuperCellShow" *matHeaderCellDef mat-sort-header> Rec. Super Cell </th>
                                <td mat-cell [hidden]="!isrecSuperCellShow" *matCellDef="let element"> {{element.recSuperCell}}</td>
                            </ng-container>

                            <!-- Rec. Locking Cell Column -->
                            <ng-container matColumnDef="recLockingCell">
                                <th mat-header-cell [hidden]="!isrecLockingCellShow" *matHeaderCellDef mat-sort-header> Rec. Locking Cell
                                </th>
                                <td mat-cell [hidden]="!isrecLockingCellShow" *matCellDef="let element"> {{element.recLockingCell}}</td>
                            </ng-container>
                            <!-- Pkg Size Std Cell Column -->
                            <ng-container matColumnDef="packageSizefitstandardcell">
                                <th mat-header-cell [hidden]="!ispackageSizefitstandardcellShow" *matHeaderCellDef mat-sort-header> Pkg Size Std Cell </th>
                                <td mat-cell [hidden]="!ispackageSizefitstandardcellShow" *matCellDef="let element">
                                    {{element.packageSizefitstandardcell}}</td>
                            </ng-container>




                            <!-- 30 Dram Column -->
                            <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell [hidden]="!isDramShow" *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell [hidden]="!isDramShow" *matCellDef="let element"> {{element.thirtyDramCapacity}}</td>
                            </ng-container>

                            <!-- Schedule Column -->
                            <ng-container matColumnDef="sch">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule </th>
                                <td mat-cell *matCellDef="let element"> {{element.sch}} </td>
                            </ng-container>

                            <!-- Cell Location Column -->
                            <ng-container matColumnDef="cellLoc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Loc. </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="selectedRow != element && element.checked">{{element.cellLoc}}</span>
                                    <span *ngIf="selectedRow != element && !element.checked">{{element.cellLocOld}}</span>

                                    <mat-form-field *ngIf="selectedRow == element">
                                        <mat-select  [disabled]="!element.checked" (selectionChange)="drugCellLocationChanged(element)" [(ngModel)]="element.cellLoc">
                                            <mat-option   *ngFor="let row of celLogList" [value]="row.value">{{row.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- Cell Size Column -->
                            <ng-container matColumnDef="cellType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Type </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.cellType}}
                                </td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style="margin: 0px 0px 0px 15px">
                                      <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="after">
                                      <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" class="table-dynamic-column flex-row" [(ngModel)]="isCellCapShow">
                                          Cell Cap
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" class="table-dynamic-column flex-row" [(ngModel)]="isDramShow">
                                          30 Dram
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSuperCellCapShow" class="table-dynamic-column flex-row">
                                          Super Cell Cap
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isRatioShow" class="table-dynamic-column flex-row">
                                          Ratio
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackageSizeShow" class="table-dynamic-column flex-row">
                                          Package Size
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isTabShow" class="table-dynamic-column flex-row">
                                          Tab
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isautomateShow" class="table-dynamic-column flex-row">
                                          Automate
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecSuperCellShow" class="table-dynamic-column flex-row">
                                          Rec. Super Cell
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecLockingCellShow" class="table-dynamic-column flex-row">
                                          Rec. Locking Cell
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ismanufacturerShow" class="table-dynamic-column flex-row">
                                          Manufacturer
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ispackageSizefitstandardcellShow" class="table-dynamic-column flex-row">
                                          Pkg Size Std Cell
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dynamic-column flex-row">
                                          Strength
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isUnRegulatedColumnShow" class="table-dynamic-column flex-row">
                                            isUnRegulated
                                          </mat-checkbox>

                                      </div>
                                      <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dynamic-column btn btn-primary btn-sm">Ok</button>
                                    </mat-menu>
                                  </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" (mouseenter)="selectedRow = row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>

                <mat-tab label="New Drugs({{dataSourceNotAbailable.data.length}})">
                    <div class="mat-elevation-z8" #TABLE1>
                        <table mat-table #table1 [dataSource]="dataSourceNotAbailable" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- Drug Name Column -->
                            <ng-container matColumnDef="drugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>


                            <!-- Customer Drug Name Column -->
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                              </ng-container>

                            <!-- nonCountReason Column -->
                            <ng-container matColumnDef="nonCountReason">
                                <th mat-header-cell [hidden]="!isNonCountReasonNotAvailableShow" *matHeaderCellDef> Non Count Reason </th>
                                <td mat-cell [hidden]="!isNonCountReasonNotAvailableShow" *matCellDef="let element"> {{element.nonCountReason}} </td>
                            </ng-container>


                            <!-- rxQty Column -->
                            <ng-container matColumnDef="rxQty">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                                <td mat-cell *matCellDef="let element"> {{element.rxQty}}</td>
                            </ng-container>

                            <!-- sch Column -->
                            <ng-container matColumnDef="sch">
                                <th mat-header-cell [hidden]="!isSCHNotAvailablehow" *matHeaderCellDef mat-sort-header> Schedule </th>
                                <td mat-cell [hidden]="!isSCHNotAvailablehow" *matCellDef="let element"> {{element.sch}}</td>
                            </ng-container>

                            <!-- status Column -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let element"> {{element.status}}</td>
                            </ng-container>

                            <!-- strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthNotAvailableShow" mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                                <td [hidden]="!isStrengthNotAvailableShow" mat-cell *matCellDef="let element"> {{element.strength}}</td>
                            </ng-container>

                            <!-- topOrder Column -->
                            <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}}</td>
                            </ng-container>

                            <!-- Tab Column -->
                            <ng-container matColumnDef="unitQty">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                                <td mat-cell *matCellDef="let element"> {{element.unitQty}}</td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                            <!-- NDC Column -->
                            <ng-container matColumnDef="ndc">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC </th>
                              <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                          </ng-container>

                          <!--genericSequenceNumber Column-->
                          <ng-container matColumnDef="genericSequenceNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GCN </th>
                            <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}} </td>
                        </ng-container>

                          <!-- Pills Disp Column -->
                          <ng-container matColumnDef="usage">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{orderOrUsage}} </th>
                              <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                          </ng-container>

                          <!-- No Of Orders Column -->
                          <ng-container matColumnDef="noOfOrder">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{phllDispLable}} </th>
                              <td mat-cell *matCellDef="let element"> {{element.noOfOrder}} </td>
                          </ng-container>

                          <!-- Cell Cap Column -->
                          <ng-container matColumnDef="cellCapacity">
                              <th mat-header-cell [hidden]="!isCellCapShow" *matHeaderCellDef mat-sort-header> Cell Cap </th>
                              <td mat-cell [hidden]="!isCellCapShow" *matCellDef="let element"> {{element.cellCapacity}}</td>
                          </ng-container>

                          <!-- Super Cell Cap Column -->
                          <ng-container matColumnDef="superCellCapacity">
                              <th mat-header-cell [hidden]="!isSuperCellCapShow" *matHeaderCellDef mat-sort-header> Super Cell Cap </th>
                              <td mat-cell [hidden]="!isSuperCellCapShow" *matCellDef="let element"> {{element.superCellCapacity}}</td>
                          </ng-container>

                          <!-- Ratio Column -->
                          <ng-container matColumnDef="ratio">
                              <th mat-header-cell [hidden]="!isRatioShow" *matHeaderCellDef mat-sort-header> Ratio </th>
                              <td mat-cell [hidden]="!isRatioShow" *matCellDef="let element"> {{element.ratio}}</td>
                          </ng-container>

                          <!-- Package Size Column -->
                          <ng-container matColumnDef="packageSize">
                              <th mat-header-cell [hidden]="!isPackageSizeShow" *matHeaderCellDef mat-sort-header> Package Size </th>
                              <td mat-cell [hidden]="!isPackageSizeShow" *matCellDef="let element"> {{element.packageSize}}</td>
                          </ng-container>

                          <!-- Manufacturer Column -->
                          <ng-container matColumnDef="manufacturer">
                              <th mat-header-cell [hidden]="!ismanufacturerNotAvailableShow" *matHeaderCellDef mat-sort-header> Manufacturer </th>
                              <td mat-cell [hidden]="!ismanufacturerNotAvailableShow" *matCellDef="let element"> {{element.manufacturer}}</td>
                          </ng-container>

                          <!-- Tab Column -->
                          <ng-container matColumnDef="tab">
                              <th mat-header-cell [hidden]="!isTabShow" *matHeaderCellDef mat-sort-header> Tab </th>
                              <td mat-cell [hidden]="!isTabShow" *matCellDef="let element"> {{element.tab}}</td>
                          </ng-container>

                          <!-- Tab Column -->
                          <ng-container matColumnDef="automate">
                              <th mat-header-cell [hidden]="!isautomateShow" *matHeaderCellDef mat-sort-header> Automate </th>
                              <td mat-cell [hidden]="!isautomateShow" *matCellDef="let element"> {{element.automate}}</td>
                          </ng-container>


                          <!-- Rec. Super Cell Column -->
                          <ng-container matColumnDef="recSuperCell">
                              <th mat-header-cell [hidden]="!isrecSuperCellShow" *matHeaderCellDef mat-sort-header> Rec. Super Cell </th>
                              <td mat-cell [hidden]="!isrecSuperCellShow" *matCellDef="let element"> {{element.recSuperCell}}</td>
                          </ng-container>

                          <!-- Rec. Locking Cell Column -->
                          <ng-container matColumnDef="recLockingCell">
                              <th mat-header-cell [hidden]="!isrecLockingCellShow" *matHeaderCellDef mat-sort-header> Rec. Locking Cell
                              </th>
                              <td mat-cell [hidden]="!isrecLockingCellShow" *matCellDef="let element"> {{element.recLockingCell}}</td>
                          </ng-container>
                          <!-- Pkg Size Std Cell Column -->
                          <ng-container matColumnDef="packageSizefitstandardcell">
                              <th mat-header-cell [hidden]="!ispackageSizefitstandardcellShow" *matHeaderCellDef mat-sort-header> Pkg Size Std Cell </th>
                              <td mat-cell [hidden]="!ispackageSizefitstandardcellShow" *matCellDef="let element">
                                  {{element.packageSizefitstandardcell}}</td>
                          </ng-container>

                          <!-- 30 Dram Column -->
                          <ng-container matColumnDef="thirtyDramCapacity">
                              <th mat-header-cell [hidden]="!isDramShow" *matHeaderCellDef mat-sort-header> 30 Dram </th>
                              <td mat-cell [hidden]="!isDramShow" *matCellDef="let element"> {{element.thirtyDramCapacity}}</td>
                          </ng-container>

                          <!-- Cell Size Column -->
                          <ng-container matColumnDef="cellType">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Type </th>
                              <td mat-cell *matCellDef="let element">
                                  {{element.cellType}}
                              </td>
                          </ng-container>



                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                 
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                                        <div class="checkbox-container">
                                        <!--Non count Reason-->
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isNonCountReasonNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Non Count Reason
                                        </mat-checkbox>
                                        <!--Manufacturer-->
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ismanufacturerNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Manufacturer
                                        </mat-checkbox>
                                        <!--SCH-->
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSCHNotAvailablehow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            SCH
                                        </mat-checkbox>
                                        <!--Strength-->
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          Strength
                                      </mat-checkbox>
                                      <!--Cell Cap-->
                                      <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isCellCapShow" style="border-bottom: 0px solid black;">
                                        Cell Cap
                                    </mat-checkbox>
                                       <!--30 Dram-->
                                    <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isDramShow" style="border-bottom: 0px solid black;">
                                        30 Dram
                                    </mat-checkbox>

                                    <!-- Super Cell Cap-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSuperCellCapShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Super Cell Cap
                                    </mat-checkbox>
                                    <!--Ratio-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isRatioShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Ratio
                                    </mat-checkbox>

                                    <!--Package Size-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackageSizeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Package Size
                                    </mat-checkbox>
                                    <!-- Tab-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isTabShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Tab
                                    </mat-checkbox>

                                    <!-- Automate-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isautomateShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Automate
                                    </mat-checkbox>
                                    <!-- ec. Super Cell-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecSuperCellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Rec. Super Cell
                                    </mat-checkbox>
                                    <!-- Rec. Locking Cell-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecLockingCellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Rec. Locking Cell
                                    </mat-checkbox>
                                    <!-- Pkg Size Std Cell-->
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ispackageSizefitstandardcellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Pkg Size Std Cell
                                    </mat-checkbox>

                                    <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                </div>
                                    </mat-menu>
                                
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsNotAvailable"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsNotAvailable;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSourceNotAbailable.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsNotAvailable.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>

                <mat-tab label="Excluded({{dataSourceExcluded.data.length}})">
                    <div class="mat-elevation-z8" #TABLE2>
                        <table mat-table #table2 class="mat-elevation-z8" [dataSource]="dataSourceExcluded" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>

                              <!-- Top Order Column -->
                              <ng-container matColumnDef="topOrder">
                                <th [hidden]="!isToOrderExcludeShow" mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                                <td [hidden]="!isToOrderExcludeShow" mat-cell *matCellDef="let element"> {{element.sl}} </td>
                            </ng-container>

                            <!-- excludedReason Column -->
                            <ng-container matColumnDef="excludedReason">
                                <th mat-header-cell  *matHeaderCellDef> Excluded Reason </th>
                                <td mat-cell  *matCellDef="let element"> {{element.excludedReason}} </td>
                            </ng-container>

                            <!-- NDC Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC </th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- Drug Name Column -->
                            <ng-container matColumnDef="drugName">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>

                            <!-- name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Customer Drug Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                            </ng-container>

                          <!--genericSequenceNumber Column-->
                          <ng-container matColumnDef="genericSequenceNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> GCN </th>
                            <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}} </td>
                        </ng-container>

                            <!-- Strength Column -->
                            <ng-container matColumnDef="strength">
                                <th [hidden]="!isStrengthShow" mat-header-cell *matHeaderCellDef> Strength </th>
                                <td [hidden]="!isStrengthShow" mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>
                            <!-- Schedule Column -->
                            <ng-container matColumnDef="sch">
                                <th mat-header-cell *matHeaderCellDef> Schedule </th>
                                <td mat-cell *matCellDef="let element"> {{element.sch}} </td>
                            </ng-container>

                            <!-- recSuperCell Column -->
                            <ng-container matColumnDef="recSuperCell">
                                <th mat-header-cell [hidden]="!isrecSuperCellExcludeShow" *matHeaderCellDef> Rec. Super Cell </th>
                                <td mat-cell [hidden]="!isrecSuperCellExcludeShow" *matCellDef="let element"> {{element.recSuperCell}} </td>
                            </ng-container>

                            <!-- lockingCell Column -->
                            <ng-container matColumnDef="lockingCell">
                                <th mat-header-cell [hidden]="!isrecLockingCellExcludeShow" *matHeaderCellDef> Locking Cell </th>
                                <td mat-cell [hidden]="!isrecLockingCellExcludeShow" *matCellDef="let element"> {{element.lockingCell}} </td>
                            </ng-container>

                            <!-- manufacturer Column -->
                            <ng-container matColumnDef="manufacturer">
                                <th mat-header-cell [hidden]="!ismanufacturerExcludeShow" *matHeaderCellDef> Manufacturer </th>
                                <td mat-cell [hidden]="!ismanufacturerExcludeShow" *matCellDef="let element"> {{element.manufacturer}} </td>
                            </ng-container>

                            <!-- rxQty Column -->
                            <ng-container matColumnDef="rxQty">
                                <th [hidden]="!isRxQtyShow" mat-header-cell *matHeaderCellDef mat-sort-header> Rx. Qty </th>
                                <td [hidden]="!isRxQtyShow" mat-cell *matCellDef="let element"> {{element.rxQty}}</td>
                            </ng-container>

                            <!-- unitQty  Column -->
                            <ng-container matColumnDef="unitQty">
                                <th [hidden]="!isUnitQty" mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                                <td [hidden]="!isUnitQty" mat-cell *matCellDef="let element"> {{element.unitQty}}</td>
                            </ng-container>

                            <!-- thirtyDramCapacity Column -->
                            <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell [hidden]="!isThirtyDramExcludeShow" *matHeaderCellDef mat-sort-header> 30 Dram Capacity </th>
                                <td mat-cell [hidden]="!isThirtyDramExcludeShow" *matCellDef="let element"> {{element.thirtyDramCapacity}}</td>
                            </ng-container>

                              <!-- cellCapacity Column -->
                              <ng-container matColumnDef="cellCapacity">
                                <th mat-header-cell [hidden]="!isCellCapacityExcludeShow" *matHeaderCellDef> Cell Capacity</th>
                                <td mat-cell [hidden]="!isCellCapacityExcludeShow" *matCellDef="let element"> {{element.cellCapacity}}</td>
                            </ng-container>

                            <!-- superCellCapacity Column -->
                            <ng-container matColumnDef="superCellCapacity">
                                <th mat-header-cell [hidden]="!isSuperCellCapExcludeShow" *matHeaderCellDef> Super Cell Capacity </th>
                                <td mat-cell [hidden]="!isSuperCellCapExcludeShow" *matCellDef="let element"> {{element.superCellCapacity}}</td>
                            </ng-container>




                            <!-- packageSize Column -->
                            <ng-container matColumnDef="packageSize">
                                <th mat-header-cell [hidden]="!isPackageSizeExcludeShow" *matHeaderCellDef> Package Size</th>
                                <td mat-cell [hidden]="!isPackageSizeExcludeShow" *matCellDef="let element"> {{element.packageSize}}</td>
                            </ng-container>

                            <!-- packageSizeFit Column -->
                            <ng-container matColumnDef="packageSizeFit">
                                <th mat-header-cell [hidden]="!isPackageSizeFitExcludeShow" *matHeaderCellDef> Package Size Fit</th>
                                <td mat-cell [hidden]="!isPackageSizeFitExcludeShow" *matCellDef="let element"> {{element.packageSizeFit}}</td>
                            </ng-container>

                            <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->

                          <!-- Pills Disp Column -->
                          <ng-container matColumnDef="usage">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{orderOrUsage}} </th>
                              <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                          </ng-container>



                      <!-- No Of Orders Column -->
                      <ng-container matColumnDef="noOfOrder">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{phllDispLable}} </th>
                          <td mat-cell *matCellDef="let element"> {{element.noOfOrder}} </td>
                      </ng-container>


                          <!-- Ratio Column -->
                          <ng-container matColumnDef="ratio">
                              <th mat-header-cell [hidden]="!isRatioShow" *matHeaderCellDef mat-sort-header> Ratio </th>
                              <td mat-cell [hidden]="!isRatioShow" *matCellDef="let element"> {{element.ratio}}</td>
                          </ng-container>

                          <!-- Tab Column -->
                          <ng-container matColumnDef="tab">
                              <th mat-header-cell [hidden]="!isTabShow" *matHeaderCellDef mat-sort-header> Tab </th>
                              <td mat-cell [hidden]="!isTabShow" *matCellDef="let element"> {{element.tab}}</td>
                          </ng-container>

                          <!-- automate Column -->
                          <ng-container matColumnDef="automate">
                              <th mat-header-cell [hidden]="!isautomateShow" *matHeaderCellDef mat-sort-header> Automate </th>
                              <td mat-cell [hidden]="!isautomateShow" *matCellDef="let element"> {{element.automate}}</td>
                          </ng-container>

                          <!-- Rec. Super Cell Column -->
                          <ng-container matColumnDef="recSuperCell">
                              <th mat-header-cell [hidden]="!isrecSuperCellExcludeShow" *matHeaderCellDef mat-sort-header> Rec. Super Cell </th>
                              <td mat-cell [hidden]="!isrecSuperCellExcludeShow" *matCellDef="let element"> {{element.recSuperCell}}</td>
                          </ng-container>

                          <!-- Rec. Locking Cell Column -->
                          <ng-container matColumnDef="recLockingCell">
                              <th mat-header-cell [hidden]="!isrecLockingCellShow" *matHeaderCellDef mat-sort-header> Rec. Locking Cell
                              </th>
                              <td mat-cell [hidden]="!isrecLockingCellShow" *matCellDef="let element"> {{element.recLockingCell}}</td>
                          </ng-container>
                          <!-- Pkg Size Std Cell Column -->
                          <ng-container matColumnDef="packageSizefitstandardcell">
                              <th mat-header-cell [hidden]="!ispackageSizefitstandardcellShow" *matHeaderCellDef mat-sort-header> Pkg Size Std Cell </th>
                              <td mat-cell [hidden]="!ispackageSizefitstandardcellShow" *matCellDef="let element">
                                  {{element.packageSizefitstandardcell}}</td>
                          </ng-container>

                              <!-- drugStatus Column -->
                              <!-- <ng-container matColumnDef="drugStatus">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                  <td mat-cell  *matCellDef="let element">
                                      {{element.drugStatus}}
                                  </td>
                              </ng-container> -->
                                <!-- Super Cell Cap Column -->
                                <ng-container matColumnDef="superCellCapacity">
                                  <th mat-header-cell [hidden]="!isSuperCellCapShow" *matHeaderCellDef mat-sort-header> Super Cell Cap </th>
                                  <td mat-cell [hidden]="!isSuperCellCapShow" *matCellDef="let element"> {{element.superCellCapacity}}</td>
                              </ng-container>

                              <!-- Ratio Column -->
                              <ng-container matColumnDef="ratio">
                                  <th mat-header-cell [hidden]="!isRatioShow" *matHeaderCellDef mat-sort-header> Ratio </th>
                                  <td mat-cell [hidden]="!isRatioShow" *matCellDef="let element"> {{element.ratio}}</td>
                              </ng-container>

                              <!-- Package Size Column -->
                              <ng-container matColumnDef="packageSize">
                                  <th mat-header-cell [hidden]="!isPackageSizeShow" *matHeaderCellDef mat-sort-header> Package Size </th>
                                  <td mat-cell [hidden]="!isPackageSizeShow" *matCellDef="let element"> {{element.packageSize}}</td>
                              </ng-container>


                                <!-- Cell Size Column -->
                                <ng-container matColumnDef="cellType">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Type </th>
                                  <td mat-cell *matCellDef="let element">
                                      {{element.cellType}}
                                  </td>
                              </ng-container>


                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                                        <div class="checkbox-container">
                                        <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isThirtyDramExcludeShow" style="border-bottom: 0px solid black;">
                                            30 Dram Capacity
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isCellCapacityExcludeShow" style="border-bottom: 0px solid black;">
                                            Cell Capacity
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isToOrderExcludeShow" style="border-bottom: 0px solid black;">
                                            Top Order
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSuperCellCapExcludeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Super Cell Capacity
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isRatioShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Ratio
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackageSizeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Package Size
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackageSizeFitExcludeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Package Size Fit
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ismanufacturerExcludeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Manufacturer
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isRxQtyShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          Rx Qty
                                      </mat-checkbox>
                                      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isUnitQty" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Unit Qty
                                    </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecSuperCellExcludeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Rec. Super Cell
                                        </mat-checkbox>
                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecLockingCellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                            Locking Cell
                                        </mat-checkbox>

                                        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Strength
                                      </mat-checkbox>

    <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>

                                      </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsExcluded"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsExcluded;"></tr>


                        </table>
                        <table>
                            <tr *ngIf="dataSourceExcluded.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsExcluded.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Not Oral Solid({{dataSourceOral.data.length}})">
                    <div class="mat-elevation-z8" #TABLE3>
                        <table mat-table #table3 [dataSource]="dataSourceOral" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox" >
                              <th mat-header-cell *matHeaderCellDef> </th>
                              <td mat-cell *matCellDef="let element">
                                <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                              </td>
                          </ng-container>


                               <!-- topOrder Column -->
                               <ng-container matColumnDef="topOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Top Order </th>
                                <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                            </ng-container>
                          <!-- Drug Name Column -->
                          <ng-container matColumnDef="drugName">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name </th>
                              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>


                          <!-- Customer Drug Name Column -->
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Drug Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                            </ng-container>

                          <!-- nonCountReason Column -->
                          <ng-container matColumnDef="nonCountReason">
                              <th mat-header-cell [hidden]="!isNonCountReasonNotAvailableShow" *matHeaderCellDef> Non Count Reason </th>
                              <td mat-cell [hidden]="!isNonCountReasonNotAvailableShow" *matCellDef="let element"> {{element.nonCountReason}} </td>
                          </ng-container>


                          <!-- rxQty Column -->
                          <ng-container matColumnDef="rxQty">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                              <td mat-cell *matCellDef="let element"> {{element.rxQty}}</td>
                          </ng-container>

                          <!-- sch Column -->
                          <ng-container matColumnDef="sch">
                              <th mat-header-cell [hidden]="!isSCHNotAvailablehow" *matHeaderCellDef mat-sort-header> Schedule </th>
                              <td mat-cell [hidden]="!isSCHNotAvailablehow" *matCellDef="let element"> {{element.sch}}</td>
                          </ng-container>

                          <!-- status Column -->
                          <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                              <td mat-cell *matCellDef="let element"> {{element.status}}</td>
                          </ng-container>

                          <!-- strength Column -->
                          <ng-container matColumnDef="strength">
                              <th [hidden]="!isStrengthNotAvailableShow" mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                              <td [hidden]="!isStrengthNotAvailableShow" mat-cell *matCellDef="let element"> {{element.strength}}</td>
                          </ng-container>

                          <!-- topOrder Column -->
                          <ng-container matColumnDef="topOrder">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                              <td mat-cell *matCellDef="let element"> {{element.topOrder}}</td>
                          </ng-container>

                          <!-- Tab Column -->
                          <ng-container matColumnDef="unitQty">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                              <td mat-cell *matCellDef="let element"> {{element.unitQty}}</td>
                          </ng-container>

                          <!-- drugStatus Column -->
                          <!-- <ng-container matColumnDef="drugStatus">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                              <td mat-cell  *matCellDef="let element">
                                  {{element.drugStatus}}
                              </td>
                          </ng-container> -->

                          <!-- NDC Column -->
                          <ng-container matColumnDef="ndc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> NDC </th>
                            <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                        </ng-container>

                        <!--genericSequenceNumber Column-->
                        <ng-container matColumnDef="genericSequenceNumber">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> GCN </th>
                          <td mat-cell *matCellDef="let element"> {{element.genericSequenceNumber}} </td>
                      </ng-container>

                        <!-- Pills Disp Column -->
                        <ng-container matColumnDef="usage">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{orderOrUsage}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
                        </ng-container>

                        <!-- No Of Orders Column -->
                        <ng-container matColumnDef="noOfOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{phllDispLable}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.noOfOrder}} </td>
                        </ng-container>

                        <!-- Cell Cap Column -->
                        <ng-container matColumnDef="cellCapacity">
                            <th mat-header-cell [hidden]="!isCellCapShow" *matHeaderCellDef mat-sort-header> Cell Cap </th>
                            <td mat-cell [hidden]="!isCellCapShow" *matCellDef="let element"> {{element.cellCapacity}}</td>
                        </ng-container>

                        <!-- Super Cell Cap Column -->
                        <ng-container matColumnDef="superCellCapacity">
                            <th mat-header-cell [hidden]="!isSuperCellCapShow" *matHeaderCellDef mat-sort-header> Super Cell Cap </th>
                            <td mat-cell [hidden]="!isSuperCellCapShow" *matCellDef="let element"> {{element.superCellCapacity}}</td>
                        </ng-container>

                        <!-- Ratio Column -->
                        <ng-container matColumnDef="ratio">
                            <th mat-header-cell [hidden]="!isRatioShow" *matHeaderCellDef mat-sort-header> Ratio </th>
                            <td mat-cell [hidden]="!isRatioShow" *matCellDef="let element"> {{element.ratio}}</td>
                        </ng-container>

                        <!-- Package Size Column -->
                        <ng-container matColumnDef="packageSize">
                            <th mat-header-cell [hidden]="!isPackageSizeShow" *matHeaderCellDef mat-sort-header> Package Size </th>
                            <td mat-cell [hidden]="!isPackageSizeShow" *matCellDef="let element"> {{element.packageSize}}</td>
                        </ng-container>

                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                            <th mat-header-cell [hidden]="!ismanufacturerNotAvailableShow" *matHeaderCellDef mat-sort-header> Manufacturer </th>
                            <td mat-cell [hidden]="!ismanufacturerNotAvailableShow" *matCellDef="let element"> {{element.manufacturer}}</td>
                        </ng-container>

                        <!-- Tab Column -->
                        <ng-container matColumnDef="tab">
                            <th mat-header-cell [hidden]="!isTabShow" *matHeaderCellDef mat-sort-header> Tab </th>
                            <td mat-cell [hidden]="!isTabShow" *matCellDef="let element"> {{element.tab}}</td>
                        </ng-container>

                        <!-- Tab Column -->
                        <ng-container matColumnDef="automate">
                            <th mat-header-cell [hidden]="!isautomateShow" *matHeaderCellDef mat-sort-header> Automate </th>
                            <td mat-cell [hidden]="!isautomateShow" *matCellDef="let element"> {{element.automate}}</td>
                        </ng-container>


                        <!-- Rec. Super Cell Column -->
                        <ng-container matColumnDef="recSuperCell">
                            <th mat-header-cell [hidden]="!isrecSuperCellShow" *matHeaderCellDef mat-sort-header> Rec. Super Cell </th>
                            <td mat-cell [hidden]="!isrecSuperCellShow" *matCellDef="let element"> {{element.recSuperCell}}</td>
                        </ng-container>

                        <!-- Rec. Locking Cell Column -->
                        <ng-container matColumnDef="recLockingCell">
                            <th mat-header-cell [hidden]="!isrecLockingCellShow" *matHeaderCellDef mat-sort-header> Rec. Locking Cell
                            </th>
                            <td mat-cell [hidden]="!isrecLockingCellShow" *matCellDef="let element"> {{element.recLockingCell}}</td>
                        </ng-container>
                        <!-- Pkg Size Std Cell Column -->
                        <ng-container matColumnDef="packageSizefitstandardcell">
                            <th mat-header-cell [hidden]="!ispackageSizefitstandardcellShow" *matHeaderCellDef mat-sort-header> Pkg Size Std Cell </th>
                            <td mat-cell [hidden]="!ispackageSizefitstandardcellShow" *matCellDef="let element">
                                {{element.packageSizefitstandardcell}}</td>
                        </ng-container>

                        <!-- 30 Dram Column -->
                        <ng-container matColumnDef="thirtyDramCapacity">
                            <th mat-header-cell [hidden]="!isDramShow" *matHeaderCellDef mat-sort-header> 30 Dram </th>
                            <td mat-cell [hidden]="!isDramShow" *matCellDef="let element"> {{element.thirtyDramCapacity}}</td>
                        </ng-container>

                        <!-- Cell Size Column -->
                        <ng-container matColumnDef="cellType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Type </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.cellType}}
                            </td>
                        </ng-container>

                      <!-- dosageForm Column -->
                      <ng-container matColumnDef="dosageForm">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage Form </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.dosageForm}}
                        </td>

                         <!-- isUnregulated Column -->
                         <ng-container matColumnDef="isUnRegulated">
                            <th mat-header-cell [hidden]="!isUnRegulatedColumnShow" *matHeaderCellDef mat-sort-header> isUnRegulated </th>
                            <td mat-cell [hidden]="!isUnRegulatedColumnShow"  *matCellDef="let element">
                                {{element.isUnregulated}}
                            </td>
                        </ng-container>
                    </ng-container>

                            <!-- Action Details -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-icon [matMenuTriggerFor]="columnMenu" class="pointer menu-trigger" tabindex="0" style=" margin: 0px 0px 0px 15px">
                                        <img src="/assets/img/icons/ico.16.edit.svg" alt="">
                                    </mat-icon>
                                    <mat-menu #columnMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                                        <div class="checkbox-container">
                                        <!--Non count Reason-->
                                      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isNonCountReasonNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          Non Count Reason
                                      </mat-checkbox>
                                      <!--Manufacturer-->
                                      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ismanufacturerNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          Manufacturer
                                      </mat-checkbox>
                                      <!--SCH-->
                                      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSCHNotAvailablehow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                          SCH
                                      </mat-checkbox>
                                      <!--Strength-->
                                      <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isStrengthNotAvailableShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                        Strength
                                    </mat-checkbox>
                                    <!--Cell Cap-->
                                    <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isCellCapShow" style="border-bottom: 0px solid black;">
                                      Cell Cap
                                  </mat-checkbox>
                                     <!--30 Dram-->
                                  <mat-checkbox (click)="$event.stopPropagation()" class="table-dyanamic-column flex flex-row" [(ngModel)]="isDramShow" style="border-bottom: 0px solid black;">
                                      30 Dram
                                  </mat-checkbox>

                                  <!-- Super Cell Cap-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isSuperCellCapShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Super Cell Cap
                                  </mat-checkbox>
                                  <!--Ratio-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isRatioShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Ratio
                                  </mat-checkbox>

                                  <!--Package Size-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isPackageSizeShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Package Size
                                  </mat-checkbox>
                                  <!-- Tab-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isTabShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Tab
                                  </mat-checkbox>

                                  <!-- Automate-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isautomateShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Automate
                                  </mat-checkbox>
                                  <!-- ec. Super Cell-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecSuperCellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Rec. Super Cell
                                  </mat-checkbox>
                                  <!-- Rec. Locking Cell-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isrecLockingCellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Rec. Locking Cell
                                  </mat-checkbox>
                                  <!-- Pkg Size Std Cell-->
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="ispackageSizefitstandardcellShow" class="table-dyanamic-column flex flex-row" style="border-bottom: 0px solid black;">
                                      Pkg Size Std Cell
                                  </mat-checkbox>
                                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="isUnRegulatedColumnShow" class="table-dynamic-column flex-row">
                                    isUnRegulated
                                  </mat-checkbox>

                                  <button style="margin: 1% 1% 5% 20%; height: 30px;" class="table-dyanamic-column btn btn-primary btn-sm">Ok</button>
                                </div>
                                  </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <mat-icon class="pointer menu-trigger" tabindex="0" style=" margin: 0px 20px 0px 0px">
                                        <img src="/assets/img/icons/ico_16_Menu.svg" alt="">
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsOral"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsOral;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSourceOral.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsOral.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Cell Locator Sheet({{dataSourcecellLocatorSheet.data.length}})">
                    <div class="mat-elevation-z8" #TABLE4>
                        <table mat-table #table4 [dataSource]="dataSourcecellLocatorSheet" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox" hidden="true">
                                <th mat-header-cell *matHeaderCellDef hidden="true"> </th>
                                <td mat-cell *matCellDef="let element" hidden="true">
                                </td>
                            </ng-container>

                            <!-- Drug Name Column -->
                            <ng-container matColumnDef="drugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CDDB Drug Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                            </ng-container>

                            <!-- ndc Number Column -->
                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>NDC </th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                            <!-- baffle Column -->
                            <ng-container matColumnDef="baffle">
                                <th mat-header-cell *matHeaderCellDef> Baffle </th>
                                <td mat-cell *matCellDef="let element"> {{element.baffle}} </td>
                            </ng-container>

                            <!-- cell Column -->
                            <ng-container matColumnDef="cell">
                                <th mat-header-cell *matHeaderCellDef> Cell </th>
                                <td mat-cell *matCellDef="let element"> {{element.cell}} </td>
                            </ng-container>

                            <!-- height Column -->
                            <ng-container matColumnDef="height">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Height </th>
                                <td mat-cell *matCellDef="let element"> {{element.height}} </td>
                            </ng-container>

                            <!-- width Column -->
                            <ng-container matColumnDef="width">
                                <th mat-header-cell *matHeaderCellDef> Width </th>
                                <td mat-cell *matCellDef="let element"> {{element.width}} </td>
                            </ng-container>

                            <!--maxCapacity Column -->
                            <ng-container matColumnDef="maxCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Capacity </th>
                                <td mat-cell *matCellDef="let element"> {{element.maxCapacity}} </td>
                            </ng-container>

                            <!--SuperCell maxCapacity Column -->
                            <ng-container matColumnDef="supercellMaxCapacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Super Max Capacity </th>
                                <td mat-cell *matCellDef="let element"> {{element.supercellMaxCapacity}} </td>
                            </ng-container>

                            <!--cellType Column -->
                            <ng-container matColumnDef="cellType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cell Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.cellType}} </td>
                            </ng-container>

                            <!-- pressure Column -->
                            <ng-container matColumnDef="pressure">
                                <th mat-header-cell  *matHeaderCellDef> Pressure </th>
                                <td mat-cell  *matCellDef="let element"> {{element.pressure}} </td>
                            </ng-container>

                            <!-- isSuperCell Column -->
                            <ng-container matColumnDef="isSuperCell">
                                <th mat-header-cell *matHeaderCellDef> Is Super Cell </th>
                                <td mat-cell *matCellDef="let element"> {{element.isSuperCell}} </td>
                            </ng-container>


                            <!-- thirtyDramCapacity Column -->
                            <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell  *matHeaderCellDef> Thirty Dram Capacity </th>
                                <td mat-cell  *matCellDef="let element"> {{element.thirtyDramCapacity}} </td>
                            </ng-container>

                             <!-- drugStatus Column -->
                            <!-- <ng-container matColumnDef="drugStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Status</th>
                                <td mat-cell  *matCellDef="let element">
                                    {{element.drugStatus}}
                                </td>
                            </ng-container> -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumnsCellLocatorSheet"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" mat-row *matRowDef="let row; columns: displayedColumnsCellLocatorSheet;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSourcecellLocatorSheet.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumnsCellLocatorSheet.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </section>
    <div #worksheet [hidden]="true" class="worksheet">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th class="custom-width">Drug Name</th>
              <th>NDC #</th>
              <th>Height</th>
              <th>Width</th>
              <th>Baffle</th>
              <th>Pressure</th>
              <!-- <th>30 Dram</th> -->
              <th>Cell</th>
              <th>Max Capacity</th>
              <th>Special Cell</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of cellLocatorSheetList; let i = index" [ngClass]="{'row-even': i % 2 === 0, 'row-odd': i % 2 !== 0}">
              <td>{{ i + 1 }}</td>
              <td>{{ item.drugName }}</td>
              <td>{{ item.ndc }}</td>
              <td class="numeric">{{ item.height }}</td>
              <td>{{ item.width || '' }}</td>
              <td class="numeric">{{ item.baffle  }}</td>
              <td class="numeric">{{ item.pressure  }}</td>
              <!-- <td class="numeric">{{ item.thirtyDramCapacity  }}</td> -->
              <td>{{ item.cell || '' }}</td>
              <td class="numeric">{{ item.maxCapacity  }}</td>
              <td>{{ item.specialCell || '' }}</td>
            </tr>
            <tr *ngFor="let item of configurTheUnconfiguredDrugandLocation(); let i = index" [ngClass]="{'row-even': i % 2 === 0, 'row-odd': i % 2 !== 0}">
              <td></td>
              <td>{{ item.drugName }}</td>
              <td>{{ item.ndc }}</td>
              <td class="numeric">{{ item.height  }}</td>
              <td>{{ item.width || '' }}</td>
              <td class="numeric">{{ item.baffle }}</td>
              <td class="numeric">{{ item.pressure  }}</td>
              <!-- <td class="numeric">{{ item.thirtyDramCapacity  }}</td> -->
              <td>{{ item.cell.split('-')[0] || '' }}</td>
              <td class="numeric">{{ item.maxCapacity  }}</td>
              <td>{{ item.specialCell || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
</app-card>
