import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewStoreService extends DataService<any> {

  constructor(http: HttpClient) {
    super(environment.apiUrl.viewstoreService + 'pharmacypeekviews', http);
  }

  public getHourlyPouchCountAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/hpc_site');
  }

  public getHourlyPouchCount(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/hpc_device/' + deviceid);
  }

  public getHourlyVialCountAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/hvc_site');
  }

  public getHourlyVialCount(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/hvc_device/' + deviceid);
  }


  public getDispenseRatioAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/dispense_ratio_site');
  }

  public getDispenseRatioDevice(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/dispense_ratio_device/' + deviceid);
  }

  public getUpTimeAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/uptime_site');
  }

  public getUpTimeDevice(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/uptime_device/' + deviceid);
  }

  public getDownTimeAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/downtime_site/');
  }
  
  public getDownTimeDevice(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/downtime_device/' + deviceid);
  }

  public getSystemStatusAllSystem(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/systemstatus_site');
  }

  public getSystemStatusDevice(deviceid): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/systemstatus_device/' + deviceid);
  }
  
}
