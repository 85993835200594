import { Component, OnInit } from '@angular/core';
import { BatchListType, RunModes } from '../../models/batch-models';
import QueueData from '../../models/script-manager-data';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { ScriptsActions } from 'src/app/store/actions';
import { getAuthProfile, getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';

export enum View {
  ORG,
  SITE
}

@Component({
  selector: 'app-pharmacy-scripting',
  templateUrl: './pharmacy-scripting.component.html',
  styleUrls: ['./pharmacy-scripting.component.scss'],
})
export class PharmacyScriptingComponent implements OnInit {
  View = View;

  BatchListType = BatchListType;
  RunModes = RunModes;
  runMode: string;
  queueDataMax: QueueData[] = [];
  queueDataPouch: QueueData[] = [];

  Menu_Pouch_ScriptManagement: boolean = false;
  Menu_Vial_ScriptManagement: boolean = false;

  machinePermissionsATP = false;
  machinePermissionsMAX = false;

  constructor(
    public store: Store<State>
  ) {

    this.runMode = RunModes.AUTORUN;
  }


  ngOnInit(): void {
    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.Menu_Pouch_ScriptManagement = flags?.Menu_Pouch_ScriptManagement;
        this.Menu_Vial_ScriptManagement = flags?.Menu_Vial_ScriptManagement;
      }
    }
    );

    this.store.select(getAuthProfile).subscribe((profile) => {
      if (profile) {
        const machinePermissions = profile.machinePermissions;
        this.machinePermissionsATP = machinePermissions.includes('ATP');
        this.machinePermissionsMAX = machinePermissions.includes('MAX');
      }
    });

    this.queueDataMax = [];
    this.queueDataPouch = [];
    this.store.dispatch(ScriptsActions.setScriptData());
  }

}
