import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import QueueData, { ScriptManagerData } from '../../../models/script-manager-data';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { QueuePriorityEnum } from '../../../models/pending-script-priority-enum';
import { ParataStandardInterfaceService } from '../../../services/parataStandardInterface.service';
import { getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { MovePouchDialogComponent } from './Components/move-to/move-pouch-dialog.component';



@Component({
  selector: 'app-pouch-script-manager-table',
  templateUrl: './pouch-script-manager-table.component.html',
  styleUrls: ['./pouch-script-manager-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class PouchScriptManagerTableComponent implements OnInit {

  @Input() inventories: QueueData[];
  @Input() filterText;
  @Input() loading;
  @Input() isComplete: boolean ;
  @Output() loadInventories: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('scriptManagerTable', { static: false }) private scriptManagerRef;
  @ViewChild('fixedHeader', { static: false }) private fixedHeaderRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();

  expandedElement: any;

  QueuePriority = QueuePriorityEnum;
  panelOpenState: boolean;

  flags: any;
  Pouch_ScriptManagement_BacklogTime: boolean = false;
  Pouch_ScriptManagement_Action: boolean = false;

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth > 900) {

    }
  }

  constructor(
    private dialog: MatDialog,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    public store: Store<State>
  ) { 

    
  }

  ngOnInit() {
    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.flags = flags;
        this.Pouch_ScriptManagement_BacklogTime = flags?.Pouch_ScriptManagement_BacklogTime;
        this.Pouch_ScriptManagement_Action= flags.Pouch_ScriptManagement_Action;
      }
    }
    );
  }

  onExpandRow(row: any) {
    row.isExpanded = !row.isExpanded;
  }
  
  onExpandRow_inner(row: any, rowIndex: number) {
    row.isExpanded = !row.isExpanded;   
  }

  isRowClickable_inner(dataList:any, rowIndex: number): boolean {
    return dataList[rowIndex]?.patients && dataList[rowIndex]?.patients?.length > 0;
  }

  onOpenDispensingDetail(event, row: ScriptManagerData, type: string){
    // if (event.target.classList.contains('mat-button') || event.target.alt ==='Menu' || type === 'IN QUEUE')
    // {return;}

    // const dialogRef = this.dialog.open(DispensingScriptDetailComponent, {
    //   data: row,
    // });

    // dialogRef.afterClosed().subscribe(result => {
    // });
    
  }

  openMoveToDialog(parataBatchId,system) {
    const dialogRef = this.dialog.open(MovePouchDialogComponent, 
      {
        backdropClass: ['moveTo','smmdDialog'],
        data: { parataBatchId: parataBatchId, system : system}
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  setPriority(priority: number, row: ScriptManagerData) {
    this.parataStandardInterfaceService.setPriority('d', row.transactionID, priority);

  }
  getEnumValue(value: string) {
    const tt= QueuePriorityEnum;
   // eslint-disable-next-line max-len
   const str =  Object.keys(tt).filter(k => typeof (tt as any)[k] === value) as any ;

  }

  mathCeil(number){
    const num = Math.ceil(number);
    return num + ' min';
  }
 
}
