export enum QueuePriorityEnum {
    Urgent = 3,
    Rush = 2,
    Normal = 1,
    Delay = 0,
    Hold = 4
}

export enum QueueInformationEnum{
    InQueue,
    Incomplete,
    Complete,
    InProcess,
}
