import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PharmacyPeekRoutingModule } from './pharmacy-peek-routing.module';
import { PharmacyPeekComponent } from './components/pharmacy-peek/pharmacy-peek.component';
import { PharmacyPeekDialogComponent } from './components/pharmacy-peek-dialog/pharmacy-peek-dialog.component';
import { PouchCountChartComponent } from './components/charts/pouch-count-chart/pouch-count-chart.component';
import { OverallBarChartComponent } from './components/charts/overall-bar-chart/overall-bar-chart.component';
import { PharmacyPeekMgmtDialogComponent } from './components/widgets-configuration/pharmacy-peek-mgmt-dialog/pharmacy-peek-mgmt-dialog.component';
import { PharmacyPeekWidgetComponent } from './components/widgets-configuration/pharmacy-peek-widget/pharmacy-peek-widget.component';
import { PharmacyPeekListComponent } from './components/widgets-configuration/pharmacy-peek-list/pharmacy-peek-list.component';
import { PharmacyPeekSelectDialogComponent } from './components/widgets-configuration/pharmacy-peek-select-dialog/pharmacy-peek-select-dialog.component';
import { PharmacyPeekDiscardChangesDialogComponent } from './components/widgets-configuration/pharmacy-peek-discard-changes-dialog/pharmacy-peek-discard-changes-dialog.component';
import { PharmacyPeekMgmtDialogMobileComponent } from './components/widgets-configuration/pharmacy-peek-mgmt-dialog-mobile/pharmacy-peek-mgmt-dialog-mobile.component';
import { PackagerUptimeAllSystemComponent } from './components/widgets/packager-uptime-all-system/packager-uptime-all-system.component';
import { PackagerUptimeDeviceComponent } from './components/widgets/packager-uptime-device/packager-uptime-device.component';
import { PackagerDowntimeAllSystemComponent } from './components/widgets/packager-downtime-all-system/packager-downtime-all-system.component';
import { PackagerDowntimeDeviceComponent } from './components/widgets/packager-downtime-device/packager-downtime-device.component';
import { DispenseRatioComponent } from './components/widgets/dispense-ratio/dispense-ratio.component';
import { HourlyPouchCountAllSystemComponent } from './components/widgets/hourly-pouch-count-all-system/hourly-pouch-count-all-system.component';
import { HourlyPouchCountDeviceComponent } from './components/widgets/hourly-pouch-count-device/hourly-pouch-count-device.component';
import { SystemStatusDeviceComponent } from './components/widgets/system-status-device/system-status-device.component';
import { SystemStatusMultipleDeviceComponent } from './components/widgets/system-status-multiple-device/system-status-multiple-device.component';
import { PharmacyPeekSelectMultipleDialogComponent } from './components/widgets-configuration/pharmacy-peek-select-multiple-dialog/pharmacy-peek-select-multiple-dialog.component';
import { HourlyVialCountAllSystemComponent } from './components/widgets/hourly-vial-count-all-system/hourly-vial-count-all-system.component';
import { HourlyVialCountDeviceComponent } from './components/widgets/hourly-vial-count-device/hourly-vial-count-device.component';

@NgModule({
    declarations: [
        PharmacyPeekComponent,
        PouchCountChartComponent,
        PharmacyPeekDialogComponent,
        OverallBarChartComponent,
        PharmacyPeekMgmtDialogComponent,
        PharmacyPeekMgmtDialogMobileComponent,
        PharmacyPeekWidgetComponent,
        PharmacyPeekListComponent,
        PharmacyPeekSelectDialogComponent,
        PharmacyPeekSelectMultipleDialogComponent,
        PharmacyPeekDiscardChangesDialogComponent,
        PackagerUptimeAllSystemComponent,
        PackagerUptimeDeviceComponent,
        PackagerDowntimeAllSystemComponent,
        PackagerDowntimeDeviceComponent,
        DispenseRatioComponent,
        HourlyPouchCountAllSystemComponent,
        HourlyPouchCountDeviceComponent,
        SystemStatusDeviceComponent,
        SystemStatusMultipleDeviceComponent,
        HourlyVialCountAllSystemComponent,
        HourlyVialCountDeviceComponent
    ],
    exports: [
        PharmacyPeekComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        PharmacyPeekRoutingModule,
        DragDropModule
    ]
})
export class PharmacyPeekModule {}
