import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as constants from 'src/app/config/app-constants';
import { DrugFileUploadService } from '../../services/drug-file-upload.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-petmed-fileupload',
  templateUrl: './petmed-fileupload.component.html',
  styleUrls: ['./petmed-fileupload.component.scss']
})
export class PetmedFileuploadComponent implements OnInit {

  constructor( private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private fileUploadService: DrugFileUploadService) { }
    percentage : number=0;
    fielName: string= "";
    fieleSelect:boolean= false;
    backgroundcolor:string="#053AB2 0% 0% no-repeat padding-box";
    selectedFile:File=null;
    responseMessage:string= "";
    responseMessageError:string = "";


  ngOnInit(): void {

  }


  onFileSelected(event:any) {
    this.selectedFile = <File>event.target.files[0];
    this.fielName = this.selectedFile.name;
    this.fieleSelect = true;
}
selectedFileRemove(){
  this.fielName = "";
  this.fieleSelect= false;

}

private fileResponseHandler(message: string) {
  this.ngxLoader.stop();
  this.selectedFile=null;
  this.toastService.openToast(message || 'File successfully uploaded!', constants.ToastPanelType.done, 1);
  this.responseMessage = message || 'File successfully uploaded!';
  this.responseMessageError= "";
  if (this.percentage===100){
    this.backgroundcolor="green";
  }

}
private progressbarShow(event){
switch (event.type) {
  case HttpEventType.Sent:
  console.log('Request has been made!');
  break;
  case HttpEventType.ResponseHeader:
  console.log('Response header has been received!');
  break;
  case HttpEventType.UploadProgress:
  var eventTotal = event.total ? event.total : 0;
  this.percentage = Math.round(event.loaded / eventTotal * 100);

  break;
  case HttpEventType.Response:
    this.fileResponseHandler(event.body);
  setTimeout(() => {
  this.percentage = 0;
  this.fieleSelect = false;
  }, 2000);
}
}
onUpload() {
  if (this.selectedFile === null) {
    return this.toastService.openToast(
     'No file added for upload.',
     constants.ToastPanelType.error,
   );
  }
  if(Math.round(this.selectedFile.size/1024) > 2000){
    return this.toastService.openToast(
      'File size must be less than 2 MB.',
      constants.ToastPanelType.error,
    );
  }

  this.fileUploadService.upload(this.selectedFile, 'petMedUpload')
    .subscribe(
      (event)=> this.progressbarShow(event),
      (error:HttpErrorResponse)=>this.fileErrorHandler(error)
    );
}
public notificationHide(){
  this.selectedFile=null;
  this.responseMessage = "";
  this.responseMessageError = "";
}

private fileErrorHandler(error) {
  this.selectedFile=null;
  this.ngxLoader.stop();
  this.responseMessage = "";
  this.responseMessageError = 'Try again with a Pet Medication SpreadSheet format!';
  this.backgroundcolor = "red"
  setTimeout(() => {
    this.percentage = 0;
    this.fieleSelect = false;
    this.backgroundcolor = "#053AB2";
    }, 2000);
  this.toastService.openToast(this.responseMessageError || 'Failed to process file upload!', constants.ToastPanelType.error, 1);


}
}
