<div *ngIf="this.isparentnotassociated" class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">  
  <div>
    <h1 mat-dialog-title id="addnewsite">
      Add New Site
    </h1>
    <mat-dialog-content class="add-site-container">
      <h6 class="m-t-0 m-b-0">What organization is this site associated with?</h6>
          <form class="autocomplete_form" [formGroup]='parentOrgformGroup'>
          <mat-form-field  [floatLabel]="'never'" class="autocomplete autocomplete-with-icon" appearance="fill" style="margin-top: -3px !important;">
            <label for="parentName" class="parentname_label">
              &nbsp; 
            </label>           
            <div class="autocomplete_input">
              <input class="autocomplete_input" id="parentName" formControlName='parentName' placeholder="Select Organization" type="text" matInput 
              [matAutocomplete]="auto" >           
              <button mat-button [ngStyle]="{'visibility': (parentOrgformGroup.get('parentName').value) ? 'visible' : 'hidden'}" mat-icon-button aria-label="" (click)="clear(parentOrgformGroup.get('parentName'))">
                <mat-icon>close</mat-icon>
              </button>             
            </div>
            <mat-autocomplete #auto="matAutocomplete"  >
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" [attr.aria-valuenow]="option.name" (onSelectionChange)="fetchParentOrgDetails(option)" matTooltip="{{option.name}}"  matTooltipPosition="after" matTooltipClass="my-custom-tooltip">
                {{option.name}}
              </mat-option>
              <mat-option *ngIf="isNoOrgResult" (onSelectionChange)="openOrgWin()">
              <p>
                 No Search Results found
               <span  class="link d-block" id="RequestNewOrg" (click)="openOrgWin()" >Request New Organization ></span>
              </p>
            </mat-option>
            </mat-autocomplete>           
            <mat-error *ngIf="parentOrgformGroup.get('parentName').hasError('required')">No Organization was chosen</mat-error> 
          </mat-form-field>
      </form>
      
      <small class="fts-12">Can’t find your organization?</small>
      <button mat-button class="btn-transparent fts-12" id="RequestNewOrg" (click)="openOrgWin()" type="button" aria-label="Request New Organization" [attr.tabindex]="parentOrgId?-1:0">Request New Organization ></button>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full"> 
    <button mat-button class="btn-default btn-md" (click)="dialogRef.close()" id="CancelOrg" type="button" aria-label="Cancel">Cancel</button>
    <button mat-button class="btn-primary btn-md btn-done m-l-16" (click)="showsite(true)" id="OrgChoosen" [disabled]="!this.parentOrgId" [class.btn-disabled]="!parentOrgId" type="button" aria-label="Next" tabindex="0">Next</button>
  </mat-dialog-actions>
</div>

<div *ngIf="!this.isparentnotassociated" class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <div>
    <h1 mat-dialog-title>
      Add New Site
    </h1>
    <mat-dialog-content  class="add-site-exitorg-container">
      <label>
        Organization Name
      </label>
      <p tabindex="0"[attr.aria-label]="'Organization Name' + parentOrgName">{{parentOrgName}}</p> 
      
      <form class="autocomplete_form" [formGroup]='formGroup' #formDirective="ngForm">
          <h6 tabindex="0">What site are you adding to Parata Stratus™ ?</h6>
          
          <div class="flex flex-row gap-4 justify-start content-stretch items-stretch xs:flex xs:flex-col">
            <mat-form-field floatLabel="never" class="autocomplete lg:basis-2/5 md:basis-2/5 sm:basis-2/5 xs:flex-1" appearance="fill">
              <label for="salesforceName">
                <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>Site Name From Salesforce
              </label>
              <input type="text" formControlName='salesforceName' placeholder="Find site by name or customer number" id="salesforceName"  matInput [matAutocomplete]="salesforce" >                  
              <mat-autocomplete #salesforce="matAutocomplete" [displayWith]="getSiteName.bind(this)">
                <mat-option *ngFor="let option of siteOptionswithfilter" (onSelectionChange)="fetchSalesforceDetails(option)" [value]="option.id" [disabled]="option.isOnboarded" >
                  <div *ngIf="option.isOnboarded">
                    <span matTooltip="{{option.name}} ({{option.customer_Num__c}})"  matTooltipPosition="after" matTooltipClass="custom_tooltip" *ngIf="option.customer_Num__c" class="option-text">
                      {{option.name}} ({{ option.customer_Num__c }})                        
                    </span>
                    <span matTooltip="{{option.name}}"  matTooltipPosition="after" matTooltipClass="custom_tooltip" *ngIf="!option.customer_Num__c" class="option-text" >
                      {{option.name}}
                    </span>
                    <span style="float: right;overflow: hidden;text-overflow: ellipsis;max-width: 32%;">
                      <span *ngIf="option.isOnboarded">ONBOARDED</span>                    
                    </span>
                  </div>                    
                  <div *ngIf="!option.isOnboarded">
                    <span matTooltip="{{option.name}} ({{option.customer_Num__c}})"  matTooltipPosition="after" matTooltipClass="custom_tooltip" *ngIf="option.customer_Num__c" style="float: left;overflow: hidden;text-overflow: ellipsis;max-width: 100%;">
                      {{option.name}} ({{ option.customer_Num__c }})                        
                    </span>                      
                    <span matTooltip="{{option.name}}"  matTooltipPosition="after" matTooltipClass="custom_tooltip" *ngIf="!option.customer_Num__c" style="float: left;overflow: hidden;text-overflow: ellipsis;max-width: 100%;">
                      {{option.name}}
                    </span>                      
                  </div>
                </mat-option>
              </mat-autocomplete>
              <mat-error>{{ getSalesforceErrorMessage() }}</mat-error>                 
            </mat-form-field>
      
              <mat-form-field floatLabel="never" class="xs:flex-1 sm:basis-2/5 md:basis-1/4 lg:basis-1/4">
                  <label for="FriendlyName">
                    <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                      Friendly Name for Site
                      <custom-tooltip text="A “Friendly Name” is the name the customer will see when they log in to Parata Stratus™."></custom-tooltip>
                    </label>
                  <input trim type="text" formControlName='siteName' appNoHtmlTags matInput id="FriendlyName" (blur)="siteExist()">
                  <mat-error *ngIf="formGroup.get('siteName').hasError('required')">Friendly Name required</mat-error>
              </mat-form-field>
          </div>
  
          <h6 class="m-b-16">Add new user to site</h6>
          <div class="flex flex-row gap-4 justify-start content-stretch items-stretch xs:flex-col">
              <mat-form-field floatLabel="never" class="xs:flex-1 sm:basis-1/2 md:basis-[21%] lg:basis-[21%]">
                  <label for="LastName">
                    <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                    <span>Last Name</span>
                  </label>
                  <input type="text" trim matInput appNoHtmlTags id="LastName" formControlName='userLastName'>
                  <mat-error *ngIf="formGroup.get('userLastName').hasError('required') ">First Name required</mat-error>
                  <mat-error *ngIf="formGroup.get('userLastName').hasError('maxlength')">Last Name cannot exceed 50 characters</mat-error>
                  <mat-error *ngIf="formGroup.get('userLastName').hasError('pattern')">Last Name cannot contain numbers or symbols</mat-error>
              </mat-form-field>
              <mat-form-field floatLabel="never" class="xs:flex-1 sm:basis-1/2 md:basis-[21%] lg:basis-[21%]">
                  <label for="FirstName">
                    <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                    <span>First Name</span>
                  </label>
                  <input type="text" trim matInput appNoHtmlTags id="FirstName" formControlName='userFirstName'>
                  <mat-error *ngIf="formGroup.get('userFirstName').hasError('required')">First Name required</mat-error>
                  <mat-error *ngIf="formGroup.get('userFirstName').hasError('maxlength')">First Name cannot exceed 50 characters</mat-error>
                  <mat-error *ngIf="formGroup.get('userFirstName').hasError('pattern')">First Name cannot contain numbers or symbols</mat-error>
              </mat-form-field>
              <mat-form-field floatLabel="never" class="xs:flex-1 sm:basis-1/2 md:basis-[27%] lg:basis-[27%]">
                  <label for="Email">
                    <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                    <span>Email</span>
                  </label>
                  <input type="text" trim matInput appNoHtmlTags id="Email" formControlName='userEmail' (blur)="emailExist()">
                  <mat-error *ngIf="formGroup.get('userEmail').hasError('required')">Email required</mat-error>
                  <mat-error *ngIf="formGroup.get('userEmail').hasError('pattern')">Invalid email</mat-error>
              </mat-form-field>
           
              <mat-form-field floatLabel="never" class="grow-0 shrink basis-[180px]">
                <label for="PermissionGroup">
                  <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                  <span>Permission Group</span>
                </label>
                <mat-select id="PermissionGroup" formControlName='permissionGroup'>
                  <mat-option *ngFor="let permissionGroup of permissionGroups" [value]="permissionGroup.roleId">{{ permissionGroup.description }}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('permissionGroup').hasError('required')">Permission Group required</mat-error>
              </mat-form-field>
             
          </div>
          <div>
              <mat-table #table [dataSource]="userdataSource">
                <!-- name Column -->
                <ng-container matColumnDef="name">
                  <mat-cell *matCellDef="let row"> {{row.userLastName | uppercase}}{{', ' + row.userFirstName}} </mat-cell>
                </ng-container>
            
                <!-- email Column -->
                <ng-container matColumnDef="email">
                  <mat-cell *matCellDef="let row"> {{row.userEmail}}</mat-cell>
                </ng-container>
            
                <!-- role Column -->
                <ng-container matColumnDef="role">
                  <mat-cell *matCellDef="let row"> {{row.roleName}} </mat-cell>
                </ng-container>
  
                <!-- delete Column -->
                <ng-container matColumnDef="delete">
                  <mat-cell *matCellDef="let row; let i = index" class="field_trash">
                    <mat-icon [id]="i" (click)="deleterow($event)">close</mat-icon>
                  </mat-cell>
                </ng-container>
            
                <mat-row *matRowDef="let row; columns: userdisplayedColumns;"></mat-row>
              </mat-table>
            </div>
      </form>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions *ngIf="!this.isparentnotassociated" class="flex flex-row justify-end content-end items-end w-full xs:flex-col">
      <button mat-button class="btn-default btn-md" (click)="dialogRef.close()" id="CancelNewUser" aria-label="Cancel" type="button">Cancel</button>
      <button mat-button *ngIf="showbackbutton" class="btn-default btn-md m-l-16" (click)="hidesite()" id="BackToOrg" aria-label="Back" type="button">Back</button>
      <button mat-button class="btn-primary btn-md m-l-16" id="SuccesNewUser" (click)="addRow()"   aria-label="Create New Site" type="button">Create New Site</button>

  </mat-dialog-actions>
</div>