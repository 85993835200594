import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from './toast.service';
import * as constants from '../../config/app-constants';

@Injectable({
  providedIn: 'root'
})
export class BlobService extends DataService<any> {

  constructor(http: HttpClient) {
    super(environment.apiUrl.blobService, http);
  }

  downloadFile(
    containerName: string,
    fileName: string,
    ngxLoader: NgxUiLoaderService,
    toastService: ToastService,
    successMsg: string = 'Downloaded successfully',
    errorMsg: string = 'Download failed'
  ) {
    ngxLoader.start();
    return this.http
      .get(`${this.url}DownloadFile/${containerName}/${fileName}`, { responseType: 'blob' })
      .subscribe((result: Blob) => {
        const blob = new Blob([result], { type: result.type });
        const blobUrl = window.URL.createObjectURL(blob);
        const fileAnchor = document.createElement('a');

        fileAnchor.download = decodeURIComponent(fileName);
        fileAnchor.href = blobUrl;
        fileAnchor.click();

        ngxLoader.stop();
        toastService.openToast(successMsg);
        console.info('download success:', result);
      }, (error) => {
        ngxLoader.stop();
        toastService.openToast(errorMsg, constants.ToastPanelType.error);
        console.error('download error:', error);
      }
    );
  }

}
