 <cdk-virtual-scroll-viewport [itemSize]="colHeight" class="scroll-container"> 
  <div *cdkVirtualFor="let row of (inventories || []);let index = index" class="card-item" 
    [class.card-item-open]="openingRow === index"> 
    <div class="main-detail flex flex-row">
      <div class="basis-6 lt-sm:hidden">
        <app-icon class="pointer" (click)="openingRow = (openingRow === index) ? null : index" 
          [icon]="openingRow === index ? 'arwup' : 'arwdown'" size="12"></app-icon>
      </div>
      <div class="flex flex-row flex-wrap flex-auto">
        <div *ngFor="let column of columns;let cId = index" class="item basis-1/3 xs:basis-full">
          <p class="label">
            <app-icon *ngIf="!cId" class="pointer m-r-4 hidden lt-sm:block" size="12" 
              (click)="openingRow = (openingRow === index) ? null : index" 
              [icon]="openingRow === index ? 'arwup' : 'arwdown'"></app-icon>
            {{column.header}}
            <app-icon *ngIf="column.icon" [icon]="column.icon" class="m-l" attr.aria-label="Average of daily dispense since last {{dateRange}} days" matTooltip="Average of daily dispense since last {{dateRange}} days" matTooltipPosition="above"></app-icon>
          </p>
          <p class="value" *ngIf="column.highlightMatched" [innerHTML]=" row[column.field] | noHtml | highlightMatched: filterText | titlecase" [attr.aria-label]=" column.header + row[column.field]"></p>
          <p class="value" *ngIf="!column.highlightMatched" [innerHTML]=" row[column.field] | noHtml " [attr.aria-label]=" column.header + row[column.field]"></p>
        </div>
      </div>
    </div>

    <div *ngIf="openingRow === index" class="more-detail">
      <div *ngFor="let items of row.lastDispInfo; let index = index" class="m-b-2 lt-sm:flex lt-sm:flex-row lt-sm:flex-wrap flex flex-row">     
        <div class="lt-sm:basis-1/2 basis-1/3">          
        </div>
        <div class="value lt-sm:basis-1/2 basis-[calc(33.33333%-88px)]" [attr.aria-label]="'Quantity' + items.quantity">
          {{ items.quantity | number }}
        </div>
        <div class="label p-t-4 basis-[88px] lt-sm:hidden">
          <ng-container *ngIf="!index" aria-label="Last Dispensed">Last Dispensed</ng-container>
        </div>
        <div class="basis-1/3 lt-sm:hidden">
          <div class="value" [attr.aria-label]="'Last Filled Date' + items.lastFilledDate">{{ items.lastFilledDate || '&nbsp;' }}</div>
          <div class="value m-t-0">
            <span class="link" [attr.aria-label]="'Link' + items.link">{{ items.link }} ></span>
          </div>
        </div>

        <div class="flex-row basis-full hidden lt-sm:block">
          <div class="label basis-[60px]">
            <ng-container *ngIf="!index">Last Disp</ng-container>
          </div>
          <div class="value basis-[calc(50%-60px)]" [attr.aria-label]="'Last Filled Date' + items.lastFilledDate">{{ items.lastFilledDate || '&nbsp;' }}</div>
          <div class="value basis-1/2" [attr.aria-label]="'Link' + items.link"> <span class="link">{{ items.link }} ></span></div>
        </div>

      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
