export enum SignalEventType {
  HPC_DEVICE = 'hpc_device',
  HPC_SITE = 'hpc_site',
  HVC_DEVICE = 'hvc_device',
  HVC_SITE = 'hvc_site',
  UPTIME_DEVICE = 'uptime_device',
  UPTIME_SITE = 'uptime_site',
  DOWMTIME_DEVICE = 'downtime_device',
  DOWMTIME_SITE = 'downtime_site',
  DISPENSE_RATIO_SITE = 'dispense_ratio_site',
  DISPENSE_RATIO_DEVICE = 'dispense_ratio_device',
  SYSTEM_STATUS_DEVICE = 'systemstatus_device',
  SYSTEM_STATUS_SITE = 'systemstatus_site',
  Script_POUCH_INPROCESS_BATCHES_SITE = 'scriptrouting_inprocessbatches_site',
  Script_POUCH_INQUEUE_BATCHES_SITE = 'scriptrouting_inqueuebatches_site',
  MAX_VIAL_UPDATER = 'max-vial-updater', 
}