
export const MedicationXMLBrand = '<ArrayOfMedication xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">'
export class DispLocation  {
    smartId: string;
    canNumber: string;
    dispLocDictId: number;
    lotNumber: string;
    expDate: string;
    canQty: number;
    aLotNumber: string;
    aExpDate: string;
    active: string;
    dispenselocationid: number;
    clusterCanStatusDictID: string;
}

export class Medication {
    ndc: string;
    custNdc: string;
    barcode: string | null;
    genericName: string;
    brandName: string;
    manufacturer: string;
    strength: string;
    pillForm: string;
    volume: number;
    color: string;
    shape: string;
    marking: string;
    description: string;
    packQuantity: number;
    costPerPill: number;
    weight: number;
    drugControlLevelDictId: number;
    comment: string;
    doNotMix: number;
    active: number;
    parlevel: number;
    maxParLevel: number;
    inventoryNumber: string;
    gcn_seqNo: number;
    distributor: string | null;
    ndcid: number;
    drugid: number;
    manufacturerid: number;
    dispLocTypeDictId: number;
    packageVolume: number;
    priority: number;
    image: string;
    score: number | null;
    coldPackDictId: number;
    drugDoseFormDictId: number;
    dispLoc: DispLocationList = new DispLocationList();
}
export class DispLocationList{
    DispLocation: DispLocation[];
    // Cell: CellData[] = [];
 
     constructor() {
         this.DispLocation = [];
     }
}

export class ArrayOfMedication {
    Medication: Medication[];
   // Cell: CellData[] = [];

    constructor() {
        this.Medication = [];
    }
}

export class MedicationXml {
    ArrayOfMedication: ArrayOfMedication = {} as ArrayOfMedication;

    constructor() {
        this.ArrayOfMedication = new ArrayOfMedication();
    }
}