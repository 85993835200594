import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { SearchBarComponent } from 'src/app/shared/components/search-bar/search-bar.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { MatSort } from '@angular/material/sort';
import { Asset, OrgAsset } from 'src/app/external-user/inventory/models/asset';
import { SiteService } from 'src/app/external-user/inventory/services/site.service';
import { OrgAssetService } from 'src/app/external-user/inventory/services/org-asset.service';
import { Site } from 'src/app/external-user/inventory/models/site';
import { InventoryViewsData, InventoryViewsRptData } from '../../../models/inventory-view-data';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { InventoryViewsService } from '../../../services/viewstore-inventory-views.service';
import { ToastPanelType ,appConst as CONSTANTS } from 'src/app/config/app-constants';

export interface InvRptData {
  Inventory: InventoryViewsData[];
}

// basic select
interface SortItemMdl {
  active: string;
  fieldDispName: string;
  sortDirection: string;
  direction: string;
}

const inventoriesPerPage = CONSTANTS.INVENTORIES_PER_PAGE;
export enum View {
  SITE,
  SYSTEM,
}

@Component({
  selector: 'app-inventory-views',
  templateUrl: './inventory-views.component.html',
  styleUrls: ['./inventory-views.component.scss'],
})
export class InventoryViewsComponent implements OnInit {
  View = View;
  innerDisplayedColumns: string[] = ['lastFilledDate', 'avgDailyDispense', 'quantity', 'link'];
  @ViewChild(SearchBarComponent, { static: true }) childComponent: SearchBarComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  parentOrgId = '';
  parentOrgName = '';
  organizationId = '';

  sites: Site[] = [];
  orgAssets: OrgAsset[] = [];

  selectedSite: Site = null;
  selectedAsset: Asset = null;
  isMobileView = false;

  inventoriesTemp: InventoryViewsData[] = null;
  inventories: InventoryViewsData[] = null;
  loadingInventories = false;
  loadedAllInventories = false;

  dateRangeFilterList: any = [14, 30, 60];
  selectedDateRange: number = 30; //this is default daterange filter

  inventoriesRpt: InventoryViewsRptData[] = null;

  page = 0;
  filterText = '';
  windowWidth = window.innerWidth;

  downloadingExcel = false;
  @Input() deviceType;
  // basic select
  SortItems: SortItemMdl[] = [
    { active: 'drugName', fieldDispName: 'Drug Name', sortDirection: 'Ascending', direction: 'asc' },
    { active: 'drugName', fieldDispName: 'Drug Name', sortDirection: 'Descending', direction: 'desc' },
    { active: 'avgDailyDispense', fieldDispName: 'Avg. Daily Disp', sortDirection: 'Ascending', direction: 'asc' },
    { active: 'avgDailyDispense', fieldDispName: 'Avg. Daily Disp', sortDirection: 'Descending', direction: 'desc' },
    { active: 'totalQuantity', fieldDispName: 'Current Inv', sortDirection: 'Ascending', direction: 'asc' },
    { active: 'totalQuantity', fieldDispName: 'Current Inv', sortDirection: 'Descending', direction: 'desc' }
  ];
  selectedSort = this.SortItems[0];

  @HostListener('window:resize')
  onResize() {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth <= 1024) { this.isMobileView = true; }
    else { this.isMobileView = false; }
  }

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private toastService: ToastService,
    private siteService: SiteService,
    private orgAssetService: OrgAssetService,
    private inventoryService: InventoryViewsService,
    private datepipe: DatePipe,
    public store: Store<State>,
  ) {
  
  }

  get view() {
    if (this.selectedAsset && this.selectedAsset.machineNo) {
      return View.SYSTEM;
    } else {
      return View.SITE;
    }
  }

  get visibleOrgAssets(): OrgAsset[] {
    if (this.selectedSite) {
      return this.orgAssets.filter(asset => asset.organizationId === this.selectedSite.organizationId);
    }
    return this.orgAssets;
  }

  ngOnInit() {
    
    if (this.windowWidth <= 1024) { this.isMobileView = true; }
    else { this.isMobileView = false; }
    this.authService.profile$.subscribe((profile) => {
      this.parentOrgId = profile.parentOrganizationId;
      this.parentOrgName = profile.parentOrganization;
      this.organizationId = profile.organizationId;
      this.loadSites();
    });
  }

  loadSites() {
    const ngxLoaderKey = 'loadSites';
    this.ngxLoader.start(ngxLoaderKey);
    const loadSiteTime = window.performance.now();
    this.siteService.getSitesForParentOrg(this.parentOrgId)
      .subscribe((sites) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.sites = sites.filter(site => site.enabled === true);
        this.selectedSite = this.organizationId === this.parentOrgId
        ? this.sites[0]  // Org user, select the first site
        : this.sites.find(site => site.organizationId === this.organizationId);  // Site user, find the matching site
        this.loadAssets();
      }, (response) => {
        this.toastService.stopLoadingAndShowError(response,
          'Error while loading sites for parent org!', ngxLoaderKey);
        if (response.status === 404) {
          this.inventories = [];
          this.loadingInventories = false;
        }
      });
  }

  loadAssets() {
    const ngxLoaderKey = 'loadAssets';
    const loadAssetTime = window.performance.now();
    this.orgAssetService.getAssetsByParentOrgId(this.parentOrgId)
      .subscribe((assets) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.orgAssets = assets
          .filter(orgAsset => orgAsset.assets.some(asset => this.deviceTypeMatch(asset.configurations, this.deviceType)))
          .map(oa =>{
            oa.assets = oa.assets.filter(asset => this.deviceTypeMatch(asset.configurations, this.deviceType));
            return oa;
          });
        this.loadInventories();

      }, (response) => {
        if (response.status === 404) {
          this.ngxLoader.stop(ngxLoaderKey);
          this.toastService.openToast('No device exists!', ToastPanelType.warning);
          this.inventoriesRpt = [];
          this.inventories = [];
          this.loadingInventories = false;
        } else {
          this.toastService.stopLoadingAndShowError(response,
            'Error while loading devices for parent org!', ngxLoaderKey);
        }
      });
  }

  deviceTypeMatch(configurations, deviceType){
    const configObj = JSON.parse(configurations);
    if(configObj.MachineTypeID && this.getDeviceType(configObj.MachineTypeID) == deviceType)
      return true;

    return false;
  }

  getDeviceType(intType):string{
    switch(Number(intType)){
      case 1:
        return "atp";
      case 2:
        return "pass";
      case 3:
        return "max";
      default:
        return undefined;
    }
  }

  setSite(site: Site) {
    this.selectedSite = site;
    this.selectedAsset = null;// Clear the asset's
    // Clear the search bar on site change/selection
    this.childComponent.filter = '';
    this.filterText = '';
    this.loadInventories();
  }

  setAsset(asset: Asset) {
    this.selectedAsset = asset;

    // If site not selected then choose the site associated with the asset
    if (!this.selectedSite && asset) {
      const orgAsset = this.visibleOrgAssets
        .find(oa => oa.assets.some(({ assetId }) => asset.assetId === assetId));
      const orgId = orgAsset.organizationId;
      this.selectedSite = this.sites.find(site => site.organizationId === orgId);
    }

    // Clear the search bar on asset change/selection
    this.childComponent.filter = '';
    this.filterText = '';
    this.loadInventories();
  }

  loadInventories() {
    const loadSiteTime = window.performance.now();

    if (this.selectedAsset && this.selectedAsset.machineNo) {
      this.getInvDataByDevice();
    }
    else {
      this.getInvDataBySite();
    }
  }

  getInvDataBySite() {
    const ngxLoaderKey = 'load inventory by site';
    this.ngxLoader.start(ngxLoaderKey);
    this.inventoryService.getInventoriesBySite(this.selectedSite.customerNo, this.deviceType).subscribe({
      next:(data: any[]) => {
        if (data) {
          const rootData = data;
          this.inventoriesRpt = rootData?.map(t => t.view);
          this.parseInventoryAndFilter(this.inventoriesRpt);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      },
      error:(response) => {
        if (response.status === 404) {
          this.inventories = [];
          this.inventoriesRpt = [];
          this.loadingInventories = false;
        }
        else {
          const message = ['organizational', 'site', 'system level'][this.view];
          this.toastService.stopLoadingAndShowError(response,
            `Error while searching inventory data for ${message} NDC view!`); 
        }
        this.ngxLoader.stop(ngxLoaderKey);
      }
    });
  }

  getInvDataByDevice() {
    const ngxLoaderKey = 'load inventory by device';
    this.ngxLoader.start(ngxLoaderKey);
    this.inventoryService.getInventoriesByDevice(this.selectedSite.customerNo, this.selectedAsset.machineNo).subscribe({
      next:(data: any[]) => {
        if (data) {
          const rootData = data;
          console.log("getInventoriesByDevice-rootData: " + rootData);
          this.inventoriesRpt = rootData.map(t => t.view);
          console.log("getInventoriesByDevice: " + this.inventoriesRpt);
          this.parseInventoryAndFilter(this.inventoriesRpt);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      },
      error:(response) => {
        if (response.status === 404) {
          this.inventories = [];
          this.inventoriesRpt = [];
          this.loadingInventories = false;
        }
        else {
          const message = ['organizational', 'site', 'system level'][this.view];
          this.toastService.stopLoadingAndShowError(response,
            `Error while searching inventory data for ${message} NDC view!`);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      }
    });
  }

  parseInventoryAndFilter(inventoriesRpt) {
    this.loadingInventories = false;
    this.inventories = [];

    inventoriesRpt?.forEach((t) => {

      try {
        let invObj = { // Return the new object structure
          id: 1,
          drugName: t.drugName,
          totalQuantity: t.currentInventory,
          ndc: t.ndc,
          manufacturer: t.manufacturer,
          actualDispenseQuantity: 0,
          avgDailyDispense: 0,
          canisterLocation: t.canisterLocation,
          lastDispInfo: []
        };

        if (this.selectedDateRange == 14) {
          invObj.actualDispenseQuantity = t.fourteenDayMetrics?.actualDispenseQuantity;
          invObj.avgDailyDispense = t.fourteenDayMetrics?.averageDailyDispense;
        }
        else if (this.selectedDateRange == 30) {
          invObj.actualDispenseQuantity = t.thirtyDayMetrics?.actualDispenseQuantity;
          invObj.avgDailyDispense = t.thirtyDayMetrics?.averageDailyDispense;
        } else if (this.selectedDateRange == 60) {
          invObj.actualDispenseQuantity = t.sixtyDayMetrics?.actualDispenseQuantity;
          invObj.avgDailyDispense = t.sixtyDayMetrics?.averageDailyDispense;
        }
        if (this.view == View.SYSTEM) {
          invObj.canisterLocation = t.canisterLocation;
        }

        //Ndc view parsing - start
        t.details.forEach((item) => {
          let linkValue = '';

          if (this.view == View.SITE) {
            const org = this.visibleOrgAssets.filter(oa => oa.organizationId === this.selectedSite.organizationId)[0];
            const asset = org.assets.find(s => s.machineNo === item.id);
            linkValue = asset ? (asset.name) : item.id;
          }

          let ndcObj = {
            lastFilledDate: item.lastDispensed,
            quantity: item.currentInventory,
            locationId: item.id,
            avgDailyDispense: 0,
            actualDispenseQuantity: 0,
            link: linkValue
          };

          if (this.view == View.SITE) {
            if (this.selectedDateRange == 14) {
              ndcObj.actualDispenseQuantity = item.fourteenDayMetrics?.actualDispenseQuantity;
              ndcObj.avgDailyDispense = item.fourteenDayMetrics?.averageDailyDispense;
            }
            else if (this.selectedDateRange == 30) {
              ndcObj.actualDispenseQuantity = item.thirtyDayMetrics?.actualDispenseQuantity;
              ndcObj.avgDailyDispense = item.thirtyDayMetrics?.averageDailyDispense;
            } else if (this.selectedDateRange == 60) {
              ndcObj.actualDispenseQuantity = item.sixtyDayMetrics?.actualDispenseQuantity;
              ndcObj.avgDailyDispense = item.sixtyDayMetrics?.averageDailyDispense;
            }
          }
          invObj.lastDispInfo.push(ndcObj);
        });
        //Ndc view parsing - end
        

        this.sortLastDispensedByDate(invObj);
        this.inventories.push(invObj);
      }
      catch (error) {
        console.log("inventory err: " + error);
      }
    });
    let filteredData = this.searchbyDrugNameorNDC(this.inventories);
     this.inventories = filteredData;
    this.inventoriesTemp = this.inventories.slice();

    if (this.isMobileView) { this.sortingChangeMobile(this.sort); }
    else { this.sortingChange(this.sort); }
  }

  compare(a: string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareN(a: number , b: number , isAsc: boolean) {
    return (Number(a) < Number(b) ? -1 : 1) * (isAsc ? 1 : -1);
  }


  sortingChange(sortObj) {
    this.sort = sortObj;
    if (this.inventoriesTemp == null) { return; }

    const data = this.inventoriesTemp.slice();

    this.inventories = data.sort((a, b) => {
      const isAsc = sortObj.direction === 'asc';
      switch (sortObj.active) {
        case 'drugName':
          return this.compare(a.drugName.trim().toLocaleLowerCase(), b.drugName.trim().toLocaleLowerCase(), isAsc);
        case 'avgDailyDispense':
          return this.compareN(a.avgDailyDispense, b.avgDailyDispense, isAsc);
        case 'totalQuantity':
          return this.compareN(a.totalQuantity, b.totalQuantity, isAsc);
        default:
          return 0;
      }
    });
  }

  sortingChangeSelect(sortObj) {
    this.sortingChangeMobile(sortObj);
    return;
  }

  sortingChangeMobile(sortObj) {
    if (sortObj) { this.selectedSort = sortObj.value; }

    if (this.inventoriesTemp == null) { return; }

    const data = this.inventoriesTemp.slice();

    this.inventories = data.sort((a, b) => {
      const isAsc = this.selectedSort.direction === 'asc';
      switch (this.selectedSort.active) {
        case 'drugName':
          return this.compare(a.drugName.trim().toLocaleLowerCase(), b.drugName.trim().toLocaleLowerCase(), isAsc);
        case 'avgDailyDispense':
          return this.compareN(a.avgDailyDispense, b.avgDailyDispense, isAsc);
        case 'totalQuantity':
          return this.compareN(a.totalQuantity, b.totalQuantity, isAsc);
        default:
          return 0;
      }
    });
  }

  filterInventory(value) {
    this.filterText = value;
    this.parseInventoryAndFilter(this.inventoriesRpt);
  }


  searchbyDrugNameorNDC(inventories) {
    inventories = [...inventories.filter(t =>
      t.drugName.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
      || t.ndc.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase()))];
    return inventories;
  }

  //Date range filter 
  dateRangeFilter(value) {
    this.selectedDateRange = value;
    this.parseInventoryAndFilter(this.inventoriesRpt)
  }

  sortLastDispensedByDate(item) {
    // Sort the date
    // Show the recenetly dispensed date(descending) at top by default
    item.lastDispInfo.sort((a, b) => <any>new Date(b.lastFilledDate) - <any>new
      Date(a.lastFilledDate));
  }

  //Download inventory in excel
  downloadExcel() {
    if (this.downloadingExcel) { return; }
    const ngxLoaderKey = 'downloadInventory';
    this.ngxLoader.start(ngxLoaderKey);

    let exportservice;//= this.inventoryService.getInventoriesExportByOrg(this.selectedDateRange);
    if (this.selectedAsset && this.selectedAsset.machineNo) {
      exportservice = this.inventoryService.getInventoriesExportByDevice(this.selectedSite.customerNo, this.selectedAsset.machineNo, this.selectedDateRange);
    }
    else {
      exportservice = this.inventoryService.getInventoriesExportBySite(this.selectedSite.customerNo, this.deviceType, this.selectedDateRange);
    }
    

    exportservice.subscribe((result: Blob) => {
      this.ngxLoader.stop(ngxLoaderKey);
      const blob = new Blob([result], { type: result.type });
      const blobUrl = window.URL.createObjectURL(blob);
      const fileAnchor = document.createElement('a');
      const filename = [
        `${this.parentOrgName} Inventories.xlsx`,
        `${this.selectedSite && this.selectedSite.name} (${this.parentOrgName}) Inventories.xlsx`,
        `${this.selectedAsset && this.selectedAsset.name} Inventories.xlsx`,
      ][this.view];
      fileAnchor.download = decodeURIComponent(filename);
      fileAnchor.href = blobUrl;
      fileAnchor.click();

    }, (error) => {
      this.toastService.stopLoadingAndShowError(error,
        'An error occurred while downloading inventories', ngxLoaderKey);
    });
  }  

  onDropDownExpand() { 
  (document.querySelector('.mat-expansion-panel-content') as HTMLElement).setAttribute("style", "height: 0px; visibility: visible;");
     }
}
