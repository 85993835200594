<div class="flex flex-row flex-wrap">
  <div class="basis-full">
    <app-card>
      <div title class="flex justify-start content-center items-center flex-row">
        <div class="basis-[29px]">
          <a routerLink="/customer-management" id="backtocm" tabindex="0" aria-label="Go back" role="button">
            <app-icon icon="arwlft"></app-icon>
          </a>
        </div>

        <ng-container>
          <div class="justify-between content-center items-center flex-1">
            <div>
              <h5 class="ucase m-0 text-primaryblue pointer" tabindex="0" [attr.aria-label]="SelectedSiteName">
                {{ SelectedSiteName }}
              </h5>
            </div>
            <div class="sm:grow-0 sm:shrink sm:basis-60 hidden sm:block">
              <mat-form-field>
                <mat-select id="edit-cus-select" class="m-t-0" [value]="activeChild">
                  <mat-option *ngFor="let link of visibleChildren" id="{{'edit-cus-opt-' + link.routerLink}}" 
                    [value]="link.routerLink" [routerLink]="link.routerLink">{{ link.title }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>

      <div>
        <div class="p-16 bb-Gray4 m-b-2x hidden xs:block">
          <mat-form-field>
            <mat-select id="edit-cus-mobile" class="m-t-0" [value]="activeChild">
              <mat-option *ngFor="let link of visibleChildren" id="{{'edit-cus-mobile-' + link.routerLink}}" 
                [value]="link.routerLink" [routerLink]="link.routerLink">{{ link.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex flex-row lt-md:flex lt-md:flex-col gt-sm:flex gt-sm:flex-row gt-sm:flex-nowrap">
          <div class="org_indetail_scroll flex">
            <div class="side-links gt-sm:basis-40 hidden gt-sm:block">
              <a *ngFor="let link of visibleChildren" [routerLink]="link.routerLink" routerLinkActive="active" id="{{ link.idValue }}" class="side-link">
                <span>{{ link.title }}</span>
              </a>
            </div>
            <div class="flex-1">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div> 
</div>
