import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Product } from '../models/product';
import { SubProduct } from '../models/sub-product';
import { PackageSize } from '../models/package-size';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends DataService<Product> {

    httpClient: HttpClient;
    constructor(http: HttpClient) {
        super(environment.apiUrl.notificationService, http);
        this.httpClient = http;
    }
    getSubProducts(): Observable<SubProduct[]> {
        return this.httpClient.get<SubProduct[]>(`${environment.apiUrl.productService}subproducts/Active`);
    }
    getPackageSize(): Observable<PackageSize[]> {
        return this.httpClient.get<PackageSize[]>(`${environment.apiUrl.productService}packages/Active`);
    }
    getActiveProducts() {
        return this.getAll(environment.apiUrl.productService + 'products/Active');
    }
    getActiveMachinesType() {
        return this.getAll(environment.apiUrl.productService + 'machinetype/Active');
    }
}
