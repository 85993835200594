<h6 class="flex justify-between content-center items-center flex-row">
  System Status
  <!-- <a routerLink="/reports/774c17c7-5923-41fa-9ea0-4f0bdebb8af7" id="report_multiple_systems_status" class="btn-link"
      aria-label="Multiple System Status">
      Report >
  </a> -->
</h6>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" class="pp_table">

  <ng-container matColumnDef="system">
    <th mat-header-cell *matHeaderCellDef> System </th>
    <td mat-cell *matCellDef="let element" class="system" [matTooltipDisabled]="element?.system.length<13" 
    [matTooltip]="element?.system" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
      {{ ( element?.system && element?.system.length > 10+3) ? ((element?.system).slice(0,
        10) + '...') : element?.system}} 
    </td>
  </ng-container>

  <ng-container matColumnDef="alert">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element" class="alert" 
      [ngClass]="{'icon_alert': element.errorType === 'Error', 'icon_warning': element.errorType === 'Warn'}">
      {{element.alert}} </td>
  </ng-container>
  
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element" class="status" [ngClass]="{'error_txt': element.errorType === 'Error'}" 
    [matTooltip]="element?.status" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
      {{ ( element?.status && element?.status.length > (13 + 3)) ? ((element?.status).slice(0,
      13) + '...') : element?.status}}
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef class="text-right"> Duration </th>
    <td mat-cell *matCellDef="let element" class="duration" [ngClass]="{'error_txt': element.errorType === 'Error'}" class="text-right">
      {{element.duration}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="noDataExistsForMultipleDevice === true">
  <p class="pp_report">
    <span class="rcountdesc">No data exists</span>
  </p>
</div>

<hr>