import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoggerService extends DataService<any> {
  private logSource: string ;
  private logURL: string;
  private logLevel: string;

  constructor(http: HttpClient) {
    super(environment.apiUrl.logService, http);
    this.logSource= 'Parata.CA.Portal';
    this.logURL=`${environment.apiUrl.logService}LogMessage`;
    this.logLevel=`${environment.logLevel}`;
  }

  logError(hintMsg: string, errMsg: string) {
    this.callLogger(hintMsg,errMsg,'Error');
  }

  logWarning(hintMsg: string, warnMsg: string) {
    if(this.logLevel=='All' || this.logLevel=='Warning')
      {this.callLogger(hintMsg,warnMsg,'Warning');}
  }

  logInfo(hintMsg: string, infoMsg: string) {
    if(this.logLevel=='All' || this.logLevel=='Info')
      {this.callLogger(hintMsg,infoMsg,'Info');}
  }

  private callLogger(hintMsg: string, infoMsg: string, logType: string){
    const logger = { source: this.logSource, message: hintMsg + infoMsg , traceLevel: logType };
    this.create(logger, this.logURL).subscribe(res => {
      // success
      console.log(hintMsg + '. Please use the GUID to get more details - ' + res);
     }, (error) => {
       // fail
      // console.log(hintMsg + " Failed while logging the details");
       // console.log(error);
     });
  }
}
