import { Component, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Organization } from '../../models/organization';
import { OrganizationService } from '../../services/organization.service';
import * as constants from '../../../../config/app-constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-organization-dialog',
  templateUrl: './add-organization-dialog.component.html',
  styleUrls: ['./add-organization-dialog.component.scss']
})

export class AddOrganizationDialogComponent implements OnInit {

  formGroup: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(50)])
  });

  duplicateorg = false;
  duplicateorgname = '';

  constructor(
    public dialogRefNew: MatDialogRef<AddOrganizationDialogComponent>, private ngZone: NgZone,
    public dialog: MatDialog,
    private organizationService: OrganizationService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
  }

  isLoad(event) {
    if (event != undefined && event.keyCode == 13) {
      this.addOrganization();
      return false;
    }
  }
  closePopup(val: any='') {
    this.formGroup.get('name').markAsUntouched();
    this.dialogRefNew.close(val);
    this.dialogRefNew.close(true);
    this.dialog.closeAll();
    this.ngZone.run(() => {
      this.dialogRefNew.close(val);
    });
  }

  validateForm() {
    if (!this.formGroup.valid) {
      this.formGroup.get('name').markAsTouched();
    }
  }

  addOrganization() {

    if (!this.formGroup.valid) {
      this.validateForm();
      return;
    }

    this.ngxLoader.start('add-organization-submit');
    const organization: Organization = this.formGroup.getRawValue();
    this.organizationService.getOrganizationByName(organization.name)
      .subscribe((organizations) => {
        this.ngxLoader.stop('add-organization-submit');
        this.duplicateorg = true;
        this.duplicateorgname = organization.name;
      }, (res) => {
        this.duplicateorg = false;
        this.organizationService.saveOrganization(organization).subscribe(
          () => {
            this.ngxLoader.stop('add-organization-submit');
            this.closePopup('success');
            setTimeout(() => {
              this.toastService.openToast('New Organization Created', constants.ToastPanelType.done);
            }, 700);
          },
          ({ error }) => {
            this.ngxLoader.stop('add-organization-submit');
            const message = error.errors ? Object.values(error.errors)
              .map((e: string[]) => e.join('\n'))
              .join('\n') : error.error;
            this.toastService.openToast(message, constants.ToastPanelType.error);
          },
        );
      });
  }
}
