import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Asset } from '../models/asset';

@Injectable({
  providedIn: 'root',
})
export class AssetService extends DataService<Asset> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}assets/`, http);
    this.httpClient = http;
  }

  getActiveMachinesType(): Observable<Asset[]> {
    return this.http.get<any[]>(`${this.url}MachineTypes`);
  }

  getAssetByOrgId(orgId: string): Observable<any[]> {
      return this.http.get<any[]>(`${this.url}Active/Org/${orgId}`);
  }

  getActiveAssetsByType(assetType: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}Active/AssetType/${assetType}`);
  }
}
