<div class="packagerdowntime_dialog">
    <form class="flex justify-between content-stretch items-stretch flex-col">
        <div>
            <h1 mat-dialog-title class="!flex justify-between content-stretch items-stretch flex-row">
                <div tabindex="0">
                   {{ data.title | uppercase }}
                </div>
                <div>
                    <button id="closeDialogBtn" (click)="closeDialog()" class="btn-transparent btn_icon" type="button" 
                        aria-label="close">
                        <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="">
                    </button>
                </div>
            </h1>
            <mat-dialog-content class="pack-down-container">
                <form>
                    <mat-form-field floatLabel="never" class="flex-1">
                        <label for="Systems">
                            <span>Systems</span>
                        </label>
                        <mat-select id="Systems" [(value)]="selectedSystem" [disableOptionCentering]="true">
                            <mat-option *ngFor="let system of systems" [value]="system.value" 
                                [matTooltip]="system?.viewValue" matTooltipPosition="below" 
                                matTooltipClass="my-custom-tooltip" 
                                [matTooltipDisabled]="system?.viewValue.length < (13 + 3)">
                                {{ ( system?.viewValue && system?.viewValue.length > (13 + 3)) ?
                                ((system?.viewValue).slice(0,
                                13) + '...') : system?.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
            <button mat-button class="btn-default btn-md" type="button" id="cancelDialogBtn" 
              (click)="closeDialog()">Cancel</button>
            <button mat-button class="btn-primary btn-md m-l-16" type="button" id="add_org" 
              (click)="closeDialogAdd()">Add</button>
        </mat-dialog-actions>
    </form>
</div>
