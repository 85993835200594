<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>
        <h1 mat-dialog-title class="!flex flex-row">
            <div class="flex justify-start content-center items-center flex-row">
                <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
                    Adjust Duplicate NDC
                </div>
                <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
                    <mat-icon (click)="dialogRef.close()" class="cross-wizard">
                        <img src="/assets/img/icons/icon.16.states.svg" alt="">
                    </mat-icon>
                </div>
            </div>

    </h1>


    <mat-dialog-content>
        <div class="flex justify-start content-center items-center flex-row">
            <button mat-button  class="btn-blue btn-sm" style="margin-right:5px"  (click)="highestUsageDrugSelect()"  id="Highest-Usage-Drugs" type="button" aria-label="Highest Usage Drugs">Highest Usage Drugs</button>
            <button mat-button  class="btn-light btn-sm" style="margin-right:5px"   id="Lowest-Usage-Drugs" (click)="lowestUsageDrugSelect()" type="button" aria-label="Lowest Usage Drugs">Lowest Usage Drugs</button>
            <button mat-button  class="btn-warning btn-sm" style="margin-right:5px" (click)="allDrugUnSelect()"  id="unselect-all" type="button" aria-label="Unselect All">Unselect All</button>

        </div>
        <div class="mat-elevation-z8" #TABLE>
            <table  #table mat-table [dataSource]="dataSource">
                 <!-- Checkbox Column -->
                 <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [(ngModel)]="element.isChecked" (change)="drugCellRowChecked(element, $event.checked)" ></mat-checkbox>
                    </td>
                </ng-container>
                <!-- SL Name Column -->
                <ng-container matColumnDef="sl">
                    <th mat-header-cell *matHeaderCellDef> SL </th>
                    <td mat-cell *matCellDef="let element"> {{element?.sl}} </td>
                </ng-container>

                <!-- Drug Name Column -->
                <ng-container matColumnDef="drugName">
                    <th mat-header-cell *matHeaderCellDef> Drug Name </th>
                    <td mat-cell *matCellDef="let element"> {{element?.drugName}} </td>
                </ng-container>

                <!-- GCN Column -->
                <ng-container matColumnDef="gcnNumber">
                    <th mat-header-cell *matHeaderCellDef> GCN </th>
                    <td mat-cell *matCellDef="let element"> {{element?.gcnNumber}} </td>
                </ng-container>

                <!-- Ndc Column -->
                <ng-container matColumnDef="ndc">
                    <th mat-header-cell *matHeaderCellDef> NDC </th>
                    <td mat-cell *matCellDef="let element"> {{element?.ndc}} </td>
                </ng-container>


                <!-- Manufacturer Column -->
                <ng-container matColumnDef="manufacturer">
                    <th mat-header-cell *matHeaderCellDef> Manufacturer</th>
                    <td mat-cell *matCellDef="let element"> {{element?.manufacturer}} </td>
                </ng-container>
                 <!-- strength Column -->
                 <ng-container matColumnDef="strength">
                    <th mat-header-cell *matHeaderCellDef> Strength</th>
                    <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
                </ng-container>

                <!-- Unit Usage Column -->
                <ng-container matColumnDef="unitOfUsage">
                    <th mat-header-cell *matHeaderCellDef> Unit Usage </th>
                    <td mat-cell *matCellDef="let element"> {{element?.unitOfUsage}} </td>
                </ng-container>

                <!-- Rx Qty Column -->
                <ng-container matColumnDef="rxQty">
                    <th mat-header-cell *matHeaderCellDef> Rx Qty </th>
                    <td mat-cell *matCellDef="let element"> {{element?.rxQty}} </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row (click)="selectedRow = row" [style.background]="row?.backgroundColor"  [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>


            </table>
             <table>
                <tr *ngIf="dataSource?.data.length==0">
                    <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                        No Records Found!
                    </td>
                </tr>
            </table>

        </div>


    </mat-dialog-content>
</div>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
        <button mat-button class="btn-blue btn-md"  id="Confirm" [disabled]="selectedGcnDrugList.length === 0" (click)="saveGcnDrugs()" type="button" aria-label="Confirm">Confirm</button>
        <button mat-button class="btn-primary btn-md" (click)="redirect()" id="Go_Back" type="button" aria-label="Go_Back">Go Back</button>
    </mat-dialog-actions>

</div>
