<div class="inner-mat-dialog title-section">
  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">

        <mat-icon (click)="redirectToDrugPage()" class="mt-point5rem">
          <img src="/assets/img/icons/ico.16.arwlft.svg" alt="">
        </mat-icon>
        <h3>{{drugName}}</h3><span *ngIf="this.drugCharacteristicsModel.id===0"
          class="alert-lable">Available only in FDB</span>
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="!isEditDisable && openDrugDetailsImportWizard()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.edit.svg" alt="">
        </mat-icon>
      </div>
    </div>
  <div class="scroll-height">
  <div class="drug-info flex flex-row">

    <div class="drug-left-side">
      <div class="image-div flex justify-between content-center items-center flex-row">
        <div>
          <img (click)="openImportWizardImageSlide()" class="img-width" [src]="images.serialImage"
            alt="drug image">
        </div>

        <div class="flex flex-row justify-between content-center items-center h-full">
          <button *ngIf="this.drugCharacteristicsModel.quarantined"
            [style.background-color]="this.drugCharacteristicsModel.quarantinedColor" class="btn-status mt-2rem">
            {{this.drugCharacteristicsModel.quarantined}}</button>

          <button *ngIf="this.drugCharacteristicsModel.drugStatus"
            [style.background-color]="this.drugCharacteristicsModel.drugStatusColor" class="btn-status mt-2rem">
            {{this.drugCharacteristicsModel.drugStatus}}</button>

          <p class="country-value mt-2rem">{{drugCharacteristicsModel.countryCode}}
            <img class="flag-img" *ngIf="drugCharacteristicsModel.countryCode?.length>0"
              [src]="drugCharacteristicsModel.countryCode === 'USA'? '/assets/img/country/usa.png': drugCharacteristicsModel.countryCode === 'CAN'? '/assets/img/country/canadian.webp':'/assets/img/no-image.png' "
               />
          </p>
          <div class="sourceDiv">
            <span class="label">Data Source</span>
            <mat-form-field>
              <mat-select [(ngModel)]="dataSourceId" (selectionChange)="onSourceChange($event)" ngDefaultControl>
                <mat-option *ngFor="let source of dataSources" [value]="source.value">
                  {{ source.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        </div>

      </div>



      <div class="flex flex-col">
        <h5>Characteristics</h5>
        <div class="row-info flex flex-row">
          <div class="flex flex-col basis-[20%]">
            <span class="label">Generic Name</span>
            <span class="label-value">{{drugCharacteristicsModel.genericName}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Drug Name</span>
            <span class="label-value">{{drugCharacteristicsModel.drugName}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Strength</span>
            <span class="label-value">{{drugCharacteristicsModel.strength}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Unit</span>
            <span class="label-value">{{drugCharacteristicsModel.unit}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Drug Number</span>
            <span class="label-value">{{drugCharacteristicsModel.drugId}}</span>
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Active</span>
            <span class="label-value">{{drugCharacteristicsModel.active}}</span>
          </div>


        </div>

        <div class="row-info flex flex-row">
          <div class="flex flex-col basis-[20%]">
            <span class="label">Manufacturer</span>
            <span class="label-value">{{drugCharacteristicsModel.manufacturer}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Route Of Admin</span>
            <span class="label-value">{{drugCharacteristicsModel.roa}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">OTC</span>
            <span class="label-value">{{drugCharacteristicsModel.otc}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Dosage Form</span>
            <span class="label-value">{{drugCharacteristicsModel.dosageForm}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Niosh</span>
            <span class="label-value">{{drugCharacteristicsModel.niosh}}</span>
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">AntiBiotic</span>
            <span class="label-value">{{drugCharacteristicsModel.antibiotics}}</span>
          </div>

        </div>

        <div class="row-info flex flex-row">
          <div class="flex flex-col basis-[20%]">
            <span class="label">Distributor</span>
            <span class="label-value">{{drugCharacteristicsModel.distributor}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Unit of Use</span>
            <span class="label-value">{{drugCharacteristicsModel.unitOfUse}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Unit Dose</span>
            <span class="label-value">{{drugCharacteristicsModel.unitDose}}</span>
          </div>



          <div class="flex flex-col basis-[20%]">
            <span class="label">Storage Condtions</span>
            <span class="label-value">{{drugCharacteristicsModel.storageConditions}}</span>
          </div>

          <div class="flex flex-col basis-[15%]">
            <span class="label">GPI #</span>
            <span class="label-value">{{drugCharacteristicsModel.gpi}}</span>
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Generic Number</span>
            <span class="label-value">{{drugCharacteristicsModel.gcn}}</span>
          </div>
          <hr>
        </div>

        <div class="row-info flex flex-row">

          <div class="flex flex-col basis-[20%]">
            <span class="label">Shape</span>
            <span class="label-value">{{drugCharacteristicsModel.shape}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">Quarantine Reason</span>
            <span class="label-value">{{drugCharacteristicsModel.quarantineReason}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Oral Solid</span>
            <span class="label-value">{{drugCharacteristicsModel.oralSolid}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Schedule</span>
            <span class="label-value">{{ this.drugCharacteristicsModel.schedule}}</span>
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Updated</span>
            <span class="label-value">{{drugCharacteristicsModel.updated | date:'shortDate'}}</span>
          </div>
          <hr />
        </div>

        <h5>CDDB Standard Fields</h5>

        <div class="row-info flex flex-row gap-[1rem]">
          <div class="flex flex-col basis-[25%]">
            <span class="label">Unit</span>
            <span class="label-value">{{drugCharacteristicsModel.unitStd}}</span>
          </div>
          <div class="flex flex-col basis-[25%]">
            <span class="label">Schedule</span>
            <span class="label-value">{{ this.drugCharacteristicsModel.scheduleStd}}</span>
          </div>
          <div class="flex flex-col basis-[25%]">
            <span class="label">Route Of Admin</span>
            <span class="label-value">{{drugCharacteristicsModel.roaStd}}</span>
          </div>

          <div class="flex flex-col basis-[25%]">
            <span class="label">Dosage Form</span>
            <span class="label-value">{{drugCharacteristicsModel.dosageFormStd}}</span>
          </div>

        </div>

        <div class="row-info flex flex-row gap-[1rem]">

          <div class="flex flex-col basis-[25%]">
            <span class="label">Shape</span>
            <span class="label-value">{{drugCharacteristicsModel.shapeStd}}</span>
          </div>

        <div class="flex flex-col basis-[25%]">
          <span class="label">Coatings</span>
          <span class="label-value">{{drugPackagesModel.coating}}</span>
        </div>

        <div  class="flex flex-col basis-[25%] mr-20px">
          <div class="flex flex-row">
            <span class="label">Color</span>
          </div>
          <span class="label-value">{{drugPackagesModel.colorStd}}</span>

        </div>

        <div  class="flex flex-col basis-[25%] mr-20px">
          <div class="flex flex-row">
            <span class="label">Scoring</span>
          </div>
          <span class="label-value">{{drugPackagesModel.scoring}}</span>
        </div>

         </div>

          <div class="row-info flex flex-row gap-[1rem]">
          <div class="flex flex-col basis-[15%]">
            <span class="label">2D Barcodes</span>
            <span class="label-value">{{drugPackagesModel.barcode2dStd}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Package Type</span>
            <span class="label-value">{{drugCharacteristicsModel.packagingStd}}</span>
          </div>
        </div>

        <!-- ---------------------- -->
        <h5>General Attributes</h5>
        <div class="row flex gap-[1rem] pt-10px pl-1rem" >
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Length</span>
            <span class="label-value">{{drugGeneralAttributes.length}}</span>
          </div>
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Width</span>
            <span class="label-value">{{drugGeneralAttributes.width}}</span>
          </div>
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Thickness</span>
            <span class="label-value">{{drugGeneralAttributes.thickness}}</span>
          </div>
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Diameter</span>
            <span class="label-value">{{atpModel.diameter}}</span>
          </div>
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Piece Weight</span>
            <span class="label-value">{{drugGeneralAttributes.pieceWeight}}</span>
          </div>
          <div class="flex flex-col basis-[16.6%]">
            <span class="label">Diagonal</span>
            <span class="label-value">{{drugGeneralAttributes.diagonal}}</span>
          </div>
        </div>
        <hr>
        <div class="row flex gap-[1rem] pt-10px pl-1rem" >
          <div class="flex flex-col basis-[25%]">
            <span class="label">Classification Shape</span>
            <span class="label-value">{{drugGeneralAttributes.classificationShape}}</span>
          </div>
          <div class="flex flex-col basis-[25%]">
            <span class="label">Pill Volume</span>
            <span class="label-value">{{this.atpModel.pillVolume}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">T1</span>
            <span class="label-value">{{atpModel.t1Status}}</span>
          </div>

          <div class="flex flex-col basis-[15%]">
            <span class="label">T2</span>
            <span class="label-value">{{atpModel.t2Status}}</span>
          </div>
          <div class="flex flex-col basis-[20%]">
            <span class="label">30 Dram Capacity</span>
            <span class="label-value">{{drugPackagesModel.thirtyDramCapacity}}</span>
          </div>

        </div>
        <hr>
        <div class="row-info flex flex-row gap-[1rem] pt-10px pl-1rem" >

          <div class="flex flex-col basis-[15%]">
            <span class="label"> Imprint 1</span>
            <span class="label-value">{{drugPackagesModel.imprint1}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label"> Imprint 2</span>
            <span class="label-value">{{drugPackagesModel.imprint2}}</span>
          </div>
          <div  class="flex flex-col basis-[20%] mr-20px">
            <div class="flex flex-row">
              <span class="label">Color</span>
            </div>
            <span class="label-value">{{drugPackagesModel.color}}</span>
          </div>
          <div  class="flex flex-col basis-[25%] mr-20px">
            <div class="flex flex-row">
             <span class="label">Country</span>
            </div>
            <span class="label-value">{{drugCharacteristicsModel.countryCode}}</span>
          </div>

          <div class="flex flex-col basis-[25%]">
            <span class="label">Drug Classification Type</span>
            <span class="label-value">{{drugCharacteristicsModel.drugNumberType}}</span>
          </div>

        </div>

        <h5>Package</h5>
        <div class="row-info flex flex-row gap-[1rem] pt-10px pl-1rem" >
          <div class="flex flex-col basis-[23%]">
            <span class="label">Barcode</span>
            <span class="label-value">{{drugPackagesModel.barcode}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">2 D Barcodes</span>
            <span class="label-value">{{drugPackagesModel.barcode2d}}</span>
          </div>
        </div>

        <div class="row-info flex flex-row gap-[1rem] pt-10px pl-1rem" >
          <div class="flex flex-col basis-1/5">
            <span class="label">Package Type</span>
            <span class="label-value">{{drugCharacteristicsModel.packaging}}</span>
          </div>
          <div class="flex flex-col basis-1/5">
            <span class="label">Capacity</span>
            <span class="label-value">{{drugCharacteristicsModel.packageSize}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Width</span>
            <span class="label-value">{{drugPackagesModel.packageWidth}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Height</span>
            <span class="label-value">{{drugPackagesModel.packageHeight}}</span>
          </div>
          <div class="flex flex-col basis-[15%]">
            <span class="label">Depth</span>
            <span class="label-value">{{drugPackagesModel.packageDepth}}</span>
          </div>

        </div>

        <h5>Inventory</h5>

        <div *ngFor="let inventory of inventoryList">
          <div  *ngIf="!inventory.isDestroyed" class="flex flex-col div-border">
            <div class="flex flex-row">

              <div class="flex flex-col basis-[23%]">
                <span class="label">Inventory Number</span>
                <span class="label-value">{{inventory.inventoryNumber}}</span>
              </div>
              <div class="flex flex-col basis-1/5">
                <span class="label">Inventory</span>
                <span class="label-value">{{inventory.inventoryQuantity}}</span>
              </div>
              <div class="flex flex-col basis-1/5">
                <span class="label">Source</span>
                <span class="label-value">{{inventory.source}}</span>
              </div>
              <div class="flex flex-col basis-1/5">
                <span class="label">Added</span>
                <span class="label-value">{{inventory.addedDate}}</span>
              </div>
              <div class="flex flex-col basis-1/5">
                <span class="label">Owner</span>
                <span class="label-value">{{inventory.owner}}</span>
              </div>
              <div class="flex flex-col basis-1/5">
                <span class="label">Last Changed</span>
                <span class="label-value">{{inventory.lastChangedDate}}</span>
              </div>
            </div>
            <div class="flex flex-row">
            </div>

            <div class="flex flex-col basis-1/5">
              <span class="label"> Inventory Comments</span>
              <span class="label-value">{{inventory.comments}}</span>
            </div>

          </div>
        </div>

      </div>

    </div>
    <div class="drug-right-side">
      <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows>
          <ng-container matColumnDef="deviceName">
            <th mat-header-cell *matHeaderCellDef>Device Name</th>
            <td mat-cell *matCellDef="let element" width="30%"><span class="fw-b">
                <span *ngIf="element.isExpanded">
                  <mat-icon class="cross-wizard">
                    <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                  </mat-icon>
                </span>
                <span *ngIf="!element.isExpanded">
                  <mat-icon class="cross-wizard">
                    <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                  </mat-icon>
                </span>
                {{element.deviceName}}</span> </td>
          </ng-container>

          <ng-container matColumnDef="drugStatus">
            <th mat-header-cell *matHeaderCellDef>Drug Status</th>
            <td mat-cell *matCellDef="let element">
              <!-- <button *ngIf="element.drugStatus" [style.background-color]="element.drugRecordColor" class="btn-status">
                {{element.drugStatus}}</button> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="deviceStatus">
            <th mat-header-cell *matHeaderCellDef>Device Status</th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="element.deviceStatus" [style.background-color]="element.deviceStatusColor"
                class="btn-status"> {{element.deviceStatus}}</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="labOptimizedStatus">
            <th mat-header-cell *matHeaderCellDef>lab status</th>
            <td mat-cell *matCellDef="let element">
              <span class="lab-status">Lab Optimized
                <span>{{element.labOptimizedStatus}}</span> </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="4">
              <div class="row queue-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                <div *ngIf="element.deviceName==='ATP'" class="pb-point8rem div-border">
                  <div class="flex flex-col">
                  <h5>Attributes</h5>

                  <div class="row row-info flex gap-[1rem] div-border">

                    <div class="flex flex-col basis-1/5">
                      <span class="label">TCG Pill Type</span>
                      <span class="label-value">{{atpModel.tcgPillType }}</span>
                    </div>

                    <div class="flex flex-col basis-1/5">
                      <span class="label">Drug Status</span>
                      <span class="label-value">{{atpModel.atpDrugStatus }}</span>
                    </div>

                    <div class="flex flex-col basis-1/5">
                      <span class="label">Lab Optimize</span>
                      <span class="label-value">{{atpModel.labOptimized }}</span>
                    </div>

                  </div>
                  <h5>Canister</h5>
                  <div class="row row-info flex gap-[1rem]">
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Canister Number</span>
                      <span class="label-value">{{atpModel.canisterNumber }}</span>
                    </div>

                    <div class="flex flex-col basis-1/5">
                      <span class="label">Canister Capacity</span>
                      <span class="label-value">{{atpModel.canisterCapacity }}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">High Cap Canister</span>
                      <span class="label-value">{{atpModel.highCapCanister }}</span>
                    </div>

                  </div>

                </div>
                </div>
                <div *ngIf="element.deviceName==='PASS'">
                  <div class="flex flex-col pb-point8rem div-border">
                    <h5>Attributes</h5>

                    <div class="row flex gap-[1rem]">


                      <div class="flex flex-col basis-1/5">
                        <span class="label">Drug Status</span>
                        <span class="label-value">{{this.passModel.passDrugStatus}}</span>
                      </div>
                      <div class="flex flex-col basis-[15%]">
                        <span class="label">DNU Reason</span>
                        <span class="label-value">{{this.passModel.dnuReason}}</span>
                      </div>
                      <div class="flex flex-col basis-[15%]">
                        <span class="label">Lab Optimize</span>
                        <span class="label-value">{{this.passModel.passLabOptimized}}</span>
                      </div>
                    </div>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-[15%]">
                        <span class="label">Canister ID</span>
                        <span class="label-value">{{this.passModel.canisterId}}</span>
                      </div>
                      <div class="flex flex-col basis-1/5">
                        <span class="label">Snap Canister ID</span>
                        <span class="label-value">{{this.passModel.snapCanisterId}}</span>
                      </div>

                    </div>

                  </div>
                </div>
                <div *ngIf="element.deviceName==='MAX'" class="pb-point8rem">
                  <div class="flex flex-col">
                    <h5>Attributes</h5>

                    <div class="row flex gap-[1rem] div-border">
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">13 Dram DNU</span>
                        <span class="label-value">{{this.maxModel.thirtyeenDramCapacity}}</span>
                      </div>
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Do Not Use?</span>
                        <span class="label-value">{{this.maxModel.doNotUse}}</span>
                      </div>

                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Drug Status</span>
                        <span class="label-value">{{this.maxModel.maxDrugStatus}}</span>
                      </div>

                      <div class="flex flex-col basis-1/5">
                        <span class="label">Lab Optimize</span>
                        <span class="label-value">{{this.maxModel.labOptimizedStatus}}</span>
                      </div>
                    </div>

                    <h5>Cell Settings</h5>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Cell Height</span>
                        <span class="label-value">{{this.maxModel.cellHeight}}</span>
                      </div>
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Cell Width</span>
                        <span class="label-value">{{this.maxModel.cellWidth}}</span>
                      </div>
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Baffle</span>
                        <span class="label-value">{{this.maxModel.baffle}}</span>
                      </div>
                      <div class="flex flex-col basis-[18%]">
                        <span class="label">Pressure</span>
                        <span class="label-value">{{this.maxModel.pressure}}</span>
                      </div>

                    </div>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-1/5">
                        <span class="label">Cell Capacity</span>
                        <span class="label-value">{{maxModel.cellCapacity }}</span>

                      </div>
                      <div class="flex flex-col basis-1/5">
                        <span class="label">Super Cell Capacity</span>
                        <span class="label-value">{{maxModel.superCellCapacity }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="element.deviceName==='SYNMED'">
                  <div class="flex flex-col div-border syn-div">
                    <h5>Attributes</h5>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-1/4">
                        <span class="label">Drug Status</span>
                        <span class="label-value">{{this.synmedModel.synmedDrugStatus}}</span>
                      </div>
                      <div class="flex flex-col basis-1/4">
                        <span class="label">Lab Optimize</span>
                        <span class="label-value">{{this.synmedModel.labOptimizedStatus}}</span>
                      </div>
                      <div class="flex flex-col basis-1/4">
                        <span class="label">Fragile Flag</span>
                        <span class="label-value">{{this.synmedModel.fragileFlag}}</span>
                      </div>
                      <div class="flex flex-col basis-1/4">
                        <span class="label">DNU reason</span>
                        <span class="label-value">{{this.synmedModel.dnuReason}}</span>
                      </div>
                    </div>


                    <h5>Settings</h5>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-[25%]">
                        <span class="label">Preferred Pipette Size</span>
                        <span class="label-value">{{this.synmedModel.preferredPipetteSize}}</span>
                      </div>
                      <div class="flex flex-col basis-[15%]">
                        <span class="label">Lid Hole</span>
                        <span class="label-value">{{this.synmedModel.lidHoleSize}}</span>
                      </div>
                      <!-- <div class="flex flex-col basis-1/5">
                        <span class="label">Large Container Capacity</span>
                        <span class="label-value">{{this.synmedModel.largeCanisterCapacity}}</span>
                      </div> -->
                      <div class="flex flex-col basis-[25%]">
                        <span class="label">Lrg Pipette Drop Height</span>
                        <span class="label-value">{{this.synmedModel.largePipetteDropHeight}}</span>
                      </div>
                      <div class="flex flex-col basis-[20%]">
                        <span class="label">Mass Index</span>
                        <span class="label-value">{{this.synmedModel.massIndex}}</span>
                      </div>

                    </div>
                    <div class="row flex gap-[1rem]">
                      <div class="flex flex-col basis-[25%]">
                        <span class="label">Preferred Container Size</span>
                        <span class="label-value">{{this.synmedModel.preferredContainerSize}}</span>
                      </div>
                      <div class="flex flex-col basis-[15%]">
                        <span class="label">Preferred Lid Hole</span>
                        <span class="label-value">{{this.synmedModel.preferredLidHoleSize}}</span>
                      </div>

                      <!-- <div class="flex flex-col basis-1/5">
                        <span class="label">Small Container Capacity</span>
                        <span class="label-value">
                          {{this.synmedModel.smallCanisterCapacity}}
                        </span>
                      </div> -->
                      <div class="flex flex-col basis-[25%]">
                        <span class="label">Sm Pipette Drop Height</span>
                        <span class="label-value">{{this.synmedModel.smallPipetteDropHeight}}</span>
                      </div>
                      <div class="flex flex-col basis-[20%]">
                        <span class="label">Toggle</span>
                        <span class="label-value">{{this.synmedModel.toggleFlag}}</span>
                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="element.deviceName==='BEACON'">
                  <span>Coming Soon..</span>
                </div>
              </div>
            </td>
          </ng-container>
          <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr> -->
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="queue-element-row"
            [class.queue-expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded;"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="queue-detail-row"></tr>
        </table>
      </div>
    </div>

  </div>
</div>
</div>
