<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>

        <h2 mat-dialog-title class="m-b-0 !flex flex-row xs:hidden sm:block md:block lg:block xl:block">
            <div class="uppercase basis-[200px]" tabindex="0" aria-label="Permission Groups ">
                Permission Groups 
            </div>
            <div class="caps flex-1" tabindex="0" aria-label="Add New Group">
                <a class="btn-link m-l-20" (click)="addNewGroup()" [ngClass]="(disableAddNew)?' disablegrid1' :'' ">Add
                    New Group ></a>
            </div>
            <div class="flex flex-row justify-end content-center items-center h-full">
                <button mat-button class="btn-default btn-sm btn-delete" [disabled]="selectedPerGroup?.isDefault" (click)="deletePermissionGroups(selectedPerGroup?.isDefault)">Delete</button>
            </div>
            <div class="flex flex-row justify-end content-center items-center h-full basis-[3%]">
                <button class="btn-transparent btn_icon" type="button" aria-label="close" (click)="closePopup()" (keypress.enter)="closePopup()"> <!-- mat-dialog-close -->
                    <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="">
                </button>
            </div>
        </h2><!--         
        <h1 mat-dialog-title>
            Permission Groups   <a class="btn-link m-l-20" (click)="addNewGroup()">Add
                New Group ></a>
        </h1> -->
        <mat-dialog-content class="user-management-container">
            <app-permission-groups isGearIconClicked=true (disableAddNew)="disableAddNewButton($event)"  (delPermissionGroup)="disableDelete($event)" [clickAddGroup]="clickAddGroup" [refreshPerGroup]="refreshPerGroup"></app-permission-groups>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
        <button mat-button class="btn-default btn-md" (click)="closePopup()" id="cancel_pop">Cancel</button>
        <button mat-button class="btn-primary btn-md" (click)="closePopup()" id="save_group">Done</button>
    </mat-dialog-actions>    
</div>