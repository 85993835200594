import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trim]'
})
export class TrimDirective {

  constructor(private el: ElementRef,
              private control: NgControl) {
  }
  @HostListener('focusout') onFocusOut() {
    const value: string = this.el.nativeElement.value.trim();
    this.control.control?.setValue(value);
    this.control.valueAccessor?.writeValue(value);
  }
}
