import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/operators';
import { ToastService } from '../shared/services/toast.service';
import { ToastPanelType } from '../config/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private toastService: ToastService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        const tokenReq = request.clone({
          setHeaders: { Authorization: `Bearer ${token}`}
        });
        return next.handle(tokenReq);
      }),

      retry(1),

      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) { // client-side error
          console.error(`Error: ${error.error.message}`);

        } else { // server-side error
          console.error(`Error status: ${error.status} - ${error.name}\n  Message: ${error.message}`);
          
          if (error.message === "Login required") {
            this.auth.login();
            return;
          }

          if (error.status === 401) { // 401 Unauthorized
            const url = `${location.pathname}${location.search}${location.hash}`;
            this.toastService.openToast('Unauthorized! Redirect to login.', ToastPanelType.error);
            setTimeout(() => this.auth.login(url), 3000);
            return;
          }
          if (error.status === 400) { // 400 BadRequest
            console.log(`Error status: ${error.status} - ${error.error} `);
            return throwError(error);
          }
        }

        return throwError(error);
      }),
    );
  }
}
