import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import * as constants from '../../config/app-constants';
import { AuthGuard } from '../../auth/auth.guard';
import { ConsumableOrderConfigComponent } from './components/consumable-order-config/consumable-order-config.component';
import { ConsumableOrderModifyComponent } from './components/consumable-order-modify/consumable-order-modify.component';

const routes: Routes = [
  {
    path: constants.routePaths.ORDER_SETUP,
    component: ConsumableOrderConfigComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: constants.routePaths.ORDER_MODIFY,
    component: ConsumableOrderModifyComponent,
    canActivate: [ AuthGuard ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule],
  exports: [RouterModule],
})
export class OrderRoutingModule { }
