import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getWidgetList, getSelectedWidgetsList } from 'src/app/store/selectors/pharmacy-peek-widgets.selector';
import { saveSelectedPharmacyWidgetList } from 'src/app/store/actions/pharmacy-peek-widgets.action';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PharmacyPeekSelectDialogComponent } from '../pharmacy-peek-select-dialog/pharmacy-peek-select-dialog.component';
import { PharmacyPeekService } from '../../../services/pharmacy-peek.service';
import { PharmacyPeekWidgetActions } from 'src/app/store/actions';
import { PharmacyPeekSelectMultipleDialogComponent } from '../pharmacy-peek-select-multiple-dialog/pharmacy-peek-select-multiple-dialog.component';


@Component({
  selector: 'app-pharmacy-peek-widget',
  templateUrl: './pharmacy-peek-widget.component.html',
  styleUrls: ['./pharmacy-peek-widget.component.scss']
})

export class PharmacyPeekWidgetComponent {
  filtered = false;
  widgetList: any[] = [];
  constructor(public store: Store<State>, public dialog: MatDialog, private pharmacyPeekService: PharmacyPeekService,) { }

  ngOnInit(): void {
    this.bindWidget();
  }

  public bindWidget(filterText: string = '') {

    this.widgetList = [];
    this.store.select(getWidgetList).subscribe(res => {
      if (res) {
        this.widgetList = res;
      }
    }
    );

    if (this.widgetList.length == 0) {
      this.pharmacyPeekService.getPharmacyPeekWidget().subscribe(
        (response) => {
          this.store.dispatch(PharmacyPeekWidgetActions.getPharmacyWidgetList({ data: response }));
        },
        (error) => {
          console.log('pharmacypeekwidget error:', error);
        },
      );
    }

    if (filterText != '') {

      this.filtered = true;
      this.widgetList = [...this.widgetList.filter(t =>
        t.displayName.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()))];
    }
  }

  openPharmacyPeekPackagerDowntimeDialog(widget) {

    if (widget.name === 'DispensingRatios') {
      const widgetupdate = {
        widgetId: widget.widgetId,
        name: widget.name,
        displayName: widget.displayName,
        machinePermissions: widget.machinePermissions,
        allSystem: true,
        systemConfig: {
          machineType: 'ATP',
          deviceId: '',
          deviceName: 'All Systems'
        }
      };
      this.store.dispatch(saveSelectedPharmacyWidgetList({ data: widgetupdate }));
    }
    else if (widget.name === 'SystemStatus') {
      const dialogRefMulti = this.dialog.open(PharmacyPeekSelectMultipleDialogComponent,
        {
          backdropClass: ['PharmacyPeekSelectDialog', 'smDialog', 'Multiselectdd'],
          data: { title: widget.displayName, widgetName: widget.name, widget: widget },
        }
      );

      dialogRefMulti.afterClosed().subscribe(result => {
        console.log('Dialog result:', result);

        if (result.add && result.idList.length > 0 && result.nameList.length > 0) {
          const widgetupdate = {
            widgetId: widget.widgetId,
            name: widget.name,
            displayName: widget.displayName,
            machinePermissions: widget.machinePermissions,
            allSystem: result.allSelected,
            systemConfig: {
              machineType: 'ATP',
              deviceId: result.idList.toString(),
              deviceName: result.allSelected === true ? 'All Systems' : result.nameList.toString()
            }
          };

          this.store.dispatch(saveSelectedPharmacyWidgetList({ data: widgetupdate }));
        }


      });
    }
    else {
      const dialogRef = this.dialog.open(PharmacyPeekSelectDialogComponent,
        {
          backdropClass: ['PharmacyPeekSelectDialog', 'smDialog'],
          data: { title: widget.displayName, widget },
        }
      );

      dialogRef.afterClosed().subscribe(result => {
        if (result.add === true && result.selectedSystem) {
          const widgetupdate = {
            widgetId: widget.widgetId,
            name: widget.name,
            displayName: widget.displayName,
            machinePermissions: widget.machinePermissions,
            allSystem: result.selectedSystem.value === 'allSystem' ? true : false,
            systemConfig: {
              machineType: 'ATP',
              deviceId: result.selectedSystem.value === 'allSystem' ? '' : result.selectedSystem.value,
              deviceName: result.selectedSystem.value === 'allSystem' ? 'All Systems' : result.selectedSystem.viewValue
            }
          };
          this.store.dispatch(saveSelectedPharmacyWidgetList({ data: widgetupdate }));
        }
        console.log(`Dialog result: ${result}`);
      });
    }


  }
}
