<div>
    <h1 mat-dialog-title class="title-text-red">
        <mat-icon>
            <img src="{{alertIcon}}" alt="">
        </mat-icon>
        {{alertWizardTitle}}
        <button class="close" (click)="closeDialog(false, 0)"></button>
    </h1>
    <mat-dialog-content class="body-messge">
        <h6 class="flex justify-center content-center items-center">{{alertWizardBodyMessage}}</h6>
        <br>
        <h6 class="flex justify-center content-center items-center" *ngIf="alertWizardBodyMessage2?.length>0 && alertWizardBodyMessage2 !==null">{{alertWizardBodyMessage2}}</h6>
    </mat-dialog-content>
    
    <mat-dialog-actions class="flex justify-end content-end items-end">
     
            <button [disabled]="this.isButtonOneDisabled"  mat-button class="{{cancelButtonColor}} btn-md" (click)="closeDialog(false, 1)">{{cancelButtonText}}</button>
            <button [disabled]="this.isButtonTwoDisabled" mat-button class="{{confirmButtonColor}} btn-md" [mat-dialog-close]="" (click)="closeDialog(true,2)" cdkFocusInitial>{{confirmButtonText}}</button>
  
        
    </mat-dialog-actions>
</div>
