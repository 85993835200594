import { createAction, props } from '@ngrx/store';

export const GET_HOURLY_POUCH_COUNT = '[PP] Get Hourly Pouch Count';
export const GET_HOURLY_VIAL_COUNT = '[PP] Get Hourly Vial Count';
export const GET_HOURLY_POUCH_COUNT_ALLSYSTEMS = '[PP] Get Hourly Pouch Count All Systems';
export const GET_HOURLY_VIAL_COUNT_ALLSYSTEMS = '[PP] Get Hourly Vial Count All Systems';

export const UPDATE_HOURLY_POUCH_COUNT = '[PP] Update Hourly Pouch Count';
export const UPDATE_VIAL_POUCH_COUNT = '[PP] Update Hourly Vial Count';
export const UPDATE_HOURLY_POUCH_COUNT_ALLSYSTEMS = '[PP] Update Hourly Pouch Count All Systems';
export const UPDATE_VIAL_COUNT_ALLSYSTEMS = '[PP] Update Hourly Vial Count All Systems';

export const GET_UPTIME_PACKAGER = '[PP] Get Uptime Packager';
export const GET_DOWNTIME_PACKAGER = '[PP] Get Downtime Packager';
export const GET_UPTIME_PACKAGER_ALLSYSTEMS = '[PP] Get Uptime Packager All Systems';
export const GET_DOWNTIME_PACKAGER_ALLSYSTEMS = '[PP] Get Downtime Packager All Systems';

export const UPDATE_UPTIME_PACKAGER = '[PP] Update Uptime Packager';
export const UPDATE_DOWNTIME_PACKAGER = '[PP] Update Downtime Packager';
export const UPDATE_UPTIME_PACKAGER_ALLSYSTEMS = '[PP] Update Uptime Packager All Systems';
export const UPDATE_DOWNTIME_PACKAGER_ALLSYSTEMS = '[PP] Update Downtime Packager All Systems';


export const GET_DISPENSE_RATIO = '[PP] Get Dispense Ratio';
export const UPDATE_DISPENSE_RATIO = '[PP] Update Dispense Ratio';

export const GET_SYSTEM_STATUS_DEVICE = '[PP] Get System Status Device';
export const GET_SYSTEM_STATUS_ALLSYSTEMS = '[PP] Get System Status All Systems';
export const UPDATE_SYSTEM_STATUS_ALLSYSTEMS = '[PP] Update System Status All Systems';
export const UPDATE_SYSTEM_STATUS_DEVICE = '[PP] Update System Status Device';



export const getHourlyPouchCountDevice = createAction(
  GET_HOURLY_POUCH_COUNT,
  props<{data: any}>()
);

export const getHourlyVialCountDevice = createAction(
  GET_HOURLY_VIAL_COUNT,
  props<{data: any}>()
);

export const getHourlyPouchCountAllSystem = createAction(
  GET_HOURLY_POUCH_COUNT_ALLSYSTEMS,
  props<{data: any}>()
);

export const getHourlyVialCountAllSystem = createAction(
  GET_HOURLY_VIAL_COUNT_ALLSYSTEMS,
  props<{data: any}>()
);


export const updateHourlyPouchCountDevice = createAction(
  UPDATE_HOURLY_POUCH_COUNT,
  props<{data: any}>()
);

export const updateHourlyVialCountDevice = createAction(
  UPDATE_VIAL_POUCH_COUNT,
  props<{data: any}>()
);

export const updateHourlyPouchCountAllSystem = createAction(
  UPDATE_HOURLY_POUCH_COUNT_ALLSYSTEMS,
  props<{data: any}>()
);

export const updateHourlyVialCountAllSystem = createAction(
  UPDATE_VIAL_COUNT_ALLSYSTEMS,
  props<{data: any}>()
);


export const getUptimePackagerDevice = createAction(
  GET_UPTIME_PACKAGER,
  props<{data: any}>()
);

export const getDowntimePackagerDevice = createAction(
  GET_DOWNTIME_PACKAGER,
  props<{data: any}>()
);


export const getUptimePackagerAllSystem = createAction(
  GET_UPTIME_PACKAGER_ALLSYSTEMS,
  props<{data: any}>()
);

export const getDowntimePackagerAllSystem = createAction(
  GET_DOWNTIME_PACKAGER_ALLSYSTEMS,
  props<{data: any}>()
);


export const updateUptimePackagerDevice = createAction(
  UPDATE_UPTIME_PACKAGER,
  props<{data: any}>()
);

export const updateDowntimePackagerDevice = createAction(
  UPDATE_DOWNTIME_PACKAGER,
  props<{data: any}>()
);

export const updateUptimePackagerAllSystem = createAction(
  UPDATE_UPTIME_PACKAGER_ALLSYSTEMS,
  props<{data: any}>()
);

export const updateDowntimePackagerAllSystem = createAction(
  UPDATE_DOWNTIME_PACKAGER_ALLSYSTEMS,
  props<{data: any}>()
);


export const getDispenseRatio = createAction(
  GET_DISPENSE_RATIO,
  props<{data: any}>()
);

export const updateDispenseRatio = createAction(
  UPDATE_DISPENSE_RATIO,
  props<{data: any}>()
);

export const getSystemStatusDevice = createAction(
  GET_SYSTEM_STATUS_DEVICE,
  props<{data: any}>()
);

export const getSystemStatusAllSystem = createAction(
  GET_SYSTEM_STATUS_ALLSYSTEMS,
  props<{data: any}>()
);

export const updateSystemStatusDevice = createAction(
  UPDATE_SYSTEM_STATUS_DEVICE,
  props<{data: any}>()
);

export const updateSystemStatusAllSystem = createAction(
  UPDATE_SYSTEM_STATUS_ALLSYSTEMS,
  props<{data: any}>()
);