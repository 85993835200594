<h6 class="flex justify-between content-center items-center flex-row">
  <span *ngIf="!deviceName" [matTooltipDisabled]="deviceId?.length<10" matTooltip="{{deviceId  }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
    {{ deviceId | truncate:10 }} - Hourly Vial Count</span>
  <span *ngIf="deviceName" [matTooltipDisabled]="deviceName?.length<10" matTooltip="{{deviceName  }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
    {{ deviceName | truncate:10  }} - Hourly Vial Count</span>
  <a routerLink="/reports/c6c66beb-c05b-494c-8c35-71f4a38650b1" class="btn-link" aria-label="Report">Report ></a>
</h6>
<span id="avgHoursTodayHidden" class="hiddenSpan"> {{avgHoursTodayHidden}}</span>
<span id="hourCountHidden" class="hiddenSpan"> {{hourCountHidden}}</span>

<div *ngIf="!noDataExistsForHPC">
  <p class="pp_report pp_report_hc">
    <span class="rcount count">{{ totalToday }}</span>
    <span class="rcountdesc count1">
      Today's Total
    </span>

    <span class="count2 ">
      <span class="count">{{ avgHoursToday }}</span>
      {{todayDay}}
      <custom-tooltip text="Averaged against 6 months of Day of the Week"></custom-tooltip>
    </span>

  </p>

  <app-pouch-count-chart [avgHours]="avgHoursTodayHidden" [data]="pouchCountDataToday || []"></app-pouch-count-chart>
</div>

<div *ngIf="noDataExistsForHPC">
  <p class="pp_report">
    <span class="rcountdesc">No data exists</span>
  </p>
</div>

<hr>