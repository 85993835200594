import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../auth/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import * as constants from '../../config/app-constants';

const routes: Routes = [
  {
    path: constants.routePaths.STRATUS_SETUP,
    loadChildren: () => import('../../common/customer-survey/customer-survey.module').then(m => m.CustomerSurveyModule),
    canActivate: [AuthGuard],
  },
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class PharmacyPeekRoutingModule { }
