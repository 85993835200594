import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InventoryRoutingModule } from './inventory-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatSortModule } from '@angular/material/sort';
import { InventoryViewsComponent } from './components/inventory-child/inventory-views/inventory-views.component';
import { InventoryViewsMobileTableComponent } from './components/inventory-child/inventory-views-mobile-table/inventory-views-mobile-table.component';
import { InventoryViewsTableRptComponent } from './components/inventory-child/inventory-views-table-rpt/inventory-views-table-rpt.component';
import { InventoryMasterComponent } from './components/inventory-master/inventory-master.component';

@NgModule({
    declarations: [
        InventoryViewsComponent,
        InventoryViewsMobileTableComponent,
        InventoryViewsTableRptComponent,
        InventoryMasterComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        InventoryRoutingModule,
        SharedModule,
        ScrollingModule,
        CdkTableModule,
        MatCheckboxModule,
        MatSortModule,
        MatTableModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
    ],
    providers: [DatePipe]
})
export class InventoryModule { }
