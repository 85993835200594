import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HourlyPouchCountData, PouchCountData } from '../models/pouch-count-data';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class PharmacyPeekService extends DataService<any>{

  collapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setCollapsed(value: boolean) {
    this.collapsed.next(value);
  }

  constructor(http: HttpClient) {
    super(environment.apiUrl.configurationService, http);
  }

  public getPharmacyPeekWidget(): Observable<any> {
    return this.http.get<Observable<any>>(this.url+ 'pharmacypeekwidget');
  }

  public getUserConfiguration(): Observable<any> {
    return this.http.get<Observable<any>>(this.url+ 'userconfiguration/GetUserPPWidgetConfig');
  }

  public saveUserConfiguration(resource: any): Observable<any> {
    return this.http.post(this.url+ 'userconfiguration/SavePPWidgetConfig', resource);
  }

}
