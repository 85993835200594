import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, from, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MachineData, ScriptManagerData, SiteData, VialData } from '../models/script-manager-data';

@Injectable({
  providedIn: 'root',
})
export class ParataStandardInterfaceService extends DataService<ScriptManagerData> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.parataStandardInterfaceService}`, http);
    this.httpClient = http;
  }

  getPendingScripts(organizationID, machineTypeId): Observable<Array<ScriptManagerData>> {
    const path = this.url + '/Script/' + 'GetPendingScripts/' + organizationID + '/' + machineTypeId;
    return from(this.http.get<Array<ScriptManagerData>>(path)
      .pipe(map((data: Array<ScriptManagerData>) => data.map(pending => pending
      )
      ),
        catchError(err => {
          console.error(err);
          return empty();
        })
      )
    );
  }

  getInProcessScripts(organizationID, machineTypeId): Observable<Array<ScriptManagerData>> {
    const path = this.url + '/Script/' + 'GetInProcessScripts/' + organizationID + '/' + machineTypeId;
    return from(this.http.get<Array<ScriptManagerData>>(path)
      .pipe(map((data: Array<ScriptManagerData>) => data.map(inProcess => inProcess
      )

      ),
        catchError(err => {
          console.error(err);
          return empty();
        })
      )
    );
  }

  getIncompleteScripts(organizationID, machineTypeId): Observable<Array<ScriptManagerData>> {
    const path = this.url + '/Script/' + 'GetIncompleteScripts/' + organizationID + '/' + machineTypeId;
    return from(this.http.get<Array<ScriptManagerData>>(path)
      .pipe(map((data: Array<ScriptManagerData>) => data.map(inProcess => inProcess
      )

      ),
        catchError(err => {
          console.error(err);
          return empty();
        })
      )
    );
  }

  getCompleteScripts(organizationID, machineTypeId): Observable<Array<ScriptManagerData>> {
    const path = this.url + '/Script/' + 'GetCompletedScripts/' + organizationID + '/' + machineTypeId;
    return from(this.http.get<Array<ScriptManagerData>>(path)
      .pipe(map((data: Array<ScriptManagerData>) => data.map(inProcess => inProcess
      )

      ),
        catchError(err => {
          console.error(err);
          return empty();
        })
      )
    );
  }


  getScript(organizationID, machineTypeId, scriptId): Observable<ScriptManagerData> {
    const path = this.url + '/Script/' + 'GetScript/' + organizationID + '/' + machineTypeId + '/' + scriptId;
    return from(this.http.get<ScriptManagerData>(path)
    );
  }
  setPriority(organizationID, transactionID, priority) {
    const path = this.url + '/Script/' + `ChangeUrgency/${organizationID}/${transactionID}/${priority}`;
    this.http.patch(path.trim(), '').subscribe(
      res => res
    );
  }


  cancelScript(organizationID, scriptID) {
    const path = this.url + '/Script/' + `CancelScript/${organizationID}/${scriptID}`;
    this.http.delete(path.trim()).subscribe(
      res => res
    );
  }

  cancelIncompleteScript(scriptID, organizationID, transactionID) {
    const path = this.url + '/Script/' + `CancelIncompleteScript/${organizationID}/${scriptID}/${transactionID}`;
    this.http.delete(path.trim()).subscribe(
      res => res
    );
  }
  getVialDetail(organizationID, transactionID): Observable<Array<VialData>> {
    const path = this.url + '/Script/' + `GetPrescriptionDetails/${organizationID}/${transactionID}`;
    return from(this.http.get<Array<VialData>>(path)
      .pipe(map((data: Array<VialData>) => data.map(vials => vials
      )

      ),
        catchError(err => {
          console.error(err);
          return empty();
        })
      )
    );
  }

  public getSite(siteid: string): Observable<Array<SiteData>> {
    return this.http.get<Array<SiteData>>(this.url + '/GetSite/' + siteid);
  }

  public getAllMachines(siteid: string): Observable<Array<MachineData>> {
    return this.http.get<Array<MachineData>>(this.url + '/Machine/GetAllMachines/' + siteid);
  }

  public createSite(resource: SiteData): Observable<Array<SiteData>> {
    return this.http.post<Array<SiteData>>(this.url + '/CreateSite', resource);
  }

  public createMachine(siteid: string, resource: MachineData): Observable<Array<MachineData>> {
    return this.http.post<Array<MachineData>>(this.url + '/Machine/CreateMachine/' + siteid, resource);
  }

  public deleteMachine(siteid: string, deviceId: string) {
    return this.http.delete(this.url + '/Machine/DeleteMachine/' + siteid + '/' + deviceId);
  }

  public moveParataBatch(parataBatchId: string, deviceId: string) {
    return this.http.post(this.url + '/Batch/' + parataBatchId + '/move/' + deviceId, '');
  }

  public getCompletedBatchesSite(): Observable<any> {
    return this.http.get<Observable<any>>(this.url + '/ParataBatchStatus/CompletedBatches');
  }
}
