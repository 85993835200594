import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Asset, Batch, BatchListType, RunModes } from '../../../models/batch-models';
import { TenantService } from '../../../services/tenant.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ParataStandardInterfaceService } from '../../../services/parataStandardInterface.service';
import QueueData, { ScriptManagerData, VialData } from '../../../models/script-manager-data';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { ScriptsActions } from 'src/app/store/actions';
import { getVialScripts } from 'src/app/store/selectors/scripts.selector';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getAuthProfile, getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { SiteService } from 'src/app/external-user/inventory/services/site.service';
import { ToastPanelType } from 'src/app/config/app-constants';
import { PsisMachineType } from '../../../services/psis-enum-mapping';
import { deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';

export enum View {
  ORG,
  SITE
}

@Component({
  selector: 'app-vial-scripting',
  templateUrl: './vial-scripting.component.html',
  styleUrls: ['./vial-scripting.component.scss'],
})
export class VialScriptingComponent implements OnInit, OnDestroy {
  @Input() sites: any;
  @Input() organizationId: any;
  @Input() parentOrgId: any;
  @Input() parentOrgName: any;

  View = View;

  BatchListType = BatchListType;
  RunModes = RunModes;
  runMode: string;
  batches: Batch[];
  assets: Asset[];
  completeCount: number;

  queueDataMax: QueueData[] = [];

  destroyed$ = new Subject();
  countDown: Subscription;

  Menu_Vial_ScriptManagement: boolean = false;
  flags: any;
  selectedUser: any;

  machineTypeATP = false;
  machineTypeMAX = false;

  selectedSite: any;

  signalrSubList = {
    type: SignalEventType.MAX_VIAL_UPDATER,
  }

  constructor(
    public tenantService: TenantService,
    public dialog: MatDialog,
    public toastService: ToastService,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    private ngxLoader: NgxUiLoaderService,
    private siteService: SiteService,
    public store: Store<State>,
    private signal: SignalRService,
  ) {

    this.runMode = RunModes.AUTORUN;

    this.store.select(getVialScripts).subscribe(res => {
      if (res == null) { return; }
      this.queueDataMax = Object.values(res);
    });

  }

  get view() {
    if (this.selectedSite && this.selectedSite.customerNo) {
      return View.SITE;
    }
    return View.ORG;
  }


  ngOnInit() {
    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.flags = flags;
        this.Menu_Vial_ScriptManagement = flags?.Menu_Vial_ScriptManagement;
      }
    }
    );

    this.store.select(getAuthProfile).subscribe((profile) => {
      if (profile) {
        this.parentOrgId = profile.parentOrganizationId;
        this.parentOrgName = profile.parentOrganization;
        this.organizationId = profile.organizationId;
        this.loadSites();
      }
    });

    this.setSignalRUpdater();
  }

  setSignalRUpdater(){
    
    this.signal.getDataStream<any>(SignalEventType.MAX_VIAL_UPDATER)
      .pipe()
      .subscribe((dataObj) => {
        const script = dataObj?.data?.data?.vialUpdate;
        const newData: VialData = {
          maxScripId: script.MaxScriptId,
          rxid: script.RxId,
          // dispenseQuantity: script.BatchQuantity,
          status: script.EventType,
          vialNumber: script.vialNumber
        }
        
        let newArr = [];
        newArr = this.setDataFormat();
        let scriptToFind: ScriptManagerData;
        let oldQueue = '';
        this.queueDataMax.map(x => x?.inventories.map(y => {
          if (y?.scriptID === newData?.rxid) {
            scriptToFind = y;
            oldQueue = x.name;
          }
          else
            {
              newArr.find(n => n.name === x.name)?.inventories.push(y);
            }
        }));

        if(scriptToFind)
        {
          // eslint-disable-next-line max-len
          const vial = scriptToFind?.vials.find(v => v.rxid === newData.rxid && v.maxScripId === newData.maxScripId && v.vialNumber === newData.vialNumber);
          if (vial) {
            vial.status = newData.status;
          }
          else {
            scriptToFind?.vials.push(newData);
          }
          
          const status = this.setStatus(scriptToFind.vials);
          scriptToFind.status = status;
          this.setQueue(scriptToFind, newArr, status);
          // eslint-disable-next-line max-len
          this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: newArr.find(x=>x.name === oldQueue), type: oldQueue } }));
        }
        else{

          this.parataStandardInterfaceService.getScript(this.organizationId, PsisMachineType.MAX, newData.maxScripId)
          .subscribe((script: ScriptManagerData) => {
            if (script?.vials.find(x => x.maxScripId === newData.maxScripId) === null) {
              script?.vials.push(newData);
            }
            const status = this.setStatus(script.vials);
            script.status = status;
            this.setQueue(script, newArr, status);
            this.ngxLoader.stop();
          }, () => {
            this.ngxLoader.stop();
          });
        }

        console.log(dataObj);
      });
  }
  setDataFormat(): Array<QueueData> {
    const queueData = [];
    queueData.push({ name: 'INCOMPLETE', isExpanded: true, inventories: [] });
    queueData.push({ name: 'IN PROCESS', isExpanded: true, inventories: [] });
    queueData.push({ name: 'IN QUEUE', isExpanded: true, inventories: [] });
    queueData.push({ name: 'COMPLETE', isExpanded: true, inventories: [] });
    return queueData;
  }

  setQueue(script: ScriptManagerData, data: Array<QueueData>, status: string) {
      if(status === 'Incomplete'){
        const queue = data.find(x=>x.name ==='INCOMPLETE').inventories;
        queue.push(script);
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: queue, type: 'INCOMPLETE' } }));
      }
      if(status === 'OrderPending'){
        const queue = data.find(x=>x.name ==='IN QUEUE').inventories;
        queue.push(script);
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: queue , type: 'IN QUEUE' } }));
      }
      if(status === 'OrderFilling'){
        const queue = data.find(x=>x.name ==='IN PROCESS').inventories;
        queue.push(script);
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: queue , type: 'IN PROCESS' } }));
      }
      if(status === 'OrderReady' || status === 'OrderCompleted') {
        const queue = data.find(x=>x.name ==='COMPLETE').inventories;
        queue.push(script);
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: queue, type: 'COMPLETE' } }));
      }
  }

  setStatus(orders: VialData[]) {
    if (orders == null || orders.length === 0)
      {return '';}
    let min = 0;
    let max = 99999;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (order.status === 'OrderIncomplete') {
        return 'Incomplete';
      }
      if (order.status === 'OrderPending') {
        if (0 < min)
          {min = 0;}
        if (0 > max)
          {max = 0;}
      }
      if (order.status === 'OrderFilling') {
        if (2 < min)
          {min = 2;}
        if (2 > max)
          {max = 2;}
      }
      if (order.status === 'OrderReady') {
        if (3 < min)
          {min = 3;}
        if (3 > max)
          {max = 3;}
      }
      if (order.status === 'OrderCompleted') {
        if (4 < min)
          {min = 4;}
        if (4 > max)
          {max = 4;}
      }
    }

    if (max >= 2)
      {return 'Started';}
    if (max == 0 || max == 1)
      {return 'Pending';}
    if (min == 3 || min == 4)
      {return 'Completed';}

    return 'Unknown';
  }


  loadSites() {
    const ngxLoaderKey = 'loadSites';
    this.ngxLoader.start(ngxLoaderKey);
    this.siteService.getSitesForParentOrg(this.parentOrgId)
      .subscribe((sites) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.sites = sites;
        this.selectedSite = this.sites.find(site => site.organizationId === this.organizationId);
        this.parataStandardInterfaceService.getAllMachines(this.organizationId).subscribe((response) => {
          let resData = response;
          const pouch = resData.filter(x => x.machineType === PsisMachineType.POUCH);
          const max = resData.filter(x => x.machineType === PsisMachineType.MAX);

          if (pouch.length > 0) {
            this.machineTypeATP = true;
          }

          if (max.length > 0) {
            this.machineTypeMAX = true;
            this.getPendingScripts(PsisMachineType.MAX);
            this.getInProcessScripts(PsisMachineType.MAX);
            this.getIncompleteScripts(PsisMachineType.MAX);
            this.getCompleteScripts(PsisMachineType.MAX);
          }

        }, (error) => {
          if (error.status === 404) {
            this.toastService.openToast('PSIS getAllMachines No Data Exists!', ToastPanelType.warning);
            } else {
            this.toastService.openToast('PSIS getAllMachines Service Load Error!', ToastPanelType.error);
          }
        }
        );

      }, (error) => {
        this.toastService.stopLoadingAndShowError(error,
          'Error while loading sites for parent org!', ngxLoaderKey);
      });
  }


  setInterval(machineTypeId) {
    this.countDown = interval(3000).pipe(
      takeUntil(this.destroyed$)).subscribe(() => {
        this.getPendingScripts(machineTypeId);
        this.getInProcessScripts(machineTypeId);
        this.getIncompleteScripts(machineTypeId);
        this.getCompleteScripts(machineTypeId);
      });
  }

  cancelInterval() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    if (this.countDown && !this.countDown.closed) {
      this.countDown.unsubscribe();
    }
  }

  filterInventory(value: string) {
    this.cancelInterval();
    let queueData = [];
    if (value === '') {
      this.queueDataMax = [];
      this.store.dispatch(ScriptsActions.setScriptData()); return;
    }

    queueData = this.queueDataMax;

    const filter = queueData.flatMap(x => {
      if (x.inventories != null) {
        const filterObj = x.inventories?.filter(f =>
          f.patientFirstName.toLowerCase().includes(value.toLowerCase()) ||
          f.patientLastName.toLocaleLowerCase().includes(value.toLowerCase()) ||
          f.drugCode.toLocaleLowerCase().includes(value.toLowerCase()) ||
          f.drugName.toLocaleLowerCase().includes(value.toLowerCase()) ||
          f.rxNumber.toLocaleLowerCase().includes(value.toLowerCase())
        );
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: filterObj, type: x.name } }));;
      }
      else {
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: [], type: x.name } }));;
      }

    }
    );
  }

  getPendingScripts(machineTypeId) {
    this.parataStandardInterfaceService.getPendingScripts(this.organizationId, machineTypeId)
      .subscribe((list: Array<ScriptManagerData>) => {
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: list, type: 'IN QUEUE' } }));
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
  }

  getInProcessScripts(machineTypeId) {
    this.parataStandardInterfaceService.getInProcessScripts(this.organizationId, machineTypeId)
      .subscribe((list: Array<ScriptManagerData>) => {
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: list, type: 'IN PROCESS' } }));
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
  }

  getIncompleteScripts(machineTypeId) {
    this.parataStandardInterfaceService.getIncompleteScripts(this.organizationId, machineTypeId)
      .subscribe((list: Array<ScriptManagerData>) => {
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: list, type: 'INCOMPLETE' } }));
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
  }

  getCompleteScripts(machineTypeId) {
    this.parataStandardInterfaceService.getCompleteScripts(this.organizationId, machineTypeId)
      .subscribe((list: Array<ScriptManagerData>) => {
        this.store.dispatch(ScriptsActions.updateInventoryMaxData({ data: { inventory: list, type: 'COMPLETE' } }));
        this.completeCount = list.length;
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
  }

  ngOnDestroy() {
    this.cancelInterval();
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
  }

}

