import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Component, Input, OnInit } from '@angular/core';
import { Drug } from '../../models/drug';
import { RpoToolService } from '../../services/rpo-tool.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as constants from 'src/app/config/app-constants';
import { ImportWizardSearchDrugComponent } from '../import-wizard-search-drug/import-wizard-search-drug.component';
import { routePaths } from 'src/app/config/app-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-drug-list',
  templateUrl: './search-drug-list.component.html',
  styleUrls: ['./search-drug-list.component.scss']
})
export class SearchDrugListComponent implements OnInit {
  displayedColumns = ['name', 'ndc', 'countryCode','source', 'system', 'strength', 'manufacturer', 'priority', 'requestor', 'age','action'];
  selectedRow:any;
  dataSource= new MatTableDataSource<Drug>();
  @Input() dataSourceId:number = 0;
  @Input() searchText: string;
  @Input()  permanentQuarantine = false;
  @Input() countryId:number = 0; 
    //#region Pagination properties
    totalRows: number = 0;
    currentPage: number = 1;
    pageSize: number = 5;
    pageSizeOptions: number[] = [5, 10, 20, 40, 50];
    //#endregion

  constructor(
    private rpoService: RpoToolService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getDrugData();
  }

  handleInputChange(dataSourceId:number,searchText:any, countryId:number, permanentQuarantine:boolean){
    this.dataSourceId=dataSourceId;
    this.searchText = searchText;   
    this.countryId = countryId;
    this.permanentQuarantine = permanentQuarantine
    if(searchText.length>0){
      
    }
    else{
      this.dataSource = new MatTableDataSource<Drug>(); 
    }
      
   
}
redirectToDetailsPage(rowId:any,dataSourceId:number){
  this.route.navigate([`/${routePaths.DRUG_MANAGEMENT}/drug-details`, rowId,dataSourceId]);
}
openImportWizardSearchDrug() {

  const dialogRef = this.dialog.open(ImportWizardSearchDrugComponent, {
    backdropClass: ['lgDialog'],
    disableClose: true,
    data: {
      dataSourceId:this.dataSourceId,
      searchText: this.searchText,
      countryId:this.countryId
    }
  });

}
  getDrugData(){
    if(this.searchText.length===0){
      return;
   }
    let searchKey = 'searchKey'
    this.ngxLoader.start(searchKey);
    this.rpoService.getSearchDrugData(
      this.dataSourceId,
      this.searchText,
      this.countryId,
      this.currentPage,
      this.pageSize,
    
    ).subscribe((result)=>{
   
      this.dataSource.data = result.data.drugs;
      this.currentPage = result.data.currentPage
      this.totalRows = result.data.totalRows
      this.ngxLoader.stop(searchKey);
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop(searchKey);
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }


}
