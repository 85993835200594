import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StratusSetupRoutingModule } from './stratus-setup-routing.module';
import { StratusSetupDialogComponent, DialogEntryComponent } from './components/stratus-setup-dialog/stratus-setup-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModules } from '../../common/common.module';

@NgModule({
    declarations: [StratusSetupDialogComponent, DialogEntryComponent],
    imports: [
        CommonModules,
        StratusSetupRoutingModule,
        SharedModule,
    ]
})
export class StratusSetupModule { }
