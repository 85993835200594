import { createAction, props } from '@ngrx/store';

export const GET_PHARMACY_WIDGET_LIST = '[PPW] Get Pharmacy Widget List';
export const GET_SELECTED_PHARMACY_WIDGET_LIST = '[PPW] Get Selected Pharmacy Widget List';
export const SAVE_SELECTED_PHARMACY_WIDGET_LIST = '[PPW] Save Selected Pharmacy Widget List';
export const REORDER_SELECTED_PHARMACY_WIDGET_LIST = '[PPW] Reorder Selected Pharmacy Widget List';

export const UPDATE_SELECTED_PHARMACY_WIDGET_LIST = '[PPW] Update Selected Pharmacy Widget List';


export const getPharmacyWidgetList = createAction(
  GET_PHARMACY_WIDGET_LIST,
  props<{data: any}>()
);

export const getSelectedPharmacyWidgetList = createAction(
  GET_SELECTED_PHARMACY_WIDGET_LIST,
  props<{data: any}>()
);

export const updateSelectedPharmacyWidgetList = createAction(
  UPDATE_SELECTED_PHARMACY_WIDGET_LIST,
  props<{data: any}>()
);

export const saveSelectedPharmacyWidgetList = createAction(
  SAVE_SELECTED_PHARMACY_WIDGET_LIST,
  props<{data: any}>()
);

export const reorderSelectedPharmacyWidgetList = createAction(
  REORDER_SELECTED_PHARMACY_WIDGET_LIST,
  props<{data: any}>()
);
