import { routePaths } from './app-constants';

export interface Route {
  title: string;
  routerLink: string;
  menuId: string,
}

export const routes: Route[] = [{
    title: 'Edit Customer',
    routerLink: routePaths.EDIT_CUSTOMER,
    menuId: 'MnuCustomerManagement',
  },
  {
    title: 'Onboarding',
    routerLink: `${routePaths.EDIT_CUSTOMER}/onboarding`,
    menuId: 'MnuOnboarding',
  },
  {
    title: 'Permission Groups',
    routerLink: `${routePaths.EDIT_CUSTOMER}/permission-groups`,
    menuId: 'MnuPermissionGroup',
  },
  {
    title: 'User Mgmt & Permissions',
    routerLink: `${routePaths.EDIT_CUSTOMER}/user-mgmt-permissions`,
    menuId: 'MnuUserManagement',
  },
  {
    title: 'Consumables Subscription',
    routerLink: `${routePaths.EDIT_CUSTOMER}/consumables-subscription`,
    menuId: 'MnuConsumableSubscription',
  },
  {
    title: 'Reports',
    routerLink: routePaths.REPORTS,
    menuId: 'Mnureportsubscription',
  },
  {
    title: 'PM Tool Pass',
    routerLink: `${routePaths.EXPORT_PASS_IM_TOOL}`,
    menuId: 'MnuImTool',
  },
  {
    title: 'PM Tool ATP',
    routerLink: `${routePaths.EXPORT_ATP_IM_TOOL}`,
    menuId: 'MnuImTool',
  },
  {
    title: 'PM Tool Mini',
    routerLink: `${routePaths.EXPORT_MINI_IM_TOOL}`,
    menuId: 'MnuImTool',
  },
  {
    title: 'PM Tool Max',
    routerLink: `${routePaths.EXPORT_IM_TOOL}`,
    menuId: 'MnuImTool',
  },
  {
    title: 'PM Tool Customer Drug',
    routerLink: `${routePaths.IM_TOOL}`,
    menuId: 'MnuImTool',
  },
  {
   title: 'PM Tool Synmed',
   routerLink: `${routePaths.EXPORT_SYNMED_IM_TOOL}`,
   menuId: 'MnuImTool',
  }];
