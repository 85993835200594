import { DropDown } from "./dropdown";

export class DropdownValues {
    static PreferredPipetteSize: string[] = ['Small', 'Large'];
    static PreferredContainerSize: string[] = ['Small', 'Large', 'Both'];
    static MassIndex:number[] = [0, 1, 2, 3]
    static SmallPipetteDropHeight: number[] = [0, 1, 2, 3, 4];
    static LargePipetteDropHeight: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    static PreferredLidHoleSize: number[] = [0, 52, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 125, 130, 140, 150, 160, 170];
    static LidHoleSize: number[] = [52, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 125, 130, 140, 150, 160, 170, 999];
    static  CommonBooleanDropDownValues: DropDown[] = [
        { value: true, label: 'Yes', description: undefined },
        { value: false, label: 'No', description: undefined }
      ];
  }