import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Drug } from '../../models/drug';
import { RpoToolService } from '../../services/rpo-tool.service';
import * as constants from 'src/app/config/app-constants';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { routePaths } from 'src/app/config/app-constants';
import { Router } from '@angular/router';
import { DropDown } from '../../models/dropdown';
import { DropdownValues } from '../../models/synmed-data';
@Component({
  selector: 'app-import-wizard-quarantined-drug-list',
  templateUrl: './import-wizard-quarantined-drug-list.component.html',
  styleUrls: ['./import-wizard-quarantined-drug-list.component.scss']
})
export class ImportWizardQuarantinedDrugListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = ['name', 'ndc', 'countryCode','source','system', 'strength', 'manufacturer', 'priority', 'requestor', 'age','action'];
  selectedRow:any;
  searchText:string;
  permanentQuarantine:boolean;
  listOfCountry :DropDown[] =[];
  listOfDataSourceFilter :DropDown[] =[];
  countryId:number= 0;
  dataSourceId:number= 0;
  permanentQuarantineList:DropDown[]= DropdownValues.CommonBooleanDropDownValues
  dataSource= new MatTableDataSource<Drug>();
     //#region Pagination properties
     totalRows: number = 0;
     currentPage: number = 1;
     pageSize: number = 10;
     pageSizeOptions: number[] = [50, 100, 200, 500];
     //#endregion
  constructor(
    public dialogRef: MatDialogRef<ImportWizardQuarantinedDrugListComponent>,
    public dialog: MatDialog,
    private rpoService:RpoToolService,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dataSourceId = data?.dataSourceId;
      this.searchText = data?.searchText;
      this.countryId = data?.countryId;      
      this.permanentQuarantine = data?.permanentQuarantine;
     }

  ngOnInit(): void {
    this.getDrugData();
    this.getCountry();
    this.getDataSourceFilter();
  }
  search(){
    this.getDrugData();
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getDrugData();
  }
  redirectToDetailsPage(rowId:any,dataSourceId:number){
    this.route.navigate([`/${routePaths.DRUG_MANAGEMENT}/drug-details`, rowId,dataSourceId]);
    this.dialogRef.close();
  }
  getDrugData(){   
    let quarantinedKey = 'QuarantinedKey'
    this.ngxLoader.start(quarantinedKey);
    this.rpoService.getQuarantinedDrugata(
      
      this.dataSourceId,
      this.searchText,
      this.permanentQuarantine,
      this.countryId,
      this.currentPage == 0 ? 1 : this.currentPage,
       this.pageSize
    ).subscribe((response)=>{
      let result = response.data;
      this.ngxLoader.stop(quarantinedKey);
     this.dataSource.data = result.drugs
     setTimeout(() => {
      this.paginator.pageIndex = this.currentPage;
      this.paginator.length = result.totalRows;
    });
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop(quarantinedKey);
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }
  getCountry(){
    this.ngxLoader.start();
    this.rpoService.getCountry().subscribe((result)=>{
      this.ngxLoader.stop();
      this.listOfCountry = result.data;
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }
  getDataSourceFilter(){
    this.ngxLoader.start();
    this.rpoService.getDataSourceFilter().subscribe((result)=>{
      this.ngxLoader.stop();
      this.listOfDataSourceFilter = result.data;
    },
    (error: HttpErrorResponse) => {
      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

}
