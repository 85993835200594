import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeletePermissionGroupDialogComponent } from 'src/app/common/permission-groups/components/delete-permission-group-dialog/delete-permission-group-dialog.component';

@Component({
  selector: 'app-permission-groups-dialog',
  templateUrl: './permission-groups-dialog.component.html',
  styleUrls: ['./permission-groups-dialog.component.scss']
})
export class PermissionGroupsDialogComponent implements OnInit {
  clickAddGroup:Subject<any> = new Subject();
  refreshPerGroup:Subject<any> = new Subject();

  paramParentOrgId: string;
  selectedPerGroup:any;
  disableAddNew:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<PermissionGroupsDialogComponent>,
    public dialog: MatDialog,public auth: AuthService) { }
  ngOnInit() {
      this.auth.profile$.subscribe((profile) => {
        this.paramParentOrgId = profile.parentOrganizationId;
      }); 
  }
  addNewGroup() {
    this.clickAddGroup.next(1);
  }

  closePopup(val: any='') {   
      this.dialogRef.close(val);  
  }
  disableDelete(value: any) {
    console.log(value);
    this.selectedPerGroup = value;
  }
  disableAddNewButton(value: any) {
    console.log(value);
    this.disableAddNew = value;
  }

  
  deletePermissionGroups(isDefault) {
    if (isDefault)
      return;

    if (this.auth.loggedIn) {
      this.dialog.open(
        DeletePermissionGroupDialogComponent,
        {
          disableClose: true, backdropClass: ['DeletePermissionGrpsDialog', 'smDialog', 'SnackDialog'], data: {
            role: this.selectedPerGroup, parentOrgId: this.paramParentOrgId
          }
        },

      ).afterClosed()
        .subscribe((shouldReload: boolean) => {
          if (shouldReload) 
            this.refreshPerGroup.next(1);
        });
    }
  }
}
