import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNoHtmlTags]'
})
export class NoHtmlTagsDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const sanitizedValue = this.sanitizeInput(inputElement.value);
    inputElement.value = sanitizedValue;
  }

  private sanitizeInput(input: string): string {
    // Use a regular expression to remove or escape HTML and script tags
    const sanitizedValue = input.replace(/<[^>]*>/g, ''); // Remove HTML tags
    return sanitizedValue;
  }
}
