import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { stratify } from 'd3-hierarchy';

@Component({
  selector: 'app-stratus-setup-dialog',
  templateUrl: './stratus-setup-dialog.component.html',
  styleUrls: ['./stratus-setup-dialog.component.scss']
})
export class StratusSetupDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<StratusSetupDialogComponent>,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
  }
}

@Component({
  template: ''
})
export class DialogEntryComponent {
  constructor(public dialog: MatDialog, private router: Router,
    private route: ActivatedRoute) {
      this.route.parent.params.subscribe(({ id }) => {
        this.openDialog();
      });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(StratusSetupDialogComponent, {
      width: '94vw', backdropClass: 'StratusSetupDialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['../../'], { relativeTo: this.route });
    });
  }
}
