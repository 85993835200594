import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from 'src/app/store/state';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FleetManagerActions } from '../actions';
import { FleetManagementService } from 'src/app/internal-user/fleet-management/services/fleet-management.service';
import { EMPTY } from 'rxjs/internal/observable/empty';

@Injectable()
export class FleetManagerEffect {
  constructor(private actions$: Actions,
    private fleetManagementService: FleetManagementService,
    public store: Store<State>,) {}


  FleetManager$ = createEffect(() => this.actions$.pipe(
      ofType(FleetManagerActions.getIOTDeviceDetails),
      mergeMap(() =>
      this.fleetManagementService.getDeviceInventory().pipe
        (
          map((response) => FleetManagerActions.getIOTDeviceDetailsSuccessful({ data: response })),
          catchError(error => {return EMPTY})
        )),
      ));
}
