import { Role } from './role';
import { routes } from '../../../config/route-permission';
import { navItems } from '../../../config/nav';

export class Profile {
  organizationId: string;
  parentOrganizationId: string;
  organizationSfId: string;
  parentOrganizationSfId: string;
  organization: string;
  orgType: string;
  parentOrganization: string;
  userRoles: string[] = [];
  machinePermissions: string[] = [];
  reportPermissions: string[] = [];
  apiPermissions: string[] = [];
  menuPermissions: string[] = [];
  givenName: string;
  familyName: string;
  nickname: string;
  name: string;
  picture: string;
  updatedAt: Date = null;
  email: string;
  emailVerified: boolean;
  sub: string;
  eulaAccepted: boolean;
  
  constructor(profile = null) {
    if(!profile) { return; }

    this.organizationId = profile['https://parata.com/organizationId'] || [];
    this.parentOrganizationId = profile['https://parata.com/parentOrganizationId'] || [];
    this.organizationSfId = profile['https://parata.com/organizationSfId'] || [];
    this.parentOrganizationSfId = profile['https://parata.com/parentOrganizationSfId'] || [];
    this.organization = profile['https://parata.com/organization'] || [];
    this.orgType = profile['https://parata.com/orgType'] || [];
    this.parentOrganization = profile['https://parata.com/parentOrganization'] || [];
    this.userRoles = profile['https://parata.com/userRoles'] || [];
    this.machinePermissions = profile['https://parata.com/machinepermissions'] || [];
    this.reportPermissions = profile['https://parata.com/reportpermissions'] || [];
    this.apiPermissions = profile['https://parata.com/apipermissions'] || [];
    this.menuPermissions = profile['https://parata.com/menupermissions'] || [];
    if (this.menuPermissions.findIndex((element) => element ==='MnuInventory')>-1)
      {
        if (this.machinePermissions.findIndex((element) => element ==='ATP')>-1)
          {
            this.menuPermissions.push('MnuInventoryAtp');
          };
        if (this.machinePermissions.findIndex((element) => element ==='MAX')>-1)
          {
            this.menuPermissions.push('MnuInventoryMax');
          };
        if (this.machinePermissions.findIndex((element) => element ==='PASS')>-1)
          {
            this.menuPermissions.push('MnuInventoryPass');
          };
      }
    this.eulaAccepted = profile['https://parata.com/eulaAccepted'];
    this.givenName = profile.given_name;
    this.familyName = profile.family_name;
    this.nickname = profile.nickname;
    this.name = profile.name;
    this.picture = profile.picture;
    this.updatedAt = new Date(profile.updated_at);
    this.email = profile.email;
    this.emailVerified = profile.email_verified;
    this.sub = profile.sub;
  }

  hasRole(role: string) {
    return this.userRoles.includes(role);
  }

  hasAnyRoleOf(...roles: string[]) {
    return this.userRoles.some(role => roles.includes(role));
  }

  hasMenuPermission(menu: string): boolean {
    const nav = navItems.find(({ link }) => link.replace(/^\//g, '') === menu);
    return nav && this.menuPermissions.includes(nav.menuId);
  }

  hasRouteAccess(menu: string): boolean {
    const routePermission = routes.find(({routerLink}) => routerLink === menu);    
    return this.menuPermissions.includes(routePermission.menuId);
  }
}
