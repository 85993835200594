<mat-list-item matRipple
  *ngIf="!node.hidden"
  title="{{node.label}}"
  [matRippleDisabled]="node.disabled"
>
  <div [id]="node.menuDesktopId">
    <ng-container *ngTemplateOutlet="node.externalRedirect ? redirectLinkTemplate : routerLinkTemplate"></ng-container>
  </div>
</mat-list-item>

<ng-template #redirectLinkTemplate>
  <a attr.aria-label="{{node.label}}" class="anml-link" [href]="node.link" [target]="getHrefTargetType()" role="link" >
    <ng-container *ngTemplateOutlet="isRtlLayout ? linkLabelRtlOutlet : linkLabelLtrOutlet"></ng-container>
  </a>
</ng-template>

<ng-template #routerLinkTemplate>
  <a attr.aria-label="{{node.label}}" class="anml-link" *ngIf="!node.externalRedirect && node.link" [routerLink]="node.link" role="link">
    <ng-container *ngTemplateOutlet="isRtlLayout ? linkLabelRtlOutlet : linkLabelLtrOutlet"></ng-container>
  </a>
  <a attr.aria-label="{{node.label}}" class="anml-link" *ngIf="!node.link" role="link">
    <ng-container *ngTemplateOutlet="isRtlLayout ? linkLabelRtlOutlet : linkLabelLtrOutlet"></ng-container>
  </a>
</ng-template>

<ng-template #linkLabelLtrOutlet>
  <div class="anml-data" [dir]="'ltr'">
    <ng-container *ngTemplateOutlet="iconContainer"></ng-container>
    <span class="label">{{node.label}}</span>
  </div>
  <div class="amml-icon-arrow-container" *ngIf='node.hasChildren'>
    <mat-icon [@ExpandedLTR]="nodeExpandStatus()">
      keyboard_arrow_down
    </mat-icon>
  </div>
</ng-template>

<ng-template #linkLabelRtlOutlet>
  <div class="anml-data" [dir]="'rtl'">
    <ng-container *ngTemplateOutlet="iconContainer"></ng-container>
    <span class="label">{{node.label}}</span>
  </div>
  <div class="amml-icon-arrow-container" *ngIf='node.hasChildren'>
    <mat-icon [@ExpandedRTL]="nodeExpandStatus()">
      keyboard_arrow_down
    </mat-icon>
  </div>
</ng-template>

<ng-template #iconContainer>
  <div class="icon-container" [ngSwitch]="getListIcon(node)">
    <span *ngSwitchCase="'faIcon'" class="amml-icon amml-icon-fa">
      <em [ngClass]="getSelectedFaIcon()"></em>
    </span>
    <mat-icon *ngSwitchCase="'icon'" class="amml-icon">
      {{getSelectedIcon()}}
    </mat-icon>
    <mat-icon *ngSwitchCase="'svgIcon'" class="amml-icon amml-svg-icon"
      svgIcon="{{getSelectedSvgIcon()}}">
    </mat-icon>
    <img *ngSwitchCase="'imageIcon'" matListAvatar class="amml-icon"
      src="{{getSelectedImageIcon()}}" alt="" />
  </div>
</ng-template>
