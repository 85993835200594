import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { DailyPackagerDowntime } from '../../../models/daily-packager-downtime';
import { getDowntimePackagerDevice } from 'src/app/store/selectors/pharmacy-peek.selector';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';


const dailyPackagerDowntimeColors = ['#053AB2', '#2A84FA', '#6EADFD', '#ACD0FE'];

const dailyPackagerSystemDowntimeKeys = [
  { valueKey: 'dtTrayLoad', percentKey: 'dtTrayLoadPercent', label: 'Tray Load' },
  { valueKey: 'dtCanisterLoad', percentKey: 'dtCanisterLoadPercent', label: 'Canister Load' },
  { valueKey: 'dtMechanical', percentKey: 'dtMechanicalPercent', label: 'Mechanical' },
  { valueKey: 'dtOperatorInitiated', percentKey: 'dtOperatorInitiatedPercent', label: 'Operator Initiated' },
  { valueKey: 'dtOther', percentKey: 'dtOtherPercent', label: 'Others' },

];

@Component({
  selector: 'app-packager-downtime-device',
  templateUrl: './packager-downtime-device.component.html',
  styleUrls: ['./packager-downtime-device.component.scss']
})

export class PackagerDowntimeDeviceComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() filterDeviceId: string;
  childOrg = '';
  step = -1;
  @ViewChild('todayOverView', { static: false }) todayOverViewRef: ElementRef;

  views = {
    overview: true,
  };

  name = '';
  deviceId = '';
  downtime = 0;
  dailyPackagerUtilization = [];
  dailyPackagerDowntime: DailyPackagerDowntime[] = [];
  dailyPackagerSystemDowntime: DailyPackagerDowntime[] = [];
  dailyPackagerSystemSpecificDowntime: any = [];
  noDataExistsForDT = true;
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.DOWMTIME_DEVICE,
    deviceId: ''
  }

  constructor(
    public store: Store<State>, 
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
    ) { }

  ngOnInit() {
    this.store.select(getDowntimePackagerDevice).subscribe(response => {
      if (response) {
        let resData: any = response;
        //  filter selected widget
        if (this.filterDeviceId) {
          resData = resData.filter(t => t.deviceId == this.filterDeviceId);
        }
        if (resData == null) { return; }
        this.dailyPackagerUtilization = resData;
        resData.forEach((item) => {
          this.name = item.name ? item.name : '';
          this.deviceId = item.deviceId  ? item.deviceId : this.filterDeviceId; 
          this.downtime = item.downtime  ? item.downtime : 0; 
          this.dailyPackagerSystemDowntime = dailyPackagerSystemDowntimeKeys.map(({ valueKey, percentKey, label }) =>
          ({
            id: `DailyPackagerSystemUtilization_${valueKey}`,
            valueStr: item[valueKey],
            percent: item[percentKey],
            value: +(item[percentKey].replace('%', '')),
            label,
            deviceId: item.deviceId
          }))
            .sort(({ value: valueA }, { value: valueB }) => valueB - valueA)
            .map((data, i) => ({ ...data, color: dailyPackagerDowntimeColors[i] }));

        });

        this.dailyPackagerSystemSpecificDowntime = this.dailyPackagerSystemDowntime;
        this.noDataExistsForDT = false;

      }
    });

    this.subscription = this.signal.getDataStream<string>(SignalEventType.DOWMTIME_DEVICE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateDowntimePackagerDevice({ data: dataObj.data }));
      console.log(dataObj);
    });

    this.signalrSubList = {
      type: SignalEventType.DOWMTIME_DEVICE,
      deviceId: this.filterDeviceId ? this.filterDeviceId : ''
    }

    this.callDownTimeDevice();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
    this.deviceId = this.filterDeviceId ? this.filterDeviceId : ''; 
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  
  private callDownTimeDevice() {
    const deviceId = this.filterDeviceId ? this.filterDeviceId : '';
    this.viewStoreService.getDownTimeDevice(deviceId)
      .subscribe(
        (response) => {
            this.store.dispatch(PharmacyPeekActions.getDowntimePackagerDevice({ data: response }));
        },
        (error) => {
          console.log('callDownTimeDevice error:', error);
          if (error.status === 404) {
            this.loggerService.logWarning('DownTimeDevice Service: No Data: ', error);
          } else {
            this.toastService.openToast('DownTimeDevice service load error!', ToastPanelType.error);
          }
        }
      );
  }

  setStep(index: number) {
    if (this.step === index)
      {this.step = -1;}
    else
      {this.step = index;}
  }

}
