<div class="flex flex-row">  
    <app-search-bar placeholder="Search by ID Number" class="fleet_search gt-xs:grow-0 gt-xs:shrink gt-xs:basis-[300px] xs:basis-full" inputId="fleet-search" (filterChange)="filterData($event)"></app-search-bar>
</div>
<div class="flex flex-row">
    <div class="p-l-16 p-r-16 basis-full">    
        <div class="fleet_scroll">
            <!-- Fleet mgmt grid -->
            <table mat-table [dataSource]="dataSource" matSort #sort="matSort"  matSortActive="idName"   matSortDirection="asc" 
            class="prepro_table" (matSortChange)="announceSortChange($event)" tabindex="0" role="table">

                <!-- friendlyName -->
                <ng-container matColumnDef="idName" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID Number">
                        ID Number
                    </th>
                    <td mat-cell *matCellDef="let element"  [innerHTML]="element.edgeDeviceId | noHtml | highlightMatched: filterText" >  </td>
                </ng-container>

                <!-- Agent Type -->
                <ng-container matColumnDef="agentType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Agent Type">
                        Agent Type
                    </th>
                    <td mat-cell *matCellDef="let element"> On Device </td>
                </ng-container>

                <ng-container matColumnDef="connected">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Connected">
                        Connected
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.connectionState === 'Online'" class="flex">{{element.connectionState}} </span>
                        <span *ngIf="element.connectionState !== 'Online'" class="flex text-error">
                            <img class="alert_icon" src="../../../../../assets/img/icons/ico.16.alert.svg" alt="Alert">{{element.connectionState}}
                        </span>                    
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastUpdated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Last Updated">
                        Last Updated
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.connectionStateUpdatedTime | date:'dd-MMM-YYYY, h:mm aa'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" tabindex="0" (keydown.enter)="openSystemConfigDialog(row)" (click)="openSystemConfigDialog(row)"></tr>
            </table>
            <table class="no-result" *ngIf="dataSource?.data?.length==0">
                <tr>
                    <td [attr.colspan]="displayedColumns.length">
                      <span>No data exists</span>                                          
                    </td>
                </tr>        
            </table>
        </div>        
    </div>
</div>