
<div class="main-section">
    <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
        <div class="flex justify-start content-center items-center flex-row">
            <div class="flex flex-row justify-start content-stretch items-stretch h-full">
                EDIT CDDB AUTOMATION SCRIPT
            </div>

        </div>
    </h1>

        <div class="flex flex-row">
            <div style="padding-right:1rem;" class="basis-1/2">
                <div class="flex flex-col">
                    <div class="flex flex-row">
                        <span style="color: red; padding-right:.2rem;">*</span> <span class="label">Script Type</span>
                    </div>



                        <mat-form-field>
                            <mat-select>
                                <mat-option>MAX Script</mat-option>
                                <mat-option>General Script</mat-option>
                            </mat-select>
                         </mat-form-field>


                   </div>
                   <div class="flex flex-col">
                    <div class="flex flex-row">
                    <span style="color: red; padding-right:.2rem;">*</span>  <span class="label">Script Setting</span>
                  </div>

                        <mat-form-field>
                            <mat-select >
                                <mat-option>30 Dram Capacity</mat-option>
                                <mat-option>MDL download</mat-option>
                                <mat-option>Drug Image Downloads</mat-option>
                                <mat-option>Cell Height</mat-option>
                                <mat-option>Cell Width</mat-option>
                                <mat-option>Cell Baffle</mat-option>
                                <mat-option>Pressure</mat-option>
                            </mat-select>
                         </mat-form-field>

            </div>

             </div>

           <div class="flex basis-1/2">
            <textarea class="basis-4/5" rows="8">using System;
              using System.Collections.Generic;
              using System.Linq;
              using System.Text; namespace DrugLibrary.DataModel
              {
                  public class ThirtyDramCapacity
                  {        public ThirtyDramCapacity()
                      {
                      }        public int CalculateThirtyDramCapacity(DrugMeasurement measurements)
                      {
                          int capacity = -1;
                          double thirtyDramVialVolume = 6.71957;
                          double length = 0;
                          double width = 0;
                          double thickness = 0;            if (measurements.ShapeClassificationID == DrugShapeClassifications.CapsuleID)
                          {
                              if (measurements.Width <= 0)
                                  throw new Exception("The width is required for a capsule shaped pill's 30 dram capacity to be calculated.");
                              if (measurements.Length <= 0)
                                  throw new Exception("The length is required for a capsule shaped pill's 30 dram capacity to be calculated.");</textarea>
         </div>
       </div>
    <div class="flex justify-end content-stretch items-stretch">
      <button class="btn-primary btn btn-md" >Save</button>
    </div>
</div>







