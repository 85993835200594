import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from 'src/app/store/state';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InventoryService } from 'src/app/external-user/inventory/services/viewstore-inventory.service';
import {InventoryActions} from '../actions';
import { appConst as CONSTANTS } from '../../config/app-constants';
import { InvRptData } from '../../external-user/inventory/components/inventory-child/inventory-views/inventory-views.component';
import * as storage from '../state/storage';

@Injectable()
export class InventoryEffect {
  constructor(private actions$: Actions, private inventoryService: InventoryService, public store: Store<State>,) {}


  Inventory$ = createEffect(() => this.actions$.pipe(
      ofType(InventoryActions.getInventoryData),
      mergeMap(() =>
      {
        const TotalDrugCount: any = storage.getItem('inventory').TotalDrugCount;
        if(TotalDrugCount > CONSTANTS.INVENTORIES_PER_PAGE)
        {
         return this.inventoryService.getInventories(CONSTANTS.INVENTORIES_PER_PAGE + 1, TotalDrugCount, false, false).pipe
        (
          map((response) => {
            return InventoryActions.updateInventoryData(response)}
          ),
          catchError(error => {return EMPTY})
        );
        }
        else
        {
          return;
        }
      }),
  ));
}
