<div *ngIf="enableConsumable">
  <app-notification-banner class="p-b-15"></app-notification-banner>
</div>

<app-card title="Reports">
  <div class="flex justify-between content-start items-start flex-row flex-wrap">
    <div class="app-search-bar-container p-t-16 p-b-16 flex basis-full">
      <app-search-bar (filterChange)="filterReports($event)" placeholder="Search by Report Name"
                      hoverPlaceholder="Search by Report Name" inputId="report-subscription-search" class="grow-0 shrink basis-[325px]"></app-search-bar>
    </div>
    <div class="reports_table basis-full">
      <table mat-table [dataSource]="dataSource">
        <caption></caption>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element.reportName | noHtml | highlightMatched: filterText"
              class="report-name" data-label="Report Name" tabindex="0"
              [attr.aria-label]="'Report Name is' + element.reportName" [routerLink]="['/reports', element.reportId ]"
              (keydown.enter)="keypressreport(element.reportId)">
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="report-description" data-label="Report Description" tabindex="0"
              [routerLink]="['/reports', element.reportId ]" (keydown.enter)="keypressreport(element.reportId)">
            {{element.description}}
          </td>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="report-checkbox" tabindex="0">
            <div [class.show]="row.isSubscribed || row.hovered" (focus)="row.hovered = true" class="flex flex-row">
              <mat-slide-toggle (change)="toggleSubscription($event, row)" [checked]="row.isSubscribed" tabindex="0">
                {{ row.isSubscribed?'Subscribed':'Not Subscribed'}}
              </mat-slide-toggle>
            </div>
          </td>
        </ng-container>

        <!-- Link Column -->
        <ng-container matColumnDef="link">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="report-link" [routerLink]="['/reports', element.reportId ]"
              (keydown.enter)="keypressreport(element.reportId)">
            <img src="../../../.././../assets/img/icons/ico.16.arwrgt.svg" tabindex="0" alt="" class="pointer">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="reportsTableColumns" class="none"></tr>
        <tr mat-row *matRowDef="let row; columns: reportsTableColumns;" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
            tabindex="0"></tr>
      </table>
    </div>
    <div class="reports_table_mini basis-full">
      <div *ngFor="let items of filteredReports">
        <div class="rtm_row xs:flex xs:justify-start xs:content-center xs:items-center xs:flex-row sm:justify-start sm:items-center sm:content-center"
             [routerLink]="['/reports', items.reportId ]">
          <div class="md:basis-[63%] xs:hidden sm:hidden md:block ">
            <p class="rep_name">{{items.reportName}}</p>
            <p class="rep_desc">{{items.description}}</p>
          </div>
          <!-- medium screen -->
          <div class="sm:basis-2/5 xs:hidden sm:block md:hidden">
            <p class="rep_name">{{items.reportName}}</p>
          </div>
          <div class="sm:basis-[30%] xs:hidden sm:block md:hidden">
            <p class="rep_desc">{{items.description}}</p>
          </div>
          <div class="sm:basis-1/4 xs:hidden sm:block md:basis-[32%]">
            <div [class.show]="items.isSubscribed || items.hovered">
              <app-toggle [value]="items.isSubscribed" (valueChange)="toggleSubscription($event, items)"
                          onLabel="Subscribed" offLabel="Not Subscribed"></app-toggle>
            </div>
          </div>
          <!-- mobile screen -->
          <div class="xs:basis-[95%] sm:hidden xs:block md:hidden">
            <p class="rep_name">{{items.reportName}}</p>
            <p class="rep_desc">{{items.description}}</p>
            <div [class.show]="items.isSubscribed || items.hovered">
              <app-toggle [value]="items.isSubscribed" (valueChange)="toggleSubscription($event, items)"
                          onLabel="Subscribed" offLabel="Not Subscribed"></app-toggle>
            </div>
          </div>
          <div class="flex justify-end content-center items-center xs:basis-[5%]">
            <p class="arwrgt">
              <img src="../../../.././../assets/img/icons/ico.16.arwrgt.svg" alt="" class="pointer">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-card>
