import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appPositiveNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PositiveNumberValidatorDirective,
      multi: true
    }
  ]
})
export class PositiveNumberValidatorDirective implements Validator {

  // Regular expression to match only positive numbers (integer and decimal)
  private validPositiveNumberRegex = /^[+]?\d*\.?\d+$/;  // Accepts integers and decimals but no negative numbers

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    // Check if value matches the positive number pattern
    if (this.validPositiveNumberRegex.test(value)) {
      return null; // Valid positive number
    }

    return { 'invalidPositiveNumber': true }; // Invalid number
  }
}
