import { NgModule } from '@angular/core';
import { ReportsModule } from './reports/reports.module';
import { ReportsRoutingModule } from './reports/reports-routing.module';
import { BatchManagerModule } from './batch-manager/batch-manager.module';
import { PharmacyPeekModule } from './pharmacy-peek/pharmacy-peek.module';
import { InventoryModule } from './inventory/inventory.module' ;
import { StratusSetupModule } from './stratus-setup/stratus-setup.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [
    ReportsModule,
    BatchManagerModule,
    PharmacyPeekModule,
    SharedModule,
    FormsModule,
    InventoryModule,
    ReactiveFormsModule,
    ReportsRoutingModule,
    StratusSetupModule,
  ],
  exports: [
    PharmacyPeekModule,
    StratusSetupModule,
  ],
})

export class ExternalUserModule { }
