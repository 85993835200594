import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { View } from '../inventory-views/inventory-views.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { TitleCasePipe } from '@angular/common';
import { InventoryViewsData } from '../../../models/inventory-view-data';


interface InventoryViewsTableColumns {
  field: string;
  header: string;
  highlightMatched: boolean;
  icon?: string;
}

export const availableColumns: InventoryViewsTableColumns[] = [
  { field: 'drugName', header: 'Drug Name', highlightMatched: true },
  { field: 'ndc', header: 'NDC', highlightMatched: true },
  { field: 'manufacturer', header: 'Manufacturer', highlightMatched: true },
  { field: 'actualDispenseQuantity', header: 'Act. Disp Qty', highlightMatched: false, icon: '' },
  { field: 'avgDailyDispense', header: 'Avg. Daily Disp', highlightMatched: false, icon: 'info' },
  { field: 'totalQuantity', header: 'Current Inv', highlightMatched: false },
  { field: 'canisterLocation', header: 'Canister Loc', highlightMatched: false },
  // { field: 'actions', header: '', highlightMatched: false },
];
const displayedColumnsTemp = ['drugName', 'ndc', 'manufacturer','actualDispenseQuantity', 'avgDailyDispense', 'totalQuantity', 'canisterLocation'];

@Component({
  selector: 'app-inventory-views-table-rpt',
  templateUrl: './inventory-views-table-rpt.component.html',
  styleUrls: ['./inventory-views-table-rpt.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class InventoryViewsTableRptComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() inventories: InventoryViewsData[];
  @Input() filterText;
  @Input() loading;
  @Input() view: View;
  @Input() dateRange;
  @Output() loadInventories: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('inventoryTable', { static: false }) private inventoryTableRef;
  @ViewChild('fixedHeader', { static: false }) private fixedHeaderRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();

  View = View;
  expandedElement: any;
  columns: InventoryViewsTableColumns[] = [];
  displayedColumns: any = displayedColumnsTemp;

  panelOpenState: boolean;

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth > 900) {

    }
  }

  constructor(   
  ) { }

  ngOnInit() {
    const buffer = 5;
    this.panelOpenState = true;

    if (this.inventories && !this.loading && buffer > this.inventories.length)
    {
      this.loadInventories.emit(null);
      }
  }

  ngAfterViewInit() {
    this.sort.sortChange.emit({ active: 'drugName', direction: 'asc' });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view) {
      if (this.view === View.SYSTEM) {
        this.columns = availableColumns;
        this.displayedColumns = displayedColumnsTemp;
      } else {
        this.columns = availableColumns.filter(({ field }) => field !== 'canisterLocation');
        this.displayedColumns = displayedColumnsTemp.filter((x) => x.toString() !== 'canisterLocation');
      }
    }
  }

  isRowClickable(rowIndex: number): boolean {
    return this.inventories[rowIndex].lastDispInfo && this.inventories[rowIndex].lastDispInfo.length > 0;
  }

  sortingChange() {
    this.sortChange.emit( this.sort);
    this.expandedElement = null;
    return;
  }

  onExpandRow(row: any, rowIndex: number) {
    row.isExpanded = !row.isExpanded;
    // const expandedElement1 = this.expandedElement === row ? null : row;
    // if (expandedElement1) {
    //   this.expandedElement = expandedElement1;
    // }
    // else {
    //   this.expandedElement = null;
    // }
  }
}
