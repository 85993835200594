import { createAction, props } from '@ngrx/store';

export const GET_IOT_DEVICE_DETAILS = 'GET_IOT_DEVICE_DETAILS';
export const GET_IOT_DEVICE_DETAILS_SUCCESSFUL = 'GET_IOT_DEVICE_DETAILS_SUCCESSFUL';
export const GET_FT_CONFIG = 'GET_FT_CONFIG';

export const getFTConfiguration = createAction(
  GET_FT_CONFIG,
  props<{data: any}>()
);

export const getIOTDeviceDetails = createAction(
  GET_IOT_DEVICE_DETAILS
);

export const getIOTDeviceDetailsSuccessful = createAction(
  GET_IOT_DEVICE_DETAILS_SUCCESSFUL,
  props<{data: any}>()
);
