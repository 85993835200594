import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import * as constants from '../../config/app-constants';
import { AuthGuard } from '../../auth/auth.guard';
import { PharmacyScriptingComponent } from './components/pharmacy-scripting/pharmacy-scripting.component';

const routes: Routes = [
  {
    path: constants.routePaths.SCRIPT_ROUTING,
    component: PharmacyScriptingComponent,
    canActivate: [ AuthGuard ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BatchManagerRoutingModule { }
