import { createAction, props } from '@ngrx/store';
import { InvRptData } from '../../external-user/inventory/components/inventory-child/inventory-views/inventory-views.component';

export const GET_INVENTORY_REPORT_DATA = '[Inventory] Get Inventory Report data';
export const UPDATE_INVENTORY_REPORT_DATA = '[Inventory] Update Inventory Report data';
export const RESET_INVENTORY_REPORT_DATA = '[Inventory] Reset Inventory Report data';

export const getInventoryData = createAction(
  GET_INVENTORY_REPORT_DATA,
  props<{data: any}>()
);

export const updateInventoryData = createAction(
  UPDATE_INVENTORY_REPORT_DATA,
  props<{data: any}>()
);

export const resetInventoryData = createAction(
  RESET_INVENTORY_REPORT_DATA
);
