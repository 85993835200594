import { Component, Inject, OnInit } from '@angular/core';
import { GenericConfirmationDialogComponent } from 'src/app/shared/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { FleetManagerActions } from 'src/app/store/actions';
import { FleetManagementService } from '../../services/fleet-management.service';
import { DeviceSummaryField } from '../../models/deviceSummary.model';

@Component({
  selector: 'app-edge-appliance-details',
  templateUrl: './edge-appliance-details.component.html',
  styleUrls: ['./edge-appliance-details.component.scss']
})
export class EdgeApplianceDetailsComponent implements OnInit {

  edgeDeviceId: string;
  edgeDeviceFriendlyName: string;
  organizationId: string;
  status: string;
  lastKnownOnlineTime: string;
  ipAddress: string = "Requesting IP...";
  additionalFields: DeviceSummaryField[];

  constructor(
    public dialogRef: MatDialogRef<EdgeApplianceDetailsComponent>
    , @Inject(MAT_DIALOG_DATA) public parentCompData: any
    , private fleetConfigService: FleetConfigurationService
    , public store: Store<State>
    , private toastService: ToastService
    , public dialog: MatDialog
    , private ngxLoader: NgxUiLoaderService
    , public fleetManagementService: FleetManagementService) { }

  ngOnInit() {
    this.edgeDeviceId = this.parentCompData.edgeDeviceId;

    const loadingKey = 'LoadDeviceSummary';
    this.ngxLoader.start(loadingKey);

    this.fleetManagementService.getEdgeDeviceSummary(this.edgeDeviceId).subscribe({
      next: deviceSummary => {
        this.ngxLoader.stop(loadingKey);
        
        if (!deviceSummary) {
          console.error(`No details could be located for device ID ${this.edgeDeviceId}.`);
          this.toastService.openToast(`Details for device ${this.edgeDeviceId} could not be found.`, constants.ToastPanelType.error);
          this.dialogRef.close(false);
        }

        this.edgeDeviceFriendlyName = deviceSummary.edgeDeviceFriendlyName;
        this.organizationId = deviceSummary.organizationId;
        this.status = deviceSummary.connectionState;
        this.lastKnownOnlineTime = deviceSummary.lastOnlineTime;
        this.additionalFields = deviceSummary.additionalFields;

        if (!deviceSummary.ipAddress) {
          this.getHostInformation();
          return;
        }

        this.ipAddress = deviceSummary.ipAddress;
      },
      error: err => {
        console.error(err);
        this.ngxLoader.stop(loadingKey);
        this.toastService.openToast(`Unexpected error fetching details for device ${this.edgeDeviceId}.`, constants.ToastPanelType.error);
        this.dialogRef.close();
      }
    });
  }

  onDisable() {
    const message = "Are you sure? Disabling an Edge Appliance will stop data being transferred and data will be lost after 72 hours."
    const dialogRef = this.dialog.open(GenericConfirmationDialogComponent,
      {
        width: '250px',
        disableClose: true
      });
    let instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.title = "Disable edge device";
    instance.message = message;

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        const ngxLoaderKey = 'DisableDevice';
        this.ngxLoader.start(ngxLoaderKey);
        this.fleetConfigService.disableDevice(this.edgeDeviceId).subscribe({
          next: (result) => {
            this.status = "Disabled";
            this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
            this.ngxLoader.stop(ngxLoaderKey);
            this.toastService.openToast('Disabled edge device successfully.', constants.ToastPanelType.done);
            console.log(result);
          },
          error: (error) => {
            this.toastService.stopLoadingAndShowError(error,
              'Failed to disable edge device!', ngxLoaderKey);
          }
        });
      }
    });
  }

  private getHostInformation(): void {
    this.fleetManagementService.getDeviceInformation(this.edgeDeviceId).subscribe({
      next: (v) => {
        try {
          const result = JSON.parse(v);
          const deviceDetails = JSON.parse(result.data);
          this.ipAddress = this.separateIpAddresses(deviceDetails.IPAddress);
        }
        catch (err) {
          console.log(err);
          this.ipAddress = "Unknown";
        }
      },
      error: (e) => {
        console.log(e);
        this.ipAddress = "Unknown";
      }
    });
  }

  private separateIpAddresses(ipAddress): string {
    const ipv4Regex = /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/;
    const ipv4Match = ipAddress.match(ipv4Regex);
    return ipv4Match ? ipv4Match[0] : "Unknown";
  }
}