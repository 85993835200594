<div class="flex flex-row">
  <div class="s_rol flex flex-row justify-start content-center items-center h-full">
    <app-search-bar class="fleet_search xs:basis-full gt-xs:grow-0 gt-xs:shrink gt-xs:basis-[300px]"
      placeholder="Search Name or ID Number" inputId="fleet-search" 
      (filterChange)="filterData($event)"></app-search-bar>
    <div class="m-r-8 flex grow-0 shrink-0 basis-4" style="align-self: center">
      <app-icon icon="filter" aria-label="filter" role="button" class="pointer"></app-icon>
    </div>
    <mat-form-field class="combowidth grow-0 shrink basis-[180px]">
      <mat-select id="provisioned-org-select" value="" [(ngModel)]="selectedOrgId" class="m-t-0 pro-mat-select" 
        (selectionChange)="filterChange()" disableOptionCentering>
        <mat-option *ngFor="let type of orgDDList" [value]="type.Key">
          {{ type.Value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="combowidth grow-0 shrink basis-[180px]">
      <mat-select id="provisioned-status-select" value="" [(ngModel)]="selectedStatusId" class="m-t-0 pro-mat-select" 
        (selectionChange)="filterChange()" disableOptionCentering>
        <mat-option *ngFor="let type of statusList" [value]="type.Key">
          {{ type.Value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="flex flex-row">
  <div class="p-l-16 p-r-16 basis-full">
    <div class="fleet_scroll">
      <mat-accordion class="pro_table" [multi]="true" >
        <!-- Common Table Header -->
        <table #tblProvisionedHeader mat-table [dataSource]="packagerList" matSort #sort="matSort" 
          matSortActive="idName" matSortDirection="asc" class="prepro_table header-table" tabindex="0" role="table">
          <!-- Common Column Definitions -->
          <ng-container matColumnDef="name">            
            <th mat-header-cell *matHeaderCellDef [style.width.px]="250">              
              <span class="accordion-collapse">
                <img (click)="toggleAccordion()" [class.collapsed-accordion-icon]="accordionCollapsed" src="../../../../../assets/img/icons/ico.12.states.svg" class="icon-states" alt="accordion-toggle" >
              </span>
              <span style="padding-left:8px">Name</span>
            </th>
             <td mat-cell *matCellDef="let element" [innerHTML]="element.systemFriendlyName " data-label="Name" 
              tabindex="0">
            </td> 
          </ng-container>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="200">ID Number</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.systemId" data-label="ID Number" tabindex="0">
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="200">Status</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.status" data-label="Status" tabindex="0"></td>
          </ng-container>
          <ng-container matColumnDef="heartbeat">
            <th mat-header-cell *matHeaderCellDef [style.width.px]="200">CDC Heartbeat</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.cdcHeartbeat | date:'medium'" 
              data-label="CDC Heartbeat" tabindex="0">
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef ></th>
            <td mat-cell *matCellDef="let element" data-label="actions" tabindex="0">
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        </table>
        <div class="pro-table-content" [style.height.px]="offsetFromBottom">
          <table class="no-result" *ngIf="provisionedList?.data?.length==0">
            <tr>
              <td [attr.colspan]="displayedColumns.length">
                <span>No data exists</span>
              </td>
            </tr>
          </table>
          <mat-expansion-panel togglePosition="before" [expanded]="true" *ngFor="let element of provisionedList.data">
            <mat-expansion-panel-header (click)="toggleRow(element)" 
              [ngClass]="{'offline': element.connectionState !== 'Online'}">
              <mat-panel-title>
                <span class="flex basis-[90%]">
                  <span class="cls-orgname">
                    <img class="alert-icon" *ngIf="element.connectionState !== 'Online'" 
                      src="../../../../../assets/img/icons/ico.16.alert.svg" alt="Alert">
                      <span [innerHTML]="(getDeviceDisplayName(element)) | uppercase | noHtml | highlightMatched: filterText"></span>
                    </span>
                  <span class="cls-status">
                    {{element?.connectionState}}
                  </span>
                </span>
                <span class="span-ip">{{ element.ipAddress}}</span>
                <span class="text-right basis-[10%]">
                  <button [matMenuTriggerFor]="provisionedMenu" class="btn-transparent p-0 device-menu" 
                    (click)="$event.stopPropagation()" (keypress.enter)="$event.stopPropagation()" type="button" 
                    tabindex="0" [id]="element?.customerId" aria-label="Action Menu">
                    <mat-icon class="pointer menu-trigger">
                      <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
                    </mat-icon>
                  </button>
                  <mat-menu #provisionedMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                    <button mat-menu-item type="button" (click)="downloadLogs(element.edgeDeviceId)" *ngIf="element.connectionState == 'Online'">Pull Logs</button>
                    <button mat-menu-item type="button" [disabled]="element.connectionState == 'Disabled'" (click)="restartModules(element.edgeDeviceId)">Restart Modules(s)</button>
                    <button mat-menu-item type="button" [disabled]="element.connectionState == 'Disabled'" (click)="restartDevice(element.edgeDeviceId)">Restart Edge Appliance</button>
                    <button mat-menu-item type="button" (click)="addNewSystemDialog(element)">Add New System</button>
                    <button mat-menu-item type="button" (click)="showEdgeApplianceDetails(element)">Details</button>
                    <button mat-menu-item type="button" (click)="deprovisionDevice(element.edgeDeviceId)">Deprovision Device</button>
                  </mat-menu>
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row-tables">
              <table #tblProvisionedContent mat-table [dataSource]="element.systems" matSort #sort="matSort" 
                matSortActive="idName" matSortDirection="asc" class="row-table" tabindex="0" role="table">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef >Name</th>
                  <td mat-cell *matCellDef="let rowdata" data-label="Name" tabindex="0" [style.width.px]="250">
                    <span style="padding-left:16px">
                      <img class="alert-icon" *ngIf="rowdata.status !== 'Online'" 
                        src="../../../../../assets/img/icons/ico.16.alert.svg" alt="Alert">
                    <span class="txt-ellipsis" 
                      [matTooltipDisabled]="false" 
                      [matTooltip]="rowdata?.systemFriendlyName == '' ? rowdata?.systemId : rowdata?.systemFriendlyName" 
                      matTooltipPosition="below"
                      matTooltipClass="my-custom-tooltip" 
                      [innerHTML]="(rowdata?.systemFriendlyName == '' ? rowdata?.systemId : rowdata?.systemFriendlyName) | noHtml | highlightMatched: filterText">
                    </span>
                </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef >ID Number</th>
                  <td mat-cell *matCellDef="let rowdata" data-label="Name" tabindex="0" [style.width.px]="200">
                       <span class="txt-ellipsis" 
                          [matTooltipDisabled]="false" 
                          [matTooltip]="rowdata?.systemId" 
                          matTooltipPosition="below" 
                          matTooltipClass="my-custom-tooltip" 
                          [innerHTML]="rowdata?.systemId | noHtml | highlightMatched: filterText">
                      </span>
                </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef >Status</th>
                  <td mat-cell *matCellDef="let rowdata" [innerHTML]="rowdata.status" data-label="Name" tabindex="0" 
                    [style.width.px]="200" [ngClass]="{'offline': applyColumnClass(rowdata)}"></td>
                </ng-container>
                <ng-container matColumnDef="heartbeat">
                  <th mat-header-cell *matHeaderCellDef >CDC Heartbeat</th>
                  <td mat-cell *matCellDef="let rowdata" 
                    [innerHTML]="rowdata.cdcHeartbeat | date:'MMM dd - hh:mm aaaaa\'m\''" data-label="Name" 
                    tabindex="0">
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                  <td mat-cell *matCellDef="let rowdata" class="text-right">
                    <!-- <button [matMenuTriggerFor]="provisionedMenu" class="btn-transparent p-0"
                      (click)="$event.stopPropagation()" (keypress.enter)="$event.stopPropagation()" type="button"
                      tabindex="0" [id]="element?.customerId" aria-label="Action Menu">
                      <mat-icon class="pointer menu-trigger">
                        <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
                      </mat-icon>
                    </button>
                    <mat-menu #provisionedMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                      <button mat-menu-item type="button">Remove From Edge Appliance</button>
                      <button mat-menu-item type="button">View/Edit Details</button>
                    </mat-menu> -->
                  </td>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRow(row)" 
                  [ngClass]="{'offline': applyRowClass(row)}"></tr>
              </table>
            </div>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </div>
  </div>
</div>
