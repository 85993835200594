import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AddGcnDrugs, AdjustGcnDrug, GcnDrugs, GroupBy } from '../../models/gcn-drugs';
import { IMToolService } from '../../services/im-tool.service';
import * as constants from 'src/app/config/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import * as XLSX from "xlsx";
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';

@Component({
  selector: "app-import-wizard-gcn",
  templateUrl: "./import-wizard-gcn.component.html",
  styleUrls: ["./import-wizard-gcn.component.scss"],
})
export class ImportWizardGcnComponent implements OnInit {
  @ViewChild("TABLE") table: ElementRef;
  dataSource =new MatTableDataSource<GcnDrugs>([]);
  gcnNumberList:GroupBy[]=[];
  isColorChange:boolean=false;
  gcnDrugList: any;
  gcnDrugdModel:AdjustGcnDrug;
  customerId:string;
  customerName:string;
  saveGcnDrugListModel:AddGcnDrugs[]=[];

  selectedGcnDrugList:GcnDrugs[]=[];


   //#region Table colums Array
 displayedColumns: string[] = [
 "checkbox",
 "sl",
 "drugName",
 "gcnNumber",
 "ndc",
 "manufacturer",
 "strength",
 "unitOfUsage",
 "rxQty",
];
//#endregion

  selectedRow:any;


  constructor(
    private imtoolService: IMToolService,
    public dialogRef: MatDialogRef<ImportWizardGcnComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.gcnDrugList = data.gcnDrugList;
    this.customerId = data.customerId;
    this.customerName = data.customerName;
    this.gcnNumberList = Array.from(this.gcnDrugList.reduce((m, t) => m.set(t.gcnNumber, t.gcnNumber), new Map()).values());


    for(let i=0; i<this.gcnNumberList.length; i++){

     let background_color =  this.random_bg_color();
      for(let j=0; j<this.gcnDrugList.length; j++){
        if(this.gcnNumberList[i] == this.gcnDrugList[j].gcnNumber){
          this.gcnDrugList[j].backgroundColor = background_color;
        }
      }
    }
    this.dataSource.data = this.gcnDrugList;
  }

  ngOnInit(): void {}
  closeDialog(response: boolean) {
    this.dialogRef.close({ event: "close", data: response });
  }

   random_bg_color(): string {
    if(this.isColorChange)
    {
      this.isColorChange = false;
      return "rgb(191, 191, 191)";
    }
    else
    {
      this.isColorChange = true;
      return "rgb(255, 255, 255)";
    }
  }

  ExportTOExcel() {
    this.ngxLoader.start();
    setTimeout(()=>{

      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      if (this.dataSource.data.length > 0) {
        const ws1: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
          this.table.nativeElement
        );
        ws1["!cols"][0] = { hidden: true };
        XLSX.utils.book_append_sheet(wb, ws1, "Duplicate NDC");
      }


      /* save to file */
      XLSX.writeFile(
        wb,
        `Duplicate_NDC_${this.customerName}.xlsx`
      );
      this.ngxLoader.stop();
      this.toastService.openToast("File exported successfully.", constants.ToastPanelType.done, 2);
    },1000)


  }
  redirect() {
    this.dialogRef.close({ event: "close", data: false });
    this.route.navigate(['./im-tool']);
  }

  redirectDrugPage() {
    this.dialogRef.close({ event: "close", data: false });
    this.route.navigate(["/im-tool/export", this.customerId]);
  }

  allDrugUnSelect(){
    this.selectedGcnDrugList=[];
    for(let i=0; i<this.dataSource.data.length; i++){
      this.dataSource.data[i].isChecked = false;
    }
  }
  highestUsageDrugSelect(){
    this.selectedGcnDrugList=[];
    for(let i=0; i<this.dataSource.data.length; i++){
      this.dataSource.data[i].isChecked = false;
    }

   let data=  this.dataSource.data;
   data= Object.values(data.reduce((x,y)=>Object.assign(x,{[y.gcnNumber]:y}),{}))
   let size= data.length;
   for(let k=0; k<size; k++){
      let temp;
      for(let j=0; j<this.dataSource.data.length; j++){
        if(data[k].gcnNumber === this.dataSource.data[j].gcnNumber){
          if(temp === undefined || temp === null || this.dataSource.data[j].unitOfUsage>temp.unitOfUsage){
              temp = this.dataSource.data[j];
          }
        }
      }
      if(temp!== undefined && temp !== null){
        this. drugCellRowChecked(temp, true)
      }
    }
  }
  lowestUsageDrugSelect(){
    this.selectedGcnDrugList=[];
    for(let i=0; i<this.dataSource.data.length; i++){
      this.dataSource.data[i].isChecked = false;
    }
   let data=  this.dataSource.data;
   data= Object.values(data.reduce((x,y)=>Object.assign(x,{[y.gcnNumber]:y}),{}))
   let size= data.length;
   for(let k=0; k<size; k++){
      let temp;
      for(let j=0; j<this.dataSource.data.length; j++){
        if(data[k].gcnNumber === this.dataSource.data[j].gcnNumber){
          if(temp === undefined || temp === null || this.dataSource.data[j].unitOfUsage<temp.unitOfUsage){
              temp = this.dataSource.data[j];
          }
        }
      }
      if(temp!== undefined && temp !== null){
        this. drugCellRowChecked(temp, true)
      }
    }
  }
  drugCellRowChecked(row:any, isChecked:boolean){
    if (isChecked) {
      if(this.isAlreadyAddedThisGcnDrug(row)){
        this.toastService.openToast(
          `Duplicate NDC drug already added, deselect and add again!!`,
          constants.ToastPanelType.warning,
          2
        );

        let index = this.dataSource.data.findIndex(
          (x) => x.gcnNumber === row.gcnNumber &&  x.sl === row.sl
        );
        setTimeout(()=>{
          this.dataSource.data[index].isChecked = false;
        }, 20)

      }
      else{
        this.selectedGcnDrugList.push({
          sl: row.sl,
          ndc: row.ndc,
          drugName: row.drugName,
          customerDrugName: row.customerDrugName,
          strength: row.strength,
          manufacturer: row.manufacturer,
          isChecked: row.isChecked,
          gcnNumber:row.gcnNumber,
          unitOfUsage:row.unitOfUsage,
          backgroundColor:row.background_color,
          packageSize:row.packageSize,
          rxQty:row.rxQty
        });
        let index = this.dataSource.data.findIndex(
          (x) => x.gcnNumber === row.gcnNumber &&  x.sl === row.sl
        );

          this.dataSource.data[index].isChecked = true;

      }
    } else {
      let index = this.selectedGcnDrugList.findIndex(
        (x) => x.gcnNumber == row.gcnNumber && x.sl == row.sl
      );
      if(index>=0){
        this.selectedGcnDrugList.splice(index, 1);
      }

    }
  }
  isAlreadyAddedThisGcnDrug(row):boolean{
    let output = false;
      for(let i=0; i<this.selectedGcnDrugList.length; i++){
        if(this.selectedGcnDrugList[i].gcnNumber === row.gcnNumber){
          output= true;
          break;
        }
      }
      return output;
  }


  saveGcnDrugs(){
    this.ngxLoader.start();
    this.selectedGcnDrugList?.forEach(element =>  this.saveGcnDrugListModel.push({ ndc: element.ndc, genericSequenceNumber:element.gcnNumber, usage:element.unitOfUsage, checked: element.isChecked }));
    this.gcnDrugdModel ={
      deviceId:0,
      customerId : this.customerId,
      gcnDrug: this.saveGcnDrugListModel
    }
    this.imtoolService.gcnDrugAdd(this.gcnDrugdModel).subscribe((result)=>{
      this.ngxLoader.stop();
      if(result.statusCode===200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.dataSource.data = [];
        this.selectedGcnDrugList=[];
        this.saveGcnDrugListModel=[];
        this.gcnDrugList = [];
        this.gcnDrugdModel=null;
        this.customerId = "";

        this.closeDialog(true);
      }
      else{
        this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);
      }

    },(error: HttpErrorResponse) => {

      this.ngxLoader.stop();
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  openAlertWindow() {

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Export Duplicate NDC List",
        bodyMessage: "Are you sure you want to export this drug list?",
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.ExportTOExcel();
      } else {
        console.log("not export", result);
      }
    });
  }

}
