/** 
   * #region MachineType
   * API definition for machinetype and mapping for scripting by machine/device
   *
   *    {
   *        AssetId = Constants.ASSET_MACHINE_ATP,
   *       AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *       Name = "ATP",
   *        Description = "ATP Machine",
   *        Configurations = "{'MachineTypeID' : 1}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }, 
   *    {
   *        AssetId = Constants.ASSET_MACHINE_PASS,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "PASS",
   *        Description = "PASS Machine",
   *        Configurations = "{'MachineTypeID' : 2}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    },
   *    {
   *        AssetId = Constants.ASSET_MACHINE_MAX,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "MAX",
   *        Description = "MAX Machine",
   *        Configurations = "{'MachineTypeID' : 3}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }, 
   *    {
   *        AssetId = Constants.ASSET_MACHINE_MINI,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "MINI",
   *        Description = "MINI Machine",
   *        Configurations = "{'MachineTypeID' : 4}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }
   *
   */

/**
   * public enum Packaging
   * {
   *     /// <summary>Packaging will be determined by rules in the routing process</summary>
   *     Undefined,
   *
   *     /// <summary>Each Dose will be packaged separately, packager will be determined by rules in the routing process</summary>
   *     Adherence,
   *
   *     /// <summary>Doses will be packaged in a blister card</summary>
   *     Blister,
   *
   *     /// <summary>Meds are already packaged or will be filled manually into vials.</summary>
   *     Manual,
   *
   *     /// <summary>Doses will be packaged in strip packaging</summary>
   *     Pouch,
   *
   *     /// <summary>a Med will be packaged in vial(s).</summary>
   *     Vial
   * }
    */

export function convertAuthEnumtoPsisEnum(authEnum) {
    switch (parseInt(authEnum)) {
        case AuthMachineType.ATP:
          return PsisMachineType.POUCH;
        case AuthMachineType.MAX:
          return PsisMachineType.MAX;
        default:
          return null
      }
}

export enum AuthMachineType {
    ATP = 1,
    PASS = 2,
    MAX = 3,
    MINI = 4,
}

export enum PsisMachineType {
    POUCH = 4,
    MAX = 5,
}
