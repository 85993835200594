import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';

export interface State {
  fleetConfig: any;
}

export const initialState: State = {
  fleetConfig: null,
};

const fleetConfigReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.FleetManagerActions.getFTConfiguration , (state, action) => ({
      ...state,
      fleetConfig : action.data
    })
  ),
);

export function reducer(state: State | undefined, action: Action): any {
  return fleetConfigReducer(state, action);
}
