<div>
  <div class="bg-white flex justify-between content-stretch items-stretch xs:flex xs:flex-col flex-row flex-wrap">
    <div class="flex-1">
      <div class="s_rol flex justify-start content-center items-center flex-row">
        <app-search-bar tabindex="-1" (filterChange)="applyFilter({ text: $event })" 
          minLengthToSearch="1" placeholder="Search" inputId="UserManagementSearch" class="m-r-24 grow-0 shrink basis-[200px]"></app-search-bar>
        <div class="m-r-8 flex grow-0 shrink-0 basis-4" style="align-self: center">
          <app-icon icon="filter" aria-label="filter" role="button" class="pointer"></app-icon>
        </div>
        <mat-form-field *ngIf="this.isFilterVisible" class="grow-0 shrink basis-[180px] m-r-16">
          <mat-select id="usr-mgt-roles-select" value="" [(ngModel)]="selectedRoleId" (valueChange)="applyFilter({ roleId: $event })" class="m-t-0">
            <mat-option id="usr-mgt-roles-option-all" value="">All Permission Groups</mat-option>
            <mat-option *ngFor="let role of roles" id="{{'usr-mgt-roles-option-' + role.name.toLowerCase()}}" 
              [value]="role.roleId">{{ role.description }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isOrgLevel && parentOrganization" class="grow-0 shrink basis-[180px] m-r-16">
          <mat-select [(ngModel)]="selectedSiteId" (selectionChange)="onSiteFilterChange()" class="m-t-0">
            <mat-option [value]="parentOrganization.organizationId">{{ parentOrganization.name }}</mat-option>
            <mat-option *ngFor="let site of sites" [value]="site.organizationId">{{ site.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="text-right p-t-15 p-b-15 none flex-1">
        <custom-tooltip text="To add many users at once, download the template, fill in user details and upload completed template."></custom-tooltip>

      <button id="UserManagementDownloadTemplate" class="btn-default m-r-15" type="button">
        <app-icon icon="import" class="m-r-5" color="053ab2"></app-icon>
        Download Template
      </button>
      <button id="UserManagementUploadFile" class="btn-default m-r-15" type="button">
        <app-icon icon="export" class="m-r-5" color="053ab2"></app-icon>
        Upload Template
      </button>
      <button id="UserManagementRemoveSelected" class="btn-default" [class.btn-disabled]="selection.isEmpty()" [disabled]="selection.isEmpty()" (click)="inactiveUsers()" type="button">Inactivate</button>
    </div>

  </div>

  <form (submit)="addUser()" [formGroup]='formGroup' #formDirective="ngForm" style="border-right: 1px solid #fff;">
    <div class="form-section flex justify-start gap-4 content-stretch items-stretch gt-xs:flex gt-xs:flex-row gt-xs:flex-wrap xs:flex xs:flex-col">
      <mat-form-field floatLabel="never" class="um_lname xl:basis-[280px] lg:basis-[300px] md:basis-[280px] sm:basis-[280px] xs:flex-1 lg:order-1 sm:order-1 md:order-1">
        <label for="UserManagementNewLastName">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Last Name</span>
        </label>
        <input matInput id="UserManagementNewLastName" appNoHtmlTags  maxlength="51" formControlName='userLastName'>
        <mat-error *ngIf="formGroup.get('userLastName').hasError('required')">Last Name required</mat-error>
        <mat-error *ngIf="formGroup.get('userLastName').hasError('maxlength')">Last Name cannot exceed 50 characters
        </mat-error>
        <mat-error *ngIf="formGroup.get('userLastName').hasError('pattern')">Last Name cannot contain numbers or symbols
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" class="um_fname xl:basis-[280px] lg:basis-[300px] md:basis-[280px] sm:basis-[280px] xs:flex-1 lg:order-2 sm:order-2 md:order-2">
        <label for="UserManagementNewFirstName">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>First Name</span>
        </label>
        <input matInput id="UserManagementNewFirstName" appNoHtmlTags maxlength="51" formControlName='userFirstName'>
        <mat-error *ngIf="formGroup.get('userFirstName').hasError('required')">First Name required</mat-error>
        <mat-error *ngIf="formGroup.get('userFirstName').hasError('maxlength')">First Name cannot exceed 50 characters
        </mat-error>
        <mat-error *ngIf="formGroup.get('userFirstName').hasError('pattern')">First Name cannot contain numbers or
          symbols</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" class="um_email xl:basis-[280px] lg:basis-[300px] md:basis-[280px] sm:basis-[280px] xs:flex-1 lg:order-3 sm:order-4 md:order-4">
        <label for="UserManagementNewEmail">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Email</span>
        </label>
        <input matInput id="UserManagementNewEmail" type="email" appNoHtmlTags formControlName='userEmail' (blur)="emailExist()" 
          required>
        <mat-error *ngIf="formGroup.get('userEmail').hasError('required')">Email required</mat-error>
        <mat-error *ngIf="formGroup.get('userEmail').hasError('pattern')">Invalid email (username&#64;domain.com)
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" class="um_role xl:basis-[280px] lg:basis-[300px] md:basis-[280px] sm:basis-[280px] xs:flex-1 lg:order-4 md:order-5 sm:order-5">
        <label for="UserManagementNewRole">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Permission Group</span>
        </label>
        <mat-select id="UserManagementNewRole" formControlName='roleId'>
          <mat-option *ngFor="let role of roles" [value]="role.roleId">{{ role.description }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('roleId').hasError('required')">Permission Group required</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="isOrgLevel" floatLabel="never" class="um_role xl:basis-[280px] lg:basis-[300px] md:basis-[280px] sm:basis-[280px] xs:flex-1 lg:order-5 md:order-6 sm:order-6">
        <label for="UserManagementNewSite">
          <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
          <span>Site</span>
        </label>
        <mat-select id="UserManagementNewSite" formControlName='siteId'>
          <mat-option *ngFor="let site of availableSites" [value]="site.organizationId">{{ site.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.get('siteId').hasError('required')">Site is required</mat-error>
      </mat-form-field>

      <div class="text-right usermgmt_control flex gt-md:justify-end gt-md:content-end gt-md:items-end sm:justify-between sm:content-stretch sm:items-stretch xs:flex-1 gt-sm:flex-1 sm:basis-[110px] lg:order-5 md:order-3 sm:order-3">
        <div class="flex flex-1 justify-end items-center content-center sm:flex sm:justify-between sm:content-end sm:items-end sm:flex-col sm:flex-wrap md:flex md:justify-between md:content-end md:items-end md:flex-col md:flex-wrap">
          <a id="AdminClearAll" class="btn-link m-r-20" (click)="reset()" (keydown.enter)="reset()" tabindex="0">Clear All ></a>
          <button id="UserManagementAddNew" class="btn-default btn-sm btn-invite" 
            type="submit" >{{ isIM ? 'Add' : 'Invite User' }}</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- RWD code for usermgmt & permissions -->
<mat-accordion class="usermgmgt_mini">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="arwdwn"></span>
        ACTIVE USERS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="uma_list" *ngFor="let element of dataSource.data;index as i">
      <div *ngIf="element?.enabled && element?.firstName!=undefined" class="matcard flex flex-row" id="{{'matcard'+i}}">
        <div class="basis-4/5">
          <ul class="user_list_mini">
            <li>
              <p class="label">
                Name
              </p>
              <p class="value">
                {{element?.lastName | uppercase}} - {{element?.firstName}}
              </p>
            </li>
            <li>
              <p class="label">
                Email
              </p>
              <p class="value">
                {{element?.email}}
              </p>
            </li>
            <li>
              <p class="label">
                Permission Group
              </p>
              <p class="value">
                {{element?.roleDescription}}
              </p>
            </li>
          </ul>
        </div>
        <div class="flex flex-row justify-end content-center items-center h-full basis-1/5">
          <mat-icon [matMenuTriggerFor]="userMenu" class="pointer menu-trigger">
            <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
          </mat-icon>
          <mat-menu #userMenu="matMenu" backdropClass="custom_drop" xPosition="before">
            <button mat-menu-item (click)="showEditUser(element,i)" [id]="getId(element, 'Edit')" type="button">Edit</button>
            <button mat-menu-item [disabled]="this.loggedEmail == element?.email" (click)="changeUserStatus(element)" id="inactivate" type="button">Inactivate</button>
            <button mat-menu-item *ngIf="isResendInvitationVisible" (click)="resendUserInvitation(element?.userId)" [id]="getId(element, 'Resend')" type="button">Resend Invitation</button>
          </mat-menu>
        </div>
      </div>
      <div class="matedit" *ngIf="element?.enabled && element?.firstName!=undefined" id="{{'matedit'+i}}" style="display: none;">
        <form [formGroup]='editUserFormGroup'class="edit_user xl:flex xl:flex-row">
          <div class="form-section flex justify-start content-stretch items-stretch lg:flex-row md:flex-row md:flex-wrap sm:flex-row sm:flex-wrap xs:flex-col gap-4">
            <div class="md:basis-[30%] sm:basis-[30%] xs:flex-1 sm:order-1 md:order-1">
              <mat-form-field floatLabel="never">
                <label for="Name">
                  <span>Name</span>
                </label>
                <input formControlName='name' matInput appNoHtmlTags id="name" value="{{element?.lastName | uppercase}} - {{element?.firstName}}" aria-readonly="true" tabindex="0" 
                readonly>
              </mat-form-field>
            </div>
            <div class="md:basis-[30%] sm:basis-[30%] xs:flex-1 sm:order-2 md:order-2">
              <mat-form-field floatLabel="never">
                <label for="Email">
                  <span>Email</span>
                </label>
                <input formControlName='email' matInput appNoHtmlTags id="email" value="{{element?.email}}" aria-readonly="true" tabindex="0" 
                readonly>
              </mat-form-field>
            </div>
            <div class="md:basis-[30%] sm:basis-[30%] xs:flex-1 sm:order-4 md:order-4">
              <mat-form-field floatLabel="never">
                <label for="PermissionGroup">
                  <span>Permission Group</span>
                </label>
                <mat-select formControlName='roleId' tabindex="0">
                  <mat-option *ngFor="let role of roles" [value]="role.roleId">{{ role.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="btn-control-sm md:basis-[30%] sm:basis-[30%] xs:flex-1 md:order-3 sm:order-3 sm:flex-col justify-end items-start content-start sm:justify-end sm:items-center sm:content-center">
              <button mat-button class="btn-link sm:order-2" tabindex="0" type="button" aria-label="Cancel" 
                (click)="hideEditUser(i)" (keydown.enter)="cancelEditUser()">Cancel</button>
              <button mat-button class="btn-default btn-sm sm:order-1" tabindex="0" type="button" aria-label="Save" 
                (click)="changeRole()">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel> 
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="arwdwn"></span>
        INACTIVE USERS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="umia_list flex flex-row" *ngFor="let element of dataSource.data;index as i">
      <div *ngIf="!element?.enabled && element?.firstName!=undefined" class="basis-[90%]">
        <ul class="user_list_mini">
          <li>
            <p class="label">
              Name
            </p>
            <p class="value">
              {{element?.lastName | uppercase}} - {{element?.firstName}}
            </p>
          </li>
          <li>
            <p class="label">
              Email
            </p>
            <p class="value">
              {{element?.email}}
            </p>
          </li>
          <li>
            <p class="label">
              Permission Group
            </p>
            <p class="value">
              {{element?.roleDescription}}
            </p>
          </li>
        </ul>
      </div>
      <div class="text-right basis-[10%]" *ngIf="!element?.enabled && element?.firstName!=undefined">
        <mat-icon [matMenuTriggerFor]="userMenu" class="pointer menu-trigger">
          <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
        </mat-icon>
        <mat-menu #userMenu="matMenu" backdropClass="custom_drop" xPosition="before">
          <button mat-menu-item (click)="changeUserStatus(element)" id="reactivate" type="button">Reactivate</button>
        </mat-menu>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<form (submit)="isOrgLevel ? changeUserDetails() : changeRole()" [formGroup]='editUserFormGroup' class="usermgmt" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox class="m-r-0" (change)="$event ? masterToggle() : null" 
          [checked]="selection.hasValue() && isAllSelected" [indeterminate]="selection.hasValue() && !isAllSelected" 
          [aria-label]="checkboxLabel()"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox class="m-r-0" *ngIf="row.enabled && !row.isEdit" (click)="$event.stopPropagation()" 
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" 
          [aria-label]="checkboxLabel(row)" [id]="getId(row, 'Check')">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">Name</th>
      <td mat-cell *matCellDef="let element" [id]="getId(element, 'Name')" [innerHTML]=" getName(element) | noHtml | highlightMatched: filter.text" data-label="Name" tabindex="0"></td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
      <td mat-cell *matCellDef="let element" [id]="getId(element, 'Email')" [innerHTML]=" element?.email | noHtml | highlightMatched: filter.text" data-label="Email" tabindex="0"></td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="roleName">Permission Group</th>
      <td mat-cell *matCellDef="let element" [id]="getId(element, 'Role')" data-label="Role" tabindex="0">
        {{ element?.roleDescription }}</td>
    </ng-container>

    <ng-container matColumnDef="site">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="orgName">Site</th>
      <td mat-cell *matCellDef="let element" [id]="getId(element, 'Site')" data-label="Site" tabindex="0">
        {{ element?.orgName }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="text-right">
        
        <button [matMenuTriggerFor]="userMenu" class="btn-transparent p-0" (click)="$event.stopPropagation()" (keypress.enter)="$event.stopPropagation()" type="button" tabindex="0" [id]="element?.email" aria-label="Action Menu">
          <mat-icon class="pointer menu-trigger">
            <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
          </mat-icon>
        </button>
        
        
        <mat-menu #userMenu="matMenu" backdropClass="custom_drop" xPosition="before">
          <button mat-menu-item *ngIf="element?.enabled" (click)="editUser(element)" [id]="element?.email + '_Edit'" type="button">Edit</button>
          <button mat-menu-item *ngIf="element?.enabled" [disabled]="this.loggedEmail == element?.email" (click)="changeUserStatus(element)" id="inactivate" type="button">Inactivate</button>
          <button mat-menu-item *ngIf="!element?.enabled" (click)="changeUserStatus(element)" id="reactivate" type="button">Reactivate</button>
          <button mat-menu-item *ngIf="element?.enabled && isResendInvitationVisible" (click)="resendUserInvitation(element?.userId)" [id]="getId(element, 'Resend')" type="button">Resend Invitation</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRow(row)"></tr>


    <!-- Group header row and column -->
    <tr mat-row class="row-group" *matRowDef="let row; columns: ['groupHeader']; when: isGroup" 
      (click)="groupHeaderClick(row)"></tr>

    <ng-container matColumnDef="groupHeader">
      <td mat-cell *matCellDef="let group" [id]="group.htmlId" [attr.colspan]="displayedColumns.length" tabindex="0">
        <span class="pointer">
          <app-icon [icon]="group.expanded ? 'arwup' : 'arwdown'" size="12" class="m-r-3 icon_arw"></app-icon>
          <span>{{ group.groupName }}</span>
        </span>
      </td>
    </ng-container>

    <!-- Edit user row and column -->
    <tr mat-row class="row-editing" *matRowDef="let row; columns: editingColumns; when: isEditingRow.bind(this)"></tr>

    <ng-container matColumnDef="editRole">
      <td mat-cell *matCellDef="let row" class="m-width100" [id]="''">
        <mat-form-field floatLabel="never">
          <mat-select formControlName='roleId' tabindex="0" [id]="row?.email + '_Role'">
            <mat-option *ngFor="let role of roles" [value]="role.roleId" [id]="row?.email + '_Role_' + role.description.replace(' ', '_')">{{ role.description }}</mat-option>
          </mat-select>
          <mat-error *ngIf="editUserFormGroup.get('roleId').hasError('required')">Permission Group required</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="editSite">
      <td mat-cell *matCellDef="let row" class="m-width100" [id]="''">
        <mat-form-field floatLabel="never">
          <mat-select formControlName='siteId' tabindex="0" [id]="row?.email + '_Site'">
            <mat-option *ngFor="let site of availableSitesForEditForm" [value]="site.organizationId" [id]="row?.email + '_Site_' + site.name.replace(' ', '_')">{{ site.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="editUserFormGroup.get('siteId').hasError('required')">Site is required</mat-error>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="editActions">
      <td mat-cell *matCellDef="let row" [id]="''" class="text-right">
        <a class="btn-link m-r-20" [id]="row?.email + '_Cancel'" (click)="cancelEditUser()" (keydown.enter)="cancelEditUser()" tabindex="0">Cancel ></a>
        <button type="submit" [id]="row?.email + '_Save'" class="btn-default btn-sm m-t-10 m-b-10" [class.btn-disabled]="!this.editUserFormGroup.valid" [disabled]="!this.editUserFormGroup.valid" tabindex="0">Save</button>
      </td>
    </ng-container>


    <!-- No data row and column -->
    <tr mat-row class="no-data" *matRowDef="let row; columns: ['noDataCheckbox', 'noData']; when: isNoDataRow"></tr>

    <ng-container matColumnDef="noDataCheckbox">
      <td mat-cell *matCellDef></td>
    </ng-container>
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="displayedColumns.length - 1" mat-cell *matCellDef="let row" [id]="getId(row, 'None')" tabindex="0">
        {{ row.noDataText }}
      </td>
    </ng-container>

  </table>

</form>

<div *ngIf="inactivatedUser">
  <div class="badge_undo badge_inactive flex justify-between content-center items-center flex-row">
    <div class="basis-[67%]">User Inactivated</div>
    <div class="undo_close flex flex-row justify-between content-center items-center h-full basis-[33%]">
      <button class="btn-default btn-sm m-r-8" (click)="changeUserStatus(inactivatedUser)" id="undo">Undo</button>
      <app-icon icon="clear" class="pointer btn-transparent btn_icon flex flex-row" id="clear" (click)="inactivatedUser = null"></app-icon>
    </div>
  </div>
</div>
