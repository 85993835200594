import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DeploymentTypeMetadata, FeatureSelectedEventArgs } from '../../models/deploymentForm.model';
import { AuthService } from 'src/app/auth/auth.service';
import { SystemFormService } from '../../services/system-form.service';

/**
 * Component containing a form that is dynamically constructed from a given deployment's metadata.
 * 
 */
@Component({
  selector: 'app-system-form',
  templateUrl: './system-form.component.html',
  styleUrls: ['./system-form.component.scss']
})
export class SystemFormComponent implements OnInit {
  /** The form to use; can be a nested group on a parent form. */
  @Input() form: FormGroup;
  /** The form's associated metadata. */
  @Input() systemFormMetadata: DeploymentTypeMetadata;
  @Input() organizationId: string;
  @Input() recursionDepth?: number = 0;

  @Output() featureSelected = new EventEmitter<FeatureSelectedEventArgs>();

  canEditAdvancedOptions: boolean;
  isExpanded: boolean = true;
  selectedFeatures: string[];

  get featureName(): string { return this.systemFormMetadata.friendlyName; }
  get optionalFeatures(): FormArray { return this.form.get('optionalFeatures') as FormArray; }
  get optionalFeatureNames(): string[] { return this.systemFormMetadata?.optionalFeatures?.map(feature => feature.friendlyName); }

  /** Determines if any dynamic controls are currently being shown to the user */
  get featureControlsShown(): boolean {
    const hasRequiredOptions = this.systemFormMetadata.requiredParameters.length > 0;
    const hasAdvancedOptions = this.systemFormMetadata.optionalParameters.length > 0;
    
    if (this.canEditAdvancedOptions && (hasRequiredOptions || hasAdvancedOptions)) {
      return true;
    }

    if (!this.canEditAdvancedOptions && hasRequiredOptions) {
      return true;
    }

    return false;
  }
  get isParentForm(): boolean { return this.recursionDepth === 0; }

  constructor(
    private authService: AuthService,
    private systemFormService: SystemFormService
  ) { }

  ngOnInit(): void {
    this.authService.profile$.subscribe({
      next: (profile) => {
        this.canEditAdvancedOptions = profile.hasRole("Parata Dev");
      },
      error: (err) => {
        console.error('Could not determine if current user has Parata Dev role, removing advanced options.');
        console.error(err);
        this.canEditAdvancedOptions = false;
      }
    });

    this.selectedFeatures = this.form.value.optionalFeatures?.map(feature => feature.name) || [];
  }

  /**
   * Event handler for optional feature multi-select menu.
   * @param event The DOM event.
   */
  onFeatureSelect(event: any) {
    const featureMetadata = this.systemFormMetadata.optionalFeatures.find(feature => feature.friendlyName === event.source.value);
    const selected = event.source.selected;

    // Toggle feature if the user raised the event
    if (event.isUserInput) {
      if (selected) {
        let feature = this.form.value.optionalFeatures.find(feature => feature.name === featureMetadata.name);
  
        if (!feature) {
          this.optionalFeatures.push(this.getFeatureFormGroup(featureMetadata));
        }
      }
      else {
        let featureIndex = this.optionalFeatures.value.findIndex(feature => feature.name === featureMetadata.name);
        this.optionalFeatures.removeAt(featureIndex);
      }
    }

    this.featureSelected.emit({
      featureMetadata: featureMetadata,
      selected: selected,
      isUserInput: event.isUserInput
    });
  }

  /** Handler to bubble up featureSelected events from recursive components. */
  nestedFeatureSelected(args: FeatureSelectedEventArgs): void {
    this.featureSelected.emit(args);
  }

  /** Gets the component's metadata by name. */
  getOptionalFeature(featureName): DeploymentTypeMetadata {
    return this.systemFormMetadata.optionalFeatures.find(feature => feature.name === featureName);
  }

  /** Return a new FormGroup built from the given metadata with default values. */
  getFeatureFormGroup(feature: DeploymentTypeMetadata): FormGroup {
    return this.systemFormService.getSystemForm(feature);
  }
}
