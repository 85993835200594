import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ScriptManagerData } from 'src/app/external-user/batch-manager/models/script-manager-data';
import { ParataStandardInterfaceService } from 'src/app/external-user/batch-manager/services/parataStandardInterface.service';
import { PsisMachineType } from 'src/app/external-user/batch-manager/services/psis-enum-mapping';
import { MaxPriorityDialogComponent } from '../../../max-priority-dialog/max-priority-dialog.component';
import { VialScriptingComponent } from '../../../vial-scripting/vial-scripting.component';


export interface MenuData {
  name: string;
  id: number;
  type: string;
}

@Component({
  selector: 'app-action-menu-component',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() type = '';
  @Input() row: ScriptManagerData;
  @Input() organizationId: string;
  MenuItems: Array<MenuData>;

  someMethod() {
    this.trigger.openMenu();
  }

  constructor(
    private dialog: MatDialog,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    @Inject(VialScriptingComponent) private parent: VialScriptingComponent) {
    this.InitIncomplete();
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    if (this.type === 'INCOMPLETE')
      {this.InitIncomplete();}
    else
      {this.InitInQueUe();}
  }
  private InitIncomplete() {
    this.MenuItems = [{ name: 'Retry Script', id: 0 , type: this.type },
    { name: 'Remove Script', id: 1, type: this.type }];

  }
  private InitInQueUe() {
    this.MenuItems = [{ name: 'Move To..', id: 0, type: this.type },
    { name: 'Change Priority..', id: 1, type: this.type },
    { name: 'Cancel Script', id: 2, type: this.type }];

  }
  RunMenu(object: MenuData){
    //this.parent.cancelInterval();

    if(object.type == 'INCOMPLETE')
        {this.DoIncomplete(object);}
    else
        {this.DoInQueue(object);}
  }

  private DoInQueue(object: MenuData) {
    if(object.id == 1){
      this.ChangePriority();
    }
    if(object.id == 2){
      this.CancelScript();
    }
  }
  private CancelScript() {
    this.parataStandardInterfaceService.cancelScript(this.organizationId, this.row.scriptID);
    this.parent.getPendingScripts(PsisMachineType.MAX);
  }

  private ChangePriority(){
      const dialogRef = this.dialog.open(MaxPriorityDialogComponent, {
        data: {priority: this.row.priority}
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result)
          {this.setPriority(result.data.priority);}
      });

  }

  private setPriority(priority: number) {
    this.parataStandardInterfaceService.setPriority(this.organizationId, this.row.transactionID, priority);
    this.parent.getPendingScripts(PsisMachineType.MAX);

  }
  private DoIncomplete(object: MenuData) {
    if(object.id == 1){
      this.CancelIncompleteScript();
    }
  }
  private CancelIncompleteScript() {
    this.parataStandardInterfaceService.cancelIncompleteScript(this.organizationId, this.row.scriptID, this.row.transactionID);
    this.parent.getIncompleteScripts(PsisMachineType.MAX);
  }

}


