<div *ngIf="isloaded" class="notify_banner xs:flex xs:flex-col flex flex-row">
  <button class="notification_close" (click)="isloaded = !isloaded" aria-label="Close" type="button"></button>
  <div class="basis-[75%] xs:basis-full sm:basis-[70%] md:basis-[65%]">
    <h6 class="m-0"><img src="../../../../assets/img/icons/ico.16.info.svg" alt="">{{subject}}</h6>
    <p class="m-b-0">{{message}}</p>
  </div>
  <div class="flex basis-[25%] xs:basis-full sm:basis-[30%] md:basis-[35%] justify-end content-center items-center">
    <button class="btn-default btn-sm m-r-10" id="SetupFormOpen" [routerLink]="[reDirectURL, notificationId]" type="button">  
      {{fromActionText}}
    </button>
  </div>
</div>