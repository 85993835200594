import { Component, Input } from '@angular/core';
import { ExpandedLTR, ExpandedRTL } from '../../animation';
import { CONSTANT } from '../../constants';
import { NavItem } from '../../app.model';

@Component({
  selector: 'app-list-item-content',
  templateUrl: './list-item-content.component.html',
  styleUrls: ['./list-item-content.component.css'],
  animations: [ExpandedLTR, ExpandedRTL],
})
export class ListItemContentComponent {
  @Input() node: NavItem;
  @Input() isRtlLayout: boolean;

  getListIcon(node: NavItem): string {
    if (!this.isNullOrUndefinedOrEmpty(node.icon)) {
      return `icon`;
    } else if (!this.isNullOrUndefinedOrEmpty(node.faIcon)) {
      return `faIcon`;
    } else if (!this.isNullOrUndefinedOrEmpty(node.imageIcon)) {
      return `imageIcon`;
    } else if (!this.isNullOrUndefinedOrEmpty(node.svgIcon)) {
      return `svgIcon`;
    } else {
      return ``;
    }
  }

  getHrefTargetType(): string {
    return this.node.hrefTargetType ? this.node.hrefTargetType : CONSTANT.DEFAULT_HREF_TARGET_TYPE;
  }

  getSelectedSvgIcon(): string {
    return this.node.isSelected && this.node.activeSvgIcon ? this.node.activeSvgIcon : this.node.svgIcon;
  }

  getSelectedIcon(): string {
    return this.node.isSelected && this.node.activeIcon ? this.node.activeIcon : this.node.icon;
  }

  getSelectedFaIcon(): string {
    return this.node.isSelected && this.node.activeFaIcon ? this.node.activeFaIcon : this.node.faIcon;
  }

  getSelectedImageIcon(): string {
    return this.node.isSelected && this.node.activeImageIcon ? this.node.activeImageIcon : this.node.imageIcon;
  }

  nodeExpandStatus(): string {
    return this.node.expanded ? 'yes' : 'no';
  }

  private isNullOrUndefinedOrEmpty(object: any): boolean {
    return this.isNullOrUndefined(object) || object === '';
  };

  private isNullOrUndefined(object: any): boolean {
    return object === null || object === undefined;
  };

}
