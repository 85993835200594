import { Injectable } from '@angular/core';
import {
  Embed,
  factories,
  IEmbedConfiguration,
  service as pbiService,
} from 'powerbi-client';

export function powerBiServiceFactory() {
  return new pbiService.Service(
    factories.hpmFactory,
    factories.wpmpFactory,
    factories.routerFactory
  );
}

@Injectable({
  providedIn: 'root',
  useFactory: powerBiServiceFactory
})
export class PowerBiService {
  private powerBiCoreService: pbiService.Service;

  constructor(private service: pbiService.Service) {
      this.powerBiCoreService = service;
  }

  embed(element: HTMLElement, config: IEmbedConfiguration): Embed {
    return this.powerBiCoreService.embed(element, config);
  }
}