import { NgModule } from '@angular/core';
import { UserModule } from './user/user.module';
import { CommonRoutingModule } from './common-routing.module';
import { CustomerSurveyModule } from './customer-survey/customer-survey.module';
import { PermissionGroupsModule } from './permission-groups/permission-groups.module';

@NgModule({
  declarations: [],
  imports: [
    UserModule,
    CommonRoutingModule,
    CustomerSurveyModule,
    PermissionGroupsModule
  ],
  exports: [
    UserModule,
    CustomerSurveyModule,
    PermissionGroupsModule
  ]
})
export class CommonModules { }
