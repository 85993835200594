import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { Report } from '../../models/report';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { environment } from 'src/environments/environment';
import { LaunchDarklyService } from '../../../../shared/services/launch-darkly.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { Notification } from '../../../../shared/models/notification';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-report-subscription',
  templateUrl: './report-subscription.component.html',
  styleUrls: ['./report-subscription.component.scss'],
})
export class ReportSubscriptionComponent implements OnInit {
  //#region Fields
  reportUrl = environment.reportUrl;
  reportsTableColumns: string[] = ['name', 'description', 'select', 'link'];
  dataSource = new MatTableDataSource<Report>();
  reports: Report[] = [];
  filteredReports: Report[] = [];
  enableConsumable = false;
  selection = new SelectionModel<Report>(true, []);
  filterText = '';
  //#endregion

  //#region  constructor & ngOnInit
  constructor(
    public router: Router,
    private reportService: ReportService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    public launchDarklyService: LaunchDarklyService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.getPermittedReports();
    this.launchDarklyService.flagChange.subscribe((flags) => {
      this.enableConsumable = (flags.CustomerManagement_ConsumablesSubscription);
    });
  }

  //#endregion

  //#region  functions
  getPermittedReports(): void {
    this.ngxLoader.start();
    this.reportService.getAll().subscribe(resp => {
      this.reports = resp;
      if (this.reports.length === 0) {
        this.toastService.openToast('No Permitted Report found!', constants.ToastPanelType.warning);
      }
      this.filterReports(null);
      this.ngxLoader.stop();
    }, (error: HttpErrorResponse) => {
      this.reports = [];
      this.filterReports(null);
      this.toastService.openToast('An error occurred while loading reports!', constants.ToastPanelType.error);
      console.error(error);
      this.ngxLoader.stop();
    },
    );
  }

  updateSubscription(): void {
    const userSubscribedReport = this.reports.filter(item => item.isSubscribed).map(({ reportId }) => reportId);
    this.reportService.update(userSubscribedReport).subscribe(
      () => this.toastService.openToast('Subscription Updated!', constants.ToastPanelType.done),
      () => this.toastService.openToast('Subscription Updation Failed!', constants.ToastPanelType.error),
    );
  }

  toggleSubscription(event, dataSource): void {
    this.reports.forEach((rep, i) => {
      if (rep.reportId === dataSource.reportId) {
        this.reports[i].isSubscribed = event.checked;
      }
    });
    this.updateSubscription();
  }

  filterReports(value) {
    if (value) { this.filterText = value; }
    else { this.filterText = ''; }
    this.filteredReports = value ? this.reports.filter((report) =>
      report.reportName.toLowerCase().includes(value.toLowerCase()) ||
      report.reportId.toLowerCase().includes(value.toLowerCase()),
    ) : this.reports;
    this.sortReports();
  }

  sortReports() {

    this.filteredReports = this.filteredReports.sort((a, b) => { return (a.reportName.toLocaleLowerCase() < b.reportName.toLocaleLowerCase() ? -1 : 1); });
   
    this.dataSource = new MatTableDataSource(this.filteredReports);
  }

  keypressreport(reportId) {
    this.router.navigate(['/reports', reportId])
  }
}
