import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';
import { PermissionGroup } from '../models/permission-group';
import { Role, RolePostRequest } from '../models/role';

@Injectable({
  providedIn: 'root'
})

export class PermissionGroupsService extends DataService<PermissionGroup>{

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}CustomerPermissionGroup/`, http);
  }

  getPermissionGroup(orgId: string, roleId: string): Observable<PermissionGroup[]> {
    return this.http.get<PermissionGroup[]>(`${this.url}${roleId}/${orgId}`);
  }

  getRoles(orgId: string): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.url}Role/${orgId}`);
  }

  getDefaultPermissionGroups(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.url}DefaultPermissionGroups`);
  }

  getDefaultFeature(): Observable<PermissionGroup[]> {
    return this.http.get<PermissionGroup[]>(`${this.url}DefaultFeature`);
  }

  savePermissionGroup(rolePostRequest: any) {
    return this.http.post(`${this.url}`, rolePostRequest);
  }

  updatePermissionGroup(rolePostRequest: any) {
    return this.http.put(`${this.url}`, rolePostRequest);
  }

  getImpactedMembers(orgId: string, roleId: string) {
    return this.http.get<any>(`${this.url}UserCount/${roleId}/${orgId}`);
  }

  deactivatePermissionGroup(orgId: string, roleId: string) {
    return this.http.delete(`${this.url}${roleId}/${orgId}`);
  }

}
