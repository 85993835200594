import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { selectParentOrganizations } from 'src/app/store/selectors/organizations.selector';
import { Subscription } from 'rxjs';

/** 
 * Encapsulates logic for an autocomplete control that selects an available organization. 
 * Form value is the customer name is SalesForce.
 */
@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss']
})
export class OrganizationSelectComponent implements OnInit, OnChanges, OnDestroy {
  /** The parent form this control is a part of. */
  @Input() form: FormGroup;
  /** Name of the form control to bind to on the parent form. */
  @Input() orgControlName: string;
  /** Determines whether the backing controls are enabled/disabled. */
  @Input() disabled: boolean;

  constructor(
    private store: Store<State>,
    private toastService: ToastService
  ) { }

  /** NgRx subscription to organization state. */
  orgSubscription: Subscription;
  /** Full list of available organizations. */
  organizations: Organization[];
  /** Filtered list of organizations to display in autocomplete control. */
  filteredOrg: Organization[];

  /** The form control associated with this component. */
  get orgSelectControl() { return this.form.get(this.orgControlName) as FormControl<string>; }

  ngOnInit(): void {
    this.orgSubscription = this.store.select(selectParentOrganizations).subscribe({
      next: (parentOrganizations) => {
        this.organizations = parentOrganizations;

        this.orgSelectControl.addValidators(this.validateOrg().bind(this));

        this.orgSelectControl.valueChanges.subscribe({
          next: value => {
            this.filteredOrg = this.filterOrgs(value || '');
          }
        });

        if (this.orgSelectControl.value) {
          this.orgSelectControl.setValue(this.orgSelectControl.value);
        }
        else {
          this.filteredOrg = this.filterOrgs('');
        }
      },
      error: (err) => {
        console.error(err);
        this.toastService.openToast('Error loading organization list.', constants.ToastPanelType.error);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.disabled ? this.orgSelectControl.disable() : this.orgSelectControl.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
  }

  /** Custom validator for organization form data. */
  validateOrg(): ValidatorFn {
    return (control: AbstractControl<string, string>): ValidationErrors | null => {
      const orgControlValue = control.value;

      if (!orgControlValue) {
        return null;
      }

      if (!this.organizations || this.organizations.length === 0) {
        return { NoOptionsError: true };
      }

      if (!this.organizations.find(org => org.customerNo === orgControlValue)) {
        return { InvalidOptionError: true };
      }

      return null;
    }
  }

  private filterOrgs(orgName: string): Organization[] {
    if (!orgName) {
      return this.organizations;
    }

    return this.organizations.filter(org => org.customerNo.toLowerCase().includes(orgName.toLowerCase()));
  }
}
