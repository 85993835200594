import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as constants from 'src/app/config/app-constants';
import { LoggerService } from '../shared/services/logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Profile } from '../shared/models/auth/profile';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js/src/global';
import * as storage from '../store/state/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth0Domain,
      client_id: environment.auth0ClientID,
      audience: environment.auth0Audience
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((error: HttpErrorResponse) => {
      let errorMessage: string;
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `${error.status}\nMessage: ${error.message}`;
      }
      this.loggerService.logError('Error in createAuth0Client:', errorMessage);
      return throwError(error);
  })
  );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => {
      this.loggedIn = res;
      this.loggerService.logInfo('User Authenticated: ', res ? 'True' : 'False');
    })
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  profile$: Observable<Profile> = this.userProfileSubject$.asObservable().pipe(
    filter(profile => !!profile),
    map(profile => new Profile(profile)),
  );
  // Create a local property for login status
  loggedIn: boolean = null;

  private scheduleReGetTokenTimeout = null;

  constructor(private router: Router, private loggerService: LoggerService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        if (user) {
          sessionStorage.setItem(constants.sessionKeys.FAMILY_NAME, btoa(user.family_name));
          this.loggerService.logInfo('Logged in User email: ' + user.email, '');
          this.userProfileSubject$.next(user);
        }
      })
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options: GetTokenSilentlyOptions = {}): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      tap(() => this.scheduleReGetToken()),
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          this.loggerService.logInfo('Logged in successfully!', '');
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        this.loggerService.logInfo('Login failed!', '');
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
}


  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.deleteAllCookies();
    storage.clearStorage(); // Clear storage on login
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath },
        prompt: 'login'
      });
    },
    catchError((error: HttpErrorResponse) => {
      let errorMessage: string;
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
      }
      this.loggerService.logError('login Error: ' ,errorMessage);
      return throwError(error);
  })
    );
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() =>
          // Redirect callback complete; get user and login status
           combineLatest([
            this.getUser$(),
            this.isAuthenticated$
          ])
        )
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        console.info('Login successful!');
        this.router.navigate([targetRoute]);
      },
      catchError((error: HttpErrorResponse) => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
      }
      this.loggerService.logError('authComplete Error: ',errorMessage);
      return throwError(error);
  })
      );
    }
  }

  logout() {
    sessionStorage.removeItem(constants.sessionKeys.REMIND_LATER);
    sessionStorage.removeItem(constants.sessionKeys.POPUP_RELOAD_TIME);
    sessionStorage.removeItem(constants.sessionKeys.FAMILY_NAME);
    this.deleteAllCookies();

    // Clear the client store(state management) on logout
    storage.clearStorage();

    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.auth0ClientID,
        returnTo: window.location.origin
      });
      this.loggerService.logInfo('User loggedout!', '');
      setTimeout(() => this.login(), 300);
    });
  }

  /**
   * Call get token after amount of time no activity to prevent timeout
   */
  private scheduleReGetToken() {
    if (this.scheduleReGetTokenTimeout) {
      clearTimeout(this.scheduleReGetTokenTimeout);
      this.scheduleReGetTokenTimeout = null;
    }

    const timeout = 5 * 60 * 1000; // 5 minutes
    this.scheduleReGetTokenTimeout = setTimeout(
      () => this.getTokenSilently$({ ignoreCache: true }).subscribe()
      , timeout);
  }
}
