import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionGroupsRoutingModule } from './permission-groups-routing.module';
import { PermissionGroupsComponent } from './components/permission-groups/permission-groups.component';
import { DeletePermissionGroupDialogComponent } from './components/delete-permission-group-dialog/delete-permission-group-dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [PermissionGroupsComponent, DeletePermissionGroupDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    PermissionGroupsRoutingModule,
  ],
  exports: [
    PermissionGroupsComponent
  ],schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class PermissionGroupsModule { }
