import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";

import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { getDispenseRatio } from 'src/app/store/selectors/pharmacy-peek.selector';
import { State } from 'src/app/store/state';
import { ViewStoreService } from '../../../services/view-type.service';


@Component({
  selector: 'app-dispense-ratio',
  templateUrl: './dispense-ratio.component.html',
  styleUrls: ['./dispense-ratio.component.scss']
})

export class DispenseRatioComponent implements OnInit, AfterViewInit, OnDestroy {
  noDataExistsForDR = true;
  dispenseRatioRowCount = 3;
  displayedColumns: string[] = ['canisterRatio', 'trayRatio', 'deviceId'];
  dataSource: any[];
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.DISPENSE_RATIO_SITE,
    deviceId: ''
  }

  constructor(public store: Store<State>,
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
  ) { }

  ngOnInit(): void {

    this.store.select(getDispenseRatio).subscribe(res => {
      if (res) {
        let resData = [...res];
        resData = resData.sort((a, b) => {
          if (a.trayRatio < b.trayRatio) { return 1; }
          if (a.trayRatio > b.trayRatio) { return -1; }
          return 0;
        });
        this.dataSource = resData.slice(0, this.dispenseRatioRowCount);
        this.noDataExistsForDR = false;
      }
    }
    );

    this.subscription = this.signal.getDataStream<string>(SignalEventType.DISPENSE_RATIO_SITE)
    .pipe(auditTime(1000))
    .subscribe((dataObj) => {
      const data: any = Object.assign({}, dataObj.data);
      if (typeof data?.devices === 'string') {
        data.devices = JSON.parse(data.devices);
      }
      this.store.dispatch(PharmacyPeekActions.updateDispenseRatio({ data: data }));
      console.log(data);
    })

    this.callDispensingRatios();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  private callDispensingRatios(deviceId?: any) {
    this.viewStoreService.getDispenseRatioAllSystem().subscribe(
      (response) => {
        this.noDataExistsForDR = false;
        this.store.dispatch(PharmacyPeekActions.getDispenseRatio({ data: response }));
      },
      (error) => {
        console.log('dispenseRatioService error:', error);
        if (error.status === 404) {
          this.loggerService.logWarning('Dispense Ratio Service: No Data: ', error);
        } else {
          this.toastService.openToast('Dispense Ratio service load error!', ToastPanelType.error);
        }
      }

    );
  }

}
