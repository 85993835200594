import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import QueueData, { ScriptManagerData } from '../../../models/script-manager-data';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { TitleCasePipe } from '@angular/common';
import { MaxPriorityDialogComponent } from '../../vial/max-priority-dialog/max-priority-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { QueuePriorityEnum } from '../../../models/pending-script-priority-enum';
import {LegacyTooltipPosition as TooltipPosition} from '@angular/material/legacy-tooltip';
import { DispensingScriptDetailComponent } from '../../vial/dispensing-script-detail-dialog/dispensing-script-detail-dialog.component';
import { ParataStandardInterfaceService } from '../../../services/parataStandardInterface.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';


interface ScriptManagerTableColumns {
  field: string;
  header: string;
  highlightMatched: boolean;
  icon?: string;
}


@Component({
  selector: 'app-script-manager-table-rpt',
  templateUrl: './script-manager-table-rpt.component.html',
  styleUrls: ['./script-manager-table-rpt.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class ScriptManagerTableRptComponent {

  @Input() inventories: QueueData[];
  @Input() filterText;
  @Input() loading;
  @Input() organizationId: string;
  @Input() isComplete: boolean ;
  @Output() loadInventories: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('scriptManagerTable', { static: false }) private scriptManagerRef;
  @ViewChild('fixedHeader', { static: false }) private fixedHeaderRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();

  expandedElement: any;

  QueuePriority = QueuePriorityEnum;
  panelOpenState: boolean;


  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth > 900) {

    }
  }

  constructor(
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  onExpandRow(row: any) {
    row.isExpanded = !row.isExpanded;
  }
  onOpenDispensingDetail(event, row: ScriptManagerData, type: string){
    if (event.target.classList.contains('mat-button') || event.target.alt ==='Menu' || type === 'IN QUEUE')
    {return;}

    const dialogRef = this.dialog.open(DispensingScriptDetailComponent, {
      data: row,
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  getEnumValue(value: string) {
    const tt= QueuePriorityEnum;
   // eslint-disable-next-line max-len
   const str =  Object.keys(tt).filter(k => typeof (tt as any)[k] === value) as any ;

  }
}
