import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSurveyComponent } from './components/customer-survey/customer-survey.component';
import { SharedModule } from '../../shared/shared.module';
import { CustomerSurveyRoutingModule } from './customer-survey-routing.module';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';

@NgModule({
  declarations: [CustomerSurveyComponent],
  imports: [
    CommonModule,
    SharedModule,
    CustomerSurveyRoutingModule,
    MatSlideToggleModule
  ],
  exports: [
    CustomerSurveyComponent
  ],
})
export class CustomerSurveyModule { }
