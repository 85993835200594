<h2 mat-dialog-title class="m-b-0 !flex flex-row xl:hidden lg:hidden md:hidden sm:hidden xs:block">
    <div class="uppercase flex-1">
        Pharmacy Peek Management
    </div>
    <div class="flex flex-row justify-end content-center items-center h-full">
        <button class="btn-transparent btn_icon" type="button" aria-label="close" (click)="openPharmacyPeekDiscardChangesDialog()"> <!-- mat-dialog-close -->
            <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="">
        </button>
    </div>
</h2>

<div class="flex flex-col xl:hidden lg:hidden md:hidden sm:hidden xs:block">
    <mat-button-toggle-group name="fontStyle" role="radiogroup" tabindex="0">
        <mat-button-toggle value="1" checked="checked" role="radio" (change)="radioChange($event)" tabindex="0" aria-checked="true">
            PP Widgets
        </mat-button-toggle>
        <mat-button-toggle value="2" role="radio" (change)="radioChange($event)" tabindex="-1" aria-checked="false">
            {{ppList}}
        </mat-button-toggle>
    </mat-button-toggle-group>

    <div *ngIf="selected=='1'" class="pp_content">
        <app-pharmacy-peek-widget></app-pharmacy-peek-widget>
    </div>
    <div *ngIf="selected=='2'" class="pp_content">
        <app-pharmacy-peek-list></app-pharmacy-peek-list>
    </div>
    <div class="btn_controls flex flex-row justify-end content-stretch items-stretch w-full">
        <button mat-button class="btn-default btn-md" (click)="openPharmacyPeekDiscardChangesDialog()" type="button">Cancel</button>
        <button mat-button class="btn-primary btn-md m-l-16" mat-dialog-close type="button">Done</button>
    </div>
</div>

