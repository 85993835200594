import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private httpClient: HttpClient;
  private url: string;

  constructor(http: HttpClient) {
    this.url = environment.apiUrl.fileUploadService;
    this.httpClient = http;
  }

  upload(file, url = this.url, responseType: any = 'text'): Observable<any> {
    const formData: any = new FormData();
    formData.append('file', file);
    return this.httpClient.post(this.url, formData, {
      responseType,
    });
  }

  uploadUserFile(file): Observable<any> {
    return this.upload(file, `${this.url}/User`, 'json');
  }
}
