<mat-card>
  <mat-card-title class="flex justify-center content-stretch items-stretch">Pet Medication Spreadsheet Upload </mat-card-title>
  <mat-card-content class="flex justify-center content-stretch items-stretch">
    <div class="file-upload">
    <div class="selected-file"  *ngIf ="fieleSelect">
      <mat-icon class="selected-file-icon">description</mat-icon>
      <span class="selected-file-name">{{fielName}}</span>
      <mat-icon class="selected-file-clear" (click)="selectedFileRemove()">clear</mat-icon>
    </div>
    <div class="meter" *ngIf ="fieleSelect && percentage>0">
      <p>Uploading({{fielName}})</p>

      <span  [style.width]="percentage+'%'" [style.background-color]="backgroundcolor">{{percentage}}%</span>
    </div>
    <div class="choose_file_pet"  *ngIf ="!fieleSelect">
      <label for="choose_file_pet">
        <input type="file" id="choose_file_pet" accept=".csv, .xls, .xlsx" (change)="onFileSelected($event)" #fileUploadSynMed>
        <span>Choose File ></span>
      </label>
    </div>
</div>
 </mat-card-content>
  <mat-card-footer class="flex justify-center content-stretch items-stretch">
    <button *ngIf="percentage==0" [ngClass]="{'btn':!fieleSelect, 'btn-active':fieleSelect}" [disabled]="!fieleSelect" (click)="onUpload()">Upload</button>
  </mat-card-footer>
</mat-card>
