import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightMatched'
})
export class HighlightMatchedPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, search: string): any {
    if (value && search) {
      const start = value.toLowerCase().indexOf(search.trim().toLowerCase());
      const end = search.length + start;

      if (start === -1) {
        return value;
      }

      const highlightedText = `${value.slice(0, start)}<mark>${value.slice(start, end)}</mark>${value.slice(end)}`;
      return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }
    return value;
  }
}
