import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { PouchCountChartData } from '../../../models/pouch-count-chart-data';

@Component({
  selector: 'app-pouch-count-chart',
  templateUrl: './pouch-count-chart.component.html',
  styleUrls: ['./pouch-count-chart.component.scss'],
})
export class PouchCountChartComponent implements OnChanges {

  @Input() avgHours;
  @Input() data: PouchCountChartData[] = [];

  @ViewChild('chart', { static: true }) chartRef: ElementRef<HTMLElement>;

  private svg;
  private tooltip;
  private chartRatio = 1.6;
  private margin = { top: 5, bottom: 20, left: 0, right: 0 };
  private width: number;
  private height: number;
  private outerWidth: number;
  private outerHeight: number;
  private xScale: any;
  private yScale: any;
  private maxValue: any;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.outerWidth = 283 ;// this.chartRef.nativeElement.clientWidth;
      this.outerHeight = 107; //this.chartRef.nativeElement.clientWidth / this.chartRatio;
      this.width = this.outerWidth - (this.margin.left + this.margin.right);
      this.height = this.outerHeight - (this.margin.top + this.margin.bottom);
      this.chartRef.nativeElement.style.height = `${this.outerHeight}px`;
      
      this.createSvg();
      this.drawAxis();
      this.drawBars();
      this.drawYAxisLabel();
    }, 100);
  }

  private createSvg(): void {
    d3.select(this.chartRef.nativeElement)
      .selectAll('*').remove();

    this.svg = d3.select(this.chartRef.nativeElement)
      .append('svg')
      .attr('width', this.outerWidth)
      .attr('height', this.outerHeight)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    const { top, left } = this.chartRef.nativeElement.getBoundingClientRect();
    this.tooltip = d3.select(this.chartRef.nativeElement)
      .append('div').attr('class', 'tooltip-area')
      .style('transform', `translate(${left}px, ${top}px)`)
      .style('opacity', 0);

    this.xScale = d3.scaleBand()
      .range([15, this.width])
      .domain(this.data.map(d => d.time))
      .padding(0.3)
      .paddingInner(0.3)
      .paddingOuter(0.6);

    const dataMax: any = this.data;
    const maxValue = dataMax.reduce((value, { count }) => Math.max(count, value), dataMax[0]?.max || 10);

    this.maxValue = Math.ceil(maxValue / 100) * 100;
    this.yScale = d3.scaleLinear()
      .range([this.height, 0])
      .domain([0, this.maxValue]);

  }

  private drawAxis(): void {
    const step = (this.maxValue) / 4;
    const linesX = this.maxValue ?
      [...Array((this.maxValue) / step + 1).keys()].map((i) => i * (step)) : [0];

    this.svg.selectAll()
      .data(linesX)
      .enter()
      .append('line')
      .attr('x1', 20)
      .attr('y1', d => this.yScale(d))
      .attr('x2', this.width - 10)
      .attr('y2', d => this.yScale(d))
      .style('stroke-width', 1)
      .style('stroke', (d) => d ? '#8e8e8e' : '#525252')
      .style('fill', 'none')
      .style('opacity', 0.2)
      .style('shape-rendering', 'crispEdges');

    // draw text on x axis
    const xLabels = this.data.length > 7
      ? this.data.filter((d, i) => i % 2 === 0 && i !== 22||  i === this.data.length - 1 )
      : this.data;

    this.svg.selectAll()
      .data(xLabels)
      .enter()
      .append('text')
      .attr('x', d => this.xScale(d.time))
      .attr('y', this.height + 15)
      .style('font-size', '10px')
      .style('font-weight', '400')
      .style('text-anchor', 'middle')
      .style('fill', '#595959')
      .attr('dx', this.xScale.bandwidth() / 2)
      .text(d => d.time);
  }

  private drawBars(): void {
    const topRoundedBar = d => {
      const y = this.yScale(d.count);
      const round = this.xScale.bandwidth() / 4;
      return this.roundedRect(
        this.xScale(d.time),
        y,
        this.xScale.bandwidth(),
        this.height - y,
        (this.height - y > round) ? round : 0,
        { bl: false, br: false },
      );
    };

    const mouseover = (event, d) => {
      d3.select(event.currentTarget).attr('fill', '#191970');
      this.tooltip.style('opacity', 1).html(d.tooltip);
    };

    const mouseleave = (event, d) => {
      d3.select(event.currentTarget).attr('fill', '#2a84fa');
      this.tooltip.style('opacity', 0).html('');
    };

    const mousemove = (event, d) => {
      const isNearRight = this.xScale(d.time) > this.width / 3 * 2;
      const [x, y] = d3.pointer(event, document);
      this.tooltip
        .style('opacity', 1)
        .style('transform', `translate(calc(${isNearRight ? '-100%' : '-50%'} + ${x}px), calc(-100% + ${y - 3}px))`)
        .html(d.tooltip);
    };

    this.svg.selectAll()
      .data(this.data)
      .enter()
      .append('path')
      .attr('d', topRoundedBar.bind(this))
      .attr('fill', '#2a84fa')
      .style('cursor', 'pointer')
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
      .on('mouseover', mouseover);
  }

  private drawYAxisLabel(): void {

    const step = Math.ceil(this.maxValue / 4);
    const linesY = [...Array(Math.round(this.maxValue  / step + 1)).keys()].map((i) => {
      return (i === 0 ? '' :
        ((i * step ) / 100) * 100)
    });

    this.svg.selectAll()
      .data(linesY)
      .enter()
      .append('text')
      .attr('x', 10)
      .attr('y', c => this.yScale(c))
      .style('font-size', '10px')
      .style('font-weight', '400')
      .style('text-anchor', 'middle')
      .style('fill', '#595959')
      .attr('dy', '0.4em')
      .text(c => this.kFormatter(c));

  }


  kFormatter(num) {
    return num > 999 ? (Math.ceil(num / 100) / 10) + 'k' : num
  }

  /**
   * Return path of rounded rectangle
   * this function should be placed in helper
   */
  roundedRect(x, y, w, h, r = 0, rounds = {} as any) {
    const {
      tl = true, tr = true,
      bl = true, br = true,
    } = rounds;
    return [
      `M${x + r},${y}`,
      `h${w - 2 * r}`,

      tr ? `a${r},${r} 0 0 1 ${r},${r}` : `h${r}v${r}`,
      `v${h - 2 * r}`,

      br ? `a${r},${r} 0 0 1 ${-r},${r}` : `v${r}h${-r}`,
      `h${2 * r - w}`,

      bl ? `a${r},${r} 0 0 1 ${-r},${-r}` : `h${-r}v${-r}`,
      `v${2 * r - h}`,

      tl ? `a${r},${r} 0 0 1 ${r},${-r}` : `v${-r}h${r}`,
      'z',
    ].join('');
  }
}
