import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { InventoryStock } from '../models/inventory-stock';
import { Observable } from 'rxjs';
import { CurrentStock } from '../models/current-stock';

@Injectable({
    providedIn: 'root'
})
export class InventoryService extends DataService<InventoryStock> {
    httpClient: HttpClient;
    constructor(http: HttpClient) {
        super(environment.apiUrl.inventoryService, http);
        this.httpClient = http;
    }
    getInventoryStockByOrg(machineTypeId: number) {
        return this.getByID(machineTypeId, `${environment.apiUrl.inventoryService}inventory/PM/`);
    }
    updateInventoryStockThreshold(orgId: string, machineTypeId: number, resource: InventoryStock) {
        return this.updateById(`${orgId}/` + `${machineTypeId}`, resource, `${environment.apiUrl.inventoryService}inventory/`);
    }
    createStockAndOrderDetails(resource: any) {
        return this.create(resource, `${environment.apiUrl.inventoryService}inventory/CreateInventoryWIthStocks`);
    }
    getInventoryStockByOrgAndMachineType(orgId: string,machineTypeId: number){
        return this.getByID(`${orgId}/` + `${machineTypeId}`, `${environment.apiUrl.inventoryService}inventory/`);
    }
    getCurrentStockByOrgAndMachineType(orgId: string,machineTypeId: number) {
        return this.getByID(`${orgId}/` + `${machineTypeId}`, `${environment.apiUrl.inventoryService}stockdetails/CurrentStock/Active/`);
    }
    getAutoOrderByOrgAndMachineType(orgId: string,machineTypeId: number) {
        return this.getByID(`${orgId}/` + `${machineTypeId}`, `${environment.apiUrl.inventoryService}stockdetails/AutoOrder/Active/`);
    }
    UpdateInventoryAndAutoAutoOrder(orgId: string,machineTypeId: number,resource: any){
        return this.updateById(`${orgId}/` + `${machineTypeId}`, resource, `${environment.apiUrl.inventoryService}inventory/UpdateInventoryWithStocks/`);

    }
}
