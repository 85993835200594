import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, Subscription, switchMap } from 'rxjs';
import { selectSitesByParentCustomerNo } from 'src/app/store/selectors/organizations.selector';

/** 
 * Encapsulates logic for an autocomplete control that selects an available site. 
 * Form value is the SalesForce customer site number as entered in customer management.
 */
@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss']
})
export class SiteSelectComponent implements OnInit, OnChanges, OnDestroy {
  /** Parent organization ID to filter sites on. */
  @Input() organizationId: string;
  /** The parent form this control is a part of. */
  @Input() form: FormGroup;
  /** Name of the form control to bind to on the parent form. */
  @Input() controlName: string;
  /** Determines whether the backing controls are enabled/disabled. */
  @Input() disabled: boolean;

  /** Subject to emit changes whenever the parent organization is changed. */
  parentOrgSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  /** NgRx subscription to site state. */
  siteSubscription: Subscription;
  /** Observable collection of available sites. */
  sites$: Observable<Organization[]>;
  /** Non-observable list of the latest available sites, used for validation. */
  sites: Organization[];
  /** Filtered list of sites to display in autocomplete control. */
  filteredSites$: Observable<Organization[]>;
  /** Flag to indicate whether asynchronous initialization has completed. */
  initialized: boolean = false;

  /** The form control associated with this component. */
  get siteSelectControl() { return this.form.get(this.controlName) as FormControl<string>; }

  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.siteSelectControl.addValidators(this.validateSite().bind(this));

    // Setup observable pipeline to update the available site list whenever the parent org changes
    this.sites$ = this.parentOrgSubject.asObservable().pipe(
      switchMap(orgId => this.store.select(selectSitesByParentCustomerNo(orgId))),
      shareReplay(1)
    );

    // Setup observable pipeline to filter sites whenever the available sites change or the user updates the control value
    this.filteredSites$ = combineLatest([
      this.siteSelectControl.valueChanges.pipe(startWith(this.siteSelectControl.value || '')),
      this.sites$
    ]).pipe(
      map(([controlValue, sites]) => sites.filter(site => {
        if (!site.customerNo) {
          return false;
        }
        if (site.customerNo?.toLowerCase()?.includes(controlValue.toLowerCase())) {
          return true;
        }
        if (site.name.toLowerCase().includes(controlValue.toLowerCase())) {
          return true;
        }
        return false;
      }))
    );

    // Subscribe to receieve latest available sites to use for validation
    this.siteSubscription = this.sites$.subscribe(sites => {
      this.sites = sites;
      this.siteSelectControl.setValue(this.siteSelectControl.value, { emitEvent: false });
    });

    if (this.organizationId) {
      this.parentOrgSubject.next(this.organizationId);
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['organizationId'] && (changes['organizationId'].currentValue !== changes['organizationId'].previousValue)) {
      if (this.initialized) {
        this.siteSelectControl.setValue('');
        this.parentOrgSubject.next(changes['organizationId'].currentValue || '');
      }
    }

    if (changes['disabled']) {
      this.disabled ? this.siteSelectControl.disable() : this.siteSelectControl.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  /** Display logic for textbox control. */
  displaySite(siteId: string): string {
    return this?.sites?.find(site => site.customerNo === siteId)?.name || siteId;
  }

  /** Custom validator for site form data. */
  validateSite(): ValidatorFn {
    return (control: AbstractControl<string, string>): ValidationErrors | null => {
      const siteControlValue = control.value;

      if (!siteControlValue) {
        return null;
      }

      if (!this.sites || this.sites.length === 0) {
        return { NoOptionsError: true };
      }

      if (!this.sites.find(site => site.customerNo === siteControlValue)) {
        return { InvalidOptionError: true };
      }

      return null;
    }
  }
}
