import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService
  ) {}

  handleError(error: HttpErrorResponse) {
    this.ngxLoader.stop();
    console.log(error);
    const errorMessage = error.error.message ?? 'Something went wrong!!';
    this.toastService.openToast(errorMessage, constants.ToastPanelType.error, 2);
  }
}
