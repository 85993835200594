import { createAction, props } from '@ngrx/store';

export const GET_ASSET_BY_ORG_ID = '[Assets] Assets By Org Id';
export const SAVE_LAUNCHDARKLY = '[LaunchDarkly] Save LaunchDarkly Flags';


export const getAssetByOrgId = createAction(
  GET_ASSET_BY_ORG_ID,
  props<{assets: any; machinePermissions: any, authProfile: any}>()
);

export const saveLaunchDarklyFlags = createAction(
  SAVE_LAUNCHDARKLY,
  props<{flags: any}>()
);
