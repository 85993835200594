<div class="flex flex-row">
  <div class="xs:basis-full basis-[95%]">
    <ul class="breadcrumb flex justify-start content-stretch items-stretch flex-row" tabindex="0">
      <li class="default" id="ParentOrganization" tabindex="0">
        <span *ngIf="parentOrgName" class="b_info">
          <span class="svgicon icon_enterprise"></span>
          <span class="org_mac" matTooltip="{{ parentOrgName }}"  matTooltipPosition="below">{{ parentOrgName }}</span>
        </span>
      </li>
      <li [ngClass]="view === View.SITE ? 'active' : 'default'" id="SiteOrganization" tabindex="0">
        <span class="b_info">
          <span class="svgicon icon_pharmacy"></span>
          <span class="org_mac" *ngIf="selectedSite" matTooltip="{{ selectedSite.name }}">{{ selectedSite.name }}</span>
        </span>
        <button mat-icon-button [matMenuTriggerFor]="menusites" id="siteSelectButton" type="button" title="Dropdown Arrow">
          <mat-icon>
            <img src="../../../../../assets/img/icons/ico.16.arwdown.svg" alt="">
          </mat-icon>
        </button>
        <mat-menu #menusites="matMenu" backdropClass="breadcrumb_drop" xPosition="before">
          <ng-container *ngFor="let site of sites;">
            <button mat-menu-item (click)="setSite(site)" type="button" class="flex justify-between content-center items-center">
              <span [ngClass]="(selectedSite && selectedSite.organizationId == site.organizationId) ? 'text-activebdd' : 'text-defaultbdd'">{{ parentOrgName }} ({{ site.name }}) </span>
              <mat-icon *ngIf="selectedSite && selectedSite.organizationId == site.organizationId" aria-label="check" alt="check" class="m-r-4">
                check
              </mat-icon>
            </button>
          </ng-container>
        </mat-menu>
      </li>
      <li [ngClass]="view === View.SYSTEM ? 'active' : 'default'" id="SelectedAsset" tabindex="0">
        <span class="b_info">
          <span class="svgicon icon_asset"></span>
          <span class="org_mac" *ngIf="!selectedAsset" matTooltip="ALL SYSTEMS">ALL SYSTEMS</span>
          <span class="org_mac" *ngIf="selectedAsset" matTooltipClass="my-custom-tooltip" matTooltip="{{ selectedAsset.name }}">{{ selectedAsset.name }}</span>
        </span>
        <button mat-icon-button [matMenuTriggerFor]="menusystems" (click)="onDropDownExpand()" id="menuSelectButton" type="button" title="Dropdown Arrow">
          <mat-icon>
            <img src="../../../../../assets/img/icons/ico.16.arwdown.svg" alt="">
          </mat-icon>
        </button>
        <mat-menu #menusystems="matMenu" backdropClass="breadcrumb_drop" xPosition="before">
          <button mat-menu-item (click)="setAsset(null)" type="button" class="flex justify-between content-center items-center">
            <span [ngClass]="!selectedAsset ? 'text-activebdd' : 'text-defaultbdd'">ALL SYSTEMS </span>
            <mat-icon *ngIf="!selectedAsset" aria-label="check" alt="check" class="m-r-4">
              check
            </mat-icon>
          </button>
          <mat-accordion displayMode="flat" [multi]="true">
            <ng-container *ngFor="let org of visibleOrgAssets;">
              <mat-expansion-panel [expanded]="true"  class="alltimexpansion">
                <mat-expansion-panel-header (click)="$event.stopPropagation();">
                  <mat-panel-title>{{ org.orgName }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sysgrid" *ngFor="let asset of org.assets">
                  <button mat-menu-item (click)="setAsset(asset)" type="button" class="flex justify-between content-center items-center">
                    <span [ngClass]="(selectedAsset && selectedAsset.assetId == asset.assetId) ? 'text-activebdd' : 'text-defaultbdd'">{{ asset.name }}</span>
                    <mat-icon *ngIf="selectedAsset && selectedAsset.assetId == asset.assetId" aria-label="check" alt="check" class="m-r-4">
                      check
                    </mat-icon>
                  </button>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </mat-menu>
      </li>
    </ul>
  </div>
  <div class="xs:hidden basis-[5%]">
    <div class="flex justify-end content-start items-start flex-row">
    </div>
  </div>
</div>

<app-card>
  <div title class="inventory_ctrl flex justify-between content-center items-center flex-row">
    <h5 class="ucase m-0 text-primaryblue" tabindex="0">Inventory {{deviceType}}</h5>

    <div class="flex flex-row justify-center content-center items-center h-full">
      <button class="btn-transparent btn_icon m-l-28" title="download" tabindex="0" (click)="downloadExcel()" [disabled]="downloadingExcel" type="button">
        <app-icon icon="import"></app-icon>
      </button>
    </div>
  </div>

  <div class="flex justify-between content-start items-start flex-row flex-wrap">
    <div class="p-t-16 p-b-16 bb-Gray4 inventory_switch flex flex-row justify-center content-center items-center h-full gap-4 basis-full sm:hidden xs:block md:hidden lg:hidden">
      <mat-button-toggle-group name="fontStyle">
        <mat-button-toggle value="bold">Current Inventory</mat-button-toggle>
        <mat-button-toggle value="italic">Inventory Trend</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="app-search-bar-container xs:flex xs:justify-between xs:content-end xs:items-end xs:h-full xs:flex-col basis-full flex flex-row justify-between content-center items-center h-full">
      <app-search-bar placeholder="Search by NDC or Drug Name" class="inventory_search xs:basis-full grow-0 shrink basis-[300px]" inputId="inventory-search" (filterChange)="filterInventory($event)"></app-search-bar>
      <form action="" class="smsort flex justify-start content-center items-center basis-full">
        <span class="sort">Sort By</span>
        <mat-form-field appearance="fill" class="m-l-8 xs:flex-1 grow-0 shrink basis-[200px]">
          <mat-select [(value)]="selectedSort" class="m-t-0 sm_sorting"  (selectionChange)="sortingChangeMobile($event)">
            <mat-option *ngFor="let sortItem of SortItems" [value]="sortItem"   >
              {{sortItem.fieldDispName }} ({{sortItem.sortDirection }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <form action="" class="smtime flex justify-start content-center items-center basis-full">
        <span class="sort">Time Range</span>
        <mat-form-field appearance="fill" class="m-l-8 xs:flex-1 grow-0 shrink basis-[200px]">
          <mat-select [(value)]="selectedDateRange" class="m-t-0 sm_sorting"  (selectionChange)="dateRangeFilter($event.value)">
            <mat-option *ngFor="let item of dateRangeFilterList; " [value]="item" >
              Last {{item}} Days
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>

  <app-inventory-views-table-rpt 
    *ngIf="windowWidth > 1025" 
    [filterText]="filterText" 
    [inventories]="inventories" 
    [loading]="loadingInventories" [dateRange]="selectedDateRange" 
    [view]="view" (sortChange)="sortingChange($event)" tabindex="0"></app-inventory-views-table-rpt>

  <app-inventory-views-mobile-table 
    *ngIf="windowWidth <= 1024" 
    [filterText]="filterText" 
    [inventories]="inventories" 
    [loading]="loadingInventories" 
    [dateRange]="selectedDateRange" 
    [view]="view" tabindex="0"
  ></app-inventory-views-mobile-table>
</app-card>
