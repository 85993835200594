import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import { getAssetsByOrgID } from 'src/app/store/selectors/assets.selector';
import { State } from 'src/app/store/state';
import { translateMachineTypeIdToMachinePermission, translateMachinePermissionToMachineTypeId } from 'src/app/external-user/pharmacy-peek/services/mapping';

interface System {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pharmacy-peek-select-multiple-dialog',
  templateUrl: './pharmacy-peek-select-multiple-dialog.component.html',
  styleUrls: ['./pharmacy-peek-select-multiple-dialog.component.scss']
})
export class PharmacyPeekSelectMultipleDialogComponent implements OnInit {
  systems: System[] = [];

  title: any = '';
  widgetName: any = '';
  widget: any;

  @ViewChild('select') select: MatSelect;

  allSelected = false;
  hidePlaceholder = false;
  errorMessage = false;
  mySelections: string[];

  selection = new FormControl('', [Validators.required]);

  getSelectionErrorMessage() {
    if (this.selection.hasError('required')) {
      return 'Please Select a System';
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PharmacyPeekSelectMultipleDialogComponent>,
    public store: Store<State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.widgetName = data.widgetName;
    this.widget = data.widget;
  }


  /** 
   * #region MachineType
   * API definition for machinetype and mapping for scripting by machine/device
   *
   *    {
   *        AssetId = Constants.ASSET_MACHINE_ATP,
   *       AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *       Name = "ATP",
   *        Description = "ATP Machine",
   *        Configurations = "{'MachineTypeID' : 1}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }, 
   *    {
   *        AssetId = Constants.ASSET_MACHINE_PASS,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "PASS",
   *        Description = "PASS Machine",
   *        Configurations = "{'MachineTypeID' : 2}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    },
   *    {
   *        AssetId = Constants.ASSET_MACHINE_MAX,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "MAX",
   *        Description = "MAX Machine",
   *        Configurations = "{'MachineTypeID' : 3}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }, 
   *    {
   *        AssetId = Constants.ASSET_MACHINE_MINI,
   *        AssetTypeId = Constants.ASSET_TYPE_MACHINE,
   *        Name = "MINI",
   *        Description = "MINI Machine",
   *        Configurations = "{'MachineTypeID' : 4}",
   *        LastActionDoneOn = DateTime.Parse("2022-02-16T05:00"),
   *        LastActionDone = "Created as part of Seeding",
   *        Enabled = true
   *    }
   *
   */


  ngOnInit(): void {
    if (this.data.widget) {
      var widgetJson = JSON.parse(JSON.stringify(this.data.widget));
      this.store.select(getAssetsByOrgID).subscribe(assets => {
        if (assets) {
          assets.forEach(element => {
            if (this.widgetName === 'SystemStatus') {
              widgetJson.machinePermissions.forEach(machine => {
                if (element.configurations.machineTypeID == translateMachinePermissionToMachineTypeId(machine)) {
                  this.systems.push({ value: element.machineNo, viewValue: element.name });
                }
              });
            }
            else {
              this.systems.push({ value: element.machineNo, viewValue: element.name });
            }
          });
        }
      });
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.hidePlaceholder = true;
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this.hidePlaceholder = false;
    }
  }

  optionClick() {
    let newStatus = true;
    this.hidePlaceholder = false;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
      const filterData = this.systems.filter(x => x.value === item.value.value);
      if (filterData.length > 0 && item.selected) {
        this.hidePlaceholder = true;
      }

    });
    this.allSelected = newStatus;
  }

  changed() {
    if (this.select.value.length < 7) {
      this.mySelections = this.select.value;
    } else {
      this.select.value = this.mySelections;
      this.optionClick();
    }
  }

  closeDialog() {
    this.dialogRef.close({ add: false, nameList: null, idList: null, allSelected: null });
  }

  closeDialogAdd() {
    const idList: any = [];
    const nameList: any = [];
    this.select.options.forEach((item: MatOption) => {
      const filterData = this.systems.filter(x => x.value === item.value.value);
      if (filterData.length > 0 && item.selected) {
        idList.push(filterData[0].value);
        nameList.push(filterData[0].viewValue);
      }
      else {
        this.selection.markAsTouched();
      }
    });
    if (idList.length > 0) {
      this.dialogRef.close({ add: true, nameList: nameList, idList: idList, allSelected: this.allSelected });
    }
  }

}
