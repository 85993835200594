<div class="inv_table" #inventoryTable>
  <div class="inv_table-header inv_table-row inv_table-title borderBottom">
    <div class="td"><!--Space holder --></div>
    <div class="td">Name</div>
    <div class="td"><!--Space holder --></div>
    <div class="td">(#) pouches</div>
    <div class="td">System</div>
    <div class="td">Status</div>
    <div class="td" *ngIf="Pouch_ScriptManagement_BacklogTime === true" >Est. Time</div>
    <div class="td" *ngIf="Pouch_ScriptManagement_Action === true">
      <!-- Action -->
    </div>
  </div>
  <div *ngFor="let queue of inventories ;">
    <div
      *ngIf="queue && (queue?.name != 'COMPLETE' && isComplete== false) || (queue?.name == 'COMPLETE' && isComplete ==true)">
      <div class="inv_table-header inv_table-row inv_table_subtitle borderBottom">
        <h6 class="ucase m-8 text-black" [class.clickable]="true" (click)="onExpandRow(queue);">
          <span>
            <app-icon [icon]="queue?.isExpanded ? 'arwup' : 'arwdown'" size="12" class="leftRapper ">
            </app-icon>
            <div class="rightRapper">
              <div>{{queue?.name}}</div>
              <div>({{queue?.inventory?.data?.batches.length}})</div>
            </div>
          </span>
        </h6>
      </div>

      <div class="inv_table-row" *ngFor="let row of queue?.inventory?.data?.batches ; let rowIndex = index"
        (click)="onOpenDispensingDetail($event, row, queue?.name)">
        <div class="inv_table-body borderBottom" *ngIf="queue?.isExpanded">
          <div class="collapseElement">
            <div class="td" [class.clickable]="isRowClickable_inner(queue?.inventory?.data?.batches,rowIndex)" (click)="onExpandRow_inner(row,rowIndex);" (keydown.enter)="onExpandRow_inner(row,rowIndex);" tabindex="0">
                <span>
                  <app-icon [icon]="row.isExpanded ? 'arwup' : 'arwdown'" size="12" class="pointer">
                  </app-icon>
                </span>
              </div>
            <div class="td" matTooltip="{{row?.parataBatchId | uppercase}}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"
              [innerHTML]="(row?.parataBatchId  | truncate:25 ) | noHtml | uppercase | highlightMatched: filterText">
            </div>
            <div class="td"><!--Space holder --></div>
            <div class="td">{{row?.pouchCount ? row?.pouchCount : '-'}}</div>
            <div class="td" matTooltip="{{row?.friendlyname | uppercase}}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"
            [innerHTML]="(row?.friendlyname  | truncate:25) | noHtml | uppercase | highlightMatched: filterText">
            </div>
            <div class="td status-priority" matTooltip="{{row?.status | uppercase}}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip">
              {{ (row?.status | truncate:25) | uppercase}}
            </div>
            <div class="td" *ngIf="Pouch_ScriptManagement_BacklogTime === true">
                {{row?.estimatedTime ? mathCeil(row?.estimatedTime/60) : '-'}}</div>
            <div class="td" *ngIf="Pouch_ScriptManagement_Action === true">
              <div *ngIf="queue.name == 'IN QUEUE'" [ngClass]="{'hideMenu': queue?.name !== 'IN QUEUE'}" >
                <mat-icon [matMenuTriggerFor]="scriptMenu" class="pointer menu-trigger">
                  <img src="../../../../../assets/img/icons/ico_16_Menu.svg" alt="">
                </mat-icon>
                <mat-menu #scriptMenu="matMenu" backdropClass="custom_drop" xPosition="before">
                  <button type="button" (click)="openMoveToDialog(row?.parataBatchId,row?.friendlyname)" mat-menu-item>Move To...</button>
                </mat-menu>
              </div>
              </div>
          </div>
          <div *ngIf="row.isExpanded" style="height: unset" class="inv_table-expanded-row">
            <div class="borderTop"></div>
            <div class="child-row" *ngFor="let childrow of row.patients;  let innerRowIndex = index">
              <div class=" collapseElementInner">
                <div class="td"></div>
                <div class="td" [innerHTML]="childrow?.name | noHtml | highlightMatched: filterText"></div>
                <div class="td">{{childrow?.dob}}</div>
                <div class="td">-</div>
                <div class="td"><!--Space holder --></div>
                <div class="td"><!--Space holder --></div>
                <div class="td"><!--Space holder --></div>
                <div class="td"><!--Space holder --></div>
              </div>
            </div>
        </div>
        </div>
      </div>

      <div class="inv_table-row" *ngIf="queue?.inventory?.data?.batches.length === 0 && queue?.isExpanded">
        <div class="mat-cell mat-column-pending">
          <div class="loading-spinner text-center w-100 m-15">
            No data exists
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="inv_table-row" *ngIf="loading">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center">
        <img src="/assets/img/Parata_processing.gif" alt="loading" class="w-10 m-15" />
      </div>
    </div>
  </div>
  <div class="inv_table-row" *ngIf="inventories && inventories.length === 0">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center w-100 m-15">
        No data exists
      </div>
    </div>
  </div>
</div>
