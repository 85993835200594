import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";

import { getHourlyVialCountDevice} from 'src/app/store/selectors/pharmacy-peek.selector';
import { PouchCountChartData } from '../../../models/pouch-count-chart-data';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';

@Component({
  selector: 'app-hourly-vial-count-device',
  templateUrl: './hourly-vial-count-device.component.html',
  styleUrls: ['./hourly-vial-count-device.component.scss']
})

export class HourlyVialCountDeviceComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() systemConfig: any;

  avgHoursToday: number | string;
  avgHoursTodayHidden: number;
  totalToday: number | string;
  totalTodayHidden: number;
  hourCountHidden: [];
  pouchCountDataToday: PouchCountChartData[] = [];
  noDataExistsForHPC = true;
  deviceId: string;
  deviceName: string;
  todayDay = '';
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.HPC_DEVICE,
    deviceId: ''
  }

  constructor(
    public store: Store<State>,
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
  ) { }

  ngOnInit(): void {

    this.store.select(getHourlyVialCountDevice).subscribe(res => {
      const resData: any = res;
      if (resData && resData.length > 0) {
        this.deviceId = this.systemConfig?.deviceId ? this.systemConfig?.deviceId : '';
        resData.forEach(element => {
          if (this.deviceId === element.deviceId) {
            this.updateChartFromAPIData(element);
            this.noDataExistsForHPC = false;
          }
        });
      }
    });
    
    this.subscription = this.signal.getDataStream<string>(SignalEventType.HVC_DEVICE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateHourlyVialCountDevice({ data: dataObj }));
      console.log(dataObj);
    })

    this.signalrSubList = {
      type: SignalEventType.HVC_DEVICE,
      deviceId: this.systemConfig?.deviceId ? this.systemConfig?.deviceId : ''
    }

    this.callHVCDevice();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
    this.deviceId = this.systemConfig?.deviceId;
    this.deviceName = this.systemConfig?.deviceName;
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  private callHVCDevice() {
    const deviceId = this.systemConfig?.deviceId ? this.systemConfig?.deviceId : '';
    this.viewStoreService.getHourlyVialCount(deviceId).subscribe(
      (response) => {
        this.store.dispatch(PharmacyPeekActions.getHourlyVialCountDevice({ data: response }));
      },
      (error) => {
        console.log('HourlyVialCount Service error:', error);

        if (error.status === 404) {
          this.loggerService.logWarning('HourlyVialCount Service: No Data: ', error);
        } else {
          this.toastService.openToast('HourlyVialCount Service load error!', ToastPanelType.error);
        }
      },
    );
  }
  
  private updateChartFromAPIData(data) {
    this.pouchCountDataToday = [...Array(24)].map((v, i) => {
      const time = `${(i % 12) || 12}${i < 12 ? 'a' : 'p'}`;
      const count = (i >= 0) ? data.data.hourCounts[i] : 0;
      return {
        time,
        count,
        max: Math.ceil(Math.max(...data.data.hourCounts)/100) * 100,
        tooltip: `<b>${count}</b>&nbsp;vial${count >= 2 ? 's' : ''} – ${i % 12 || 12} ${i < 12 ? 'AM' : 'PM'}`,
      };
    });

    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const d = new Date();
    const day = weekday[d.getDay()];
    this.todayDay = day + ' Avg';
    
    this.avgHoursToday = this.kFormatter(data.data.average);
    this.avgHoursTodayHidden = data.data.average;
    this.totalToday = this.kFormatter(data.data.total);
    this.totalTodayHidden = data.data.total;
    this.hourCountHidden = data.data.hourCounts.toString();
    
    this.deviceId = this.systemConfig?.deviceId ? this.systemConfig?.deviceId : null;
    this.deviceName = this.systemConfig?.deviceName ? this.systemConfig?.deviceName : null;
    
  }

  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/100)/10) + 'k' : Math.sign(num)*Math.abs(num)
  }
}
