<section class="content_container">
  <div class="container xs:flex xs:flex-col flex flex-row flex-wrap">
    <!-- Customer Onboarding -->
    <h4 class="m-b-0 basis-full">Customer Onboarding</h4>
    <div class="file-upload basis-[54%] xs:flex-1 sm:basis-[56%]">
      <app-fileupload></app-fileupload>
    </div>
    <div class="flex basis-[54%] justify-end content-center items-center xs:flex-1 sm:basis-[56%]">
      <button class="btn-primary" 
        [disabled]="disableProcessFileButton()" 
        [class.btn-disabled]="disableProcessFileButton()" 
        (click)="processUploadFile()"
      >Save</button>
    </div>

    <div class="flex basis-2/5 justify-start content-center items-center xs:flex-1 sm:basis-2/5">
      <div class="m-l-10">
        <mat-checkbox>Update Users</mat-checkbox>
      </div>
    </div>
    <div class="m-t-30 m-b-30 sm:flex-1 basis-[70%]">
      <hr />
    </div>

    <!-- Order setup -->
     <!-- <app-consumable-order></app-consumable-order> -->
     
  </div>
</section>
