import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { OrgRole } from '../models/org-role';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends DataService<OrgRole> {

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}roles/`, http);
  }

  getRolesForOrg(orgId: string, orgType: string, gearIcon: boolean, isOrgLevel: boolean): Observable<OrgRole[]> {
    const baseUrl = `${this.url}Active/`;

    if (orgType === "Internal" && gearIcon) {
      return this.http.get<OrgRole[]>(`${baseUrl}InternalOrg`);
    }
  
    const excludeParam = isOrgLevel ? "?excludeOrgWideAccess=false" : "";
    if (orgType === "Internal") {
      return this.http.get<OrgRole[]>(`${baseUrl}Org/${orgId}${excludeParam}`);
    }
  
    return this.http.get<OrgRole[]>(`${baseUrl}Org${excludeParam}`);
  }

}
