import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as constants from 'src/app/config/app-constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Product } from '../../models/product';
import { Organization } from '../../../customer-management/models/organization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../../../shared/services/toast.service';
import { AutoOrder } from '../../models/auto-order';
import { CurrentStock } from '../../models/current-stock';
import { InventoryStock } from '../../models/inventory-stock';
import { SubProduct } from '../../models/sub-product';
import { PackageSize } from '../../models/package-size';
import { OrderDetail } from '../../models/order-detail';
import { FileuploadComponent } from '../../../../shared/components/fileupload/fileupload.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadService } from '../../../../shared/services/file-upload.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-consumable-order-config',
  templateUrl: './consumable-order-config.component.html',
  styleUrls: [ './consumable-order-config.component.scss' ],
})
export class ConsumableOrderConfigComponent implements OnInit {

  //#region Fields
  orderTableColumns: string[] = ['productName', 'partNumber', 'packageSize', 'quantity'];
  currentStockDataSource: MatTableDataSource<Product>;
  requiredStockDataSource: MatTableDataSource<Product>;
  currentStockPouchesCount: number;
  notificaitonId: string;
  orgnizations: Organization[] = [];
  machineTypes: any[] = [];
  // save auto consume order
  autoOrderDetail: AutoOrder[] = [];
  currentStocks: CurrentStock[] = [];
  inventoryStock: InventoryStock;
  currentStockControls = 'currentStockControls';
  curStockSubProduct = 'curStockSubProduct';
  curStockPackageSize = 'curStockPackageSize';
  curStockQuantity = 'curStockQuantity';
  requiredStockControls = 'requiredStockControls';
  requiredStockSubProduct = 'requiredStockSubProduct';
  requiredStockPackageSize = 'requiredStockPackageSize';
  requiredStockQuantity = 'requiredStockQuantity';
  products: Product[];
  subProducts: SubProduct[];
  packageSizes: PackageSize[];
  orderDetail: OrderDetail[] = [];
  resp: CurrentStock[] = [];
  toUpdate: boolean;
  private processStarted = false;
  formGroup: FormGroup;

  @ViewChild(FileuploadComponent, {static: false}) fileUpload: FileuploadComponent;

  //#endregion

  //#region Constructor & ngOnInit
  constructor(
    public router: Router,
    private notificationService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.notificaitonId = this.route.snapshot.queryParamMap.get('rqid');
    this.ngxLoader.start();
    this.formGroup = this.formBuilder.group({
      currentStockControls: this.formBuilder.array([]), // current stock
      requiredStockControls: this.formBuilder.array([]), // required stock
      pouchesCount: new FormControl(10000, Validators.required),
      organizationId: new FormControl('', Validators.required),
      machineType: new FormControl('', Validators.required),
    });
    this.ngxLoader.stop();
  }

  //#endregion

  /// region file upload functions
  processUploadFile() {
    if (this.fileUpload.SelectedFiles.length === 0) {
      return this.toastService.openToast(
        'No file added for upload.',
        constants.ToastPanelType.error,
      );
    }

    this.ngxLoader.start();

    this.fileUploadService.upload(this.fileUpload.SelectedRawFiles[0])
      .subscribe(
        (result) => this.fileResponseHandler(result),
        (error: HttpErrorResponse) => this.fileErrorHandler(error),
      );
  }

  disableProcessFileButton(): boolean {
    if (this.formGroup && this.formGroup.controls) {
      if (this.processStarted) {
        return true;
      }
      if (this.fileUpload && this.fileUpload.SelectedFiles && this.fileUpload.SelectedFiles.length === 0) {
        return true;
      }
    }
  }

  private fileResponseHandler(message: string) {
    this.ngxLoader.stop();
    this.processStarted = false;
    this.fileUpload.SelectedFiles = [];
    this.toastService.openToast(message || 'File successfully uploaded!', constants.ToastPanelType.done);

  }

  private fileErrorHandler(error) {
    console.error(error);
    const response = JSON.parse(error) || {};
    this.ngxLoader.stop();
    this.processStarted = false;
    this.toastService.openToast(response.message || 'Failed to process file upload!', constants.ToastPanelType.error);
  }

/// end region
}
