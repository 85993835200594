import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';

export interface State {
    authProfile: any;
    assets: any;
    machinePermissions: any;
    launchDarklyFlags: any;
}

export const initialState: State = {
    authProfile: null,
    assets: null,
    machinePermissions: null,
    launchDarklyFlags: null,
};

const assetsReducer = createReducer(
    initialState,

    // GeTasks
    on(AllActions.AssetsActions.getAssetByOrgId, (state, action) => ({
            ...state,
            assets: action.assets,
            machinePermissions: action.machinePermissions,
            authProfile: action.authProfile
        })),

    on(AllActions.AssetsActions.saveLaunchDarklyFlags, (state, action) => ({
            ...state,
            launchDarklyFlags: action.flags,
        })),
);

export function reducer(state: State | undefined, action: Action): any {
    return assetsReducer(state, action);
}
