import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastPanelType } from 'src/app/config/app-constants';
import { OrganizationService } from 'src/app/internal-user/customer-management/services/organization.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { PermissionGroupsService } from '../../services/permission-groups.service';
import { DeletePermissionGroupDialogComponent } from '../delete-permission-group-dialog/delete-permission-group-dialog.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-permission-groups',
  templateUrl: './permission-groups.component.html',
  styleUrls: ['./permission-groups.component.scss']
})

export class PermissionGroupsComponent implements OnInit {
  perGroupList: any[];
  defaultRoleDetail: any[];
  customRoleDetail: any[];
  menuWithAssetDetail: any[];
  selectedValue: string;
  selectedname: string;
  allComplete: boolean = false;
  isDefaultPer: boolean = true;
  isEditMode: boolean = false;
  @Input() isGearIconClicked = false;
  @Input('clickAddGroup') clickAddGroup: Subject<any>;
  @Output() delPermissionGroup = new EventEmitter<any>();
  @Input('refreshPerGroup') refreshPerGroup: Subject<any>;  
  @Output() disableAddNew = new EventEmitter<any>();

  paramParentOrgId: string;
  selectedPerGroup: any;

  addNewGroupClicked: boolean = false;
  featuresListData: any[];
   
  formGroup: FormGroup = new FormGroup({
  groupName: new FormControl('',[]),
  });

  constructor(public auth: AuthService,
    private loggerService: LoggerService,
    private ngxLoader: NgxUiLoaderService,
    private organizationService: OrganizationService,
    private permissionGroup: PermissionGroupsService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastService: ToastService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.isGearIconClicked) {
      this.clickAddGroup.subscribe(e => {
        this.addNewGroup();
      });
      this.refreshPerGroup.subscribe(e => {
        this.LoadRoles();
      });
      this.auth.profile$.subscribe((profile) => {
        this.paramParentOrgId = profile.parentOrganizationId;

        this.LoadRoles();
      });
    } else {
      this.route.parent.params.subscribe(({ id }) => {
        this.organizationService.getByID(id)
          .subscribe((org) => {
            this.paramParentOrgId = org.parent_Account_Id;

            this.LoadRoles();
          });
      });
    }  

    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      groupName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9 ]*'),
      this.nameAlreadyExist(this.permissionGroup)])
    });
  }

  LoadRoles() {
    const ngxLoaderKey = 'load-roles';
    this.ngxLoader.start(ngxLoaderKey);
    this.permissionGroup.getRoles(this.paramParentOrgId).subscribe((response) => {
      this.defaultRoleDetail = response.filter(a => a.isDefault).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      this.customRoleDetail = response.filter(a => !a.isDefault).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));;
      this.selectedId(this.defaultRoleDetail[0]);
      this.ngxLoader.stop(ngxLoaderKey);
    },
      (error) => {
        if (error.status === 404) {
          this.loggerService.logWarning('Roles: No Data: ', error);
        } else {
          this.toastService.openToast('Error while getting roles!', ToastPanelType.error);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      }
    );
  }

  LoadDefaultPermission() {
    const ngxLoaderKey = 'load-permissiongroup';
    this.ngxLoader.start(ngxLoaderKey);
    this.permissionGroup.getPermissionGroup(this.paramParentOrgId, this.selectedValue).subscribe((res) => {
      this.menuWithAssetDetail = res.filter(x => x.assetTypeId == 7);
      this.menuWithAssetDetail.forEach(i => {
        i.menuList = [];
        const menu = res.filter(x => x.featureId == i.assetId && x.assetTypeId == 8);
        menu.forEach((j, index) => {
          i.menuList.push(j);
        });
        i.menuList.sort((a, b) => (a.assetName > b.assetName ? 1 : -1));;
      });
      this.featuresListData = this.menuWithAssetDetail;

      if (this.isEditMode == true) {
        this.featuresListData.forEach(p => {
          p.isSelected = false;
          if (p.menuList.length == p.menuList.filter((t) => t.isSelected).length)
            p.isSelected = true;
        });
      }


      this.ngxLoader.stop(ngxLoaderKey);
    },
      (error) => {
        if (error.status === 404) {
          this.loggerService.logWarning('PermissionGroup: No Data: ', error);
        } else {
          this.toastService.openToast('Error while getting permissiongroup!', ToastPanelType.error);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      }
    );
  }

  LoadDefaultFeature() {
    const ngxLoaderKey = 'load-permissiongroup';
    this.ngxLoader.start(ngxLoaderKey);
    this.permissionGroup.getDefaultFeature().subscribe((res) => {
      this.featuresListData = res.filter(x => x.assetTypeId == 7);
      this.featuresListData.forEach(i => {
        i.menuList = [];
        const menu = res.filter(x => x.featureId == i.assetId && x.assetTypeId == 8);
        menu.forEach((j, index) => {
          if (j.assetName == 'Reporting')
            j.isSelected = true;

          i.menuList.push(j);
        });
        i.menuList.sort((a, b) => (a.assetName > b.assetName ? 1 : -1));;
      });
      this.ngxLoader.stop(ngxLoaderKey);
    },
      (error) => {
        if (error.status === 404) {
          this.loggerService.logWarning('PermissionGroup: No Data: ', error);
        } else {
          this.toastService.openToast('Error while getting permissiongroup!', ToastPanelType.error);
        }
        this.ngxLoader.stop(ngxLoaderKey);
      }
    );
  }

  selectedId(element) {

    this.selectedValue = element.roleId;
    this.selectedname = element.name;
    this.isDefaultPer = element.isDefault;
    this.selectedPerGroup = element;
    this.delPermissionGroup.emit(element);
    this.LoadDefaultPermission();
  }

  addNewGroup() {
    this.featuresListData = [];
    this.formGroup.reset();
    this.selectedValue = "0";
    this.addNewGroupClicked = true;
    this.allComplete = false;
    const listGroups = [...this.customRoleDetail];
    if (!listGroups?.find(t => t.roleId == '0'))
      listGroups.unshift({ description: "New Group", name: "New Group", roleId: "0", isDefault: false });
    this.customRoleDetail = listGroups
    this.isEditMode = false;
    this.selectedname = "";
    this.formGroup.get("groupName").enable();
    this.LoadDefaultFeature();
    this.disableAddNew.emit(true);
  }

  CancelGroup() {
    this.formGroup.reset();
    this.featuresListData = [];
    this.isEditMode = false;
    this.addNewGroupClicked = false;
    this.disableAddNew.emit(false);
    this.LoadRoles();
    this.selectedId(this.defaultRoleDetail[0]);
  }

  validateForm() {
    if (!this.formGroup.valid) {
      this.formGroup.get('groupName').markAsTouched();
    }
  }

  nameAlreadyExist(permissionGroupsService: PermissionGroupsService): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      var groupName = control.value;
      
      if (this.nameUniqueValidation(groupName, permissionGroupsService))
        return { 'alreadyExist': true };
      else
        return null;
    }
  }

  nameUniqueValidation(groupName: string, permissionGroupsService: PermissionGroupsService) {
    let bReturn = false;

    if (groupName != '' && groupName != undefined) {
      debounceTime(500);
      if (this.customRoleDetail?.find(t => t.description.toLowerCase() == groupName.trim().toLowerCase()) || this.defaultRoleDetail?.find(t => t.description.toLowerCase() == groupName.trim().toLowerCase())) {
        return true;
      }
      
      permissionGroupsService.getRoles(this.paramParentOrgId).subscribe((response) => {
        if (response?.find(t => t.description.toLowerCase() == groupName.trim().toLowerCase())) {
          bReturn = true;
        }
      },
        (error) => {
          console.log(error);
        }
      );
    }
    return bReturn;
  }

  SaveGroup() {

   
    const frmGrup: any = this.formGroup.getRawValue();
    const ngxLoaderKey = 'save-permissiongroup';

    var groupName = (document.getElementById('groupName') as HTMLInputElement).value.trim();
    const req = {
      roleId: '',
      roleName: groupName,
      roleDescription: groupName,
      orgId: this.paramParentOrgId,
      featureIDs: []
    }

    const chk = this.featuresListData.forEach(p => {

      p.menuList.forEach(t => {
        if (t.isSelected) {
          req.featureIDs.push(t.assetId);

          if (!req.featureIDs?.includes(t.featureId))
            req.featureIDs.push(t.featureId);
        }
      });
    });

    if (req.featureIDs.length <= 0) {
      this.toastService.openToast('Atleast select one feature!', ToastPanelType.error);
      return;
    }
    if (this.isEditMode) {
      this.ngxLoader.start(ngxLoaderKey);
      req.roleId = this.selectedValue;
      this.permissionGroup.updatePermissionGroup(req).subscribe((res) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.LoadRoles();
        this.addNewGroupClicked = false;
        this.isEditMode = false;
        this.disableAddNew.emit(false);
        this.toastService.openToast('Permission group updated!');
      },
        (error) => {
          if (error.status === 404) {
            this.loggerService.logWarning('PermissionGroup: No Data: ', error);
          } else {
            this.toastService.openToast('Error while saving permissiongroup!', ToastPanelType.error);
          }
          this.ngxLoader.stop(ngxLoaderKey);
        }
      );
    }
    else {
      if (!this.formGroup.valid) {
        this.validateForm();
        return;
      }
      
      this.ngxLoader.start(ngxLoaderKey);
      this.permissionGroup.savePermissionGroup(req).subscribe((res) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.LoadRoles();
        this.addNewGroupClicked = false;
        this.isEditMode = false;
        this.disableAddNew.emit(false);
        this.toastService.openToast('Permission group saved!');

      },
        (error) => {
          if (error.status === 404) {
            this.loggerService.logWarning('PermissionGroup: No Data: ', error);
          } else {
            this.toastService.openToast('Error while saving permissiongroup!', ToastPanelType.error);
          }
          this.ngxLoader.stop(ngxLoaderKey);
        }
      );
    }
    
  }

  EditGroup() {
    this.isEditMode = true;
    this.addNewGroupClicked = true;
    this.selectedId(this.selectedPerGroup);
    this.formGroup.get("groupName").setValue(this.selectedname);
    this.formGroup.get("groupName").disable();
    this.disableAddNew.emit(true);
  }

  /*Checkbox list behaviour- start */
  updateAllComplete() {
    this.allComplete = this.featuresListData?.every(
      (t) => t.isSelected
    );
  }

  updateAllSubComplete(subItem: any) {
    subItem.isSelected = subItem.menuList?.every(
      (t) => t.isSelected
    );
    this.updateAllComplete();
  }

  setAll(e: any) {
    const selected = e.checked;
    this.allComplete = selected;

    if (this.featuresListData == null) {
      return false;
    }
    this.featuresListData?.forEach(function (p) {
      p.isSelected = selected;
      p.menuList.forEach(t => {
        t.isSelected = selected;
        if (t.assetName == 'Reporting')
          t.isSelected = true;
      })
    });
  }

  setAllSub(subItem: any, isChecked: any) {
    subItem.isSelected = isChecked.checked;
    if (this.featuresListData == null) {
      return false;
    }
    subItem?.menuList.forEach((t) => {
      t.isSelected = isChecked.checked;
      if (t.assetName == 'Reporting')
        t.isSelected = true;
    });
  }

  someComplete(): boolean {
    if (this.featuresListData == null) {
      return false;
    }
    console.log(
      this.featuresListData.filter((t) => t.isSelected).length > 0 &&
      !this.allComplete
    );

    var subCheckBoxSelectedCount = 0;
    this.featuresListData?.forEach(function (p) {
      p.menuList.forEach(t => {
        if (t.isSelected)
          subCheckBoxSelectedCount++;
      })
    });

    return (
      (this.featuresListData.filter((t) => t.isSelected).length > 0 || subCheckBoxSelectedCount > 0) && !this.allComplete
    );
  }

  someSubComplete(subCheckBox: any): boolean {
    if (subCheckBox.menuList == null) {
      return false;
    }
    return (
      subCheckBox.menuList.filter((t) => t.isSelected).length > 0 &&
      !subCheckBox.isSelected
    );
  }
  /** checkbox list behaviour- end */

  deletePermissionGroups(isDefault) {
    if (isDefault)
        return;

    if (this.auth.loggedIn) {
      this.dialog.open(
        DeletePermissionGroupDialogComponent,
        {
          disableClose: true, backdropClass: ['DeletePermissionGrpsDialog', 'smDialog', 'SnackDialog'], data: {
            role: this.selectedPerGroup, parentOrgId: this.paramParentOrgId
          }
        },

      ).afterClosed()
        .subscribe((shouldReload: boolean) => {
          if (shouldReload) this.LoadRoles();
        });
    }
  }

}
