<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>
      <h1 mat-dialog-title>
        <div class="flex justify-start content-center items-center flex-row">
          <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
            Add From CDDB To Drug List
          </div>
          <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
            <mat-icon (click)="dialogRef.close()" class="cross-wizard">
              <img src="/assets/img/icons/icon.16.states.svg" alt="">
            </mat-icon>
          </div>
        </div>
      </h1>
    
        <mat-dialog-content>
            <div class="flex justify-start content-center items-center flex-row">
                <div class="search-wrapper" style="padding: 9px">
                    <div class="search-container">
                        <app-icon icon="search" class="icon_search"></app-icon>
                        <input type="text" class="search-input" inputId="searchdrugs-input" (keyup)="getCddbDrugsData($event.target.value)" minLengthToSearch="1" placeholder="Search" [(ngModel)]="searchText" id="search_drugs">
                       
                        <app-icon icon="clear" *ngIf="searchText != null && searchText.length>0" (click)="getCddbDrugsData(''); searchText=''" id="data-clear" class="pointer"></app-icon>
                    </div>
                </div>
                <button mat-button class="btn-sm" [ngClass]="{'btn-blue':cddbDrugList.length>0, 'btn-disabled':cddbDrugList.length==0}" [disabled]="cddbDrugList.length==0" (click)="addFromCddb(deviceId)" id="Add" type="button" aria-label="Add">Add To Drug List</button>
            </div>
    
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="sortChange($event)">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox (change)="drugCellRowChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- SL Name Column -->
                    <ng-container matColumnDef="sl">
                        <th mat-header-cell *matHeaderCellDef> SL </th>
                        <td mat-cell *matCellDef="let element"> {{element.sl}} </td>
                    </ng-container>
                    <!-- Ndc Column -->
                    <ng-container matColumnDef="ndc">
                        <th mat-header-cell *matHeaderCellDef> NDC </th>
                        <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
    
                    <!-- strength Column -->
                    <ng-container matColumnDef="strength">
                        <th mat-header-cell *matHeaderCellDef> Strength</th>
                        <td mat-cell *matCellDef="let element"> {{element.strength}} </td>
                    </ng-container>
    
                    <!-- Manufacturer Column -->
                    <ng-container matColumnDef="manufacturer">
                        <th mat-header-cell *matHeaderCellDef> Manufacturer</th>
                        <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>
            
                </table>
                <table>
                    <tr *ngIf=" dataSource.data.length==0">
                        <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                            No Records Found!
                        </td>
                    </tr>
                </table>

            </div>
    
        </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex flex-row justify-end content-center items-center w-full">
    
           
                <div class="flex justify-start content-stretch items-stretch h-full basis-1/2">
                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"></mat-paginator>
                </div>
                <div class="flex justify-end content-stretch items-stretch h-full basis-1/2">
                    <button mat-button class="btn-blue  btn-md" (click)="closeDialog(true)" id="Ok" type="button" aria-label="Ok">Ok</button>
                </div>

        </mat-dialog-actions>
    
    </div>
    