import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as constants from 'src/app/config/app-constants';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthorizationService } from 'src/app/internal-user/customer-management/services/authorization.service';
import { Profile } from 'src/app/shared/models/auth/profile';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AssetService } from '../inventory/services/asset.service';
import { PharmacyPeekService } from '../pharmacy-peek/services/pharmacy-peek.service';
import { NoDataRow, TableGroup } from 'src/app/shared/models/ui/table-group';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { forkJoin } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

interface TableRow extends TableGroup, NoDataRow, NotificationManagementModel {
  index?: number;
}

export interface NotificationManagementModel {
  system? : string;
  event?: string;  
  operator?: string;
  value?: string;  
  method?: string;  
}

interface NotificationManagementFilter {
  text?: string;  
}

@Component({
  selector: 'app-notification-mgmt-dialog',
  templateUrl: './notification-mgmt-dialog.component.html',
  styleUrls: ['./notification-mgmt-dialog.component.scss']
})

export class NotificationMgmtDialogComponent implements OnInit {
  @ViewChild('formDirective', { static: true }) private formDirective: NgForm;
  formGroup: FormGroup;
  editNotificationFormGroup: FormGroup;
  displayedColumns: string[] = ['eventname', 'operator', 'nmvalue', 'nmmethod', 'recipients', 'action'];
  editingColumns: string[] = ['editeventname', 'operator', 'editnmvalue', 'nmmethod', 'recipients', 'editaction'];
  dataSource = new MatTableDataSource<TableRow>([]);
  notificationList: any = [];
  valueList: any = [];
  isSelected = '';
  systemList: any = [];
  eventList: any = [];
  editingNotification: any = [];
  profile: Profile;
  orgId: string;
  firstname = '';
  lastname = '';
  allSystemPushcount = 0;
  alreadyExist = '';
  removeEvent = false;
  isEditmode = false;
  recentRemoveEvent: string;
  recentRemoveState: string;
  recentRemoveEventName: string;
  recentRemoveEventSystem: string;
  removeEventPanelTimeout: any = null;
  filter: NotificationManagementFilter;

  constructor(public authorizationService: AuthorizationService, private toastService: ToastService, public dialogRef: MatDialogRef<NotificationMgmtDialogComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private assetService: AssetService, private userSettingsService: UserSettingsService, public auth: AuthService, private ngxLoader: NgxUiLoaderService) {

    this.auth.profile$.subscribe(profile => {
      this.profile = profile;
      this.orgId = profile.organizationId;
    });

    this.editNotificationFormGroup = new FormGroup({
      system: new FormControl('', [Validators.required]),
      event: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      operator: new FormControl('Greater Than', [Validators.required]),
      method: new FormControl('Email', [Validators.required]),
      recipients: new FormControl(this.firstname + ' ' + this.lastname, [Validators.required]),
      systemname: new FormControl(''),
    });

    this.formGroup = new FormGroup({
      system: new FormControl('', [Validators.required]),
      event: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      operator: new FormControl('Greater Than', [Validators.required]),
      method: new FormControl('Email', [Validators.required]),
      recipients: new FormControl(this.firstname + ' ' + this.lastname, [Validators.required]),
      systemname: new FormControl(),
    });
  }

  ngOnInit(): void {
    const ngxLoaderKey = 'Load Notification Management';
    this.ngxLoader.start(ngxLoaderKey);

    forkJoin([
      this.getUserDetails(),
      this.loadSystem(),
      this.loadEvent(),
      this.loadValue()      
    ]).subscribe((response) => {
        this.firstname = response[0].firstName ? response[0].firstName[0].toUpperCase() + response[0].firstName.substring(1) : '';
        this.lastname = response[0].lastName ? response[0].lastName.toUpperCase() : '';

        this.systemList = response[1];
        this.eventList = response[2];
        this.valueList = response[3];
        this.loadNotification(ngxLoaderKey);
    }, (response) => {
      this.toastService.stopLoadingAndShowError(response,
      'Unable to load Notification management!!', ngxLoaderKey);
    });
  }

  loadSystem() {
    return this.assetService.getAssetByOrgId(this.orgId);    
  }

  validateForm() {
    if (this.formGroup.invalid) {
      this.formGroup.get('system').markAsTouched();
      this.formGroup.get('event').markAsTouched();
      this.formGroup.get('value').markAsTouched();
      return;
    }
  }

  addNotification(element) {
    if (!this.formGroup.valid) {
      this.validateForm();
      return;
    }
    if (element.system == 'AllSystems') {
      this.systemList.forEach(system => {
        const rowexist = this.notificationList.filter(list => list.event == element.event && list.system == system.machineNo && list.enabled == true);
        if (rowexist && rowexist.length > 0) {
          this.alreadyExist += rowexist[0].systemname + ', ';
        }
        else {
          this.notificationList.push({ configId: null, system: system.machineNo, event: element.event, operator: element.operator, value: element.value, method: element.method, recipients: element.recipients, enabled: true, state: 'Added', systemname: system.name });
        }
      });
      if (this.alreadyExist != '') {
        this.toastService.openToast('The event ' + element.event + ' is already configured for the system(s) ' + this.alreadyExist.slice(0, -1) + '!', constants.ToastPanelType.error);
        this.alreadyExist = '';
      }
    }
    else {
      const rowexist = this.notificationList.filter(list => list.event == element.event && list.system == element.system && list.enabled == true);
      if (rowexist && rowexist.length > 0) {
        this.toastService.openToast('The event ' + element.event + ' is already configured for the system ' + rowexist[0].systemname + '!', constants.ToastPanelType.error);        
        return;
      }
      const systemrowexist = this.systemList.filter(list => list.machineNo == element.system);
      if (systemrowexist && systemrowexist.length > 0) {
        element.name = systemrowexist[0].name;
      }
      this.notificationList.push({ configId: null, system: element.system, event: element.event, operator: element.operator, value: element.value, method: element.method, recipients: element.recipients, enabled: true, state: 'Added', systemname: element.name });
    }
    this.setDataSource(this.notificationList.filter(list => list.enabled == true));
    this.reset();
  }

  removeNotification(selectedindex) {
    const arr: any = [...this.notificationList.filter(list => list.enabled == true)];
    arr[selectedindex].enabled = false;
    this.recentRemoveState = arr[selectedindex].state;
    arr[selectedindex].state = 'Deleted';
    this.setDataSource(arr.filter(list => list.enabled == true));
    this.removeEvent = true;
    this.recentRemoveEvent = arr[selectedindex].configId;
    this.recentRemoveEventName = arr[selectedindex].event;
    this.recentRemoveEventSystem = arr[selectedindex].system;
    clearTimeout(this.removeEventPanelTimeout);
    if (this.removeEvent) {
      this.removeEventPanelTimeout = setTimeout(() => this.removeEvent = false, 5000);
    }
  }

  undoRemoveEvent() {
    this.removeEvent = false;
    const arr: any = [...this.notificationList];
    const index = arr.findIndex(x => x.configId === this.recentRemoveEvent && x.system === this.recentRemoveEventSystem && x.event === this.recentRemoveEventName);
    arr[index].enabled = true;
    arr[index].state = this.recentRemoveState;
    this.setDataSource(arr.filter(list => list.enabled == true));
  }

  updateNotificationItem(index) {
    const notification: any = {
      ...this.editNotificationFormGroup.getRawValue(),
    };
    const arr: any = [...this.notificationList.filter(list => list.enabled == true)];
    const rowexist = this.notificationList.filter(list => list.event == notification.event && list.system == notification.system && list.enabled == true && list.configId != arr[index].configId);
    if (rowexist && rowexist.length > 0) {
      this.toastService.openToast('The event ' + notification.event + ' is already configured for the system ' + rowexist[0].systemname + '!', constants.ToastPanelType.error);      
      return;
    }
    else {
      const systemrowexist = this.systemList.filter(list => list.machineNo == notification.system);
      if (systemrowexist && systemrowexist.length > 0) {
        arr[index].systemname = systemrowexist[0].name;
      }
      arr[index].system = notification.system;
      arr[index].event = notification.event;
      arr[index].value = notification.value;
      if (arr[index].configId) { arr[index].state = 'Modified'; }

    }
    this.setDataSource(arr.filter(list => list.enabled == true));
    this.editNotificationItem(null);
    this.hideEditNotify(index);
  }

  loadNotification(ngxLoaderKey) {
    return this.userSettingsService.getNotificationDetails().subscribe(
      (response) => {
        const arr: any = [...response];
        arr.forEach((system, index) => {
          const rowexist = this.systemList.filter(list => list.machineNo == system.system);
          if (rowexist && rowexist.length > 0) {
            arr[index].systemname = rowexist[0].name;
          }
        });

        this.notificationList = arr.filter(list => list.enabled == true);
        this.setDataSource(arr.filter(list => list.enabled == true));
        this.ngxLoader.stop(ngxLoaderKey);
      },
      (error) => {
        this.setDataSource(null);
        this.ngxLoader.stop(ngxLoaderKey);
        console.log('notification error:', error);
      },
    );
  }

  loadEvent() {
    return this.userSettingsService.getEventDetails();  
  }

  loadValue() {
    return this.userSettingsService.getValueDetails();   
  }

  saveNotification() {
    if (this.isEditmode) {
      this.toastService.openToast("Please save the changes before submit", constants.ToastPanelType.error);
      return;
    }
    const arr: any = [...this.notificationList.filter(list => list.state != 'UnChanged')];
    if (arr.length == 0) {
      this.dialogRef.close();
      return;
    }
    this.notificationList.forEach((value, index) => {
      if (value.state == "Deleted" && value.configId == null) this.notificationList.splice(index, 1);
    });
    this.userSettingsService.saveNotificationConfig(this.notificationList).subscribe(
      (response) => {
        console.log('notification detail added');
      },
      (error) => {
        console.log('notification error:', error);
      },
    );
    this.dialogRef.close();
  }

  reset() {
    this.formDirective.resetForm();
    this.formGroup.reset({
      system: '',
      event: '',
      value: '',
      operator: 'Greater Than',
      method: 'Email',
      recipients: this.lastname.toUpperCase() + ", " + this.firstname
    });
  }

  getUserDetails() {
    return this.authorizationService.getUser(this.profile?.email);     
  }

  editNotificationItem(notification) {
    if (notification) {
      this.isEditmode = true;
      this.editingNotification = { ...notification };
      this.editNotificationFormGroup.patchValue({
        system: notification?.system,
        event: notification?.event,
        value: notification?.value,
      });
    } else {
      this.isEditmode = false;
      this.editingNotification = null;
    }
    this.setDataSource(this.notificationList.filter(list => list.enabled == true));
  }

  isEditingRow(index, item): boolean {
    return this.editingNotification && (this.editingNotification.system === item.system && this.editingNotification.event === item.event);
  }

  setDataSource(notifications: any) {
    this.dataSource.data = [...(notifications && notifications.length > 0 ? notifications : [{ noDataText: 'No notification configured', enabled: true } as NoDataRow])];    
  }

  isNoDataRow(index, item): boolean {
    return 'noDataText' in item;
  }

  applyFilter(newFilter: NotificationManagementFilter = {}) {
    this.filter = { ...this.filter, ...newFilter };    
    if(this.filter)
    {
      var filterText = this.filter.text.toLowerCase();
      var filteredData = [...this.notificationList.filter(t =>
        t.event.toLocaleLowerCase().includes(filterText)
        || t.systemname.toLocaleLowerCase().includes(filterText)
        || t.operator.toLocaleLowerCase().includes(filterText)
        || t.value.toLocaleLowerCase().includes(filterText)
        || t.method.toLocaleLowerCase().includes(filterText)
        || this.firstname.toLocaleLowerCase().includes(filterText)
        || this.lastname.toLocaleLowerCase().includes(filterText)
        )];      
      this.dataSource.data = [...(filteredData && filteredData.length > 0 ? filteredData : [{ noDataText: 'No results found' } as NoDataRow])];          
    }
  }  

  showEditNotify(notification, id) {
    if (notification) {
      this.isEditmode = true;
      this.editingNotification = { ...notification };
      this.editNotificationFormGroup.patchValue({
        system: notification.system,
        event: notification.event,
        value: notification.value,
        recipients: this.lastname + ", " + this.firstname
      });

      document.getElementById("matedit" + id).style.display = "block";
      document.getElementById("matcard" + id).style.display = "none";

    } else {
      this.isEditmode = false;
      this.editingNotification = null;
    }
    this.setDataSource(this.notificationList.filter(list => list.enabled == true));
  }

  hideEditNotify(index) {
    document.getElementById("matedit" + index).style.display = "none";
    document.getElementById("matcard" + index).style.display = "block";
    this.isEditmode = false;
  }

}