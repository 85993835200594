/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconComponent } from './components/icon/icon.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { FileuploadComponent } from './components/fileupload/fileupload.component';
import { ToastComponent } from './components/toast/toast.component';
import { SearchComponent } from './components/search/search.component';
import { OrderPopup } from './components/order-popup/order-popup.component';
import { CardComponent } from './layouts/card/card.component';
import { BatchTimePipe } from './pipes/batch-time.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MaterialModule } from './material-module/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { AlertComponent } from './components/alert/alert.component';
import { HighlightMatchedPipe } from './pipes/highlight-matched.pipe';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { TrimPipe } from './pipes/trim.pipe';
import { NoHTmlPipe } from './pipes/no-html.pipe';
import { PrivateSignalRService } from './signalr/service/private-signal-r.service';
import { SignalRService } from './signalr/service/signal-r.service';
import { NotifyDialogComponent } from './components/notify-dialog/notify-dialog.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { GenericConfirmationDialogComponent } from './generic-confirmation-dialog/generic-confirmation-dialog.component';
import { CustomToolTipComponent } from './components/custom-tooltip/custom-tooltip.component';

/* eslint-enable max-len */


@NgModule({
    declarations: [
        IconComponent,
        ToggleComponent,
        FileuploadComponent,
        ToastComponent,
        SearchComponent,
        OrderPopup,
        CardComponent,
        // BatchListComponent,
        BatchTimePipe,
        TrimPipe,
        NoHTmlPipe,
        TruncatePipe,
        BreadcrumbComponent,
        SearchBarComponent,
        AlertComponent,
        HighlightMatchedPipe,
        NotificationBannerComponent,
        NotifyDialogComponent,
        GenericConfirmationDialogComponent,
        CustomToolTipComponent,
    ],
    exports: [
        NgxUiLoaderModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        BatchTimePipe,
        TrimPipe,
        NoHTmlPipe,
        TruncatePipe,
        IconComponent,
        ToggleComponent,
        FileuploadComponent,
        ToastComponent,
        SearchComponent,
        OrderPopup,
        CardComponent,
        // BatchListComponent,
        BreadcrumbComponent,
        ReactiveFormsModule,
        SearchBarComponent,
        AlertComponent,
        HighlightMatchedPipe,
        NotificationBannerComponent,
        CustomToolTipComponent,
        GenericConfirmationDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
    ],
    providers: [{
            provide: SignalRService,
            useClass: PrivateSignalRService
        }]
})
export class SharedModule {}
