import { OnInit, Component } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { Store } from '@ngrx/store';
import { FleetManagerActions, OrganizationsActions } from 'src/app/store/actions';
import { State } from 'src/app/store/state';
import { DeploymentTypeMetadata } from '../models/deploymentForm.model';

@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.scss']
})
export class FleetManagementComponent implements OnInit {

  constructor(public store: Store<State>) { }

  selected = '1';
  deploymentForms: DeploymentTypeMetadata[];
  
  ngOnInit() {
    this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
    this.store.dispatch(OrganizationsActions.getOrganizations());
  }

  radioChange(event: MatRadioChange) {
    this.selected = event.value;
  }
}
