import { Component, Input } from '@angular/core';
import { ToastPanelType } from '../../../config/app-constants';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {

  @Input() type: string;
  @Input() title: string;
  @Input() messages: string[]|string;
  @Input() set message(message) { this.messages = [message]; }

  iconMap = {
    [ToastPanelType.done]: 'ok',
    [ToastPanelType.warning]: 'warning',
    [ToastPanelType.error]: 'error',
    [ToastPanelType.info]: 'info',
  };

}
