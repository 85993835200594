import { createAction, props } from '@ngrx/store';

export const ADD_TO_SIGNALR_SUBLIST = '[SignalR] Add to SignalR Sub List';
export const DELETE_TO_SIGNALR_SUBLIST = '[SignalR] Delete to SignalR Sub List';

export const addSignalRSubList = createAction(
    ADD_TO_SIGNALR_SUBLIST,
    props<{ data: any; }>()
);

export const deleteSignalRSubList = createAction(
    DELETE_TO_SIGNALR_SUBLIST,
    props<{ data: any; }>()
);
