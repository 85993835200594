export const BatchListType = {
  BATCH: 'BATCH',
  ASSETS: 'ASSETS',
};

export const RunModes = {
  MANUAL: 'Manual',
  AUTORUN: 'Auto-run',
};

export interface Batch {
  tenantName: string;
  batchId: number;
  batchName: string;
  assetId: number;
  assetName: string;
  assetType: string;
  batchStatus: string;
  trayRequired: boolean;
  batchTimeSeconds: number;
  locked?: boolean;
}

export interface BatchSubGroup {
  name: string;
  data: Batch[];
  backlogTimeSeconds: number;
}

export interface BatchGroup {
  name: string;
  data: BatchSubGroup[];
  backlogTimeSeconds: number;
}

export interface Asset {
  // assetId: string;
  assetName: string;
  // assetType: string;
}
