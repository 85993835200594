import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { System } from '../../models/device.model';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, shareReplay, startWith, Subscription, switchMap, tap } from 'rxjs';
import { selectSiteByCustomerNo } from 'src/app/store/selectors/organizations.selector';
import { getProvision } from 'src/app/store/selectors/fleet-manager.selector';

/** 
 * Encapsulates logic for an autocomplete control that selects an available system. 
 * Form value is the system's serial number as entered in customer management.
 */
@Component({
  selector: 'app-system-select',
  templateUrl: './system-select.component.html',
  styleUrls: ['./system-select.component.scss']
})
export class SystemSelectComponent implements OnInit, OnChanges, OnDestroy {
  /** Parent site ID to filter systems on. */
  @Input() siteId: string;
  /** The parent form this control is a part of. */
  @Input() form: FormGroup;
  /** Name of the form control to bind to on the parent form. */
  @Input() controlName: string;
  /** Determines whether the backing controls are enabled/disabled. */
  @Input() disabled: boolean;

  /** Subject to emit changes whenever the parent site is changed. */
  parentSiteSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  /** NgRx subscription to system state. */
  systemSubscription: Subscription;
  /** Observable collection of available systems. */
  systems$: Observable<any[]>;
  /** Non-observable list of the latest available systems, used for validation. */
  systems: any[];
  /** Filtered list of systems to display in autocomplete control. */
  filteredSystems$: Observable<any[]>;
  /** Full list of provisioned systems used to determine if a system has already been selected. */
  provisionedSystems: System[];
  /** NgRx subscription to provisioned system state. */
  getProvisionSubscription: Subscription;
  /** Flag to indicate whether asynchronous initialization has completed. */
  initialized: boolean = false;

  /** The form control associated with this component. */
  get systemSelectControl() { return this.form.get(this.controlName) as FormControl<string>; }
  /** Retrieve non-dynamic system friendly name control, containing friendly name entered in customer management. */
  get friendlyNameControl() { return this.form.get('systemFriendlyName') as FormControl<string>; }

  constructor(
    public store: Store<State>,
    private assetService: AssetService
  ) { }

  ngOnInit(): void {
    this.systemSelectControl.addValidators(this.validateSystem().bind(this));

    // Add friendly name as hardcoded secondary value...since we need this data but can't bind two values on the main control
    this.form.addControl('systemFriendlyName', new FormControl(''));

    this.getProvisionSubscription = this.store.select(getProvision).subscribe(devices => {
      this.provisionedSystems = devices.reduce((systems, device) => systems.concat(device.systems), []);

      // Setup observable pipeline to update the available system list whenever the parent site changes
      this.systems$ = this.parentSiteSubject.asObservable().pipe(
        switchMap(siteNumber => this.store.select(selectSiteByCustomerNo(siteNumber))),
        switchMap(site => {
          if (!site) {
            return of([]);
          }
          return this.assetService.getAssetByOrgId(site?.organizationId || '').pipe(
            map(assets => assets || []),
            catchError(() => of([]))
          );
        }),
        shareReplay(1)
      );

      // Setup observable pipeline to filter systems whenever the available systems change or the user updates the control value
      this.filteredSystems$ = combineLatest([
        this.systemSelectControl.valueChanges.pipe(startWith(this.systemSelectControl.value || '')),
        this.systems$
      ]).pipe(
        map(([controlValue, systems]) => {
          const filteredSystems = systems.filter(system => system.enabled && system.machineNo);
          const selectedSystem = filteredSystems.find(system => system.machineNo.toLowerCase() === controlValue.toLowerCase());

          this.friendlyNameControl.setValue(selectedSystem?.name || '');

          return filteredSystems.filter(system =>
            system.machineNo.toLowerCase().includes(controlValue.toLowerCase()) ||
            system.name.toLowerCase().includes(controlValue.toLowerCase())
          ).map(system => ({
            ...system,
            isProvisioned: this.provisionedSystems.some(provisionedSystem => provisionedSystem.systemId.toLowerCase() === system.machineNo.toLowerCase())
          }));
        })
      );

      // Subscribe to receive latest available systems to use for validation
      this.systemSubscription = this.systems$.subscribe(systems => {
        this.systems = systems;
        this.systemSelectControl.setValue(this.systemSelectControl.value, { emitEvent: false });
      });
    });

    if (this.siteId) {
      this.parentSiteSubject.next(this.siteId);
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteId'] && (changes['siteId'].currentValue !== changes['siteId'].previousValue)) {
      if (this.initialized) {
        this.systemSelectControl.setValue('');
        this.parentSiteSubject.next(changes['siteId'].currentValue || '');
      }
    }

    if (changes['disabled']) {
      this.disabled ? this.systemSelectControl.disable() : this.systemSelectControl.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.systemSubscription) {
      this.systemSubscription.unsubscribe();
    }

    if (this.getProvisionSubscription) {
      this.getProvisionSubscription.unsubscribe();
    }
  }

  /** Display logic for textbox control. */
  displaySystem(systemId: string): string {
    return this?.systems?.find(system => system.machineNo === systemId)?.name || systemId;
  }

   /** Custom validator for system form data. */
  validateSystem(): ValidatorFn {
    return (control: AbstractControl<string, string>): ValidationErrors | null => {
      const systemControlValue = control.value;

      if (!systemControlValue) {
        return null;
      }

      if (!this.systems || this.systems.length === 0) {
        return { NoOptionsError: true };
      }

      if (!this.systems.find(system => system.machineNo === systemControlValue)) {
        return { InvalidOptionError: true };
      }

      return null;
    }
  }
}
