import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupCustomerManagementComponent } from './components/setup-customer-management/setup-customer-management.component';
import { CustomerManagementRoutingModule } from './customer-management-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ConsumablesSubscriptionComponent } from './components/consumables-subscription/consumables-subscription.component';
import { CommonModules } from '../../common/common.module';
import { OrderModule } from '../order/order.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

@NgModule({
  declarations: [
    SetupCustomerManagementComponent,
    EditCustomerComponent,
    OnboardingComponent,
    ConsumablesSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CustomerManagementRoutingModule,
    CommonModules,
    OrderModule,
    ScrollingModule,
    MatAutocompleteModule
  ],
})
export class CustomerManagementModule { }
