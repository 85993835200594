import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { ScriptsActions } from 'src/app/store/actions';
import { getAuthProfile, getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { ActivatedRoute } from '@angular/router';

export enum View {
  ORG,
  SITE
}

@Component({
  selector: 'app-inventory-master',
  templateUrl: './inventory-master.component.html',
  styleUrls: ['./inventory-master.component.scss'],
})


export class InventoryMasterComponent implements OnInit {
  deviceType;
  View = View;

  machinePermissionsATP = false;
  machinePermissionsMAX = false;
  machinePermissionsPASS = false;

  constructor(
    public store: Store<State>,
    private route: ActivatedRoute
  ) {

  //  this.runMode = RunModes.AUTORUN;
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.deviceType = data['deviceType'];
    })
   
    this.store.select(getAuthProfile).subscribe((profile) => {
      if (profile) {
        const machinePermissions = profile.machinePermissions;
        this.machinePermissionsATP = machinePermissions.includes('ATP');
        this.machinePermissionsMAX = machinePermissions.includes('MAX');
        this.machinePermissionsPASS = machinePermissions.includes('PASS');
      }
    });

    this.store.dispatch(ScriptsActions.setScriptData());
  }

}
