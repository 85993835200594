import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { map, switchMap } from 'rxjs/operators';
import * as constants from '../config/app-constants';
import * as storage from '../store/state/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private auth: AuthService,
    private toastService: ToastService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.auth.isAuthenticated$.pipe(
      switchMap(loggedIn => {
        // redirect and return false if not logged in
        if (!loggedIn) {
          console.info('Redirect to logged in!');
          this.toastService.openToast('Not Authorized!', constants.ToastPanelType.error);

          // Clear the client store(state management) on session timeout
          storage.clearStorage();

          this.auth.login(state.url);
          return of(false);
        }

        // if logged in, wait till profile loaded and check for the permission
        return this.auth.profile$.pipe(
          map((profile) => {
            const path = this.getFullPath(next);
            const canAccess = profile.hasMenuPermission(path) || profile.hasRouteAccess(path);
            if (!canAccess) {
              console.warn('No access permission', `${state.url} (${path})`);
              this.toastService.openToast('You don\'t have permission to access this page!', constants.ToastPanelType.error);
            }
            return canAccess;
          }),
        );
      }),
    );
  }

  getFullPath(next) {
    if(next.parent.routeConfig && next.parent.routeConfig.path) {
      return `${this.getFullPath(next.parent)}/${next.routeConfig.path}`;
    }
    return next.routeConfig.path;
  }
}
