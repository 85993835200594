import { SynmedGcn } from './../../models/synmed-drug';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IMToolService } from '../../services/im-tool.service';
import * as constants from 'src/app/config/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
@Component({
  selector: 'app-import-wizard-synmed-gcn',
  templateUrl: './import-wizard-synmed-gcn.component.html',
  styleUrls: ['./import-wizard-synmed-gcn.component.scss']
})
export class ImportWizardSynmedGcnComponent implements OnInit {
  @ViewChild("TABLE") table: ElementRef;
  dataSource = new MatTableDataSource<SynmedGcn>([]);
  gcnDrugList: SynmedGcn []=[];
  customerId:string;
  saveGcnDrugListModel:SynmedGcn[]=[];

  selectedGcnDrugList:SynmedGcn[]=[];


   //#region Table colums Array


displayedColumns:string[] = [
  "isChecked",
  "sl",
  "name",
  "strength",
  "containerSize",
  "noOfCanister",
  "lidHole",
  "maxCapacity",
  "customerMnemonic",
  "ndc",
  "usage"
]
//#endregion

  selectedRow:any;


  constructor(
    private imtoolService: IMToolService,
    public dialogRef: MatDialogRef<ImportWizardSynmedGcnComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    this.customerId = data.customerId;

  }

  ngOnInit(): void {
    this.getGcnDrugList();
  }
  closeDialog(response: boolean) {
    this.dialogRef.close({ event: "close", data: response });
  }



  redirect() {
    this.dialogRef.close({ event: "close", data: {status:true, wizardNumber:3} });
  //  this.route.navigate(['./im-tool']);
  }

  redirectDrugPage() {
    this.dialogRef.close({ event: "close", data: false });
    this.route.navigate(["/im-tool/export", this.customerId]);
  }
  redirectToSynmedDrugPage(){
    this.dialogRef.close({ event: "close", data: false });
    this.route.navigate([ '/im-tool/synmed-export', this.customerId]);
  }

  allDrugUnSelectOrSelect(value:boolean){
    this.selectedGcnDrugList=[];
    for(let i=0; i<this.dataSource.data.length; i++){
      if(value){
        this.drugCellRowChecked(this.dataSource.data[i], value);
      }
      this.dataSource.data[i].isChecked = value;
    }
  }

  drugCellRowUpdate(row:any){
    if(this.alertMessageForLidHoleSize(row.lidHole, row.noOfCanister))
     return;

    for(let i=0; i<this.selectedGcnDrugList.length; i++){
      if(this.selectedGcnDrugList[i].ndc === row.ndc && this.selectedGcnDrugList[i].isChecked === true){
        this.selectedGcnDrugList[i].noOfCanister= row.noOfCanister;
        break;
      }
    }
  }
  drugCellRowChecked(row:any, isChecked:boolean){
    if (isChecked) {
      if(this.isAlreadyAddedThisGcnDrug(row)){
        this.toastService.openToast(
          `NDC drug already added, deselect and add again!!`,
          constants.ToastPanelType.warning,
          2
        );
      }
      else{
        this.selectedGcnDrugList.push({
          sl: row.sl,
          ndc: row.ndc,
          name: row.name,
          strength: row.strength,
          containerSize:row.containerSize,
          noOfCanister: row.noOfCanister,
          lidHole: row.lidHole,
          maxCapacity: row.maxCapacity,
          isChecked: true,
          customerMnemonic:row.customerMnemonic,
          usage:row.usage
        });

      }
    } else {
      let index = this.selectedGcnDrugList.findIndex(
        (x) => x.ndc == row.ndc && x.sl == row.sl
      );
      if(index>=0){
        this.selectedGcnDrugList.splice(index, 1);
      }

    }
  }
  isAlreadyAddedThisGcnDrug(row):boolean{
    let output = false;
      for(let i=0; i<this.selectedGcnDrugList.length; i++){
        if(this.selectedGcnDrugList[i].ndc === row.ndc){
          output= true;
          break;
        }
      }
      return output;
  }

  alertMessageForLidHoleSize(lidHole:number, noOfCanister:number):boolean{
    if(lidHole >=120 && noOfCanister <= 1){
    this.toastService.openToast('Lidhole > 120 cannot have single location', constants.ToastPanelType.warning, 2);
    return true;
    }
    return false;
  }

  saveGcnDrugs(){
  const GNCDRUGSYNMED_KEY = 'Gncdrugsynmedkey'
    this.ngxLoader.start(GNCDRUGSYNMED_KEY);
    this.selectedGcnDrugList.forEach(row =>{
     if(this.alertMessageForLidHoleSize(row.lidHole, row.noOfCanister))
     {
      return;
     }
      else{
        this.saveGcnDrugListModel.push({
          sl: row.sl,
          ndc: row.ndc,
          name: row.name,
          strength: row.strength,
          containerSize:row.containerSize,
          noOfCanister: row.noOfCanister,
          lidHole : row.lidHole,
          maxCapacity : row.maxCapacity,
          isChecked: row.isChecked,
          customerMnemonic:row.customerMnemonic,
          usage:row.usage
        })
      }
       });

    this.imtoolService.synmedGcnDrugAdd(this.customerId,this.selectedGcnDrugList).subscribe((result)=>{
      this.ngxLoader.stop(GNCDRUGSYNMED_KEY);
      if(result.statusCode===200){
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.dataSource.data = [];
        this.selectedGcnDrugList=[];
        this.saveGcnDrugListModel=[];
        this.gcnDrugList = [];
        this.redirectToSynmedDrugPage();
        this.closeDialog(true);
      }
      else{
        this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);
      }

    },(error: HttpErrorResponse) => {

      this.ngxLoader.stop(GNCDRUGSYNMED_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  openAlertWindow() {

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Export Duplicate NDC List",
        bodyMessage: "Are you sure you want to export this drug list?",
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {

      } else {
        console.log("not export", result);
      }
    });
  }

  getGcnDrugList(){
    const GCNDRUGLISTSYNMED_KEY = 'Gcndruglistsynmedkey'
    this.ngxLoader.start(GCNDRUGLISTSYNMED_KEY);
    this.imtoolService.getSynmedGcnDrugs(this.customerId).subscribe((response)=>{
      let result = response.data;
      let sl = 1;
      result?.forEach(element => {
        this.drugCellRowChecked(element, true);
        this.gcnDrugList.push({
          isChecked: true,
          sl: element.sl,
          name: element.name,
          strength: element.strength,
          containerSize:element.containerSize,
          noOfCanister: element.lidHole >=120 ? 2 : element.noOfCanister,
          lidHole: element.lidHole,
          maxCapacity: element.maxCapacity,
          customerMnemonic: element.customerMnemonic,
          ndc: element.ndc,
          usage: element.usage

        })
      });

      this.dataSource.data = this.gcnDrugList;
      this.ngxLoader.stop(GCNDRUGLISTSYNMED_KEY);
    },(error: HttpErrorResponse)=>{
      this.ngxLoader.stop(GCNDRUGLISTSYNMED_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

}
