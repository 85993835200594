
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'noHtml'
})

export class NoHTmlPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            // Remove HTML tags using a simple regular expression
            return value.replace(/<[^>]*>/g, '');
          }
    }

}
