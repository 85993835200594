import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PowerBiService } from '../../services/power-bi.service';
import { models } from 'powerbi-client';
import { ReportToken } from '../../models/report-token';
import { ReportTokenService } from '../../services/reporttoken.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as constants from '../../../../config/app-constants';
import { ReportService } from '../../services/report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../../../shared/services/toast.service';
import { Report } from '../../models/report';
import { AppCardOptions } from '../../../../shared/models/ui/app-card-options';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
})
export class ReportViewerComponent implements OnInit {
  @Input() embedReportId: string | number;

  @ViewChild('pbiContainer', { static: true }) pbiContainerRef: ElementRef<HTMLElement>;

  reportToken: ReportToken;
  reportId: string | number;
  isNotFound = false;
  reports: Report[];
  options: AppCardOptions[];
  selectedIndex: number;

  private pbiContainerElement: HTMLElement;

  constructor(
    private reportTokenService: ReportTokenService,
    private reportService: ReportService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private powerBiService: PowerBiService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.reportId = params.reportId;
      this.getPermittedReports();
      this.initReport();
    });
  }

  private initReport() {
    this.isNotFound = false;
    this.ngxLoader.start();

    this.reportTokenService.getByID(this.reportId).subscribe(result => {

      this.reportToken = result;

      const embedConfig = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: result.token,
        embedUrl: result.embedURL,
        permissions: models.Permissions.All,
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        },
      };

      this.pbiContainerElement = this.pbiContainerRef.nativeElement;
      this.powerBiService.embed(this.pbiContainerElement, embedConfig);
      this.ngxLoader.stop();
      this.isNotFound = false;
    }, (error: HttpErrorResponse) => {
      console.error(error);
      this.isNotFound = true;
      this.ngxLoader.stop();
    });
  }


  private getPermittedReports(): void {
    this.ngxLoader.start();
    this.reportService.getAll().subscribe(reports => {
      reports = reports.sort((a, b) => { return (a.reportName.toLocaleLowerCase() < b.reportName.toLocaleLowerCase() ? -1 : 1); });

      this.options = reports.map((report) => ({
        title: report.reportName,
        isActivated: report.reportId === this.reportId,
        routerLink: `/reports/${report.reportId}`,
        ...report,
      }));

      this.selectedIndex = reports.findIndex(({ reportId }) => reportId === this.reportId);
      this.ngxLoader.stop();
    }, (error: HttpErrorResponse) => {
      this.options = [];
      this.toastService.openToast('An error occurred while loading report!', constants.ToastPanelType.error);
      console.error(error);
      this.ngxLoader.stop();
    },
    );
  }

}
