<app-card title="">
    <div title class="flex justify-between content-start items-start">

            <mat-icon (click)="redirectCustomerList()" style="margin-bottom: 6px;">
                <img src="/assets/img/icons/ico.16.arwlft.svg" alt="">
            </mat-icon>
            <h5 style="padding-top: 5px;" class="ucase m-0 text-primaryblue xs:basis-full basis-[81%]" tabindex="0">{{customerName}} <span *ngIf="subDeviceTypeName !== null">({{subDeviceTypeName}}, {{targetInstallDate}})</span></h5>
            <div class="flex justify-between content-center items-center flex-row">
            <mat-icon (click)="onAllUserPaginateChange()" style="padding-right: 14px; ">
                <img style="padding: 4px;" src="/assets/img/icons/ico.24.scrolltotop.svg" alt="">
            </mat-icon>
        </div>
        <div class="basis-[7%]">
          <button id="btn_cddb_add" (click)="openImportWizardCDDB()" class="btn-light btn-sm add_site"><span> CDDB Add </span></button>
        </div>

  <div class="basis-[11%]">
    <button id="btn_change_device" class="bg-red btn-sm" (click)="openImportWizard()">
        Change Device
      </button>

  </div>

            <mat-form-field floatLabel="never" class="basis-[11%]">
                <mat-select style="margin-top: 0rem !important;" matInput [value]="0"  (selectionChange)="downloadFile($event.value)">
                    <mat-option [disabled]="true"  [value]="0" style="text-align: center; background-color: rgb(202 198 198);"><span style="font-weight: bolder;">Export</span></mat-option>
                    <mat-option  [value]="1">Export XLSX</mat-option>
                    
                </mat-select>
            </mat-form-field>
    </div>
    <section class="table-section">
        <div class="sticky_search">
            <div class="bg-white" style="padding-bottom: 6px; padding-top: 6px;">
                <div class="flex justify-start content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row">

                     <div class="search-wrapper basis-[17%]" style="padding: 9px">
                      <div class="search-container">
                          <app-icon icon="search" class="icon_search"></app-icon>
                          <input class="search-input" appNoHtmlTags inputId="searchcustomers-input" (keyup)="doFilter($event.target.value)"  minLengthToSearch="1" placeholder="Search" [ngModel]="searchText"  id="search_customers">
                          <app-icon icon="clear" *ngIf="searchText.length>0" (click)="doFilter('')" id="data-clear" class="pointer"></app-icon>
                      </div>
                  </div>

                    <div class="flex justify-start content-center items-center basis-[29%]">
                        <span class="flex justify-center content-stretch items-stretch basis-[19.3%]">Cell Type</span>
                        <mat-form-field floatLabel="never" class="flex justify-start content-stretch items-stretch basis-[50.3%] sm:basis-1/2">
                            <mat-select matInput  [value]="0" (selectionChange)="getRecommendedDrugData(customerId, $event.value)">
                                <mat-option  [value]="0">All</mat-option>
                                <mat-option  [value]="1">Small Canister : {{smallCanisterCount}}</mat-option>
                                <mat-option  [value]="2">Large Canister : {{largeCanisterCount}}</mat-option>
                             

                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                    <div style="text-align: center; margin-top: 1rem; color:red;" *ngIf="gcnDrugList.length>0">Similar GCN found for multiple drugs.<span style="text-decoration: underline;
                        color: blue;" (click)="openImportWizardGCN()">Click here</span> to adjust.</div>


            </div>
        </div>
        <div class="setup_scroll" >
            <mat-tab-group (selectedTabChange)="getCurrentTabSelect($event)">
               <mat-tab label="Analysis({{dataSource.data.length}})">
                    <div class="mat-elevation-z8" #TABLE>
                        <table  mat-table #table [dataSource]="dataSource" id ="matTable" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="allSelectedDrugUnchecked()" [disabled]="!isAnyDrugSelected" [(ngModel)]="isAnyDrugSelected"></mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>
                              <!-- Top Order Column -->
                         <ng-container matColumnDef="topOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                            <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                            <!--NDC column-->

                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Number </th>
                                <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                            </ng-container>

                    
                            <!-- Drug Name Column -->
                            <ng-container matColumnDef="drugName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                            </ng-container>
                           <!-- Slot Location Column -->
                           <ng-container matColumnDef="slotLocation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Slot Location </th>
                            <td mat-cell *matCellDef="let element"> {{element.slotLocation}} </td>
                          </ng-container>

                            <!-- Strength Column -->
                            <ng-container matColumnDef="strength">
                                <th  mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                                <td  mat-cell *matCellDef="let element"> {{element.strength}} </td>
                            </ng-container>
                            <!-- Schedule Column -->
                            <ng-container matColumnDef="schedule">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule </th>
                                <td mat-cell *matCellDef="let element"> {{element.schedule}} </td>
                            </ng-container>
                            <!-- Manufacturer Column -->
                            <ng-container matColumnDef="manufacturer">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> Manufacturer </th>
                                <td mat-cell  *matCellDef="let element"> {{element.manufacturer}}</td>
                            </ng-container>

                            <!-- 30 Dram Column -->
                            <ng-container matColumnDef="thirtyDramCapacity">
                                <th mat-header-cell  *matHeaderCellDef mat-sort-header> 30 Dram </th>
                                <td mat-cell  *matCellDef="let element"> {{element.thirtyDramCapacity}}</td>
                            </ng-container>

                            
                            <!-- Rx Qty Column -->
                        <ng-container matColumnDef="rxQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.rxQty}} </td>
                        </ng-container>
                  
                        <!-- Unit Qty Column -->
                        <ng-container matColumnDef="unitQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.unitQty}} </td>
                        </ng-container>
                        <!-- Canister Capacity Column -->
                        <ng-container matColumnDef="canisterCapacity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canister Capacity </th>
                            <td mat-cell *matCellDef="let element"> {{element.cellCapacity}}</td>
                        </ng-container>

                        <!-- Extender Capacity Column -->
                        <ng-container matColumnDef="extenderCapacity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Extender Capacity </th>
                            <td mat-cell *matCellDef="let element"> {{element.extenderCapacity}} </td>
                        </ng-container>

                        <!-- Canister No Column -->
                        <ng-container matColumnDef="canisterNo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canister No </th>
                            <td mat-cell *matCellDef="let element"> {{element.canisterNo}} </td>
                        </ng-container>

                          <!-- Canister Loc Column -->
                          <ng-container matColumnDef="canisterLoc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location Body </th>
                            <td mat-cell *matCellDef="let element"> {{element.cellLoc}} </td>
                         </ng-container>
 
                        <!-- largeCanisterCapacity Column -->
                        <ng-container matColumnDef="largeCanisterCapacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>Large Canister Capacity </th>
                          <td mat-cell *matCellDef="let element"> {{element.largeCanisterCapacity}}</td>
                      </ng-container>
                      
                       <!-- IsUnregulated Column -->
                       <ng-container matColumnDef="isUnRegulated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> IsUnRegulated </th>
                        <td mat-cell *matCellDef="let element"> {{element.isUnregulated}}</td>
                    </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" (mouseenter)="selectedRow = row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <table>
                            <tr *ngIf="dataSource.data.length==0">
                                <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                                    No Records Found!
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-tab>
                
                  <mat-tab label="New Drugs({{dataSourceNew.data.length}})">
                    <div class="mat-elevation-z8" #TABLE2>
                        <table mat-table #table2 [dataSource]="dataSourceNew" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>
                  
                        <!-- Top Order Column -->
                        <ng-container matColumnDef="topOrder">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                          <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                  
                        <!-- Drug Number Column -->
                        <ng-container matColumnDef="ndc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Number </th>
                          <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                        </ng-container>
                  
                        <!-- Drug Name Column -->
                        <ng-container matColumnDef="drugName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                        </ng-container>
                  
                  
                        <!-- Strength Column -->
                        <ng-container matColumnDef="strength">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                          <td mat-cell *matCellDef="let element"> {{element.strength}} </td>
                        </ng-container>
                  
                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer </th>
                          <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
                        </ng-container>
                  
                     
                  
                        <!-- Rx Qty Column -->
                        <ng-container matColumnDef="rxQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.rxQty}} </td>
                        </ng-container>
                  
                        <!-- Unit Qty Column -->
                        <ng-container matColumnDef="unitQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.unitQty}} </td>
                        </ng-container>
                                
                  
                        <!-- SCH Column -->
                        <ng-container matColumnDef="sch">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>SCH </th>
                          <td mat-cell *matCellDef="let element"> {{element.canisterCapacity}} </td>
                        </ng-container>                                  
                   <!-- status Column -->
                   <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                  </ng-container>   

                    <!-- IsUnregulated Column -->
                    <ng-container matColumnDef="isUnRegulated">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> IsUnRegulated </th>
                      <td mat-cell *matCellDef="let element"> {{element.isUnregulated}}</td>
                  </ng-container>

                        <!-- Header and Rows -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumnNewDrug"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnNewDrug;"></tr>
                  
                      </table>
                  
                      <table>
                        <tr *ngIf="dataSourceNew.data.length == 0">
                          <td style="text-align: center;" [attr.colspan]="displayedColumnNewDrug.length">
                            No Records Found!
                          </td>
                        </tr>
                      </table>
                    </div>
                  </mat-tab>
            

                  <mat-tab label="Excluded({{dataSourceExcluded.data.length}})">
                    <div class="mat-elevation-z8" #TABLE3>
                        <table mat-table #table3 [dataSource]="dataSourceExcluded" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>
                  
                        <!-- Top Order Column -->
                        <ng-container matColumnDef="topOrder">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                          <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                  
                        <!-- Drug Number Column -->
                        <ng-container matColumnDef="ndc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Number </th>
                          <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                        </ng-container>
                  
                        <!-- Drug Name Column -->
                        <ng-container matColumnDef="drugName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                        </ng-container>
                  
                  
                        <!-- Strength Column -->
                        <ng-container matColumnDef="strength">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                          <td mat-cell *matCellDef="let element"> {{element.strength}} </td>
                        </ng-container>
                  
                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer </th>
                          <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
                        </ng-container>
                  
                     
                  
                        <!-- Rx Qty Column -->
                        <ng-container matColumnDef="rxQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.rxQty}} </td>
                        </ng-container>
                  
                        <!-- Unit Qty Column -->
                        <ng-container matColumnDef="unitQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.unitQty}} </td>
                        </ng-container>
                                
                  
                        <!-- SCH Column -->
                        <ng-container matColumnDef="sch">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>SCH </th>
                          <td mat-cell *matCellDef="let element"> {{element.canisterCapacity}} </td>
                        </ng-container>                                  
                   <!-- status Column -->
                   <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                  </ng-container>   
                   <!-- IsUnregulated Column -->
                   <ng-container matColumnDef="isUnRegulated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IsUnRegulated </th>
                    <td mat-cell *matCellDef="let element"> {{element.isUnregulated}}</td>
                </ng-container>
                        <!-- Header and Rows -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumnNewDrug"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnNewDrug;"></tr>
                  
                      </table>
                  
                      <table>
                        <tr *ngIf="dataSourceExcluded.data.length == 0">
                          <td style="text-align: center;" [attr.colspan]="displayedColumnNewDrug.length">
                            No Records Found!
                          </td>
                        </tr>
                      </table>
                    </div>
                  </mat-tab>
                  <mat-tab label="Not Oral Solid({{dataSourceOral.data.length}})">
                    <div class="mat-elevation-z8" #TABLE4>
                        <table mat-table #table4 [dataSource]="dataSourceOral" matSort>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="checkbox">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element">
                                  <mat-checkbox (change)="drugCellLocationChecked(element, $event.checked)" [(ngModel)]="element.checked"></mat-checkbox>
                                </td>
                            </ng-container>
                  
                        <!-- Top Order Column -->
                        <ng-container matColumnDef="topOrder">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                          <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                  
                        <!-- Drug Number Column -->
                        <ng-container matColumnDef="ndc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Number </th>
                          <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                        </ng-container>
                  
                        <!-- Drug Name Column -->
                        <ng-container matColumnDef="drugName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                        </ng-container>
                  
                  
                        <!-- Strength Column -->
                        <ng-container matColumnDef="strength">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                          <td mat-cell *matCellDef="let element"> {{element.strength}} </td>
                        </ng-container>
                  
                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer </th>
                          <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
                        </ng-container>
                  
                     
                  
                        <!-- Rx Qty Column -->
                        <ng-container matColumnDef="rxQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.rxQty}} </td>
                        </ng-container>
                  
                        <!-- Unit Qty Column -->
                        <ng-container matColumnDef="unitQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.unitQty}} </td>
                        </ng-container>
                                
                  
                        <!-- SCH Column -->
                        <ng-container matColumnDef="sch">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>SCH </th>
                          <td mat-cell *matCellDef="let element"> {{element.canisterCapacity}} </td>
                        </ng-container>                                  
                   <!-- status Column -->
                   <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                  </ng-container>   
                <!-- IsUnregulated Column -->
                <ng-container matColumnDef="isUnRegulated">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> IsUnRegulated </th>
                  <td mat-cell *matCellDef="let element"> {{element.isUnregulated}}</td>
                </ng-container>
                      <!-- IsUnregulated Column -->
                   
                        <!-- Header and Rows -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumnNewDrug"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnNewDrug;"></tr>
                  
                      </table>
                  
                      <table>
                        <tr *ngIf="dataSourceOral.data.length == 0">
                          <td style="text-align: center;" [attr.colspan]="displayedColumnNewDrug.length">
                            No Records Found!
                          </td>
                        </tr>
                      </table>
                    </div>
                  </mat-tab>
                
            
              
                <mat-tab label="All({{dataSourceAll.data.length}})">
                    <div class="mat-elevation-z8" #TABLE5>
                      <table mat-table #table5 [dataSource]="dataSourceAll" id="matTable" matSort>
                        
                        <!-- Tab Column -->
                        <ng-container matColumnDef="tab">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tab </th>
                          <td mat-cell *matCellDef="let element"> {{element.tab}} </td>
                        </ng-container>
                  
                        <!-- Top Order Column -->
                        <ng-container matColumnDef="topOrder">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Top Order </th>
                          <td mat-cell *matCellDef="let element"> {{element.topOrder}} </td>
                        </ng-container>
                  
                        <!-- Drug Number Column -->
                        <ng-container matColumnDef="ndc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Number </th>
                          <td mat-cell *matCellDef="let element"> {{element.ndc}} </td>
                        </ng-container>
                  
                        <!-- Drug Name Column -->
                        <ng-container matColumnDef="drugName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Drug Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.drugName}} </td>
                        </ng-container>
                  
                        <!-- Slot Location Column -->
                        <ng-container matColumnDef="slotLocation">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Slot Location </th>
                          <td mat-cell *matCellDef="let element"> {{element.slotLocation}} </td>
                        </ng-container>
                  
                        <!-- Strength Column -->
                        <ng-container matColumnDef="strength">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Strength </th>
                          <td mat-cell *matCellDef="let element"> {{element.strength}} </td>
                        </ng-container>
                  
                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer </th>
                          <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
                        </ng-container>
                  
                        <!-- Schedule Column -->
                        <ng-container matColumnDef="schedule">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule </th>
                          <td mat-cell *matCellDef="let element"> {{element.schedule}} </td>
                        </ng-container>
                  
                        <!-- Rx Qty Column -->
                        <ng-container matColumnDef="rxQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Rx Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.rxQty}} </td>
                        </ng-container>
                  
                        <!-- Unit Qty Column -->
                        <ng-container matColumnDef="unitQty">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Qty </th>
                          <td mat-cell *matCellDef="let element"> {{element.unitQty}} </td>
                        </ng-container>
                  
                        <!-- Thirty Dram Capacity Column -->
                        <ng-container matColumnDef="thirtyDramCapacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Thirty Dram Capacity </th>
                          <td mat-cell *matCellDef="let element"> {{element.thirtyDramCapacity}} </td>
                        </ng-container>
                  
                        <!-- Canister Capacity Column -->
                        <ng-container matColumnDef="canisterCapacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Canister Capacity </th>
                          <td mat-cell *matCellDef="let element"> {{element.cellCapacity}} </td>
                        </ng-container>
                  
                        <!-- Extender Capacity Column -->
                        <ng-container matColumnDef="extenderCapacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Extender Capacity </th>
                          <td mat-cell *matCellDef="let element"> {{element.extenderCapacity}} </td>
                        </ng-container>
                  
                        <!-- Canister No Column -->
                        <ng-container matColumnDef="canisterNo">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Canister No </th>
                          <td mat-cell *matCellDef="let element"> {{element.canisterNo}} </td>
                        </ng-container>

                          <!-- Canister Loc Column -->
                          <ng-container matColumnDef="canisterLoc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location Body </th>
                            <td mat-cell *matCellDef="let element"> {{element.cellLoc}} </td>
                        </ng-container>

                        <!-- largeCanisterCapacity Column -->
                        <ng-container matColumnDef="largeCanisterCapacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>Large Canister Capacity </th>
                          <td mat-cell *matCellDef="let element"> {{element.largeCanisterCapacity}}</td>
                      </ng-container>
                   <!-- IsUnregulated Column -->
                   <ng-container matColumnDef="isUnRegulated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IsUnRegulated </th>
                    <td mat-cell *matCellDef="let element"> {{element.isUnregulated}}</td>
                </ng-container>
                        <!-- Header and Rows -->
                        <tr mat-header-row *matHeaderRowDef="displayColumnAll"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayColumnAll;"></tr>
                  
                      </table>
                  
                      <table>
                        <tr *ngIf="dataSourceAll.data.length == 0">
                          <td style="text-align: center;" [attr.colspan]="displayColumnAll.length">
                            No Records Found!
                          </td>
                        </tr>
                      </table>
                    </div>
                  </mat-tab>
                  
            </mat-tab-group>
        </div>
    </section>
</app-card>
