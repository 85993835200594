import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})

export class OrgLevelAdminService {
  private isOrgLevelRow=false; 

  constructor() {}

  public setOrgLevelRowValue(value: boolean) {
    this.isOrgLevelRow=value;
  }

  getOrgLevelRowValue(){
    return this.isOrgLevelRow;
  } 
}
