import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { initialize, LDClient, LDFlagSet, LDUser } from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  private flag: BehaviorSubject<LDFlagSet> = new BehaviorSubject<LDFlagSet>(undefined);
  private ldClient: LDClient;

  get flagChange() {
    return this.flag.pipe(
      filter((flag) => flag !== undefined),
    );
  }

  selectUser(ldUser: LDUser) {
    if (!environment.launchDarklyClientId) { return; }

    if (!this.ldClient) {
      this.ldClient = initialize(environment.launchDarklyClientId, ldUser);
      this.ldClient.on('ready', () => this.flag.next(this.ldClient.allFlags()));
      this.ldClient.on('change', () => this.flag.next(this.ldClient.allFlags()));
    } else {
      this.ldClient.identify(ldUser);
    }
  }
}
