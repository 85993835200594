import { ImportWizardSynmedGcnComponent } from './../import-wizard-synmed-gcn/import-wizard-synmed-gcn.component';
import { SaveSynmedDrug } from './../../models/synmed-drug';
import { PassAnalyze, SynmedAnalyze } from './../../models/drug-qualifer';
import { ImportWizardDrugQualifierComponent } from '../import-wizard-drug-qualifier/import-wizard-drug-qualifier.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ColumnName, Dropdwon, DrugType } from '../../models/column-name';
import { CommonEntity, CustomerAndFileInfo } from '../../models/customer-drug-info';
import { DrugInfoNew } from "../../models/DrugInfoNew";
import { CustomerInfo, DeviceType } from '../../models/customer-info';
import { MainDeviceType } from '../../models/device-type';
import { IMToolService } from '../../services/im-tool.service';
import * as constants from 'src/app/config/app-constants';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { ImToolAtpService } from '../../services/im-tool-atp.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GroupCanister, AddGcnDrugs, AdjustGcnDrug, GcnDrugs, GroupBy, UpdateGroupCanister } from '../../models/gcn-drugs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AssignDrawerLocation, AtpDeiceDrawerCell, AtpDeviceSelectedDrawer } from '../../models/device-cell';
import { ImportWizardGcnMaxComponent } from '../import-wizard-gcn-max/import-wizard-gcn-max.component';
import { ImToolData } from '../../services/im-tool-data.service';
import { autoCanisterValidator } from '../../models/validator';
@Component({
  selector: 'app-import-wizard',
  templateUrl: './import-wizard.component.html',
  styleUrls: ['./import-wizard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ImportWizardComponent implements OnInit {
  subdeviceTypes: DeviceType[] = [];
  deviceTypes: MainDeviceType[] = ImToolData.devices;

  deviceName:string = ''
  fielName:string = ''
  drugType: DrugType[] = [];
  noishDrugType: DrugType[] = [];
  selectedDeviceTypeId: number;
  countAnalysisDrugInfo: CommonEntity[] = [];
  countryCodeList: CommonEntity[] = [];
  ndcVariantList: Dropdwon[] = [];
  dayOfUsageList: CommonEntity[] = ImToolData.dayOfUsage;
  daysPerWeekList: CommonEntity[] = ImToolData.daysPerWeek;
  datasourceList: CommonEntity[] = ImToolData.datasource;
  validationErrorMessage: string;
  drugNameSource: CommonEntity[] = [];
  errorMessages: string[] = [];

  isparentnotassociated: number = 1;
  showbackbutton: boolean = false;
  checkedDrug: string;
  isHighCapDrawerDisable: boolean = false;
  analyzeOpened: boolean = false;


  columnNameList: ColumnName[] = [];
  customerData: CustomerInfo[] = [];

  drugwithCanisterInfoModel: DrugInfoNew;
  synmedDrugModel: SaveSynmedDrug;


  customerAndFileInfoModel: CustomerAndFileInfo;

  // File Upload Information form
  isButtonSelectLargeBottle:boolean = false;
  isLowestUsageDrugSelect:boolean = false;
  isHighestUsageDrugSelect = false;
  isUnselectAll = false;


  primaryDrugName: FormControl;
  secondaryDrugName: FormControl;
  isCustomerMnemonic:FormControl;



  // Drug and Canister Information Save form
  drugWithCanisterForm: FormGroup;
  configuration: FormControl;
  deviceId: number;
  deviceTypeId: FormControl;
  isOtcExcluded: FormControl;
  isScheduleExcluded: FormControl;
  isUnitOfUsageExcluded: FormControl;
  isNioshExcluded: FormControl;
  unitOfUsageValue: FormControl;
  orderOrUsage: FormControl;
  usageValue: FormControl;
  canisterQuantity: FormControl;
  canisterSize: FormControl;
  searchText: FormControl;
  dataSourceId:FormControl;
  //Start: Drug Type check box model value take
  drugTypeOne: FormControl;
  drugTypeTwo: FormControl;
  drugTypeThree: FormControl;
  drugTypeFour: FormControl;
  drugTypeFive: FormControl;
  //End: Drug Type check box model value take

  //START: Niosh Drug checkbox model value
  drugAntineoplastic: FormControl;
  drugNonAntineoplastic: FormControl;
  drugReproductive: FormControl;

  superCellNo: FormControl;
  lockingCellNo: FormControl;
  regularCellNo: FormControl;
  superLockingCellNo: FormControl;
  scheduleExcludedValue: string[] = ["C-I"];
  noUsage: FormControl;


  minimumDaysSupply: FormControl;
  minimumDaysSupplyMax: FormControl;
 
  highCapDrawerNo: FormControl;
  smartDrawerNo: FormControl;
  dayOfUse: FormControl;
  initalCanister: FormControl;
  smartCanister: FormControl;
  highCapCanister: FormControl;
  excludeAntibiotics: FormControl;
  ndcVariant: FormControl;


  ///PASS
  passInitialSmallCanister:FormControl;
  passInitialLargeCanister:FormControl;
  autoCanister:FormControl;
  daysOfWeek:FormControl;
  // Synmed Device

  machineNumber: FormControl;
  countryCode: FormControl;
  synmedRobotNumber: FormControl;
  synmedAnalyzeData: SynmedAnalyze = {};
  

  /// pass Device

  passanalyzeData: PassAnalyze = {} as PassAnalyze;


  nioshValue: number[] = [1];

  //#region GCN Import Wizard
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild('dialogContent') dialogContent: ElementRef;
  @ViewChild('analyzeDiv', { read: ElementRef }) analyzeDiv: ElementRef;

  dataSource = new MatTableDataSource<GcnDrugs>([]);
  gcnNumberList: GroupBy[] = [];
  isColorChange: boolean = false;
  gcnDrugList: GcnDrugs[] = [];
  gcnDrugdModel: AdjustGcnDrug;
  customerId: string;
  customerName: string;
  saveGcnDrugListModel: AddGcnDrugs[] = [];
  selectedGcnDrugList: GcnDrugs[] = [];


  displayedColumns: string[] = [
    "checkbox",
    "sl",
    "drugName",
    "gcnNumber",
    "ndc",
    "packageSize",
    "manufacturer",
    "strength",
    "unitOfUsage",
    "rxQty",
  ];
  selectedRow: any;
  //#endregion

  //#region Group Canister
  @ViewChild("TABLE1") table1: ElementRef;
  dataSourceGroupCanister = new MatTableDataSource<GroupCanister>([]);


  displayedColumnsGroupCanister: string[] = [
    'checkbox',
    'topOrder',
    'name',
    'ndc',
    'strength',
    'usage',
    'daysOfPillQtyReq',
    'bottleQty',
    'maxCap',
    'hccMaxCap',
    'recCanister',
    'groupCanister',
    'recHcCanister', 
    'highCapGroupCanister',
  ];

  selectGroupCanistherList: GroupCanister[] = [];
  groupCanisterModel: UpdateGroupCanister;
  saveGroupDrugListModel: GroupCanister[] = [];
  //#endregion
  handleDeviceIdChange(deviceId: number) {
    this.deviceId=deviceId;
    this.deviceTypes.forEach(x=>{
      if(x.id == deviceId){
        this.deviceName = x.deviceName
      }
    })
    this.getSystemDevieType(deviceId);
    this.updateSubDevice();
    
  }
  handleNoUsage(noUsage:boolean){
    this.noUsage.setValue(noUsage);
    console.log(this.noUsage.value)
  }

  constructor(
    private imtoolService: IMToolService,
    private imToolAtpService: ImToolAtpService,
    public dialogRefImportWizardComponent: MatDialogRef<ImportWizardComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.customerData.push(data.customer);
    this.hidesite(data.isparentnotassociated === undefined ? 1 : data.isparentnotassociated);
    this.noUsage = new FormControl(false);
  

    this.dataSourceId = new FormControl(1, Validators.required)
    this.configuration = new FormControl(1, Validators.required);
    this.deviceTypeId = new FormControl("", Validators.required);
    this.isOtcExcluded = new FormControl(false);
    this.isScheduleExcluded = new FormControl(true);
    this.isUnitOfUsageExcluded = new FormControl(false);
    this.isNioshExcluded = new FormControl(true);
    this.unitOfUsageValue = new FormControl(0);
    this.orderOrUsage = new FormControl(1);
    this.usageValue = new FormControl(2);
    this.canisterQuantity = new FormControl("");
    this.canisterSize = new FormControl();
    this.searchText = new FormControl(null);
    this.superCellNo = new FormControl(0);
    this.lockingCellNo = new FormControl(0);
    this.drugTypeOne = new FormControl(1);
    this.drugTypeTwo = new FormControl(0);
    this.drugTypeThree = new FormControl(0);
    this.drugTypeFour = new FormControl(0);
    this.drugTypeFive = new FormControl(0);


    this.drugAntineoplastic = new FormControl(1);
    this.drugNonAntineoplastic = new FormControl(0);
    this.drugReproductive = new FormControl(0);

    this.minimumDaysSupply = new FormControl(0);
    this.autoCanister = new FormControl(0, [Validators.required, autoCanisterValidator(this.deviceTypeId.value)]);
    this.passInitialLargeCanister = new FormControl(0);
    this.passInitialSmallCanister = new FormControl(0);
    this.highCapDrawerNo = new FormControl(0);
    this.smartDrawerNo = new FormControl(0);
    this.dayOfUse = new FormControl(0);
    this.initalCanister = new FormControl(0);
    this.smartCanister = new FormControl(0);
    this.highCapCanister = new FormControl(0);

    this.regularCellNo = new FormControl(0);
    this.excludeAntibiotics = new FormControl(false);
    this.superLockingCellNo = new FormControl(0);
    this.primaryDrugName = new FormControl('2');
    this.secondaryDrugName = new FormControl('1');

    this.machineNumber = new FormControl('');
    this.synmedRobotNumber = new FormControl('');
    this.countryCode = new FormControl('');
    this.ndcVariant = new FormControl(1);
    this.isCustomerMnemonic = new FormControl(false);
    this.daysOfWeek = new FormControl(1);
    this.drugWithCanisterForm = this.fb.group({
      dataSourceId:this.dataSourceId,
      minimumDaysSupply: this.minimumDaysSupply,
      autoCanister:this.autoCanister,
      passInitialLargeCanister:this.passInitialLargeCanister,
      passInitialSmallCanister: this.passInitialSmallCanister,
      highCapDrawerNo: this.highCapDrawerNo,
      smartDrawerNo: this.smartDrawerNo,
      dayOfUse: this.dayOfUse,
      initalCanister: this.initalCanister,
      smartCanister: this.smartCanister,
      drugAntineoplastic: this.drugAntineoplastic,
      drugNonAntineoplastic: this.drugNonAntineoplastic,
      drugReproductive: this.drugReproductive,
      drugTypeOne: this.drugTypeOne,
      drugTypeTwo: this.drugTypeTwo,
      drugTypeThree: this.drugTypeThree,
      drugTypeFour: this.drugTypeFour,
      drugTypeFive: this.drugTypeFive,
      configuration: this.configuration,
      deviceTypeId: this.deviceTypeId,
      isOtcExcluded: this.isOtcExcluded,
      isScheduleExcluded: this.isScheduleExcluded,
      isUnitOfUsageExcluded: this.isUnitOfUsageExcluded,
      canisterQuantity: this.canisterQuantity,
      canisterSize: this.canisterSize,
      searchText: this.searchText,
      scheduleExcludedValue: this.scheduleExcludedValue,
      nioshValue: this.nioshValue,
      superCellNo: this.superCellNo,
      lockingCellNo: this.lockingCellNo,
      superLockingCellNo: this.superLockingCellNo,
      isNioshExcluded: this.isNioshExcluded,
      unitOfUsageValue: this.unitOfUsageValue,
      excludeAntibiotics: this.excludeAntibiotics,
      highCapCanister: this.highCapCanister,
      isHighCapDrawerDisable: this.isHighCapDrawerDisable,
      primaryDrugName: this.primaryDrugName,
      secondaryDrugName: this.secondaryDrugName,
      machineNumber: this.machineNumber,
      synmedRobotNumber: this.synmedRobotNumber,
      countryCode: this.countryCode,
      ndcVariant: this.ndcVariant,
      daysOfWeek: this.daysOfWeek
    });
    this.customerId = this.customerData[0].customerId;

  }

  ngOnInit(): void {
   
    this.ndcVariantList.push({key:1, value:'CDDB Drug Id'}, 
    {key: 2, value:'Customer Drug Id'}, 
    {key:3, value:'Customer Mnemonic'})

    this.drugNameSource.push(
      { key: 'Customer Drug Name', value: '1' },
      { key: 'FDB Brand', value: '2' },
      { key: 'FDB Generic', value: '3' },
      { key: 'FDB Tall Man Name', value: '4' }
    )

 


    this.getDeviceType();

    if (this.isparentnotassociated === 2) {
      this.getImportWizardData()
    }
  }

  onScroll() {
    const checkContent = () => {
      if (this.analyzeDiv && this.analyzeDiv.nativeElement) {
        this.dialogContent.nativeElement.scrollTop = this.dialogContent.nativeElement.scrollHeight;
      } else {
        setTimeout(checkContent, 100);
      }
    };
    setTimeout(checkContent, 0);
  }

  updateSubDevice() {
    if(this.deviceTypeId.value === 20 || this.deviceTypeId.value === 21){
      this.autoCanister.setValue(1);
    }
    else{
      this.autoCanister.setValue(0);
    }
  
    this.drugWithCanisterForm.controls["autoCanister"].setValidators(autoCanisterValidator(this.deviceTypeId.value));
  }
  validationForPassDevice() {
    const deviceType = this.deviceTypeId.value;
    const limits = ImToolData.deviceTypeLimits[deviceType];
  
    if (!limits) {
      return null;
    }
  
    const value = this.autoCanister.value;
    const { min, max } = limits;

    if (value < min || value > max) {
      this.toastService.openToast(
        `The number of Auto Canisters must be between ${min} and ${max}.`,
        constants.ToastPanelType.warning,
        3
      );
      return true;
    }
    return false;
  }
  getAutoCanisterErrorMessage(): string {
    const control = this.drugWithCanisterForm.get('autoCanister');
  
    if (control?.hasError('autoCanisterInvalid')) {
      const deviceTypeId = this.deviceTypeId.value;
  
      switch (deviceTypeId) {
        case 18:
        case 19:
          return 'The number of Auto Canisters must be between 0 to 4.';
        case 20:
          return 'The number of Auto Canisters must be between 0 to 6.';
        case 21:
          return 'The number of Auto Canisters must be between 0 to 8.';
        default:
          return '';
      }
    }
  
    if (control?.hasError('autoCanisterExceeded')) {
      return 'The number of Auto Canisters exceeds the available large canisters.';
    }
  
    return '';
  }
  
  isEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }




  onChange(schedule: string, isChecked: boolean) {
    if (isChecked) {
      this.scheduleExcludedValue.push(schedule)
    } else {
      let index = this.scheduleExcludedValue.findIndex(
        (x) => x == schedule
      );
      this.scheduleExcludedValue.splice(index, 1);
      if (schedule == "C-I")
        this.drugTypeOne.setValue(0)
      else if (schedule == "C-II")
        this.drugTypeTwo.setValue(0)
      else if (schedule == "C-III")
        this.drugTypeThree.setValue(0)
      else if (schedule == "C-IV")
        this.drugTypeFour.setValue(0)
      else if (schedule == "C-V")
        this.drugTypeFive.setValue(0)
    }
  }


  resetDrugsCheckbox() {
    if (this.isScheduleExcluded.value == false) {
      this.scheduleExcludedValue = [];
      this.drugTypeOne.setValue(0)
      this.drugTypeTwo.setValue(0)
      this.drugTypeThree.setValue(0)
      this.drugTypeFour.setValue(0)
      this.drugTypeFive.setValue(0)
    }
    if (this.isNioshExcluded.value == false) {
      this.nioshValue = [];
      this.drugAntineoplastic.setValue(0)
      this.drugNonAntineoplastic.setValue(0)
      this.drugReproductive.setValue(0)
    }
    if (this.isUnitOfUsageExcluded.value == false) {
      this.unitOfUsageValue.setValue(0);
    }
  }

  onChangeNioshDrug(schedule: number, isChecked: boolean) {

    if (isChecked) {
      this.nioshValue.push(schedule);
    } else {
      let index = this.nioshValue.findIndex((x) => x == schedule);
      this.nioshValue.splice(index, 1);
      if (schedule == 1)
        this.drugAntineoplastic.setValue(0)
      else if (schedule == 2)
        this.drugNonAntineoplastic.setValue(0)
      else if (schedule == 3)
        this.drugReproductive.setValue(0)
    }
  }

  isUndefined(variable): boolean {
    return typeof variable === 'undefined' || variable === null

  }
  synmedValidation(): boolean {
    if (this.isUndefined(this.machineNumber.value) || this.machineNumber.value.length === 0) {
      return false
    }
    if (this.isUndefined(this.synmedRobotNumber.value) || this.synmedRobotNumber.value.length === 0) {
      return false
    }
    
    return true;
  }

  getSystemDevieType(id: any) {


    if (id == 4) {
      this.getCountries();
    }
    this.analyzeOpened = false;
    const SYSTEMKEY = 'Systemkey'
    this.ngxLoader.start(SYSTEMKEY)
    this.imtoolService.getSystemDeviceType(id).subscribe(
      (response) => {
        let result = response.data;
        this.subdeviceTypes = [];
        result?.forEach(element => {
          this.subdeviceTypes.push({
            subDeviceTypeId: element.id,
            deviceTypeId: element.deviceTypeId,
            subDeviceTypeName: element.deviceTypeName,
            isActive: element.isActive
          })
        });
        this.resetValueUnderAnalyze();
        this.ngxLoader.stop(SYSTEMKEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(SYSTEMKEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  getCountries() {
    const COUNTRY_KEY = 'countrykey'
    this.ngxLoader.start(COUNTRY_KEY)
    this.imtoolService.getCountries().subscribe(
      (response: any) => {
        let result = response.data;
        this.countryCodeList = [];
        result?.forEach(element => {
          this.countryCodeList.push({
            key: element.key,
            value: element.value
          })
        });
        this.ngxLoader.stop(COUNTRY_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(COUNTRY_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  getDeviceType() {
    const  DEVICETYPE_KEY = 'Devicetype'
    this.ngxLoader.start(DEVICETYPE_KEY)
    this.imtoolService.getDeviceType().subscribe(
      (result) => {
        this.deviceTypes = result.data;
        this.ngxLoader.stop(DEVICETYPE_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(DEVICETYPE_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  resetValueUnderAnalyze() {
    this.countAnalysisDrugInfo = [];
    this.deviceTypeId.setValue(null);
  }



  getInvalidSuperCellMessage() {
    if (this.hasInvalidSuperCell()) {
      if (this.deviceId === 5) {
        return "Minimum Super cells value is 0";
      }
      return "Minimum Super cells value is 22";
    }
    return null;  // No error message if validation passes
  }
  drugWithCanisterInfoSave() {
    if (!this.isFormValid()) return;
  
 
    const invalidSuperCellMessage = this.getInvalidSuperCellMessage();
    
    if (invalidSuperCellMessage) {
      this.toastService.openToast(
        invalidSuperCellMessage,
        constants.ToastPanelType.error,
        2
      );
      return;
    }
  
    this.prepareDrugWithCanisterModel();
  
    const DEVICEINFOSAVE_KEY = 'Deviceinfosavekey';
    this.ngxLoader.start(DEVICEINFOSAVE_KEY);
    this.imtoolService
      .drugInfoWithCanisterSaveNew(this.drugwithCanisterInfoModel)
      .subscribe(
        (result) => {
          this.ngxLoader.stop(DEVICEINFOSAVE_KEY);
          this.handleSaveResult(result);
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(DEVICEINFOSAVE_KEY);
          this.handleError(error);
        }
      );
  }
  
  isFormValid() {
    return this.drugWithCanisterForm.valid &&
      !this.validataionMessage() &&
      !this.atpValidationForAnalyze() &&
      this.analyzeOpened !== false;
  }
  
  hasInvalidSuperCell() {
    return (this.deviceId === 1 && this.deviceTypeId.value === 1 && this.analyzeOpened && this.superCellNo.value < 22) ||
      (this.deviceId === 5 && this.analyzeOpened && this.superCellNo.value < 0);
  }
  
  prepareDrugWithCanisterModel() {
    const formValue = this.drugWithCanisterForm.value;
    this.drugwithCanisterInfoModel = {
      customerId: this.customerData[0].customerId,
      deviceTypeId: formValue.deviceTypeId == null ? 0 : formValue.deviceTypeId,
      deviceId: this.deviceId,
      unitOfUsageValue: formValue.unitOfUsageValue,
      isOtcExcluded: formValue.isOtcExcluded,
      isScheduleExcluded: formValue.isScheduleExcluded,
      isUnitOfUsageExcluded: formValue.isUnitOfUsageExcluded,
      atpExcludedValue: "",
      isAtpExcluded: false,
      regularCellNo: 0,
      availableCellLoc: "",
      superCellNo: formValue.superCellNo,
      lockingCellNo: formValue.lockingCellNo,
      superLockingCellNo: formValue.superLockingCellNo,
      configuration: formValue.configuration,
      isNioshExcluded: formValue.isNioshExcluded,
      nioshValue: this.nioshValue.toString(),
      scheduleExcludedValue: this.scheduleExcludedValue.toString(),
      isActive: true,
      excludeAntibiotics: formValue.excludeAntibiotics == null || this.dataSourceId.value == 1 ? false : formValue.excludeAntibiotics,
      minimumDaysSupply: formValue.minimumDaysSupply,
      daysOfUse: formValue.dayOfUse ?? 0,
      smartDrawer: formValue.smartDrawerNo,
      highCapDrawer: formValue.highCapDrawerNo,
      initialCanister: formValue.initalCanister,
      initialSmartCanister: formValue.smartCanister,
      initialHighCapCanister: formValue.highCapCanister,
      primaryDrugKey: formValue.primaryDrugName,
      secondaryDrugKey: formValue.secondaryDrugName,
      ndcVariant: formValue.ndcVariant,
      daysOfWeek: formValue.daysOfWeek,
      passAutoCanister: formValue.autoCanister,
      passLargeCanister: formValue.passInitialSmallCanister,
      passSmallCanister: formValue.passInitialLargeCanister,
      dataSource: formValue.dataSourceId
    };
  }
  
  handleSaveResult(result) {
    if (result.statusCode === 200) {
      this.toastService.openToast(
        result.message,
        constants.ToastPanelType.done,
        2
      );
      this.hidesite(3);
    } else {
      this.toastService.openToast(
        result.message,
        constants.ToastPanelType.error,
        2
      );
    }
  }
  
  handleError(error: HttpErrorResponse) {
    this.toastService.openToast(
      error.error.message ?? error.message,
      constants.ToastPanelType.error,
      2
    );
  }
  
  synmedDrugSave() {
    if (!this.drugWithCanisterForm.valid || this.analyzeOpened === false || !this.synmedValidation()) {
      return;
    }

    let formValue = this.drugWithCanisterForm.value;
    this.synmedDrugModel = {
      customerId: this.customerData[0].customerId,
      deviceTypeId: formValue.deviceTypeId == null ? 0 : formValue.deviceTypeId,
      deviceId: this.deviceId,
      dataSource:formValue.dataSourceId,
      unitOfUsageValue: formValue.unitOfUsageValue,
      isOtcExcluded: formValue.isOtcExcluded,
      isScheduleExcluded: formValue.isScheduleExcluded,
      isUnitOfUsageExcluded: formValue.isUnitOfUsageExcluded,
      isNioshExcluded: formValue.isNioshExcluded,
      nioshValue: this.nioshValue.toString(),
      scheduleExcludedValue: this.scheduleExcludedValue.toString(),
      machineNumber: formValue.machineNumber,
      synmedRobotNumber: formValue.synmedRobotNumber,
      countryCode: formValue.countryCode,
      ndcVariant:formValue.ndcVariant,
      excludeAntibiotics: formValue.excludeAntibiotics == null  || this.dataSourceId.value == 1?false: formValue.excludeAntibiotics

    };
    const SYNMEDDEVICEINFO_KEY = 'Synmeddeviceinfokey'
    this.ngxLoader.start(SYNMEDDEVICEINFO_KEY);
    this.imtoolService
      .synmedDrugInfoWithCanisterSave(this.synmedDrugModel)
      .subscribe(
        (result) => {
          this.ngxLoader.stop(SYNMEDDEVICEINFO_KEY);
          if (result.statusCode == 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );

            this.hidesite(3)
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(SYNMEDDEVICEINFO_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  endPointRequestSentBasedOnDevice(isWizardLoad: boolean = true) {
    switch(this.deviceId){
      case 1:
      case 5:
          this.getDrugMaxAnalysisData();
      break;
    case 2:
      this.getPassAnalysisData();
      break;
    case 3:
      this.getDrugAtpAnalysisData();
      break;
    case 4:
      if (isWizardLoad === true) {
        this.openImportWizardDrugQualifier();
      }
      break;
    }
   
    setTimeout(() => {
      this.onScroll();
    }, 3000);
  }

  openImportWizardDrugQualifier() {
    if(this.deviceTypeId.value>0){
    const dialogRef = this.dialog.open(ImportWizardDrugQualifierComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId: this.deviceId,
        subDeviceId:this.deviceTypeId.value
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.synmedAnalyzeData.bothSmallCount = result.data.bothSmallCount;
        this.synmedAnalyzeData.bothLargeCount = result.data.bothLargeCount;
        this.synmedAnalyzeData.bothEitherCount = result.data.bothEitherCount;
        this.synmedAnalyzeData.fragileCount = result.data.fragileCount;
        this.synmedAnalyzeData.largeEitherCount = result.data.largeEitherCount;
        this.synmedAnalyzeData.largeLargeCount = result.data.largeLargeCount;
        this.synmedAnalyzeData.largeSmallCount = result.data.largeSmallCount;
        this.synmedAnalyzeData.schedule2DrugCount = result.data.schedule2DrugCount;
        this.synmedAnalyzeData.smallEitherCount = result.data.smallEitherCount;
        this.synmedAnalyzeData.smallLargeCount = result.data.smallLargeCount;
        this.synmedAnalyzeData.smallSmallCount = result.data.smallSmallCount;
        this.synmedAnalyzeData.toggleCount = result.data.toggleCount;
        this.synmedAnalyzeData.nonCetritifedDrugs = result.data.nonCetritifedDrugs;
        this.analyzeOpened = true;
      } else {
        console.log("No change implement", result);
      }
      this.synmedDrugSave();
    });
    }
    else{
      this.toastService.openToast(
        'please select the sub-device',
        constants.ToastPanelType.warning,
        2
      );
    }
  }

  openImportWizardSynmedGcn() {
    const dialogRef = this.dialog.open(ImportWizardSynmedGcnComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.status) {
        this.hidesite(result.data.wizardNumber);
      } else {
        this.dialogRefImportWizardComponent.close()
        console.log("Cancel=", result);
      }
    });
  }

  openImportWizardMaxGcnComponent() {
    const dialogRef = this.dialog.open(ImportWizardGcnMaxComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId:this.deviceId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data.status) {
        this.hidesite(result.data.wizardNumber);
      } else {
        this.dialogRefImportWizardComponent.close()
        console.log("Cancel=", result);
      }
    });
  }
  getDrugAtpAnalysisData() {
    if(this.dayOfUse.value === null || this.dayOfUse.value === 0){
      return;
    }
    const ATPANALYSISDATA_KEY = 'Atpanalysisdatakey' 
    this.ngxLoader.start(ATPANALYSISDATA_KEY);
    this.imToolAtpService.getAtpAnalizeData(this.deviceTypeId.value, this.customerData[0].customerId,
      this.dayOfUse.value, this.highCapDrawerNo.value, this.smartDrawerNo.value).subscribe((result) => {
        this.ngxLoader.stop(ATPANALYSISDATA_KEY);
        this.countAnalysisDrugInfo = result.data;
        this.analyzeOpened = true;
      },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(ATPANALYSISDATA_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        })
  }
  getPassAnalysisData(){
    if (!this.validateDeviceTypeSelection()) {
      return; // Exit if validation fails
    }
    if(this.validationForPassDevice()){
        return;
    }
    const Passkey = 'Passanalysisdatakey' ;
    this.ngxLoader.start(Passkey);
    this.imtoolService.getPassAnalysisData(
      this.deviceTypeId.value,
      this.customerData[0].customerId, 
      this.minimumDaysSupply.value, 
      this.autoCanister.value, 
      1,
     // this.dayOfUse.value,
  1//  this.daysOfWeek.value,
  ).subscribe((response) => {
      this.ngxLoader.stop(Passkey);
      response.data.forEach(item => {
        switch (item.key) {
          case 'Small Location':
            this.passanalyzeData.availableSmallLocation = item.value;
            break;
          case 'Large Location':
            this.passanalyzeData.availableLargeLocation = item.value;
            break;
          case 'Small Canister':
            this.passanalyzeData.smallCanisterDrugs = item.value;
            break;
          case 'Large Canister':
            this.passanalyzeData.largeCanisterDrugs = item.value;
            break;
        }
      });
      this.analyzeOpened = true;
    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(Passkey);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }
  validateDeviceTypeSelection(): boolean {
    // Check if device type is selected
    if (this.deviceTypeId.value === 0 || this.deviceTypeId.value === null || this.deviceTypeId.value === '') {
      this.toastService.openToast(
        'You must select a sub-device type to proceed. Please choose a valid option from the list.',
        constants.ToastPanelType.error,
        2
      );
      return false;
    }
  
    // Check if auto canister is valid
    if (this.autoCanister == null || this.autoCanister.value === '') {
      this.toastService.openToast(
        'Auto Canister cannot be null or empty. Please provide a valid value.',
        constants.ToastPanelType.error,
        2
      );
      return false;
    }
  
    // If both validations pass
    return true;
  }
  
  getDrugMaxAnalysisData() {
    const  MAXANALYSISDATA_KEY = 'Maxanalysisdatakey' ;
    this.ngxLoader.start(MAXANALYSISDATA_KEY);
    this.imtoolService.getAnalysisDataById(this.customerData[0].customerId, this.dataSourceId.value).subscribe((result) => {
      this.ngxLoader.stop(MAXANALYSISDATA_KEY);
      this.countAnalysisDrugInfo = result.data;
      this.analyzeOpened = true;
    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(MAXANALYSISDATA_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }


hidesite(wizardNumber: number) {
  this.isparentnotassociated = wizardNumber;
  this.showbackbutton = this.isparentnotassociated === 2;
  if (this.isparentnotassociated === 3) {
    this.getGcnDrugList();
  }
  if (this.isparentnotassociated === 4 && this.deviceId == 3) {
    this.getGroupCanisterLocation();
    this.deviceAllCellGenerate();
  }
   if (this.isparentnotassociated === 4 && (this.deviceId === 1 || this.deviceId == 5)) {
            this.openImportWizardMaxGcnComponent();
    }
  if (this.isparentnotassociated === 4 && this.deviceId === 4) {
    this.openImportWizardSynmedGcn();
  }

}

  validataionMessage(): boolean {
    if (this.isSuperCellExceeded() || this.isSuperCellBelowMinimum() || 
        this.isSuperLockingCellExceeded() || this.isLockingCellExceeded() || 
        this.isConfigurationInvalid() || this.isDaysOfWeekUndefined() || this.isPassInitialCanisterExceeded() ||
        this.isDayOfUseInvalid()) {
      return true;
    }
    
    return this.isAnalyzeButtonDisabled();
  }
  
  isSuperCellExceeded(): boolean {
    if (this.deviceId === 1 && this.superCellNo.value > 89) {
      this.toastService.openToast("Super Quantity exceeds the superMax limit", constants.ToastPanelType.error, 2);
      this.superCellNo.setValue(0);
      return true;
    }
    if (this.deviceId === 5 && this.superCellNo.value > 23) {
      this.toastService.openToast("Super Quantity exceeds the superMax limit: 23", constants.ToastPanelType.error, 2);
      this.superCellNo.setValue(0);
      return true;
    }
    return false;
  }
  
  isSuperCellBelowMinimum(): boolean {
    if (this.superCellNo.value < 0) {
      this.toastService.openToast("Minimum SuperCell quantity: 0", constants.ToastPanelType.error, 2);
      this.superCellNo.setValue(0);
      return true;
    }
    return false;
  }
  
  isSuperLockingCellExceeded(): boolean {
    if ((this.deviceId === 1 || this.deviceId === 5) && this.superLockingCellNo.value > this.superCellNo.value) {
      this.toastService.openToast("Super Locking Cell cannot exceed Super Cell", constants.ToastPanelType.error, 2);
      this.superLockingCellNo.setValue(0);
      return true;
    }
    return false;
  }

  isPassInitialCanisterExceeded(): boolean {
    if(this.deviceId === 2 && this.passInitialSmallCanister.value > this.passanalyzeData.availableSmallLocation + this.passanalyzeData.availableLargeLocation){
      let sum = this.passanalyzeData.availableSmallLocation + this.passanalyzeData.availableLargeLocation;
      this.toastService.openToast("Initial Canisters should not be more than sum of Available Small and Large Locations : " + sum, constants.ToastPanelType.error, 2 );
      this.passInitialSmallCanister.setValue(0);
      return true;
    }
    return false;
  }
  
  isLockingCellExceeded(): boolean {
    const maxLockingCellForDevice1 = 200 - (this.superCellNo.value * 2);
    const maxLockingCellForDevice5 = 49 - (this.superCellNo.value * 2);
  
    if (this.deviceId === 1 && this.lockingCellNo.value > maxLockingCellForDevice1) {
      this.toastService.openToast("Locking cell cannot exceed regular Cell: " + maxLockingCellForDevice1, constants.ToastPanelType.error, 2);
      this.lockingCellNo.setValue(0);
      return true;
    }
  
    if (this.deviceId === 5 && this.lockingCellNo.value > maxLockingCellForDevice5) {
      this.toastService.openToast("Locking cell cannot exceed regular Cell: " + maxLockingCellForDevice5, constants.ToastPanelType.error, 2);
      this.lockingCellNo.setValue(0);
      return true;
    }
  
    return false;
  }
  
  isConfigurationInvalid(): boolean {
    if ((this.deviceId === 1 || this.deviceId === 5) && this.configuration.value === 2 &&
        this.lockingCellNo.value <= 0 && this.superLockingCellNo.value <= 0) {
      return true;
    }
    return false;
  }
  
  isDaysOfWeekUndefined(): boolean {
    const daysOfWeek = this.drugWithCanisterForm.get('daysOfWeek').value;
    if ((this.deviceId === 1 || this.deviceId === 5) && this.isUndefined(daysOfWeek)) {
      return false; // Depending on your logic, adjust this condition
    }
    return false;
  }
  
  isDayOfUseInvalid(): boolean {
    if ((this.deviceId === 1 || this.deviceId === 3 || this.deviceId === 5) &&
        (this.dayOfUse.value === null || this.dayOfUse.value === 0)) {
      if ((this.deviceId === 1 || this.deviceId === 5) && this.noUsage.value === true) {
        return false;
      }
      return true;
    }
    return false;
  }

  
  redirectDrugList(deviceId: number) {
    this.dialogRefImportWizardComponent.close();
    if (deviceId === 3) {
      this.route.navigate(["/im-tool/atp-export", this.customerData[0].customerId]);
    }
    else if (deviceId === 4) {
      this.route.navigate(["/im-tool/synmed-export", this.customerData[0].customerId]);
    }
    else if(deviceId === 5){
      this.route.navigate(["/im-tool/mini-export", this.customerData[0].customerId]);
    }
    else if(deviceId === 2){

    }
    else {
      this.route.navigate(["/im-tool/export", this.customerData[0].customerId]);
    }

  }


  getImportWizardData() {
    const IMPORTWIZARDDATA_KEY = 'Importwizardatakey'
    this.ngxLoader.start(IMPORTWIZARDDATA_KEY);

    this.imtoolService.getImportWizardData(this.customerData[0].customerId).subscribe((response) => {
      let result = response.data;
      let count = 0;
      let dataBindInterval = setInterval(() => {
        this.deviceId = result.deviceId;
        this.isNioshExcluded.setValue(result.isNioshExcluded);
        this.isOtcExcluded.setValue(result.isOtcExcluded);
        this.isScheduleExcluded.setValue(result.isScheduleExcluded);
        this.isUnitOfUsageExcluded.setValue(result.isUnitOfUsageExcluded);
        this.lockingCellNo.setValue(result.lockingCellNo);
        this.regularCellNo.setValue(result.regularCellNo);
        this.deviceTypeId.setValue(result.deviceTypeId);
        this.superCellNo.setValue(result.superCellNo);
        this.superLockingCellNo.setValue(result.superLockingCellNo);
        this.dataSourceId.setValue(result.dataSource);
        this.configuration.setValue(result.configurationType?.toString());
        this.highCapCanister.setValue(result.highCapCanister);
        this.dayOfUse.setValue(result.daysOfUse?.toString());
        this.initalCanister.setValue(result.initialCanister);
        this.highCapDrawerNo.setValue(result.highCapDrawer);
        this.excludeAntibiotics.setValue(result.excludeAntibiotics);
        this.highCapCanister.setValue(result.initialHighCapCanister);
        this.smartCanister.setValue(result.initialSmartCanister);
        this.minimumDaysSupply.setValue(result.minimumDaysSupply);
        this.smartDrawerNo.setValue(result.smartDrawer);
        this.countryCode.setValue(result.countryCode);
        this.machineNumber.setValue(result.machineNumber);
        this.synmedRobotNumber.setValue(result.synmedRobotNumber);
        this.noUsage.setValue(result.noUsage);
        this.ndcVariant.setValue(result.ndcVariant)
        this.isCustomerMnemonic.setValue(result.isCustomerMnemonic)
        this.autoCanister.setValue(result.passAutoCanister);
        this.passInitialLargeCanister.setValue(result.passLargeCanister);
        this.passInitialSmallCanister.setValue(result.passSmallCanister);
        if (result.scheduleExcludedValue) {
          this.scheduleExcludedValue = [];

          result.scheduleExcludedValue.split(',').forEach(x => {
            this.scheduleExcludedValue.push(x);
            if (x == 1) {
              this.drugTypeOne.setValue(x);
            }
            else if (x == 2) {
              this.drugTypeTwo.setValue(x);
            }
            else if (x == 3) {
              this.drugTypeThree.setValue(x);
            }
            else if (x == 4) {
              this.drugTypeFour.setValue(x);
            }
            else if (x == 5) {
              this.drugTypeFive.setValue(x);
            }
          })
        }
        if (result.nioshExcludedValue != null || result.nioshExcludedValue != undefined) {
          this.nioshValue = [];
          result.nioshExcludedValue.split(',').forEach(x => {
            this.nioshValue.push(x);
            if (x == 1) {
              this.drugAntineoplastic.setValue(x)
            }
            else if (x == 2) {
              this.drugNonAntineoplastic.setValue(x)
            }
            else if (x == 3) {
              this.drugReproductive.setValue(x)
            }
          })
        }


        this.ngxLoader.stop(IMPORTWIZARDDATA_KEY);
        count++;
        if (count === 2) {
          clearInterval(dataBindInterval)
          this.ngxLoader.stop(IMPORTWIZARDDATA_KEY);
          this.endPointRequestSentBasedOnDevice(false);
        }
      }, 500)
      this.unitOfUsageValue.setValue(result.unitOfUsageValue);
      this.handleDeviceIdChange(result.deviceId);

    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(IMPORTWIZARDDATA_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

  /*Input box readonly set*/
  isReadOnly: boolean = false;
  isReadOnlyForLite: boolean = false;

  isDisabled() {
    if (this.deviceTypeId.value === 9) { this.highCapDrawerNo.setValue(0); }
    if(this.deviceId===2){
      this.updateSubDevice()
    }

    if (this.drugWithCanisterForm.get('deviceTypeId').value == 3) {
      this.superCellNo.setValue(36);
      this.isReadOnlyForLite = true;
      this.isReadOnly = true;
      this.lockingCellNo.setValue(0);
      this.superLockingCellNo.setValue(0);
      this.configuration.setValue(1);
    }
    else if (this.drugWithCanisterForm.get('deviceTypeId').value == 4) {
      this.superCellNo.setValue(45);
      this.isReadOnlyForLite = true;
      this.isReadOnly = true;
      this.lockingCellNo.setValue(0);
      this.superLockingCellNo.setValue(0);
      this.configuration.setValue(1);
    }
    else if (this.drugWithCanisterForm.get('deviceTypeId').value == 5) {
      this.isReadOnlyForLite = true;
      this.superCellNo.setValue(88);
      this.isReadOnly = false;
    }
    else {
      this.isReadOnly = false;
      this.superCellNo.setValue(0);
      this.isReadOnlyForLite = false;
    }
  }

  isAnalyzeButtonDisabled() {
    if ((this.isScheduleExcluded.value == true && this.scheduleExcludedValue.length === 0) ||
      (this.isNioshExcluded.value == true && this.nioshValue.length === 0) || (this.isUnitOfUsageExcluded.value == true && this.unitOfUsageValue.value === 0)) {
      this.countAnalysisDrugInfo = [];
      this.isDisabled();

      this.superCellNo.setValue(0);

      this.isReadOnlyForLite = false;
      return true;
    }
    else if (this.deviceId === 3 && this.deviceTypeId.value == null) {
      return true;
    }
    else {
      return false;
    }

  }

  atpValidationForAnalyze(): boolean {
    let output = false;
    let sd256 = this.highCapDrawerNo.value > 2 ? (8 - ((this.highCapDrawerNo.value - 2) * 2)) : 8;
    let sd320 = this.highCapDrawerNo.value > 2 ? (12 - ((this.highCapDrawerNo.value - 2) * 2)) : 12;
    let sd400 = this.highCapDrawerNo.value > 2 ? (17 - ((this.highCapDrawerNo.value - 2) * 2)) : 17;
    let sd480 = this.highCapDrawerNo.value > 2 ? (22 - ((this.highCapDrawerNo.value - 2) * 2)) : 22;
    let initialCan: number = parseInt(this.countAnalysisDrugInfo[0]?.value == '' ? '0' : this.countAnalysisDrugInfo[0]?.value);
    let initialHighCan: number = parseInt(this.countAnalysisDrugInfo[2]?.value == '' ? '0' : this.countAnalysisDrugInfo[2]?.value);

    if (this.deviceId === 3 && this.initalCanister.value > initialCan) {
      output = true;
      this.initalCanister.setValue(0);
      this.toastService.openToast(`Maximum Initial Canister value : ${initialCan}`,
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceId === 3 && this.highCapCanister.value > initialHighCan) {
      this.highCapCanister.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Initial High Canister value : ${initialHighCan}`,
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceId === 3 && this.smartCanister.value > 999) {
      this.smartCanister.setValue(0);
      output = true;
      this.toastService.openToast("Maximum Initial Smart Canister value : 999",
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceTypeId.value === 9 && this.smartDrawerNo.value > 4) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast("Maximum Smart Drawer value : 4",
        constants.ToastPanelType.error,
        2
      );
    }
    else if ((this.deviceTypeId.value === 10 || this.deviceTypeId.value === 11) && this.highCapDrawerNo.value > 2) {
      this.highCapDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast("Maximum High Cap Drawer value : 2",
        constants.ToastPanelType.error,
        2
      );

    }
    else if (this.deviceTypeId.value === 10 && this.smartDrawerNo.value > (4 - (this.highCapDrawerNo.value))) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Smart Drawer value : ${(4 - this.highCapDrawerNo.value)}`,
        constants.ToastPanelType.error,
        2
      );

    }
    else if (this.deviceTypeId.value === 11 && this.smartDrawerNo.value > 4) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast("Maximum Smart Drawers value : 4",
        constants.ToastPanelType.error,
        2
      );
    }
    else if ((this.deviceTypeId.value === 12 || this.deviceTypeId.value === 13 || this.deviceTypeId.value === 14 || this.deviceTypeId.value === 15) && this.highCapDrawerNo.value > 4) {
      this.highCapDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast("Maximum High Cap Drawer value : 4",
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceTypeId.value === 12 && this.smartDrawerNo.value > sd256) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Smart Drawers value : ${sd256}`,
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceTypeId.value === 13 && this.smartDrawerNo.value > sd320) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Smart Drawers value : ${sd320}`,
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceTypeId.value === 14 && this.smartDrawerNo.value > sd400) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Smart Drawers value : ${sd400}`,
        constants.ToastPanelType.error,
        2
      );
    }
    else if (this.deviceTypeId.value === 15 && this.smartDrawerNo.value > sd480) {
      this.smartDrawerNo.setValue(0);
      output = true;
      this.toastService.openToast(`Maximum Smart Drawers value : ${sd480}`,
        constants.ToastPanelType.error,
        2
      );
    }
    return output;
  }


  //#region GCN Import Wizard
  closeDialog(response: boolean) {
    this.dialogRefImportWizardComponent.close({ event: "close", data: response });
  }

  random_bg_color(): string {
    if (this.isColorChange) {
      this.isColorChange = false;
      return "rgb(191, 191, 191)";
    }
    else {
      this.isColorChange = true;
      return "rgb(255, 255, 255)";
    }
  }



  customExcelFileDownload() {
    const uri = 'data:application/vnd.ms-excel;base64,';
    let tableRow = 2;
    let tableData = this.dataSource.data.map(x=>
      {
        let styleID;
        if (x.backgroundColor === 'rgb(191, 191, 191)') {
            styleID = "S522";
        } else {
            styleID = "S52";
        }
      return `<Row ss:Index="${tableRow++}">
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.sl}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.customerDrugName == null? '': x.customerDrugName}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.gcnNumber == null? '': x.gcnNumber}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.ndc == null? '': x.ndc}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.packageSize == null? '': x.packageSize}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.manufacturer == null? '': x.manufacturer}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.strength == null? '': x.strength}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.unitOfUsage == null? '':x.unitOfUsage}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.rxQty == null?'': x.rxQty}</Data>
      </Cell>
     </Row>`
      })
      let concatenatedTableData = tableData.join('');


    const template = `
    <?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">
    <x:ExcelWorkbook>
     <x:ActiveSheet>0</x:ActiveSheet>
    </x:ExcelWorkbook>
    <o:DocumentProperties>
     <o:Created>2023-09-13T17:41:39Z</o:Created>
     <o:LastSaved>2024-01-10T10:59:44Z</o:LastSaved>
    </o:DocumentProperties>
    <o:CustomDocumentProperties>
     <o:ContentTypeId>0x010100698360ABD404FE40AAB1A4EEE7594A12</o:ContentTypeId>
     <o:MediaServiceImageTags />
     <o:_ExtendedDescription />
    </o:CustomDocumentProperties>
    <Styles>
     <Style ss:ID="Default" ss:Name="Normal">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
 
     <Style ss:ID="S50">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1" />
      <ss:Font ss:Bold="1" ss:FontName="Times New Roman" ss:Size="36" />
     </Style>
     <Style ss:ID="S51">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S52">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:FontName="Arial"  ss:Size="12" />
     </Style>
     <Style ss:ID="S522">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:FontName="Arial"  ss:Size="12" />
      <Interior ss:Color="#BFBFBF" ss:Pattern="Solid"/>'
     </Style>
     <Style ss:ID="S53">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1" />
      <ss:Font ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S54">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:FontName="Arial" ss:Background = "#FFD733" ss:Bold="1" ss:Size="12" />
     </Style>
    </Styles>
    <Worksheet ss:Name="Duplicate NDC">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="34.35" ss:DefaultColumnWidth="54" ss:ExpandedRowCount="${this.dataSource.data.length+1}" ss:ExpandedColumnCount="9">
      <Column ss:Index="1" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="62.25" />
      <Column ss:Index="2" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="210.75" />
      <Column ss:Index="3" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="107.25" />
      <Column ss:Index="4" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="59.25" />
      <Column ss:Index="5" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="59.25" />
      <Column ss:Index="6" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="107.25" />
      <Column ss:Index="7" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="71.25" />
      <Column ss:Index="8" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="89.25" />
      <Column ss:Index="9" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="59.25" />
      <Row ss:Index="1">
       <Cell ss:StyleID="S54"><Data ss:Type="String">SL</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Drug Name</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">GCN</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">NDC</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Pack Qty</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Manufacturer</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Strength</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Unit Usage</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Unit Usage</Data>
       </Cell>
      </Row>

      ${concatenatedTableData}
 
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
      <Panes>
       <Pane>
        <Number>3</Number>
        <ActiveRow>3</ActiveRow>
        <ActiveCol>7</ActiveCol>
        <RangeSelection>R4C8</RangeSelection>
       </Pane>
      </Panes>
     </WorksheetOptions>
    </Worksheet>
   </Workbook>
   `
    const base64 = function(s:any) { return window.btoa(unescape(encodeURIComponent(s))) };
    const link = document.createElement('a');
    link.download =`Duplicate_NDC_${this.customerName}.xls`;
    link.href = uri + base64(template);
    link.click();
}
  openAlertWindowForGcnExport() {

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Export Duplicate NDC List",
        bodyMessage: "Are you sure you want to export this drug list?",
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        if (this.dataSource.data.length > 0) {
          this.customExcelFileDownload();
        }
      } else {
        console.log("not export", result);
      }
    });
  }
  redirect() {
    this.dialogRefImportWizardComponent.close({ event: "close", data: false });
    this.route.navigate(['./im-tool']);
  }


  allDrugUnSelect() {
    this.buttonSelected(true,false, false, false)
    this.selectedGcnDrugList = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isChecked = false;
    }
  }
  highestUsageDrugSelect() {
    this.buttonSelected(false,false, false, true)
    this.selectedGcnDrugList = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isChecked = false;
    }

    let data = this.dataSource.data;
    data = Object.values(data.reduce((x, y) => Object.assign(x, { [y.gcnNumber]: y }), {}))
    let size = data.length;
    for (let k = 0; k < size; k++) {
      let temp;
      for (let j = 0; j < this.dataSource.data.length; j++) {
        if (data[k].gcnNumber === this.dataSource.data[j].gcnNumber) {
          if (temp === undefined || temp === null || this.dataSource.data[j].unitOfUsage > temp.unitOfUsage) {
            temp = this.dataSource.data[j];
          }
        }
      }
      if (temp !== undefined && temp !== null) {
        this.drugCellRowChecked(temp, true)
      }
    }
  }
  lowestUsageDrugSelect() {
    this.buttonSelected(false,false, true, false)
    this.selectedGcnDrugList = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isChecked = false;
    }
    let data = this.dataSource.data;
    data = Object.values(data.reduce((x, y) => Object.assign(x, { [y.gcnNumber]: y }), {}))
    let size = data.length;
    for (let k = 0; k < size; k++) {
      let temp;
      for (let j = 0; j < this.dataSource.data.length; j++) {
        if (data[k].gcnNumber === this.dataSource.data[j].gcnNumber) {
          if (temp === undefined || temp === null || this.dataSource.data[j].unitOfUsage < temp.unitOfUsage) {
            temp = this.dataSource.data[j];
          }
        }
      }
      if (temp !== undefined && temp !== null) {
        this.drugCellRowChecked(temp, true)
      }
    }
  }
  largestBottleSize() {
    this.buttonSelected(false,true, false, false)
    this.selectedGcnDrugList = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isChecked = false;
    }
    let data = this.dataSource.data;
    data = Object.values(data.reduce((x, y) => Object.assign(x, { [y.gcnNumber]: y }), {}))
    let size = data.length;
    for (let k = 0; k < size; k++) {
      let temp;
      for (let j = 0; j < this.dataSource.data.length; j++) {
        if (data[k].gcnNumber === this.dataSource.data[j].gcnNumber) {
          if (temp === undefined || temp === null || this.dataSource.data[j].packageSize > temp.packageSize) {
            temp = this.dataSource.data[j];
          }
        }
      }
      if (temp !== undefined && temp !== null) {
        this.drugCellRowChecked(temp, true)
      }
    }
  }
  buttonSelected(isUnselectAll, largeButtole, isLowestUsageDrugSelect, isHighestUsageDrugSelect){
    this.isButtonSelectLargeBottle = largeButtole;
    this.isLowestUsageDrugSelect = isLowestUsageDrugSelect;
    this.isHighestUsageDrugSelect = isHighestUsageDrugSelect;
    this.isUnselectAll = isUnselectAll
  }

  drugCellRowChecked(row: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedGcnDrugList.push({
        sl: row.sl,
        ndc: row.ndc,
        drugName: row.drugName,
        customerDrugName: row.customerDrugName,
        strength: row.strength,
        manufacturer: row.manufacturer,
        isChecked: row.isChecked,
        gcnNumber: row.gcnNumber,
        unitOfUsage: row.unitOfUsage,
        backgroundColor: row.background_color,
        rxQty: row.rxQty,
        packageSize: row.packageSize
      });
      let index = this.dataSource.data.findIndex(
        (x) => x.gcnNumber === row.gcnNumber && x.sl === row.sl
      );

      this.dataSource.data[index].isChecked = true;

      //  }
    } else {
      let index = this.selectedGcnDrugList.findIndex(
        (x) => x.gcnNumber == row.gcnNumber && x.sl == row.sl
      );
      if (index >= 0) {
        this.selectedGcnDrugList.splice(index, 1);
      }

    }
  }
  isAlreadyAddedThisGcnDrug(row): boolean {
    let output = false;
    for (let i = 0; i < this.selectedGcnDrugList.length; i++) {
      if (this.selectedGcnDrugList[i].gcnNumber === row.gcnNumber) {
        output = true;
        break;
      }
    }
    return output;
  }


  saveGcnDrugs(isSelectAll: boolean) {
    const GCNDRUG_KEY= 'Gcndrugkey'
    this.ngxLoader.start(GCNDRUG_KEY);
    this.saveGcnDrugListModel = [];
    this.dataSource.data.forEach(element => this.saveGcnDrugListModel.push({ ndc: element.ndc, genericSequenceNumber: element.gcnNumber, usage: element.unitOfUsage, checked: isSelectAll === true ? true : element.isChecked }));
    this.gcnDrugdModel = {
      deviceId: this.deviceId,
      customerId: this.customerId,
      gcnDrug: this.saveGcnDrugListModel
    }
    this.imtoolService.gcnDrugAdd(this.gcnDrugdModel).subscribe((result) => {

      if (result.statusCode === 200) {
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.dataSource.data = [];
        this.selectedGcnDrugList = [];
        this.saveGcnDrugListModel = [];
        this.gcnDrugList = [];
        this.gcnDrugdModel = null;
        this.ngxLoader.stop(GCNDRUG_KEY);
        if (this.deviceId === 3 || this.deviceId === 4 || this.deviceId === 1 || this.deviceId === 5 || this.deviceId === 2) {
          this.hidesite(4);
        }
        else {
          this.redirectDrugList(this.deviceId)
        }

      }
      else {
        this.ngxLoader.stop(GCNDRUG_KEY)
        this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);
        this.hidesite(3);
      }

    }, (error: HttpErrorResponse) => {

      this.ngxLoader.stop(GCNDRUG_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  isOpenAlertWizard() {
    if (this.selectedGcnDrugList.length > 0) {
      this.saveGcnDrugs(false)
    }
    else {
      this.openAlertWizardForDuplicateNDC();
    }
  }

  openAlertWizardForDuplicateNDC() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Adjust Duplicate NDC List",
        bodyMessage: "No drugs were selected, do you want to keep all these drugs or exclude them?",
        bodyMessage2: "",
        cancelBtnText: "Exclude All Drugs ",
        confirmBtnText: "Keep All Drugs",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data == true) {
        this.saveGcnDrugs(true)
      } else {
        this.saveGcnDrugs(false)
      }
    });
  }

  getGcnDrugList() {
    const GCNDRUGLIST_KEY = 'Gcndruglistkey'
    this.ngxLoader.start(GCNDRUGLIST_KEY);
    this.imtoolService.getGcnDrugs(this.customerId).subscribe((response) => {
      let result = response.data
      let sl = 1;
      this.gcnDrugList = [];
      result?.forEach(element => {
        this.gcnDrugList.push({
          backgroundColor: element.backgroundColor,
          ndc: element.ndc,
          manufacturer: element.manufacturer,
          gcnNumber: element.genericSequenceNumber,
          isChecked: false,
          drugName: element.drugName,
          customerDrugName: element.customerDrugName,
          strength: element.strength,
          unitOfUsage: element.usage,
          sl: sl++,
          rxQty: element.rxQty,
          packageSize: element.packageSize

        })
      });

      this.gcnNumberList = Array.from(this.gcnDrugList.reduce((m, t) => m.set(t.gcnNumber, t.gcnNumber), new Map()).values());


      for (let i = 0; i < this.gcnNumberList.length; i++) {

        let background_color = this.random_bg_color();
        for (let j = 0; j < this.gcnDrugList.length; j++) {
          if (Number(this.gcnNumberList[i]) === Number(this.gcnDrugList[j].gcnNumber)) {
            this.gcnDrugList[j].backgroundColor = background_color;
          }
        }
      }
      this.dataSource.data = this.gcnDrugList;
      this.ngxLoader.stop(GCNDRUGLIST_KEY);
    }, (error: HttpErrorResponse) => {
      this.ngxLoader.stop(GCNDRUGLIST_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  //#endregion


  //#region Group Canisnter
  saveGroupCanister() {
    this.groupCanisterModel = null;
    for (let i = 0; i < this.selectedDeviceDrawerListByNdc.length; i++) {
      for (let j = 0; j < this.selectGroupCanistherList.length; j++) {
        if (this.selectedDeviceDrawerListByNdc[i].ndc === this.selectGroupCanistherList[j].ndc) {
          this.selectGroupCanistherList[j].drawers.push({
            drawerNo: this.selectedDeviceDrawerListByNdc[i].drawerNumber,
            locations: this.selectedDeviceDrawerListByNdc[i].drawerCell,
          });
        }
      }
    }
    const GROUPCANISTER_KEY = 'Groupcanisterkey'
    this.ngxLoader.start(GROUPCANISTER_KEY);

    this.groupCanisterModel = {
      customerId: this.customerId,
      drugs: this.selectGroupCanistherList
    }

    this.imToolAtpService.finalImportForAtp(this.groupCanisterModel).subscribe((result) => {
      this.selectGroupCanistherList = [];
      if (result.statusCode === 200) {
        this.toastService.openToast(result.message, constants.ToastPanelType.done, 2);
        this.ngxLoader.stop(GROUPCANISTER_KEY);
        this.dataSourceGroupCanister.data = [];
        this.selectGroupCanistherList = [];
        this.redirectDrugList(this.deviceId)
      }
      else {
        this.toastService.openToast(result.message, constants.ToastPanelType.error, 2);
        this.ngxLoader.stop(GROUPCANISTER_KEY);
      }

    }, (error: HttpErrorResponse) => {

      this.ngxLoader.stop(GROUPCANISTER_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }
  getGroupCanisterLocation() {
    const GROUPCANISTERLOCATION_KEY = 'Groupcanisterlocationkey'
    this.ngxLoader.start(GROUPCANISTERLOCATION_KEY);
    this.imToolAtpService.getGroupCanister(this.customerId).subscribe((response) => {
      let result = response.data
      this.dataSourceGroupCanister.data = result?.groupCanisterDrugs;
      this.assignDrawerCellList = result?.assignedDrawerLocations;
      this.selectedGcnDrugList = [];
      this.ngxLoader.stop(GROUPCANISTERLOCATION_KEY);
    }, (error: HttpErrorResponse) => {
      this.ngxLoader.stop(GROUPCANISTERLOCATION_KEY);
      console.log(error);
      this.toastService.openToast(
        error.error.message ?? error.message,
        constants.ToastPanelType.error,
        2
      );
    })
  }

  updateSelectedGroupCanisterList(row) {
    this.selectGroupCanistherList.forEach(x => {
      if (x.ndc == row.ndc) {
        x.groupCanister = row.groupCanister === -1 ? 0 : row.groupCanister,
          x.highCapGroupCanister = row.highCapGroupCanister === -1 ? 0 : row.highCapGroupCanister
      }
    })
  }

  showhideElement(ndc: string, drawerNo: number, event) {
    let selectorId = '';
    if (!this.isApplicableThisDrawer(this.deviceTypeId.value, drawerNo)) {
      return;
    }
    if (this.highCapDrawerNo.value === 1 && (drawerNo === 2 || drawerNo === 6)) {

      if (drawerNo === 2) {
        selectorId = `${ndc}-${2}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 2 & 6'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';

        this.selectDrawer(drawerNo, ndc, event, true);
      }

    }
    else if (this.highCapDrawerNo.value === 2 && (drawerNo === 2 || drawerNo === 6)) {

      if (drawerNo === 2) {
        selectorId = `${ndc}-${2}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 2 & 6'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 2 && (drawerNo === 3 || drawerNo === 7)) {

      if (drawerNo === 3) {
        selectorId = `${ndc}-${3}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 3 & 7'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }

    }
    else if (this.highCapDrawerNo.value === 3 && (drawerNo === 2 || drawerNo === 6)) {

      if (drawerNo === 2) {
        selectorId = `${ndc}-${2}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 2 & 6'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 3 && (drawerNo === 3 || drawerNo === 7)) {

      if (drawerNo === 3) {
        selectorId = `${ndc}-${3}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 3 & 7'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }

    }
    else if (this.highCapDrawerNo.value === 3 && (drawerNo === 10 || drawerNo === 14)) {

      if (drawerNo === 10) {
        selectorId = `${ndc}-${10}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 10 & 14'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 4 && (drawerNo === 2 || drawerNo === 6)) {


      if (drawerNo === 2) {
        selectorId = `${ndc}-${2}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 2 & 6'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 4 && (drawerNo === 3 || drawerNo === 7)) {

      if (drawerNo === 3) {
        selectorId = `${ndc}-${3}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 3 & 7'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 4 && (drawerNo === 10 || drawerNo === 14)) {

      if (drawerNo === 10) {
        selectorId = `${ndc}-${10}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 10 & 14'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }
    }
    else if (this.highCapDrawerNo.value === 4 && (drawerNo === 11 || drawerNo === 15)) {

      if (drawerNo === 11) {
        selectorId = `${ndc}-${11}`
        document.getElementById(`drawerlevel-${selectorId}`).innerText = 'Drawer 11 & 15'
        let element = document.getElementById(`highcaplevel-${selectorId}`);
        element.style.display = element.style.display === 'none' ? 'block' : 'none';
        this.selectDrawer(drawerNo, ndc, event, true);
      }

    }
    else {
      selectorId = `${ndc}-${drawerNo}`
     
      if(this.lebelShowForSmartDrawer(ndc, drawerNo, event)){
        this.selectDrawer(drawerNo, ndc, event, false, true);
      }
      else{
        this.selectDrawer(drawerNo, ndc, event, false, false);
      }

    }
    if (selectorId.length > 0) {
      const elementById = document.getElementById(selectorId)
      elementById.style.display = elementById.style.display === 'none' ? 'block' : 'none';
    }
   
  }
  lebelShowForSmartDrawer(ndc: string, drawerNo: number, event):boolean{
     let selectorId = '';
     const maxCanister = drawerNo * 16;
     let limitOfSmartDrawar  = 0;

     let smartdrawer = this.atpSubDevices.find(x=>x.Id == this.deviceTypeId.value).SmartDrawer;
      let highcap = this.highCapDrawerNo.value >2?2: smartdrawer>=9?0 : this.highCapDrawerNo.value;
      limitOfSmartDrawar = (smartdrawer + highcap +this.smartDrawerNo.value-1) * 16;
   
      smartdrawer *= 16
     if(maxCanister>=smartdrawer && maxCanister <=limitOfSmartDrawar){
      selectorId = `${ndc}-${drawerNo}`
     }
     if(selectorId?.length>0){
     let element = document.getElementById(`smartdrawerlevel-${selectorId}`);
     element.style.display = element.style.display === 'none' ? 'block' : 'none';
     return true;
     }
     return false;
  }

  isApplicableThisDrawer(deviceId: number, drawerNo: number): boolean {
    const maxCanisterAmount = drawerNo * 16;
    let output = false;
    for (let i = 0; i < this.atpSubDevices.length; i++) {
      if (this.atpSubDevices[i].Id === deviceId && maxCanisterAmount <= this.atpSubDevices[i].CanisterAmount) {

        output = true;
        break;

      }
    }
    if (!output) {
      this.toastService.openToast(`Drawer ${drawerNo} is not available for sub-device ${this.atpSubDevices.find(x => x.Id === deviceId).CanisterAmount}.`, constants.ToastPanelType.error, 2);
    }
    return output;
  }

  groupCanisterRowSelect(row: any, isChecked: boolean) {
    if (isChecked) {
      let index = this.dataSourceGroupCanister.data.findIndex(
        (x) => x.ndc === row.ndc
      );
      this.selectGroupCanistherList.push({
        isChecked: true,
        topOrder: row.topOrder,
        strength: row.strength,
        ndc: row.ndc,
        groupCanister: row.groupCanister,
        highCapGroupCanister: row.highCapGroupCanister,
        usage: row.usage,
        bottleQty: row.bottleQty,
        maxCap: row.maxCap,
        hccMaxCap: row.hccMaxCap,
        recCanister : row.recCanister,
        recHcCanister : row.recHcCanister,
        daysOfPillQtyReq: row.daysOfPillQtyReq,
        drawers: []
      });
      this.dataSourceGroupCanister.data[index].isChecked = true;


    } else {
      let index = this.selectGroupCanistherList.findIndex(
        (x) => x.ndc == row.ndc
      );
      if (index >= 0) {
        this.selectGroupCanistherList.splice(index, 1);
        setTimeout(() => {
          this.dataSourceGroupCanister.data[index].isChecked = false;
        }, 20)

      }
      this.clearUncheckedRowDrawer(row.ndc);
    }
  }

  atpTableData: number[][] = [
    [13, 14, 15, 16],
    [9, 10, 11, 12],
    [5, 6, 7, 8],
    [1, 2, 3, 4]
  ];


  atpSubDevices = [
    {
      Id: 9,
      CanisterAmount: 64,
      SmartDrawer:1
    },
    {
      Id: 10,
      CanisterAmount: 128,
      SmartDrawer:5
    },
    {
      Id: 11,
      CanisterAmount: 192,
      SmartDrawer:9
    },
    {
      Id: 12,
      CanisterAmount: 256,
      SmartDrawer:9
    },
    {
      Id: 13,
      CanisterAmount: 320,
      SmartDrawer:9
    },
    {
      Id: 14,
      CanisterAmount: 400,
      SmartDrawer:9
    },
    {
      Id: 15,
      CanisterAmount: 480,
      SmartDrawer:9
    }
  ];


  deviceCell: number[][];
  deviceDrawer: AtpDeiceDrawerCell[] = [];
  devceDrawerLevel: string = ''
  selectedDeviceDrawerListByNdc: AtpDeviceSelectedDrawer[] = []
  assignDrawerCellList: AssignDrawerLocation[] =[];

  cleanTheGroupCanisterData() {
    this.deviceCell = [];
    this.deviceDrawer = [];
    this.devceDrawerLevel = ''
    this.selectedDeviceDrawerListByNdc = []
    this.selectGroupCanistherList = []
  }

  openAlertWindowForGroupCanisterBackButton() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.alert.svg",
        title: "Warning!",
        bodyMessage: "Navigating back will result in the loss of any changes made on this page.",
        cancelBtnText: "Cancel",
        confirmBtnText: "Ok",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.cleanTheGroupCanisterData();
        this.hidesite(3);
      } else {
        console.log("Cancel=", result);
      }
    });
  }

  IsDrawerSelect(drawer: number, ndc: string): boolean {
    for (let i = 0; i < this.selectedDeviceDrawerListByNdc.length; i++) {
      if (this.selectedDeviceDrawerListByNdc[i].drawerNumber == drawer && this.selectedDeviceDrawerListByNdc[i].ndc == ndc) {
        return false;
      }
    }
    return true;
  }

  clearUncheckedRowDrawer(ndc: string) {

    this.cleartheUncheckedRowDrawerColor(ndc);

    let index = this.selectedDeviceDrawerListByNdc.findIndex(x => x.ndc === ndc);
    if (index >= 0) {
      this.selectedDeviceDrawerListByNdc.splice(index, this.selectedDeviceDrawerListByNdc.filter(x => x.ndc === ndc).length);
    }
  }
  cleartheUncheckedRowDrawerColor(ndc) {
    for (let i = 0; i < this.selectedDeviceDrawerListByNdc.length; i++) {
      if (this.selectedDeviceDrawerListByNdc[i].ndc === ndc) {
        let element = document.getElementById(`drawer-${ndc}-${this.selectedDeviceDrawerListByNdc[i].drawerNumber}`)
        this.hidetheuncheckedRowDrawer(ndc, this.selectedDeviceDrawerListByNdc[i].drawerNumber);
        element.classList.remove('selected-drawer')
        element.classList.remove('smart-drawer');
        for (let j = 0; j < this.selectedDeviceDrawerListByNdc[i].drawerCell.length; j++) {
          let element = document.getElementById(`drawercell-${ndc}-${this.selectedDeviceDrawerListByNdc[i].drawerNumber}-${this.selectedDeviceDrawerListByNdc[i].drawerCell[j]}`)
          element.classList.remove('selected-cell')
        }
      }
    }

  }

  hidetheuncheckedRowDrawer(ndc, drawerNo) {
    let selectorId = `${ndc}-${drawerNo}`
    let element = document.getElementById(`highcaplevel-${selectorId}`);
    if (element)
      element.style.display = 'none';

    document.getElementById(`${selectorId}`).style.display = 'none';
  }




  selectDrawer(drawer: number, ndc: string, event, isHighCapCanister: boolean = false, isSmart = false): boolean {
    let element = event.target;
    if (this.IsDrawerSelect(drawer, ndc)) {
      this.selectedDeviceDrawerListByNdc.push({
        ndc: ndc,
        drawerNumber: drawer,
        isHighCapCanister: isHighCapCanister,
        drawerCell: []
      })
      element.classList.add('selected-drawer')
      if(isSmart)
        element.classList.add('smart-drawer');
    }
    else {
      let index = this.selectedDeviceDrawerListByNdc.findIndex(x => x.drawerNumber === drawer && x.ndc === ndc)

      this.removeTheSelectedCellWithDrawer(index);
      this.selectedDeviceDrawerListByNdc.splice(index, 1);

      element.classList.remove('selected-drawer')
      element.classList.remove('smart-drawer');

      let [groupCanister, highCapGroupCanister] = this.selectedListLengthBasedOnNdc(ndc);
      this.updateDataSet({ndc, groupCanister, highCapGroupCanister})
    }
    return true;
  }

  removeTheSelectedCellWithDrawer(index:number){
    this.selectedDeviceDrawerListByNdc[index].drawerCell.forEach(cell => {
      let element = document.getElementById(`drawercell-${this.selectedDeviceDrawerListByNdc[index].ndc}-${this.selectedDeviceDrawerListByNdc[index].drawerNumber}-${cell}`);
      element.classList.remove('selected-cell');
      
      this.updateTheOtherNdcCellColor(this.selectedDeviceDrawerListByNdc[index].ndc, this.selectedDeviceDrawerListByNdc[index].drawerNumber, cell, false);
    });
   
   
  }

  updateDataSet(row) {
    let index = this.dataSourceGroupCanister.data.findIndex(x => x.ndc === row.ndc);
    this.dataSourceGroupCanister.data[index].groupCanister = row.groupCanister === -1 ? 0 : row.groupCanister;
    this.dataSourceGroupCanister.data[index].highCapGroupCanister = row.highCapGroupCanister === -1 ? 0 : row.highCapGroupCanister;
  }


  selectDrawerCell(drawer: number, ndc: string, cell: number, event) {
    let element = event.target;
    let isAlreadyAddedIndex = this.selectedDeviceDrawerListByNdc.findIndex(x => x.ndc !== ndc && x.drawerCell.includes(cell))
    let isAlreadyAssignThisLocation = this.searchDrawerLocation(drawer, cell); 
    if (isAlreadyAddedIndex === -1 && isAlreadyAssignThisLocation === false) {
      let index = this.selectedDeviceDrawerListByNdc.findIndex(x => x.drawerNumber === drawer && x.ndc === ndc);

      let drawerCellIndex = this.selectedDeviceDrawerListByNdc[index].drawerCell.indexOf(cell)
      if (drawerCellIndex === -1) {

        let isHighCapCanisterCell = this.selectedDeviceDrawerListByNdc.some(x =>
          x.isHighCapCanister && x.ndc == ndc && x.drawerNumber == drawer
        );
        if(isHighCapCanisterCell == false){
        let indexOfDataset = this.dataSourceGroupCanister.data.findIndex(x => x.ndc === ndc);
        if(this.dataSourceGroupCanister.data[indexOfDataset].groupCanister>=8){
          this.toastService.openToast(`Oops! It seems you've reached the maximum limit of canister cells allowed for this NDC. No further selections can be made.`, constants.ToastPanelType.error, 2);
          return;
        }
      } 

        this.selectedDeviceDrawerListByNdc[index]?.drawerCell.push(cell);
        let [groupCanister, highCapGroupCanister] = this.selectedListLengthBasedOnNdc(ndc);
        this.updateSelectedGroupCanisterList({
          ndc: this.selectedDeviceDrawerListByNdc[index].ndc, 
          groupCanister: groupCanister,
          highCapGroupCanister: highCapGroupCanister
        })

        this.updateDataSet({
          ndc: this.selectedDeviceDrawerListByNdc[index].ndc,
          groupCanister: groupCanister,
          highCapGroupCanister: highCapGroupCanister
        });

        element.classList.add('selected-cell');
        this.updateTheOtherNdcCellColor(ndc, drawer, cell, true);
      }
      else {
        this.selectedDeviceDrawerListByNdc[index]?.drawerCell.splice(drawerCellIndex, 1);

        let [groupCanister, highCapGroupCanister] = this.selectedListLengthBasedOnNdc(ndc);

        this.updateSelectedGroupCanisterList({
          ndc: this.selectedDeviceDrawerListByNdc[index].ndc, groupCanister: groupCanister,
          highCapGroupCanister: highCapGroupCanister
        })

        this.updateDataSet({
          ndc: this.selectedDeviceDrawerListByNdc[index].ndc, groupCanister: groupCanister,
          highCapGroupCanister: highCapGroupCanister
        });
        element.classList.remove('selected-cell');
        this.updateTheOtherNdcCellColor(ndc, drawer, cell, false);
      }
    }
    else {
      if(isAlreadyAssignThisLocation === false ){
        this.toastService.openToast(`This drawer cell has already been utilized for this NDC-${this.selectedDeviceDrawerListByNdc[isAlreadyAddedIndex].ndc}`, constants.ToastPanelType.error, 2);
      }
      else{
        this.toastService.openToast(`This drawer cell has already been utilized`, constants.ToastPanelType.error, 2);
      }
      
      element.classList.remove('selected-cell');
    }
  }

  updateTheOtherNdcCellColor(ndc:string, drawerNumber:number, cell:number, isSelect:boolean){
    this.dataSourceGroupCanister.data.forEach(row => {
      if(row.ndc!= ndc){
        
        let element = document.getElementById(`drawercell-${row.ndc}-${drawerNumber}-${cell}`);
        if(isSelect){
        element.classList.add('already-selected-cell');
        }
        else{
          element.classList.remove('already-selected-cell');
        }
      }
    });
    
  }
  searchDrawerLocation(drawerNo: number, location: number): boolean{
    let result = this.assignDrawerCellList.find(
      (drawerLocation) => drawerLocation.drawerNo === drawerNo && drawerLocation.locations.includes(location)
    );
  
    return result == null ? false :  true;
  }

  selectedListLengthBasedOnNdc(ndc: string): [groupCanister: number, highcapGroupCanister: number] {
    let groupCanister = 0, highCapGroupCanister = 0;

    for (let i = 0; i < this.selectedDeviceDrawerListByNdc.length; i++) {
      if (this.selectedDeviceDrawerListByNdc[i].ndc === ndc && this.selectedDeviceDrawerListByNdc[i].isHighCapCanister) {
        highCapGroupCanister += this.selectedDeviceDrawerListByNdc[i].drawerCell.length;
      }
      else if (this.selectedDeviceDrawerListByNdc[i].ndc === ndc && !this.selectedDeviceDrawerListByNdc[i].isHighCapCanister) {
        groupCanister += this.selectedDeviceDrawerListByNdc[i].drawerCell.length;
      }
    }
    return [groupCanister, highCapGroupCanister];
  }


  deviceAllCellGenerate() {
    const numRows = 8;
    const numCols = 2;
    for (let drawer = 1; drawer <= 16; drawer++) {
      this.devceDrawerLevel = `Drawer-${drawer}`
      let counter = 16 * (drawer - 1) + 1;
      this.deviceCell = [];
      for (let i = 0; i < numRows; i++) {
        const row = [];
        for (let j = 0; j < numCols; j++) {
          if (j === 1) {
            row.push(counter + 7);
          }
          else {
            row.push(counter++);
          }
        }
        this.deviceCell.push(row);
      }
      this.deviceDrawer.push({ drawerName: this.devceDrawerLevel, drawerNumber: drawer, drawerCell: this.deviceCell })
    }
  }

  //#endregion
}
