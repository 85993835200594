import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { getUptimeAllSystem } from 'src/app/store/selectors/pharmacy-peek.selector';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';


@Component({
  selector: 'app-packager-uptime-all-system',
  templateUrl: './packager-uptime-all-system.component.html',
  styleUrls: ['./packager-uptime-all-system.component.scss']
})

export class PackagerUptimeAllSystemComponent implements OnInit, AfterViewInit, OnDestroy {

  childOrg = '';
  step = -1;
  @ViewChild('todayOverview', { static: false }) todayOverviewRef: ElementRef;

  views = {
    overview: true,
  };

  noDataExistsForUP = true;

  uptimeDate: any = null;
  overallIdleTime: string;
  overallIdleTimePercent: string;
  overallUptime: string;
  overallUptimePercent: string;
  overallUtilizationTime: string;
  overallUtilizationTimePercent: string;
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.UPTIME_SITE,
    deviceId: ''
  }
  
  constructor(public store: Store<State>,
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
  ) { }

  ngOnInit() {
    this.store.select(getUptimeAllSystem).subscribe(resData => {
      if (resData) {
        this.uptimeDate = resData;
        this.overallIdleTime = resData.overallIdleTime;
        this.overallIdleTimePercent = resData.overallIdleTimePercent;
        this.overallUptime = resData.overallUptime;
        this.overallUptimePercent = resData.overallUptimePercent;
        this.overallUtilizationTime = resData.overallUtilizationTime;
        this.overallUtilizationTimePercent = resData.overallUtilizationTimePercent;
        this.noDataExistsForUP = false;
      }
    });

    this.subscription = this.signal.getDataStream<string>(SignalEventType.UPTIME_SITE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateUptimePackagerAllSystem({ data: dataObj.data }));
      console.log(dataObj);
    })

    this.callUptimeAllSystem();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  
  private callUptimeAllSystem() {
    this.viewStoreService.getUpTimeAllSystem()
      .subscribe(
        (response) => {
            this.store.dispatch(PharmacyPeekActions.getUptimePackagerAllSystem({ data: response }));
        },
        (error) => {
          console.log('callUptimeAllSystem error:', error);
          if (error.status === 404) {
            this.loggerService.logWarning('callUptimeAllSystem Service: No Data: ', error);
          } else {
            this.toastService.openToast('UptimeAllSystem service load error!', ToastPanelType.error);
          }
        }
      );
  }

  setStep(index: number) {
    if (this.step === index) { this.step = -1; }
    else { this.step = index; }
  }

}
