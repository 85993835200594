import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-overall-bar-chart',
  templateUrl: './overall-bar-chart.component.html',
  styleUrls: ['./overall-bar-chart.component.scss'],
})
export class OverallBarChartComponent implements OnChanges {

  @Input() ref: ElementRef;
  @Input() data: { value: number; color: string }[];
  @ViewChild('chart', { static: true }) chartRef: ElementRef<HTMLElement>;

  private stackedData: {
    start: number; end: number; height: number; middle: number;
    leftJoin: number; color: string;
  }[];
  private svg;
  private readonly chartWidthPercent = 50;
  private width: number;
  private height: number;
  private chartStartX: number;
  private chartWidth: number;
  private maxValue: number;
  private yScale: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.ref) { return; }

    setTimeout(() => {
      const rCountDescs = this.ref.nativeElement.querySelectorAll('div.rcountdesc');
      const rCounts = this.ref.nativeElement.querySelectorAll('span.rcountdesc');
      if (rCountDescs.length !== 6) { return; }

      const chartTop = rCountDescs[1].getBoundingClientRect().top;
      const chartBottom = this.ref.nativeElement.getBoundingClientRect().bottom;

      this.width = this.chartRef.nativeElement.clientWidth;
      this.height = chartBottom - chartTop;
      this.chartWidth = this.width * this.chartWidthPercent / 100;
      this.chartStartX = this.width - this.chartWidth;

      const spaceBottom = chartBottom - this.ref.nativeElement.getBoundingClientRect().bottom;
      this.chartRef.nativeElement.style.height = `${this.height}px`;
      this.chartRef.nativeElement.style.marginBottom = `${spaceBottom}px`;

      this.stackedData = this.data.reduce(
        (prev, { value, color }, i) => {
          const { top, bottom } = rCounts[i].getBoundingClientRect();
          return [...prev, {
            start: i ? prev[i - 1].end : 0,
            end: i ? prev[i - 1].end + value : value,
            middle: i ? prev[i - 1].end + value / 2 : value / 2,
            height: value,
            leftJoin: Math.round((top + bottom) / 2 - chartTop),
            color,
          }];
        }, []);

      this.createSvg();
      this.drawBars();
      this.drawConnectLines();
    }, 100);
  }

  private createSvg(): void {
    d3.select(this.chartRef.nativeElement)
      .selectAll('*').remove();

    this.svg = d3.select(this.chartRef.nativeElement)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    this.maxValue = this.data ? this.data.reduce((total, { value }) => total + value, 0) : 0;
    this.yScale = d3.scaleLinear()
      .domain([this.maxValue, 0])
      .range([this.height, 0]);
  }

  private drawBars(): void {
    this.svg.selectAll()
      .data(this.stackedData)
      .enter()
      .append('rect')
      .attr('x', this.chartStartX)
      .attr('y', ({ start }) => this.yScale(start))
      .attr('height', ({ height }) => this.yScale(height))
      .attr('width', this.chartWidth)
      .attr('fill', ({ color }) => color);
  }


  private drawConnectLines(): void {

    const makeLinesData = (d, i) => {
      const jumpX = this.chartStartX / 2 + (this.chartStartX / 8) * (2 - i);
      return [
        { x: 0, y: d.leftJoin },
        { x: jumpX, y: d.leftJoin },
        { x: jumpX, y: this.yScale(d.middle) },
        { x: this.chartStartX - 4, y: this.yScale(d.middle) },
      ];
    };

    this.svg.selectAll()
      .data(this.stackedData)
      .enter()
      .append('path')
      .datum((d, i) => makeLinesData(d, i))
      .attr('class', 'line')
      .attr('fill', 'none')
      .style('stroke', '#191970')
      .style('stroke-width', '1.5')
      .style('fill', 'none')
      .attr('d', d3.line()
        .curve(d3.curveLinear)
        // @ts-ignore
        .x(({ x }) => x)
        // @ts-ignore
        .y(({ y }) => y),
      );
  }
}
