import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  CellLocatedSheet, CommonEntity, CustomerDrugCellLocation, CustomerDrugInfo, DrugCellLog, ListOfDrugLocation } from '../../models/customer-drug-info';
import { IMToolService } from '../../services/im-tool.service';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from "src/app/config/app-constants";
import { ImportWizardCddbComponent } from '../import-wizard-cddb/import-wizard-cddb.component';
import { ImportWizardGcnComponent } from '../import-wizard-gcn/import-wizard-gcn.component';
import { GcnDrugs } from '../../models/gcn-drugs';
import { ImportWizardComponent } from '../import-wizard/import-wizard.component';
import { ManufacturingAnalysisExcel } from '../../models/manufacture-analysis';
import { ExportFileInfo } from '../../models/export-file-info';
import { ExcelExportService } from '../../services/excel-export-service.service';
import { AnalysisTabDrug, CellLocatorSheet, ExcludedDrug, NewTabDrug, NotOralSolidDrug } from '../../models/max-drugs';
import { ImportWizardDrugMovingComponent } from '../import-wizard-drug-moving/import-wizard-drug-moving.component';
import { MoveDrug } from '../../models/move-drugs';
import {CellConfigurationXml} from '../../models/cell-configuration';
import { ImToolData } from '../../services/im-tool-data.service';
@Component({
  selector: "app-customer-drug-list",
  templateUrl: "./customer-drug-list.component.html",
  styleUrls: ["./customer-drug-list.component.scss"],

})
export class CustomerDrugListComponent implements OnInit {
  manufacturingAnalyzeExcelModel:ManufacturingAnalysisExcel={};
  searchText:string='';
  drugList: CustomerDrugInfo;
  celLogList: DrugCellLog[] = [];
  drugCellSize:CommonEntity[]=[];
  drugCellLocationUpdateList: ListOfDrugLocation;
  drugCellLocationList: CustomerDrugCellLocation[] = [];
  analysisDrugList: AnalysisTabDrug[] = [];
  notAvailableDrugList: NewTabDrug[] = [];
  excludedDrugList: ExcludedDrug[] = [];
  notOralDrugList: NotOralSolidDrug[] = [];
  gcnDrugList: GcnDrugs[] = [];
  cellLocatorSheetList:CellLocatorSheet[]=[];
  excelExportFileInfo: ExportFileInfo;
  //#region  Table datasourse
  dataSource = new MatTableDataSource<AnalysisTabDrug>();
  dataSourceOral = new MatTableDataSource<NotOralSolidDrug>();
  dataSourceExcluded = new MatTableDataSource<ExcludedDrug>();
  dataSourceNotAbailable = new MatTableDataSource<NewTabDrug>();
  dataSourcecellLocatorSheet = new MatTableDataSource<CellLocatorSheet>();
  //#endregion
  @ViewChild(MatTable, { static: true }) drugTabletable: MatTable<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("table", { read: MatSort, static: true }) sort: MatSort;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild("table3", { read: MatSort, static: true }) sort3: MatSort;
  @ViewChild("table4", { read: MatSort, static: true }) sort4: MatSort;
  @ViewChild("TABLE") table: ElementRef;
  @ViewChild("TABLE1") table1: ElementRef;
  @ViewChild("TABLE2") table2: ElementRef;
  @ViewChild("TABLE3") table3: ElementRef;
  @ViewChild("TABLE4") table4: ElementRef;
  @ViewChild("table5") table5: ElementRef;
  @ViewChild("table6") table6: any;
  @Output() sortChange: EventEmitter<MatSort> = new EventEmitter<MatSort>();
  @ViewChild('worksheet') worksheet: ElementRef;
  selectedDrugSystem: number = 1;
  xmlAnalysisFormatData:CellConfigurationXml = new CellConfigurationXml();
  orderOrUsage:string='# of Orders';
  phllDispLable:string='Pills Disp';
  analysisData:number;
  //#region Table column showing or not showing boolean variables

  //Dynamic column show
  isCellCapShow: boolean = true;
  isDramShow: boolean = true;
  isSuperCellCapShow: boolean = false;
  isRatioShow: boolean = false;
  isPackageSizeShow: boolean = false;
  ismanufacturerShow: boolean = true;
  isTabShow: boolean = false;
  isautomateShow: boolean = false;
  isrecSuperCellShow: boolean = false;
  isrecLockingCellShow: boolean = false;
  ispackageSizefitstandardcellShow: boolean = false;
  isotcShow: boolean = false;
  isStrengthShow:boolean = false;
  isUnRegulatedColumnShow: boolean = false;

  // Not Available Table
  ismanufacturerNotAvailableShow: boolean = true;
  isNonCountReasonNotAvailableShow: boolean = false;
  isSCHNotAvailablehow: boolean = false;
  isStrengthNotAvailableShow:boolean = false;



  //Excluded Table//
  isExcludedReasonShow: boolean = false;
  isToOrderExcludeShow: boolean = true;
  isrecSuperCellExcludeShow: boolean = false;
  isRatioExcludeShow: boolean = false;
  ismanufacturerExcludeShow: boolean = true;
  isrecLockingCellExcludeShow: boolean = false;
  isSuperCellCapExcludeShow: boolean = false;
  isPackageSizeExcludeShow: boolean = false;
  isThirtyDramExcludeShow: boolean = true;
  isPackageSizeFitExcludeShow: boolean = false;
  isCellCapacityExcludeShow: boolean = true;
  isStrengthExcludeShow:boolean= false;
  isRxQtyShow:boolean= false;
  isUnitQty:boolean= false;
  //Not Oral Solid Table//
  ismanufacturerOralSolidShow: boolean = false;
  isDramOralSolidShow: boolean = false;
  isCellCapOralSolidShow: boolean = false;
  isSuperCellCapOralSolidShow: boolean = false;
  isPackageSizeOralSolidShow: boolean = false;
  isSchOralSolidShow: boolean = false;
  isrecLockingCellOralSolidShow: boolean = false;
  isStrengthNotOralShow:boolean = false
  //#endregion

  // Selected Row Color Change key
  selectedRow;

  isShowCellLocOnMouseHover: boolean = false;

  customerName: string = "";
  targetInstallDate: string = "";
  subDeviceTypeName: string = "";
  deviceId: number;
  deviceName: string = "";

  //#region Cell count variables
  lockingCellCount: number;
  regularCellCount: number;
  remainingCellCount: number;
  remainingSuperCellCount: number;
  superCellCount: number;
  superLockingCellCount:number;
  alternateCellCount:number;
  allAssignDrugCount:number;
  unconfiguredRegularCount:number;
  unconfiguredRegularLockingCount: number;
  unconfiguredSuperCount: number;
  unconfiguredSuperLockingCount: number;
  //#endregion

  //#region Table colums Array
  displayedColumns: string[] = [
    "checkbox",
    "topOrder",
    "cellLoc",
    "ndc",
    'genericSequenceNumber',
    "cellType",
    "drugName",
    "name",
    "strength",
    "usage",
    "noOfOrder",
    "thirtyDramCapacity",
    "cellCapacity",
    "superCellCapacity",
    "ratio",
    "packageSize",
    "manufacturer",
    "sch",
    "tab",
    "automate",
    "recSuperCell",
    "recLockingCell",
    "packageSizefitstandardcell",
    // "drugStatus",
    "isUnRegulated",
    "action"
  ];
  displayedColumnsNotAvailable: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    'genericSequenceNumber',
    "cellType",
    "drugName",
    "name",
    "strength",
    "usage",
    "noOfOrder",
    "thirtyDramCapacity",
    "cellCapacity",
    "superCellCapacity",
    "ratio",
    "packageSize",
    "manufacturer",
    "sch",
    "tab",
    "nonCountReason",
    "automate",
    "recSuperCell",
    "recLockingCell",
    "packageSizefitstandardcell",
    // "drugStatus",
    "rxQty",
    "status",
    "unitQty",
    "action",
  ];
  displayedColumnsExcluded: string[] = [
    "checkbox",
    "topOrder",
    "excludedReason",
    "ndc",
    'genericSequenceNumber',
    "cellType",
    "drugName",
    "name",
    "strength",
    "usage",
    "noOfOrder",
    "thirtyDramCapacity",
    "cellCapacity",
    "superCellCapacity",
    "ratio",
    "packageSize",
    "manufacturer",
    "sch",
    "tab",
    "automate",
    "recSuperCell",
    "recLockingCell",
    "packageSizefitstandardcell",
    "rxQty",
    "unitQty",
    "packageSize",
    "packageSizeFit",
    // "drugStatus",
    "action",
  ];
  displayedColumnsOral: string[] = [
    "checkbox",
    "topOrder",
    "ndc",
    'genericSequenceNumber',
    "cellType",
    "drugName",
    "name",
    "strength",
    "usage",
    "noOfOrder",
    "thirtyDramCapacity",
    "cellCapacity",
    "superCellCapacity",
    "ratio",
    "packageSize",
    "manufacturer",
    "dosageForm",
    "sch",
    "tab",
    "automate",
    "recSuperCell",
    "recLockingCell",
    "packageSizefitstandardcell",
    // "drugStatus",
    "isUnRegulated",
    "action"
  ];
  displayedColumnsCellLocatorSheet: string[] = [
    "checkbox",
    "cell",
    "ndc",
    "cellType",
    "drugName",
    "height",
    "width",
    "baffle",
    "thirtyDramCapacity",
    "isSuperCell",
    "maxCapacity",
    "supercellMaxCapacity",
    "pressure",
    // "drugStatus"
  ];
  //#endregion

  loading: boolean = true;
  customerId: string;

  // current which tab selected
  tabSelected: number = 0;
  currentFilterSize:number = 0;


  // All selected checkbox propertities
  isAnyDrugSelected: boolean = false;


  moveDrug:MoveDrug = {} as MoveDrug;
  //isScrollButtonShow:boolean=false;

  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(
    private dialog: MatDialog,
    private imtoolService: IMToolService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private activateRoute: ActivatedRoute,
    private toastService: ToastService,
    private excelExportService: ExcelExportService,
  ) {

  }

  ngOnInit(): void {

    this.activateRoute.paramMap.subscribe((params) => {
      this.customerId = params.get("customerid");
    });
    this.getAvailableCellLocation(this.customerId);
    this.getRecommendedDrugData(this.customerId, 0);
    this.analysisData=this.dataSource.data.length;
  }

    ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSourceNotAbailable.sort = this.sort1;
    this.dataSourceExcluded.sort = this.sort2;
    this.dataSourceOral.sort = this.sort3;
    this.dataSourcecellLocatorSheet.sort = this.sort4;
  }


  printWorksheet() {
    let fileName = `Cell Locator Sheet-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName ||""}-${
      this.getCurrentDate()}`;
    const printContent = this.worksheet.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>${fileName}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
              vertical-align: bottom;
            }
            .row-even {
              background-color: #ffdab9;
            }
            .row-odd {
              background-color: #fff;
            }
            th {
              background-color: #f2f2f2;
            }
            .custom-width {
              width: 527.24px;
            }
            .numeric {
              text-align: right;
            }
            
            @media print {
              button {
                display: none;
              }
              .row-even {
                -webkit-print-color-adjust: exact;
                background-color: #ffdab9 !important;
              }
              .row-odd {
                -webkit-print-color-adjust: exact;
                background-color: #fff !important;
              }
              th {
                -webkit-print-color-adjust: exact;
                background-color: #f2f2f2 !important;
              }
              footer, .footer, div[role="contentinfo"], .print-footer {
                display: none !important;
              }
              @page {
                margin-left: 0.5in;
                margin-right: 0.5in;
                margin-bottom: 0;
              }
            }
          </style>
        </head>
        <body onload="window.print();window.close()">
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
  }

  onAllUserPaginateChange() {
    const matTable= document.getElementById('matTable');
    matTable.scrollIntoView();
  }

  allSelectedDrugUnchecked(){
    if (this.drugCellLocationList.length>0){
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        let index = this.dataSource.data.findIndex(
          (x) => x.fileUploadDrugId == this.drugCellLocationList[i].fileUploadDrugId
        );
        this.dataSource.data[index].checked = false;
      }
      this.drugCellLocationList=[];
    }
  }

  drugCellLocationChecked(row: any, isChecked: boolean) {
    if (isChecked) {
      this.drugCellLocationList.push({
        fileUploadDrugId: row.fileUploadDrugId,
        cellLoc: row.cellLoc,
        drugName: row.name,
        cellType: row.cellType,
        processedDrugId:row.processedDrugId
      });
      this.isAnyDrugSelected = true;
    } else {
      let index = this.drugCellLocationList.findIndex(
        (x) => x.fileUploadDrugId == row.fileUploadDrugId
      );
      this.drugCellLocationList.splice(index, 1);
      if(this.drugCellLocationList.length==0){
        this.isAnyDrugSelected = false;
      }
    }
  }

  getAvailableCellLocation(customerId) {
    const AVAILABLECELLLOCATION = 'Availablecelllocation'
    this.ngxLoader.start(AVAILABLECELLLOCATION);
    this.imtoolService.getAvailableCellLocation(customerId).subscribe(
      (response) => {
        let result = response.data;
        this.celLogList = [];
       result?.forEach(x=>{
        this.celLogList.push({
          id: x.cellLoc,
          value: x.cellLoc,
        });
      });
       this.ngxLoader.stop(AVAILABLECELLLOCATION);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(AVAILABLECELLLOCATION);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  drugCellLocationChanged(row: any) {

    if (!this.isAlreadyCellLocationUsed(row)) {
      for (let i = 0; i < this.drugCellLocationList.length; i++) {
        if (
          this.drugCellLocationList[i].fileUploadDrugId == row.fileUploadDrugId
        ) {
          this.drugCellLocationList[i].cellLoc = row.cellLoc;
          break;
        }
      }
    }
  }

  isAlreadyCellLocationUsed(row: any): boolean {
    for (let i = 0; i < this.drugCellLocationList.length; i++) {
      if (this.drugCellLocationList[i].cellLoc == row.cellLoc) {
        this.toastService.openToast(
          `drug (${this.drugCellLocationList[i].drugName}) has already used this cell location`,
          constants.ToastPanelType.warning,
          2
        );
        this.replaceTheOldCellLocation(row);
        return true;
      }
    }
    return false;
  }


  isDrugCellValidForThisRow(row: any):boolean{
    let cellType = row.cellLoc.substring(3);
     if(row.recSuperCell==="Yes" && cellType ==="Regular"){
      this.toastService.openToast(
        `Super cell can not be added to Regular Cell Location`,
        constants.ToastPanelType.warning,
        2
      );
     this.replaceTheOldCellLocation(row);
      return false;
     }
        else if(row.recSuperCell==="No" && cellType==="Super"){
         this.openAleftWizardForValidateDrug(row)
      }
      return true;




  }
openAleftWizardForValidateDrug(row:any){

  const dialogRef = this.dialog.open(AlertWizardComponent, {
    backdropClass: ['smDialog'],
    disableClose: true,
    data: {
      alertIcon: "/assets/img/icons/ico.16.warning.svg",
      title: 'Unoccupied Space Warning',
      bodyMessage: 'Are you sure you want to add a regular cell drug to a Super cell location',
      bodyMessage2: "",
      cancelBtnText: "Cancel",
      confirmBtnText: "Ok",
      confirmButtonColor: "btn-light-blue",
      cancelButtonColor: "btn-light-red",
    },
  });
  dialogRef.afterClosed().subscribe((result) => {
    if (!result.data) {
      this.replaceTheOldCellLocation(row);
    }
  });
}


  replaceTheOldCellLocation(row: any){
    let index = this.dataSource.data.findIndex(
      (x) => x.ndc == row.ndc
    );
    this.dataSource.data[index].cellLoc = row.cellLocOld;
    index = this.drugCellLocationList.findIndex((x)=> x.fileUploadDrugId== row.fileUploadDrugId)
    this.drugCellLocationList[index].cellLoc = row.cellLocOld;
  }



  getCurrentTabSelect(tab: any) {
    this.updateMainDataSourceArray(this.tabSelected)
    this.tabSelected = tab.index;
    this.drugCellLocationList = [];
  }
  updateMainDataSourceArray(tab:number){
    if(tab===0)
      {
          this.getTheSelectedDrugList().forEach(x=> {
            let index=  this.dataSource.data.findIndex(y=>y.processedDrugId == x)
            this.dataSource.data[index].checked = false;
            this.isAnyDrugSelected = false;
          } );
      }
      else if(tab === 1){
        this.getTheSelectedDrugList().forEach(x=> {
          let index=  this.dataSourceNotAbailable.data.findIndex(y=>y.processedDrugId == x)
          this.dataSourceNotAbailable.data[index].checked = false;
        } );
      }
      else if(tab === 2){
        this.getTheSelectedDrugList().forEach(x=> {
          let index=  this.dataSourceExcluded.data.findIndex(y=>y.processedDrugId == x)
          this.dataSourceExcluded.data[index].checked = false;
        } );
      }

        else if(tab === 3){
          this.getTheSelectedDrugList().forEach(x=> {
            let index=  this.dataSourceOral.data.findIndex(y=>y.processedDrugId == x)
            this.dataSourceOral.data[index].checked = false;
          } );
        }

  }

  getTheSelectedDrugList(): number[]{
    return this.drugCellLocationList.map((x) => x.processedDrugId)
  }

  downloadFile(data:number){
    if(data==1){
      this.openAlertWindow();
    }
    else if(data ==2){
      this.downloadXML();
    }
  }
  formateNdc(ndc: string):string{
    if(ndc?.length=== 11){
      return`${ndc.substring(0, 5)}-${ndc.substring(5, 9)}-${ndc.substring(9, 11)}`
    }
    return ndc;
  }

  configurTheUnconfiguredDrugandLocation(){
    let unconfiguredDrugs:CellLocatorSheet[] = [];
    let totalUnconfiguredDrug = this.manufacturingAnalyzeExcelModel.unConfiguredRegular + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredRegularLocking + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredSuper + 
                                this.manufacturingAnalyzeExcelModel.unConfiguredSuperLocking;
    
    let entriesToCreate = Math.max(totalUnconfiguredDrug, this.celLogList.length);
    for (let i = 0; i < entriesToCreate; i++) {
      unconfiguredDrugs.push({
          sl: null,
          drugName: 'UNCONFIGURED CELL',
          baffle: null, 
          cell: this.celLogList[i] ? this.celLogList[i].value : '',
          height: null, 
          maxCapacity: null, 
          supercellMaxCapacity: null, 
          ndc:'DO NOT APPLY LABEL',
          pressure: null, 
          isSuperCell: '',
          thirtyDramCapacity: null, 
          width: '',
          cellType: '',
          drugStatus: '',
          labelName: 'UNCONFIGURED CELL',
          specialCell: ''
      });
    }

      return  unconfiguredDrugs 
  }

  customExcelFileDownload() {
    const uri = 'data:application/vnd.ms-excel;base64,';
    let row = 1;
    let tableRow = 2;
   
    let newCellLocationrSheet = this.cellLocatorSheetList.slice();
    let concatenatedTableData2 = this.configurTheUnconfiguredDrugandLocation()
    concatenatedTableData2.forEach(x=> newCellLocationrSheet.push(x))
    let tableData = newCellLocationrSheet.map(x=>
      {
        let styleID;
        if (row % 2 === 0) {
            styleID = "S522";
        } else {
            styleID = "S52";
        }
        row++;
      return `<Row ss:Index="${tableRow++}">
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.sl==null?'':x.sl}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.drugName}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.ndc}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.height == null? '': x.height}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.width == null? '': x.width}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.baffle == null? '': x.baffle}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.pressure == null? '': x.pressure}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.thirtyDramCapacity == null? '': x.thirtyDramCapacity}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.cell == null? '':x.cell.split('-')[0]}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.maxCapacity == null? '': x.maxCapacity}</Data>
      </Cell>
      <Cell ss:StyleID="${styleID}"><Data ss:Type="String">${x.specialCell == null? '': x.specialCell}</Data>
      </Cell>
     </Row>`
      })
      let concatenatedTableData = tableData.join('');


    const template = `
    <?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">
    <x:ExcelWorkbook>
     <x:ActiveSheet>0</x:ActiveSheet>
    </x:ExcelWorkbook>
    <Styles>
     <Style ss:ID="Default" ss:Name="Normal">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S21">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="16" />
     </Style>
     <Style ss:ID="S22">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S23">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S24">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S25">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S26">
      <ss:Alignment ss:Horizontal="Right" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S27">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S28">
      <ss:Alignment ss:Horizontal="Right" ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S29">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S30">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S31">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S32">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S33">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S34">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S35">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S36">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="16" />
     </Style>
     <Style ss:ID="S37">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S38">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="16" />
     </Style>
     <Style ss:ID="S39">
      <ss:Alignment ss:Horizontal="Right" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S40">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S41">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S42">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S43">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S44">
      <ss:Alignment ss:Horizontal="Right" ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S45">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S46">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="16" />
     </Style>
     <Style ss:ID="S47">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S48">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Color="#000000" ss:FontName="Calibri" ss:Size="12" />
     </Style>
     <Style ss:ID="S49">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:Bold="1" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S50">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1" />
      <ss:Font ss:Bold="1" ss:FontName="Times New Roman" ss:Size="36" />
     </Style>
     <Style ss:ID="S51">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Arial" ss:Size="12" />
     </Style>
     <Style ss:ID="S52">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:FontName="Arial"  ss:Size="12" />
     </Style>
     <Style ss:ID="S522">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1"/>
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:FontName="Arial"  ss:Size="12" />
      <Interior ss:Color="#ffdab9" ss:Pattern="Solid"/>'
     </Style>
     <Style ss:ID="S53">
      <ss:Alignment ss:Vertical="Bottom" ss:WrapText="1" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:FontName="Arial" ss:Size="20" />
     </Style>
     <Style ss:ID="S54">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Borders>
       <ss:Border ss:Position="Left" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Top" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Right" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
       <ss:Border ss:Position="Bottom" ss:Color="#000000" ss:LineStyle="Continuous" ss:Weight="1" />
      </ss:Borders>
      <ss:Font ss:FontName="Arial" ss:Background = "#FFD733" ss:Bold="1" ss:Size="12" />
     </Style>
    </Styles>
    <Worksheet ss:Name="Cell Locator Sheet">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="34.35" ss:DefaultColumnWidth="54" ss:ExpandedRowCount="${newCellLocationrSheet.length+1}" ss:ExpandedColumnCount="11">
     <Column ss:Index="1" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="62.25" />
     <Column ss:Index="2" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="210.75" />
     <Column ss:Index="3" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="107.25" />
     <Column ss:Index="4" ss:Span="2" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="59.25" />
     <Column ss:Index="7" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="71.25" />
     <Column ss:Index="8" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="89.25" />
     <Column ss:Index="9" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="59.25" />
     <Column ss:Index="10" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="89.25" />
     <Column ss:Index="11" ss:StyleID="S51" ss:AutoFitWidth="0" ss:Width="95.25" />
      <Row ss:Index="1">
       <Cell ss:StyleID="S54"><Data ss:Type="String">#</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Drug Name</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">NDC #</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Height</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Width</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Baffle</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Pressure</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">30 Dram</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Cell</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">Max Capacity</Data>
       </Cell>
       <Cell ss:StyleID="S54"><Data ss:Type="String">S</Data>
       </Cell>
      </Row>

      ${concatenatedTableData}
 
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
      <x:Layout x:Orientation="Portrait"/>
      <x:Scale x:FitToWidth="1" x:FitToHeight="0"/>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" />
       <PageMargins x:Top="0.75"
        x:Bottom="0.75" 
        x:Left="0.25" 
        x:Right="0.25" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>200</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
       <Margin>Narrow</Margin>
       <FitToOnePage>True</FitToOnePage>
      </Print>
     </WorksheetOptions>
    </Worksheet>
    <Worksheet ss:Name="Checklist">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="15.75" ss:DefaultColumnWidth="54" ss:ExpandedRowCount="39" ss:ExpandedColumnCount="7">
      <Column ss:Index="1" ss:AutoFitWidth="0" ss:Width="200.75" />
      <Column ss:Index="2" ss:AutoFitWidth="0" ss:Width="111.75" />
      <Column ss:Index="3" ss:AutoFitWidth="0" ss:Width="79.5" />
      <Column ss:Index="4" ss:AutoFitWidth="0" ss:Width="71.25" />
      <Column ss:Index="5" ss:AutoFitWidth="0" ss:Width="27" />
      <Column ss:Index="6" ss:AutoFitWidth="0" ss:Width="67.5" />
      <Row ss:Index="1" ss:Height="20.25">
       <Cell ss:StyleID="S21"><Data ss:Type="String">Drug Configuration Summary</Data>
       </Cell>
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
      </Row>
      <Row ss:Index="2">
       <Cell ss:StyleID="S23" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S25" />
      </Row>
      <Row ss:Index="3">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Customer Name</Data>
       </Cell>
       <Cell ss:StyleID="S42"><Data ss:Type="String">${this.customerName}</Data>
       </Cell>
       <Cell ss:Index="4" ss:StyleID="S22" />
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="4">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Serial Number</Data>
       </Cell>
       
       <Cell ss:StyleID="S49" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22"><Data ss:Type="String">Date</Data>
       </Cell>
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="5">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Product Type</Data>
       </Cell>
       <Cell ss:StyleID="S42"><Data ss:Type="String">Max 2</Data>
       </Cell>
       <Cell ss:Index="4" ss:StyleID="S22" />
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S31" />
       <Cell ss:StyleID="S32" />
      </Row>
      <Row ss:Index="6">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Vial Type</Data>
       </Cell>
       <Cell ss:StyleID="S41" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22"><Data ss:Type="String">Tech. Initials</Data>
       </Cell>
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="7">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Software Version</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S31" />
       <Cell ss:StyleID="S32" />
      </Row>
      <Row ss:Index="8">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Host Interface Version</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="9">
       <Cell ss:StyleID="S33" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S35" />
      </Row>
      <Row ss:Index="10" ss:Height="20.25">
       <Cell ss:StyleID="S36"><Data ss:Type="String">Configuration Confirmation</Data>
       </Cell>
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S25" />
      </Row>
      <Row ss:Index="11">
       <Cell ss:StyleID="S37" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22"><Data ss:Type="String">Config</Data>
       </Cell>
       <Cell ss:StyleID="S22"><Data ss:Type="String">Verify/Initials</Data>
       </Cell>
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="12">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Configured Standard</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0167</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.configuredRegular}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="13">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Unconfigured Standard</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0167</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.unConfiguredRegular}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="14">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Configured Standard - Locking</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0166</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.configuredRegularLocking}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="15">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Unconfigured Standard - Locking</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0166</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.unConfiguredRegularLocking}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="16">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Configured Super</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0156</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.configuredSuper}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="17">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Unconfigured Super</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0156</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.unConfiguredSuper}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="18">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Configured Super - Locking</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0157</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.configuredSuperLocking}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="19">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Unconfigured Super - Locking</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">802-0157</Data> </Cell> 
       <Cell ss:StyleID="S30"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.unConfiguredSuperLocking}</Data>
       </Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="20">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Total Cells Configured</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S27"><Data ss:Type="Number">${this.manufacturingAnalyzeExcelModel.totalConfigured}</Data>
       </Cell>
       <Cell ss:StyleID="S27" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="21">
       <Cell ss:StyleID="S43" />
       <Cell ss:StyleID="S44" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
      </Row>
      <Row ss:Index="22">
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
      </Row>
      <Row ss:Index="23" ss:Height="20.25">
       <Cell ss:StyleID="S46"><Data ss:Type="String">Configuration Checklist</Data>
       </Cell>
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S45" />
       <Cell ss:StyleID="S47" />
      </Row>
      <Row ss:Index="24">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Apply Software Patches</Data>
       </Cell>
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S42" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="25">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Delete Data</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S41" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="26">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Verify Cell Barcodes</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="27">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Visual Check of Cells</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="28">
       <Cell ss:StyleID="S37"><Data ss:Type="String">MDL</Data>
       </Cell>
       <Cell ss:StyleID="S28"><Data ss:Type="String">Type:</Data></Cell>
       <Cell ss:StyleID="S22"><Data ss:Type="String">${this.manufacturingAnalyzeExcelModel.datasource}</Data></Cell>
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="29">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Drug Configuration</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="30">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Verify Software Settings</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>

      <Row ss:Index="31">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Label Cells</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="32">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Save Cell Layout</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="33">
       <Cell ss:StyleID="S37"><Data ss:Type="String">Configure Drop Off Bins</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="34">
       <Cell ss:StyleID="S33"><Data ss:Type="String">Host Interface</Data>
       </Cell>
       <Cell ss:StyleID="S39" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S30" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S35" />
      </Row>
      <Row ss:Index="35">
       <Cell ss:StyleID="S23" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S24" />
       <Cell ss:StyleID="S25" />
      </Row>
      <Row ss:Index="36" ss:Height="20.25">
       <Cell ss:StyleID="S38"><Data ss:Type="String">MAC Address</Data>
       </Cell>
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S22" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="37">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Cell Side</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S31" />
       <Cell ss:StyleID="S40" />
       <Cell ss:StyleID="S40" />
       <Cell ss:StyleID="S32" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="38">
       <Cell ss:StyleID="S26"><Data ss:Type="String">Drop-Off Side</Data>
       </Cell>
       <Cell ss:StyleID="S28" />
       <Cell ss:StyleID="S31" />
       <Cell ss:StyleID="S40" />
       <Cell ss:StyleID="S40" />
       <Cell ss:StyleID="S32" />
       <Cell ss:StyleID="S29" />
      </Row>
      <Row ss:Index="39">
       <Cell ss:StyleID="S48" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S34" />
       <Cell ss:StyleID="S35" />
      </Row>
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
     </WorksheetOptions>
    </Worksheet>
    <Worksheet ss:Name="Address Label">
     <ss:Names />
     <ss:Table ss:DefaultRowHeight="15.75" ss:DefaultColumnWidth="54" ss:ExpandedRowCount="8" ss:ExpandedColumnCount="1">
      <Column ss:Index="1" ss:AutoFitWidth="0" ss:Width="620.25" />
      <Row ss:Index="1" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">Customer Name:</Data>
       </Cell>
      </Row>
      <Row ss:Index="2" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">${this.customerName}</Data>
       </Cell>
      </Row>
      Row>
      <Row ss:Index="3" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String"></Data>
       </Cell>
      </Row>
      <Row ss:Index="4" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">Ship To:</Data>
       </Cell>
      </Row>
      <Row ss:Index="5" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">${this.manufacturingAnalyzeExcelModel.shippingStreet}</Data>
       </Cell>
      </Row>
      <Row ss:Index="6" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">${this.manufacturingAnalyzeExcelModel.shippingCity}, ${this.manufacturingAnalyzeExcelModel.shippingState}   ${this.manufacturingAnalyzeExcelModel.shippingPostalCode}</Data>
       </Cell>
      </Row>
      <Row ss:Index="7" ss:Height="45">
       <Cell ss:StyleID="S50" />
      </Row>
      <Row ss:Index="8" ss:Height="45">
       <Cell ss:StyleID="S50"><Data ss:Type="String">Attention: ${this.customerName}</Data>
       </Cell>
      </Row>
     </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0.3" />
       <Footer x:Margin="0.3" />
       <PageMargins x:Top="0.75" x:Bottom="0.75" x:Left="0.7" x:Right="0.7" />
      </PageSetup>
      <Print>
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
     </WorksheetOptions>
    </Worksheet>
   </Workbook>
   `
    const base64 = function(s:any) { return window.btoa(unescape(encodeURIComponent(s))) };
    const link = document.createElement('a');
    link.download =`Max Cell Bartender-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
      this.getCurrentDate()
     }.xls`;
    link.href = uri + base64(template);
    link.click();
}

  ExportToExcelFromJSON(){
    const EXPORTEXCELFROMJSON_KEY = 'Exortexcelfromjsonkey'
    this.ngxLoader.start(EXPORTEXCELFROMJSON_KEY);
    let analysisData:any[] =[];
    this.analysisDrugList.forEach(x=>{
      analysisData.push({
        'Top Order': x.topOrder,
        'Cell Loc.': x.cellLoc,
        'NDC': x.ndc,
        'GCN': x.genericSequenceNumber,
        'Cell Type': x.cellType,
        'CDDB Drug Name': x.name,
        'Customer Drug Name':x.drugName,
        'Strength': x.strength,
        'Pills Disp': this.orderOrUsage == '# of Orders'? x.noOfOrder: x.usage,
        '# of Orders': this.phllDispLable == 'Pills Disp'?  x.usage: x.noOfOrder,
        '30 Dram': this.dcrRplace(x.thirtyDramCapacity),
        'Cell Cap': x.cellCapacity,
        'Super Cell Cap': x.superCellCapacity,
        'Ratio': x.ratio,
        'Package Size': x.packageSize,
        'Manufacturer': x.manufacturer,
        'Schedule': x.sch,
        'Tab': x.tab,
        'Automate': x.automate,
        'Rec. Super Cell': x.recSuperCell,
        'Rec. Locking Cell': x.recLockingCell,
        'Pkg Size Std Cell': x.packageSizefitstandardcell,
        'IsUnRegulated': x.isUnregulated,
        // 'Drug Status': x.drugStatus,
      })
    })
    let newDrugData : any[]=[];
    this.notAvailableDrugList.forEach(x=>{
      newDrugData.push({
        'Top Order': x.topOrder,
        'NDC': x.ndc,
        'GCN': x.genericSequenceNumber,
        'Customer Drug Name':x.drugName,
        'Strength': x.strength,
        'Pills Disp': this.orderOrUsage == '# of Orders'? x.noOfOrder: x.usage,
        '# of Orders': this.phllDispLable == 'Pills Disp'?  x.usage: x.noOfOrder,

        '30 Dram': this.dcrRplace(x.thirtyDramCapacity),
        'Cell Cap': x.cellCapacity,
        'Super Cell Cap': x.superCellCapacity,
        'Ratio': x.ratio,
        'Package Size': x.packageSize,
        'Manufacturer': x.manufacturer,
        'Schedule': x.sch,
        'Tab': x.tab,
        'Automate': x.automate,
        'Rec. Super Cell': x.recSuperCell,
        'Rec. Locking Cell': x.recLockingCell,
        'Pkg Size Std Cell': x.packageSizefitstandardcell,
        'Non Count Reason':x.nonCountReason,
        'Rx Qty': x.rxQty,
        'Status': x.status,
        'Unit Qty' : x.unitQty,
        'IsUnRegulated': x.isUnregulated,
        // 'Drug Status': x.drugStatus

      })
    })

    let excludedData : any[]=[];
    this.excludedDrugList.forEach(x=>{
      excludedData.push({
        'Excluded Reason': x.excludedReason,
        'NDC': x.ndc,
        'CDDB Drug Name': x.name,
        'Customer Drug Name': x.drugName,
        'GCN': x.genericSequenceNumber,
        'Strength': x.strength,
        'Pills Disp': this.orderOrUsage == '# of Orders'? x.noOfOrder: x.usage,
        '# of Orders': this.phllDispLable == 'Pills Disp'?  x.usage: x.noOfOrder,
        '30 Dram': this.dcrRplace(x.thirtyDramCapacity),
        'Cell Cap': x.cellCapacity,
        'Super Cell Cap': x.superCellCapacity,
        '30 Dram Capacity':this.dcrRplace(x.thirtyDramCapacity),
        'Cell Capacity': x.cellCapacity,
        'Super Cell Capacity': x.superCellCapacity,
        'Ratio': x.ratio,
        'Manufacturer': x.manufacturer,
        'Schedule': x.sch,
        'Tab': x.tab,
        'Automate': x.automate,
        'Rec. Locking Cell': x.recLockingCell,
        'Rec. Super Cell': x.recSuperCell,
        'Pkg Size Std Cell': x.packageSizefitstandardcell,
        'Rx. Qty': x.rxQty,
        'Unit Qty': x.unitQty,
        'Package Size': x.packageSize,
       'Package Size Fit': x.packageSizeFit,
        // 'Drug Status': x.drugStatus,



      })
    })
    let notOralData: any[]=[];
    this.notOralDrugList.forEach(x=>{
      notOralData.push({
        'Top Order': x.topOrder,
        'NDC': x.ndc,
        'GCN': x.genericSequenceNumber,
        'CDDB Drug Name': x.name,
        'Customer Drug Name':x.drugName,
        'Strength': x.strength,
        'Pills Disp': this.orderOrUsage == '# of Orders'? x.noOfOrder: x.usage,
        '# of Orders': this.phllDispLable == 'Pills Disp'?  x.usage: x.noOfOrder,
        '30 Dram': this.dcrRplace(x.thirtyDramCapacity),
        'Cell Cap': x.cellCapacity,
        'Super Cell Cap': x.superCellCapacity,
        'Ratio': x.ratio,
        'Package Size': x.packageSize,
        'Manufacturer': x.manufacturer,
        'Schedule': x.sch,
        'Tab': x.tab,
        'Automate': x.automate,
        'Rec. Super Cell': x.recSuperCell,
        'Rec. Locking Cell': x.recLockingCell,
        'Pkg Size Std Cell': x.packageSizefitstandardcell,
        // 'Drug Status': x.drugStatus,
        'Dosage Form': x.dosageForm,
        'IsUnRegulated': x.isUnregulated,
      })
    })

    this.excelExportFileInfo={
      fileName:`Druglist-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName || ""}- ${
       this.getCurrentDate()
      }.xlsx`,
      templates:[
        {
          data: analysisData,
          sheetName:"Analysis"
        },
        {
          data: newDrugData,
          sheetName:"New Drugs"
        },
        {
          data: excludedData,
          sheetName:"Excluded"
        },
        {
          data: notOralData,
          sheetName:"Not Oral Solid"
        }
      ]
    }
    this.excelExportService.exportAsExcelFile(this.excelExportFileInfo);
    this.changeStatusToExport();
    this.ngxLoader.stop(EXPORTEXCELFROMJSON_KEY);
  }

  dcrRplace(actualValue):string{
    return actualValue === 'DCR'?'Configure on site': actualValue
  }

   OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "\n<" + prop + ">";

      if (obj[prop] instanceof Array) {
        var arr =obj[prop];
        for (var i = 0; i < arr.length; i++) {
        xml += "\n<" + prop + ">";
        xml += this.OBJtoXML(new Object(arr[i]));
        xml += "\n</" + prop + ">";
        if (i === arr.length - 1) {
          xml += "\n";
        }
      }

      } else if (typeof obj[prop] == "object") {
        xml +=  this.OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');

    return xml
  }
  downloadXML() {
      // analysis drug list
      var fileContents, fileName;

       fileContents = this.OBJtoXML(this.xmlAnalysisFormatData);
       fileName = `CellConfiguration-${this.customerName}-${this.deviceName}-${this.subDeviceTypeName}- ${this.getCurrentDate()}.xml`;
      var pp = document.createElement('a');
      pp.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(fileContents.trimStart()));
      pp.setAttribute('download', fileName);
      pp.click();


  }


  defaultSortSet(columnName:string, dir:any){
    const sortState: Sort = {active: columnName, direction: dir};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  changeStatusToExport() {
    const CHANGESTATUSEXPORT = 'Changestatusexportkey'
    this.ngxLoader.start(CHANGESTATUSEXPORT);
    this.imtoolService.changeStatusToExport(this.customerId).subscribe(
      (result) => {
        this.ngxLoader.stop(CHANGESTATUSEXPORT);
        if (result.statusCode == 200) {
          this.toastService.openToast(
            `Downloaded Successfully. ${ result.message}`,
            constants.ToastPanelType.done,
            2
          );
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(CHANGESTATUSEXPORT);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }
  getRecommendedDrugData(customerId, cellSize) {
    this.currentFilterSize = cellSize;
    const LISTDATALOAD_KEY = 'Listdataloadkey'
    this.ngxLoader.start(LISTDATALOAD_KEY);
    this.xmlAnalysisFormatData = new CellConfigurationXml()
    this.imtoolService.getRecommendedDrugData(customerId, cellSize).subscribe(
      (response) => {

        let result = response.data;

        if(result == null){
          this.ngxLoader.stop(LISTDATALOAD_KEY);
          this.toastService.openToast(
            response.message,
            constants.ToastPanelType.error,
            2
          );
          return
          } ;

          let datasourceValue = ImToolData.datasource.find(x=>x.key == result.dataSource)?.value;
        this.manufacturingAnalyzeExcelModel.configuredRegular= result.configuredRegular;
        this.manufacturingAnalyzeExcelModel.configuredRegularLocking = result.configuredRegularLocking;
        this.manufacturingAnalyzeExcelModel.configuredSuper = result.configuredSuper;
        this.manufacturingAnalyzeExcelModel.configuredSuperLocking = result.configuredSuperLocking;
        this.manufacturingAnalyzeExcelModel.totalConfigured = result.totalConfigured;
        this.manufacturingAnalyzeExcelModel.datasource = datasourceValue == undefined ? '':datasourceValue;
        this.manufacturingAnalyzeExcelModel.unConfiguredRegular = result.unConfiguredRegular;
        this.manufacturingAnalyzeExcelModel.unConfiguredRegularLocking = result.unConfiguredRegularLocking;
        this.manufacturingAnalyzeExcelModel.unConfiguredSuper = result.unConfiguredSuper;
        this.manufacturingAnalyzeExcelModel.unConfiguredSuperLocking = result.unConfiguredSuperLocking;
        this.manufacturingAnalyzeExcelModel.shippingCity = result.shippingCity;
        this.manufacturingAnalyzeExcelModel.shippingPostalCode = result.shippingPostalCode;
        this.manufacturingAnalyzeExcelModel.shippingState = result.shippingState;
        this.manufacturingAnalyzeExcelModel.shippingStreet = result.shippingStreet;
        this.manufacturingAnalyzeExcelModel.accountNumber = result.accountNumber;

        this.orderOrUsage = result.orderOrUsage == true?'# of Orders': 'Pills Disp';
        this.phllDispLable = result.orderOrUsage == true?'Pills Disp': '# of Orders';
       if( result.cellConfigurationXml != null){
        result.cellConfigurationXml?.cellConfiguration.cell.forEach(element => {
          this.xmlAnalysisFormatData.CellConfiguration.Cell.push({Location : element.location, NDC: element.ndc})
        });
      }

      
        this.customerName = result.customerName;
        this.targetInstallDate = result.targetInstallDate;
        this.subDeviceTypeName = result.deviceTypeName;
        this.deviceId= result.deviceId;
        this.deviceName = result.deviceName;

        this.lockingCellCount = result.lockingCellCount;
        this.regularCellCount = result.regularCellCount;
        this.remainingCellCount = result.remainingCellCount;
        this.unconfiguredRegularCount = result.unConfiguredRegular;
        this.unconfiguredRegularLockingCount = result.unConfiguredRegularLocking;
        this.unconfiguredSuperCount = result.unConfiguredSuper;
        this.unconfiguredSuperLockingCount = result.unConfiguredSuperLocking;
        this.remainingSuperCellCount = result.remainingSuperCellCount;
        this.superCellCount = result.superCellCount;
        this.superLockingCellCount = result.superLockingCellCount;
        this.alternateCellCount = result.noCellCount==undefined?0:result.noCellCount;
        this.allAssignDrugCount = result?.totalConfigured;
        this.analysisDrugList = [];
        this.notAvailableDrugList = [];
        this.excludedDrugList =[];
        this.notOralDrugList =[];
        this.gcnDrugList=[];
        this.cellLocatorSheetList = [];
        let rowSerial=1;
        result.drugs?.forEach((x) => {
          this.analysisDrugList.push({
            topOrder:x.topOrder,
            checked: false,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            name:x.name,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            rxQty: x.rxQty,
            unitQty: x.unitQty,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            cellLoc: x.cellLoc,
            cellLocOld: x.cellLoc,
            cellType: x.cellType,
            drugName: x.drugName,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            status:'',
            processedDrugType:x.processedDrugType,
            drugStatus: x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            processedDrugId:x.processedDrugId,
            isUnregulated: x.isUnregulated
          });
        });


        rowSerial=1;
        result.notAvailableDrugs?.forEach((x) => {
          this.notAvailableDrugList.push({
            sl:x.topOrder,
            topOrder:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            checked: false,
            drugName: x.drugName,
            status:x.drugStatus,
            name:x.name,
            rxQty: x.rxQty,
            unitQty: x.unitQty,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            nonCountReason:x.nonCountReasonForNotAvailable,
            drugStatus:x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            processedDrugId:x.processedDrugId,
            isUnregulated: x.isUnregulated
          });
        });
        rowSerial=1;
        result.excludedDrugs?.forEach((x)=>{

          this.excludedDrugList.push({
            sl:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            checked: false,
            drugName: x.drugName,
            status:x.excludedReason,
            name:x.name,
            rxQty: x.rxQty,
            unitQty: x.unitQty,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            topOrder:x.topOrder,
            excludedReason:x.excludedReason,
            recLockingCell:x.recLockingCell,
            thirtyDramCapacity:x.thirtyDramCapacity,
            ratio:x.ratio,
            recSuperCell:x.recSuperCell,
            cellCapacity:x.cellCapacity,
            superCellCapacity:x.superCellCapacity,
            packageSize:x.packageSize,
            packageSizeFit:x.packageSizeFit,
            drugStatus:x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            tab: x.tab,
            automate: x.automate,
            processedDrugId:x.processedDrugId

          })
        })
        rowSerial=1;
        result.notOralDrugs?.forEach((x)=>{

          this.notOralDrugList.push({
            sl:x.topOrder,
            checked: false,
            topOrder:x.topOrder,
            fileUploadDrugId: x.processedDrugId, // after discussion change this code( old code): x.fileUploadDrugId
            ndc: x.ndc,
            name:x.name,
            usage: x.usage,
            noOfOrder: x.noOfOrder,
            schedule: x.schedule,
            rxQty: x.rxQty,
            unitQty: x.unitQty,
            thirtyDramCapacity: x.thirtyDramCapacity,
            cellCapacity: x.cellCapacity,
            superCellCapacity: x.superCellCapacity,
            ratio: x.ratio,
            packageSizefitstandardcell: x.packageSizefitstandardcell,
            packageSize: x.packageSize,
            recSuperCell: x.recSuperCell,
            recLockingCell: x.recLockingCell,
            tab: x.tab,
            automate: x.automate,
            drugName: x.drugName,
            strength: x.strength,
            manufacturer: x.manufacturer,
            sch: x.sch,
            status:'',
            drugStatus: x.drugStatus,
            genericSequenceNumber:x.genericSequenceNumber,
            dosageForm:x.dosageForm,
            processedDrugId:x.processedDrugId,
            isUnregulated: x.isUnregulated
          })
        })
        rowSerial=1;
        result.gcnList?.forEach(element => {
          this.gcnDrugList.push({
            backgroundColor:'#fff',
            sl: element.topOrder,
            drugName: element.drugName,
            customerDrugName: element.customerDrugName,
            ndc: element.ndc,
            gcnNumber:element.genericSequenceNumber,
            manufacturer:element.manufacturer,
            strength:element.strength,
            unitOfUsage:element.usage,
            rxQty: element.unitQty,
            packageSize:element.packageSize,
            isChecked:false,
          })
        });

        rowSerial = 1;
        result.cellLocatorSheet?.forEach(element=>{
          this.cellLocatorSheetList.push({
            sl: rowSerial++,
            drugName:element.drugName,
            baffle:element.baffle,
            cell:element.cell,
            height:element.height,
            maxCapacity:element.maxCapacity,
            supercellMaxCapacity : element.superCellMaxCapacity,
            ndc:element.ndc,
            pressure:element.pressure,
            isSuperCell:element.s==true?'Yes':'No',
            thirtyDramCapacity:element.thirtyDramCapacity,
            width:element.width,
            cellType:element.cellType,
            drugStatus:element.drugStatus,
            labelName:element.labelName,
            specialCell:element.specialCell,
          })
        })

        this.dataSource.data = this.analysisDrugList;

        this.dataSourceNotAbailable.data = this.notAvailableDrugList;
        this.dataSourceExcluded.data = this.excludedDrugList;
        this.dataSourceOral.data = this.notOralDrugList;
        this.dataSourcecellLocatorSheet.data = this.cellLocatorSheetList;
        this.ngxLoader.stop(LISTDATALOAD_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(LISTDATALOAD_KEY);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );

      }
    );
  }
  removeDrugCellLocation() {
    const REMOVEDRUGCELLLOCATION_KEY = 'RemoveDrugCellLocationKey'
    this.ngxLoader.start(REMOVEDRUGCELLLOCATION_KEY);
    this.drugCellLocationUpdateList = {
      customerId: this.customerId,
      addRemoveDrugs: this.drugCellLocationList,
    };
    this.imtoolService.drugRemove(this.drugCellLocationUpdateList).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
          this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(REMOVEDRUGCELLLOCATION_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  isCellLocationNotEmpty():boolean{
    return this.drugCellLocationList.some(function (el) {
      return el.cellLoc === "" ||  el.cellLoc === null;
    });
  }

  addDrugCellLocation() {

    if(this.isCellLocationNotEmpty()){
      this.toastService.openToast(
        "Please select a cell location to Add!",
        constants.ToastPanelType.error,
        2
      );
    }
    else{
      const CELLLOCATIONADD_KEY = 'Celllocationaddkey' 
      this.ngxLoader.start(CELLLOCATIONADD_KEY);
      this.drugCellLocationUpdateList = {
        customerId: this.customerId,
        addRemoveDrugs: this.drugCellLocationList,
      };
      this.imtoolService.drugAdd(this.drugCellLocationUpdateList).subscribe(
        (result) => {
          if (result.statusCode == 200) {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
            window.setTimeout(() => {
              window.location.reload();
            }, 2000);
            this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          } else {
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
            this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(CELLLOCATIONADD_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
    }

  }

  openAlertWindowForDrugRemove() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Remove Drug ?",
        bodyMessage: "Are you sure, you want to remove this drug?",
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Remove",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.removeDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openAlertWindowForDrugAdd() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Add Drug?",
        bodyMessage: "Are you sure you want to add this drug?",
        bodyMessage2: this.drugCellLocationList
          .map((x, index) => {
            return `${index + 1}. ${x.drugName}`;
          })
          .join(" | "),
        cancelBtnText: "Cancel",
        confirmBtnText: "Add",
        confirmButtonColor: "btn-light-red",
        cancelButtonColor: "btn-light-blue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.addDrugCellLocation();
      } else {
        console.log("not export", result);
      }
    });
  }

  openImportWizardCDDB() {
    const dialogRef = this.dialog.open(ImportWizardCddbComponent, {
      backdropClass: ['lgDialog'],
    disableClose: true,
      data: {
        customerId: this.customerId,
        deviceId: this.deviceId
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugData(this.customerId, 0);
      } else {
        console.log("No change implement", result);
      }
    });
  }

  openImportWizardGCN() {
    const dialogRef = this.dialog.open(ImportWizardGcnComponent, {
    disableClose: true,
    backdropClass: ['lgDialog'],
      data: {
        gcnDrugList: this.gcnDrugList,
        customerId:this.customerId,
        customerName:this.customerName
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getRecommendedDrugData(this.customerId, 0);
      } else {
        console.log("No change implement", result);
      }
    });
  }
  public doFilter = (value: string) => {
    if (this.tabSelected === 0)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 1)
      this.dataSourceNotAbailable.filter = value.trim().toLocaleLowerCase();
    else if (this.tabSelected === 2)
      this.dataSourceExcluded.filter = value.trim().toLocaleLowerCase();
    else this.dataSourceOral.filter = value.trim().toLocaleLowerCase();
  };


  openAlertWindow() {
    let title =
      this.remainingCellCount == 0
        ? "Confirm export"
        : "Export with empty cell";
    let message1 =
      this.remainingCellCount == 0
        ? "Are you sure you wish to export?"
        : "There are currently open Cell Locations for this configuration, are you sure you wish to export?";

    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: title,
        bodyMessage: message1,
        bodyMessage2: "",
        cancelBtnText: "Cancel",
        confirmBtnText: "Export",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-red",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.ExportToExcelFromJSON();
      } else {
        console.log("not export", result);
      }
    });
  }

  redirectCustomerList() {
    this.router.navigate(["/im-tool"]);
  }
  openImportWizard() {
    const dialogSiteRef = this.dialog.open(ImportWizardComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
      data: { customer: {
        sl:1,
        customerId: this.customerId,
        customerName: this.customerName,
        caseNumber: '',
        customerNumber: '',
        deviceType: '',
        subDeviceTypeName:'',
        lastEdited:'',
        status:'',
        statusDescription: '',
        targetInstallDate: this.targetInstallDate,
      }, isparentnotassociated:2 },
    });
    dialogSiteRef.afterClosed().subscribe((result) => {
        this.getRecommendedDrugData(this.customerId, 0);
        this.getAvailableCellLocation(this.customerId);
    });
  }


  openImportWizardDrugMovingComponent() {

    const dialogRefMoving = this.dialog.open(ImportWizardDrugMovingComponent, {

      backdropClass: ['smDialog'],
      disableClose: true,
      data:{
          title:'',
          listOfData:[{ key:1, value:'Analysis', isSelected: this.tabSelected === 0 },
           { key:2, value:'New Drugs', isSelected: this.tabSelected === 1  },
           { key:3, value:'Excluded', isSelected: this.tabSelected === 2 },
           { key:4, value:'Not Oral Solid', isSelected: this.tabSelected === 3  },]
      }
    });
    dialogRefMoving.afterClosed().subscribe((result) => {
      if(result.data.isClose === true){
      this.onUpload(result.data.selectedTab, this.drugCellLocationList.map((x) => x.processedDrugId))
      }
    });
  }


  onUpload(selectedTab, drugs) {
    if(selectedTab === 0)
        {
          return
        }
  const MOVEDRUG_KEY = 'Movedrugkey'
    this.ngxLoader.start(MOVEDRUG_KEY);
    this.moveDrug = {
      customerId: this.customerId,
      deviceId: this.deviceId,
      processDrugType: selectedTab,
      drugId: drugs,
    }
    this.imtoolService.moveDrugs(this.moveDrug).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );

          this.ngxLoader.stop(MOVEDRUG_KEY);
          setTimeout(()=>{
            location.reload();
          },2000)

        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(MOVEDRUG_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(MOVEDRUG_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }


  openAlertWindowFoManufactureFile() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "",
        title: "Manufacturing Export File",
        bodyMessage: "Do you want to download the file locally or send it to the manufacturing team?",
        bodyMessage2:'',
        cancelBtnText: "Local",
        confirmBtnText: "Manufacture",
        confirmButtonColor: "btn-light-blue",
        cancelButtonColor: "btn-light-blue",
        buttonglobalClass :"space-between space-between",
        isButtonOneDisabled:false,
        isButtonTwoDisabled:true,

      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data && result.buttonClick === 2) {
        //this.removeDrugCellLocation();
      } else if(!result.data && result.buttonClick === 1) {
        this.customExcelFileDownload();
      }
    });
  }
  
  getCurrentDate(): string {
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = {  
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    return currentDate.toLocaleDateString('en-US',options);
  }

}
