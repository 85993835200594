<app-card>
  <div title class="inventory_ctrl flex flex-row justify-between content-center items-center xs:flex xs:justify-start xs:content-start xs:items-start">
    <h5 class="ucase m-0 text-primaryblue xs:basis-full basis-1/2" tabindex="0">Fleet Management</h5>

    <div class="xs:flex xs:flex-row xs:justify-center xs:content-stretch xs:items-stretch xs:h-full xs:basis-full flex flex-row justify-end content-center items-center h-full basis-1/2">
      <mat-radio-group aria-label="Select an option" class="xs:hidden sm:block md:block lg:block">
        <mat-radio-button value="1" class="m-r-16" checked="checked" (change)="radioChange($event)" tabindex="0">
          Pre-Provisioned</mat-radio-button>
        <mat-radio-button value="2" (change)="radioChange($event)" tabindex="0">Provisioned</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="p-t-16 p-b-16 bb-Gray4 preprovisioned_switch flex flex-row justify-start content-start items-start h-full gap-4 basis-full sm:hidden xs:block md:hidden lg:hidden xl:hidden">
      <mat-button-toggle-group name="fontStyle" role="radiogroup" tabindex="0">
        <mat-button-toggle value="1" checked="checked" role="radio" (change)="radioChange($event)" tabindex="0" 
          aria-checked="true">Pre-Provisioned</mat-button-toggle>
        <mat-button-toggle value="2" role="radio" (change)="radioChange($event)" tabindex="-1" aria-checked="false">
          Provisioned</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div *ngIf="selected=='1'">
    <app-pre-provisioned></app-pre-provisioned>
  </div>
  <div *ngIf="selected=='2'">
    <app-provisioned></app-provisioned>
  </div>
</app-card>
