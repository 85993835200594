import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InventoryViewsService extends DataService<any> {

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.viewstoreService}`, http);
  }

  public getInventoriesByOrg(): Observable<any> {       
    return this.http.get<any>(`${this.url}inventoryviews`);
  }
  public getInventoriesBySite(siteId, deviceType): Observable<any> {  
   
    return this.http.get<Observable<any>>(`${this.url}inventoryviews/site/${siteId}/devicetype/${deviceType}`);
  }
  public getInventoriesByDevice(siteId,deviceId): Observable<any> {     
    return this.http.get<Observable<any>>(`${this.url}inventoryviews/site/${siteId}/device/${deviceId}`);
  }

  public getInventoriesExportByOrg(days): Observable<Blob> {   
    return this.http.get(`${this.url}inventoryviews/export/days/${days}`, { responseType: 'blob' });
  }
  public getInventoriesExportBySite(siteId,deviceType,days): Observable<Blob> { 
    return this.http.get(`${this.url}inventoryviews/export/site/${siteId}/devicetype/${deviceType}/days/${days}`, { responseType: 'blob' });
  }
  public getInventoriesExportByDevice(siteId,deviceId,days): Observable<Blob> {   
    return this.http.get(`${this.url}inventoryviews/export/site/${siteId}/device/${deviceId}/days/${days}`, { responseType: 'blob' });
  }

}
