<div class="inv_table" #inventoryTable matSort matSortDisableClear matSortActive="drugName"
  matSortDirection="asc" (matSortChange)="sortingChange()">
  <div class="inv_table-header inv_table-row">
    <div class="td"></div>
    <div class="td" mat-sort-header="drugName" tabindex="0"  matTooltip="Drug Name">Drug Name </div>
    <div class="td" tabindex="0" matTooltip="NDC">NDC</div>
    <div class="td" tabindex="0"  matTooltip="Manufacturer">Manufacturer</div>
    <div class="td" tabindex="0"  matTooltip="Actual Dispense Quantity">
      Act. Disp.<!--Actual Dispense Quantity-->
      </div>
      <div class="td" mat-sort-header="avgDailyDispense" tabindex="0" matTooltip="Average quantity dispensed per day for the last {{dateRange}} days">
        Avg. Daily Disp.
        <img src="../../../../../assets/img/icons/ico.16.info.svg" attr.aria-label="Average quantity dispensed per day for the last {{dateRange}} days"  class="m-l-8" alt="" tabindex="0">
      </div>
    <div class="td" mat-sort-header="totalQuantity" tabindex="0" matTooltip="Current Inventory">Cur. Inv.</div>
    <div class="td" *ngIf="view === View.SYSTEM" tabindex="0" matTooltip="Canister Location">Can. Loc.</div>
    <div class="td" *ngIf="view !== View.SYSTEM"></div>

  </div>
  <div class="inv_table-row" *ngFor="let row of inventories ; let rowIndex = index">
    <div class="inv_table-body borderBottom">
      <div class="collapseElement ">
        <div class="td" [class.clickable]="isRowClickable(rowIndex)" (click)="onExpandRow(row,rowIndex);" (keydown.enter)="onExpandRow(row,rowIndex);" tabindex="0">
          <span>
            <app-icon [icon]="row.isExpanded ? 'arwup' : 'arwdown'" size="12" class="pointer">
            </app-icon>
          </span>
        </div>
        <div class="td" id="{{row.drugName}}" tabindex="0" [attr.aria-label]="'Drug Name' + row.drugName" [innerHTML]="row.drugName| noHtml | highlightMatched: filterText | titlecase"></div>
        <div class="td" id="{{row.ndc}}" tabindex="0" [attr.aria-label]="'Ndc' + row.ndc" [innerHTML]="row.ndc | noHtml | highlightMatched: filterText"></div>
        <div class="td" id="{{row.manufacturer}}" tabindex="0" [attr.aria-label]="'Manufacturer' + row.manufacturer">{{row.manufacturer | titlecase}}</div>
        <div class="td" id="{{row.actualDispenseQuantity}}" tabindex="0" [attr.aria-label]="'Actual Dispense Quantity' + row.actualDispenseQuantity">{{row.actualDispenseQuantity | number}}</div>
        <div class="td" id="{{row.avgDailyDispense}}" tabindex="0" [attr.aria-label]="'Average Daily Dispense' + row.avgDailyDispense">{{row.avgDailyDispense | number}}</div>
        <div class="td" id="{{row.totalQuantity}}" tabindex="0" [attr.aria-label]="'Total Quantity' + row.totalQuantity">{{row.totalQuantity | number}}</div>
        <div class="td" *ngIf="view === View.SYSTEM" tabindex="0" [attr.aria-label]="'Canister Loc' + row.canisterLocation">{{row.canisterLocation}}</div>
        <div class="td" *ngIf="view !== View.SYSTEM"></div>

        <div *ngIf="row.isExpanded" style="height: unset" class="detailTable">
        <div class="borderTop"></div>
        <div class="child-row" *ngFor="let childrow of row.lastDispInfo;  let innerRowIndex = index">
          <div class="td"></div>
          <div class="td" *ngIf="innerRowIndex==0" tabindex="0" aria-label="Last Dispensed">  Last Dispensed</div>
          <div class="td" *ngIf="innerRowIndex>0"></div>
          <div class="td" tabindex="0" [attr.aria-label]="'NDC' + childrow.lastFilledDate">{{childrow.lastFilledDate}}</div>
          <div class="td"></div>
          <div class="td" *ngIf="view == View.SYSTEM"></div>
          <div class="td" *ngIf="view == View.SYSTEM"></div>
          <div class="td" *ngIf="view != View.SYSTEM" tabindex="0" [attr.aria-label]="'Actual Dispense Quantity' + childrow.actualDispenseQuantity">{{childrow.actualDispenseQuantity | number}}</div>
          <div class="td" *ngIf="view != View.SYSTEM" tabindex="0" [attr.aria-label]="'Average Daily Dispense' + childrow.avgDailyDispense">{{childrow.avgDailyDispense | number}}</div>
          <div class="td" tabindex="0" [attr.aria-label]="'Quantity' + childrow.quantity">{{childrow.quantity | number}}</div>
          <div class="td" *ngIf="view != View.SYSTEM" tabindex="0" [attr.aria-label]="childrow?.link" matTooltip="{{childrow?.link}}" matTooltipClass="my-custom-tooltip"> {{ childrow?.link | truncate:10 | uppercase}}</div>
          <div class="td" *ngIf="view === View.SYSTEM" tabindex="0" [attr.aria-label]="childrow?.locationId" matTooltip="{{childrow?.locationId}}" matTooltipClass="my-custom-tooltip">{{ childrow?.locationId | truncate:10 | uppercase}}</div>
        </div>
      <!-- </div> -->
    </div>
      </div>
  </div>
  <div class="inv_table-row" *ngIf="loading">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center">
        <img src="/assets/img/Parata_processing.gif" alt="loading" class="w-10 m-15" />
      </div>
    </div>
  </div>
  <div class="inv_table-row" *ngIf="inventories && inventories.length === 0">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center w-100 m-15" tabindex="0">
        No data exists
      </div>
    </div>
  </div>
</div>
