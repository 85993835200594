<div class="inner-mat-dialog flex justify-start content-stretch items-stretch flex-col">
    <div>
        <h1 mat-dialog-title class="flex justify-start content-center items-center flex-row">
            <div class="flex justify-start content-center items-center flex-row">
                <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-2/4">
                    Group Cell Page
                    <div class="sticky_search">
                        <div class="search-wrapper" style="padding-left: 9px;">
                            <div class="search-container">
                                <app-icon icon="search" class="icon_search"></app-icon>
                                <input class="search-input" appNoHtmlTags inputId="searchcustomers-input" (keyup)="doFilter($event.target.value)"  minLengthToSearch="1" placeholder="Search" [ngModel]="searchText"  id="search_customers">
                                <app-icon icon="clear" *ngIf="searchText.length>0" (click)="doFilter('')" id="data-clear" class="pointer"></app-icon>
                            </div>
                        </div>
                        </div>
                </div>
                
                
                <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-2/4">
                    <mat-icon (click)="closeWizard.emit(true)" class="cross-wizard">
                      <img src="/assets/img/icons/ico.16.clear.svg" alt="">
                    </mat-icon>
                  </div>
            </div>

        </h1>


        <mat-dialog-content>

            <div class="mat-elevation-z8" #TABLE>
                <table #table mat-table [dataSource]="dataSource">
                    <!-- SL Name Column -->
                    <ng-container matColumnDef="sl">
                        <th mat-header-cell *matHeaderCellDef> Top Order </th>
                        <td mat-cell *matCellDef="let element"> {{element?.sl}} </td>
                    </ng-container>

                    <!-- Drug Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Drug Name </th>
                        <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
                    </ng-container>

                    <!-- Strength Column -->
                    <ng-container matColumnDef="strength">
                        <th mat-header-cell *matHeaderCellDef> Strength </th>
                        <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
                    </ng-container>
                    <!-- Container Size Column -->
                    <ng-container matColumnDef="bottleSize">
                        <th mat-header-cell *matHeaderCellDef> Bottle Size </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.bottleSize}}
                        </td>
                    </ng-container>
                    <!-- dailyUsage Column -->
                    <ng-container matColumnDef="dailyUsage">
                        <th mat-header-cell *matHeaderCellDef> Minimum Supply </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.dailyUsage}}
                        </td>
                    </ng-container>

                    <!-- cellCapacity Column -->
                    <ng-container matColumnDef="cellCapacity">
                        <th mat-header-cell *matHeaderCellDef> Canister Capacity </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.cellCapacity}}
                        </td>
                    </ng-container>
                    <!-- groupCell Column -->
                    <ng-container matColumnDef="groupCell">
                        <th mat-header-cell *matHeaderCellDef> # of Canister </th>
                        <td mat-cell *matCellDef="let element">
                            <input class="custom-input" min="1" onkeypress="return !(event.charCode == 46)" #inputField (keyup)="inputField.value = inputField.value < 1 ? 1 : inputField.value; element.groupCell = inputField.value < 1 ? 1 : inputField.value" [(ngModel)]="element.groupCell" type="number">
                        </td>
                    </ng-container>

                    <!-- cellType Column -->
                    <ng-container matColumnDef="cellType">
                        <th mat-header-cell *matHeaderCellDef> Canister Type </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.cellType}}
                        </td>
                    </ng-container>


                    <!-- ndc Column -->
                    <ng-container matColumnDef="ndc">
                        <th mat-header-cell *matHeaderCellDef> NDC</th>
                        <td mat-cell *matCellDef="let element"> {{element?.ndc}} </td>
                    </ng-container>

                    <!-- Unit Usage Column -->
                    <ng-container matColumnDef="usage">
                        <th mat-header-cell *matHeaderCellDef> Usage </th>
                        <td mat-cell *matCellDef="let element"> {{element?.usage}} </td>
                    </ng-container>

                     <!-- LargeCanisterCapacity Column -->
                     <ng-container matColumnDef="largeCanisterCapacity">
                        <th mat-header-cell *matHeaderCellDef> Large Canister Capacity </th>
                        <td mat-cell *matCellDef="let element"> {{element?.largeCanisterCapacity}} </td>
                    </ng-container>

                     <!-- ExtenderCapacity Column -->
                     <ng-container matColumnDef="extenderCapacity">
                        <th mat-header-cell *matHeaderCellDef> Extender Capacity</th>
                        <td mat-cell *matCellDef="let element"> {{element?.extenderCapacity}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row (click)="selectedRow = row" [style.background]="row?.backgroundColor" 
                      [ngClass]="{ 'highlight': row === selectedRow }" 
                      *matRowDef="let row; columns: displayedColumns;"></tr>


                </table>
                <table>
                    <tr *ngIf="dataSource?.data.length==0">
                        <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                            No Records Found!
                        </td>
                    </tr>
                </table>

            </div>


        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
        <button mat-button class="btn-primary btn-md" (click)="this.hideSide.emit(3)" id="Go_Back" type="button" 
            aria-label="Go_Back">Go Back</button>
        <button mat-button class="btn-blue btn-md" id="Confirm" (click)="saveGcnDrugs()" type="button" 
            aria-label="Confirm">Confirm</button>
    </mat-dialog-actions>

</div>