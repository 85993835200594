<h1 mat-dialog-title>DISPENSING SCRIPT DETAILS</h1>

<mat-dialog-content>
  <div class="up-info-panel ">
    <div class="rx-num headerTable">Rx Number</div>
    <div class="rx-num-data dataTable">{{scriptData?.rxNumber}}</div>

    <div class="patient-name headerTable">Patient Name</div>
    <div class="patient-name-data dataTable">{{scriptData?.patientFirstName}} {{scriptData?.patientLastName}}</div>

    <div class="drug-name headerTable">Drug Name</div>
    <div class="drug-name-data dataTable">{{scriptData?.drugName}}</div>

    <div class="disp-qty headerTable">Disp. Qty</div>
    <div class="disp-qty-data dataTable">{{scriptData?.dispenseQuantity}}</div>

    <div class="system headerTable">System</div>
    <div class="system-data dataTable">{{scriptData?.assignedMachine == '' ? '-' : scriptData?.assignedMachine}}</div>

    <div class="status headerTable">Status</div>
    <div class="status-data dataTable">{{scriptData?.status}}</div>

    <div class="priority headerTable">Priority</div>
    <div class="priority-data dataTable">{{object.values(priorityEnum).includes(scriptData?.priority) ? priorityEnum[scriptData?.priority] : scriptData?.priority}}</div>

    <div class="received-time headerTable">Received Time</div>
    <div class="received-time-data dataTable">{{scriptData?.receivedTime == null ? '-' : datePipe.transform(scriptData?.receivedTime, 'd-MMM-yyyy h:mm:ss aa')}}</div>

    <div class="completed-time headerTable">Completed Time</div>
    <div class="completed-time-data dataTable">{{scriptData?.completedTime == null ? '-' : datePipe.transform(scriptData?.completedTime, 'd-MMM-yyyy h:mm:ss aa')}}</div>
  </div>

  <div class="down-info-panel">
    <div class="DrugDetail title-bottom">DRUG DETAILS</div>
    <div class="DrugDetailData">
      <div class="DrugNameDown headerTable">Drug Name</div>
      <div class="DrugNameDownData dataTable">{{scriptData?.drugName}}</div>
      <div class="NdcDown headerTable">NDC</div>
      <div class="NdcDownData dataTable">{{scriptData?.drugCode}}</div>
    </div>
    <div class="DrugPicture image-dispensing-container">
      <img class="image-dispensing" src="/assets/img/sidebar_mobilenav_bg.png" alt="Drug Image">
    </div>

    <div class="VialDetail title-bottom">VIAL DETAILS</div>
    <div class="VialDetailData">
      <div class="VialStatusDown headerTable">Vial Status</div>
      <div class="VialStatusDownData dataTable">{{selectedVial?.status}} ({{getCorrectDate(selectedVial?.lastUpdate )}})</div>
      <div class="VialQuantityDown headerTable">Vial Quantity</div>
      <div class="VialQuantityDownData dataTable">{{selectedVial?.expectedQuantity}}</div>
      <div class="CellLocationDown headerTable">Location</div>
      <div class="CellLocationDownData dataTable">{{selectedVial?.dropOffLocation}}</div>
    </div>
    <div class="VialPicture image-dispensing-container">
      <img class="image-dispensing"  [src]="imagePath" alt="Vial Image">
    </div>

    <div class="Vials title-bottom">VIALS ({{vialData.length}})</div>
    <div class="VialsData">
      <ng-container *ngFor="let vial of vialData; let i = index">
        <div class="VialsDataOuterBox"
             [ngClass]="{'VialsDataOuterBoxIncomplete': vial.status === 'Incomplete', 'VialsDataOuterBoxSelected': selectedVialNumber === i+1 && vial.status !== 'Incomplete', 'VialsDataOuterBoxIncompleteSelected': selectedVialNumber === i+1 && vial.status === 'Incomplete'}"
             (click)="selectVial(i+1, vial)">
          <div class="VialsDataInnerBox">
            <div class="vial-status-num">{{i+1}}</div>
            <div class="vial-status-text">{{vial.status}}</div>
            <img class="vial-status-alert-icon" *ngIf="vial.status === 'Incomplete' && selectedVialNumber !== i+1" src="../../../../../assets/img/icons/ico.16.alert.svg" alt="Alert">
            <img class="vial-status-alert-icon" *ngIf="vial.status === 'Incomplete' && selectedVialNumber === i+1" src="../../../../../assets/img/icons/ico.16.alert.inverted.svg" alt="Alert">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="wrapper-dialog-action">
    <button mat-button class="btn-primary" (click)="dialogRef.close()">Done</button>
  </div>
</mat-dialog-actions>
