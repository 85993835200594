import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { getSystemStatusDevice } from 'src/app/store/selectors/pharmacy-peek.selector';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { getDifferenceInTime } from 'src/app/external-user/pharmacy-peek/services/mapping';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';

export interface SystemStatus {
  system: string;
  alert: string;
  status: string;
  duration: string;
}

@Component({
  selector: 'app-system-status-device',
  templateUrl: './system-status-device.component.html',
  styleUrls: ['./system-status-device.component.scss']
})

export class SystemStatusDeviceComponent implements OnInit, OnDestroy {
  @Input() filterDeviceId: any;
  @Input() pgAssets: any;

  displayedColumns: string[] = ['system', 'alert', 'status', 'duration'];
  dataSource = new MatTableDataSource<SystemStatus>();
  destroyed$ = new Subject();
  countDown: Subscription;

  childOrg = '';
  noDataExistsForDevice = true;
  deviceId: string;
  deviceName: string;
  datePipe: DatePipe = new DatePipe('en-US');
  errorType = 'None';
  resData: any = [];
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.SYSTEM_STATUS_DEVICE,
    deviceId: ''
  }


  constructor(
    public store: Store<State>,
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
  ) { }

  ngOnInit(): void {
    this.store.select(getSystemStatusDevice).subscribe(res => {
      if (res && res.data) {
        this.resData = res?.data;
        this.updateTableData(this.resData);
        this.setInterval();
      }
    });
    
    this.subscription = this.signal.getDataStream<string>(SignalEventType.SYSTEM_STATUS_DEVICE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateSystemStatusDevice({ data: dataObj.data }));
      console.log(dataObj);
    })

    this.signalrSubList = {
      type: SignalEventType.SYSTEM_STATUS_DEVICE,
      deviceId: this.filterDeviceId ? this.filterDeviceId : ''
    }

    this.callSystemStatus();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
    this.deviceId = this.filterDeviceId ? this.filterDeviceId : ''; 
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
    this.cancelInterval();
  }

  private callSystemStatus(deviceId?: any) {
    this.viewStoreService.getSystemStatusAllSystem().subscribe(
      (response) => {
        this.store.dispatch(PharmacyPeekActions.getSystemStatusDevice({ data: response }));
      },
      (error) => {
        console.log('SystemStatus error:', error);

        if (error.status === 404) {
          this.loggerService.logWarning('SystemStatus Service: No Data: ', error);
        } else {
          this.toastService.openToast('System Status service load error!', ToastPanelType.error);
        }
      },
    );
  }
  
  setInterval() {
    this.countDown = interval(10000).pipe(
      takeUntil(this.destroyed$)).subscribe(() => {
        if (this.resData) {
          this.updateTableData(this.resData)
        }
      });
  }

  cancelInterval() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
    if (this.countDown && !this.countDown.closed) {
      this.countDown.unsubscribe();
      }
  }

  updateTableData(devices) {
    const newData = [];
    for (let [key, value] of Object.entries(devices)) {
      let itData: any = value;
      const duration = getDifferenceInTime(itData.currentStateTimestamp);
      let newPData = [];
      let copyPData = this.pgAssets.slice();

      if (this.filterDeviceId) {
        newPData = copyPData.filter(t => t.deviceId == this.filterDeviceId);
      }

      if (newPData.length === 0) { return; }

      newPData.forEach(element => {
        if (element.deviceId === key) {
          this.deviceName = element.deviceName;
          this.deviceId = element.deviceId;
          this.errorType = 'None';

          if (itData.deviceType === 'ATP') {
            if (itData.currentState === 'Tray Fill Load' || itData.currentState === 'Canister Load') {
              this.errorType = 'Warn';
            }
            else if (itData.currentState === 'Other' || itData.currentState === 'Mechanical Downtime' || itData.currentState === 'Operator Initiated') {
              this.errorType = 'Error';
            }
          }

          if (itData.deviceType === 'MAX') {
            if (itData.currentState === 'Paused') {
              this.errorType = 'Warn';
            }
            else if (itData.currentState === 'Error' || itData.currentState === 'Manual Downtime' || itData.currentState === 'Maintenance') {
              this.errorType = 'Error';
            }
          }

          const newObj = {
            system: this.deviceName ? this.deviceName : key,
            alert: '',
            status: itData.currentState,
            duration: duration,
            errorType: this.errorType
          }

          if (this.deviceId === key) {
            newData.push(newObj);
          }

          this.dataSource.data = newData;
          this.noDataExistsForDevice = false;
        }

      }
      );

    };
  }

}
