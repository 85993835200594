<div *ngIf="machineTypeMAX === false">

  <div class="flex flex-row">
    <div class="xs:basis-full sm:basis-[95%] md:basis-[95%] lg:basis-[95%]">
      <ul class="breadcrumb flex justify-start content-stretch items-stretch flex-row" tabindex="0">
        <li [ngClass]="view === View?.ORG ? 'active' : 'default'" id="ParentOrganization" tabindex="0">
          <span *ngIf="parentOrgName" class="b_info">
            <span class="svgicon icon_enterprise"></span>
            <span class="org_mac" matTooltip="{{ parentOrgName }}" matTooltipPosition="below">{{ parentOrgName }}</span>
          </span>
        </li>
        <li [ngClass]="view === View.SITE ? 'active' : 'default'" id="SiteOrganization" tabindex="0">
          <span class="b_info">
            <span class="svgicon icon_pharmacy"></span>
            <span class="org_mac" *ngIf="selectedSite" matTooltip="{{ selectedSite?.name }}">{{ selectedSite?.name }}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="xs:basis-[5%] xs:hidden sm:basis-[5%] md:basis-[5%] lg:basis-[5%]">
      <div class="flex justify-end content-start items-start flex-row">
      </div>
    </div>
  </div>
  
  <app-card>
    <div title class="inventory_ctrl flex justify-start content-center items-center flex-row">
      <h5 class="m-0 text-primaryblue text-title lg:grow-0 lg:shrink lg:basis-[270px] xl:grow-0 xl:shrink xl:basis-[270px]" tabindex="0">SCRIPT
        MANAGEMENT</h5>
      <div>
        <div class="app-search-bar-container p-0 xs:flex xs:flex-col flex flex-row">
          <app-search-bar *ngIf="Pouch_ScriptManagement_Search === true" class="xs:basis-full grow-0 shrink basis-[370px]"
            placeholder="Search by Batch Name, System or Patient Name" inputId="script-mgnt-search" 
            (filterChange)="filterInventory($event)"></app-search-bar>
          <form *ngIf="Pouch_ScriptManagement_Sort === true" action="" class="smsort flex justify-start content-center items-center basis-full">
            <span class="sort">Sort By</span>
            <mat-form-field appearance="fill" class="m-l-8 xs:flex-1 grow-0 shrink basis-[200px]">
              <mat-select class="m-t-0 sm_sorting">
                <mat-option>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <h6 class="m-l-16 m-r-16 m-t-8 m-b-8 text-primaryblue text-title h7" tabindex="0">SCRIPT VIEW</h6>
    <div *ngIf="machineTypeATP === false" class="loading-spinner text-center w-100 m-15">
      No data exists
    </div>
  </app-card>
  
  <mat-tab-group headerPosition="below" class="scriptmgmt_tab" *ngIf="Pouch_ScriptManagement_Complete === true && machineTypeATP === true" 
  (selectedIndexChange)="onTabChanged($event)" [selectedIndex]="selectedIndexBinding" >
    <mat-tab class="tab-table-button-left">
      <app-pouch-script-manager-table [inventories]="queueDataPouch"  [filterText]="filterText" [isComplete]=false></app-pouch-script-manager-table>
      <ng-template class="tab-table-button-left" mat-tab-label> POUCH ORDERS </ng-template>
    </mat-tab>
    <mat-tab class="tab-table-button-right">
      <app-pouch-script-completed [inventories]="queueDataPouch"  [filterText]="filterText" [isComplete]=true></app-pouch-script-completed>
      <ng-template mat-tab-label> COMPLETE ({{completeCount}})</ng-template>
    </mat-tab>
  </mat-tab-group>
  
  <mat-tab-group headerPosition="below" class="scriptmgmt_tab scriptmgmt_tab_NoComplete" *ngIf="Pouch_ScriptManagement_Complete === false">
  
    <mat-tab *ngIf="machineTypeATP === true">
      <app-pouch-script-manager-table [inventories]="queueDataPouch" [filterText]="filterText" [isComplete]=false></app-pouch-script-manager-table>
      <ng-template mat-tab-label > POUCH ORDERS </ng-template>
    </mat-tab>
  </mat-tab-group>
  
  </div>