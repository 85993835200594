<h1 mat-dialog-title>
  Change Priority
  <button class="close" (click)="dialogRef.close()"></button>
</h1>
<mat-dialog-content>
  <h6>Select New Priority</h6>
  <mat-radio-group aria-labelledby="priority-radio-group-label" class="priority-radio-group" [(ngModel)]="prioritySelected">
    <mat-radio-button class="priority-radio-button" *ngFor="let priority of priorities; let i = index" [value]="priority" [disabled]="priority == 4 ? 'disabled': null" [checked] ="priority === prioritySelected"
      (click)="setPriority(priorityEnum[priority]);">
      <div class="priority-radio-button-content">
        <div >{{priorityEnum[priority]}}</div>
        <div class="text-{{priority}}" *ngIf="priority != 1">
          {{priorityEnum[priority]}}
        </div>
      </div>
    </mat-radio-button>
  </mat-radio-group>


</mat-dialog-content>

<mat-dialog-actions >
  <button mat-button class="btn-default" (click)="dialogRef.close()">Cancel</button>
  <button mat-button class="btn-primary" [mat-dialog-close]="" cdkFocusInitial  (click)="ConfirmChange();">Change Priority</button>
</mat-dialog-actions>
