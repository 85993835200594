import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { State } from '../reducers/fleet-manager.reducer';

export const getPreProvisionState = createFeatureSelector<State>('iotDeviceDetails');

export const getPreProvision = createSelector(
  getPreProvisionState,
  (state: State) => {
    if (state?.iotDeviceDetails?.unprovisioned) {
      return getSorted(state.iotDeviceDetails?.unprovisioned);
    }
    else {
      return state.iotDeviceDetails;
    }
  }
);

export const getProvision = createSelector(
  getPreProvisionState,
  (state: State) => {
    if (state?.iotDeviceDetails?.provisioned) {
      return getSorted(state.iotDeviceDetails?.provisioned);
    }
    else {
      return state.iotDeviceDetails;
    }
  }
);

export function getSorted(data) {
  return data.slice().sort((a, b) => {
    if (a.edgeDeviceId.toLowerCase() > b.edgeDeviceId.toLowerCase())
      {return 1;}
    if (a.edgeDeviceId.toLowerCase() < b.edgeDeviceId.toLowerCase())
      {return -1;}
    return 0;
  });
}
