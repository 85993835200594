<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>
        <h1 mat-dialog-title>
            User Management & Permissions
        </h1>
        <mat-dialog-content class="user-management-container">
            <app-user-management isGearIconClicked=true></app-user-management>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex justify-end content-end items-end w-full flex-row">
        <button mat-button class="btn-primary btn-done" (click)="dialogRef.close()">Done</button>
    </mat-dialog-actions>
</div>