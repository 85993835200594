import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PharmacyScriptingComponent } from './components/pharmacy-scripting/pharmacy-scripting.component';
import { MaxPriorityDialogComponent } from './components/vial/max-priority-dialog/max-priority-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { BatchManagerRoutingModule } from './batch-manager-routing.module';
import { BatchListComponent } from './components/vial/batch-list/batch-list.component';
import { ScriptManagerTableRptComponent } from './components/vial/script-manager-table-rpt/script-manager-table-rpt.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ActionMenuComponent } from './components/vial/script-manager-table-rpt/Components/action-menu-component/action-menu.component';
import { IncompleteTableComponent } from './components/vial/script-manager-table-rpt/Components/incomplete-status-table-component/incomplete-status-table.component';
import { PriorityTableComponent } from './components/vial/script-manager-table-rpt/Components/priority-table-component/priority-table.component';
import { DispensingScriptDetailComponent } from './components/vial/dispensing-script-detail-dialog/dispensing-script-detail-dialog.component';
import { PouchScriptManagerTableComponent } from './components/pouch/pouch-script-manager-table/pouch-script-manager-table.component';
import { PouchScriptingComponent } from './components/pouch/pouch-scripting/pouch-scripting.component';
import { VialScriptingComponent } from './components/vial/vial-scripting/vial-scripting.component';
import { MovePouchDialogComponent } from './components/pouch/pouch-script-manager-table/Components/move-to/move-pouch-dialog.component';
import { PouchScriptCompletedComponent } from './components/pouch/pouch-script-completed/pouch-script-completed.component';

@NgModule({
    declarations: [
        PharmacyScriptingComponent,
        PouchScriptingComponent,
        VialScriptingComponent,
        MaxPriorityDialogComponent,
        BatchListComponent,
        ScriptManagerTableRptComponent,
        PriorityTableComponent,
        IncompleteTableComponent,
        ActionMenuComponent,
        DispensingScriptDetailComponent,
        PouchScriptManagerTableComponent,
        MovePouchDialogComponent,
        PouchScriptCompletedComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BatchManagerRoutingModule,
        MatDialogModule
    ]
})

export class BatchManagerModule { }
