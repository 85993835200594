import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { navItems } from 'src/app/config/nav';

@Injectable()
export class SharedService {

  private message = new BehaviorSubject('');
  sharedMessage = this.message.asObservable();
  menuPermissions = [];
  navItems = [];
  constructor() { }

  nextMessage(message: string) {
    this.menuPermissions[0] = '/' + message;
    this.navItems = navItems.filter(x => this.menuPermissions.includes(x.link));
    this.message.next(this.navItems[0]?.label);
  }

}
