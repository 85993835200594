<h1 mat-dialog-title>
  PARATA Legal agreement
  <button class="close" (click)="dialogRef.close(false)"></button>
</h1>
<mat-dialog-content>
  <div class="xs:flex xs:flex-col flex flex-row">
    <div class="flex flex-grow flex-shrink basis-auto justify-start content-center items-center">
      <h6 class="m-t-10 m-b-0">You must agree to the legal agreement statement below before you can continue.</h6>
    </div>
    <div class="flex justify-end content-center items-center basis-[35%]">
      <a class="btn-link" style="font-size: inherit" (click)="downloadPDF()">Download PDF ></a>
    </div>
  </div>
  <div>
    <div class="text-container">
     <ol>
    <li>
        <h6>PARATA SOFTWARE SERVICES</h6>
        <p>
          These Terms and Conditions  (these <b>“Terms”</b>) provide Customer access and use of the web-based subscription services of Parata Systems, LLC, a Delaware limited liability company, with its principal place of business at 106 Roche Drive, Durham, NC 27703 (<b>“Parata”</b>), as specified on the applicable electronic or written order or subscription agreement between the parties (<b>“Customer Agreement”</b>). In addition to the Terms and Customer Agreement, Customer’s purchase of Parata related products and related services (collectively, the <b>“Service”</b>) is subject to Parata’s Privacy Policy.
        </p>
    </li>
    <li>
        <h6>USE OF SERVICES</h6>
        <ul>
            <li>
            <p>
              <b>Parata Responsibilities. Support.</b> Parata shall use commercially reasonably efforts to provide customer support for the Service as further detailed in the Customer Agreement.
            </p>
            </li>
            <li>
              <p>
              <b>Customer Responsibilities</b>
            </p>
                <ul>
                    <li>
                      <p>
                      <b>Access by Employees and Contractors.</b> Customer will not make the Service available to anyone other than its employees and contractors solely to access the Service for the benefit of Customer in compliance with these Terms and the Customer Agreement. Customer is responsible for the compliance with these Terms and the Customer Agreement by its employees and contractors.
                    </p>
                    </li>
                    <li>
                      <p>
                      You will be given the ability to create or request creation of user accounts, which may be required to access the Service (an <b>“Account”</b>). In doing so, you will need to provide certain registration details or other information. It is a condition of your use of the Service that all the information you provide on the Service is correct, current and complete. You agree that all information you provide to create an Account or otherwise is governed by these Terms and the Customer Agreement, and you consent to all actions we take with respect to your information consistent with these Terms and the Customer Agreement. Customer is responsible for: (i) making all arrangements necessary for its employees and contractors to have access to the Service and (ii) ensuring that all persons who access the Service through an Account are aware of these Terms and comply with them.
                    </p>
                    </li>
                    <li>
                    <p>
                      Customer is responsible for ensuring that Accounts are kept protected, and any associated usernames and passwords are kept confidential, and Customer acknowledges and agrees that it remains responsible for all actions taken on or through the Accounts. Parata has the right to disable any Account, user name, password or other identifier, if, in its opinion, Customer or any employee or contractor has violated any provision of these Terms or the Customer Agreement. Customer agrees to use reasonable efforts to prevent any unauthorized use of the Service and to immediately notify Parata in writing of any unauthorized use that comes to Customer’s attention. If there is unauthorized use by anyone who obtained access to the Service directly or indirectly through Customer, Customer will take all steps reasonably necessary to terminate the unauthorized use. Customer agrees to notify us immediately of any unauthorized access to or use of any Accounts or any other breach of security, and to cooperate and assist with any actions taken by Parata to prevent or terminate such unauthorized use or breach or security.
                    </p>
                    </li>
                    <li>
                    <p>
                      <b>Restrictions and Responsibilities.</b> Customer may not (i) sell, resell, rent or lease the Service, use the Service beyond its internal operations or reverse engineer the Service; (ii) use the Service to store or transmit infringing, unsolicited marketing emails, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party rights (including without limitation any privacy rights); (iii) interfere with or disrupt the integrity or performance of the Service; (iv) attempt to gain unauthorized access to the Service or its related systems or networks; (v) modify, copy the Service, or create derivative works based on the Service or any part, feature, function or user interface thereof; (vi) except to the extent permitted by applicable law, disassemble, reverse engineer, or decompile the Service or remove or modify any proprietary marking or restrictive legends in the Service; (vii) use the Service in violation of any law, including without limitation, HIPAA, Telephone Consumer Protection Act and any spam laws (for example, CAN SPAM); or (vii) access the Service to build a competitive product or service. Customer is solely responsible for Customer Information (defined below), must use commercially reasonable efforts to prevent unauthorized access to the Service, must notify Parata promptly of any such unauthorized access, and may use the Service only in accordance with its user guide and applicable law.
                    </p>
                    </li>
                    <li>
                    <p>
                      <b>Customer Information.</b> All data, information, images, documentation, and files entered or uploaded by Customer to the Service remains the sole property of Customer, as between Parata and Customer (<b>“Customer Information”</b>), subject to these Terms and the Customer Agreement. Customer grants Parata a non-exclusive, royalty-free license to modify, store, transmit, and otherwise use the Customer Information for purposes of Parata performing pursuant to these Terms and the Customer Agreement. Notwithstanding the foregoing, if Customer’s access to the Services is suspended for non-payment of fees in accordance with Section 3(c), Parata will have no obligation to provide access to Customer Information to Customer via the Service until Customer remedies such non-payment as provided in this agreement.
                    <p>
                    </li>
                    <li>
                    <p>
                      <b>Accuracy of Information Provided by Customer</b> Customer represents and warrants to Parata that all Customer Information and Content (as defined below) and other material provided under Customer’s account, by Customer or on its behalf, is true, correct and accurate. If Customer learns that any Customer Information or Content provided to Parata as part of the Service is not true, correct or accurate, Customer must immediately notify Parata by phone and in writing of this fact, and provide the true, correct and accurate information to Parata. Parata relies on Customer representations regarding the truth, accuracy and compliance with laws of Customer Information and Content. 
                      <b>PARATA IS NOT LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY CUSTOMER’S FAILURE TO COMPLY WITH THIS PARAGRAPH, IRRESPECTIVE OF ANY ACT OR OMISSION ON THE PART OF PARATA.</b>
                    </p>
                    </li>
                </ul>
            </li>
            <li>
              <b>Additional Terms</b>

                <ul>
                    <li>
                      <p>
                      <b>Content.</b> Customers may upload or submit content, files and information to the Service (“Content”). As between Parata and Customer, all Content belongs to Customer, and Customer hereby grants Parata a non-exclusive irrevocable, perpetual, royalty-free license to display, store, distribute, share, modify, creative derivative works of and otherwise use such Content for purposes providing the Service under these Terms and the Customer Agreement).
                    </p>
                    </li>
                    <li>
                      <p>
                      <b>Warranties.</b>	 Customer represents and warrants to Parata that:
                    </p>
                      <ul>
                        <li>
                        <p>
                          Any Content submitted to the Service does not violate any copyright, trade secret, privacy or other third party right;
                        </p>
                        </li>
                        <li>
                        <p>
                          It will not submit any Content that is untrue, defamatory, harmful to any person, or violates HIPAA Privacy Rules, state or federal laws on patient privacy; and
                        </p>
                        </li>
                        <li>
                        <p>
                          All patient information submitted by Customer are accurate, have the patient's consent, and comply with ethical guidelines of professional medical associations as well as state and local medical and pharmacy or private practice boards and governing bodies.
                        </p>
                        </li>
                      </ul>  
                    </li>
                    <li>
                    <p>
                      <b>Reminders and SMS Messages.</b> Customer agrees that by registering for the Service, including any request forms or use of communications features, constitutes a request for Parata to send email, fax, phone call, or SMS reminders that are consistent with its provision of the Service, but may also extend to special oﬀers and upcoming events (e.g. training). Parata is not responsible for any text messaging or data transmission fees. If an employee or contractor of Customer provides a cellular phone number and agrees to receive communications from Parata, then such party specifically authorizes Parata to send text messages or calls to such number. Customer represents and warrants it has the authority to grant such authorization. Customer is not required to consent to receive text messages or calls as a condition of using the Service and any employee or contractor may opt out of such messages in any manner detailed by Parata.
                     </p>

                    </li>
                  </ul>
                </li>
        </ul>
    </li>
    <li>
      <h6>Payment Terms</h6>
      <ul>
          <li>
          <b>Payment</b> 
          <p>Customer must pay all fees on terms and as otherwise specified in the Customer Agreement . Unless otherwise stated in the Customer Agreement, invoiced charges are due upon receipt. Customer is responsible for providing complete and accurate billing and contact information to Parata and notifying Parata of any changes to such information.
          </p>
          </li>
          <li>
            <b>Taxes</b>
            <p>
Parata’s fees do not include any taxes, levies, or other similar governmental assessments (<b>“Taxes”</b>). Customer is responsible for the payment of all Taxes associated with its purchases under this agreement. Parata is solely responsible for taxes assessable against Parata based on its income, property, and employees.
</p>
          </li>
          <li>
            <b>Suspension of Service for Non Payment</b>
            <p>
Parata may suspend or terminate the Service, or both, if Customer has not paid amounts owed to Parata when due. In advance of any suspension or termination, Parata will make commercially reasonable efforts to send a minimum five-day (5-day) notice of payment default to Customer prior to suspension or termination (Customer is responsible updating its contact information with Parata and notifying Parata of any changes to such information).
</p>
          </li>
          <li>
            <b>Fee Changes</b>
            <p>
All fees may be changed with thirty (30) days advance email notice to Customer. Customer is responsible for keeping its updated email address on file with Parata.
</p>
          </li>
      </ul>
    </li>
    <li>
      <h6>WARRANTY/SERVICE LEVEL AGREEMENT/DISCLAIMERS</h6>
      <ul>
          <li>
          <b>Availability</b> 
          <p>Parata will make commercially reasonable efforts to maintain uptime of ninety-five percent (95%) excluding any scheduled downtime.
          </p>
          </li>
          <li>
            <b>Mutual Compliance with Laws</b>
            <p>
Each party represents and warrants to the other party that it will comply with all applicable laws regarding its performance under this agreement.
</p>
          </li>
          <li>
            <b>NO MEDICAL ADVICE PROVIDED BY PARATA</b>
            <p>
The Service does not provide medical advice, provide medical or diagnostic services, or prescribe or otherwise recommend medication. Use of the Service is not a substitute for the professional judgment of health care providers including pharmacists in diagnosing and treating patients. Customer agrees that it is solely responsible for verifying the accuracy of patient information (including, without limitation, obtaining all applicable patients' medical and medication history), obtaining any required patient’s consent to use the Service, and for all of its decisions or actions with respect to the medical care, treatment, and well-being of its patients, including without limitation, all of Customer’s acts or omissions. Any use or reliance by Customer upon the Service will not diminish that responsibility. Customer assumes all risks associated with Customer’s clinical use of the Service for the treatment of patients. Neither Parata nor its licensors assume any liability or responsibility for damage or injury (including death) to Customer, a patient, other persons, or tangible property arising from any use of the Service.
</p>
          </li>
          <li>
            <b>CUSTOMER’S COMPLIANCE WITH MEDICAL RETENTION LAWS AND PATIENT RECORDS ACCESS</b>
            <p>
Customer is responsible for understanding and complying with all state and federal laws related to retention of medical records, patient access to information, and patient authorization to release data. Customer agrees that it will obtain any necessary patient consent prior to using the Service or any other product, application or service provided by Parataas necessary to comply with state or federal law.
</p>
          </li>
          <li>
            <b>DISCLAIMERS</b>
            <p>
PARATA DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, OR WITHOUT DELAY, AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WHILE PARATA TAKES REASONABLE PHYSICAL, TECHNICAL, AND ADMINISTRATIVE MEASURES TO SECURE THE SERVICE, PARATA DOES NOT GUARANTEE THAT THE SERVICE CANNOT BE COMPROMISED. PARATA DISCLAIMS ANY WARRANTY REGARDING ANY PERCENTAGE OF COLLECTION OF CLAIMS FOR CUSTOMER.
</p>
          </li>
          <h6>Mutual Confidentiality</h6>
          <li>
            <b>Definition of Confidential Information</b>
            <p>
Confidential Information means all non-public information disclosed by a party (<b>“Discloser”</b>) to the other party (<b>“Recipient”</b>), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure (<b>“Confidential Information”</b>). Parata's Confidential Information includes, without limitation, the non-public portions of the Service.
</p>
          </li>
          <li>
            <b>Protection of Confidential Information</b>
            <p>
The Recipient must use the same degree of care that it uses to protect the confidentiality of its own confidential information (but in no event less than reasonable care) not to disclose or use any Confidential Information of the Discloser for any purpose outside the scope of this agreement. The Recipient must make commercially reasonable efforts to limit access to Confidential Information of Discloser to those of its employees, contractors, and clients (as the case may be) who need such access for purposes consistent with this agreement and who have signed confidentiality agreements with Recipient no less restrictive than the confidentiality terms of this agreement. The Recipient may disclose Confidential Information (i) to the extent required by law or legal process; (ii) to its legal or financial advisors, provided that such advisors are bound by a duty of confidentiality that includes use and disclosure restrictions; and (iii) as required under applicable securities regulations. In addition, each Party may disclose the terms and conditions of this agreement on a confidential basis to current and prospective investors, acquirers, lenders, and their respective legal and financial advisors in connection with due diligence activities.
</p>
          </li>
          <li>
            <b>Exclusions</b>
            <p>
Confidential Information excludes information that: is or becomes generally known to the public without breach of any obligation owed to Discloser; was known to the Recipient prior to its disclosure by the Discloser without breach of any obligation owed to the Discloser; is received from a third party without breach of any obligation owed to Discloser; or was independently developed by the Recipient without use or access to the Confidential Information.
</p>
          </li>
      </ul>
    </li>
    <li>
      <h6>Ownership and Proprietary Rights</h6>
      <ul>
          <li>
            <p>
            <b>Ownership and Reservation of Rights by Parata.</b> All right, title, and interest in and to the Services and Documentation, including all intellectual property rights therein, are and will remain with Parata or, with respect to any third-party materials, its licensors.  Customer has no right, license, or authorization with respect to the Service except as expressly set forth in these Terms or in the Customer Agreement.
          </p>
          </li>
          <li>
            <p>
            <b>Customer Data.</b> Customer Data means any data that Customer including, for avoidance of doubt, any employee or contractor, uploads to the Service under an Account or that Customer otherwise transfers, processes, uses, or stores in connection with an Account. Parata acknowledges that, as between Parata and Customer, Customer own all right, title, and interest, including all intellectual property rights, in and to the Customer Data. Customer hereby grants to Parata a non-exclusive, royalty-free, worldwide license to reproduce, distribute, and otherwise use and display the Customer Data and perform all acts with respect to the Customer Data as may be necessary for Parata to provide the Services.
          </p>
          </li>
          <li>
          <p>
            <b>Aggregation Services and De-identified Data.</b> Parata may use protected health information to provide you with data aggregation services (as that term is defined by HIPAA) and to create de-identified data in accordance with 45 CFR 164.514(a)-(c). Parata shall solely own all right, title and interest, in any de-identified data it creates from protected health information. Parata and its affiliates may use and disclose, during and after expiration or termination these Terms or the Customer Agreement, all aggregate, anonymized information and de-identified data for purposes of enhancing the Service, technical support and other business purposes as Parata determines in its sole discretion, all in compliance with the HIPAA Privacy Standards, including without limitation the limited data set and de-identification of information regulations.
          </p>
          </li>
          <li>
          <p>
            Resultant Data. Resultant Data means data and information related to Customer’s use of the Service that Parata uses in an aggregate and anonymized manner, including to compile statistical and performance information related to the provision and operation of the Service. Parata uses this data, without limitation, to improve the performance of the Service or develop important updates. Parata never uses it to re-identify information required by the Terms herein. In furtherance of the foregoing, Customer hereby unconditionally and irrevocably grant Parata an assignment of all right, title, and interest in and to the Resultant Data, including all intellectual property rights relating thereto.
          </p>
          </li>
      </ul>
    </li>
    <li>
      <ul>
      <p>
            DATA PROTECTION. In the event Customer provide to Parata Personal Data as defined by Regulation (EU) 2016/679 (General Data Protection Regulation), Parata’s Data Processing Agreement (the <b>“DPA”</b>) and incorporated herein by reference, will apply, and describes the parties’ respective roles for the processing and control of Personal Data. When providing the Services to Customer, Parata will act as a data processor and will act on Customer’s instruction concerning the treatment of such Personal Data, as specified in these Terms and in the Customer Agreement and the DPA. The DPA defines the administrative, physical, technical and other safeguards applied to Personal Data residing in the Services environment and describe other aspects of system management applicable to the Services.
          </p>
      </ul>
    </li>
    <li>
      <h6>Limits on Liability.</h6>
      <ul>
          <li>
          <p>
            <b>Limitation on Damages.</b> Parata is not and shall not be liable for any indirect, special, or consequential damages (including, without limitation, costs of delay, loss of data or information, lost profits or revenues or loss of anticipated cost savings) arising under or related to these Terms, provision of the Service, or the Customer Agreement, even if advised of the possibility of such loss or damage.
          </p>
          </li>
          <li>
          <p>
            <b>Limit.</b> Parata’s total liability for all damages arising under or related to this agreement (in contract, tort, or otherwise) does not exceed the actual amount paid by Customer within the six-month (6-month) period preceding the event which gave rise to the claim. Any claim by Customer against Parata must be brought within twelve (12) months of the event which gave rise to the claim, and if it is not brought within such time period then such claim is expressly waived by Customer.
          </p>
          </li>
      </ul>
    </li>
    <li>
      <h6>TERM, TERMINATION, AND RETURN OF DATA</h6>
      <ul>
          <li>
            <b>Term</b>
            <p>
            The applicable Services will continue for the duration specified in the Customer Agreement (including any extension). Use of the Service as provided for in these Terms and in the Customer Agreement continues until the Customer Agreement and all Services expire or are otherwise terminated. 
          </p>           
          </li>
          <li>
            <b>Termination for Material Breach</b>
            <p>
Either party may terminate this agreement and the Customer Agreement if the other party material breaches any provision in these Terms or the Customer Agreement and does not cure the breach as specified in the Customer Agreement or, if silent, within thirty (30) days of written receipt of notice of breach. 
</p>
          </li>
          <li>
            <b>Return of Data</b>
            <p>
Parata will have no obligation to provide Customer Information to Customer upon termination of the relationship. Notwithstanding the foregoing, Parata will retain Customer Information for sixty (60) days from such termination and Parata may provide Customer access to such information upon Customer’s request. 
</p>
          </li>
          <li>
            <b>Customer Actions upon Termination</b>
            <p>
Upon termination, Customer must pay any unpaid fees and destroy all Parata property in Customer’s possession. Customer, upon Parata’s request, will confirm in writing that it has complied with this requirement.
</p>
          </li>
          <li>
            <b>Suspension or Termination of Service for Violation of Law or the Agreement</b>
            <p>
Parata may immediately suspend or terminate the Service and remove applicable Customer Information or Content if it in good faith believes that, as part of using the Service, Customer may have violated any applicable law or any term of this agreement. Parata may try to contact Customer in advance, but it is not required to do so.
</p>
          </li>
        </ul>
      </li>
      <li>
        <h6>INDEMNITY</h6>
        <ul>
            <li>
         
            <b>General Indemnity</b>
        <p>
            To the extent allowed by applicable law, Customer must indemnify, defend (at Parata’s option), and hold harmless Parata against all third-party claims (including, without limitation, by governmental agencies), demands, damages, costs, penalties, fines, and expenses (including reasonable attorneys’ fees and costs) arising out of or related to:
          </p>
            <ul style="margin-left: 15%;">
              <li>
              <p>
                the use of the Service by Customer including, for additional clarity, any of its employees, contractors or affiliates (and their employees and contractors) who have access to the Service;
              </p>
              </li>
              <li>
              <p>
                Customer’s breach of any provision of these Terms or the Customer Agreement;
              </p>
              </li>
              <li>
              <p>
                any unauthorized use, access, or distribution of the Service by Customer; or
              </p>
              </li>
              <li>
              <p>
                violation of any individual’s privacy rights related to information submitted under Customer’s account, or fraudulent, invalid, duplicate, incomplete, unauthorized, or misleading information submitted under Customer’s account or by Customer. 
              </p> 
              
              </li>
            </ul>
          </li>
      </ul>
    </li>
    <li>
      <h6>GOVERNING LAW AND ARBITRATION</h6>
      <ul>
          <li>
            <b>Governing Law</b>
            <p>
These Terms and any action related thereto is governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of North Carolina (without regard to conflicts of law principles).  
</p>          
          </li>
          <li>
            <b>General Arbitration Process</b>
            <p>
Any dispute or claim that may arise between the parties relating in any way to or arising out of this agreement, Customer’s use of or access to the Services (<b>“Claim”</b>), must be resolved exclusively through final and binding arbitration (rather than in court) under the then current commercial rules of the American Arbitration Association. Any judgment on the award rendered by the arbitrator is final and may be entered in any court of competent jurisdiction. Nothing in this agreement prevents either party from seeking injunctive or equitable relief in any court of competent jurisdiction. The prevailing party in any arbitration or litigation is entitled to recover its attorneys’ fees and costs from the other party. The arbitration will be held in Wake or Durham County, North Carolina.
</p>
          </li>
          <li>
            <b>Optional Arbitration for Claims Less than $10,000</b>
            <p>
Notwithstanding the foregoing, for any Claim (excluding claims for injunctive or other equitable relief) where the total amount of the award sought is less than $10,000, the party requesting relief may choose to resolve the dispute in a more cost-effective manner through binding non-appearance-based arbitration. If a party elects arbitration, they must initiate such arbitration through an established alternative dispute resolution (<b>“ADR”</b>) provider mutually agreed upon by the parties. The ADR provider and the parties must comply with the following rules: (i) the arbitration will be conducted by telephone or online and be solely based on written submissions, the specific manner of which to be chosen by the party initiating the arbitration; (ii) the arbitration will not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and (iii) any judgment on the award rendered by the arbitrator is final and may be entered in any court of competent jurisdiction.
</p>
          </li>
          <li>
            <b>Enforcement</b>
            <p>
Notwithstanding subsections (b) and (c), Parata may bring an action to enforce its intellectual property or other proprietary rights in any court of competent jurisdiction.
</p>
          </li>
          <li>
            <b>Equitable Relief</b>
            <p>
Notwithstanding anything above, Parata may seek and obtain injunctive and equitable relief in any court of competent jurisdiction without restriction or required process in or otherwise related to these Terms or the Customer Agreement.
</p>
          </li>
          <li>
            <b>PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS</b>
            <p>
EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL PARTY BASIS, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, CLASS OR REPRESENTATIVE PROCEEDING. IF THIS CLASS ACTION AND REPRESENTATIVE ACTION WAIVER PROVISION IS HELD TO BE UNENFORCEABLE, THEN SECTIONS 11.b AND 11.c SHALL ALSO BE UNENFORCEABLE.
</p>
          </li>
      </ul>
    </li>
    <li>
      <h6>OTHER TERMS</h6>
      <ul>
          <li>
            <b>No Solicit or Hire Clause</b>
            <p>
Customer acknowledges that Parata invests considerable time and expense in the training of its employees and independent subcontractors in the services to be provided under this agreement. Customer agrees that for the full term of this agreement, and for one (1) year after its termination Customer will not solicit or employ in any capacity, whether as a direct employee, independent contractor, or representative of another company providing similar services to Customer as Parata, any person employed by Parata at any time during the term of this agreement whose duties involve providing the Services, whether for Customer or other Parata customers.
</p>          
          </li>
          <li>
            <b>Consent to Electronic Notice, Communications and Transactions</b>
            <p>
For purposes of messages and notices about the Service (including, without limitation, collections and payments issues), Parata may send email notices to the email address associated with Customer's account or provide in service notifications. For certain notices (e.g., notices regarding termination or material breaches), Parata may send notices to the postal address provided by Customer. <b>Parata has no liability associated with Customer's failure to maintain accurate contact information within the Service or its failure to review any emails or in-service notices.</b> Customer will have the ability to enter into agreements, authorizations, consents, and applications; make referrals; order lab tests; prescribe medications; or engage in other transactions electronically. CUSTOMER AGREES THAT ITS ELECTRONIC SUBMISSIONS VIA THE SERVICES IN CONNECTION WITH SUCH ACTIVITIES CONSTITUTE ITS AGREEMENT TO BE BOUND BY SUCH AGREEMENTS AND TRANSACTIONS AND APPLIES TO ALL RECORDS RELATING TO SUCH TRANSACTIONS. Customer represents and warrants that it has the authority to take such actions.
</p>  
          </li>
          <li>
            <b>Entire Agreement and Changes</b>
            <p>
These Terms and the Customer Agreement constitute the entire agreement between the parties and supersede all prior or contemporaneous negotiations or agreements, whether oral or written, related to this subject matter. Customer is not relying on any representation concerning this subject matter, oral or written, not included in this agreement. No representation, promise, or inducement not included in this agreement is binding. No modification or waiver of any term of this agreement is effective unless signed by both parties. The Convention on Contracts for the International Sale of Goods does not apply. Notwithstanding the foregoing, Parata may modify this agreement by posting modified Terms of Service on the Parata website. Customer agrees that by continuing to use the Service after posting of the modified Terms of Service, Customer agrees to be bound by the changes. In the event of any conflict between these Terms and the Customer Agreement, then the Terms shall prevail.
</p>
          </li>
          <li>
            <b>Feedback</b>
            <p>
If Customer provides feedback or suggestions about the Service, then Parata (and those it allows to use its technology) may use such information without obligation to Customer.
</p>
          </li>
          <li>
            <b>Beta Features</b>
            <p>
If Customer is invited to access any beta features of the Service or a Customer accesses any beta features of the Service, Customer acknowledges that: (a) such features have not been made commercially available by Parata; (b) such features may not operate properly, be in final form, or be fully functional; (c) such features may contain errors, design flaws, or other problems; (d) it may not be possible to make such features fully functional; (e) use of such features may result in unexpected results, corruption or loss of data, or other unpredictable damage or loss; (f) such features may change and may not become generally available; and (g) Parata is not obligated in any way to continue to provide or maintain such features for any purpose in providing the ongoing Service. These beta features are provided AS IS, with all faults. Customer assumes all risk arising from use of such features, including, without limitation, the risk of damage to Customer’s computer system or the corruption or loss of data.
</p>
          </li>
          <li>
            <b>No Assignment</b>
            <p>
Parata may assign or transfer this agreement or the Customer Agreement (or its rights and/or obligations thereunder) to any third party without consent. Customer may not assign or transfer this agreement or the Customer Agreement to a third party without the prior written consent of Parata, except that this agreement and the Customer Agreement may be assigned (without Parata’s consent) as part of a merger, or sale of all or substantially all of the business or assets, of Customer.
</p>
          </li>
          <li>
            <b>Electronic Notice</b>
            <p>
For purposes of messages and notices about the Service (including without limitation, collections and payments issues), Parata may send email notices to the email addresses associated with Customer's account or provide in service notifications. For certain notices (e.g., notices regarding termination or material breaches), Parata may send notices to the postal or email address provided by Customer. Parata has no liability associated with Customer's failure to maintain accurate contact information within the Service or its failure to review any emails or in-service notices.
</p>
          </li>
          <li>
            <b>Independent Contractors and Enforceability</b>
            <p>
The parties are independent contractors with respect to each other. If any term of this agreement is invalid or unenforceable, the other terms remain in effect.
</p>
          </li>
          <li>
            <p>
            <b>No Additional Terms</b>
          
Parata rejects additional or conflicting terms of a form-purchasing document. If there is an inconsistency between this agreement and the Customer Agreement, the Customer Agreement prevails.
</p>
          </li>
          <li>
            <b>Survival of Terms</b>
            <p>
All terms survive termination of these Terms and the Customer Agreement that by their nature survive for a party to assert its rights and receive the protections of this agreement.
</p>
          </li>
          <li>
            <b>Customer Name</b>
            <p>
Parata may use Customer's name and logo in customer lists and related promotional materials describing Customer as a customer of Parata, which use must be in accordance with Customer’s trademark guidelines and policies, if any, provided to Parata.
</p>
          </li>
      </ul>
    </li>
</ol>
    </div>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="acceptAgreement">
      <app-icon icon="asterisk" size="10"></app-icon>
      By clicking on "Next Step" or “I Agree”, you agree to Parata’s Terms and Conditions, Website Terms Of Use, and our Privacy Policy
    </mat-checkbox>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-end content-stretch items-stretch space-x-[1%]">
  <button mat-button class="btn-default btn-lg" 
    (click)="dialogRef.close(false)"
  >Cancel</button>

  <button mat-button class="btn-primary btn-lg" 
    [disabled]="!acceptAgreement" [class.btn-disabled]="!acceptAgreement" 
    (click)="dialogRef.close(true)" cdkFocusInitial
  >I Agree</button>
</mat-dialog-actions>
