import { KeyValue } from '@angular/common';
import { Component, Inject, Pipe, PipeTransform } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Asset } from '../../../models/batch-models';
import { QueuePriorityEnum } from '../../../models/pending-script-priority-enum';
import { IEnumBaseDictionary } from '../../../models/script-manager-data';


export interface DialogData {
  priority: number;
}

@Component({
  selector: 'app-move-batch-dialog',
  templateUrl: './max-priority-dialog.component.html',
  styleUrls: ['./max-priority-dialog.component.scss']
})
export class MaxPriorityDialogComponent {

  priorityEnum= QueuePriorityEnum;
  priorities= [...Object.values(QueuePriorityEnum)].filter(value => typeof value === 'number').map(value => value as number);
  prioritySelected: number ;

  constructor(
    public dialogRef: MatDialogRef<MaxPriorityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.prioritySelected = data.priority;
    }
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterViewInit(){

      this.prioritySelected = this.data.priority;
    }
    setPriority(param: string){

      const priorityValue =  {key: param, value: this.priorityEnum[param] as number};
      this.data.priority = priorityValue.value ;
    }

    ConfirmChange(){

    this.dialogRef.close({data: this.data});
    }


}
