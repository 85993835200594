import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-user-management-dialog',
  templateUrl: './user-management-dialog.component.html',
  styleUrls: ['./user-management-dialog.component.scss']
})
export class UserManagementDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserManagementDialogComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

}
