import { AbstractControl, ValidatorFn } from "@angular/forms";
import { AutoCompleteField } from "../models/auto-complete-field";

export class AutoCompleteDropDownValidators {
    // The validator is written specifically to validate the autocomplete drop down control
    // The auto complete drop down provides an ability to enter the text manually or select from the drop down as well
    // To honour that feature, we validate if the entered text and the selected value is part of the collection that is binded to the drop down
    // The parameter _collection is the list of data that is binded to the drop down
    // The parameter _field is the kind or category of data that is binded to the drop down
    static SelectedValueValidator(_collection: any[], _field: AutoCompleteField = AutoCompleteField.name): ValidatorFn {
        return (_control: AbstractControl): { [key: string]: boolean } | null => {    
            let matchedElements;    
            let searchValue = this.getSearchValue(_control, _field);

            if (searchValue != null && searchValue.trim().length != 0) {
                if (_collection == null) {
                    return { ShowValidatonError: true };
                } else {
                    matchedElements = this.matchElements(_collection, _field, searchValue);
                }
            }       
        
            if (matchedElements?.length > 0) {
                // The search value is part of the array. Do not show any validation error. Return null.
                return null;
            } else {
                    // The search value does not match the elements in the array. Show validation error. Return true.
                return { ShowValidatonError: true };
            }
        };
    }

    static getSearchValue (_control: AbstractControl, _field: AutoCompleteField): any | null {        
        if(typeof _control.value === 'string') {
            return this.getStringValue(_control.value);  
        } else {
            return this.getObjectValue(_control.value, _field); 
        }
    }

    static getStringValue(value): any {
        return value === null || value.length === 0 ? '' : value?.toLowerCase();  
    }    

    static getObjectValue(value: any, _field: AutoCompleteField): any {
        switch(_field) {
            case AutoCompleteField.customerNo: {
                return value ? `${value?.name} (${value?.customerNo})`?.toLowerCase() : '';                 
            }
            case AutoCompleteField.machineNo: {
                return value ? `${value?.name} (${value?.machineNo})`?.toLowerCase() : ''; 
            }
            case AutoCompleteField.salesforce: {
                return value ? value?.id : '';                 
            }
            default: {
                return value ? value?.name : '';    
            }
        }
    }

    static matchElements(_collection: any[], _field: AutoCompleteField, _searchValue: string) {
        switch(_field) {
            case AutoCompleteField.customerNo: {
                return _collection.filter((alias) => `${alias?.name?.toLowerCase()} (${alias?.customerNo?.toLowerCase()})` === _searchValue);
            }
            case AutoCompleteField.machineNo: {
                return _collection.filter((alias) => `${alias?.name?.toLowerCase()} (${alias?.machineNo?.toLowerCase()})` === _searchValue && alias?.isProvisioned === false);
            }
            case AutoCompleteField.salesforce: {
                return _collection.filter((alias) => alias?.id.toLowerCase() === _searchValue && alias?.isOnboarded === false);              
            }
            default: {
                return _collection.filter((alias) => alias?.name?.toLowerCase() === _searchValue);  
            }
        }
    }
}