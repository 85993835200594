import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component,  EventEmitter,  Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ColumnName } from '../../models/column-name';
import {  CustomerAndFileInfo } from '../../models/customer-drug-info';
import { CustomerInfo } from '../../models/customer-info';
import { DrugFileUpload } from '../../models/drug-file-upload';
import { IMToolService } from '../../services/im-tool.service';
import * as constants from 'src/app/config/app-constants';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ImToolData, ImToolFirstScreenData } from '../../services/im-tool-data.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-import-wizard-first',
  templateUrl: './import-wizard-first.component.html',
  styleUrls: ['./import-wizard-first.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ImportWizardFirstComponent implements OnInit {
 
  @Output() hideSide = new EventEmitter<number>();
  @Output() closeWizard = new EventEmitter<boolean>();
  @Output() parentFileName = new EventEmitter<string>();
  @Output() noUsageChange = new EventEmitter<boolean>();
  devices = ImToolData.devices;
  ndcLabel = ImToolFirstScreenData.ndcLabels;
  fileuploadObject: DrugFileUpload;
  customerId: string;
  
  columnNameList: ColumnName[] = [];
  @Output() deviceIdChange = new EventEmitter<number>()
  @Input() customerData: CustomerInfo[] = [];
  errorMessages:[] =[];
  private destroy$ = new Subject<void>();


  customerAndFileInfoModel: CustomerAndFileInfo;

  // File Upload Information form
  drugFileInfoForm: FormGroup;
  mappedDrugName: FormControl;
  mappedNDC: FormControl;
  mappedMnemonic: FormControl;
  mappedUsage: FormControl;
  mappedNoOfOrders: FormControl;
  primaryDrugName: FormControl;
  secondaryDrugName: FormControl;
  mappedSuperCellSelect: FormControl;
  mappedAtpLot: FormControl;
  mappedRanking:FormControl;
  mappedAtpDrugExpirationDate: FormControl;
  mappedCustomerQuantity: FormControl;
  mappedAtpDeviceLocation: FormControl;
  mappedAtpPillDescription: FormControl;
  mappedAtpPillColor: FormControl;
  mappedAtpPillShape: FormControl;
  mappedAtpImp1: FormControl;
  mappedAtpImp2: FormControl;
  mappedExternalDrugFlag: FormControl;
  mappedCustomerBarcode:FormControl;
  isCustomerMnemonic:FormControl;
  mappedLockingCell:FormControl;
  mappedCustomerInventoryNumber:FormControl;
  mappedCustomerComment:FormControl;
  mappedCustomerOtc:FormControl;
  mappedNdcLabel:FormControl;
  // File operation related variables
  fileContent: string;
  percentage: number = 0;
  fielName: string = "";
  fieleSelect: boolean = false;
  fileUploadButtonShow: boolean = true;
  backgroundcolor: string = "green";

//#endregion

 

noUsage: FormControl;
orderOrUsage :  FormControl;
usageValue : FormControl;
deviceId:FormControl;
  constructor(
    private imtoolService: IMToolService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.customerData.push(data.customer);
    this.noUsage = new FormControl(false);
    this.orderOrUsage = new FormControl(1);
    this.mappedNdcLabel = new FormControl(1);
    this.usageValue = new FormControl(2)
    this.mappedDrugName = new FormControl("", Validators.required);
    this.mappedNDC = new FormControl("", Validators.required);
    this.mappedMnemonic = new FormControl("");
    this.mappedUsage = new FormControl("", Validators.required);
    this.mappedSuperCellSelect = new FormControl("", Validators.required)
    
    this.mappedNoOfOrders = new FormControl("");
    this.mappedAtpLot = new FormControl();
    this.mappedRanking = new FormControl();
    this.mappedAtpDrugExpirationDate = new FormControl();
    this.mappedCustomerQuantity = new FormControl();
    this.mappedAtpDeviceLocation = new FormControl();
    this.mappedAtpPillDescription = new FormControl();
    this.mappedAtpPillColor = new FormControl();
    this.mappedAtpPillShape = new FormControl();
    this.mappedAtpImp1 = new FormControl();
    this.mappedAtpImp2 = new FormControl();
    this.mappedCustomerBarcode = new FormControl();
    this.mappedLockingCell= new FormControl();
    this.mappedCustomerInventoryNumber = new  FormControl();
    this.mappedCustomerComment= new  FormControl();
    this.mappedCustomerOtc= new  FormControl();
    this.customerId = this.customerData[0].customerId;
   this.mappedExternalDrugFlag = new FormControl();

  }

  ngOnInit(): void {
   
    this.deviceId = new FormControl(1);
    this.deviceIdChange.emit(1)
    this.drugFileInfoForm = this.fb.group({
      deviceId : this.deviceId,
      mappedExternalDrugFlag:this.mappedExternalDrugFlag,
      mappedNdcLabel: this.mappedNdcLabel,
      mappedDrugName: this.mappedDrugName,
      mappedNDC: this.mappedNDC,
      mappedMnemonic: this.mappedMnemonic,
      mappedSuperCellSelect: this.mappedSuperCellSelect,
      mappedUsage: this.mappedUsage,
      mappedNoOfOrders: this.mappedNoOfOrders,
      mappedAtpLot:this.mappedAtpLot,
      mappedRanking:this.mappedRanking,
      mappedAtpDeviceLocation:this.mappedAtpDeviceLocation,
      mappedAtpDrugExpirationDate:this.mappedAtpDrugExpirationDate,
      mappedCustomerQuantity:this.mappedCustomerQuantity,
      mappedAtpPillDescription:this.mappedAtpPillDescription,
      mappedAtpPillColor:  this.mappedAtpPillColor,
      mappedAtpPillShape: this.mappedAtpPillShape,
      mappedAtpImp1:this.mappedAtpImp1,
      mappedAtpImp2: this.mappedAtpImp2,
      mappedCustomerBarcode:this.mappedCustomerBarcode,

      mappedLockingCell: this.mappedLockingCell,
      mappedCustomerInventoryNumber:this.mappedCustomerInventoryNumber,
      mappedCustomerComment:this.mappedCustomerComment,
      mappedCustomerOtc:this.mappedCustomerOtc,
      orderOrUsage: this.orderOrUsage,
      usageValue: this.usageValue,
      noUsage: this.noUsage,
      isCustomerMnemonic :this.isCustomerMnemonic
    });
    this.formValidataionUpdate();
    this.drugFileInfoForm.get('deviceId').valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe(value => {
      this.updateColumnList()
    });
   this.setUsageValue();

    
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setUsageValue(){
    this.drugFileInfoForm.controls['orderOrUsage'].setValue(1);
    this.drugFileInfoForm.controls['usageValue'].setValue(2);

  }

 updateColumnList(){
  this.columnNameList = this.columnNameList.filter(
    column => !ImToolData.columnList.some(
      item => item.name === column.name
    )
  );
  
  if(this.deviceId.value === 1 || this.deviceId.value === 5){
    ImToolData.columnList.forEach(object => {
      this.columnNameList.push(object)
    })
  }
  if (this.deviceId.value === 3) {
    const firstObject = ImToolData.columnList[0];  // Get the first object
    if (firstObject) {
        this.columnNameList.push(firstObject);  // Push only the first object
    }
}
   
  
 }
 updateTheForm(value:number){
  this.drugFileInfoForm.reset({
    deviceId:value
  })
  this.deviceIdChange.emit(value)
  this.setUsageValue();
  this.drugFileInfoForm.controls['mappedNdcLabel'].setValue(1);
 }
  isEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }

  updateNoUsage(){
    
    if(this.mappedUsage.value === 'No Usage Data Provided' || this.mappedNoOfOrders.value === 'No Usage Data Provided'){
      this.noUsage.setValue(true);
      this.noUsageChange.emit(true)
      this.mappedNoOfOrders.setValue('No Usage Data Provided');
      this.mappedUsage.setValue('No Usage Data Provided');
    }
    else{
      this.noUsage.setValue(false);
      this.noUsageChange.emit(false)
    }
    this.formValidataionUpdate();
  }

  formValidataionUpdate() {
    if (this.noUsage.value == false) {
      if(this.deviceId.value === 1){
      this.drugFileInfoForm.controls["mappedUsage"].setValidators(Validators.required);
      }
      this.drugFileInfoForm.controls["mappedSuperCellSelect"].clearValidators();
      this.drugFileInfoForm.controls["mappedSuperCellSelect"].reset();

    }
    else {
      this.drugFileInfoForm.controls["mappedUsage"].clearValidators();
      this.drugFileInfoForm.controls["mappedUsage"].reset();
      if(this.deviceId.value === 3){
        this.drugFileInfoForm.controls["mappedAtpDeviceLocation"].setValidators(Validators.required);
      }
      else{
        this.drugFileInfoForm.controls["mappedAtpDeviceLocation"].clearValidators();
        this.drugFileInfoForm.controls["mappedAtpDeviceLocation"].reset();
      }
    }
    this.drugFileInfoForm.controls["mappedUsage"].updateValueAndValidity()
    this.drugFileInfoForm.controls["mappedSuperCellSelect"].updateValueAndValidity()
    this.drugFileInfoForm.controls["mappedAtpDeviceLocation"].updateValueAndValidity()
  }

  isUndefined(variable): boolean {
    return typeof variable === 'undefined' || variable === null

  }

  fileUpload() {
    const FILEUPLOAD_KEY = 'Fileuploadkey'
    if (this.fileuploadObject.FileContent.length > 0) {
      this.fileUploadButtonShow = false;
      this.ngxLoader.start(FILEUPLOAD_KEY);
      this.imtoolService.create(this.fileuploadObject).subscribe(
        (result) => {
          this.ngxLoader.stop(FILEUPLOAD_KEY);
          this.progressbarShow(result);
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(FILEUPLOAD_KEY);
          this.toastService.openToast(
            "Failed to upload the file.",
            constants.ToastPanelType.error,
            2
          );
          setTimeout(() => {
            this.percentage = 0;
          }, 2000);
        }
      );
    }
  }


  customerAndFileInfoSave() {
    let formValue = this.drugFileInfoForm.value;
    this.parentFileName.emit(this.fielName)
    this.customerAndFileInfoModel = {
      customerId: this.customerData[0].customerId,
      fileContent: this.fileContent,
      fileName: this.fielName,
      customerName: this.customerData[0].customerName,
      caseNumber: this.customerData[0].caseNumber,
      customerNumber: this.customerData[0].customerName,
      deviceType:
        this.customerData[0].deviceType == null
          ? ""
          : this.customerData[0].deviceType.subDeviceTypeName,
      targetInstallDate: this.customerData[0].targetInstallDate,
      uploadedBy: "",
      statusId: this.customerData[0].status.id,
      mappedDrugName: formValue.mappedDrugName,
      mappedNDC: formValue.mappedNDC,
      mappedMnemonic: formValue.mappedMnemonic,
      mappedUsage: formValue.mappedUsage,
      mappedNoOfOrders: formValue.mappedNoOfOrders,
      mappedSuperCell: formValue.mappedSuperCellSelect,
      mappedAtpLot:formValue.mappedAtpLot,
      mappedAtpDeviceLocation:formValue.mappedAtpDeviceLocation,
      mappedAtpDrugExpirationDate:formValue.mappedAtpDrugExpirationDate,
      mappedCustomerQuantity:formValue.mappedCustomerQuantity,
      mappedAtpPillDescription:formValue.mappedAtpPillDescription,
      mappedAtpPillColor:  formValue.mappedAtpPillColor,
      mappedAtpPillShape: formValue.mappedAtpPillShape,
      mappedAtpImp1:formValue.mappedAtpImp1,
      mappedAtpImp2: formValue.mappedAtpImp2,
      mappedRanking:formValue.mappedRanking,
      mappedCustomerBarcode:formValue.mappedCustomerBarcode,

      mappedLockingCell: formValue.mappedLockingCell,
      mappedCustomerInventoryNumber:formValue.mappedCustomerInventoryNumber,
      mappedCustomerComment:formValue.mappedCustomerComment,
      mappedCustomerOtc:formValue.mappedCustomerOtc,
      mappedNdcLabel:formValue.mappedNdcLabel,
      noSuperCell:this.mappedSuperCellSelect.value === 'No Super cell'?true:false,
      isAlphabeticalOrder:this.mappedRanking.value == 'Alphabetical(max only)'?true:false,
      orderOrUsage: formValue.orderOrUsage == 1 ? true : false,
      noUsage: formValue.noUsage === undefined || formValue.noUsage === null ? false : formValue.noUsage,
      isCustomerMnemonic: this.isUndefined(formValue.isCustomerMnemonic) ? false : true,
      mappedExternalDrugFlag:formValue.mappedExternalDrugFlag
    };
    const FILEINFOSAVE_KEY = 'Fileinfosavekey'

    this.ngxLoader.start(FILEINFOSAVE_KEY)
    this.imtoolService
      .customerAndFileInfoSave(this.customerAndFileInfoModel)
      .subscribe(
        (result) => {
          this.ngxLoader.stop(FILEINFOSAVE_KEY);
          if (result.statusCode == 200) {
            this.hideSide.emit(2);
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.done,
              2
            );
          } else {
            this.hideSide.emit(1);
            this.toastService.openToast(
              result.message,
              constants.ToastPanelType.error,
              2
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(FILEINFOSAVE_KEY);
          console.log(error);
          let messageArray = error.error.message.split("|");
          if (messageArray.length < 2) {
            this.toastService.openToast(
              error.error.message ?? error.message,
              constants.ToastPanelType.error,
              2
            );
          }
          else {
            this.toastService.openToast(
              'Multiple errors have been detected. Please review the error list.',
              constants.ToastPanelType.error,
              2
            );
            this.errorMessages = messageArray;
          }
        }
      );
  }



  
  private progressbarShow(event) {
    switch (event.type) {
      case HttpEventType.Sent:
        console.log("Request has been made!");
        break;
      case HttpEventType.ResponseHeader:
        console.log("Response header has been received!");
        break;
      case HttpEventType.UploadProgress:
        var eventTotal = event.total ? event.total : 0;
        this.percentage = Math.round(event.loaded / eventTotal * 100);
        break;
      case HttpEventType.Response:
        if (event.body.succeed) {
          this.fileResponseHandler(event.body.data);
        }
        else {
          this.toastService.openToast(
            event.body.message,
            constants.ToastPanelType.done,
            1
          );
        }


        setTimeout(() => {
          this.percentage = 0;
        }, 2000);
        break;
    }
  }
  private fileResponseHandler(response: []) {

    this.ngxLoader.stop();
    this.toastService.openToast(
      "File successfully uploaded!",
      constants.ToastPanelType.done,
      1
    );
    this.columnNameList = [];
    response.forEach((x) => {
      this.columnNameList.push(x);
    });
    this.updateColumnList()
  }
  onFileSelected(event: any) {
    console.log("event in onFileSelected " + JSON.stringify(event));
    this.prepareFile(event);
  }
  onFileDropped(event: any) {
    this.prepareFile(event);
  }
  prepareFile(event: any) {
    this.fielName = event.target.files[0].name;
    this.fieleSelect = true;
    this.fileuploadObject = new DrugFileUpload();
    this.fileuploadObject.FileName = this.fielName;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (readerEvent: any) => {
      const splitVal = reader.result.toString().split(",");
      if (splitVal.length > 0) {
        this.fileuploadObject.FileContent = splitVal[1];
        this.fileContent = this.fileuploadObject.FileContent;
      }
    };
  }
  onRemoveFile() {
    this.openAlertWindow();
  }
  openAlertWindow() {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.alert.svg",
        title: "DELETE CHANGES?",
        bodyMessage: "Are you sure you want to Cancel and delete your changes?",
        bodyMessage2: "This process cannot be undone.",
        cancelBtnText: "Cancel",
        confirmBtnText: "Delete",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.fielName = "";
        this.fieleSelect = false;
        this.fileUploadButtonShow = true;
        this.columnNameList = [];
        this.drugFileInfoForm.reset();
        this.valueSetAfterResetForm();
      } else {
        console.log("Cancel=", result);
      }
    });
  }
  isSelecteMappedMnomonic(){
    this.drugFileInfoForm.controls["isCustomerMnemonic"].setValue(this.drugFileInfoForm.get('mappedMnemonic').value.length>0?true:false);
  }


  valueSetAfterResetForm() {
   this.setUsageValue()
    this.drugFileInfoForm.controls['noUsage'].setValue(false);
    this.drugFileInfoForm.controls['mappedNdcLabel'].setValue(1);
    this.formValidataionUpdate();
    this.deviceId.setValue(1)
    this.deviceIdChange.emit(1)
  }
  //#endregion

 }
