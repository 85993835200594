import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-alert-wizard',
  templateUrl: './import-wizard-alert.component.html',
  styleUrls: ['./import-wizard-alert.component.scss']
})
export class ImportWizardAlertComponent implements OnInit {

  alertWizardTitle:string;
  titelFontColor:string;
  bodyMessageColor:string;
  alertWizardBodyMessage:string;
  alertWizardBodyMessage2:string='';
  alertIcon:string='';
  cancelButtonText:string;
  confirmButtonText:string;
  cancelButtonColor:string;
  confirmButtonColor:string;
  buttonglobalClass :string;
  isButtonOneDisabled :boolean;
  isButtonTwoDisabled :boolean;
  constructor(
    public dialogRef: MatDialogRef<ImportWizardAlertComponent>,  
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any) { 
      this.alertIcon = data.alertIcon;
      this.alertWizardTitle = data.title;
      this.alertWizardBodyMessage = data.bodyMessage;
      this.alertWizardBodyMessage2 = data.bodyMessage2;
      this.cancelButtonText = data.cancelBtnText;
      this.confirmButtonText = data.confirmBtnText;

      this.cancelButtonColor =
        data.cancelButtonColor == undefined
          ? "btn-light-blue"
          : data.cancelButtonColor;
      this.confirmButtonColor =
        data.confirmButtonColor == undefined
          ? "btn-light-red"
          : data.confirmButtonColor;
        this.buttonglobalClass  = data.buttonglobalClass == undefined?"end end":data.buttonglobalClass;
        this.isButtonOneDisabled = data.isButtonOneDisabled == undefined?false:data.isButtonOneDisabled;
        this.isButtonTwoDisabled = data.isButtonTwoDisabled == undefined?false:data.isButtonTwoDisabled;
    }

  ngOnInit(): void {
  }
closeDialog(response:boolean, buttonClick:number){
  this.dialogRef.close({event:'close', data:response, buttonClick:buttonClick })
}
}
