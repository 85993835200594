<div floatLabel="never" [formGroup]="form">
  <div class="autocomplete-input flex flex-row">
    <input type="text" matInput appNoHtmlTags trim [formControlName]="controlName" [matAutocomplete]="auto">
    <button mat-button [ngStyle]="{'visibility': (systemSelectControl.value) ? 'visible' : 'hidden'}" mat-icon-button
      aria-label="" (click)="systemSelectControl.setValue('')" [disabled]="systemSelectControl.disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySystem.bind(this)">
    <mat-option class="autoCompleteSystem" *ngFor="let system of filteredSystems$ | async" [value]="system.machineNo"
      matTooltip="{{system.name}}" [disabled]="system.isProvisioned" matTooltipPosition="after"
      matTooltipClass="my-custom-tooltip">
      <span *ngIf="system.isProvisioned">{{system.name}} ({{system.machineNo}})
        &nbsp;&nbsp;&nbsp;&nbsp;CONFIGURED</span>
      <span *ngIf="!system.isProvisioned">{{system.name}} ({{system.machineNo}})</span>
    </mat-option>
  </mat-autocomplete>
</div>