import { KeyValue, DatePipe } from '@angular/common';
import { Component, Inject, Input, Pipe, PipeTransform } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Asset } from '../../../models/batch-models';
import { QueuePriorityEnum } from '../../../models/pending-script-priority-enum';
import { IEnumBaseDictionary, ScriptManagerData, VialData } from '../../../models/script-manager-data';
import { ParataStandardInterfaceService } from '../../../services/parataStandardInterface.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dispensing-script-dialog',
  templateUrl: './dispensing-script-detail-dialog.component.html',
  styleUrls: ['./dispensing-script-detail-dialog.component.scss'],
  providers: [DatePipe]
})

export class DispensingScriptDetailComponent {

  object = Object;
  priorityEnum = QueuePriorityEnum;
  scriptData: ScriptManagerData;
  vialData: Array<VialData>;
  selectedVialNumber: number;
  selectedVial: VialData;
  imagePath: any;


  constructor(
    public dialogRef: MatDialogRef<DispensingScriptDetailComponent>,
    public datePipe: DatePipe,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    private ngxLoader: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public script: ScriptManagerData
  ) {
    this.scriptData = script;
    this.vialData = [];
    this.selectedVialNumber = 1;
    this.getVialDetail();
  }

  selectVial(vialNumber: number, vial: VialData) {
    this.selectedVialNumber = vialNumber;
    this.selectedVial = vial;
    this.setImage();
  }

  getVialDetail() {

    if (this.scriptData?.vials?.length > 0 ) {
      this.vialData = this.scriptData.vials;
    }
    else {
      this.parataStandardInterfaceService.getVialDetail('organizationID', this.scriptData.transactionID)
        .subscribe((list: Array<VialData>) => {
          if (list.length > 0) {
            this.vialData = [...list];
            this.selectedVial = this.vialData[0];
            this.setImage();
          }
          this.ngxLoader.stop();
        }, () => {
          this.ngxLoader.stop();
        });
    }
  }

  setImage() {
    if (this.selectedVial.vialImage == null) {
      this.imagePath = '/assets/img/noImage.jpg';
    }
    else {
      this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.selectedVial.vialImage);
    }
  }
  getCorrectDate(date: Date) {
    const datepipe: DatePipe = new DatePipe('en-US');
    const dateIn = new Date(date);
    const timeOffsetInMS = dateIn.getTimezoneOffset() * 60000;
    const convertedTime = dateIn.setTime(dateIn.getTime() + timeOffsetInMS);
    const formattedDate = datepipe.transform(convertedTime, 'dd-MMM-yyy HH:mm:ss a');
    return formattedDate;
  }
}
