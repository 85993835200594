<div class="inv_table" #inventoryTable>

    <div class="inv_table-header inv_table-row ">
        <div class="td"><!--Space holder --></div>
        <div class="td">Batch Name</div>
        <div class="td"><!--Space holder --></div>
        <div class="td">(#) Pouches</div>
        <div class="td">System</div>
        <div class="td">Date & Time</div>
    </div>
    <div *ngFor="let queue of inventories ;">
        <div *ngIf="queue && (queue?.name == 'COMPLETE' && isComplete ==true)">
            <div class="inv_table-header inv_table-row ">
                <div class="td " [class.clickable]="true" (click)="onExpandRow(queue)"
                    tabindex="0">
                    <span>
                        <app-icon [icon]="queue.isExpanded ? 'arwup' : 'arwdown'" size="12" class="pointer">
                        </app-icon>
                    </span>
                </div>
                <div class="td rowTitle" matTooltip="{{ queue?.name  +  (queue?.inventory?.data?.batches.length) | uppercase}}"
                    matTooltipPosition="below" matTooltipClass="my-custom-tooltip">
                    <div>{{queue?.name | uppercase}} ({{queue?.inventory?.data?.batches.length}})</div>
                </div>
                <div class="td"><!--Space holder --></div>
                <div class="td"><!--Space holder --></div>
                <div class="td"><!--Space holder --></div>
            </div>

            <div class="inv_table-row" *ngFor="let row of queue?.inventory?.data?.batches ; let rowIndex = index"
                (click)="onOpenDispensingDetail($event, row, queue?.name)">
                <div class="inv_table-body" *ngIf="queue?.isExpanded">
                    <div class="collapseElement">
                        <div class="td"
                            [class.clickable]="isRowClickable_inner(queue?.inventory?.data?.batches,rowIndex)"
                            (click)="onExpandRow_inner(row,rowIndex);"
                            (keydown.enter)="onExpandRow_inner(row,rowIndex);" tabindex="0">
                            <span>
                                <app-icon [icon]="row.isExpanded ? 'arwup' : 'arwdown'" size="12" class="pointer">
                                </app-icon>
                            </span>
                        </div>
                        <div class="td" matTooltip="{{row?.parataBatchID | uppercase}}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"
                        [innerHTML]="(row?.parataBatchID  | truncate:25 ) | noHtml | uppercase | highlightMatched: filterText">
                        </div>
                        <div class="td"><!--Space holder --></div>
                        <div class="td">{{row?.pouchCount ? row?.pouchCount : '-'}}</div>
                        <div class="td" matTooltip="{{row?.deviceID | uppercase}}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"
                            [innerHTML]="(row?.deviceID  | truncate:25) | noHtml | uppercase | highlightMatched: filterText">                        
                        </div>
                        <div class="td">{{row?.completedDatetime | date:"MM/dd/yyyy hh:mm a"}}</div>
                    </div>
                    <div *ngIf="row.isExpanded" style="height: unset" class="inv_table-row">
                        <div class="borderTop"></div>
                        <div class="child-row " *ngFor="let childrow of row.patients;  let innerRowIndex = index">
                            <div class=" collapseElement">
                                <div class="td1"></div>
                                <div class="td1" [innerHTML]="childrow?.name | noHtml | highlightMatched: filterText"></div>
                                <div class="td1">{{childrow.dob | date:"dd-MMM-yyyy":"+0000"}}</div>
                                <div class="td1">{{childrow.pouchCount}}</div>
                                <div class="td1"><!--Space holder --></div>
                                <div class="td1"><!--Space holder --></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="inv_table-row" *ngIf="queue?.inventory?.data?.batches.length === 0 && queue?.isExpanded">
                <div class="mat-cell mat-column-pending">
                  <div class="loading-spinner text-center w-100 m-15">
                    No data exists
                  </div>
                </div>
              </div>
        </div>
        <div class="inv_table-row" *ngIf="loading">
            <div class="mat-cell mat-column-pending">
                <div class="loading-spinner text-center">
                    <img src="/assets/img/Parata_processing.gif" alt="loading" class="w-10 m-15" />
                </div>
            </div>
        </div>
        <div class="inv_table-row" *ngIf="inventories && inventories.length === 0">
            <div class="mat-cell mat-column-pending">
                <div class="loading-spinner text-center w-100 m-15">
                    No data exists
                </div>
            </div>
        </div>
    </div>