<div class="pp_report">


  <div *ngIf="dailyPackagerUtilization">
      <h6 class="flex justify-between content-center items-center flex-row">
        <span *ngIf="name !== '' " [matTooltipDisabled]="name.length<10" matTooltip="{{name }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
          {{ name | truncate:10 }} - Packager Downtime</span>
          <span *ngIf="name === '' "  [matTooltipDisabled]="deviceId.length<10" matTooltip="{{deviceId  }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
            {{ deviceId | truncate:10  }} - Packager Downtime</span>
        <a routerLink="/reports/774c17c7-5923-41fa-9ea0-4f0bdebb8af7" id="report_downtime_device" class="btn-link" 
          [attr.aria-label]="name + '- Packager Downtime Report'">
          Report >
        </a>
      </h6>

      <div *ngIf="noDataExistsForDT === false" class="flex justify-between content-stretch items-stretch flex-col gap-1">

        <div class="basis-full">

          <div class="rcount m-t-8">
            <span id="OverallDownTime" class="time">{{ downtime }}</span>
            <span id="OverallDownTimeTitle" class="rcountdesc details"> Total </span>
            <custom-tooltip text="Total system downtime since 12:00am"></custom-tooltip>
          </div>
        </div>

        <div class="basis-full">
          <div class="pp_report_det flex justify-between content-stretch items-stretch flex-row gap-[1px]">
            <div #todayOverView class="grow-0 shrink-0 basis-[0%]">
              <div class="rcountdesc"></div>
              <div id="overallDownTime" class="rcount"></div>
              <ng-container *ngFor="let loadsys of dailyPackagerSystemSpecificDowntime">

                <div class="rcount dtContainer">
                  <div class="rcountdesc"></div>
                  <span class="time" id="{{ loadsys.id }}">{{ loadsys.valueStr }} </span>
                  <span class="rcountdesc loadDesc">
                    <span id="{{ loadsys.id + 'Percent' }}" class="percent">
                      {{ loadsys.percent }}
                    </span>
                    <span class="label" id="{{ loadsys.label }}">
                      {{ loadsys.label | truncate:14}}</span>
                  </span>
                </div>

              </ng-container>
            </div>

            <div class="flex-1">
              <app-overall-bar-chart [ref]="todayOverViewRef" [data]="dailyPackagerSystemSpecificDowntime">
              </app-overall-bar-chart>
            </div>
          </div>
        </div>


      </div>

      <div *ngIf="noDataExistsForDT === true">
        <p class="pp_report">
          <span class="rcountdesc">No data exists</span>
        </p>
      </div>

      <hr>

  </div>

</div>