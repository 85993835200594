import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';


export interface DispenseRatio {
  customerId: string;
  devices?: any[];
  siteId: string;
  window: string;
}

export interface State {
  HourlyPouchCountAllSystem: any;
  HourlyPouchCountDevice: any;
  HourlyVialCountAllSystem: any,
  HourlyVialCountDevice: any
  UptimePackagerAllSystem: any,
  UptimePackagerDevice: any
  DowntimePackagerAllSystem: any,
  DowntimePackagerDevice: any
  DispenseRatio: any;
  SystemStatusDevice: any;
  SystemStatusAllSystem: any;
}

export const initialState: State = {
  HourlyPouchCountAllSystem: null,
  HourlyPouchCountDevice: null,
  HourlyVialCountAllSystem: null,
  HourlyVialCountDevice: null,
  UptimePackagerAllSystem: null,
  UptimePackagerDevice: null,
  DowntimePackagerAllSystem: null,
  DowntimePackagerDevice: null,
  DispenseRatio: null,
  SystemStatusDevice: null,
  SystemStatusAllSystem: null,
};

const pharmacyPeekReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.PharmacyPeekActions.getHourlyPouchCountDevice, (state, action) => ({
    ...state,
    HourlyPouchCountDevice: getHCPFilteredData(state.HourlyPouchCountDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.getHourlyVialCountDevice, (state, action) => ({
    ...state,
    HourlyVialCountDevice: getHCPFilteredData(state.HourlyVialCountDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.getHourlyPouchCountAllSystem, (state, action) => ({
    ...state,
    HourlyPouchCountAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.getHourlyVialCountAllSystem, (state, action) => ({
    ...state,
    HourlyVialCountAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.updateHourlyPouchCountDevice, (state, action) => ({
    ...state,
    HourlyPouchCountDevice: getHCPFilteredData(state.HourlyPouchCountDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateHourlyVialCountDevice, (state, action) => ({
    ...state,
    HourlyVialCountDevice: getHCPFilteredData(state.HourlyVialCountDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateHourlyPouchCountAllSystem, (state, action) => ({
    ...state,
    HourlyPouchCountAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.updateHourlyVialCountAllSystem, (state, action) => ({
    ...state,
    HourlyVialCountAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.getUptimePackagerDevice, (state, action) => ({
    ...state,
    UptimePackagerDevice: getFilteredPackager(state.UptimePackagerDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.getDowntimePackagerDevice, (state, action) => ({
    ...state,
    DowntimePackagerDevice: getFilteredPackager(state.DowntimePackagerDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.getUptimePackagerAllSystem, (state, action) => ({
    ...state,
    UptimePackagerAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.getDowntimePackagerAllSystem, (state, action) => ({
    ...state,
    DowntimePackagerAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.updateUptimePackagerDevice, (state, action) => ({
    ...state,
    UptimePackagerDevice: getFilteredPackager(state.UptimePackagerDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateDowntimePackagerDevice, (state, action) => ({
    ...state,
    DowntimePackagerDevice: getFilteredPackager(state.DowntimePackagerDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateUptimePackagerAllSystem, (state, action) => ({
    ...state,
    UptimePackagerAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.updateDowntimePackagerAllSystem, (state, action) => ({
    ...state,
    DowntimePackagerAllSystem: returnNewState(action.data),
  })
  ),

  on(AllActions.PharmacyPeekActions.getDispenseRatio,
    (state, action) => ({
      ...state,
      DispenseRatio: returnNewState(action.data)
    })
  ),

  on(AllActions.PharmacyPeekActions.updateDispenseRatio,
    (state, action) => ({
      ...state,
      DispenseRatio: returnNewState(action.data)
    })
  ),

  on(AllActions.PharmacyPeekActions.getSystemStatusDevice, (state, action) => ({
    ...state,
    SystemStatusDevice: returnNewState(action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateSystemStatusDevice, (state, action) => ({
    ...state,
    SystemStatusDevice: getSystemFilteredData(state.SystemStatusDevice, action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.getSystemStatusAllSystem, (state, action) => ({
    ...state,
    SystemStatusAllSystem: returnNewState(action.data)
  })
  ),

  on(AllActions.PharmacyPeekActions.updateSystemStatusAllSystem, (state, action) => ({
    ...state,
    SystemStatusAllSystem: returnNewState(action.data)
  })
  ),

);

export function reducer(state: State | undefined, action: Action): any {
  return pharmacyPeekReducer(state, action);
}


export function returnNewState(state) {
  const newState = Object.assign({}, state);
  return newState;
}

export function getHCPFilteredData(storeData, newData) {
  let newArr = [];
  if (storeData) {
    let existData: any = [...storeData];
    if (newData.deviceId) {
      existData = existData.filter(x => x?.deviceId !== newData.deviceId);
      newArr = [...existData];
      newArr.push(newData);
      return newArr;
    }
    else {
      return existData;
    }
  }
  else {
    newArr.push(newData);
    return newArr;
  }

}

export function getSystemFilteredData(storeData, newData) {
  if (storeData) {
    let sData = Object.assign({}, storeData);
    sData.data = Object.assign({}, sData.data);
    const nData = Object.assign({}, newData);

    const existData: any = { ...storeData.data };
    if (nData.deviceId) {
      existData[nData.deviceId] = nData.data;
      sData.data = Object.assign({}, existData);
      return sData;
    }
    else {
      return sData;
    }
  }
  else {
    return newData;
  }
}

export function getFilteredPackager(storeData, newData) {
  let newArr = [];
  if (storeData) {
    let existData: any = [...storeData];
    if (newData.deviceId) {
      existData = existData.filter(x => x?.deviceId !== newData.deviceId);
      newArr = [...existData];
      newArr.push(newData);
      return newArr;
    }
    else {
      return existData;
    }
  }
  else {
    newArr.push(newData);
    return newArr;
  }
}
