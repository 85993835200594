import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';

export interface State {
  signalrSubList: any;
}

export const initialState: State = {
  signalrSubList: null,
};

const signalrReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.SignalRActions.addSignalRSubList, (state, action) => ({
    ...state,
    signalrSubList: getSignalRList(state.signalrSubList, action.data)
  })
  ),

  on(AllActions.SignalRActions.deleteSignalRSubList, (state, action) => ({
    ...state,
    signalrSubList: deleteSignalRList(state.signalrSubList, action.data)
  })
  ),

);

export function reducer(state: State | undefined, action: Action): any {
  return signalrReducer(state, action);
}

export function getSignalRList(storeData, newData) {
  let newArr = [];
  if (storeData) {
    newArr = [...storeData];
    newArr.push(newData);
    return newArr;
  }
  else {
    newArr.push(newData);
    return newArr;
  }

}

export function deleteSignalRList(storeData, newData) {
  let newArr = [];
  if (storeData) {
    newArr = [...storeData];
    newArr.forEach(e => {
      if (e.type === newData.type) {
        const index = newArr.indexOf(e);
        newArr.splice(index, 1);
        newArr = [...newArr];
        return newArr;
      }
    });

  }
  else {
    return newArr;
  }

}