import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportToken } from '../models/report-token';

@Injectable({
  providedIn: 'root'
})
export class ReportTokenService extends DataService<ReportToken> {
  constructor(http: HttpClient) {
    super(environment.apiUrl.reportRepository + '/ReportToken/', http);
   }
}
