import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../models/notification';


@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss']
})

export class NotificationBannerComponent implements OnInit {
  notification: Notification[];
  subject: string;
  message: string;
  fromActionText: string;
  reDirectURL: string;
  isloaded: boolean;
  notificationId: string;

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.isloaded=false;

    this.notificationService.getNotificationsByType(1).subscribe(response => {
      this.notification = response;
      this.subject=this.notification[0].subject;
      this.message=this.notification[0].message;
      this.fromActionText=this.notification[0].fromActionText;
      this.reDirectURL=this.notification[0].reDirectURL;
      this.notificationId=this.notification[0].notificationId;
      this.isloaded=true;
    }, (error => {
      this.notification = null;
      console.log('error in getting the requested data', error);
    }),
    );
  }
}
