import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {

  filter: string;

  @Input() inputId;
  @Input() placeholder;
  @Input() hoverPlaceholder;

  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterEnter: EventEmitter<string> = new EventEmitter<string>();

  onKeyup(value) {
    this.filter = value;    
    this.filterChange.emit(this.filter);  
  }

  onKeyEnter(value) {
    this.filter = value ? value.path[0].value : value;
    this.filterEnter.emit(this.filter);
  }

  clear() {
    this.filter = '';
    this.filterChange.emit(this.filter);  
    this.filterEnter.emit(this.filter);
  }

}
