import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { SignalRService } from './signal-r.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { SignalEvent } from '../models/signal-event';
import { SignalEventType } from '../models/signal-event-type';
import { getSignalrList } from 'src/app/store/selectors/signalr.selector';


@Injectable()
export class PrivateSignalRService extends SignalRService {
  private _signalEvent: Subject<SignalEvent<any>>;
  private _openConnection: boolean = false;
  private _isInitializing: boolean = false;
  private _hubConnection!: signalR.HubConnection;


  private siteId = 'https://parata.com/customerno';
  private customerId = 'https://parata.com/parentOrganization';
  private url = environment.apiUrl.viewsHub;
  accessToken;
  private backoffDelay = 5;
  private subList;

  constructor(public auth: AuthService, private store: Store<State>,) {
    super();
    this._signalEvent = new Subject<any>();
    this._isInitializing = true;
    this.startSignalR();
  }

  getDataStream<TDataShape>(...filterValues: SignalEventType[]): Observable<SignalEvent<TDataShape>> {
    this._ensureConnection();
    return this._signalEvent.asObservable().pipe(filter(event => filterValues.some(f => f === event.type)));
  }

  private _ensureConnection() {
    if (this._openConnection || this._isInitializing) {
      return;
    }
    this.startSignalR();
  }

  public async invokes(): Promise<void> {
    this.store.select(getSignalrList).subscribe(async subList => {
      if (subList && subList.length > 0) {

      const list = [...subList];
      const uniqueIds = [];

      const unique = list.filter(element => {
        const isDuplicate1 = uniqueIds.includes(element.type);
        const isDuplicate2 = uniqueIds.includes(element.deviceId);

        if (!isDuplicate1 && !isDuplicate2) {
          uniqueIds.push(element);
          return true;
        }
        return false;
      });

        this.subList = unique;
        this.subList.forEach(async element => {
          console.log(subList);
          await this._hubConnection.invoke('Subscribe', element.type, this.customerId, this.siteId, element.deviceId);
          console.log(`SignalR Connected Invoke.  URL: ${this.url}  State: ${this._hubConnection.state} Type: ${element.type} CustomerId: ${this.customerId} SiteId: ${this.siteId} DeviceId: ${element.deviceId}`);
        });

      }
    }
    );
  }

  public async startSignalR() {

    this.auth.userProfile$.subscribe(async userProfile => {
      const userProfileData = userProfile;
      this.customerId = userProfileData[this.customerId];
      this.siteId = userProfileData[this.siteId];
      const accessToken = await this.auth.getTokenSilently$().toPromise();
      if (accessToken) {
        this.accessToken = accessToken;

        if (!this.customerId) {
          console.log('ERROR: no customerId!!  This is required for SignalR.');
          return;
        }

        this._initializeSignalR();

      }
    });
  }

  private async _initializeSignalR() {

    const options = {
      accessTokenFactory: () => this.accessToken,
    };

    if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
      return;
    }

    this._hubConnection = await new signalR.HubConnectionBuilder()
      .withUrl(this.url, options)
      .withAutomaticReconnect([0, 2000, 10000, 30000])
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.start();
    }

  }

  private async start() {
    this._hubConnection.start()
      .then(_ => {
        this._openConnection = true;
        this._isInitializing = false;
        this._setupSignalREvents();
        console.log(`SignalR Connected.  URL: ${this.url}  State: ${this._hubConnection.state} CustomerId: ${this.customerId} SiteId: ${this.siteId}`);
        this.invokes();
      })
      .catch(err => {
        console.warn(err);
        this._hubConnection.stop().then(_ => {
          this._openConnection = false;
          console.log(err);
          console.log(`SignalR unable to start.  URL: ${this.url}  State: ${this._hubConnection.state}`);
          if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Disconnected) {
            setTimeout(this.start.bind(this), this.backoffDelay * 1000);
          }
        })
      });
  }

  private _setupSignalREvents() {
    this._hubConnection.onreconnecting(error => {
      console.log(`Connection lost due to error "${error}". Reconnecting.  URL: ${this.url}  State: ${this._hubConnection.state}`);
    });

    this._hubConnection.onreconnected(connectionId => {
      console.log(`Connection reestablished. Connected with connectionId "${connectionId}". Reconnecting.  URL: ${this.url}  State: ${this._hubConnection.state}`);
    });

    this._hubConnection.onclose(error => {
      console.log(`Connection closed due to error "${error}". Re-starting the connection in ${this.backoffDelay} seconds.   URL: ${this.url}  State: ${this._hubConnection.state}`);
      this._openConnection = false;
      this.start();
    });

    this._hubConnection.on('ReceiveMessage', data => {
      if (data === 'connected') { return; }
      if (data === 'unauthorized') { console.log('ERROR: Token is invaid or otherwise unauthorized for SignalR.'); return; }
      const parsedData = JSON.parse(data);
      this._onMessage({ type: parsedData.type, data: parsedData })

    });
  }

  private _onMessage<TDataShape>(payload: SignalEvent<TDataShape>) {
    this._signalEvent.next(payload);
  }

}