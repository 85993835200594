import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { KeyValueData } from '../../../shared/models/keyvalue-data';
import { Organization } from '../models/organization';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService extends DataService<Organization> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}`, http);
    this.httpClient = http;
  }

  getMachineTypes(orgId) {
    const machineTypes: KeyValueData[] = [
      { id: '1', name: 'ATP' },
      { id: '2', name: 'PASS' },
      { id: '3', name: 'MAX' },
      { id: '4', name: 'Mini' },
    ];
    return machineTypes;
  }

  getActiveUsers(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.apiUrl.authorizationService}users/Active`);
  }

  getUser(userEmail: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl.authorizationService}users/Email/${userEmail}`);
  }

  checkUserExists(userEmail: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl.authorizationService}users/UserExists/${userEmail}`);
  }

  putAcceptEula(userEmail: string) {
    return this.httpClient.put(`${environment.apiUrl.authorizationService}users/EULA/${userEmail}`, null);
  }
}
