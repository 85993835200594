import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppCardOptions } from '../../models/ui/app-card-options';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnChanges {

  @Input() title: string;
  @Input() backLink: string;

  @Input() options: AppCardOptions[];
  @Input() selectedIndex: number;
  @Output() selectIndex: EventEmitter<number> = new EventEmitter<number>();

  @Input() collapsable: boolean;
  @Input() collapsedTitle: string;
  @Input() collapsed: boolean;
  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('content', {static: false}) elementView: ElementRef;

  contentHeight: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapsed && this.elementView) {
      this.contentHeight = this.elementView.nativeElement.offsetHeight;
    }
  }
}
