<div [class]="'alert-container alert-container-' + type">
  <div class="flex flex-row">
     <div class="flex-1">
      <h4>{{ title }}</h4>
      <p *ngFor="let message of messages">
        {{ message }}
      </p>
    </div>
  </div>
</div>
