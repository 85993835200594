import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportViewerComponent } from './components/report-viewer/report-viewer.component';
import { ReportSubscriptionComponent } from './components/report-subscription/report-subscription.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PharmacyPeekModule } from '../pharmacy-peek/pharmacy-peek.module';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';

@NgModule({
  declarations: [
    ReportViewerComponent,
    ReportSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReportsRoutingModule,
    FormsModule,
    PharmacyPeekModule,
    MatSlideToggleModule
  ],
  exports: [
    ReportViewerComponent,
    ReportsRoutingModule,
  ]
})
export class ReportsModule { }
