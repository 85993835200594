import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import QueueData from 'src/app/external-user/batch-manager/models/script-manager-data';
import { State } from '../reducers/scripts.reducer';



export const getState = createFeatureSelector<State>('scripts');

export const getVialScripts = createSelector(
  getState,
  (state: State) => {
    if (state.Vial) {
      return getSorted(state.Vial);
    } else {
      return state.Vial
    }
  }
);

export const getPouchScripts = createSelector(
  getState,
  (state: State) => {
    if (state.Pouch) {
      return getSorted(state.Pouch);
    } else {
      return state.Pouch
    }
  }
);

export function getSorted(data) {
  const sortData: any = data;

  sortData.sort((a, b) => {
    if (b.name.toLowerCase() < a.name.toLowerCase()) { return 1; }
    if (b.name.toLowerCase() > a.name.toLowerCase()) { return -1; }
    return 0;
  });

  return sortData;
}