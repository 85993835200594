import { PackagerOverallUtilizationDTO, PackagerUtilizationDTO } from '../models/daily-packager-downtime';

export function mapperUptimeAllSystem(data) {
  if (data.length === 0) { return null; }
  const packUtil = data.data;
  const objDailyOverallPackagerUtil: PackagerOverallUtilizationDTO = {
    customerId: '',
    siteId: '',
    overallUptime: '',
    overallUptimePercent: '',
    overallUtilizationTime: '',
    overallUtilizationTimePercent: '',
    overallIdleTime: '',
    overallIdleTimePercent: '',
    overallDownTime: '',
    overallDownTimePercent: '',
    overallDTTrayLoad: '',
    overallDTTrayLoadPercent: '',
    overallDTCanisterLoad: '',
    overallDTCanisterLoadPercent: '',
    overallDTMechanical: '',
    overallDTMechanicalPercent: '',
    overallDTOperatorInitiated: '',
    overallDTOperatorInitiatedPercent: '',
    overallDTOther: '',
    overallDTOtherPercent: '',
    packagers: [],
  };
  let overallIdleTime: any = 0;
  let overallUtilTime: any = 0;
  let overallUpTime: any = 0;

  const idleTime: any = packUtil.idleTime;
  const utilTime: any = packUtil.utilizationTime;

  overallIdleTime = idleTime;
  overallUtilTime = utilTime;

  objDailyOverallPackagerUtil.siteId = data.siteId;
  objDailyOverallPackagerUtil.customerId = data.customerId;

  overallUpTime = overallIdleTime + overallUtilTime;
  objDailyOverallPackagerUtil.overallUtilizationTime = customTimeFormat(overallUtilTime);
  objDailyOverallPackagerUtil.overallIdleTime = customTimeFormat(overallIdleTime);
  objDailyOverallPackagerUtil.overallUptime = customTimeFormat(overallUpTime);


  objDailyOverallPackagerUtil.overallUtilizationTimePercent = (packUtil.utilizationTimePct || 0) + '%';
  objDailyOverallPackagerUtil.overallIdleTimePercent = (packUtil.idleTimePct || 0) + '%';
  objDailyOverallPackagerUtil.overallUptimePercent = ((packUtil.utilizationTimePct + packUtil.idleTimePct) || 0) + '%';

  // eslint-disable-next-line no-console
  console.log(JSON.stringify(objDailyOverallPackagerUtil));
  return objDailyOverallPackagerUtil;
}

export function mapperUptimeDevice(data) {
  if (data.length === 0) { return null; }
  const packUtil = data.data;
  const idleTime: any = packUtil.idleTime;
  const utilTime: any = packUtil.utilizationTime;

  const devicePkgrUtilization: any =
  {
    deviceId: '',
    uptime: '',
    idleTime: '',
    utilTime: '',
    downtime: '',
    uptimePercent: '',
    idleTimePercent: '',
    utilTimePercent: '',
    downtimePercent: '',
  };


  devicePkgrUtilization.deviceId = data.deviceId;

  const upTime = idleTime + utilTime;
  devicePkgrUtilization.uptime = customTimeFormat(upTime);
  devicePkgrUtilization.idleTime = customTimeFormat(idleTime);
  devicePkgrUtilization.utilTime = customTimeFormat(utilTime);

  devicePkgrUtilization.uptimePercent = ((packUtil.utilizationTimePct + packUtil.idleTimePct) || 0) + '%';
  devicePkgrUtilization.idleTimePercent = (packUtil.idleTimePct || 0) + '%';
  devicePkgrUtilization.utilTimePercent = (packUtil.utilizationTimePct || 0) + '%';

  console.log(JSON.stringify(devicePkgrUtilization));
  return devicePkgrUtilization;
}

export function mapperDowntimeDevice(data) {
  if (data.length === 0) { return null; }
  const packUtil = data.data;
  const dtTrayLoad: any = packUtil.trayLoadDowntime;
  const dtCanisterLoad: any = packUtil.canisterLoadDowntime;
  const dtMechanical: any = packUtil.mechanicalDowntime;
  const dtOperatorInitiated: any = packUtil.operatorInitiatedDowntime;
  const dtOther: any = packUtil.otherDowntime;

  const devicePkgrUtilization: any =
  {
    deviceId: '',
    downtime: '',
    downtimePercent: '',
    dtTrayLoad: '',
    dtTrayLoadPercent: '',
    dtCanisterLoad: '',
    dtCanisterLoadPercent: '',
    dtMechanical: '',
    dtMechanicalPercent: '',
    dtOperatorInitiated: '',
    dtOperatorInitiatedPercent: '',
    dtOther: '',
    dtOtherPercent: '',
  };

  devicePkgrUtilization.deviceId = data.deviceId;
  devicePkgrUtilization.dtTrayLoad = customTimeFormat(dtTrayLoad);
  devicePkgrUtilization.dtCanisterLoad = customTimeFormat(dtCanisterLoad);
  devicePkgrUtilization.dtMechanical = customTimeFormat(dtMechanical);
  devicePkgrUtilization.dtOperatorInitiated = customTimeFormat(dtOperatorInitiated);
  devicePkgrUtilization.dtOther = customTimeFormat(dtOther);

  const downTime = (dtTrayLoad + dtCanisterLoad + dtMechanical + dtOperatorInitiated + dtOther);
  devicePkgrUtilization.downtime = customTimeFormat(downTime);

  devicePkgrUtilization.dtTrayLoadPercent = (packUtil.trayLoadDowntimePct || 0) + '%';
  devicePkgrUtilization.dtCanisterLoadPercent = (packUtil.canisterLoadDowntimePct || 0) + '%';
  devicePkgrUtilization.dtMechanicalPercent = (packUtil.mechanicalDowntimePct || 0) + '%';
  devicePkgrUtilization.dtOperatorInitiatedPercent = (packUtil.operatorInitiatedDowntimePct || 0) + '%';
  devicePkgrUtilization.dtOtherPercent = (packUtil.otherDowntimePct || 0) + '%';

  console.log(JSON.stringify(devicePkgrUtilization));
  return devicePkgrUtilization;
}

export function mapperDowntimeAllSystem(data) {
  if (data.length === 0) { return null; }
  const packUtil = data.data;
  const objDailyOverallPackagerUtil: any = {
    customerId: '',
    siteId: '',
    overallDownTime: '',
    overallDownTimePercent: '',
    overallDTTrayLoad: '',
    overallDTTrayLoadPercent: '',
    overallDTCanisterLoad: '',
    overallDTCanisterLoadPercent: '',
    overallDTMechanical: '',
    overallDTMechanicalPercent: '',
    overallDTOperatorInitiated: '',
    overallDTOperatorInitiatedPercent: '',
    overallDTOther: '',
    overallDTOtherPercent: '',
  };
  let overallIdleTime: any = 0;
  let overallUtilTime: any = 0;
  let overallUpTime: any = 0;
  let overallDT: any = 0;
  let overallDTTrayLoad: any = 0;
  let overallDTCanisterLoad: any = 0;
  let overallDTMechanical: any = 0;
  let overallDTOperatorInitiated: any = 0;
  let overallDTOther: any = 0;

  const dtTrayLoad: any = packUtil.trayLoadDowntime;
  const dtCanisterLoad: any = packUtil.canisterLoadDowntime;
  const dtMechanical: any = packUtil.mechanicalDowntime;
  const dtOperatorInitiated: any = packUtil.operatorInitiatedDowntime;
  const dtOther: any = packUtil.otherDowntime;

  overallDTTrayLoad = overallDTTrayLoad + dtTrayLoad;
  overallDTCanisterLoad = overallDTCanisterLoad + dtCanisterLoad;
  overallDTMechanical = overallDTMechanical + dtMechanical;
  overallDTOperatorInitiated = overallDTOperatorInitiated + dtOperatorInitiated;
  overallDTOther = overallDTOther + dtOther;

  objDailyOverallPackagerUtil.siteId = data.siteId;
  objDailyOverallPackagerUtil.customerId = data.customerId;

  overallDT = overallDTTrayLoad + overallDTCanisterLoad + overallDTMechanical + overallDTOperatorInitiated + overallDTOther;

  objDailyOverallPackagerUtil.overallDownTime = customTimeFormat(overallDT);
  objDailyOverallPackagerUtil.overallDTTrayLoad = customTimeFormat(overallDTTrayLoad);
  objDailyOverallPackagerUtil.overallDTCanisterLoad = customTimeFormat(overallDTCanisterLoad);
  objDailyOverallPackagerUtil.overallDTMechanical = customTimeFormat(overallDTMechanical);
  objDailyOverallPackagerUtil.overallDTOperatorInitiated = customTimeFormat(overallDTOperatorInitiated);
  objDailyOverallPackagerUtil.overallDTOther = customTimeFormat(overallDTOther);

  objDailyOverallPackagerUtil.overallDTTrayLoadPercent = ((packUtil.trayLoadDowntimePct || 0)) + '%';
  objDailyOverallPackagerUtil.overallDTCanisterLoadPercent = (packUtil.canisterLoadDowntimePct || 0) + '%';
  objDailyOverallPackagerUtil.overallDTMechanicalPercent = (packUtil.mechanicalDowntimePct || 0) + '%';
  objDailyOverallPackagerUtil.overallDTOperatorInitiatedPercent = (packUtil.operatorInitiatedDowntimePct || 0) + '%';
  objDailyOverallPackagerUtil.overallDTOtherPercent = (packUtil.otherDowntimePct || 0) + '%';

  // eslint-disable-next-line no-console
  console.log(JSON.stringify(objDailyOverallPackagerUtil));
  return objDailyOverallPackagerUtil;
}

export function SumInMilliSecs(pckgrUtilization) {
  const splitArr = pckgrUtilization.split(',');
  const numArr = splitArr.map(Number);
  let baseAmout = 0;
  numArr.forEach(element => {
    baseAmout = baseAmout + element;
  });

  return baseAmout;
}

export function customTimeFormat(milliseconds) {
  const time = msToTime(milliseconds);
  return time;
}

export function msToTime(milliseconds) {
  if (!milliseconds) {
    milliseconds = 0;
  }
  // Get hours from milliseconds
  const hours = milliseconds / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);
  const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  // Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

  return h + 'h' + ' ' + m + 'm';
}

export function calculateUtilInMins(pkgrUtil) {
  const hours = pkgrUtil / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  return (((absoluteHours) * 60) + absoluteMinutes);
}

export function calculatePercent(utilizedTime, overallTime) {
  return calculateUtilInMins(overallTime) === 0 ? 0
    : Math.round((calculateUtilInMins(utilizedTime) / calculateUtilInMins(overallTime)) * 100);
}


export function getDifferenceInTime(currentTimeStamp) {

  let timestamp = new Date(currentTimeStamp);
  timestamp = new Date(timestamp.getTime() + timestamp.getTimezoneOffset() * 60000);
  let today = new Date();
  today = new Date(today.getTime() + today.getTimezoneOffset() * 60000);

  const startDate = timestamp.getTime();
  const endDate = today.getTime();

  const diffInMin = ((endDate - startDate) / 1000 / 60);
  const newHr = Math.abs(diffInMin / 60);
  const hh = Math.floor(newHr);

  const minutes = Math.abs(diffInMin % 60);
  const mm = Math.floor(minutes);
  const m = mm > 9 ? mm : '0' + mm;

  const time = hh > 0 ? hh + 'h ' + m + 'm ' : m + 'm ';

  return time;
}

enum MachineTypes {
  ATP = 1,
  PASS,
  MAX,
  MINI,
}

type MachineTypesStrings = keyof typeof MachineTypes;

export function translateMachinePermissionToMachineTypeId(key: MachineTypesStrings) {
  return MachineTypes[key];
}

export function translateMachineTypeIdToMachinePermission(machineTypeId: number) {
  return MachineTypes[machineTypeId];
}
