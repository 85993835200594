export class InventoryStock {
    organizationId: string;
    organizationName: string;
    facilityId: string;
    machineTypeId: string;
    thresholdQty: number;
    currentStock: number;
    stockConsumed: number;
    preOrderStatus: boolean;
    inventoryStockId: string;
}
