import { PairEntity } from '../../models/customer-drug-info';
import { DrugQualifer, UpdateQualifierDrug, SynmedAnalyze } from '../../models/drug-qualifer';
import { Component, HostListener, Inject, OnInit, Optional, ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as constants from 'src/app/config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IMToolService } from '../../services/im-tool.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { AlertWizardComponent } from '../alert-wizard/alert-wizard.component';
import { ImportWizardImageComponent } from '../import-wizard-image/import-wizard-image.component';

@Component({
  selector: 'app-import-wizard-drug-qualifer',
  templateUrl: './import-wizard-drug-qualifier.component.html',
  styleUrls: ['./import-wizard-drug-qualifier.component.scss']
})
export class ImportWizardDrugQualifierComponent implements OnInit {
  //#region Pagination properties
  totalRows: number = 0;
  currentPage: number = 1;
  pageSize: number = 15;
  pageSizeOptions: number[] = [50, 100, 200, 500];
  customerId: string;
  subDeviceId:number;
  synmedAnalyzeData: SynmedAnalyze = {};
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  //#endregion
  defaultImage: string = 'iVBORw0KGgoAAAANSUhEUgAAAHkAAAB5CAMAAAAqJH57AAAAMFBMVEX///+/v7+9vb26urrl5eXHx8fw8PDz8/PNzc3q6urCwsL5+fn8/Pzi4uLa2trW1tZO13xoAAACCUlEQVRoge3Z23aDIBAFUBlUEJH8/9/WS1UQSuMwpCvNnLd2me4KByXaNBwOh8N549iaycGthIrpsrKoGJZZ/nsZJGXghqwVZSa4IWeOuZ9Bsswyyyx/qGzHMbuBqyVbZ+Y7zkO9XB7N+muA4dVyt99gZf9a+dyowC/NsxOtrMWZ/FQ7SNYQK1tzwpAdbiVlsgnVZash/cdJRnvMfNjNRZSpA2o3rF+PeFDKjd5XVa5gtluPMImO4WXbbVcSkZtlt/17sqWUm2bSxnQuN8n9PiyJCal7xzgXQDwjpHdJdWnSdLYw7hiprGUAqKODc8eiSaGUWxAB3XlLPu4YoTwus+rRzjvlxIcJ5QcEM9oHsIgWH508fEvg1h+tDuWoY3TyMasb7S6nHF3dyeTplBZaXV0Blw1CkTyc14fRf5YiXdBrkSRK5Pl2cIxgOKugo7GO9mslcguwXyAGGUvRcIcdK5GX9QvrgPs7lEyCjhXI7TqiZqHjIqcig44VyNt5wkyrp+ALgpeP7ZBRqT4lT9rvWMF3jOc0P0HHCHaAd+J1DC0/1+ZL/I5hZeTzX49ByhYxy0u8r7xIGfvI2+sYTsaesvA6hpMn9Bss6crkoUXnmOg3fSrFMsv/RO7wqzgRd+NNmaB81Q533g7WCcsss8wyyyyzzDLLLLP8eXLNmIw89lWTkTkcDofD+SlfIsYv/LXKcNQAAAAASUVORK5CYII='
  //#region Sorting Properties
  sortDir: string = "asc";
  sortColumnName: string = "";
  searchText: string = '';
  @HostListener("matSortChange", ["$event"])
  sortChange(event) {
    this.sortColumnName = event.active;
    this.sortDir = event.direction;
    this.getQualiferDrug('');
  }
  statusId: number = 0;
  //#endregion

  masIndexList: PairEntity[] = [];
  pipetteList: PairEntity[] = [];
  fragileList: PairEntity[] = [];
  toggleList: PairEntity[] = [];
  volcanicList: PairEntity[] = [];
  lidTypeList: PairEntity[] = [];
  dnuList: PairEntity[] = [];
  containerSizeList: PairEntity[] = [];
  lidHoleSizeList: PairEntity[] = [];


  updateDrugQualifierModel: UpdateQualifierDrug[] = []
  @ViewChild('TABLE', { static: false }) tableElement: ElementRef;
  constructor(
    private imtoolService: IMToolService,
    public dialogRef: MatDialogRef<ImportWizardDrugQualifierComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.customerId = data.customerId;
    this.subDeviceId = data.subDeviceId;
  }

  ngOnInit(): void {
    this.getQualiferDrug('');
    this.getAllDropdownData();
    this.lidTypeList.push({
      value:'S',
      label:'S'
    },
    {
      value:'C',
      label:'C'
    },
    {
      value:'W',
      label:'W'
    })
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getQualiferDrug('');
    this.currentPage = 1
  }
  TABLE: ElementRef
  onScroll(element) {
    const atBottom = element.target.scrollHeight < element.target.scrollTop + element.target.offsetHeight + 4
    if (atBottom) {
      this.getQualiferDrug(this.searchText)
      //this.currentPage = 0
    }
  }

  dataSource = new MatTableDataSource<DrugQualifer>([]);
  drugList: DrugQualifer[] = [];
  selectedDrugList: DrugQualifer[] = []

  displayedColumns: string[] = [
    'checkbox',
    'unitUsage',
    'rxQty',
    'customerDrugName',
    'cddbDrugName',
    'strength',
    'strengthUnitOfMeasure',
    'ndc',
    'gcn',
    'manufacturer',

   
  ];

  drugChecked(row: any, isChecked: boolean) {
    if (isChecked) {
      this.selectedDrugList.push({
        isChecked: true,
        customerDrugName: row.customerDrugName,
        cddbDrugName: row.cddbDrugName,
        ndc: row.nationalDrugCode,
        gcn: row.genericSequenceNumber,
        manufacturer: row.manufacturer,
        strength: row.strength,
        unitUsage: row.unitUsage,
        rank:row.rank,
        shapeFlag:row.shapeFlag,
        lidHoleType:row.lidHoleType,
        rxQty: row.rxQty,
        massIndex: row.massIndex,
        dispensingHeight: row.dispensingHeight,
        largeDropHeight: row.largeDropHeight,
        dnuMax: row.dnuMax,
        fragileFlag: row.fragileFlag,
        genericName: row.genericName,
        genericSequenceNumber: row.genericSequenceNumber,
        labelName: row.labelName,
        lidHoleSize: row.lidHoleSize,
        lidVolcanic: row.lidVolcanic,
        nationalDrugCode: row.nationalDrugCode,
        pipetteSize:row.pipetteSize,
        strengthUnitOfMeasure: row.strengthUnitOfMeasure,
        toggleFlag: row.toggleFlag,
        containerSize: row.containerSize,
        image: row.image
      });

    } else {
      let index = this.selectedDrugList.findIndex(
        (x) => x.ndc == row.ndc
      );
      this.selectedDrugList.splice(index, 1);
      if (this.selectedDrugList.length == 0) {
        // this.isAnyDrugSelected = false;
      }
    }
  }
  drugInfoChange = function (row) {
    let data = this.validationMessage(row)

    if (!data.value) {

      if (data.validationType === 'SMALL-DROP-HEIGHT')
        row.dispensingHeight = 1;
      if (data.validationType === 'LARGE-DROP-HEIGHT')
        row.largeDropHeight = 1;
      this.toastService.openToast(data.message, constants.ToastPanelType.warning, 2);
    }

    for (let i = 0; i < this.selectedDrugList.length; i++) {
      if (this.selectedDrugList[i].ndc == row.ndc) {
        this.selectedDrugList[i].isChecked = true;
        this.selectedDrugList[i].ndc = row.ndc;
        this.selectedDrugList[i].massIndex = row.massIndex;
        this.selectedDrugList[i].lidHoleSize = row.lidHoleSize;
        this.selectedDrugList[i].pipetteSize = row.pipetteSize;
        this.selectedDrugList[i].containerSize = row.containerSize;
        this.selectedDrugList[i].fragileFlag = row.fragileFlag;
        this.selectedDrugList[i].dispensingHeight = row.dispensingHeight;
        this.selectedDrugList[i].largeDropHeight = row.largeDropHeight;
        this.selectedDrugList[i].toggleFlag = row.toggleFlag;
        this.selectedDrugList[i].lidVolcanic = row.lidVolcanic;
        this.selectedDrugList[i].dnuMax = row.dnuMax;
        break;

      }
    }

  }
  getQualiferDrug(searchValue: string) {
    this.searchText = searchValue;
    if (searchValue.length > 0) {
      this.drugList = [];

    }
    const QUALIFIERDRUG_KEY = 'QUALIFIERDRUG_KEY'
    this.ngxLoader.start(QUALIFIERDRUG_KEY);
    this.imtoolService.getQualiferDrug(this.customerId, this.currentPage == 0 ? 1 : this.currentPage,
      this.pageSize, this.searchText, this.subDeviceId).subscribe(
        (response) => {
          let result = response.data
          this.dataSource.data = [];

          result?.drugs.forEach(element => {
            this.drugList.push({
              isChecked: false,
              customerDrugName: element.customerDrugName,
              cddbDrugName: element.cddbDrugName,
              ndc: element.nationalDrugCode,
              gcn: element.genericSequenceNumber,
              manufacturer: element.manufacturer,
              strength: element.strength,
              unitUsage: element.unitUsage,
              rank:element.rank,
              lidHoleType:element.lidHoleType,
              rxQty: element.rxQty,
              shapeFlag:element.shapeFlag,
              backgroundColor:element.shapeFlag == false? 'rgb(191, 191, 191)':'rgb(255,255,255)',
              massIndex: element.massIndex && element.massIndex > 0 ? element.massIndex.toString() : '0',
              dispensingHeight: element.dispensingHeight || element.dispensingHeight > 0 ? element.dispensingHeight : 0,
              largeDropHeight: element.largeDropHeight || element.largeDropHeight > 0 ? element.largeDropHeight : 0,
              dnuMax: !this.isNullOrUndefined(element.dnuMax) && element.dnuMax === true ? '1' : '0',
              fragileFlag: !this.isNullOrUndefined(element.fragileFlag) && element.fragileFlag === true ? 'Y' : 'N',
              genericName: element.genericName,
              genericSequenceNumber: element.genericSequenceNumber,
              labelName: element.labelName,
              lidHoleSize: !this.isNullOrUndefined(element.lidHoleSize) ? element.lidHoleSize.toString() : '',
              lidVolcanic: !this.isNullOrUndefined(element.lidVolcanic) && element.lidVolcanic === true ? 'Y' : 'N',
              //lidVolcanic: element.lidVolcanic?'True':'False',
              nationalDrugCode: element.nationalDrugCode,
              pipetteSize: !this.isNullOrUndefined(element.pipetteSize) && element.pipetteSize?.length > 0 ? element.pipetteSize.toString() : '3',
              strengthUnitOfMeasure: element.strengthUnitOfMeasure,
              //toggleFlag: element.toggleFlag === false || this.isNullOrUndefined(element.toggleFlag) ? 'N' : element.toggleFlag,
              toggleFlag: element.toggleFlag ? 'Y' : 'N',
              containerSize: !this.isNullOrUndefined(element.containerSize) && element.containerSize.length > 0 ? element.containerSize.toString() : 'Large',
              image: `data:image/png;base64,${element?.image == undefined || element?.image == null ? this.defaultImage : element?.image}`
            });

           
          });
          this.dataSource.data = this.drugList
          this.synmedAnalyzeData.nonCetritifedDrugs = result.totalRows;
          this.currentPage++;
          this.ngxLoader.stop(QUALIFIERDRUG_KEY);
        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop(QUALIFIERDRUG_KEY);
          console.log(error);
          this.toastService.openToast(
            error.error.message ?? error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

  getAllDropdownData() {
    const DROPDOWN_KEY = 'DROPDOWN_KEY'
    this.ngxLoader.start(DROPDOWN_KEY);
    this.imtoolService.getQualifierDrugDropdownData().subscribe(
      (response: any) => {
        let result = response.data
        this.masIndexList = result[0].pair;
        this.pipetteList = result[1].pair;
        this.fragileList = result[2].pair;
        this.toggleList = result[3].pair;
        this.dnuList = result[4].pair;
        this.volcanicList = result[5].pair;
        this.containerSizeList = result[6].pair;
        this.lidHoleSizeList = result[7].pair;
        this.ngxLoader.stop(DROPDOWN_KEY);
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(DROPDOWN_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  isNullOrUndefinedOrEmpty(value): boolean {
    return value === null || value === undefined || value === '';
  }
  isNullOrUndefined(value): boolean {
    return value === null || value === undefined;
  }

  validationMessage = function (row: DrugQualifer) {
    if (row.dispensingHeight > 10 || row.dispensingHeight < 0) {
      return { message: 'Cannot set value, Small Drop Height range: 0 - 10', value: false, validationType: 'SMALL-DROP-HEIGHT' }
    }
    else if (row.largeDropHeight > 10 || row.largeDropHeight < 0) {
      return { message: 'Cannot set value, Large Drop Height range: 0 - 10', value: false, validationType: 'LARGE-DROP-HEIGHT' }
    }
    else if (row.pipetteSize === '3' && row.containerSize === 'Small') {
      return { message: 'Large pipette and Small container size drug does not exist', value: false, validationType: 'CAN_PIP' }
    }
    // else if (row.ndc.length == 0)
    //   return { message: 'NDC empty', value: false };

    // else if (this.isNullOrUndefined(row.massIndex))
    //   return { message: 'Mass Index is missing', value: false }

    // else if (this.isNullOrUndefined(row.lidHoleSize))
    //   return { message: 'Lid Hole Size is missing', value: false }

    else if (this.isNullOrUndefinedOrEmpty(row.pipetteSize) && row.isChecked == true)
      return { message: 'Pipette Size is missing', value: false, validationType: 'PipetteSize' }

    else if (this.isNullOrUndefinedOrEmpty(row.containerSize) && row.isChecked == true)
      return { message: 'Container Size is missing', value: false, validationType: 'ContainerSize' }

    // else if (this.isNullOrUndefined(row.fragileFlag))
    //   return { message: 'Container Size is missing', value: false }

    // else if (this.isNullOrUndefined(row.pipetteSize))
    //   return { message: 'Pipette Size is missing', value: false }
    return { message: '', value: true };
  }


  updateDrug() {

    this.updateDrugQualifierModel = [];
    let validate: boolean = true;
    this.selectedDrugList.forEach(data => {
      let validationMessage = this.validationMessage(data)
      if (!validationMessage.value) {
        this.toastService.openToast(validationMessage.message, constants.ToastPanelType.warning, 2);
        validate = false;
        return;
      }
      else {
        this.updateDrugQualifierModel.push({
          ndc: data.ndc,
          massIndex: data.massIndex == null ? 0 : parseInt(data.massIndex),
          lidHole: data.lidHoleSize == null ? 0 : parseInt(data.lidHoleSize),
          pipette: data.pipetteSize,
          containerSize: data.containerSize,
          fragile: data.fragileFlag,
          dropHeight: data.dispensingHeight == null ? 0 : data.dispensingHeight,
          largeDropHeight: data.largeDropHeight == null ? 0 : data.largeDropHeight,
          toggle: data.toggleFlag,
          volcanic: data.lidVolcanic,
          dnu: data.dnuMax,

        })
      }

    })

    if (!validate) return;
    const QUALIFIERDRUGSAVE_KEY = 'QUALIFIERDRUGSAVE_KEY'
    this.ngxLoader.start(QUALIFIERDRUGSAVE_KEY);
    this.synmedAnalyzeData.nonCetritifedDrugs = this.synmedAnalyzeData.nonCetritifedDrugs - this.updateDrugQualifierModel.length;
    this.imtoolService.updateQualifierDrug(this.customerId, this.updateDrugQualifierModel).subscribe(
      (result) => {
        if (result.statusCode == 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          this.synmedAnalyzeData.largeLargeCount = result.data?.largeLargeCount;
          this.synmedAnalyzeData.largeEitherCount = result.data?.largeEitherCount;
          this.synmedAnalyzeData.largeSmallCount = result.data?.largeSmallCount;
          this.synmedAnalyzeData.bothLargeCount = result.data?.bothLargeCount;
          this.synmedAnalyzeData.bothEitherCount = result.data?.bothEitherCount;
          this.synmedAnalyzeData.bothSmallCount = result.data?.bothSmallCount;
          this.synmedAnalyzeData.smallLargeCount = result.data?.smallLargeCount;
          this.synmedAnalyzeData.smallEitherCount = result.data?.smallEitherCount;
          this.synmedAnalyzeData.smallSmallCount = result.data?.smallSmallCount;
          this.synmedAnalyzeData.fragileCount = result.data?.fragileCount;
          this.synmedAnalyzeData.schedule2DrugCount = result.data?.schedule2DrugCount;
          this.synmedAnalyzeData.toggleCount = result.data?.toggleCount;
          this.closeDialog(this.synmedAnalyzeData)
          this.ngxLoader.stop(QUALIFIERDRUGSAVE_KEY);
        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
          this.ngxLoader.stop(QUALIFIERDRUGSAVE_KEY);
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop(QUALIFIERDRUGSAVE_KEY);
        console.log(error);
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
  }

  saveButtonClick() {
    // if(this.selectedDrugList.length == 0 ){
    //   this.toastService.openToast(
    //     "Please select Drug!",
    //     constants.ToastPanelType.error,
    //     2
    //   );
    //   return;
    // }
    this.openAlertWindow()
  }
  openImageWindow(image): any {
    const dialogRef = this.dialog.open(ImportWizardImageComponent, {
      backdropClass: ['mdDialog'],
      disableClose: true,
      data: {
        image: image
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      
    });
  }

  openAlertWindow(): any {
    const dialogRef = this.dialog.open(AlertWizardComponent, {
      backdropClass: ['smDialog'],
      disableClose: true,
      data: {
        alertIcon: "/assets/img/icons/ico.16.warning.svg",
        title: "Warning",
        bodyMessage: "Are you sure you want to update the drugs?",
        bodyMessage2: "",
        cancelBtnText: "Go Back",
        confirmBtnText: "Continue",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.updateDrug();
      } else {
        console.log("Cancle", result);
      }
    });
  }
  closeDialog(response: SynmedAnalyze) {
    this.dialogRef.close({ event: 'close', data: response })
  }

}
