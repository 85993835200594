import { PassDeviceType } from "../enums/device-type";
import { ColumnName } from "../models/column-name";

export class ImToolData {
    static devices = [
      { id: 1, deviceName: 'Max 2', isActive: true },
      { id: 2, deviceName: 'Pass', isActive: true },
      { id: 3, deviceName: 'ATP', isActive: true },
      { id: 4, deviceName: 'SynMed', isActive: true },
      { id: 5, deviceName: 'Mini', isActive: true }
    ];
    static dayOfUsage = [
        { key: '7', value: '7' },
        { key: '14', value: '14' },
        { key: '21', value: '21' },
        { key: '30', value: '30' },
        { key: '45', value: '45' },
        { key: '60', value: '60' },
        { key: '90', value: '90' },
        { key: '120', value: '120' },
        { key: '180', value: '180' },
        { key: '240', value: '240' },
        { key: '300', value: '300' },
        { key: '365', value: '365' }
    ]
    static columnList:ColumnName[]  = [
      { name: "No Usage Data Provided" },
      { name: "No Super cell" },
      { name: "Alphabetical(max only)" },
      { name: "No Locking cell" }
    ];
    static daysPerWeek=[  
      { key: 1, value: '1' },
      { key: 2, value: '2' },
      { key: 3, value: '3' },
      { key: 4, value: '4' },
      { key: 5, value: '5' },
      { key: 6, value: '6' },
      { key: 7, value: '7' }]
    static datasource = [
      {key:5, value:'Medi-Span (USA)', description:'Preferred Data source for all Products in the US'},
      {key:1, value:'FDB (USA)', description:'Secondary Data source option for USA Drug data, only select this option with manager approval'}
      
    ]

    static deviceTypeLimits = {
      [PassDeviceType.Type18]: { min: 0, max: 4 },
      [PassDeviceType.Type19]: { min: 0, max: 4 },
      [PassDeviceType.Type20]: { min: 1, max: 6 },
      [PassDeviceType.Type21]: { min: 1, max: 8 },
    };
  }

export class ImToolFirstScreenData{
  static ndcLabels = [
    { id: 1, title: 'NDC 11' },
    { id: 2, title: 'NDC 10 Hyphenated'},
  ];
}