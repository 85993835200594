<div class="container p-t-15 p-b-15 xs:flex xs:flex-col flex flex-row flex-wrap">
    <!-- Order Modify -->
    <div class="basis-full">
        <h2 class="m-b-0">Consumable Order Configurations</h2>
        <small>Please answer the following questions To adjust your Consumable Order</small>
    </div>
    <!-- Order details -->
    <form [formGroup]='formGroup' class="sm:flex-1 basis-[65%]">
        <h4 class="m-b-0">What Should we Send?</h4>
        <small>Consumables</small>
        <mat-card class="m-b-20">
            <div class="p-15">
                <table mat-table [dataSource]="productDataSource" class="consumableorder" 
                    formArrayName='orderFormControls'>
                    <!-- Product name like Papaer,Ribbon -->
                    <ng-container matColumnDef="productName">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let productRow" class="co_title"> {{productRow.name}} </td>
                    </ng-container>
                    <!-- Part number & description -->
                    <ng-container matColumnDef="partNumber">
                        <th mat-header-cell *matHeaderCellDef> Part Number </th>
                        <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Part Number" 
                            [formGroupName]='rowIndex'>
                            <mat-form-field floatLabel="never">
                                <mat-label>Select a Part Number</mat-label>
                                <mat-select formControlName='orderSubProduct' 
                                    (selectionChange)='subProductSelectionChanged(rowIndex)'>
                                    <mat-option *ngFor="let subProduct of productRow.subProducts" 
                                        [value]="subProduct.subProductId">
                                        {{subProduct.partNo + subProduct.description }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <!-- Packaging size of each pouch -->
                    <ng-container matColumnDef="packageSize">
                        <th mat-header-cell *matHeaderCellDef> Packaging Size </th>
                        <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Packaging Size" 
                            [formGroupName]='rowIndex'>
                            <mat-form-field floatLabel="never">
                                <mat-label>Select a Package Size</mat-label>
                                <mat-select formControlName='orderPackageSize'
                                    [disabled]='disablePackageSizeDropwDown(rowIndex)'>
                                    <mat-option *ngFor="let packsize of productRow.packageSizes" 
                                        [value]="packsize.packageSizeId">
                                        {{packsize.packageSize}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <!-- Quantity -->
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef> Quantity </th>
                        <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Quantity" 
                            [formGroupName]='rowIndex'>
                            <mat-form-field floatLabel="never">
                                <input matInput type="number" formControlName='orderQuantity' min="0" max="100">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: orderTableColumns;"></tr>
                </table>
            </div>
        </mat-card>

        <h4 class="m-b-0">When should we send it?</h4>
        <small>Consumables remaining before we send the next order</small>
        <div class="flex flex-row">
            <mat-form-field floatLabel="never" class="basis-2/5">
                <label>Pouches</label>
                <input matInput type="number" formControlName='pouchesCount' min="0" max="1000000" 
                    class="numeric_remove_arrows">
            </mat-form-field>
        </div>
        <div class="flex justify-between content-center items-center flex-row">
            <div class="xs:basis-[58%] basis-2/5">
                <mat-form-field floatLabel="never">
                    <label>Delay Order</label>
                    <input matInput [matDatepicker]="dp3" [min]="minDate" [max]='maxDate'
                        formControlName='delayOrderDate'>
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="flex flex-row justify-end content-stretch items-stretch h-full xs:basis-[42%] basis-[30%]">
                <button class="btn-primary" [class.btn-disabled]="disableSaveChanges()" 
                    [disabled]="disableSaveChanges()" (click)=saveChanges()>
                    Save Changes
                </button>
            </div>
        </div>
    </form>
</div>
