<div class="pp_report">

    <h6 class="flex justify-between content-center items-center flex-row">
        All Systems - Packager Downtime
        <a routerLink="/reports/774c17c7-5923-41fa-9ea0-4f0bdebb8af7" id="report_downtime_allsystem" class="btn-link" 
            [attr.aria-label]="'All Systems - Packager Downtime Report'">
            Report >
        </a>
    </h6>

    <div *ngIf="noDataExistsForDT === false" class="flex justify-between content-stretch items-stretch flex-col gap-px">
        <div class="basis-full">
            <div class="rcount m-t-8">
                <span id="OverallDownTime" class="time">{{ dailyPackagerUtilization.overallDownTime }}</span>
                <span id="OverallDownTimeTitle" class="rcountdesc details"> Total </span>
                <custom-tooltip text="Total combined system downtime since 12:00am"></custom-tooltip>
            </div>
        </div>
        <div class="basis-full">
            <div class="pp_report_det flex justify-between content-stretch items-stretch flex-row gap-px">
                <div #todayOverview class="grow-0 shrink-0 basis-[0%]">
                    <div class="rcountdesc"></div>
                    <div id="overallDownTime" class="rcount"></div>
                    <ng-container *ngFor="let load of dailyPackagerDowntime">
                        <div class="rcount dtContainer">
                            <div class="rcountdesc"></div>
                            <span class="time" id="{{ load.id }}">{{ load.valueStr }} </span>
                            <span class="rcountdesc  loadDesc">
                                <span id="{{ load.id + 'Percent' }}" class="percent">
                                    {{ load.percent }}
                                </span>
                                <span class="label" id="{{ load.label }}">
                                    {{ ( load.label && load.label.length > (13 + 1)) ? ((load.label).slice(0,
                                        13) + '.') : load.label}}</span>
                            </span>
                        </div>
                    </ng-container>
                </div>

                <div class="flex-1">
                    <app-overall-bar-chart [ref]="todayOverviewRef" [data]="dailyPackagerDowntime">
                    </app-overall-bar-chart>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noDataExistsForDT === true">
        <p class="pp_report">
            <span class="rcountdesc">No data exists</span>
        </p>
    </div>

</div>
<hr>