import { Component, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { IMToolService } from '../../services/im-tool.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CddbDrugs } from '../../models/cddb-drugs';

@Component({
  selector: 'app-import-wizard-dcr-drug',
  templateUrl: './import-wizard-dcr-drug.component.html',
  styleUrls: ['./import-wizard-dcr-drug.component.scss']
})
export class ImportWizardDcrDrugComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  customerId:string = "";
  deviceId:number;
  searchText:string="";
  dataSource =new MatTableDataSource<CddbDrugs>();
  selectedRow:any;
  isAnyDrugSelected:boolean= false;


  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth > 900) {
    }
  }
  constructor(private imtoolService: IMToolService,
    public dialogRef: MatDialogRef<ImportWizardDcrDrugComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.customerId = data.customerId;
      this.deviceId = data.deviceId;
     }
 //#region Table colums Array
 displayedColumns: string[] = [
  "checkbox",
  "sl",
  "ndc",
  "name",
  "strength",
  "manufacturer",
];
//#endregion
//#region Pagination properties
totalRows: number = 0;
currentPage: number = 0;
pageSize: number = 50;
pageSizeOptions: number[] = [50, 100, 200, 500];

pageChanged(event: PageEvent) {
  this.pageSize = event.pageSize;
  this.currentPage = event.pageIndex;
}
//#endregion

//#region Sorting Properties
sortDir:string="asc";
sortColumnName:string="";
@HostListener("matSortChange", ["$event"])
sortChange(event) {
  this.sortColumnName = event.active;
  this.sortDir = event.direction;
}

//#endregion
  ngOnInit(): void {
  }

  closeDialog(response:boolean){
    this.dialogRef.close({event:'close', data:response })
  }

  getDcrDrug(value:any){

  }

  

}
