import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { getHourlyPouchCountAllSystem } from 'src/app/store/selectors/pharmacy-peek.selector';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { ViewStoreService } from '../../../services/view-type.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastPanelType } from 'src/app/config/app-constants';

@Component({
  selector: 'app-hourly-pouch-count-all-system',
  templateUrl: './hourly-pouch-count-all-system.component.html',
  styleUrls: ['./hourly-pouch-count-all-system.component.scss']
})

export class HourlyPouchCountAllSystemComponent implements OnInit, AfterViewInit, OnDestroy {

  avgHoursToday: number | string;
  avgHoursTodayHidden: number;
  totalToday: number | string;
  totalTodayHidden: number;
  hourCountHidden: [];
  pouchCountDataToday: any[] = [];
  noDataExistsForHPC = true;
  todayDay = '';
  resData: any;
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.HPC_SITE,
    deviceId: ''
  }

  constructor(
    public store: Store<State>,
    private signal: SignalRService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {

    this.store.select(getHourlyPouchCountAllSystem).subscribe(res => {
      this.resData = res;
      if (this.resData && this.resData?.data && Object.keys(this.resData)) {
        this.updateChartFromAPIData(this.resData);
        this.noDataExistsForHPC = false;
      }
    });

    this.subscription = this.signal.getDataStream<string>(SignalEventType.HPC_SITE)
    .pipe(auditTime(1000))
    .subscribe((dataObj) => {
      this.store.dispatch(PharmacyPeekActions.updateHourlyPouchCountAllSystem({ data: dataObj.data }));
      console.log(dataObj);
    })

    this.callHPCAllSystem();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  private callHPCAllSystem() {
    this.viewStoreService.getHourlyPouchCountAllSystem().subscribe(
      (response) => {
        this.store.dispatch(PharmacyPeekActions.getHourlyPouchCountAllSystem({ data: response }));
      },
      (error) => {
        console.log('HourlyPouchCount AllSystems Service error:', error);

        if (error.status === 404) {
          this.loggerService.logWarning('HourlyPouchCount AllSystems No data exists: ', error);
        } else {
          this.toastService.openToast('HourlyPouchCount AllSystems service load error!', ToastPanelType.error);
        }
      },
    );
  }

  private updateChartFromAPIData(data) {
    this.pouchCountDataToday = [...Array(24)].map((v, i) => {
      const time = `${(i % 12) || 12}${i < 12 ? 'a' : 'p'}`;
      const count = (i >= 0) ? data.data.hourCounts[i] : 0;
      return {
        time,
        count,
        max: Math.ceil(Math.max(...data.data.hourCounts) / 100) * 100,
        tooltip: `<b>${count}</b>&nbsp;pouch${count >= 2 ? 'es' : ''} – ${i % 12 || 12} ${i < 12 ? 'AM' : 'PM'}`,
      };
    });

    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date();
    const day = weekday[d.getDay()];
    this.todayDay = day + ' Avg';
    this.avgHoursToday = this.kFormatter(data.data.average);
    this.avgHoursTodayHidden = data.data.average;
    this.totalToday = this.kFormatter(data.data.total);
    this.totalTodayHidden = data.data.total;
    this.hourCountHidden = data.data.hourCounts.toString();
  }

  
  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10) + 'k' : Math.sign(num) * Math.abs(num)
  }
}
