import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';
import QueueData from 'src/app/external-user/batch-manager/models/script-manager-data';

export interface State {
  Vial: any,
  Pouch: any,
}

export const initialState: State = {
  Vial: null,
  Pouch: null,
};

export const scriptsReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.ScriptsActions.setScriptData, (state, action) => ({
    ...state,
    Vial: setDataFormat(),
    Pouch: setPouchDataFormat()
  })
  ),

  on(AllActions.ScriptsActions.updateInventoryMaxData, (state, action) => ({
    ...state,
    Vial: updateInventory(state.Vial, action),
  })
  ),

  on(AllActions.ScriptsActions.updateInventoryPouchData, (state, action) => ({
    ...state,
    Pouch: updatePouch(state.Pouch, action.data)
  })
  ),
);

export function reducer(state: State | undefined, action: Action): any {
  return scriptsReducer(state, action);
}

export function setDataFormat(){
    let queueData = [];
    queueData.push({ name: 'INCOMPLETE', isExpanded: true, inventories: null });
    queueData.push({ name: 'IN PROCESS', isExpanded: true, inventories: null });
    queueData.push({ name: 'IN QUEUE', isExpanded: true, inventories: null });
    queueData.push({ name: 'COMPLETE', isExpanded: true, inventories: null });
    return queueData;
}

export function setPouchDataFormat(){
  let queueData = [];
  queueData.push({ name: 'IN PROCESS', isExpanded: true, inventory: null });
  queueData.push({ name: 'IN QUEUE', isExpanded: true, inventory: null });
  queueData.push({ name: 'COMPLETE', isExpanded: true, inventory: null });
  return queueData;
}

export function updateInventory(storeData: Array<QueueData>, newData) {

  const sData = Object.values(storeData);
  const newArr = [];
  const updatedInv = sData.find(x => x.name === newData.data.type);
  updatedInv.inventories = newData.data.inventory;

  sData.forEach(e => {
    const index = sData.indexOf(e);
    if (e.name === newData.data.type) {
      sData.splice(index, 1);
    }
  });

  if (sData.length > 0) {
    sData.forEach(e => {
      newArr.push(e);
    });
  }

  if (updatedInv) {
    newArr.push(updatedInv);
  }

  return newArr;
}

export function updatePouch(storeData: any, newData) {

  const sData = storeData;
  const newArr = [];
  const updatedInv = sData.find(x => x.name === newData.type);
  updatedInv.inventory = newData.inventory;

  sData.forEach(e => {
    const index = sData.indexOf(e);
    if (e.name === newData.type) {
      sData.splice(index, 1);
    }
  });

  if (sData.length > 0) {
    sData.forEach(e => {
      newArr.push(e);
    });
  }

  if (updatedInv) {
    newArr.push(updatedInv);
  }

  return newArr;
}
