import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { DropDownTwo } from "src/app/internal-user/drug-management/models/dropdown";
import { MoveDrug } from "../../models/move-drugs";
import { IMToolService } from "../../services/im-tool.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: 'app-import-wizard-drug-moving',
  templateUrl: './import-wizard-drug-moving.component.html',
  styleUrls: ['./import-wizard-drug-moving.component.scss']
})
export class ImportWizardDrugMovingComponent implements OnInit {
  @ViewChild('dialogContent', { static: false }) dialogContent!: ElementRef;
  listOfData: DropDownTwo[] = [ ];
  title:string;
  customerId:string;
  deviceId:number;
  moveDrug:MoveDrug = {} as MoveDrug;
  drugs:number[] = [];
  selectedTab:number = 0;
  constructor(
    private imtoolService: IMToolService,
    public dialogRefMoving: MatDialogRef<ImportWizardDrugMovingComponent>,
    public dialogMoving: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any) {
      this.title = data.title;
      this.listOfData = data.listOfData;
    }

  ngOnInit(): void {
  }

  onUpload() {
    if(this.selectedTab === 0)
      {
        this.dialogRefMoving.close({event:'close', data:{isClose: false, selectedTab: this.selectedTab} })
      }
    else{
      this.dialogRefMoving.close({event:'close', data:{isClose: true, selectedTab: this.selectedTab} })
    }
  }

}
