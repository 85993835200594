import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { KeyValueData } from '../models/keyvalue-data';
import { Observable } from 'rxjs';
import { Notification } from '../models/notification';

// handles popup and email notification
@Injectable({
    providedIn: 'root'
})
export class NotificationService extends DataService<Notification> {
    httpClient: HttpClient;
    constructor(http: HttpClient) {
        super(environment.apiUrl.notificationService, http);
        this.httpClient = http;
    }
    getNotificationsByType(id: number): Observable<Notification[]> {
        return this.httpClient.get<Notification[]>(`${environment.apiUrl.notificationService}user/${id}`);
    }

    deleteNotificationsById(id: string): Observable<Notification[]> {
        return this.httpClient.delete<Notification[]>(`${environment.apiUrl.notificationService}${id}`);
    }
}
