<h2 mat-dialog-title class="m-b-0 !flex !flex-row xs:!hidden">
    <div class="uppercase basis-2/5" tabindex="0" aria-label="Pharmacy Peek Management">
        Pharmacy Peek Management
    </div>
    <div class="caps flex-1" tabindex="0" aria-label="Pharmacy Peek Widget Search">
        <div class="pharmacysearch">
            <app-search-bar placeholder="Search by Widget Title" inputId="widget-title-search" (filterChange)="filterWidget($event)" class="grow-0 shrink basis-[300px] xs:basis-full"></app-search-bar>
        </div>
    </div>
    <div class="flex flex-row justify-end content-center items-center h-full basis-[5%]">
        <button class="btn-transparent btn_icon" type="button" aria-label="close" (click)="openPharmacyPeekDiscardChangesDialog()" (keypress.enter)="openPharmacyPeekDiscardChangesDialog()"> <!-- mat-dialog-close -->
            <img src="../../../../../assets/img/icons/ico.16.clear.svg" alt="">
        </button>
    </div>
</h2>

<div class="flex flex-row xs:hidden">
    <div class="sm:basis-[65%] md:basis-[70%] lg:basis-[75%] xl:basis-[75%]">
        <app-pharmacy-peek-widget ></app-pharmacy-peek-widget>  
    </div>
    <div class="pharmacy_list sm:basis-[35%] md:basis-[30%] lg:basis-[25%] xl:basis-[25%]">
        <app-pharmacy-peek-list ></app-pharmacy-peek-list>
    </div>
</div>

