import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { View } from '../inventory-views/inventory-views.component';
import { availableColumns } from '../inventory-views-table-rpt/inventory-views-table-rpt.component';
import { MatSort } from '@angular/material/sort';
import { InventoryViewsData } from '../../../models/inventory-view-data';

@Component({
  selector: 'app-inventory-views-mobile-table',
  templateUrl: './inventory-views-mobile-table.component.html',
  styleUrls: ['./inventory-views-mobile-table.component.scss'],
})
export class InventoryViewsMobileTableComponent implements OnInit, OnChanges {

  get inverseOfTranslation(): string {
    return `-${this.viewport.getOffsetToRenderedContentStart()}px`;
  }
  @Input() inventories: InventoryViewsData[];
  @Input() filterText;
  @Input() loading;
  @Input() view: View;
  @Input() dateRange;
  @Output() loadInventories: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('inventoryTable', { static: false }) private inventoryTableRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  View = View;
  expandedElement: any;
  columns = [];
  openingRow: number = null;
  colHeight = 118;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateColumnHeight();
  }

  ngOnInit()  {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view) {
      if (this.view === View.SYSTEM) {
        this.columns = availableColumns.filter(({ field }) => !['arrowexp', 'actions'].includes(field));
      } else {
        this.columns = availableColumns.filter(({ field }) => !['arrowexp', 'actions', 'canisterLocation'].includes(field));
      }
    }
  }

  private updateColumnHeight() {
    this.colHeight = window.innerWidth < 600 ? 162 : 118;
  }
}
