import { Component, Input } from '@angular/core';
import { InputType, UserInputPropertySource } from '../../models/deploymentForm.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Represents a dynamic control that is driven by server metadata.
 * Creates one of many Material UI controls based on the metadata's input type and binds it to the associated form control.
 */
@Component({
  selector: 'app-dynamic-deployment-control',
  templateUrl: './dynamic-deployment-control.component.html',
  styleUrls: ['./dynamic-deployment-control.component.scss']
})
export class DynamicDeploymentControlComponent {
  @Input() organizationId: string;
  @Input() controlMetadata: UserInputPropertySource;
  @Input() form: FormGroup;

  /** Wrapper to expose InputType to HTML template. */
  public get InputType(): typeof InputType { return InputType; }

  /** The current form control instance queried from the parent form group. */
  get formControl(): FormControl { return this.form.get(this.controlMetadata.name) as FormControl; }

  /** Returns true if the required validator was added to the control. */
  get required(): boolean { return this.formControl.hasValidator(Validators.required); }
}
