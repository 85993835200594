import { animate, state, style, transition, trigger } from '@angular/animations';

export const ExpandedLTR = trigger('ExpandedLTR', [
    state('no', style({ transform: 'rotate(-90deg)' })),
    state('yes', style({ transform: 'rotate(0deg)', })),

    transition('no => yes', animate(200)),
    transition('yes => no', animate(200))
]);

export const ExpandedRTL = trigger('ExpandedRTL', [
    state('no', style({ transform: 'rotate(90deg)' })),
    state('yes', style({ transform: 'rotate(0deg)', })),

    transition('no => yes', animate(200)),
    transition('yes => no', animate(200))
]);
