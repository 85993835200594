import { createAction, props } from '@ngrx/store';

export const GET_IM_TOOL = 'Im-Tool';


export const getImTool = createAction(
  GET_IM_TOOL,
  props<{data: any}>()
);

