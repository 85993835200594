import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { State } from 'src/app/store/state';
import { IMToolService } from '../services/im-tool.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';

@Component({
  selector: 'app-im-tool',
  templateUrl: './im-tool.component.html',
  styleUrls: ['./im-tool.component.scss'],
  animations: [
        trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
export class IMToolComponent implements OnInit {

  imTool = false;

  constructor(
    public imToolService: IMToolService,
    public auth: AuthService,
    public toastService: ToastService,
    public store: Store<State>,
    public dialog: MatDialog,
  ) { }

 
  ngOnInit() {
    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.imTool = flags?.Menu_IMTool;
      }
    }
    );
  
  }

    }
