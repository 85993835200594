import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrugManagementComponent } from './components/drug-management.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DrugManagementRoutingModule } from './drug-management-routing.module';
import {  SynmedFileuploadComponent} from './components/synmed-fileupload/synmed-fileupload.component';
import { SnapcanisterFileuploadComponent } from './components/snapcanister-fileupload/snapcanister-fileupload.component';
import { PetmedFileuploadComponent } from './components/petmed-fileupload/petmed-fileupload.component';
import { DrugListComponent } from './components/drug-list/drug-list.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DrugDetailsComponent } from './components/drug-details/drug-details.component';
import { ImportWizardDrugDetailsComponent } from './components/import-wizard-drug-details/import-wizard-drug-details.component';
import { ImportWizardAddNewDrugComponent } from './components/import-wizard-add-new-drug/import-wizard-add-new-drug.component'; 
import { ImportWizardInventoryComponent } from './components/import-wizard-inventory/import-wizard-inventory.component';
import { ImportWizardImageSlideComponent } from './components/import-wizard-image-slide/import-wizard-image-slide.component';
import { CddbAutomationScriptComponent } from './components/cddb-automation-script/cddb-automation-script.component';
import { QuarantinedDrugListComponent } from './components/quarantined-drug-list/quarantined-drug-list.component';
import { SearchDrugListComponent } from './components/search-drug-list/search-drug-list.component';
import { ImportWizardQuarantinedDrugListComponent } from './components/import-wizard-quarantined-drug-list/import-wizard-quarantined-drug-list.component';
import { ImportWizardSearchDrugComponent } from './components/import-wizard-search-drug/import-wizard-search-drug.component';
import { ImportWizardAlertComponent } from './components/import-wizard-alert/import-wizard-alert.component';


@NgModule({
  declarations: [
    DrugManagementComponent,
    SynmedFileuploadComponent, 
    SnapcanisterFileuploadComponent, 
    PetmedFileuploadComponent, 
    DrugListComponent, 
    FileUploadComponent, 
    DrugDetailsComponent, 
    ImportWizardDrugDetailsComponent, 
    ImportWizardAddNewDrugComponent, 
    ImportWizardInventoryComponent, 
    ImportWizardImageSlideComponent, 
    CddbAutomationScriptComponent, 
    QuarantinedDrugListComponent,
    SearchDrugListComponent, 
    ImportWizardQuarantinedDrugListComponent, 
    ImportWizardSearchDrugComponent,
  ImportWizardAlertComponent],

  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ScrollingModule,
    CdkTableModule,
    MatSortModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,    
    DrugManagementRoutingModule,

  ],
})
export class DrugManagementModule { }


