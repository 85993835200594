
<div #dialogContent>
    <h1 mat-dialog-title class="title-text-red">
        Transferring Drug Data Between Tabs
        <button class="close" (click)="this.dialogRefMoving.close({event:'close', daa:false })"></button>
    </h1>
    <mat-dialog-content class="body-messge">
      <label for="Tab Name" class="basis-[31%]">Tab Name</label>
      <mat-form-field floatLabel="never" class="basis-[30%]">

        <mat-select style="margin-top: 0rem !important;" matInput [value]="0" 
          [(ngModel)]="selectedTab">
            <mat-option  [value]="0">Select Tab</mat-option>
            <mat-option [disabled]="type.isSelected" *ngFor="let type of listOfData" [value]="type.key">{{type.value}}</mat-option>
        </mat-select>
    </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="flex justify-end content-end items-end">

            <button  mat-button class="btn-light-blue btn-md" (click)="dialogRefMoving.close({event:'close', data:{isClose: false, selectedTab: this.selectedTab} })">Cancel</button>
            <button [disabled]="selectedTab===0"  mat-button class="btn-light-red btn-md" [mat-dialog-close]="" (click)="onUpload()">Move</button>


    </mat-dialog-actions>
</div>
