import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumableOrderConfigComponent } from './components/consumable-order-config/consumable-order-config.component';
import { ConsumableOrderModifyComponent } from './components/consumable-order-modify/consumable-order-modify.component';
import {ConsumableOrderComponent}from '../order/components/consumable-order-setup/consumable-order.component';
import { OrderRoutingModule } from './order-routing.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    ConsumableOrderConfigComponent,
    ConsumableOrderModifyComponent,
    ConsumableOrderComponent
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    SharedModule,
  ],
  exports: [
    ConsumableOrderComponent
  ]
})
export class OrderModule { }
