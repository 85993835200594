<div tabindex="0" role="alert" aria-describedby="alertText" class="flex justify-start content-start items-start flex-row">
    <div class="flex-1">
        <mat-icon class="flex-1">{{data.panelType}}</mat-icon>
    </div>
    <div id="alertText" class="basis-[98%]">
        <span [innerHTML]="data.message" id="{{data.message}}" class="m-l-8 m-r-10 flex flex-col" attr.aria-valuenow="{{data.message}}"></span>
    </div>
    <div class="flex-1">
        <button class="btn-transparent btn_icon m-t-3" (click)="close()" id="close" aria-label="Close button" type="button">
            <span class="svgicon icon_clear" aria-hidden="true"></span>
        </button>
    </div>
</div>
