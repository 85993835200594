import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { State } from '../reducers/pharmacy-peek-widgets.reducer';
import { getMachinePermissions } from './assets.selector';

export const getPharmacyPeekWidgets = createFeatureSelector<State>('pharmacyPeekWidgets');

export const getWidgetList = createSelector(
  getPharmacyPeekWidgets,
  getMachinePermissions,
  (state: State, assets) => getFilteredWidgets(state.widgetsList, assets)
);

export const getSelectedWidgetsList = createSelector(
  getPharmacyPeekWidgets,
  (state: State) => {
    const newState = state.selectedWidgetsList?.slice();
    return newState;
  }
);

export const getSaveWidgetsList = createSelector(
  getPharmacyPeekWidgets,
  (state: State) => {
    const newState = state.saveWidgetsList?.slice();
    return newState;
  }
);

export function getFilteredWidgets(list, assets) {
  const listData = list;
  const filtered: any = [];

  if (listData && listData.length != undefined) {
    listData.forEach(element => {
      if (assets) {
        const pgAsset = assets.filter(x => element?.machinePermissions.includes(x));
        if (pgAsset[0] !== undefined && pgAsset[0] != null) {
          filtered.push(element);
        }
      }
    });
  }
  return filtered;
}
