import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'user-module',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'stratus-setup-module',
    loadChildren: () => import('./customer-survey/customer-survey.module').then(m => m.CustomerSurveyModule)
  },
  {
    path: 'permission-groups-module',
    loadChildren: () => import('./permission-groups/permission-groups.module').then(m => m.PermissionGroupsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommonRoutingModule { }
