<div class="flex justify-start content-stretch items-stretch flex-row">
  <div appDragDrop class="fileuploaddiv" (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" 
      title="please select only xls and csv files" 
      accept=".xlsx, .xls, .csv" />
    <div class="flex justify-start content-center items-center flex-row">
        <div>
            <img src="../../../../assets/img/addfile.png" alt="">
        </div>
        <div>
            <p>Drop file here or</p>
            <label for="fileDropRef">Select a file</label>
        </div>
    </div>
  </div>
</div>
<div class="flex justify-start content-stretch items-stretch flex-row">
  <div class="single_file mat-tool-menu" *ngFor="let file of SelectedFiles; let arryIndex = index">
    <p class="name">
      {{ file?.fileName }}
    </p>
    <span class="remove">
      <button mat-button (click)="deleteFile(arryIndex)"> X Remove File</button>
    </span>
  </div>
</div>