import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Organization } from '../models/organization';
import { SiteWithAdminUser } from '../models/org-with-admin-user';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends DataService<Organization> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}organizations/`, http);
    this.httpClient = http;
  }

  getByID(id) {
    return super.getByID(id, `${this.url}ID/`);
  }

  getActiveOrganizations(): Observable<Organization[]> {
    return this.getAll(`${this.url}Active`);
  }

  getAllOrganizationswithParent(): Observable<Organization[]> {
    return this.getAll(`${this.url}AllOrgWithParent`);
  }

  getOrganizationByName(name){
    return super.getByID(name, `${this.url}Name/`);
  }

  getActiveOrganizationswithParent(): Observable<Organization[]> {
    return this.getAll(`${this.url}ActiveOrgwithParent`);
  }

  getParentOrganizations(): Observable<Organization[]> {
    return this.getAll(`${this.url}Parent`);
  }

  getSites(): Observable<Organization[]> {
    return this.getAll(`${this.url}Sites/All`);
  }

  saveOrganization(organization: Organization): Observable<any> {
    return this.httpClient.post(`${this.url}`, organization);
  }

  saveSiteWithAdminUser(siteWithAdminUser: SiteWithAdminUser): Observable<any> {
    return this.httpClient.post(`${this.url}SiteWithAdminUser`, siteWithAdminUser);
  }

  getSitesByParentOrgId(parentOrgId: string): Observable<Organization[]>{
    return this.httpClient.get<Organization[]>(`${this.url}Sites/${parentOrgId}`);
  }

}
