import { KeyValue } from '@angular/common';
import { Component, Inject, Input, Pipe, PipeTransform } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { QueuePriorityEnum } from 'src/app/external-user/batch-manager/models/pending-script-priority-enum';
import {  ScriptManagerData } from 'src/app/external-user/batch-manager/models/script-manager-data';


@Component({
  selector: 'app-incomplete-table-component',
  templateUrl: './incomplete-status-table.component.html',
  styleUrls: ['./incomplete-status-table.component.scss']
})
export class IncompleteTableComponent {

  priorityEnum= QueuePriorityEnum;
  @Input() row: ScriptManagerData;

  constructor() {
    }


}
