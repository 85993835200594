import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService extends DataService<any> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.salesforceService}`, http);
    this.httpClient = http;
  }

  getOrgDetails(isParent: boolean): Observable<any> {
    return this.httpClient.get<any[]>(`${this.url}GetOrgDetails/${isParent}`);
  }

  getParentOrgDetails(): Observable<any> {
    return this.httpClient.get<any[]>(`${this.url}GetParentOrgDetails`);
  }

  getOrgByID(id) {
    return super.getByID(id, `${this.url}GetOrgById/`);
  }

  getAssetByOrgId(id)
  {
    return super.getByID(id, `${this.url}GetAssetByOrgId/`);
  }

  getSitesByNameOrCustomerNo(searchValue): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'GetSiteByNameCustNo' + '/' + searchValue)
      .pipe(
        catchError(error => {          
          return of([]);
        })
      );
  }
  
}
