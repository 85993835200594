import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Site } from '../models/site';

@Injectable({
  providedIn: 'root',
})
export class SiteService extends DataService<Site> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}organizations/`, http);
    this.httpClient = http;
  }

  getSitesForParentOrg(orgId: string): Observable<Site[]> {
    return this.http.get<any[]>(`${this.url}Sites/${orgId}`);
  }
}
