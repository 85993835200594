export class FormularyData {
    customerName: string;
    machineType: string;
    fileName: string;
    fileContent: string;
    uploadedBy: string;
    id: string;
    requestNumber: string;
    name: string;
    email: string;
    organisationId: string;
    organisationName: string;
    statusId: number;
    status: string;
    createdDate: string;
    uploadedPath: string;
    modifiedPath: string;
    processedPath: string;
    modifiedBy: string;
}
