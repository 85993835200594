import { Component, OnInit, HostListener } from '@angular/core';
import { PharmacyPeekService } from '../../services/pharmacy-peek.service';
import { AuthService } from '../../../../auth/auth.service';
import { PouchCountChartData } from '../../models/pouch-count-chart-data';
import { DatePipe } from '@angular/common';
import { ViewStoreService } from '../../services/view-type.service';
import { LaunchDarklyService } from '../../../../shared/services/launch-darkly.service';
import { LDFlagSet } from '../../../../shared/models/ld-flag-set';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { PharmacyPeekWidgetActions } from 'src/app/store/actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PharmacyPeekMgmtDialogComponent } from '../widgets-configuration/pharmacy-peek-mgmt-dialog/pharmacy-peek-mgmt-dialog.component';
import { getAssetsByOrgID, getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { PharmacyPeekMgmtDialogMobileComponent } from '../widgets-configuration/pharmacy-peek-mgmt-dialog-mobile/pharmacy-peek-mgmt-dialog-mobile.component';

import { LoggerService } from 'src/app/shared/services/logger.service';
import { getSelectedWidgetsList } from 'src/app/store/selectors/pharmacy-peek-widgets.selector';

@Component({
  selector: 'app-pharmacy-peek',
  templateUrl: './pharmacy-peek.component.html',
  styleUrls: ['./pharmacy-peek.component.scss'],
  providers: [DatePipe],
})
export class PharmacyPeekComponent implements OnInit {

  flags: LDFlagSet;
  PharmacyPeekWidgets = false;
  showText: boolean;
  avgHoursToday: number;
  avgHoursTodayHidden: number;
  hourCountHidden: [];
  pouchCountDataToday: PouchCountChartData[] = [];
  dailyPackagerUtilization: { [key: string]: any } = null;
  childOrg = '';
  parentOrg = '';
  orgType: string;
  childOrgId = '';
  todayViewExpandStatus = {
    overview: true,
    atp001: true,
  };

  dataSource: any[];
  pgAssets = [];
  dispenseRatioRowCount = 3;
  displayedColumns: string[] = ['canisterRatio', 'trayRatio', 'deviceId'];
  noDataExistsForHPC = true;
  noDataExistsForDR = true;
  pharmacyWidgetList: any[];
  selectedList: any[];
  isLoadedWidget = false;
  constructor(
    public pharmacyPeekService: PharmacyPeekService,
    public auth: AuthService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
    public launchDarklyService: LaunchDarklyService,
    public dialog: MatDialog,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.isLoadedWidget = false;
    //To load PP based configured widgets
    this.LoadPPWidgets();

    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.flags = flags;
        this.PharmacyPeekWidgets = flags?.PharmacyPeekWidgets;
      }
    }
    );

    this.auth.profile$.subscribe(async profile => {
      this.parentOrg = profile.parentOrganization;
      this.childOrg = profile.organization;
      this.childOrgId = profile.organizationId;
      this.orgType = profile.orgType;
    });

    this.store.select(getAssetsByOrgID).subscribe(assets => {
      if (assets) {
        assets.forEach(element => {
          this.pgAssets.push({ deviceId: element.machineNo, deviceName: element.name });
        });
      }
    }
    );

    this.store.select(getSelectedWidgetsList).subscribe(res => {
      if (res) {
        this.pharmacyWidgetList = res;
      }
    });
  }

  private LoadPPWidgets() {
    this.pharmacyPeekService.getUserConfiguration().subscribe(
      (response) => {
        this.store.dispatch(PharmacyPeekWidgetActions.getSelectedPharmacyWidgetList({ data: response }));
      },
      (error) => {
        if (error.status === 404) {
          console.log('getUserConfiguration Service error: No Data', error);
        } else {
          this.loggerService.logError('getUserConfiguration Service: Error: ', error);
        }
      },
    )
  }

  openPharmacyPeekMgmtDialog() {
    const dialogRef = this.dialog.open(PharmacyPeekMgmtDialogComponent, { backdropClass: 'lgDialog',  disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.isLoadedWidget = false;
    });
  }

  openPharmacyPeekMgmtDialogMobile() {
    const dialogRef = this.dialog.open(PharmacyPeekMgmtDialogMobileComponent, { backdropClass: 'lgDialog',  disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.isLoadedWidget = false;
    });

  }
  @HostListener('window:keyup.esc') close(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInLeft")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialog.closeAll(); }, 1000);
  }
}
