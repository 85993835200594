<div class="pp_report">

    <h6 class="flex justify-between content-center items-center flex-row">
        All Systems - Packager Uptime
        <a routerLink="/reports/774c17c7-5923-41fa-9ea0-4f0bdebb8af7" id="report_uptime_allsystem" class="btn-link" 
            aria-label="All Systems - Packager Uptime Report">
            Report >
        </a>
    </h6>

    <div *ngIf="noDataExistsForUP === false" class="flex justify-between content-stretch items-stretch flex-col gap-2">
        <div class="basis-full">
            <div class="rcount  m-t-8">
                <span id="OverallUptime">{{ overallUptime }} </span>
                <span id="OverallUptimePercent" class="rcountdesc m-l-13"> Total </span>
                <custom-tooltip text="Total combined system availability since 12:00am"></custom-tooltip>
            </div>
        </div>
        <div class="basis-full">
            <div class="rcount rcount_success">
                <span id="OverallUtilizationTime">{{ overallUtilizationTime }} </span>
                <span id="OverallUtilizationTimePercent" class="rcountdesc m-l-13">({{ overallUtilizationTimePercent
                    }})</span>
                <span id="OverallUtilizationTimeTitle" class="rcountdesc m-l-9"> Utilization Time </span>
            </div>
        </div>
        <div class="basis-full">
            <div class="rcount ">
                <span id="OverallIdleTime">{{ overallIdleTime }} </span>
                <span id="OverallIdleTimePercent" class="rcountdesc m-l-13">({{ overallIdleTimePercent }})</span>
                <span id="OverallIdleTimeTitle" class="rcountdesc m-l-9"> Idle Time </span>
            </div>
        </div>
    </div>

    <div *ngIf="noDataExistsForUP === true">
        <p class="pp_report">
            <span class="rcountdesc">No data exists</span>
        </p>
    </div>

</div>
<hr>