import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as constants from 'src/app/config/app-constants';
import { Order } from '../models/order';
import { OrderDetail } from '../models/order-detail';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends DataService<Order> {

    httpClient: HttpClient;
    constructor(http: HttpClient) {
        super(environment.apiUrl.orderService, http);
        this.httpClient = http;
    }
    getActiveOrderDetailsByOrg(): Observable<OrderDetail[]> {
        return this.httpClient.get<OrderDetail[]>(`${environment.apiUrl.orderService}orders/ActiveOrderDetailsByOrg`);
    }
    updateOrderDetails(orderId: string, resource: any) {
        return this.updateById(orderId, resource, `${environment.apiUrl.orderService}orderdetails/`);
    }
    updateDelayOrder(orderId: string, delayDate: string) {
        return this.updateById(`${orderId}` + `/${delayDate}`, {}, `${environment.apiUrl.orderService}orders/delayOrder/`);
    }
}
