import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetManagementComponent } from './components/fleet-management.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FleetManagementRoutingModule } from './fleet-management-routing.module';
import { PreProvisionedComponent } from './components/pre-provisioned/pre-provisioned.component';
import { ProvisionedComponent } from './components/provisioned/provisioned.component';
import { CustomerManagementModule } from '../customer-management/customer-management.module';
import { InventoryModule } from 'src/app/external-user/inventory/inventory.module';
import { EdgeApplianceDetailsComponent } from './components/edge-appliance-details/edge-appliance-details.component';
import { DeploymentFormDialogComponent } from './components/deployment-form-dialog/deployment-form-dialog.component';
import { OrganizationSelectComponent } from './components/organization-select/organization-select.component';
import { SiteSelectComponent } from './components/site-select/site-select.component';
import { SystemSelectComponent } from './components/system-select/system-select.component';
import { DynamicDeploymentControlComponent } from './components/dynamic-deployment-control/dynamic-deployment-control.component';
import { SystemFormComponent } from './components/system-form/system-form.component';

@NgModule({
  declarations: [
    FleetManagementComponent,
    PreProvisionedComponent,
    ProvisionedComponent,
    EdgeApplianceDetailsComponent,
    DeploymentFormDialogComponent, 
    OrganizationSelectComponent, 
    SiteSelectComponent,
    SystemSelectComponent,
    SystemFormComponent,
    DynamicDeploymentControlComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ScrollingModule,
    CdkTableModule,
    MatSortModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FleetManagementRoutingModule,
    CustomerManagementModule,
    InventoryModule
  ]
})
export class FleetManagementModule { }
