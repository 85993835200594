import { KeyValue } from '@angular/common';
import { Component, Inject, Input, Pipe, PipeTransform } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { QueuePriorityEnum } from 'src/app/external-user/batch-manager/models/pending-script-priority-enum';
import {  ScriptManagerData } from 'src/app/external-user/batch-manager/models/script-manager-data';


@Component({
  selector: 'app-priority-table-component',
  templateUrl: './priority-table.component.html',
  styleUrls: ['./priority-table.component.scss']
})
export class PriorityTableComponent {

  priorityEnum= QueuePriorityEnum;
  @Input() row: ScriptManagerData;

  constructor() {
    }


}
