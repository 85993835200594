/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { SetupCustomerManagementComponent } from './components/setup-customer-management/setup-customer-management.component';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ConsumablesSubscriptionComponent } from './components/consumables-subscription/consumables-subscription.component';
import { UserManagementComponent } from '../../common/user/components/user-management/user-management.component';
import { routePaths } from '../../config/app-constants';
import { PermissionGroupsComponent } from 'src/app/common/permission-groups/components/permission-groups/permission-groups.component';
/* eslint-enable max-len */

const routes: Routes = [
  {
    path: routePaths.CUSTOMER_MANAGEMENT,
    component: SetupCustomerManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: routePaths.EDIT_CUSTOMER,
    component: EditCustomerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user-mgmt-permissions',
        component: UserManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'consumables-subscription',
        component: ConsumablesSubscriptionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'permission-groups',
        component: PermissionGroupsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        redirectTo: 'onboarding',
        pathMatch: 'full',
      },
    ]
  },
  { path: '*',
    redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerManagementRoutingModule { }
