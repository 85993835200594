import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMToolComponent } from './components/im-tool.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { IMToolRoutingModule } from './im-tool-routing.module';
import{PmCustomerManagementComponent} from'./components/pm-customer-management/pm-customer-management.component';
import { AlertWizardComponent } from './components/alert-wizard/alert-wizard.component';
import { CustomerDrugListComponent } from './components/customer-drug-list/customer-drug-list.component'
import { FilterPipe } from './pipes/filter.pipe';
import { ImportWizardCddbComponent } from './components/import-wizard-cddb/import-wizard-cddb.component';
import { ImportWizardGcnComponent } from './components/import-wizard-gcn/import-wizard-gcn.component';
import { ImportWizardComponent } from './components/import-wizard/import-wizard.component';
import { AtpDrugListComponent } from './components/atp-drug-list/atp-drug-list.component';
import { ImportWizardDrugQualifierComponent } from './components/import-wizard-drug-qualifier/import-wizard-drug-qualifier.component';
import { SynmedDrugListComponent } from './components/synmed-drug-list/synmed-drug-list.component';
import { ImportWizardSynmedGcnComponent } from './components/import-wizard-synmed-gcn/import-wizard-synmed-gcn.component';
import { ImportWizardDcrDrugComponent } from './components/import-wizard-dcr-drug/import-wizard-dcr-drug.component';
import { HighlightDirective } from './directives/highlight';
import { DisplayDirective } from './directives/display';
import { ImportWizardDrugMovingComponent } from './components/import-wizard-drug-moving/import-wizard-drug-moving.component';
import { ImportWizardGcnMaxComponent } from './components/import-wizard-gcn-max/import-wizard-gcn-max.component';
import { ImportWizardImageComponent } from './components/import-wizard-image/import-wizard-image.component';
import { ImportWizardFirstComponent } from './components/import-wizard-first/import-wizard-first.component';
import { PassDrugListComponent } from './components/pass-drug-list/pass-drug-list.component';
import { ImportWizardPassComponent } from './components/import-wizard-gcn-pass/import-wizard-gcn-pass.component';
import { MiniDrugListComponent } from './components/mini-drug-list/mini-drug-list.component';
import { PositiveNumberValidatorDirective } from './directives/expression-validator.directive';


@NgModule({
  declarations: [
    IMToolComponent,
    PmCustomerManagementComponent,
    AlertWizardComponent,
    CustomerDrugListComponent,
    FilterPipe,
    ImportWizardCddbComponent,
    ImportWizardImageComponent,
    ImportWizardGcnComponent,
    ImportWizardComponent,
    AtpDrugListComponent,
    ImportWizardDrugQualifierComponent,
    SynmedDrugListComponent,
    ImportWizardSynmedGcnComponent,
    ImportWizardDcrDrugComponent,
    HighlightDirective,
    DisplayDirective,
    PositiveNumberValidatorDirective,
    ImportWizardDrugMovingComponent,
    ImportWizardGcnMaxComponent,
    ImportWizardFirstComponent,
    PassDrugListComponent,
    ImportWizardPassComponent,
    MiniDrugListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ScrollingModule,
    CdkTableModule,
    MatSortModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    IMToolRoutingModule,
  ],
})
export class IMToolModule {}
