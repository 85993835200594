import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { auditTime, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import QueueData from '../../../models/script-manager-data';
import { Asset, Batch, BatchListType, RunModes } from '../../../models/batch-models';
import { TenantService } from '../../../services/tenant.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ParataStandardInterfaceService } from '../../../services/parataStandardInterface.service';
import { ScriptsActions } from 'src/app/store/actions';
import { getPouchScripts } from 'src/app/store/selectors/scripts.selector';
import { ToastPanelType } from 'src/app/config/app-constants';
import { PsisMachineType } from '../../../services/psis-enum-mapping';
import { ViewStoreService } from '../../../services/view-type.service';
import { getAuthProfile, getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';
import { SiteService } from 'src/app/external-user/inventory/services/site.service';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { AssetService } from 'src/app/external-user/inventory/services/asset.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';

export enum View {
    ORG,
    SITE
}

const INQUEUE_QUEUE_NAME = 'IN QUEUE';
const INPROCESS_QUEUE_NAME = 'IN PROCESS';
const COMPLETED_QUEUE_NAME = "COMPLETE";

@Component({
    selector: 'app-pouch-scripting',
    templateUrl: './pouch-scripting.component.html',
    styleUrls: ['./pouch-scripting.component.scss'],
})

export class PouchScriptingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('tabGroup') tabGroup: MatTabGroup;

    public selectedIndexBinding: number = 0;

    sites: any;
    selectedSite: any;
    organizationId: any;
    parentOrgId: any;
    parentOrgName: any;

    View = View;

    BatchListType = BatchListType;
    RunModes = RunModes;
    runMode: string;
    batches: Batch[];
    assets: Asset[];
    completeCount: number;
    orgId: string;
    pgAssets: any[] = [];
    friendlyname: any[] = [];

    queueDataPouch: QueueData[] = [];
    queueDataPouchIntial: QueueData[] = [];

    destroyed$ = new Subject();
    countDown: Subscription;

    Menu_Pouch_ScriptManagement: boolean = false;
    flags: any;
    selectedUser: any;

    machineTypeATP = false;
    machineTypeMAX = false;
    Pouch_ScriptManagement_Sort: boolean = false;
    Pouch_ScriptManagement_Search: boolean = false;
    Pouch_ScriptManagement_Complete: boolean = false;
    Pouch_ScriptManagement_IncompleteHeader: boolean = false;
    Pouch_ScriptManagement_BacklogTime: boolean = false;

    subscription!: Subscription;
    filterText = '';


    type = [
        {
            signalrType: {
                type: SignalEventType.Script_POUCH_INQUEUE_BATCHES_SITE,
                deviceId: ''
            },
            name: INQUEUE_QUEUE_NAME
        },
        {
            signalrType: {
                type: SignalEventType.Script_POUCH_INPROCESS_BATCHES_SITE,
                deviceId: ''
            },
            name: INPROCESS_QUEUE_NAME
        }
    ]


    constructor(
        public tenantService: TenantService,
        public dialog: MatDialog,
        public toastService: ToastService,
        private parataStandardInterfaceService: ParataStandardInterfaceService,
        public viewStoreService: ViewStoreService,
        private signal: SignalRService,
        private ngxLoader: NgxUiLoaderService,
        private siteService: SiteService,
        public store: Store<State>,
        private assetService: AssetService,
        private authService: AuthService,
    ) {

        this.runMode = RunModes.AUTORUN;
    
    
    
    }

    get view() {
        if (this.selectedSite && this.selectedSite.customerNo) {
            return View.SITE;
        }
        return View.ORG;
    }

    ngOnInit() {

        this.store.select(getPouchScripts).subscribe(res => {
            if (res) {
                let redData = [...res];
                if (this.Pouch_ScriptManagement_IncompleteHeader === false) {
                    redData = redData.filter(x => x.name !== 'INCOMPLETE')
                }

                redData.forEach(element => {
                    if (element?.inventory !== null) {
                        element?.inventory?.data?.batches.forEach(innerelement => {
                            this.friendlyname = this.pgAssets.filter(x => x.machineNo == innerelement?.system);
                            if (this.friendlyname?.length === 1)
                                innerelement.friendlyname = this.friendlyname[0].name;
                            else
                                innerelement.friendlyname = innerelement.system;
                        });
                    }
                });

                this.queueDataPouch = redData.sort((a, b) => {
                    if (b.name.toLowerCase() < a.name.toLowerCase()) { return 1; }
                    if (b.name.toLowerCase() > a.name.toLowerCase()) { return -1; }
                    return 0;
                });

                if (this.queueDataPouchIntial.length === 0) {
                    this.queueDataPouchIntial = this.queueDataPouch;
                }
            }
        });

        this.authService.profile$.subscribe((profile) => {
            this.orgId = profile?.organizationId;
        });

        this.store.select(getLaunchDarklyFlags).subscribe(flags => {
            if (flags) {
                this.flags = flags;
                this.Menu_Pouch_ScriptManagement = flags?.Menu_Pouch_ScriptManagement;
                this.Pouch_ScriptManagement_Sort = flags?.Pouch_ScriptManagement_Sort;
                this.Pouch_ScriptManagement_Search = flags?.Pouch_ScriptManagement_Search;
                this.Pouch_ScriptManagement_Complete = flags?.Pouch_ScriptManagement_Complete;
                this.Pouch_ScriptManagement_IncompleteHeader = flags?.Pouch_ScriptManagement_IncompleteHeader;
                this.Pouch_ScriptManagement_BacklogTime = flags?.Pouch_ScriptManagement_BacklogTime;
            }
        }
        );

        this.store.select(getAuthProfile).subscribe((profile) => {
            if (profile) {
                this.parentOrgId = profile.parentOrganizationId;
                this.parentOrgName = profile.parentOrganization;
                this.organizationId = profile.organizationId;
                this.loadSites();
            }
        });

        if (window.sessionStorage) {
            this.selectedIndexBinding = Number(window.sessionStorage.getItem('selectedTabIndex'));
        }

        this.type.forEach(element => {
            this.startSignalRSub(element);
        });

        this.assetService.getAssetByOrgId(this.orgId)
            .subscribe((assets) => {
                this.pgAssets = assets;
            }, () => {
                this.pgAssets = [];
            });

    }

    startSignalRSub(EventType) {
        this.subscription = this.signal.getDataStream<any>(EventType.signalrType.type)
            .pipe(auditTime(1000))
            .subscribe((dataObj) => {
                this.createParataBatchViews(dataObj?.data?.data?.batches);
                this.store.dispatch(ScriptsActions.updateInventoryPouchData({ data: { inventory: dataObj.data, type: EventType.name } }));
                console.log(dataObj);
            })
    }

    ngAfterViewInit() {
        this.type.forEach(element => {
            this.store.dispatch(addSignalRSubList({ data: element.signalrType }));
        });
    }

    ngOnDestroy() {
        this.type.forEach(element => {
            this.store.dispatch(deleteSignalRSubList({ data: element.signalrType }));
        });
        this.subscription.unsubscribe();
        this.cancelInterval();
    }

    loadSites() {

        const ngxLoaderKey = 'loadSites';
        this.ngxLoader.start(ngxLoaderKey);
        this.siteService.getSitesForParentOrg(this.parentOrgId)
            .subscribe((sites) => {
                this.ngxLoader.stop(ngxLoaderKey);
                this.sites = sites;
                this.selectedSite = this.sites.find(site => site.organizationId === this.organizationId);
                this.parataStandardInterfaceService.getAllMachines(this.organizationId).subscribe((response) => {
                    let resData = response;
                    const pouch = resData.filter(x => x.machineType === PsisMachineType.POUCH || x.machineType === 1);
                    const max = resData.filter(x => x.machineType === PsisMachineType.MAX);

                    if (pouch.length > 0) {
                        this.machineTypeATP = true;
                        this.getInQueueScripts();
                        this.getInProcessScripts();
                        this.getCompleteScripts();
                    }

                    if (max.length > 0) {
                        this.machineTypeMAX = true;
                    }

                }, (error) => {
                    if (error.status === 404) {
                        this.toastService.openToast('PSIS getAllMachines No Data Exists!', ToastPanelType.warning);
                    } else {
                        this.toastService.openToast('PSIS getAllMachines Service Load Error!', ToastPanelType.error);
                    }
                }
                );

            }, (error) => {
                this.toastService.stopLoadingAndShowError(error,
                    'Error while loading sites for parent org!', ngxLoaderKey);
            });
    }

    cancelInterval() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    onTabChanged(event) {
        this.selectedIndexBinding = event;
        sessionStorage.setItem('selectedTabIndex', this.selectedIndexBinding?.toString());
    }

    filterInventory(value: string) {
        let queueData = [];

        this.filterText = value;
        queueData = [...this.queueDataPouchIntial];

        if (queueData && queueData.length > 0 && value) {
            this.filterText = this.filterText.toLocaleLowerCase();
            let qData = [];
            queueData.forEach(data => {
                if(data){
                let filteredData = this.searchByFilteredText(data);
                qData.push(filteredData);
                }
            });
            this.queueDataPouch = qData;
        }
        else{
            this.queueDataPouch = queueData;
        }
    }

    searchByFilteredText(inventoryD) {
        let inventory = inventoryD?.inventory?.data?.batches;
        let batches = [...inventory];
        let batchesFil = [];
        batches?.forEach(f =>
            {
           if( f?.parataBatchId?.toLowerCase().includes(this.filterText.toLowerCase()) ||
            f?.parataBatchID?.toLowerCase().includes(this.filterText.toLowerCase()) ||
            f?.friendlyname?.toLowerCase().includes(this.filterText.toLowerCase()) ||
            f?.deviceID?.toLowerCase().includes(this.filterText.toLowerCase())) {
            batchesFil.push(f);
            }
            else {
                let patients = f?.patients;
                let patient = [...patients];
                let batchesFil2 = patient?.filter(e =>
                    e?.name?.toLowerCase().includes(this.filterText.toLowerCase())
                )
                if (batchesFil2.length > 0) {
                    batchesFil.push(f);
                }
            }
            }
        );

        const obj = {
            name: inventoryD.name,
            isExpanded: true,
            inventory: {
                data: {
                    batches: batchesFil
                }
            }
        }
        return obj;
    }

    getInQueueScripts() {
        this.viewStoreService.getInQueueBatchesSite()
      .subscribe((inQueueBatches: any) => {
        this.createParataBatchViews(inQueueBatches.data.batches);
        this.store.dispatch(ScriptsActions.updateInventoryPouchData({ data: { inventory: inQueueBatches, type: INQUEUE_QUEUE_NAME } }));
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
    }

    getInProcessScripts() {
        this.viewStoreService.getInProgressBatchesSite()
      .subscribe((inProcessBatches: any) => {
        this.createParataBatchViews(inProcessBatches.data.batches);
        this.store.dispatch(ScriptsActions.updateInventoryPouchData({ data: { inventory: inProcessBatches, type: INPROCESS_QUEUE_NAME  } }));
        this.ngxLoader.stop();
      }, () => {
        this.ngxLoader.stop();
      });
    }

    getCompleteScripts() {
        this.parataStandardInterfaceService.getCompletedBatchesSite()
          .subscribe((completedBatches: any) => {
            this.createParataBatchViews(completedBatches);
            this.store.dispatch(ScriptsActions.updateInventoryPouchData({ data: { inventory: { data: { batches: completedBatches} }, type: COMPLETED_QUEUE_NAME } }));
            this.completeCount = completedBatches?.length;
            this.ngxLoader.stop();
          }, () => {
            this.ngxLoader.stop();
          });
        }

    private createParataBatchViews(parataBatchList: any[]) {
        parataBatchList?.forEach((parataBatch) => {
            try {
                parataBatch.patients = PouchScriptingComponent.formatPatient(parataBatch.patients)
            }
            catch (error) {
                console.log("inventory err: " + error);
            }
        });
    }

    private static formatPatient(patients: any) {
        let patientViews = [];
        
        patients?.forEach((item) => {

            // The inqueue/inprocess api's return lastname, but the completed queue api returns lastName
            const lname = item?.lastname ?? item.lastName;
            const fname = item?.firstname ?? item.firstName;
            const scriptcount = item?.scriptcount != null ? `(${ item.scriptcount })` : '';

            let patObj = {
                name: `${ lname.toUpperCase() }, ${ fname } ${ scriptcount }`,
                pouchCount: item.pouchCount,
                dob: item.dob
            };

            patientViews.push(patObj);
        });

        return patientViews;
    }
}
