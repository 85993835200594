import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';

export interface State {
  widgetsList: [];
  selectedWidgetsList: [];
  saveWidgetsList: [];
}

export const initialState: State = {
  widgetsList: null,
  selectedWidgetsList: null,
  saveWidgetsList: null,
};


const pharmacyPeekWidgetsReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.PharmacyPeekWidgetActions.getPharmacyWidgetList, (state, action) => ({
    ...state,
    widgetsList: action.data
  })
  ),


  on(AllActions.PharmacyPeekWidgetActions.getSelectedPharmacyWidgetList, (state, action) => ({
    ...state,
    selectedWidgetsList: returnNewState(action.data),
    saveWidgetsList: returnNewState(action.data)
  })
  ),

  on(AllActions.PharmacyPeekWidgetActions.updateSelectedPharmacyWidgetList, (state, action) => ({
    ...state,
    selectedWidgetsList: returnNewState(action.data),
    saveWidgetsList: returnNewState(action.data)
  })
  ),

  on(AllActions.PharmacyPeekWidgetActions.saveSelectedPharmacyWidgetList, (state, action) => ({
    ...state,
    saveWidgetsList: addNewWidget(state.saveWidgetsList, action.data)
  })
  ),

  on(AllActions.PharmacyPeekWidgetActions.reorderSelectedPharmacyWidgetList, (state, action) => ({
    ...state,
    saveWidgetsList: returnNewState(action.data)
  })
  ),
);

export function reducer(state: State | undefined, action: Action): any {
  return pharmacyPeekWidgetsReducer(state, action);
}

function addNewWidget(saveWidgetsList, newWidget) {
  let newList: any = saveWidgetsList;
  if (newList == null) {
    newList = [];
  }
  else {
    newList?.push(newWidget);
  }
  return newList;
}

export function returnNewState(state) {
  let newState: any = [];
  if (state) {
    newState = [...state];
  }
  return newState;
}