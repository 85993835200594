import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { OrgAsset } from '../models/asset';

@Injectable({
  providedIn: 'root',
})
export class OrgAssetService extends DataService<OrgAsset> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.authorizationService}orgAssets/`, http);
    this.httpClient = http;
  }

  getAssetsByParentOrgId(parentOrgId: string): Observable<OrgAsset[]> {
    return this.http.get<any[]>(`${this.url}${parentOrgId}`);
  }

  createOrgAsset(orgId: string, resource: any): Observable<any> {
    return this.httpClient.post(`${this.url}CreateSiteAsset/${orgId}`, resource);
  }

  deleteAssetById(orgId: string, assetId: string): Observable<OrgAsset[]> {
    return this.http.delete<any[]>(`${this.url}${orgId}/${assetId}`);
  }
}
