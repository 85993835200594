import { Injectable } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { Order } from '../../../internal-user/order/models/order';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Batch } from '../models/batch-models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends DataService<Order> {

  batchUrl: string;
  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.pivotUIService}Tenants/tenant/`, http);
    this.batchUrl = `${environment.apiUrl.pivotUIService}Batches/tenant/`;
    this.httpClient = http;
  }

  getTenantBatches(tenantId): Observable<Batch[]> {
     return this.httpClient.get<any[]>(this.batchUrl + tenantId)
       .pipe(map((response) => response.map((tenant) => ({
         ...tenant,
         batchTimeSeconds: Math.abs(tenant.batchTimeSeconds),
         batchName: tenant.batchName.replace('.txt', '')
       }))));
  }
}
