import { RpoToolService } from './../../services/rpo-tool.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { Image } from '../../models/drug-details';
import {  DropDownTwo } from '../../models/dropdown';
@Component({
  selector: 'app-import-wizard-image-slide',
  templateUrl: './import-wizard-image-slide.component.html',
  styleUrls: ['./import-wizard-image-slide.component.scss']
})
export class ImportWizardImageSlideComponent implements OnInit {
  listOfDataSource: DropDownTwo[];
  constructor( public dialogRef: MatDialogRef<ImportWizardImageSlideComponent>,
    public dialog: MatDialog,
    public rpoService:RpoToolService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.ndc= data.ndc
    }
    ndc:string;
    dataSource:number = 1;
    ngOnInit(): void {
    this.getSliderImage();
    this.getDataSource();
  }

   currentIndex:number = 0;
  slides:Image[]=[];
  slideShow(value:boolean){
    if(value==true){
      this.currentIndex++;
    }
    else if(value==false){
      this.currentIndex--;
    }
  }

  getSliderImage() {
    this.ngxLoader.start();
    this.slides = [] as Image[]
    this.rpoService
      .getImages
      (this.ndc, this.dataSource)
      .subscribe(
        (result) => {
          result.data?.forEach((element: any) => {
            this.slides.push({
              serialImage:'data:image/png;base64,'.concat(element.serialImage),
              imageName:element.view
            })
          });

          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined?
            'Something went wrong!!': error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }
  

  getDataSource() {
    this.ngxLoader.start();
    this.rpoService
      .getDataSource
      ()
      .subscribe(
        (result) => {
          this.listOfDataSource = result.data
          this.ngxLoader.stop();

        },
        (error: HttpErrorResponse) => {
          this.ngxLoader.stop();
          console.log(error);
          this.toastService.openToast(error.error.message === undefined?
            'Something went wrong!!': error.error.message,
            constants.ToastPanelType.error,
            2
          );
        }
      );
  }

}
