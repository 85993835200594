import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';

export interface State {
  organizations: Organization[];
  loading: boolean;
  error: any;
}

export const initialState: State = {
  organizations: [],
  loading: false,
  error: null
};

const organizationReducer = createReducer(
  initialState,
  on(AllActions.OrganizationsActions.getOrganizations, state => ({...state, loading: true})),
  on(AllActions.OrganizationsActions.getOrganizationsSuccess, (state, { organizations }) => ({ ...state, loading: false, organizations: organizations })),
  on(AllActions.OrganizationsActions.getOrganizationsFailure, (state, { error }) => ({ ...state, loading: false, organizations: [], error }))
);

export function reducer(state: State | undefined, action: Action): any {
  return organizationReducer(state, action);
}
