import { createAction, props } from '@ngrx/store';
export const SET_SCRIPT_REPORT_DATA = '[Script] Set Inventory Report data';
export const UPDATE_INVENTORY_REPORT_MAX_DATA = '[Script] Update Inventory Max Data';
export const UPDATE_INVENTORY_REPORT_POUCH_DATA = '[Script] Update Inventory Pouch data';

export const setScriptData = createAction(
  SET_SCRIPT_REPORT_DATA
  );


export const updateInventoryMaxData = createAction(
  UPDATE_INVENTORY_REPORT_MAX_DATA,
  props<{data: { inventory: Array<any>; type: string}}>()
);

export const updateInventoryPouchData = createAction(
  UPDATE_INVENTORY_REPORT_POUCH_DATA,
  props<{data: { inventory: any; type: string}}>()
);
