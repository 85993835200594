<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content>
  <div class="xs:flex xs:flex-col flex flex-row">
    <div class="flex flex-grow flex-shrink basis-auto justify-start content-center items-center">
      <h6 class="m-t-20 m-b-0">{{ data.content }}</h6>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-end content-stretch items-stretch space-x-[1%]">
  <button mat-button class="btn-primary" (click)="acknowledge()">OK</button>
</mat-dialog-actions>
