import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { DrugManagementComponent } from './components/drug-management.component';
import { routePaths } from '../../config/app-constants';
import { DrugDetailsComponent } from './components/drug-details/drug-details.component';

const routes: Routes = [
  {
    path: routePaths.DRUG_MANAGEMENT,
    component: DrugManagementComponent,
    canActivate: [AuthGuard],
  },
  {   
    path: `${routePaths.DRUG_MANAGEMENT}/drug-details/:ndc/:dataSourceId`,
    component: DrugDetailsComponent,
   // canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DrugManagementRoutingModule { }
