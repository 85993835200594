import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends DataService<any> {

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.viewstoreService}`, http);
  }

  public getInventories(start, end, siteId = null, deviceId = null): Observable<any> {
    const path = [siteId, siteId && deviceId, start, end].filter(s => s).join('/');
    return this.http.get<Observable<any>>(`${this.url}deviceinventory/GetRange/${path}`);
  }

  public searchInventories(search, siteId = null, deviceId = null): Observable<any> {
    if (!search) {
      throw new Error('Search value is required!');
    }
    const path = [siteId, siteId && deviceId, search].filter(s => s).join('/');
    return this.http.get<Observable<any>>(`${this.url}deviceinventory/SearchByDrug/${path}`);
  }

  public downloadInventory(siteId, deviceId): Observable<Blob> {
    return this.http.get(`${this.url}deviceinventory/Download/${siteId}/${deviceId}`, { responseType: 'blob' });
  }
}
