<div>
  <h1 mat-dialog-title id="addnewsite" style="margin: 0px 1px 13px 1px; ">
    <div class="flex justify-start content-center items-center flex-row">
        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
          Common Drug Database Management
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
            <mat-icon (click)="dialogRef.close()" class="cross-wizard">
                <img src="/assets/img/icons/ico.16.clear.svg" alt="">
            </mat-icon>
        </div>

    </div>
</h1>
<div>
    <p style="padding-left:23px;"><b>Accepted file formats are .xlsx, .xls, and .csv.</b></p>
<div style="margin-bottom: 10px;" class="flex flex-row flex-wrap">
    <div class="flex flex-col basis-[32%] md:basis-[32%] xs:basis-full sm:basis-1/2">
    <app-synmed-fileupload></app-synmed-fileupload>
    </div>
    <div class="flex flex-col basis-[32%] md:basis-[32%] xs:basis-full sm:basis-1/2">
    <app-petmed-fileupload></app-petmed-fileupload>
    </div>
    <div class="flex flex-col basis-[32%] md:basis-[32%] xs:basis-full sm:basis-1/2">
    <app-snapcanister-fileupload></app-snapcanister-fileupload>
    </div>
</div>
    <app-cddb-automation-script></app-cddb-automation-script>
</div>


</div>
