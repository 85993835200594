<div class="pp_report" *ngIf="uptimeData">
    <div>
        <h6 class="flex justify-between content-center items-center flex-row">
            <span *ngIf="uptimeData.name"  [matTooltipDisabled]="uptimeData.name.length<10"  matTooltip="{{uptimeData.name }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
                {{ uptimeData.name | truncate:10 }} - Packager Uptime</span>
                <span *ngIf="!uptimeData.name"  [matTooltipDisabled]="uptimeData.deviceId.length<10"  matTooltip="{{uptimeData.deviceId  }}" matTooltipPosition="below" matTooltipClass="my-custom-tooltip"> 
                  {{ uptimeData.deviceId| truncate:10  }} - Packager Uptime</span>
            <a routerLink="/reports/774c17c7-5923-41fa-9ea0-4f0bdebb8af7" id="report_uptime_device" class="btn-link" 
                [attr.aria-label]="uptimeData.name + '- Packager Uptime Report'">
                Report >
            </a>
        </h6>

        <div *ngIf="noDataExistsForUP === false" class="flex justify-between content-stretch items-stretch flex-col gap-2">
            <div class="basis-full">

                <div class="rcount  m-t-8">
                    <span id="uptime">{{ uptimeData.uptime }} </span>
                    <span id="OverallUptimeTitle" class="rcountdesc m-l-13"> Total </span>
                    <custom-tooltip text="Total combined system availability since 12:00am"></custom-tooltip>
                </div>
            </div>

            <div class="basis-full">
                <div class="rcount rcount_success ">
                    <span id="utilTime">{{ uptimeData.utilTime }} </span>
                    <span id="utilTimePercent" class="rcountdesc m-l-13">({{ uptimeData.utilTimePercent }})</span>
                    <span id="utilTimeTitle" class="rcountdesc m-l-9"> Utilization Time </span>

                </div>
            </div>
            <div class="basis-full">
                <div class="rcount">
                    <span id="idleTime">{{ uptimeData.idleTime }} </span>
                    <span id="idleTimePercent" class="rcountdesc m-l-13">({{ uptimeData.idleTimePercent }})</span>
                    <span id="idleTimeTitle" class="rcountdesc m-l-9"> Idle Time </span>
                </div>
            </div>

        </div>

        <div *ngIf="noDataExistsForUP === true">
            <p class="pp_report">
                <span class="rcountdesc">No data exists</span>
            </p>
        </div>
        <hr>
    </div>
</div>