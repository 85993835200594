/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { IMToolComponent } from './components/im-tool.component';
import { routePaths } from '../../config/app-constants';
import { CustomerDrugListComponent } from './components/customer-drug-list/customer-drug-list.component';
import { AtpDrugListComponent } from './components/atp-drug-list/atp-drug-list.component';
import { SynmedDrugListComponent } from './components/synmed-drug-list/synmed-drug-list.component';
import { PassDrugListComponent } from './components/pass-drug-list/pass-drug-list.component';
import { MiniDrugListComponent } from './components/mini-drug-list/mini-drug-list.component';

const routes: Routes = [
  {
    path: routePaths.IM_TOOL,
    component: IMToolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routePaths.IM_TOOL}/export/:customerid`,
    component: CustomerDrugListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routePaths.IM_TOOL}/atp-export/:customerid`,
    component: AtpDrugListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routePaths.IM_TOOL}/mini-export/:customerid`,
    component: MiniDrugListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routePaths.IM_TOOL}/synmed-export/:customerid`,
    component: SynmedDrugListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${routePaths.IM_TOOL}/pass-export/:customerid`,
    component: PassDrugListComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IMToolRoutingModule { }
