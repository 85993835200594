<div class="inv_table" #inventoryTable >
  <div class="inv_table-header inv_table-row inv_table-title">
    <div class="td">Rx Number</div>
    <div class="td">Patient Name</div>
    <div class="td">Drug Name </div>
    <div class="td">Disp. Qty</div>
    <div class="td">System</div>
    <div class="td">Location</div>
    <div class="td">
      Status
      <img src="../../../../../assets/img/icons/ico.16.info.svg"
        matTooltip="No Data" matTooltipPosition="above" class="m-l-8" alt="">
    </div>
  </div>
  <div *ngFor="let queue of inventories ;"  [ngClass]="{'table-incomplete': queue?.name == 'INCOMPLETE'}" >
    <div *ngIf="queue && (queue?.name != 'COMPLETE' && isComplete== false) || (queue?.name == 'COMPLETE' && isComplete ==true)">
    <div class="inv_table-header inv_table-row inv_table_subtitle ">
      <h5 class="ucase m-11 text-black" [class.clickable]="true" (click)="onExpandRow(queue);">

        <span class=" pointer_pos">
          <app-icon [icon]="queue?.isExpanded ? 'arwup' : 'arwdown'" size="12" class="leftRapper ">
          </app-icon>
          <div class="rightRapper">
            <div [innerHTML]="queue?.name | noHtml"></div>
            <div >({{queue.inventories?.length}})</div>
            <img *ngIf="queue?.name == 'INCOMPLETE'" class="icon-incomplete" src="../../../../../assets/img/icons/ico.16.alert.svg" alt="Alert">
          </div>
        </span>


      </h5>
    </div>

    <div class="inv_table-row" *ngFor="let row of queue?.inventories ; let rowIndex = index" (click)="onOpenDispensingDetail($event, row, queue?.name)">
      <div class="inv_table-body" *ngIf="queue?.isExpanded" style="height: unset">
        <div class="collapseElement">
          <div class="td" [innerHTML]="row?.rxNumber | noHtml">1</div>
          <div class="td" >{{row?.patientFirstName}} {{row?.patientLastName}} </div>
          <div class="td" [innerHTML]="row?.drugName | noHtml">1</div>
          <div class="td multivial">
            <img *ngIf="queue?.name == 'INCOMPLETE' && queue?.vialCount > 1" class="icon multi-left" src="../../../../../assets/img/icons/ico.16.multivial.svg"  #tooltip="matTooltip"
            matTooltip="Multiple vials">
            <div class="multi-right" [innerHTML]="row?.dispenseQuantity | noHtml "></div>
          </div>          
          <div class="td" [innerHTML]="row?.assignedMachine | noHtml">1</div>
          <div class="td"> {{row?.location ==""? '-' :row?.location}}</div>
          <div class="td status-priority">
            <app-priority-table-component *ngIf="queue?.name == 'IN QUEUE'" class="status-priority" [row]="row">
            </app-priority-table-component>
            <div class="tooltip" *ngIf="queue?.name == 'INCOMPLETE'">
            <app-incomplete-table-component  class="status-priority" [row]="row"  #tooltip="matTooltip"
            matTooltip="Info about the action [no data]">
            </app-incomplete-table-component>
          </div>

          <span *ngIf="queue?.name === 'COMPLETE'" > {{row?.status}}</span>
          </div>
          <app-action-menu-component class="menuDrop" [organizationId] = "organizationId"  [row]="row" [type] ='queue?.name' *ngIf="row?.priority >=0 && queue?.name == 'IN QUEUE'"></app-action-menu-component>
          <app-action-menu-component class="menuDrop" [organizationId] = "organizationId"  [row]="row"  [type] ='queue?.name' *ngIf="queue?.name == 'INCOMPLETE'"></app-action-menu-component>
        </div>

      </div>
    </div>

  </div>
</div>
  <div class="inv_table-row" *ngIf="loading">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center">
        <img src="/assets/img/Parata_processing.gif" alt="loading" class="w-10 m-15" />
      </div>
    </div>
  </div>
  <div class="inv_table-row" *ngIf="inventories && inventories.length === 0">
    <div class="mat-cell mat-column-pending">
      <div class="loading-spinner text-center w-100 m-15">
        No data exists
      </div>
    </div>
  </div>
</div>
