
<!-- Order setup -->
<div class="p-16 flex flex-col">
  <div class="basis-full">
    <h4 class="m-b-0">Consumable Order Setup</h4>
    <small>Please answer the following questions to setup your Consumable Order</small>
  </div>
  <form [formGroup]='formGroup' class="sm:flex-1 basis-[70%]">
    <h5 class="m-b-0">Organization Details</h5>
    <div class="flex justify-between content-stretch items-stretch flex-row">
      <!-- Organization name -->
      <div *ngIf="!isClicked" class="basis-2/5">
        <mat-form-field tabindex="0">
          <label for="idMachineType"> Choose the Machine Type </label>
          <mat-select id="idOrgName" formControlName='organizationId' placeholder="Select site" 
          (selectionChange)="getOrganizationBasedMachine()" class="m-t-0">
            <mat-option *ngFor="let orgnization of orgnizations" [value]="orgnization?.organizationId">
              {{orgnization?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Machine type -->
      <div class="basis-2/5">
        <mat-form-field tabindex="0">
          <mat-select id="idMachineType" formControlName='machineType' (selectionChange)="machineTypeSelectionChanged()" class="m-t-0">
            <mat-option *ngFor="let mtype of machineTypes" [value]="mtype?.id">
              {{mtype?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Current stock details -->
    <h5 class="m-t-30">How many of the following items do you have in stock?</h5>
    <mat-card tabindex="0">
      <div class="p-15">
        <table mat-table [dataSource]="currentStockDataSource" class="consumableorder" 
          formArrayName='currentStockControls'>
          <!-- Product name like Papaer,Ribbon -->
          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let productRow" class="co_title"> {{productRow?.name}} </td>
          </ng-container>
          <!-- Part number & description -->
          <ng-container matColumnDef="partNumber">
            <th mat-header-cell *matHeaderCellDef> Part Number </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Part Number" 
              [formGroupName]='rowIndex'>
              <mat-form-field>
                <mat-select value="" formControlName='curStockSubProduct'
                  (selectionChange)="subProductSelectionChanged(rowIndex)">
                  <mat-option value="">Select a Part Number</mat-option>
                  <mat-option *ngFor=" let subProduct of productRow?.subProducts" [value]="subProduct?.subProductId">
                    {{subProduct?.partNo + subProduct?.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- Packaging size of each pouch -->
          <ng-container matColumnDef="packageSize">
            <th mat-header-cell *matHeaderCellDef> Packaging Size </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Packaging Size" 
              [formGroupName]='rowIndex'>
              <mat-form-field>
                <mat-select value="" formControlName='curStockPackageSize' [disabled]='disablePackageSizeDropwDown(rowIndex)'
                  (selectionChange)="currentStockPackageSizeOrQuantityChanged(rowIndex,productRow)">
                  <mat-option value="">Select a Package Size</mat-option>
                  <mat-option *ngFor="let packsize of productRow?.packageSizes" [value]="packsize?.packageSizeId">
                    {{packsize?.packageSize}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- Quantity -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Quantity" 
              [formGroupName]='rowIndex'>
              <mat-form-field floatLabel="never">
                <input matInput type="number" (change)="currentStockPackageSizeOrQuantityChanged(rowIndex,productRow)" 
                  formControlName='curStockQuantity' min="0" max="100">
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: orderTableColumns;"></tr>
        </table>
      </div>
    </mat-card>
    <!-- Required stock details -->
    <h5>What would you like to Order?</h5>
    <mat-card tabindex="0">
      <div class="p-15 p-t-0">
        <table mat-table [dataSource]="requiredStockDataSource" class="consumableorder" 
          formArrayName='requiredStockControls'>
          <!-- Product name like Paper,Ribbon -->
          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let productRow" class="co_title"> {{productRow?.name}} </td>
          </ng-container>
          <!-- Part number & description -->
          <ng-container matColumnDef="partNumber">
            <th mat-header-cell *matHeaderCellDef> Part Number </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Part Number" 
              [formGroupName]='rowIndex'>
              
              <mat-form-field>
                <mat-select value="" formControlName='requiredStockSubProduct'
                (selectionChange)="subProductSelectionChanged(rowIndex, false)" class="m-t-0">
                  <mat-option value="">Select a Part Number</mat-option>
                  <mat-option *ngFor="let subProduct of productRow?.subProducts" [value]="subProduct?.subProductId">
                    {{subProduct?.partNo + subProduct?.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </td>
          </ng-container>
          <!-- Packaging size of each pouch -->
          <ng-container matColumnDef="packageSize">
            <th mat-header-cell *matHeaderCellDef> Packaging Size </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Packaging Size" 
              [formGroupName]='rowIndex'>
              <mat-form-field>
                <mat-select value="" formControlName='requiredStockPackageSize' 
                  [disabled]='disablePackageSizeDropwDown(rowIndex,false)'>
                  <mat-option value="">Select a Package Size</mat-option>
                  <mat-option *ngFor="let packsize of productRow?.packageSizes" [value]="packsize?.packageSizeId">
                    {{packsize?.packageSize}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <!-- Quantity -->
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let productRow;let rowIndex=index" data-label="Quantity" 
              [formGroupName]='rowIndex'>
              <mat-form-field floatLabel="never">
                <input matInput type="number" formControlName='requiredStockQuantity' min="0" max="100">
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: orderTableColumns;"></tr>
        </table>
      </div>
    </mat-card>
  
    <h5 class="m-b-0">Please enter how many pouches remaining in inventory you wish to have before we order again.
    </h5>
    <small class="m-b-20">Parata will send you the above orders when your stocks get below</small>
    <div class="flex flex-row">
      <mat-form-field floatLabel="never" tabindex="0" class="basis-2/5">
        <label for="pouchescount">Pouches</label>
        <input matInput type="number" id="pouchescount" formControlName='pouchesCount' min="0" max="1000000">
      </mat-form-field>
    </div>
  
    <button class="btn-primary" [class.btn-disabled]="disableSubmitOrder()" [disabled]="disableSubmitOrder()" 
      (click)=submitAutoConsumebleOrder()>
      Start Auto-Consumable Order
    </button>
  </form>
</div>

    
  
  