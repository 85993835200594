/* eslint-disable max-len */
// angular modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MultilevelMenuService, NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu'; // multilevel menu
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxUiLoaderService } from 'ngx-ui-loader';


// shared modules
import { AppRoutingModule } from './app-routing.module';

// NGRX Store Modules
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './store/reducers';
import { StoreEffects } from './store/effects';
import { environment } from 'src/environments/environment';

// components
import { AppComponent } from './app.component';
import { HomeComponent } from './shared/components/home/home.component';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { HeaderComponent } from './shared/components/header/header.component';

// feature modules
import { ExternalUserModule } from './external-user/external-user.module';
import { InternalUserModule } from './internal-user/internal-user.module';
import { SharedModule } from './shared/shared.module';
import { CommonModules } from './common/common.module';

// Directives
import { DragDropDirective } from './shared/directives/dragdrop.directive';
import { NoHtmlTagsDirective } from './shared/directives/NoHtmlTags.directive';
import { TrimDirective } from './shared/directives/trim.directive';
import { BrowserModule } from '@angular/platform-browser';
import { EulaDialogComponent } from './shared/components/eula-dialog/eula-dialog.component';
import { AccountDeactivatedNotifyDialogComponent } from './shared/components/account-deactivated-notify-dialog/account-deactivated-notify-dialog.component';
import { UserManagementDialogComponent } from './shared/components/user-management-dialog/user-management-dialog.component';
import { SharedService } from './shared/services/shared.service';
import { AddOrganizationDialogComponent } from './internal-user/customer-management/components/add-organization/add-organization-dialog.component';
import { AddSiteDialogComponent } from './internal-user/customer-management/components/add-site/add-site-dialog.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ListItemContentComponent } from './shared/ng-material-multilevel-menu/list-item/list-item-content/list-item-content.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NotificationMgmtDialogComponent } from './external-user/notification-mgmt-dialog/notification-mgmt-dialog.component';

import { IdleTimeMonitorService } from './shared/idle-time-monitor/idle-time-monitor.service';
import { PermissionGroupsDialogComponent } from './shared/components/permission-groups-dialog/permission-groups-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    HeaderComponent,
    DragDropDirective,
    NoHtmlTagsDirective,
    TrimDirective,
    EulaDialogComponent,
    AccountDeactivatedNotifyDialogComponent,
    UserManagementDialogComponent,
    PermissionGroupsDialogComponent,
    AddOrganizationDialogComponent,
    AddSiteDialogComponent,
    ListItemContentComponent,    
    NotificationMgmtDialogComponent    
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgMaterialMultilevelMenuModule,
    HttpClientModule,
    ReactiveFormsModule,
    HighlightModule,
    FontAwesomeModule,
    ScrollingModule,
    AppRoutingModule,
    SharedModule,
    InternalUserModule,
    ExternalUserModule,
    CommonModules,    
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(StoreEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true}),
    MatButtonToggleModule,
    MatSidenavModule
  ],
  providers: [
    IdleTimeMonitorService,
    MultilevelMenuService,
    SharedService,    
    NgxUiLoaderService,    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        }
      }
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
