<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>
      <h1 mat-dialog-title tabindex="0" class="!flex flex-row">
        <div tabindex="0" aria-label="Move To" class="flex-1">
            Move To
        </div>
        <div class="flex flex-row justify-end content-center items-center h-full basis-[5%]">
          <button class="btn-transparent btn_icon" type="button" aria-label="close" mat-dialog-close>
            <img src="../../../assets/img/icons/ico.16.clear.svg" alt="">
          </button>
        </div>
      </h1>
      <mat-dialog-content>
          <div class="flex flex-row xs:flex-col">
                <div class="xs:basis-full basis-1/2">
                    <h6 class="h7">Current system location</h6>
                    <ul class="moveto_inputs">
                        <li>
                            <p class="label">Selected Batch</p>
                            <p class="value">{{this.parataBatchId}}</p>
                        </li>
                        <li>
                            <p class="label">Current System</p>
                            <p class="value">{{this.system}}</p>
                        </li>
                    </ul>
                </div>
                <div class="xs:basis-full basis-1/2">
                    <h6 class="h7">Select a new system location</h6>
                    <ul class="moveto_inputs flex flex-row">
                        <li class="basis-[150px]">
                            <p class="label">System(s)</p>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                                <mat-radio-button (change)="radioChange($event,system?.systemname)" class="example-radio-button" *ngFor="let system of estimatedTime" [value]="system?.systemname">
                                    {{system?.friendlyname}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </li>
                        <li class="basis-[100px]">
                            <p class="label">Estimated Time</p>
                            <p class="value text-right" *ngFor="let system of estimatedTime">
                                {{system?.formatted}}
                            </p>
                        </li>
                    </ul>
                </div>
          </div>
        
      </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
      <button mat-button class="btn-default" type="button" tabindex="0" mat-dialog-close id="btnCancel">Cancel</button>
      <button mat-button (click)="moveBatch()" class="btn-primary ml-16" type="button" tabindex="0" id="btnDone">Move</button>
    </mat-dialog-actions>
  </div>