<h6 class="m-0 p-l-20 permission_detail_title" *ngIf="!isGearIconClicked">PERMISSION GROUPS <a class="btn-link m-l-20" 
    (click)="addNewGroup()" [ngClass]="(addNewGroupClicked || isEditMode)?' disablegrid1' :'' ">Add New Group ></a><button [disabled]="selectedPerGroup?.isDefault || addNewGroupClicked" mat-button 
    class="btn-default btn-done btn-sm btn-delete" (click)="deletePermissionGroups(selectedPerGroup?.isDefault)">Delete</button></h6>

<div class="flex flex-row lt-md:flex lt-md:flex-col gt-sm:flex gt-sm:flex-row gt-sm:flex-nowrap">
  <div class="per_indetail">
    <div class="side-links gt-sm:basis-40 hidden gt-sm:block xs:hidden" [ngClass]="(addNewGroupClicked || isEditMode)?' disablegrid' :'' ">
      <a *ngFor="let role of defaultRoleDetail" (click)="selectedId(role)"
        [ngClass]="{'lock': role.isDefault == true,'arrow':role.isDefault == false, active: role.roleId == this.selectedValue, inactive:role.roleId == this.selectedValue}" 
        id="{{ role.roleId }}" class="side-link" [attr.disabled]="addNewGroupClicked || isEditMode">
        <span class="txt-permissiongroup" [ngClass]="role.description == 'New Group' ? 'lite' : 'bold'" 
          [matTooltipDisabled]="role?.name?.length < 13" [matTooltip]="role?.name" 
          matTooltipPosition="below" matTooltipClass="my-custom-tooltip">
          {{ (role?.name && role?.name?.length > 10+3) ? ((role?.name).slice(0, 10) + '...') : role?.name }}
        </span>
      </a>
      <a *ngFor="let role of customRoleDetail" (click)="selectedId(role)" 
        [ngClass]="{'lock': role.isDefault == true,'arrow':role.isDefault == false, active: role.roleId == this.selectedValue, inactive:role.roleId == this.selectedValue}" 
        id="{{ role.roleId }}" class="side-link">
        <span class="txt-permissiongroup" [ngClass]="role.description == 'New Group' ? 'lite' : 'bold'" 
        [matTooltipDisabled]="role?.name?.length < 13" [matTooltip]="role?.name" 
         matTooltipPosition="below" matTooltipClass="my-custom-tooltip">
          {{ (role?.name && role?.name?.length > 10+3) ? ((role?.name).slice(0, 10) + '...') : role?.name }}
        </span>
      </a>
    </div>
    <div class="permission_detail flex-1" *ngIf="!addNewGroupClicked">
      <div class="margin" *ngIf="!isDefaultPer">
        <a class="btn-link" (click)="EditGroup()">Edit
          Group Settings ></a>
      </div>
      <div>
        <p>
          Group Name
        </p>
        <label>
          {{selectedname}}
          <img *ngIf="isDefaultPer" src="../../../../../assets/img/icons/ico.24.lock.svg" alt="lock">
        </label>
      </div>
      <div *ngFor="let pergrp of menuWithAssetDetail">
        <h6 *ngIf="pergrp.isSelected">
          {{ pergrp.assetDescription }}
        </h6>
        <div *ngFor="let menu of pergrp.menuList">
          <div *ngIf="menu.isSelected">
            <label>
              {{ menu.assetName }}</label>
            <p>{{ menu.assetDescription }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="permission_detail flex-1" *ngIf="addNewGroupClicked">
      <div>
        <p>
          Edit Group Settings
          <a class="btn-link m-l-20" style="padding-right: 10px;" (click)="CancelGroup()">Cancel ></a>
          <button class="btn-default btn-sm" type="submit" id="AddSystem" tabindex="0" (click)="SaveGroup()">
            Save
          </button>
        </p>
      </div>
      <form [formGroup]='formGroup' class="flex justify-between content-stretch items-stretch flex-col">
        <mat-form-field floatLabel="never">
          <label class="groupName" for="groupName">
            <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
            <span>Group Name</span>
          </label>
          <input matInput formControlName='groupName' appNoHtmlTags name="groupName" id="groupName" value="{{selectedname}}" required 
            maxlength="51" [disabled]="isEditMode">
          <mat-error *ngIf="formGroup.get('groupName').hasError('required')">Group Name Required</mat-error>
          <mat-error *ngIf="formGroup.get('groupName').hasError('maxLength')">Group Name cannot exceed 50
            characters</mat-error>
          <mat-error *ngIf="formGroup.get('groupName').hasError('pattern')">Group Name cannot contain special
            characters</mat-error>
          <mat-error *ngIf="formGroup.get('groupName').hasError('alreadyExist') ">Group Name should be
            Unique!</mat-error>
        </mat-form-field>
      </form>
      <div>
        <mat-checkbox [ngClass]="allComplete == true ? 'selected' : 'unselected'" class="checkbox-section" value="all" 
          color="primary" role="checkbox" [checked]="allComplete" [indeterminate]="someComplete()" 
          (change)="setAll($event)">
          Select All
        </mat-checkbox>
        <!---Bind checkbox list - start-->
        <div *ngFor="let pergrp of featuresListData">
          <div class="margintop">
            <mat-checkbox [ngClass]="pergrp.isSelected == true ? 'selected' : 'unselected'" class="" color="primary" 
              role="checkbox" [(ngModel)]="pergrp.isSelected" [indeterminate]="someSubComplete(pergrp)" 
              (change)="setAllSub(pergrp, $event)" (ngModelChange)="updateAllComplete()">
              {{ pergrp.assetDescription }}
            </mat-checkbox>
          </div>
          <div class="marginleft" *ngFor="let menu of pergrp.menuList">
            <div>
              <mat-checkbox [ngClass]="menu.isSelected == true ? 'selected' : 'unselected'" class="checkbox-margin" 
                color="primary" role="checkbox" [(ngModel)]="menu.isSelected" [disabled]="menu.assetName == 'Reporting'" 
                (ngModelChange)="updateAllSubComplete(pergrp)">
                {{menu.assetName}}
              </mat-checkbox>
              <p class="desc-margin"> {{menu.assetDescription}} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
