import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-account-deactivated-notify-dialog',
  templateUrl: './account-deactivated-notify-dialog.component.html',
  styleUrls: ['./account-deactivated-notify-dialog.component.scss']
})
export class AccountDeactivatedNotifyDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; content: string },
    public dialogRef: MatDialogRef<AccountDeactivatedNotifyDialogComponent>,
    public authService: AuthService,
  ) {
    dialogRef.disableClose = true;
  }

  acknowledge() {
    this.dialogRef.close();
    this.authService.logout();
  }
}
