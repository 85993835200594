import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IMToolService } from '../../services/im-tool.service';
@Component({
  selector: 'app-import-wizard-image',
  templateUrl: './import-wizard-image.component.html',
  styleUrls: ['./import-wizard-image.component.scss']
})
export class ImportWizardImageComponent implements OnInit {

  image : string = '';
  constructor( public dialogRef: MatDialogRef<ImportWizardImageComponent>,
    public dialog: MatDialog,
    public imToolService: IMToolService,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.image=data.image;
    }
  
  ngOnInit(): void {
   
  }

}
