
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DrugFileUploadService {
  httpClient: HttpClient;
  constructor(http: HttpClient) {
    this.httpClient = http;
  }
  upload(file,url, responseType: any = 'text'): Observable<any> {
    const formData: any = new FormData();
    formData.append('file', file);
    return this.httpClient.post(environment.apiUrl.rpoToolService+url, formData,  {
      reportProgress: true,
      observe: 'events',
     responseType
    });
  }



}
