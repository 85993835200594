import { AbstractControl, ValidatorFn } from "@angular/forms";

// Define a function that returns a ValidatorFn
export function autoCanisterValidator(passType: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      const min = 0;
      let max = 0;
  
      switch (passType) {
        case 18:
          max = 4;
          break;
        case 19:
          max = 4;
          break;
        case 20:
          max = 6;
          break;
        case 21:
          max = 8;
          break;
        default:
          return null;
      }
  
      // Validate if the value is within the range [0, max]
      if (value < min || value > max) {
        return { 'autoCanisterInvalid': true };
      }
  
      // Additional validation: Ensure the available canisters (based on the pass type)
    //   const availableLargeCanisters = 4; // You need to adjust this value based on your application
    //   if (value * 4 > availableLargeCanisters) {
    //     return { 'autoCanisterExceeded': true };
    //   }
  
      return null; // Valid
    };
  }