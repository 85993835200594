import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DeploymentTypeMetadata, InputType, UserInputPropertySource } from '../models/deploymentForm.model';

/**
 * This service provides helper methods throughout deployment form components to help create, bind, and manage 
 * dynamic content driven by metadata.
 */
@Injectable({
  providedIn: 'root'
})
export class SystemFormService {
  constructor() { }

  /**
   * Creates a new form group with default values dynamically from metadata.
   * @param formMetadata Metadata for the form.
   * @returns Angular FormGroup constructed from metadata with default values.
   */
  getSystemForm(formMetadata: DeploymentTypeMetadata): FormGroup {
    let systemForm = new UntypedFormGroup({
      name: new FormControl(formMetadata.name, Validators.required)
    });

    formMetadata.requiredParameters.forEach(param => systemForm.addControl(param.name, this.createDynamicFormControl(param, true)));
    formMetadata.optionalParameters.forEach(param => systemForm.addControl(param.name, this.createDynamicFormControl(param, false)));

    systemForm.addControl('optionalFeatures', new FormArray([]));

    if (formMetadata.optionalFeatures && formMetadata.optionalFeatures?.length > 0) {
      let optionalFeaturesFormArray: FormArray = systemForm.get('optionalFeatures') as FormArray;

      formMetadata.optionalFeatures.forEach(optionalFeature => {
        if (optionalFeature.enableByDefault) {
          let optionalFeatureMetadata = formMetadata.optionalFeatures.find(feature => feature.name === optionalFeature.name);
          optionalFeaturesFormArray.push(this.getSystemForm(optionalFeatureMetadata));
        }
      });
    }

    return systemForm;
  }

  /**
   * Takes an existing system model for a provisioned device and binds it to a form group created by the original metadata. 
   * @param formMetadata Metadata for the form.
   * @param formModel Model with existing data.
   * @returns Angular FormGroup constructed from metadata and patched with existing data.
   */
  bindSystemForm(formMetadata: DeploymentTypeMetadata, formModel: any): FormGroup {
    if (formMetadata.name !== formModel.name) {
      console.warn(`Binding failed - metadata key ${formMetadata.name} did not match with system form key ${formModel.name}.`);
    }

    let systemForm = this.getSystemForm(formMetadata);

    // This type conversion will eventually be handled on the server, and can then be removed
    formMetadata.requiredParameters.forEach(requiredParam => {
      if (requiredParam.userInputType === InputType.CheckBox && typeof formModel[requiredParam.name] === 'string') {
        formModel[requiredParam.name] = formModel[requiredParam.name].toLowerCase() === 'true';
      }
    });
    formMetadata.optionalParameters.forEach(optionalParam => {
      if (optionalParam.userInputType === InputType.CheckBox && typeof formModel[optionalParam.name] === 'string') {
        formModel[optionalParam.name] = formModel[optionalParam.name].toLowerCase() === 'true';
      }
    });

    if (formModel.optionalFeatures && formModel.optionalFeatures?.length > 0) {
      let optionalFeaturesFormArray: FormArray = systemForm.get('optionalFeatures') as FormArray;
      optionalFeaturesFormArray.clear();

      formModel.optionalFeatures.forEach(optionalFeature => {
        let optionalFeatureMetadata = formMetadata.optionalFeatures.find(feature => feature.name === optionalFeature.name);
        optionalFeaturesFormArray.push(this.bindSystemForm(optionalFeatureMetadata, optionalFeature));
      });
    }

    systemForm.patchValue(formModel);

    return systemForm;
  }

  /**
   * Creates a dynamic form control from property source metadata.
   * @param source The property metadata.
   * @param required Whether the control is required on the form (passed in separately since this is not stored in the metadata).
   * @returns Angular FormControl constructed from the metadata.
   */
  createDynamicFormControl(source: UserInputPropertySource, required: boolean): FormControl {
    const dynamicFormControl = new FormControl(source.defaultValue);

    if (required) {
      dynamicFormControl.addValidators(Validators.required);
    }

    return dynamicFormControl;
  }

  /**
   * Creates a map object used to track all unique deployment-level properties available in the deployments with relevant metadata. 
   * @param formMetadata Collection of deployment metadata.
   * @returns Map object that contains each deployment-level property as a key, along with the property metadata, 
   * list of features that reference it, and whether it is required on the form.
   * Example: 
   * {
   *   plxApiKey: {
   *     propMetadata: { 
   *       name: "plxApiKey",
   *       userInputType: ,
   *       ...
   *     },
   *     featureReferences: ["PLX"],
   *     required: false
   *   },
   *   plxEncryptionKey: etc...
   * }
   */
  getDeploymentLevelPropertyMap(formMetadata: DeploymentTypeMetadata[]): Map<string, { propMetadata: UserInputPropertySource, featureReferences: Set<string>, required: boolean }> {
    const uniqueProperties = new Map<string, { propMetadata: UserInputPropertySource, featureReferences: Set<string>, required: boolean }>();

    function aggregateFeatureLevelControls(metadata: DeploymentTypeMetadata[]) {
      metadata.forEach(metadatum => {
        const updatePropertyCollection = (source: UserInputPropertySource, required: boolean) => {
          if (!uniqueProperties.has(source.name)) {
            uniqueProperties.set(source.name, {
              propMetadata: source,
              featureReferences: new Set([metadatum.name, ...source.referencedByFeatures]),
              required: required
            });
            return;
          }

          let uniqueProperty = uniqueProperties.get(source.name);
          uniqueProperty.featureReferences = new Set([...Array.from(uniqueProperty.featureReferences), metadatum.name, ...source.referencedByFeatures]);
        };

        metadatum.deploymentLevelRequiredParameters.forEach(param => updatePropertyCollection(param, true));
        metadatum.deploymentLevelOptionalParameters.forEach(param => updatePropertyCollection(param, false));

        if (metadatum.optionalFeatures) {
          aggregateFeatureLevelControls(metadatum.optionalFeatures);
        }
      });
    }

    aggregateFeatureLevelControls(formMetadata);

    return uniqueProperties;
  }

  /**
   * Inspects the `referencedByFeatures` metadata property, and copies data recursively from parent features to any child features that reference it.
   * @param formMetadata The deployment metadata associated with the form model.
   * @param formModel The form model to transform.
   */
  setFeatureReferences(formMetadata: DeploymentTypeMetadata, formModel: any): void {
    // Iterate through all params in the feature
    formMetadata.requiredParameters.forEach(param => this.setParameterReferences(param, formModel[param.name], formModel));
    formMetadata.optionalParameters.forEach(param => this.setParameterReferences(param, formModel[param.name], formModel));

    // Do it again recursively for each subfeature, if it is enabled on the form
    formMetadata.optionalFeatures.forEach(optionalFeatureMetadata => {
      let optionalFeatureModel = formModel.optionalFeatures.find(model => model.name === optionalFeatureMetadata.name);

      if (optionalFeatureModel) {
        this.setFeatureReferences(optionalFeatureMetadata, optionalFeatureModel);
      }
    });
  }

  private setParameterReferences(param: UserInputPropertySource, value: any, formModel: any) {
    if (!param.referencedByFeatures || param.referencedByFeatures.length === 0) {
      return;
    }

    if (value === undefined) {
      console.warn(`Parameter '${param.name}' is referenced by other features but not present on the form.`);
      return;
    }

    formModel.optionalFeatures.forEach(optionalFeature => {
      if (param.referencedByFeatures.includes(optionalFeature.name)) {
        optionalFeature[param.name] = value;
      }

      optionalFeature.optionalFeatures.forEach(subFeature => this.setParameterReferences(param, value, subFeature));
    });
  }
}
