<app-card class="app_card" [collapsable]="true" [collapsed]="pharmacyPeekService.collapsed | async" 
  (collapsedChange)="pharmacyPeekService.setCollapsed($event)" collapsedTitle="Pharmacy Peek">
  <div title>
    <div class="pharmacy-peek-title flex justify-between content-center items-center flex-row basis-full">
      <div class="flex flex-col">
        <h5 class="ucase m-0" tabindex="0">PHARMACY PEEK</h5>
        <small tabindex="0">{{parentOrg}} ({{childOrg}})</small>
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-[50px]">
        <button id="pharmacyPeekMgmtDialogEditBtn" *ngIf="PharmacyPeekWidgets === true" class="btn-transparent btn_icon xs:hidden sm:hidden md:block lg:block xl:block" 
          aria-label="Pharmacy Peek Management" type="button" (click)="openPharmacyPeekMgmtDialog()" 
          (keypress.enter)="openPharmacyPeekMgmtDialog()">
          <img src="../../../../../assets/img/icons/ico.16.edit.svg" alt="Edit">
        </button>
        <button id="pharmacyPeekMgmtDialogMobileEditBtn" *ngIf="PharmacyPeekWidgets === true" 
          class="btn-transparent btn_icon xl:hidden lg:hidden md:hidden sm:block xs:block" aria-label="Pharmacy Peek Management" type="button" 
          (click)="openPharmacyPeekMgmtDialogMobile()">
          <img src="../../../../../assets/img/icons/icon.16.states.svg" alt="Edit">
        </button>
        <button id="pharmacyPeekMgmtDialogMobileCloseBtn" 
          class="btn-transparent btn_icon m-l-16 xl:hidden lg:hidden md:hidden sm:block xs:block" aria-label="Pharmacy Peek Management close" type="button" 
          (click)="close()">
          <img src="../../../../../assets/img/icons/clear-w.svg" alt="Close">
        </button>
      </div>
    </div>
  </div>

  <ng-container>
    <div class="pharmacy-peek-container">
      <mat-tab-group>
        <mat-tab #matTabToday label="Today"></mat-tab>
      </mat-tab-group>
      <div class="pharmacy-peek-content">
        <div>
          <div class="pp_repsec">
              <div *ngFor="let item of pharmacyWidgetList">

                <app-hourly-pouch-count-all-system *ngIf="item.name  == 'HourlyPouchCount' && item.allSystem == true">
                </app-hourly-pouch-count-all-system>

                <app-hourly-pouch-count-device [systemConfig]="item.systemConfig" 
                  *ngIf="item.name  == 'HourlyPouchCount'  && item.allSystem == false">
                </app-hourly-pouch-count-device>

                <app-hourly-vial-count-all-system *ngIf="item.name  == 'HourlyVialCount' && item.allSystem == true">
                </app-hourly-vial-count-all-system>

                <app-hourly-vial-count-device [systemConfig]="item.systemConfig" 
                  *ngIf="item.name  == 'HourlyVialCount'  && item.allSystem == false">
                </app-hourly-vial-count-device>

                <app-dispense-ratio *ngIf="item.name  == 'DispensingRatios'">
                </app-dispense-ratio>

                <app-packager-uptime-all-system 
                  *ngIf="item.name  == 'PackagerUptime' && item.systemConfig.deviceId==''">
                </app-packager-uptime-all-system>

                <app-packager-uptime-device [filterDeviceId]="item.systemConfig.deviceId" 
                  *ngIf=" item.name  == 'PackagerUptime' && item.systemConfig.deviceId!=''">
                </app-packager-uptime-device>

                <app-packager-downtime-all-system 
                  *ngIf=" item.name  == 'PackagerDowntime' && item.systemConfig.deviceId ==''">
                </app-packager-downtime-all-system>

                <app-packager-downtime-device [filterDeviceId]="item.systemConfig.deviceId" 
                  *ngIf=" item.name  == 'PackagerDowntime' && item.systemConfig.deviceId !=''">
                </app-packager-downtime-device>

                <app-system-status-multiple-device [pgAssets]="pgAssets" [filterDeviceId]="item.systemConfig.deviceId" 
                *ngIf=" item.name  === 'SystemStatus' && (item.systemConfig.deviceId.split(',')).length > 1"
                ></app-system-status-multiple-device>

                <app-system-status-device [filterDeviceId]="item.systemConfig.deviceId" [pgAssets]="pgAssets" 
                  *ngIf=" item.name  === 'SystemStatus' && (item.systemConfig.deviceId.split(',')).length === 1">
                </app-system-status-device>

              </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</app-card>

<p class="data_update flex justify-center content-stretch items-stretch flex-row" *ngIf="this.showText">
  <button class="btn-transparent btn_icon" aria-label="refresh" type="button">
    <span class="svgicon icon_refresh"></span>
  </button>
</p>
<p class="data_update p-r-16 flex justify-end content-center items-center flex-row" *ngIf="!this.showText">
  <em class="none">All data last updated 1:30 pm</em>
</p>