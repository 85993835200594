<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <div>
    <h1 mat-dialog-title tabindex="0" class="!flex flex-row">
      <div tabindex="0" aria-label="Notifications Management" class="flex-1">
        Notifications Management
      </div>
      <div class="flex flex-row justify-end content-center items-center h-full basis-[5%]">
        <button class="btn-transparent btn_icon" type="button" aria-label="close" mat-dialog-close>
          <img src="../../../assets/img/icons/ico.16.clear.svg" alt="">
        </button>
      </div>
    </h1>
    <mat-dialog-content>
      <div class="nmsticky flex flex-row">
        <app-search-bar minLengthToSearch="3" placeholder="Search" class="m-r-24 nmsearchbar xs:basis-full grow-0 shrink basis-[300px]" (filterChange)="applyFilter({ text: $event })">
        </app-search-bar>
      </div>
      <h6 tabindex="0" class="h7">Create a new notification using the fields below.</h6>
      <form [formGroup]='formGroup' #formDirective="ngForm" class="create_notify">
        <div class="form-section flex justify-start content-stretch items-stretch lg:flex-row lg:flex-nowrap xs:flex-row xs:flex-wrap gap-4">
          <div class="xs:basis-full sm:order-1 sm:basis-[27%] md:order-1 md:basis-[17%] lg:basis-[10%]">
            <mat-form-field floatLabel="never" class="sys_notify">
              <label for="System">
                <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                <span>System</span>
              </label>
              <mat-select id="System" tabindex="0" formControlName='system' required>
                <mat-option value="AllSystems">All Systems</mat-option>
                <mat-option *ngFor="let system of systemList;" [value]="system.machineNo"> {{system.name}} </mat-option>
              </mat-select>
              <mat-error *ngIf="!this.formGroup.valid || formGroup.get('system').hasError('required')">System is
                required</mat-error>
            </mat-form-field>
          </div>
          <div class="xs:basis-full sm:order-2 sm:basis-[41%] md:order-2 md:basis-[27%] lg:basis-[18%]">
            <mat-form-field floatLabel="never">
              <label for="Event">
                <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                <span>Event</span>
              </label>
              <mat-select id="Event" tabindex="0" formControlName='event' required>
                <mat-option *ngFor="let events of eventList;" [value]="events.name"> {{events.name}} </mat-option>
              </mat-select>
              <mat-error *ngIf="!this.formGroup.valid || formGroup.get('event').hasError('required')">Event is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="xs:basis-1/2 sm:order-4 sm:basis-[27%] md:order-3 md:basis-[21%] lg:basis-[8%]">
            <mat-form-field floatLabel="never">
              <label for="Operator">
                <span>Operator</span>
              </label>
              <input formControlName='operator' matInput id="Operator" value="Greater Than" aria-readonly="true" 
                tabindex="0" readonly>
            </mat-form-field>
          </div>
          <div class="value_sm xs:basis-1/2 sm:order-5 sm:basis-[23%] md:order-5 md:basis-[17%] lg:basis-[10%]">
            <mat-form-field floatLabel="never">
              <label for="Value">
                <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                <span>Value</span>
              </label>
              <mat-select id="Value" tabindex="0" formControlName='value' required>
                <mat-option *ngFor="let values of valueList;" [value]="values.name"> {{values.name}} </mat-option>
              </mat-select>
              <mat-error *ngIf="!this.formGroup.valid || formGroup.get('value').hasError('required')">Value is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="xs:basis-1/2 sm:order-6 sm:basis-[27%] md:order-6 md:basis-[11%] lg:basis-[10%]">
            <mat-form-field floatLabel="never">
              <label for="Method">
                <span>Method</span>
              </label>
              <input formControlName='method' matInput id="Method" value="Email" aria-readonly="true" tabindex="0" 
                readonly>
            </mat-form-field>
          </div>
          <div class="xs:basis-1/2 sm:order-7 sm:basis-2/5 md:order-7 lg:basis-[12%]">
            <mat-form-field floatLabel="never">
              <label for="Recipients">
                <span>Recipients</span>
              </label>
              <input matInput formControlName='recipients' id="Recipients" value="{{this.lastname}}, {{this.firstname}}" 
                aria-readonly="true" tabindex="0" readonly>
            </mat-form-field>
          </div>
          <div class="notify_buts sm:flex sm:justify-center sm:content-center sm:items-center sm:flex-col xs:basis-full sm:order-3 flex justify-end content-start items-start sm:basis-[26%] md:order-4 md:basis-[29%] lg:basis-[32%]">
            <button mat-button class="btn-link sm:order-2" tabindex="0" type="button" aria-label="Clear All" 
              (keypress.enter)="reset()" (click)="reset()" id="Clearall">Clear All ></button>
            <button mat-button class="btn-default btn-sm sm:order-1" tabindex="0" type="button" aria-label="Create Notification" 
              (keypress.enter)="addNotification(this.formGroup.value)" (click)="addNotification(this.formGroup.value)" 
              id="Createnotification">Create Notification</button>
          </div>
        </div>
      </form>

      <!-- table -->
      <form [formGroup]='editNotificationFormGroup' tabindex="0" class="xs:hidden md:block sm:hidden lg:block">
        <table mat-table [dataSource]="dataSource" tabindex="0">
          <ng-container matColumnDef="eventname">
            <th mat-header-cell *matHeaderCellDef> Event Name </th>
            <td mat-cell *matCellDef="let element" tabindex="0" 
              [attr.aria-label]="'Event Name' + element.systemname +'-'+ element.event" 
              [innerHTML]=" element.systemname + ' - ' + element.event | noHtml | highlightMatched: (filter ? filter.text : '')"></td>
          </ng-container>
          <ng-container matColumnDef="operator">
            <th mat-header-cell *matHeaderCellDef> Operator </th>
            <td mat-cell *matCellDef="let element" tabindex="0" [attr.aria-label]="'Operator' + element.operator" 
              [innerHTML]=" element.operator | noHtml | highlightMatched: (filter ? filter.text : '')"></td>              
          </ng-container>
          <ng-container matColumnDef="nmvalue">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element" tabindex="0" [attr.aria-label]="'Value' + element.value" 
            [innerHTML]=" element.value | noHtml | highlightMatched: (filter ? filter.text : '')"></td>              
          </ng-container>
          <ng-container matColumnDef="nmmethod">
            <th mat-header-cell *matHeaderCellDef> Method </th>
            <td mat-cell *matCellDef="let element" tabindex="0" [attr.aria-label]="'Method' + element.method" 
            [innerHTML]=" element.method | noHtml | highlightMatched: (filter ? filter.text : '')"></td>                  
          </ng-container>
          <ng-container matColumnDef="recipients">
            <th mat-header-cell *matHeaderCellDef> Recipients </th>
            <td mat-cell *matCellDef="let element" tabindex="0" [attr.aria-label]="'Recipients' + element.recipients" 
            [innerHTML]=" (this.lastname + ', ' + this.firstname) | noHtml | highlightMatched: (filter ? filter.text : '')"></td>                              
          </ng-container>
          <ng-container matColumnDef="systemname" style="display: none;">
            <th mat-header-cell *matHeaderCellDef> SystemName </th>
            <td mat-cell *matCellDef="let element" tabindex="0" [attr.aria-label]="'SystemName' + element.systemname">
              {{element.systemname}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element;index as i" class="text-right">
              <button type="button" class="btn-transparent btn-icon" aria-label="Action" tabindex="0" 
                [matMenuTriggerFor]="editNotification" 
                id="Editmenu-{{element.system|trim}}-{{element.event|trim}}-{{i}}">
                <img src="../../../assets/img/icons/ico.16.more.svg" alt="">
              </button>
              <mat-menu #editNotification="matMenu" backdropClass="custom_drop" xPosition="before">
                <button mat-menu-item type="button" (click)="editNotificationItem(element)" 
                  id="{{element.system|trim}}-{{element.event|trim}}-{{i}}" type="button">Edit</button>
                <button mat-menu-item type="button" id="{{element.system|trim}}-{{element.event|trim}}-{{i}}" 
                  (click)="removeNotification(i)" type="button">Remove</button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
          <tr mat-row *matRowDef="let row; columns: ['noData']; when: isNoDataRow"></tr>            
          <ng-container matColumnDef="noData">
            <td [attr.colspan]="displayedColumns.length" class="text-center" mat-cell *matCellDef="let row" tabindex="0">
              {{ row.noDataText }}
            </td>
          </ng-container>

          <!-- Edit row -->
          <tr mat-row class="row-editing" *matRowDef="let row; columns: editingColumns; when: isEditingRow.bind(this)">
          </tr>
          <div class="row-editing flex flex-row flex-nowrap justify-start content-stretch items-stretch xs:flex xs:flex-col gap-4 sm:flex sm:flex-row sm:flex-wrap">
            <ng-container matColumnDef="editeventname">
              <td mat-cell *matCellDef="let row" class="editeventname" [id]="">
                <div class="flex flex-row space-x-[5%]">
                  <mat-form-field floatLabel="never" class="basis-[32%]">
                    <label for="editSystem">
                      <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                      <span>System</span>
                    </label>
                    <mat-select id="editSystem" formControlName='system' tabindex="0">
                      <mat-option *ngFor="let system of systemList;" [value]="system.machineNo"> {{system.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field floatLabel="never" class="basis-[63%]">
                    <label for="editEvent">
                      <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                      <span>Event</span>
                    </label>
                    <mat-select id="editEvent" tabindex="0" formControlName='event'>
                      <mat-option *ngFor="let events of eventList;" [value]="events.name"> {{events.name}} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="editnmvalue">
              <td mat-cell *matCellDef="let row" class="editnmvalue" [id]="">
                <mat-form-field floatLabel="never">
                  <label for="editValue">
                    <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                    <span>Value</span>
                  </label>
                  <mat-select id="editValue" tabindex="0" formControlName='value'>
                    <mat-option *ngFor="let values of valueList;" [value]="values.name"> {{values.name}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="editaction" class="text-right">
              <td mat-cell *matCellDef="let row;index as i" class="text-right" [id]="">
                <button mat-button class="btn-link" tabindex="0" type="button" aria-label="Cancel" 
                  (click)="editNotificationItem(null)" id="Cancel">Cancel</button>
                <button mat-button class="btn-default btn-sm" tabindex="0" type="button" aria-label="Save" 
                  (click)="updateNotificationItem(i)" id="Save">Save</button>
              </td>
            </ng-container>
          </div>
        </table>
      </form>

      
        <div class="cardview md:hidden xs:block lg:hidden sm:block">
          <div class="cardview_row" *ngFor="let element of dataSource.data;index as i">
            <div class="matcard" id="{{'matcard'+i}}">
              <div class="flex flex-row">
                <div class="basis-4/5">
                  <div class="sm:flex sm:flex-row sm:flex-wrap flex gap-4">
                    <div class="m-b-10 sm:basis-[46%]">
                      <div class="label">
                        Event Name
                      </div>
                      <div class="value">
                        <div matTooltip="{{element.systemname + ' - ' + element.event}}" matTooltipPosition="below">{{(element.systemname + ' - ' + element.event) | truncate:49}}</div>
                      </div>
                    </div>
                    <div class="m-b-10 sm:basis-[46%]">
                      <div class="label">
                        Operator
                      </div>
                      <div class="value">
                        {{element.operator}}
                      </div>
                    </div>
                    <div class="m-b-10 sm:basis-[46%]">
                      <div class="label">
                        Value
                      </div>
                      <div class="value">
                        {{element.value}}
                      </div>
                    </div>
                    <div class="m-b-10 sm:basis-[46%]">
                      <div class="label">
                        Method
                      </div>
                      <div class="value">
                        {{element.method}}
                      </div>
                    </div>
                    <div class="m-b-10 sm:basis-[46%]">
                      <div class="label">
                        Recipients
                      </div>
                      <div class="value">
                        {{(this.lastname + ', ' + this.firstname)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row justify-end content-center items-center h-full basis-1/5">
                  <button type="button" class="btn-transparent btn-icon" aria-label="Action" tabindex="0" [matMenuTriggerFor]="editNotification_m">
                    <img src="../../../assets/img/icons/ico.16.more.svg" alt="">
                  </button>
                  <mat-menu #editNotification_m="matMenu" backdropClass="custom_drop" xPosition="before">
                    <button mat-menu-item type="button" (click)="showEditNotify(element,i)">Edit</button>
                    <button mat-menu-item type="button" (click)="removeNotification(i)">Remove</button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div class="matedit" id="{{'matedit'+i}}" style="display: none;">
              <form [formGroup]='editNotificationFormGroup' class="edit_notify xl:flex xl:flex-row">
                <div class="form-section flex justify-start content-stretch items-stretch xs:flex xs:flex-row xs:flex-wrap sm:flex sm:flex-row sm:flex-wrap lg:flex-row lg:flex-nowrap gap-4">
                  <div class="xs:basis-full sm:order-1 sm:basis-[27%] md:order-1 md:basis-[17%] lg:basis-[10%]">
                    <mat-form-field floatLabel="never" class="sys_notify">
                      <label for="System">
                        <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                        <span>System</span>
                      </label>
                      <mat-select tabindex="0" formControlName='system' required>
                        <mat-option value="AllSystems">All Systems</mat-option>
                        <mat-option *ngFor="let system of systemList;" [value]="system.machineNo"> {{system.name}} </mat-option> 
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="xs:basis-full sm:order-2 sm:basis-[41%] md:order-2 md:basis-[27%] lg:basis-[18%]">
                    <mat-form-field floatLabel="never">
                      <label for="Event">
                        <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                        <span>Event</span>
                      </label>
                      <mat-select tabindex="0" formControlName='event' required>
                        <mat-option *ngFor="let events of eventList;" [value]="events.name"> {{events.name}} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="xs:basis-1/2 sm:order-4 sm:basis-[27%] md:order-3 md:basis-[21%] lg:basis-[8%]">
                    <mat-form-field floatLabel="never">
                      <label for="Operator">
                        <span>Operator</span>
                      </label>
                      <input formControlName='operator' matInput id="Operator" value="Greater Than" aria-readonly="true" 
                        tabindex="0" readonly>
                    </mat-form-field>
                  </div>
                  <div class="value_sm xs:basis-1/2 sm:order-5 sm:basis-[23%] md:order-5 md:basis-[17%] lg:basis-[10%]">
                    <mat-form-field floatLabel="never">
                      <label for="Value">
                        <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                        <span>Value</span>
                      </label>
                      <mat-select id="Value" tabindex="0" formControlName='value' required>
                        <mat-option *ngFor="let values of valueList;" [value]="values.name"> {{values.name}} </mat-option> 
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="xs:basis-1/2 sm:order-6 sm:basis-[27%] md:order-6 md:basis-[11%] lg:basis-[10%]">
                    <mat-form-field floatLabel="never">
                      <label for="Method">
                        <span>Method</span>
                      </label>
                      <input formControlName='method' matInput id="Method" value="Email" aria-readonly="true" tabindex="0" 
                        readonly>
                    </mat-form-field>
                  </div>
                  <div class="xs:basis-1/2 sm:order-7 sm:basis-2/5 md:order-7 md:basis-[40%] lg:basis-[12%]">
                    <mat-form-field floatLabel="never">
                      <label for="Recipients">
                        <span>Recipients</span>
                      </label>
                      <input matInput formControlName='recipients' id="Recipients" value="{{this.lastname}}, {{this.firstname}}" 
                        aria-readonly="true" tabindex="0" readonly>
                    </mat-form-field>
                  </div>
                  <div class="notify_buts sm:flex sm:justify-center sm:content-center sm:items-center sm:flex-col xs:basis-full sm:order-3 flex justify-end content-start items-start sm:basis-[26%] md:order-4 md:basis-[29%] lg:basis-[32%]">
                    <button mat-button class="btn-link sm:order-2" tabindex="0" type="button" aria-label="Cancel" 
                      (click)="hideEditNotify(i)">Cancel</button>
                    <button mat-button class="btn-default btn-sm sm:order-1" tabindex="0" type="button" aria-label="Save" 
                      (click)="updateNotificationItem(i)">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        

    </mat-dialog-content>
  </div>
  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
    <button mat-button class="btn-default" type="button" tabindex="0" mat-dialog-close id="btnCancel">Cancel</button>
    <button mat-button class="btn-primary ml-16" type="button" tabindex="0" (keypress.enter)="saveNotification()" 
      (click)="saveNotification()" id="btnDone">Done</button>
  </mat-dialog-actions>
</div>

<div *ngIf="removeEvent">
  <div class="badge_undo badge_inactive flex justify-center content-stretch items-stretch flex-row">
    <div class="basis-[68%]">Notification Removed</div>
    <div class="undo_close flex flex-row justify-center content-stretch items-stretch h-full basis-[32%]">
      <button class="btn-default btn-sm m-r-16" (click)="undoRemoveEvent()" id="undo">Undo</button>
      <app-icon icon="clear" class="pointer" id="clear" (click)="removeEvent = false"></app-icon>
    </div>
  </div>
</div>