

<p class="h7 ppw_title" tabindex="0">Choose the widgets you want in your PHARMACY PEEK</p>
<div class="pharmacy-peek-widget">
    <ul *ngIf="widgetList.length > 0" class="pp_widget flex flex-row flex-wrap gap-6">
        <li *ngFor="let widget of widgetList  " tabindex="0" [attr.aria-label]="widget.name" (keydown.enter)="openPharmacyPeekPackagerDowntimeDialog(widget)" class="lg:basis-[30%]">
            <img id="{{widget.name}}" src='../../../../../assets/img/pharmacy-peek-widgets/Widget.ADD.{{widget.name}}.svg' alt="">
            <button class="add_widget" type="button" (click)="openPharmacyPeekPackagerDowntimeDialog(widget)" title="{{widget.name}}" tabindex="-1">
                <img src="../../../../../assets/img/icons/ico.24.clear.svg" alt="">
            </button>
        </li>
    </ul>
    <div  *ngIf="!(widgetList.length > 0) && filtered ">
        <div class="mat-cell mat-column-pending">
            <div class="loading-spinner text-center w-100 m-15">
              No data exists
            </div>
          </div>
    </div>
</div>