<div [formGroup]="form" class="flex flex-col py-4">
  <div class="flex w-full">
    <div class="basis-[20%] pr-4 grow-0">
      <!-- Display options on the left for parent only -->
      <mat-form-field *ngIf="isParentForm && optionalFeatureNames && optionalFeatureNames.length" floatLabel="never">
        <label for="ConfigurationOptions">
          Configuration Options
          <custom-tooltip text="Features to select"></custom-tooltip>
        </label>
        <mat-select multiple [(value)]="selectedFeatures">
          <mat-select-trigger>
            <span *ngIf="optionalFeatures.length">Options Selected ({{optionalFeatures.length}})</span>
          </mat-select-trigger>
          <mat-option *ngFor="let feature of optionalFeatureNames" [value]="feature"
            (onSelectionChange)="onFeatureSelect($event)">
            {{ feature }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Recursive child components display collapse-able header on the left, with indentation based on depth -->
      <div *ngIf="!isParentForm" class="flex items-center gap-1 pt-4 cursor-pointer font-bold" (click)="isExpanded = !isExpanded" [ngStyle]="{ 
          'padding-left': recursionDepth > 0 ? recursionDepth * 2 + 'rem' : '0rem', 
          'color': (form.touched && !form.valid) ? 'red' : 'black' 
        }">
        <mat-icon *ngIf="isExpanded">keyboard_arrow_up</mat-icon>
        <mat-icon *ngIf="!isExpanded">keyboard_arrow_down</mat-icon>
        <span>{{ featureName }}</span>
      </div>
    </div>
    <div *ngIf="isExpanded" class="flex flex-col basis-[80%] gap-4">
      <div class="grid grid-cols-4 gap-4">
        <!-- Display optional features on the right for recursive child components (if available)  -->
        <mat-form-field *ngIf="!isParentForm && optionalFeatureNames && optionalFeatureNames.length" floatLabel="never">
          <label for="ConfigurationOptions">
            Configuration Options
            <custom-tooltip text="Features to select"></custom-tooltip>
          </label>
          <mat-select multiple [(value)]="selectedFeatures">
            <mat-select-trigger>
              <span *ngIf="optionalFeatures.length">Options Selected ({{optionalFeatures.length}})</span>
            </mat-select-trigger>
            <mat-option *ngFor="let feature of optionalFeatureNames" [value]="feature"
              (onSelectionChange)="onFeatureSelect($event)">
              {{ feature }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Dynamic controls -->
        <ng-container *ngFor="let requiredParameter of systemFormMetadata.requiredParameters">
          <app-dynamic-deployment-control [form]="form" [organizationId]="organizationId"
            [controlMetadata]="requiredParameter" required="true"></app-dynamic-deployment-control>
        </ng-container>
        <ng-container *ngFor="let optionalParameter of systemFormMetadata.optionalParameters">
          <app-dynamic-deployment-control [hidden]="!canEditAdvancedOptions" [form]="form"
            [organizationId]="organizationId" [controlMetadata]="optionalParameter"
            required="false"></app-dynamic-deployment-control>
        </ng-container>
        <ng-container *ngIf="!featureControlsShown">
          <div class="pt-5">No configuration required.</div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Recursively display optional features if selected -->
  <div *ngIf="isExpanded" class="flex flex-col">
    <ng-container *ngFor="let optionalFeature of optionalFeatures?.controls">
      <app-system-form class="w-full" [form]="optionalFeature" [organizationId]="organizationId"
        [systemFormMetadata]="getOptionalFeature(optionalFeature.value.name)"
        [recursionDepth]="recursionDepth + 1" (featureSelected)="nestedFeatureSelected($event)"></app-system-form>
    </ng-container>
  </div>
</div>