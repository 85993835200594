import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { InternalDashboardComponent } from './components/internal-dashboard/internal-dashboard.component';
import { DashboardRoutingModule  } from './dashboard-routing.module';

@NgModule({
  declarations: [
    InternalDashboardComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
  ]
})
export class DashboardModule { }
