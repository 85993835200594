<div *ngIf="machineTypeATP === false">

<div class="flex flex-row">
  <div class="xs:basis-full sm:basis-[95%] md:basis-[95%] lg:basis-[95%]">
    <ul class="breadcrumb flex flex-row justify-start content-stretch items-stretch" tabindex="0">
      <li [ngClass]="view === View.ORG ? 'active' : 'default'" id="ParentOrganization" tabindex="0">
        <span *ngIf="parentOrgName" class="b_info">
          <span class="svgicon icon_enterprise"></span>
          <span class="org_mac" matTooltip="{{ parentOrgName }}" matTooltipPosition="below">{{ parentOrgName }}</span>
        </span>
      </li>
      <li [ngClass]="view === View.SITE ? 'active' : 'default'" id="SiteOrganization" tabindex="0">
        <span class="b_info">
          <span class="svgicon icon_pharmacy"></span>
          <span class="org_mac" *ngIf="selectedSite" matTooltip="{{ selectedSite.name }}">{{ selectedSite.name }}</span>
        </span>
      </li>
    </ul>
  </div>
  <div class="xs:basis-[5%] xs:hidden sm:basis-[5%] md:basis-[5%] lg:basis-[5%]">
    <div class="flex justify-end content-start items-start flex-row">
    </div>
  </div>
</div>

<app-card>
  <div title class="inventory_ctrl flex justify-start content-center items-center flex-row">
    <h5 class="m-0 text-primaryblue text-title lg:grow-0 lg:shrink lg:basis-[270px] xl:grow-0 xl:shrink xl:basis-[270px]" tabindex="0">SCRIPT
      MANAGEMENT</h5>
    <div>
      <div class="app-search-bar-container p-0 xs:flex xs:flex-col flex flex-row">
        <app-search-bar class="xs:basis-full grow-0 shrink basis-[370px]"
          placeholder="Search by Patient Name, NDC, Drug Name or Rx number" 
          (filterChange)="filterInventory($event)"></app-search-bar>
        <form action="" class="smsort flex justify-start content-center items-center basis-full">
          <span class="sort">Sort By</span>
          <mat-form-field appearance="fill" class="m-l-8 xs:flex-1 grow-0 shrink basis-[200px]">
            <mat-select class="m-t-0 sm_sorting">
              <mat-option>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <h6 class="m-l-16 m-r-16 m-t-8 m-b-8 text-primaryblue text-title h7" tabindex="0">SCRIPT VIEW</h6>
  <div *ngIf="machineTypeMAX === false" class="loading-spinner text-center w-100 m-15">
    No data exists
  </div>
</app-card>

<mat-tab-group headerPosition="below" class="scriptmgmt_tab">
  <mat-tab class="tab-table-button-left" *ngIf="machineTypeMAX === true">
    <app-script-manager-table-rpt [organizationId] = "organizationId"  [inventories]="queueDataMax" [isComplete]=false></app-script-manager-table-rpt>
    <ng-template class="tab-table-button-left" mat-tab-label> VIAL ORDERS </ng-template>
  </mat-tab>
  <mat-tab class="tab-table-button-right" *ngIf="machineTypeMAX === true">
    <app-script-manager-table-rpt [organizationId] = "organizationId"  [inventories]="queueDataMax" [isComplete]=true></app-script-manager-table-rpt>
    <ng-template mat-tab-label> VIAL COMPLETE ({{completeCount}})</ng-template>
  </mat-tab>
</mat-tab-group>

</div>