import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PharmacyPeekWidgetActions } from 'src/app/store/actions';
import { State } from 'src/app/store/state';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PharmacyPeekDiscardChangesDialogComponent } from '../pharmacy-peek-discard-changes-dialog/pharmacy-peek-discard-changes-dialog.component';
import { PharmacyPeekService } from '../../../services/pharmacy-peek.service';
import { getSaveWidgetsList, getSelectedWidgetsList } from 'src/app/store/selectors/pharmacy-peek-widgets.selector';
import { PharmacyPeekWidgetComponent } from '../pharmacy-peek-widget/pharmacy-peek-widget.component';

@Component({
  selector: 'app-pharmacy-peek-mgmt-dialog',
  templateUrl: './pharmacy-peek-mgmt-dialog.component.html',
  styleUrls: ['./pharmacy-peek-mgmt-dialog.component.scss']
})
export class PharmacyPeekMgmtDialogComponent implements OnInit {
  selectedList: any = [];
  compareList: any = [];

  @ViewChild(PharmacyPeekWidgetComponent)
  private ppWidgetComp!: PharmacyPeekWidgetComponent;

  constructor(public store: Store<State>, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PharmacyPeekMgmtDialogComponent>,
    private pharmacyPeekService: PharmacyPeekService
  ) { }

  ngOnInit(): void {

    this.store.select(getSelectedWidgetsList).subscribe(res => {
      if (res) {
        this.compareList = res;
      }
    }
    );

    this.store.select(getSaveWidgetsList).subscribe(res => {
      if (res) {
        this.selectedList = res;
      }
    }
    );
  }

  filterWidget(value) {
    this.ppWidgetComp.bindWidget(value);
  }

  openPharmacyPeekDiscardChangesDialog() {
    
    if (JSON.stringify(this.selectedList) !== JSON.stringify(this.compareList)) {
      const dialogRefDiscard = this.dialog.open(PharmacyPeekDiscardChangesDialogComponent, { backdropClass: 'smDialog' });

      dialogRefDiscard.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if (result.undoChanges === true) {
          this.store.dispatch(PharmacyPeekWidgetActions.updateSelectedPharmacyWidgetList({ data: this.compareList }));
          this.closeDialog();
        }
      });
    }
    else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }



}
