import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { getPouchScripts } from 'src/app/store/selectors/scripts.selector';
import { State } from 'src/app/store/state';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ParataStandardInterfaceService } from 'src/app/external-user/batch-manager/services/parataStandardInterface.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from '../../../../../../../config/app-constants';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { NotifyDialogComponent } from 'src/app/shared/components/notify-dialog/notify-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { MoveToData } from 'src/app/external-user/batch-manager/models/script-manager-data';

@Component({
  selector: 'app-move-to-dialog',
  templateUrl: './move-pouch-dialog.component.html',
  styleUrls: ['./move-pouch-dialog.component.scss']
})

export class MovePouchDialogComponent implements OnInit {

  parataBatchId: string;
  system: string;
  othersystems: any = [];
  otherSystemsEstimatedTime: any;
  estimatedTime: MoveToData[]  = [];
  selectedDevice:string;
  orgId: string;
  machinelist: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<State>,
    private ngxLoader: NgxUiLoaderService,
    private parataStandardInterfaceService: ParataStandardInterfaceService,
    private toastService: ToastService,
    public dialog: MatDialog,
    private authService: AuthService,
    public dialogRef: MatDialogRef<MovePouchDialogComponent>
  ) {

    this.authService.profile$.subscribe((profile) => {
      this.orgId = profile?.organizationId;
    });

    this.parataStandardInterfaceService.getAllMachines(this.orgId).subscribe((response) => {
      this.machinelist = response;

      this.store.select(getPouchScripts).subscribe(res => {
        if (res) {
          let redData = [...res];
          redData = redData.filter(x => x.name === 'IN QUEUE');

          let batchData = redData[0].inventory.data.batches;
          batchData = batchData.filter(x => x.parataBatchId === this.parataBatchId);

          this.otherSystemsEstimatedTime = batchData[0].otherSystemsEstimatedTime;
          this.estimatedTime = [];
          for (var propertyName in this.otherSystemsEstimatedTime) {
            const isexist = this.machinelist.find(machlist => machlist?.deviceId == propertyName);
            if (isexist) {
              let timestamp: number;
              timestamp = this.otherSystemsEstimatedTime[propertyName];
              var hours = Math.floor(timestamp / 3600);
              var minutes = Math.floor(timestamp / 60) - (hours * 60);
              var seconds = timestamp % 60;
              var formatted = (hours > 0 ? hours.toString() + 'h' : '') + (minutes > 0 ? minutes.toString() + 'm' : '') + (seconds > 0 ? seconds.toString() + 's' : '');
              var estimatedTimeValue = { formatted: formatted, systemname: propertyName, timestamp: timestamp, friendlyname:  isexist.name};
              this.estimatedTime.push(estimatedTimeValue);
            }
          }
          this.estimatedTime = this.estimatedTime?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
        }
      });
    }, (error) => {
      if (error.status == 404) {
        this.machinelist = [];
      }
      else {
        this.toastService.openToast('Error while getting data!', constants.ToastPanelType.error);
      }
    });
    this.parataBatchId = data?.parataBatchId;
    this.system = data?.system
  }

  ngOnInit(): void {
  }

  radioChange(event: MatRadioChange, data) {
    this.selectedDevice = data;
  }
  
  moveBatch() {
    if(this.selectedDevice==null || this.selectedDevice.length==0){
      return this.showSelectionError();
    }

    const ngxLoaderKey = 'move-batch';
    var keyMsg = '';
      this.ngxLoader.start(ngxLoaderKey);
      this.parataStandardInterfaceService.moveParataBatch(this.parataBatchId, this.selectedDevice).subscribe((response) => {
        this.ngxLoader.stop(ngxLoaderKey);
        keyMsg = 'Parata batch with Id: ' + this.parataBatchId + ' has been moved to device: ' + this.selectedDevice;
        console.log(keyMsg);
        //this.toastService.openToast(keyMsg, constants.ToastPanelType.done);
      }, (error) => this.toastService
        .stopLoadingAndShowError(error, 'Error while moving parata batch with Id: ' + this.parataBatchId, ngxLoaderKey)
      );
      this.dialogRef.close();
    }

  showSelectionError() {
    this.dialog.open(NotifyDialogComponent, {
      width: '500px',
      data: {
        title: 'NEW SYSTEM LOCATION NOT SELECTED',
        content: 'You must select a new system location to move the selected batch.',
      },
    });
    return false;
  }

}
