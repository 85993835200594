export class CellConfigurationXml {
    CellConfiguration: CellConfiguration = {} as CellConfiguration;

    constructor() {
        this.CellConfiguration = new CellConfiguration();
    }
}

export class CellConfiguration {
    Cell: CellData[] = [];

    constructor() {
        this.Cell = [];
    }
}

export interface CellData {
    Location: string;
    NDC: string;
}