import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { PharmacyPeekWidgetActions } from 'src/app/store/actions';
import { State } from 'src/app/store/state';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PharmacyPeekDiscardChangesDialogComponent } from '../pharmacy-peek-discard-changes-dialog/pharmacy-peek-discard-changes-dialog.component';
import { getSaveWidgetsList, getSelectedWidgetsList } from 'src/app/store/selectors/pharmacy-peek-widgets.selector';
import { PharmacyPeekService } from '../../../services/pharmacy-peek.service';


@Component({
  selector: 'app-pharmacy-peek-mgmt-dialog-mobile',
  templateUrl: './pharmacy-peek-mgmt-dialog-mobile.component.html',
  styleUrls: ['./pharmacy-peek-mgmt-dialog-mobile.component.scss']
})
export class PharmacyPeekMgmtDialogMobileComponent implements OnInit {
  selectedList: any = [];
  compareList: any = [];

  constructor(public store: Store<State>, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PharmacyPeekMgmtDialogMobileComponent>,
  ) {

  }


  selected = '1';
  ppList = 'PP List(0)';

  ngOnInit(): void {

    this.store.select(getSelectedWidgetsList).subscribe(res => {
      if (res) {
        this.compareList = res;
      }
    }
    );

    this.store.select(getSaveWidgetsList).subscribe(res => {
      if (res) {
        this.listChange(res.length);
        this.selectedList = res;
      }
    }
    );
  }

  listChange(newListLength: number) {
    this.ppList = 'PP List(' + newListLength + ')';
  }

  openPharmacyPeekDiscardChangesDialog() {
    
    if (JSON.stringify(this.selectedList) !== JSON.stringify(this.compareList)) {
      const dialogRefDiscard = this.dialog.open(PharmacyPeekDiscardChangesDialogComponent, { backdropClass: 'smDialog' });

      dialogRefDiscard.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if (result.undoChanges === true) {
          this.store.dispatch(PharmacyPeekWidgetActions.updateSelectedPharmacyWidgetList({ data: this.compareList }));
          this.closeDialog();
        }
      });
    }
    else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  radioChange(event: MatRadioChange) {
    this.selected = event.value;
  }

}
