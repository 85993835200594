<div *ngIf="!this.duplicateorg">
    <form [formGroup]='formGroup' #formDirective="ngForm" class="flex justify-between content-stretch items-stretch flex-col">
        <div>
            <h1 mat-dialog-title tabindex="0">
                Add New Organization
            </h1>
            <mat-dialog-content class="add-org-container">
                <mat-form-field floatLabel="never">
                    <label for="OrganizationName">
                        <app-icon icon="asterisk" size="10" aria-hidden="true"></app-icon>
                        <span>New Organization Name</span>
                    </label>
                    <input matInput id="OrganizationName" appNoHtmlTags formControlName='name' maxlength="51" (keydown)="isLoad($event)">
                    <mat-error *ngIf="formGroup.get('name').hasError('required')">Organization Name Required</mat-error>
                    <mat-error *ngIf="formGroup.get('name').hasError('maxLength')">Name cannot exceed 50 characters</mat-error>
                </mat-form-field>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
            <button mat-button class="btn-default btn-md" (click)="closePopup()" id="cancel_org">Cancel</button>
            <button mat-button class="btn-primary btn-md" (click)="addOrganization()" id="add_org">Add</button>
        </mat-dialog-actions>
    </form>
</div>

<!-- error notification -->
<div *ngIf="this.duplicateorg" class="addsite_dialog">
    <div class="flex justify-between content-stretch items-stretch flex-col">
        <div>
            <h1 mat-dialog-title id="customer-duplicate-entry-header" class="!flex justify-between content-center items-center flex-row">
                <span class="error_txt flex flex-row justify-start content-center items-center h-full flex-1">
                    <img src="../../../../../assets/img/icons/ico.24.alert.svg" alt="" class="m-r-8">
                    DUPLICATE ENTRY
                </span>
                <button class="btn-transparent btn_icon" (click)="closePopup()" id="close_dialog" aria-label="Close" type="button">
                    <span class="svgicon icon_clear"></span>
                </button>
            </h1>


            <mat-dialog-content class="add-org-error-notify">
                <p>
                    The <span class="error_txt">{{this.duplicateorgname}}</span> organization already exists in our system. Organizations cannot be duplicated. If this was a mistake, try again.
                </p>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex justify-end content-end items-end w-full flex-row">
            <button mat-button class="btn-default btn-md btn-done" (click)="closePopup()" id="close_btn">Close</button>
        </mat-dialog-actions>
    </div>
</div>
