import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CreateInventory, DestroyInventory, GetInventory, InventoryDrugInfo } from '../../models/inventory';
import { RpoToolService } from '../../services/rpo-tool.service';
import * as constants from 'src/app/config/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-import-wizard-inventory',
  templateUrl: './import-wizard-inventory.component.html',
  styleUrls: ['./import-wizard-inventory.component.scss']
})
export class ImportWizardInventoryComponent implements OnInit {


  drugInfoModel: InventoryDrugInfo;
  saveInfoModel: CreateInventory;
  getInventoryInfo: GetInventory={};
  inventoryList: GetInventory[] = [];
  destroyInventoryModel: DestroyInventory;

  inventoryWizard = 1;
  wizardTitle = 'Add Drug Record to Inventory';

  fileContent: string;
  fielName= '';
  fieleSelect= false;


  //#region Inventory Create Form Controls
    saveInfoModelForm: FormGroup;
    inventoryComment: FormControl;
    price: FormControl;
    source: FormControl;
    sourceAddress: FormControl;
    sourcePhone: FormControl;
    sourceMail: FormControl;
    quantity: FormControl;
  
  //#endregion

  //#region Inventory Destroy Form Controls
  destroyInventoryForm: FormGroup;
  destroyInventoryComment: FormControl;
  //#endregion
  schemaName:string;
  constructor(
    public dialogRef: MatDialogRef<ImportWizardInventoryComponent>,
    public rpoService: RpoToolService,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private route: Router,
    private toastService: ToastService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.inventoryWizard = data.inventoryWizardStatus;
      this.schemaName = data.schemanName;
      this.wizardTitle = this.inventoryWizard===1?this.wizardTitle:this.inventoryWizard===2?'Destroy Drug':'Drug History';
      this.drugInfoModel = data.drugInfo;
    }

  ngOnInit(): void {
    this.functionCallerCondition();

  }

  functionCallerCondition(){
    if(this.inventoryWizard===1){
      this.saveInfoModelForm = new FormGroup({
        inventoryComment: new FormControl(''),
        price:new FormControl(0),
        quantity:new FormControl(0, Validators.required),
        source:new FormControl('', Validators.required),
        sourceAddress:new FormControl(''),
        sourcePhone:new FormControl(''),
        sourceMail:new FormControl('')
      });
    }
    else if(this.inventoryWizard===2){
      this.getInventoryById();
      this.destroyInventoryForm = new FormGroup({
        destroyInventoryComment: new FormControl('')
      });
    }
    else if(this.inventoryWizard===3){
      this.getInventoryByNdc();
    }
  }

  onFileSelected(event: any) {
    this.prepareFile(event);
    }

prepareFile(event: any) {
  this.fielName = event.target.files[0].name;
  this.fieleSelect = true;
  const reader = new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = (readerEvent: any) => {
    const splitVal = reader.result.toString().split(',');
    if (splitVal.length > 0) {
      this.fileContent = 'data:image/png;base64,'.concat(splitVal[1]) ;
      console.log(this.fileContent);
    }
  };
}


save() {
  const formValue = this.saveInfoModelForm.value;
   this.saveInfoModel = {
    price:formValue.price,
    quantity:formValue.quantity,
    source:formValue.source,
    sourceAddress:formValue.sourceAddress,
    sourcePhone:formValue.sourcePhone,
    sourceEmail:formValue.sourceMail,
    comments:formValue.inventoryComment,
    drugNumber:this.drugInfoModel.drugNumber,
    images:this.fileContent,
    schemaName:this.schemaName,
    sourceType:''
   };
  this.ngxLoader.start();
  this.rpoService
    .saveInventory(this.saveInfoModel)
    .subscribe(
      (result) => {
        this.ngxLoader.stop();
        if (result.statusCode === 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          this.clearFormData();

        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(error.error.message === undefined?
          'Something went wrong!!': error.error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
}

update() {
  const formValue = this.destroyInventoryForm.value;
   this.destroyInventoryModel = {
    id:this.getInventoryInfo.id,
    destroyedComments:formValue.destroyInventoryComment,

   };
  this.ngxLoader.start();
  this.rpoService
    .destroyInventory(this.destroyInventoryModel)
    .subscribe(
      (result) => {
        this.ngxLoader.stop();
        if (result.statusCode === 200) {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.done,
            2
          );
          this.clearFormData();

        } else {
          this.toastService.openToast(
            result.message,
            constants.ToastPanelType.error,
            2
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(error.error.message === undefined?
          'Something went wrong!!': error.error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
}
getInventoryById() {
  this.ngxLoader.start();
  this.rpoService
    .getInventoryById(this.drugInfoModel.inventoryNumber)
    .subscribe(
      (response) => {
        let result = response.data
      
        this.getInventoryInfo = result;
        this.getInventoryInfo.isDestroyed = result.destroyed;
        this.getInventoryInfo.inventoryNumber = result.id;
        this.getInventoryInfo.addedDate = result.date;
        this.getInventoryInfo.owner = result.changedDate;
        this.getInventoryInfo.inventoryQuantity = result.quantity;
        this.getInventoryInfo.lastChangedDate = result.changedDate;
        console.log(this.getInventoryInfo)

        this.ngxLoader.stop();

      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(error.error.message === undefined?
          'Something went wrong!!': error.error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
}

getInventoryByNdc() {
  this.ngxLoader.start();
  this.rpoService
    .getInventoryByNDC(this.drugInfoModel.drugNumber)
    .subscribe(
      (response) => {
        let result = response.data;
        result.forEach((element: any) => {
          this.inventoryList.push({
            id: element.id,
            inventoryNumber: element.id,
            addedDate: element.date,
            owner: element.changedDate,
            inventoryQuantity: element.quantity,
            lastChangedDate: element.changedDate,
            isDestroyed: element.destroyed,
            destroyDate: element.destroyedDate,
            destroyedBy: element.destroyedBy,
            destroyedComments: element.destroyedComments,
            price: element.price,
            source: element.source,
            sourceAddress: element.sourceAddress,
            sourcePhone: element.sourcePhone,
            sourceEmail: element.sourceEmail,
            comments: element.comments,
          });
        });

        this.ngxLoader.stop();

      },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        console.log(error);
        this.toastService.openToast(error.error.message === undefined?
          'Something went wrong!!': error.error.message,
          constants.ToastPanelType.error,
          2
        );
      }
    );
}


clearFormData(){
if(this.inventoryWizard===1){
  this.saveInfoModelForm.reset();
}
else if(this.inventoryWizard===2){
  this.destroyInventoryForm.reset();
}
this.fileContent='';
this.fieleSelect  = false;
setTimeout(() => {
  this.closeDialog(true);
}, 500);
}

closeDialog(response:boolean){
  this.dialogRef.close({event:'close', data:response })
}


}
