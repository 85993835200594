import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { getAssetsByOrgID } from 'src/app/store/selectors/assets.selector';
import { State } from 'src/app/store/state';
import { translateMachineTypeIdToMachinePermission, translateMachinePermissionToMachineTypeId } from 'src/app/external-user/pharmacy-peek/services/mapping';


interface System {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pharmacy-peek-select-dialog',
  templateUrl: './pharmacy-peek-select-dialog.component.html',
  styleUrls: ['./pharmacy-peek-select-dialog.component.scss']
})
export class PharmacyPeekSelectDialogComponent implements OnInit {
  systems: any = [
    { value: 'allSystem', viewValue: 'All Systems' },
  ];
  selectedSystem = this.systems[0].value;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, widget: object },
    public dialogRef: MatDialogRef<PharmacyPeekSelectDialogComponent>,
    public store: Store<State>,

  ) { }

  ngOnInit(): void {

    this.store.select(getAssetsByOrgID).subscribe(assets => {
      if (assets) {
        var widgetJson = JSON.parse(JSON.stringify(this.data.widget));
        assets.forEach(element => {
          widgetJson.machinePermissions.forEach(machine => {
            if (element.configurations.machineTypeID == translateMachinePermissionToMachineTypeId(machine)) {
              this.systems.push({ value: element.machineNo, viewValue: element.name });
            }
          });
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ add: false, selectedSystem: null });
  }

  closeDialogAdd() {
    const data: any = this.systems.filter(x => x.value === this.selectedSystem);
    this.dialogRef.close({ add: true, selectedSystem: data[0] });
  }

}
