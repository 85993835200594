import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import * as constants from '../../config/app-constants';
import { InventoryMasterComponent } from './components/inventory-master/inventory-master.component';

const routes: Routes = [
  {
    path: constants.routePaths.INVENTORY_ATP,
    component: InventoryMasterComponent,
    canActivate: [AuthGuard],
    data:{deviceType:"atp"}
  },
  {
    path: constants.routePaths.INVENTORY_MAX,
    component: InventoryMasterComponent,
    canActivate: [AuthGuard],
    data:{deviceType:"max"}
  },
  {
    path: constants.routePaths.INVENTORY_PASS,
    component: InventoryMasterComponent,
    canActivate: [AuthGuard],
    data:{deviceType:"pass"}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InventoryRoutingModule { }
