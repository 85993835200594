import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';
import { InventoryData } from '../../external-user/inventory/models/inventory-data';
import * as storage from '../state/storage';
import { InvRptData } from '../../external-user/inventory/components/inventory-child/inventory-views/inventory-views.component';

export const initialState: InvRptData = {
   Inventory: storage.getItem('inventory').Inventory,
   //TotalDrugCount: storage.getItem('inventory').TotalDrugCount,
};

const inventoryReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.InventoryActions.getInventoryData, (state, action) => ({
      ...state,
      ...action.data
    })
  ),

  on(AllActions.InventoryActions.updateInventoryData, (state, action) => ({
      ...state,
      ...mergeInventory(state, action)
    })
  ),

  on(AllActions.InventoryActions.resetInventoryData, (state, action) => ({ Inventory : [], TotalDrugCount: 0})
  ),
);

export function reducer(state: InvRptData | undefined, action: Action): any {
  return inventoryReducer(state, action);
}

export function mergeInventory(storeData, newData) {
  if (storeData.Inventory) {
    const sData = [...storeData.Inventory];
    const nData = [...newData.inventories];
    const arr = [];
    if (sData.length > 0) {
      sData.forEach(e => {
        arr.push(e);
      });
    }

    if (nData.length > 0) {
      nData.forEach(e => {
        arr.push(e);
      });
    }
    const obj: InvRptData = { Inventory: arr}; //, TotalDrugCount: newData.totalDrugCount };
    return obj;
  }
  else {
    return newData;
  }
}
