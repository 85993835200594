import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { State } from '../reducers/organizations.reducer';
import { Organization } from 'src/app/internal-user/customer-management/models/organization';

export const selectOrganizationState = createFeatureSelector<State>('organizations');

export const selectAllOrganizations = createSelector(
  selectOrganizationState,
  (state: State) => state.organizations
);

export const selectParentOrganizations = createSelector(
  selectAllOrganizations,
  (organizations: Organization[]) => organizations.filter(org => org.is_Parent_Account)
);

export const selectSites = createSelector(
  selectAllOrganizations,
  (organizations: Organization[]) => organizations.filter(org => !org.is_Parent_Account)
);

export const selectSitesByParentCustomerNo = (orgCustomerNo: string) => createSelector(
  selectAllOrganizations,
  (organizations: Organization[]) => {
    if (!orgCustomerNo) {
      return [];
    }

    let parentOrg = organizations.find(org => org.is_Parent_Account && org.customerNo === orgCustomerNo);

    if (!parentOrg) {
      return [];
    }

    return organizations.filter(org => !org.is_Parent_Account && org.parent_Account_Id === parentOrg.organizationId);
  }
);

export const selectSiteByCustomerNo = (siteCustomerNo: string) => createSelector(
  selectSites,
  (organizations: Organization[]) => organizations.find(site => site.customerNo && site.customerNo === siteCustomerNo)
)

export const selectLoading = createSelector(
  selectOrganizationState,
  (state: State) => state.loading
);

export const selectError = createSelector(
  selectOrganizationState,
  (state: State) => state.error
);