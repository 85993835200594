<div class="addsite_dialog">
    <div class="flex justify-between content-stretch items-stretch flex-col">
        <div>
            <h1 mat-dialog-title class="!flex justify-between content-center items-center flex-row">
                <span class="error_txt flex flex-row justify-start content-center items-center h-full flex-1">
                    <img src="../../../../../assets/img/icons/ico.24.alert.svg" alt="" class="m-r-8">
                       DELETE PERMISSION GROUP?
                </span>
                <button class="btn-transparent btn_icon" (click)="closePopup()" id="close_dialog" aria-label="Close" type="button">
                    <img alt="clear" src="../../../../../assets/img/icons/ico.16.clear.svg" class="ng-star-inserted" style="width: 16px; height: 16px; transform: rotate(0deg);">
                </button>
            </h1>
            <mat-dialog-content class="delete-container">
                <p>
                    Are you sure you want to delete the  <span class="error_txt" style="font-weight: bold;">{{this.selectedGroup.name | uppercase }}</span>  permission group?
                </p>
                <br>
                <p>
                    This action will result in the {{this.impactedMembers}} group members defaulting to the <span class="highlight-label"> MEMBER </span> permission group.
                </p>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full">
            <button mat-button class="btn-default btn-md btn-done" (click)="closePopup()" id="close_btn">Close</button>
			 <button mat-raised-button class="btn-warn  btn-md" color="warn" (click)="deletePermissionGroup()" id="confirm_delpg">Delete</button>
        </mat-dialog-actions>
    </div>
</div>