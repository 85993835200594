import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ToastComponent } from '../components/toast/toast.component';
import { ToastPanelType } from 'src/app/config/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private snackBar: MatSnackBar,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  // toast  service for any notification
  public openToast(
    message: string,
    panelType: ToastPanelType = ToastPanelType.done,
    duration: number = null,
  ) {
    if (message != 'User inactivated')
    {
    duration = duration || (panelType === ToastPanelType.error ? 15 : 6);
    this.snackBar.openFromComponent(ToastComponent, {
      duration: duration * 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: { message },
      panelClass: panelType,
    });
  }
  }

   public async stopLoadingAndShowError(response, errorMessage, loadingId = '') {
     let severError = '';
     if (loadingId) {
       this.ngxLoader.stop(loadingId);
     }

     const { errors, error, status } = response;
     if (status === 404) {
       this.openToast((await error.text()) || 'No results found', ToastPanelType.warning);
       return;
     }

     if (errors) {
       severError = Object.values(error.errors)
         .map((e: string[]) => e.join('\n'))
         .join('\n');
     } else if (error) {
       if (error instanceof Blob) {
         severError = (await error.text()) || '';
       } else {
         severError = `${error.error || ''}`;
       }
     }

     const message = `${errorMessage || ''}\n\n${severError ? `<small>${severError}</small>` : ''}`
       .trim()
       .replace(/\n/g, '');

     this.openToast(message, ToastPanelType.error);
   }
}
