import {
    createFeatureSelector,
    createSelector,
  } from '@ngrx/store';
import { State } from '../reducers/signalr.reducer';

  export const getState = createFeatureSelector<State>('signalr');

  export const getSignalrList = createSelector(
    getState,
    (state: State) => state.signalrSubList
  );
