import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cddb-automation-script',
  templateUrl: './cddb-automation-script.component.html',
  styleUrls: ['./cddb-automation-script.component.scss']
})
export class CddbAutomationScriptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
