
<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">

    <div mat-dialog-title id="drug-details-header">
      <div class="flex justify-start content-center items-center flex-row">

          <div class="flex flex-row justify-start content-stretch items-stretch h-full">
           <h3>DRUG IMAGES</h3>
          </div>

      </div>
  </div>

 <div  class="images-slideshow">
  <div  class="imagesSlides fade">
    <img  [src]="image" />
  </div>
 </div>
  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
  <button mat-button class="btn-blue-light btn-md" (click)="dialogRef.close()" id="CancelNewUser" aria-label="Cancel" type="button">Cancel</button>

</mat-dialog-actions>
  </div>



