import {
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { downTimeZero, upTimeZero, HourlyPouchCountZero } from 'src/app/external-user/pharmacy-peek/models/pharmacy-peek-data';
import { mapperDowntimeAllSystem, mapperDowntimeDevice, mapperUptimeAllSystem, mapperUptimeDevice } from 'src/app/external-user/pharmacy-peek/services/mapping';
import { State } from '../reducers/pharmacy-peek.reducer';
import { State as AssetsState } from '../reducers/assets.reducer';

export const getPharmacyPeekState = createFeatureSelector<State>('pharmacyPeek');
export const getAssetsState = createFeatureSelector<AssetsState>('assets');

export const getHourlyPouchCountDevice = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    if (state.HourlyPouchCountDevice) {
      return getFilteredHPCDevice(state.HourlyPouchCountDevice);
    }
    else {
      return state.HourlyPouchCountDevice;
    }
  }
);

export const getHourlyVialCountDevice = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    const newState = state.HourlyVialCountDevice;
    return newState;
  }
);

export const getHourlyPouchCountAllSystem = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    if (state.HourlyPouchCountAllSystem) {
      return getFilteredHPCAllSystem(state.HourlyPouchCountAllSystem);
    }
    else {
      return state.HourlyPouchCountAllSystem;
    }
  }
);

export const getHourlyVialCountAllSystem = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    const newState = state.HourlyVialCountAllSystem;
    return newState;
  }
);

export const getDispenseRatio = createSelector(
  getPharmacyPeekState,
  getAssetsState,
  (state: State, assets: AssetsState) => {

    if (state.DispenseRatio) {
      return getFilteredDispenseRatio(state.DispenseRatio, assets.assets);
    }
    else {
      return state.DispenseRatio;
    }

  }
);

export const getUptimeDevice = createSelector(
  getPharmacyPeekState,
  getAssetsState,
  (state: State, assets: AssetsState) => {
    if (state.UptimePackagerDevice) {
      return getFilteredUptime(state.UptimePackagerDevice, assets.assets);
    }
    else {
      const newState = state.UptimePackagerDevice;
      return newState;
    }
  }
);

export const getUptimeAllSystem = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    if (state.UptimePackagerAllSystem) {
      return getFilteredUpTimeAllSystem(state.UptimePackagerAllSystem);
    }
    else {
      return state.UptimePackagerAllSystem;
    }
  }
);

export const getDowntimePackagerDevice = createSelector(
  getPharmacyPeekState,
  getAssetsState,
  (state: State, assets: AssetsState) => {
    if (state.DowntimePackagerDevice && state.DowntimePackagerDevice.length > 0) {
      return getFilteredDowntime(state.DowntimePackagerDevice, assets.assets);
    }
    else {
      return state.DowntimePackagerDevice;
    }
  }
);

export const getDowntimePackagerAllSystem = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    if (state.DowntimePackagerAllSystem) {
      return getFilteredDownTimeAllSystem(state.DowntimePackagerAllSystem);
    }
    else {
      return state.DowntimePackagerAllSystem;
    }
  }
);


export const getSystemStatusDevice = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    const newState = Object.assign({}, state.SystemStatusDevice);
    return newState;
  }
);

export const getSystemStatusAllSystem = createSelector(
  getPharmacyPeekState,
  (state: State) => {
    const newState = state.SystemStatusAllSystem;
    return newState;
  }
);


export function getFilteredHPCAllSystem(storeData) {
  const isPreviousDayData = getPreviousDayDataBoolean(storeData);
  if (isPreviousDayData === true) {
    storeData.data = HourlyPouchCountZero;
  }
  const resData = Object.assign({}, storeData);
  return resData;
}

export function getFilteredHPCDevice(storeData) {

  let resData: any = [...storeData];
  resData = [...resData];
  let packagers = [];
  resData.forEach(element => {
    const isPreviousDayData = getPreviousDayDataBoolean(element);
    if (isPreviousDayData === true) {
      element.data = HourlyPouchCountZero;
    }

    packagers.push(element);
  });

  if (packagers.length > 0) {
    packagers = [...packagers];
  }

  return packagers;
}

export function getFilteredDispenseRatio(storeData, pgAssets) {
  const resData: any = Object.assign({}, storeData);
  const newArr = [];
  if (resData && pgAssets) {
    for (const key in resData.data) {
      if (Object.prototype.hasOwnProperty.call(resData.data, key)) {

        const pgAsset = pgAssets.filter(x => key === x.machineNo);
        resData.data[key].deviceId = key;
        if (pgAsset[0] !== undefined && pgAsset[0] != null) {
          resData.data[key].name = pgAsset[0].name;
        }
        else {
          resData.data[key].name = '';
        }
        newArr.push(resData.data[key]);
      }

    };
  }
  return newArr;
}

export function getFilteredUpTimeAllSystem(storeData) {
  const isPreviousDayData = getPreviousDayDataBoolean(storeData);
  if (isPreviousDayData === true) {
    storeData.data = upTimeZero;
  }
  let resData: any = mapperUptimeAllSystem(storeData);
  resData = Object.assign({}, resData);
  return resData;
}

export function getFilteredDownTimeAllSystem(storeData) {
  const isPreviousDayData = getPreviousDayDataBoolean(storeData);
  if (isPreviousDayData === true) {
    storeData.data = downTimeZero;
  }
  let resData: any = mapperDowntimeAllSystem(storeData);
  resData = Object.assign({}, resData);
  return resData;
}

export function getFilteredUptime(storeData, pgAssets) {
  let resData: any = [...storeData];
  resData = [...resData];
  let packagers = [];
  if (resData && pgAssets) {
    resData.forEach(element => {
      const isPreviousDayData = getPreviousDayDataBoolean(element);
      if (isPreviousDayData === true) {
        element.data = upTimeZero;
      }

      let data: any = mapperUptimeDevice(element);
      data.name = getName(element, pgAssets);
      data.siteId = element.siteId;
      packagers.push(data);
    });
  }

  if (packagers.length > 0) {
    packagers = [...packagers];
  }

  const sortResData = packagers;
  const sortedData = sortResData.sort((a, b) => {
    if (a.deviceId.toLowerCase() < b.deviceId.toLowerCase()) { return 1; }
    if (a.deviceId.toLowerCase() > b.deviceId.toLowerCase()) { return -1; }
    return 0;
  });

  return sortedData;
}

export function getFilteredDowntime(storeData, pgAssets) {
  let resData: any = [...storeData];
  resData = [...resData];
  let packagers = [];
  if (resData && pgAssets) {
    resData.forEach(e => {
      let element = Object.assign({}, e);
      element.data = Object.assign({}, element.data);

      const isPreviousDayData = getPreviousDayDataBoolean(element);
      if (isPreviousDayData === true) {
        element.data = downTimeZero;
      }

      let data: any = mapperDowntimeDevice(element);
      data.name = getName(element, pgAssets);
      data.siteId = element.siteId,
        packagers.push(data)
    });
  }
  if (packagers.length > 0) {
    packagers = [...packagers];
  }

  const sortResData = packagers;
  const sortedData = sortResData.sort((a, b) => {
    if (a.deviceId.toLowerCase() < b.deviceId.toLowerCase()) { return 1; }
    if (a.deviceId.toLowerCase() > b.deviceId.toLowerCase()) { return -1; }
    return 0;
  });

  return sortedData;
}

export function getName(element, pgAssets) {
  let name = '';
  if(pgAssets){
  const pgAsset = pgAssets.filter(x => element?.deviceId.includes(x.machineNo));
  name = pgAsset[0] !== undefined && pgAsset[0] != null ? pgAsset[0].name : '';
  }
  return name;
}

export function getPreviousDayDataBoolean(element) {
  let isPreviousDayData = false;
  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const dayJson = new Date(element.timestamp).getDate();
  const monthJson = new Date(element.timestamp).getMonth();
  const yearJson = new Date(element.timestamp).getFullYear();

  if (day !== dayJson || month !== monthJson || year !== yearJson) {
    isPreviousDayData = true;
  }
  return isPreviousDayData;
}