/* eslint-disable max-len */
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import * as constants from './config/app-constants';
import { Menu } from './config/app-constants';
import { FocusOrigin } from '@angular/cdk/a11y';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { LaunchDarklyService } from './shared/services/launch-darkly.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EulaDialogComponent } from './shared/components/eula-dialog/eula-dialog.component';
import { AuthorizationService } from './internal-user/customer-management/services/authorization.service';
import { navConfig, navItems } from './config/nav';
import { ActivatedRoute } from '@angular/router';
import { AccountDeactivatedNotifyDialogComponent } from './shared/components/account-deactivated-notify-dialog/account-deactivated-notify-dialog.component';
import { PharmacyPeekService } from './external-user/pharmacy-peek/services/pharmacy-peek.service';
import { UserManagementDialogComponent } from './shared/components/user-management-dialog/user-management-dialog.component';
import { PharmacyPeekDialogComponent } from './external-user/pharmacy-peek/components/pharmacy-peek-dialog/pharmacy-peek-dialog.component';
import { NotificationMgmtDialogComponent } from './external-user/notification-mgmt-dialog/notification-mgmt-dialog.component';
import { Profile } from './shared/models/auth/profile';
import { Role } from './shared/models/auth/role';
import { NavItem } from './shared/ng-material-multilevel-menu/app.model';
import { AssetService } from './external-user/inventory/services/asset.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { AssetsActions } from './store/actions';
import { getLaunchDarklyFlags } from './store/selectors/assets.selector';
import { IdleTimeMonitorService } from './shared/idle-time-monitor/idle-time-monitor.service';
import { PermissionGroupsDialogComponent } from './shared/components/permission-groups-dialog/permission-groups-dialog.component';


/* eslint-enable max-len */

const mobileWidth = 960;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {

  routePaths = constants.routePaths;
  loadApp = true;
  //#region private fields
  // side bar fields
  opened = true;
  dock = true;

  profile: Profile;
  Role = Role;

  enablePharmacyPeek = false;
  showPharmacyPeek = false;
  pharmacyPeekCollapsed = false;
  pharmacyPeekMobileShown = false;
  enableNotifications = false;
  enableMessages = false;

  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;

  // nav menu items
  navItems: NavItem[] = [];
  navConfig = navConfig;

  private windowWidth = window.innerWidth;

  //#endregion


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDockMode();
    // this.showPharmacyPeekOnToggleToMobile(event);
  }

  menu_NotificationManagementFlag = false;

  constructor(
    private bnIdle: IdleTimeMonitorService,
    public auth: AuthService,
    media: MediaMatcher,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public launchDarklyService: LaunchDarklyService,
    public dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private activatedRoute: ActivatedRoute,
    private pharmacyPeekService: PharmacyPeekService,
    private assetService: AssetService,
    public store: Store<State>,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.registerMatIcons();
  }

  ngOnInit() {
    this.setDockMode();
    this.showErrorAccountDeactivated();
    this.loadPharmacyPeekConfig();

    //60 = 1 minute
    const IdleTimeInSecs=1200;
    this.bnIdle.startWatching(IdleTimeInSecs).subscribe((res) => {
      if (res) {

        this.auth.logout();
      }
    });

    this.auth.profile$.subscribe(profile => {
      console.info(profile);  // for debug reason
      this.profile = profile;
      const machinePermissions = profile.machinePermissions;
      const childOrgId = profile.organizationId;

      // Get assets and store only for external users(customers)
      if(profile.orgType == "External" && childOrgId) {
      this.assetService.getAssetByOrgId(childOrgId)
        .subscribe((assets) => {
          this.store.dispatch(AssetsActions.getAssetByOrgId({ assets, machinePermissions, authProfile: this.profile}));
        }, (error) => {
          console.log('assetService.getAssetByOrgId error:', error);
        });
      }

      if (!profile.userRoles.length) {
        this.loadApp = false;
        return this.showErrorAccountHasNoAuthorization();
      }
      this.selectLaunchDarklyUser(profile);
      this.checkEulaAccepted();
    });

    
    this.store.select(getLaunchDarklyFlags).subscribe(flags => {
      if (flags) {
        this.menu_NotificationManagementFlag = flags.Menu_NotificationManagement; 
      }
    }
    );
  }

  menuload() {
    this.enablePharmacyPeek = this.profile.menuPermissions.includes(Menu.pharmacypeek);
    this.initPendo(this.profile);
    this.initMenu();
  }

  //#region functions

  toggleOpened(): void {
    this.opened = !this.opened;
  }

  /** collapse navmenu in mobile view on menu item selection */
  selectedMenuItem(): void {
    this.setDockMode();
  }

  setDockMode() {
    this.opened = window.innerWidth > 1024;
  }

  // focus
  formatOrigin(origin: FocusOrigin): string {
    return origin ? origin + ' focused' : 'blurred';
  }

  /** workaround for the fact that (cdkFocusChange) emits outside NgZone. */
  markForCheck() {
    this.ngZone.run(() => this.changeDetectorRef.markForCheck());
  }

  //#endregion

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  private registerMatIcons() {
    this.matIconRegistry.addSvgIcon(
      'batches',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/batches.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'three_dots',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/ico_16_Menu.svg'),
    );
  }

  private selectLaunchDarklyUser(profile: Profile) {
    this.launchDarklyService.selectUser({
      key: profile.email,
      firstName: profile.givenName,
      lastName: profile.familyName,
      email: profile.email,
      custom: {
        orgId: profile.organizationId,
        orgName: profile.organization,
        parentOrgId: profile.parentOrganizationId,
        userRoles: profile.userRoles
      },
    });
  }

  private loadPharmacyPeekConfig() {
    this.launchDarklyService.flagChange
      .subscribe((flags) => {
        this.showPharmacyPeek = flags['pharmacy-peek'];
        this.enableNotifications = flags.TopNavigation_Notifications;
        this.enableMessages = flags.TopNavigation_Messages;

        // show on init if in mobile view
        if (this.windowWidth <= mobileWidth && this.showPharmacyPeek && this.enablePharmacyPeek) {
          //  this.openPharmacyPeekDialog();
        }
      });

    this.pharmacyPeekService.collapsed
      .subscribe((value) => this.pharmacyPeekCollapsed = value);
  }

  private showErrorAccountDeactivated() {
    this.activatedRoute.queryParams.subscribe(({ error, error_description }) => {
      if (error === 'unauthorized' && error_description === 'user is blocked') {
        this.loadApp = false;
        this.dialog.open(AccountDeactivatedNotifyDialogComponent, {
          width: '500px',
          data: {
            title: 'Your credentials have been inactivated',
            content: 'Your credentials have been inactivated, Please reach out to your pharmacies admin if you have any questions',
          },
        });
      }
    });
  }


  private showErrorAccountHasNoAuthorization() {
    this.dialog.open(AccountDeactivatedNotifyDialogComponent, {
      width: '500px',
      data: {
        title: 'You do not have authorization to access site',
        content: 'You do not have authorization to access site, Please reach out to your Pharmacy\'s Admin if you have any questions',
      },
    });
  }

  private checkEulaAccepted() {
    if (this.profile.orgType == "External") {
      if (!this.profile.eulaAccepted) {
        this.launchDarklyService.flagChange.subscribe(({ Login_EULA }) => {
          if (Login_EULA) {
            this.loadApp = false;
            this.showEulaForm();
          }
          else {
            this.menuload();
          }
        });
      }
      else {
        this.menuload();
      }
    }
    else {
      this.menuload();
    }
  }

  private showEulaForm() {
    const dialogRef = this.dialog.open(EulaDialogComponent, { width: '750px', disableClose: true, backdropClass: 'policy_modal' });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authorizationService.putAcceptEula(this.profile.email).subscribe();
        this.loadApp = true;
        this.menuload();
      } else {
        this.auth.logout();
      }
    });
  }

  private initMenu() {
    this.navItems = navItems.filter(x => this.profile.menuPermissions.includes(x.menuId));
    
    this.launchDarklyService.flagChange.subscribe((flags) => {
      const flagMap = {
        batches: '/script-routing',
        Menu_Inventory: '/inventory',
        Menu_FleetManagement: '/fleet-management',
        InternalPortal_Dashboard: '/dashboard',
        Menu_DrugDBManagement: '/drug-management',
        Menu_IMTool: '/im-tool',
      };

      const excludedUrls = Object.entries(flags)
        .filter(([, value]) => !value)
        .map(([key]) => flagMap[key])
        .filter(v => v);

      this.store.dispatch(AssetsActions.saveLaunchDarklyFlags({ flags}));

      this.navItems = this.navItems.filter(({ link }) => !excludedUrls.includes(link));
    });
  }

  private initPendo(profile: Profile): void {
    if (pendo.isReady()) { return; }

    pendo.initialize({
      visitor: {
        id: profile.email,
        email: profile.email,
        name: `${profile.givenName} ${profile.familyName}`.trim(),
        role: profile.userRoles.join(','),
      },

      account: {
        id: profile.organizationId,
        name: `${profile.parentOrganization} + ${profile.organization}`.trim(),
      },
    });
  }

  // gear icon
  openUserManagement() {
    if (this.auth.loggedIn) {
      this.dialog.open(UserManagementDialogComponent, { disableClose: true, backdropClass: ['usermgmtDialog', 'lgDialog', 'SnackDialog'] });
    }
  }

  // gear icon
  openPermissionGroups() {
    if (this.auth.loggedIn) {
      this.dialog.open(PermissionGroupsDialogComponent, { disableClose: true, backdropClass: ['permissionGrpsDialog', 'lgDialog', 'SnackDialog'] });
    }
  }

  openPharmacyPeekDialog() {
    if (!this.showPharmacyPeek) { return; }
    this.pharmacyPeekMobileShown = true;
    this.dialog.open(PharmacyPeekDialogComponent, {
      data: {},
      disableClose: true,
      panelClass: ['animate__animated','animate__slideInRight'],
      maxWidth: '768px',
      width: '327px',
      height: '100vh',
      backdropClass: 'pharmacy-peek-dialog',
      position: {
        top: '0',
        bottom: '100vh',
        right: '0',
      },
    });
  }

  openNotificationMgmtDialog() {
    if (this.auth.loggedIn) {
      const dialogRef = this.dialog.open(NotificationMgmtDialogComponent, { backdropClass: ['NotificationMgmtDialog', 'lgDialog', 'SnackDialog'] });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  showPharmacyPeekOnToggleToMobile(event) {
    if (
      this.showPharmacyPeek
      && this.enablePharmacyPeek
      && this.windowWidth > mobileWidth
      && event.target.innerWidth <= mobileWidth
      && !this.pharmacyPeekMobileShown
    ) {
      this.openPharmacyPeekDialog();
    }
    this.windowWidth = event.target.innerWidth;
  }
}
