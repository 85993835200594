import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {

  @Input() value: boolean;
  @Input() onLabel: string;
  @Input() offLabel: string;
  @Output() valueChange = new EventEmitter<boolean>();

  onValueChange($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.valueChange.emit(!this.value);
  }

}
