<h1 mat-dialog-title>
    PARATA STRATUS&trade; SETUP INFORMATION
    <button class="close" (click)="dialogRef.close()"></button>
</h1>

<div mat-dialog-content class="user-management-container">
    <app-alert class="m-b-20" type="info" title="Note" 
        message="Parata will need access to the following ports. Verify your network is enabled for the following port outbound tcp: 8883, 443, 9092. If you have any concerns please email bmcglaughlin@parata.com">
    </app-alert>
    <app-customer-survey></app-customer-survey>
</div>