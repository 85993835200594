<h6 class="m-b-0 flex justify-between content-center items-center flex-row">
    Dispensing Ratios
    <a routerLink="/reports/8f01b8f6-291d-4573-98ce-3c1e309fbce7" id="report_dispensingratios" 
      class="btn-link" aria-label="Dispensing Ratios Report">                
      Report >
    </a>
  </h6>
  <div *ngIf="noDataExistsForDR === false">
    <table mat-table [dataSource]="dataSource" class="dispensingorder">
      <!-- Position Column -->
      <ng-container matColumnDef="canisterRatio">
        <th mat-header-cell *matHeaderCellDef>Canister</th>
        <td id="{{'CanisterRatio' + i}}" mat-cell *matCellDef="let element; index as i" id="canisterRatio">
          {{element.canisterRatio + '%'}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="trayRatio">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> Tray <span>(3 highest tray dispensing
            %)</span></th>
        <td id="{{'TrayRatio' + i}}" mat-cell *matCellDef="let element; index as i" id="trayRatio">
          {{element.trayRatio + '%'}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="deviceId">
        <th mat-header-cell *matHeaderCellDef></th>
        <td id="{{'DeviceId' + i}}" mat-cell *matCellDef="let element; index as i" id="deviceId">
          <span matTooltipClass="my-custom-tooltip" [matTooltipDisabled]="element?.name.length<25" matTooltip="{{element?.name}}" *ngIf="element.name"> {{ element?.name | truncate}}</span>
          <span matTooltipClass="my-custom-tooltip" [matTooltipDisabled]="element?.deviceId.length<25"matTooltip="{{element?.deviceId}}" *ngIf="!element.name">{{ element?.deviceId | truncate}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="noDataExistsForDR === true">
    <p class="pp_report">
      <span class="rcountdesc">No data exists</span>
    </p>
  </div>

<hr>