<div class="flex justify-between content-stretch items-stretch xs:flex xs:flex-col gap-[30px] flex-row">
    <div class="xs:basis-full basis-[59%]">
      <mat-card style="height: 400px;" tabindex="0">
        <mat-card-header>
          <h2 class="mat-card-title" role="heading" aria-level="1">Dashboard</h2>
        </mat-card-header>
        <mat-card-content>

        </mat-card-content>
      </mat-card>
    </div>
    <div class="xs:basis-full basis-[39%]">
      <mat-card class="m-b-30"  style="height: 185px;" tabindex="0">
        <mat-card-header>
          <h2 class="mat-card-title" role="heading" aria-level="2">Orders</h2>
        </mat-card-header> 
        <mat-card-content>

        </mat-card-content>
      </mat-card>

      <mat-card  style="height: 185px;" tabindex="0">
        <mat-card-header>
          <h2 class="mat-card-title" role="heading" aria-level="3">To-Do</h2>
        </mat-card-header>
        <mat-card-content>

        </mat-card-content>
      </mat-card>
    </div>
  </div>