import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DailyPackagerDowntime } from '../../../models/daily-packager-downtime';
import { Subscription } from 'rxjs';
import { auditTime } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';

import { getDowntimePackagerAllSystem } from 'src/app/store/selectors/pharmacy-peek.selector';
import { SignalEventType } from 'src/app/shared/signalr/models/signal-event-type';
import { SignalRService } from 'src/app/shared/signalr/service/signal-r.service';
import { PharmacyPeekActions } from 'src/app/store/actions';
import { addSignalRSubList, deleteSignalRSubList } from 'src/app/store/actions/signalr.action';
import { ToastPanelType } from 'src/app/config/app-constants';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ViewStoreService } from '../../../services/view-type.service';


const dailyPackagerDowntimeColors = ['#053AB2', '#2A84FA', '#6EADFD', '#ACD0FE'];
const dailyPackagerDowntimeKeys = [
  { valueKey: 'overallDTTrayLoad', percentKey: 'overallDTTrayLoadPercent', label: 'Tray Load' },
  { valueKey: 'overallDTCanisterLoad', percentKey: 'overallDTCanisterLoadPercent', label: 'Canister Load' },
  { valueKey: 'overallDTMechanical', percentKey: 'overallDTMechanicalPercent', label: 'Mechanical' },
  { valueKey: 'overallDTOperatorInitiated', percentKey: 'overallDTOperatorInitiatedPercent', label: 'Operator Initiated' },
  { valueKey: 'overallDTOther', percentKey: 'overallDTOtherPercent', label: 'Others' },
];

const dailyPackagerSystemDowntimeKeys = [
  { valueKey: 'dtTrayLoad', percentKey: 'dtTrayLoadPercent', label: 'Tray Load' },
  { valueKey: 'dtCanisterLoad', percentKey: 'dtCanisterLoadPercent', label: 'Canister Load' },
  { valueKey: 'dtMechanical', percentKey: 'dtMechanicalPercent', label: 'Mechanical' },
  { valueKey: 'dtOperatorInitiated', percentKey: 'dtOperatorInitiatedPercent', label: 'Operator Initiated' },
  { valueKey: 'dtOther', percentKey: 'dtOtherPercent', label: 'Others' },

];

@Component({
  selector: 'app-packager-downtime-all-system',
  templateUrl: './packager-downtime-all-system.component.html',
  styleUrls: ['./packager-downtime-all-system.component.scss']
})


export class PackagerDowntimeAllSystemComponent implements OnInit, AfterViewInit, OnDestroy {

  childOrg = '';
  step = -1;
  @ViewChild('todayOverview', { static: false }) todayOverviewRef: ElementRef;

  views = {
    overview: true,
  };


  dailyPackagerUtilization: { [key: string]: any } = {};
  dailyPackagerDowntime: DailyPackagerDowntime[] = [];
  dailyPackagerSystemDowntime: DailyPackagerDowntime[] = [];
  dailyPackagerSystemSpecificDowntime: DailyPackagerDowntime[][] = [];
  noDataExistsForDT = true;
  subscription!: Subscription;

  signalrSubList = {
    type: SignalEventType.DOWMTIME_SITE,
    deviceId: ''
  }
  
  constructor(
    public store: Store<State>, 
    private signal: SignalRService,
    public toastService: ToastService,
    public viewStoreService: ViewStoreService,
    private loggerService: LoggerService,
    ) { }

  ngOnInit() {
    this.store.select(getDowntimePackagerAllSystem).subscribe(response => {
      if (response) {
        const resData = Object.assign({}, response);
        this.dailyPackagerSystemSpecificDowntime = [];
        this.dailyPackagerUtilization = resData;
        this.dailyPackagerDowntime = dailyPackagerDowntimeKeys.map(({ valueKey, percentKey, label }) => ({
          id: `DailyPackagerUtilization_${valueKey}`,
          valueStr: resData[valueKey],
          percent: resData[percentKey],
          value: +(resData[percentKey].replace('%', '')),
          label,
        }))
          .sort(({ value: valueA }, { value: valueB }) => valueB - valueA)
          .map((data, i) => ({ ...data, color: dailyPackagerDowntimeColors[i] }));

        this.noDataExistsForDT = false;
      }
    });
    
    this.subscription = this.signal.getDataStream<string>(SignalEventType.DOWMTIME_SITE)
    .pipe(auditTime(1000))
    .subscribe(dataObj => {
      this.store.dispatch(PharmacyPeekActions.updateDowntimePackagerAllSystem({ data: dataObj.data }));
      console.log(dataObj);
    })

    this.callDowntimeAllSystem();
  }

  ngAfterViewInit() {
    this.store.dispatch(addSignalRSubList({ data: this.signalrSubList }));
  }

  ngOnDestroy() {
    this.store.dispatch(deleteSignalRSubList({ data: this.signalrSubList }));
    this.subscription.unsubscribe();
  }

  
  private callDowntimeAllSystem() {
    this.viewStoreService.getDownTimeAllSystem()
      .subscribe(
        (response) => {
            this.store.dispatch(PharmacyPeekActions.getDowntimePackagerAllSystem({ data: response }));
        },
        (error) => {
          console.log('callDowntimeAllSystem error:', error);
          if (error.status === 404) {
            this.loggerService.logWarning('DowntimeAllSystem Service: No Data: ', error);
          } else {
            this.toastService.openToast('DowntimeAllSystem service load error!', ToastPanelType.error);
          }
        }
      );
  }
  
  setStep(index: number) {
    if (this.step === index)
      {this.step = -1;}
    else
      {this.step = index;}
  }

}
