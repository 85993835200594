<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
    <div>
        <h1 mat-dialog-title>
            <div class="flex justify-start content-center items-center flex-row">
                <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
                    Group Canister
                </div>
                <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
                    <mat-icon (click)="dialogRef.close()" class="cross-wizard">
                        <img src="/assets/img/icons/icon.16.states.svg" alt="">
                    </mat-icon>
                </div>
            </div>
        </h1>


        <mat-dialog-content>
            <div class="flex justify-start content-center items-center flex-row gap-2.5">
                <button mat-button class="btn-light btn-sm" (click)="allDrugUnSelectOrSelect(true)" id="Select-all" 
                    type="button" aria-label="Select All">Select All</button>
                <button mat-button class="btn-warning btn-sm" (click)="allDrugUnSelectOrSelect(false)" id="unselect-all" 
                    type="button" aria-label="Unselect All">Unselect All</button>
            </div>
            <div class="mat-elevation-z8" #TABLE>
                <table #table mat-table [dataSource]="dataSource">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="isChecked">
                        <th mat-header-cell *matHeaderCellDef>

                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox [(ngModel)]="element.isChecked" 
                                (change)="drugCellRowChecked(element, $event.checked)"></mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- SL Name Column -->
                    <ng-container matColumnDef="sl">
                        <th mat-header-cell *matHeaderCellDef> Top Order </th>
                        <td mat-cell *matCellDef="let element"> {{element?.sl}} </td>
                    </ng-container>

                    <!-- Drug Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Drug Name </th>
                        <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
                    </ng-container>

                    <!-- Strength Column -->
                    <ng-container matColumnDef="strength">
                        <th mat-header-cell *matHeaderCellDef> Strength </th>
                        <td mat-cell *matCellDef="let element"> {{element?.strength}} </td>
                    </ng-container>
                    <!-- Container Size Column -->
                    <ng-container matColumnDef="containerSize">
                        <th mat-header-cell *matHeaderCellDef> Container Size </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.containerSize}}
                        </td>
                    </ng-container>
                    <!-- No Of Canister Column -->
                    <ng-container matColumnDef="noOfCanister">
                        <th mat-header-cell *matHeaderCellDef> No Of Canister </th>
                        <td mat-cell *matCellDef="let element">
                            <input class="custom-input" [(ngModel)]="element.noOfCanister" 
                                (ngModelChange)="drugCellRowUpdate(element)" type="number">
                        </td>
                    </ng-container>

                    <!-- Lid Hole Size Column -->
                    <ng-container matColumnDef="lidHole">
                        <th mat-header-cell *matHeaderCellDef> Lid Hole Size </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.lidHole}}
                        </td>
                    </ng-container>

                    <!-- Max Capacity Column -->
                    <ng-container matColumnDef="maxCapacity">
                        <th mat-header-cell *matHeaderCellDef> Max Capacity</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.maxCapacity}}
                        </td>
                    </ng-container>

                    <!-- customerMnemonic Column -->
                    <ng-container matColumnDef="customerMnemonic">
                        <th mat-header-cell *matHeaderCellDef> Customer Mnemonic</th>
                        <td mat-cell *matCellDef="let element"> {{element?.customerMnemonic}} </td>
                    </ng-container>

                    <!-- ndc Column -->
                    <ng-container matColumnDef="ndc">
                        <th mat-header-cell *matHeaderCellDef> NDC</th>
                        <td mat-cell *matCellDef="let element"> {{element?.ndc}} </td>
                    </ng-container>

                    <!-- Unit Usage Column -->
                    <ng-container matColumnDef="usage">
                        <th mat-header-cell *matHeaderCellDef> Usage </th>
                        <td mat-cell *matCellDef="let element"> {{element?.usage}} </td>
                    </ng-container>



                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row (click)="selectedRow = row" [style.background]="row?.backgroundColor" 
                        [ngClass]="{ 'highlight': row === selectedRow }" 
                        *matRowDef="let row; columns: displayedColumns;"></tr>


                </table>
                <table>
                    <tr *ngIf="dataSource?.data.length==0">
                        <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                            No Records Found!
                        </td>
                    </tr>
                </table>

            </div>


        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="flex justify-end content-end items-end w-full flex-row">
        <button mat-button class="btn-primary btn-md" (click)="redirect()" id="Go_Back" type="button" 
            aria-label="Go_Back">Go Back</button>
        <button mat-button class="btn-blue btn-md" id="Confirm" (click)="saveGcnDrugs()" type="button" 
            aria-label="Confirm">Confirm</button>
    </mat-dialog-actions>

</div>