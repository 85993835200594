import { DataService } from '../../../shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Report } from '../models/report';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends DataService<Report> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(environment.apiUrl.reportRepository + '/subscriptions/', http);
    this.httpClient = http;
  }

  DisableAllSubscriptions(userEmail: string): Observable<any> {
    return this.httpClient.put(`${this.url}DisableAll/${userEmail}`, null);
  }
}
