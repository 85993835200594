import { Action, createReducer, on } from '@ngrx/store';
import * as AllActions from '../actions';

export interface State {
  iotDeviceDetails: any;
}

export const initialState: State = {
  iotDeviceDetails: null,
};

const preProvisionReducer = createReducer(
  initialState,

  // GeTasks
  on(AllActions.FleetManagerActions.getIOTDeviceDetailsSuccessful , (state, action) => ({
      ...state,
      iotDeviceDetails: action.data
    })
  ),
);

export function reducer(state: State | undefined, action: Action): any {
  return preProvisionReducer(state, action);
}
